import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@app/directives/directives.module';
import { TaggerInputModule } from '@app/modules/tagger-input/tagger-input.module';
import { IonicModule } from '@ionic/angular';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import * as fromPipes from './pipes';
import { ActionPopoverComponent } from './components/action-popover/action-popover.component';
import { FilterableToolbarComponent } from './components/filterable-toolbar/filterable-toolbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { FsSlideComponent } from './components/fs-carousel/fs-slide/fs-slide.component';
import { ProjectSlideComponent } from './components/fs-carousel/fs-slide/project-slide/project-slide.component';
import { StackPopoverActionsComponent } from './components/fs-carousel/fs-slide/stack-slide/stack-popover-actions/stack-popover-actions.component';
import { StackSlideComponent } from './components/fs-carousel/fs-slide/stack-slide/stack-slide.component';
import { UserSlideComponent } from './components/fs-carousel/fs-slide/user-slide/user-slide.component';
import { ClipPopoverActionsComponent } from './components/fs-grid/clip-tile/clip-popover-actions/clip-popover-actions.component';
import { ClipTileComponent } from './components/fs-grid/clip-tile/clip-tile.component';
import { ListStackItemsComponent } from './components/fs-list/list-stack-items/list-stack-items.component';
import { IconListComponent } from './components/icon-list/icon-list.component';
import { LoginFormComponent } from './components/login/login-form.component';
import { PromoCardComponent } from './components/promo-card/promo-card.component';
import { SpinnerComponent } from './components/spinner/spinner.component'; // mot implemented yet..
import { ButtonComponent } from './components/ui/buttons/button/button.component';
import { EllipsisButtonComponent } from './components/ui/buttons/ellipsis-button/ellipsis-button.component';
import { FormInputComponent } from './components/ui/form/form-input/form-input.component';
import { FormItemComponent } from './components/ui/form/form-item/form-item.component';
import { FormSelectComponent } from './components/ui/form/form-select/form-select.component';
import { FormTextareaComponent } from './components/ui/form/form-textarea/form-textarea.component';
import { FormToggleComponent } from './components/ui/form/form-toggle/form-toggle.component';
import { FormRadioComponent } from './components/ui/form/form-radio/form-radio.component';
import { InputComponent } from './components/ui/input/input.component';
import { ListTitleComponent } from './components/ui/list-title/list-title.component';
import { PageTitleComponent } from './components/ui/page-title/page-title.component';
import { UploaderStatsComponent } from './components/ui/upload-stats/uploader-stats.component';
import { SearchbarComponent } from './components/top-navbar/searchbar/searchbar.component';
import { PopOverComponent } from './components/pop-over/pop-over.component';
import { BaseModalComponent } from './components/ui/modal/base-modal.component';
import { ThumbnailPosterComponent } from './components/thumbnail-poster/thumbnail-poster.component';
import { ModalReportComponent } from './components/modal-report/modal-report.component';
import { SplitLayoutComponent } from './components/layouts/split-layout/split-layout.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { TermsPolicyModalsComponent } from './components/terms-policy-modals/terms-policy-modals.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalInviteSuccessComponent } from './components/modal-invite-success/modal-invite-success.component';
import { SegmentComponent } from './components/ui/segment/segment.component';
import { SegmentButtonComponent } from './components/ui/segment/button/segment-button.component';
import { TabFabContainerComponent } from './components/tab-fab-container/tab-fab-container.component';

// NgxPopperModule.forRoot returns a ModuleWithProviders type without a generic type argument

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        IonicModule,
        TaggerInputModule,
        DirectivesModule,
        TranslateModule,
        NgxPopperjsModule.forRoot(),
    ],
    declarations: [
        // CollapseDirective,//move to app.module?
        FooterComponent,
        IconListComponent,
        PromoCardComponent,
        SpinnerComponent,
        FilterableToolbarComponent,
        StackSlideComponent,
        UserSlideComponent,
        ProjectSlideComponent,
        FsSlideComponent,
        ActionPopoverComponent,
        ClipPopoverActionsComponent,
        StackPopoverActionsComponent,
        ...fromComponents.components,
        ...fromDirectives.directives,
        ...fromPipes.pipes,
        ListStackItemsComponent,
        ClipTileComponent,
        ListTitleComponent,
        InputComponent,
        ButtonComponent,
        EllipsisButtonComponent,
        PageTitleComponent,
        LoginFormComponent,
        FormTextareaComponent,
        FormItemComponent,
        FormInputComponent,
        FormToggleComponent,
        FormRadioComponent,
        FormSelectComponent,
        UploaderStatsComponent,
        SearchbarComponent,
        PopOverComponent,
        BaseModalComponent,
        ThumbnailPosterComponent,
        ModalReportComponent,
        SplitLayoutComponent,
        TermsPolicyModalsComponent,
        ModalInviteSuccessComponent,
        SegmentComponent,
        SegmentButtonComponent,
        TabFabContainerComponent,
    ],
    // providers: [FilterTextService],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        IonicModule,
        // CollapseDirective,
        // TutorialComponent,
        FooterComponent,
        IconListComponent,
        PromoCardComponent,
        SpinnerComponent,
        // FilterTextComponent,
        // InitCapsPipe,
        ActionPopoverComponent,
        FilterableToolbarComponent,
        ...fromComponents.components,
        ...fromDirectives.directives,
        ...fromPipes.pipes,
        ClipPopoverActionsComponent,
        ListTitleComponent,
        InputComponent,
        ButtonComponent,
        EllipsisButtonComponent,
        PageTitleComponent,
        FormItemComponent,
        FormInputComponent,
        FormTextareaComponent,
        FormToggleComponent,
        FormRadioComponent,
        FormSelectComponent,
        LoginFormComponent,
        UploaderStatsComponent,
        PopOverComponent,
        BaseModalComponent,
        SplitLayoutComponent,
        StackPopoverActionsComponent,
        ThumbnailPosterComponent,
        TermsPolicyModalsComponent,
        SearchbarComponent,
        ModalInviteSuccessComponent,
        SegmentComponent,
        SegmentButtonComponent,
        TabFabContainerComponent,
    ],
    // added to support swiper.js per https://ionicframework.com/docs/angular/slides
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
