/** @format */

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { DEFAULT_USER_AVATAR_TERTIARY, User } from '@shared/models/user.model';
import { Store } from '@ngrx/store';
import { selectCurrentMemberId } from '@store/selectors/members.selectors';
import { DiscoverCardClick, ITEM_TYPE } from '@shared/models/layout.model';

@Component({
  selector: 'app-user-slide',
  templateUrl: './user-slide.component.html',
  styleUrls: ['./user-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // doesn't work with the currentUsername changing
})
export class UserSlideComponent {
  @Input() item: User;
  // @Input() currentUsername: string;

  @Output() itemClick = new EventEmitter<DiscoverCardClick>();

  currentMemberId$ = this.store.select(selectCurrentMemberId);

  showProjects = false;
  avatarFallback = DEFAULT_USER_AVATAR_TERTIARY;

  constructor(private store: Store) {}

  onClick(event, user: User) {
    this.itemClick.emit({ event, item: user, itemType: ITEM_TYPE.Users });
  }
}
