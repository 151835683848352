/** @format */

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AlertController, IonItemSliding } from '@ionic/angular';
import { ClipsCoreService } from '@services/clips.service';
import { ToasterService } from '@services/toaster.service';
import { SentryService } from '@services/analytics/sentry.service';
import { Clip, DEFAULT_POSTER } from '@shared/models/clip.model';
import { Utils } from '@shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { StackEditComponent } from '@stacks/stack-edit/stack-edit.component';

const DEBUG_LOGS = false;
const PAGE = '[MyStackCaptureItem]';

@Component({
  selector: 'app-my-stack-capture-item',
  templateUrl: './my-stack-capture-item.component.html',
  styleUrls: ['./my-stack-capture-item.component.scss'],
})
export class MyStackCaptureItemComponent implements OnInit, OnChanges {
  @Input() isPreviewOpen: boolean = false;
  @Input() slidingItem: IonItemSliding;
  @Input() clip: Clip;
  @Input() currentIndex: number;
  @Input() index: number = 0;

  @Output() deleteClipIndex = new EventEmitter<number>();

  poster: string = DEFAULT_POSTER;
  dateFilmed: string = '';

  progressType: 'determinate' | 'indeterminate' = 'indeterminate';
  progressValue: number = 0;
  progressBuffer: number = 0; // setting it to 0 makes the buffer animation be the background
  loadingStatus: string = 'Transcoding...';
  posterText: string = 'Processing';

  transcodingCheckTimedout = false;
  transcodingCheckClicked = false;
  progressBarColor = 'tertiary';

  constructor(
    private stackEditComponent: StackEditComponent,
    private alertCtrl: AlertController,
    private toaster: ToasterService,
    private clipService: ClipsCoreService,
    private translate: TranslateService,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    DEBUG_LOGS && console.log(`${PAGE} OnInit`, this.clip);
    this.poster = this.clipService.getPoster(this.clip);
  }

  /**
   * Watch the Clip for changes
   * specifically the hlsMeta prop
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.clip && changes.clip.currentValue) {
      // && !changes.clip.firstChange
      // watching the first also, to allow init with partially transcoded clip
      // console.log(`ngOnChanges clip:`, changes.clip);
      DEBUG_LOGS &&
        console.log(`watching hlsMeta & sources:`, {
          hlsMeta: changes.clip.currentValue.hlsMeta,
          hlsSrc: changes.clip.currentValue.hlsSrc,
          sources: changes.clip.currentValue.sources, // upon this array having a length, mystack template will change to an item rather than capture-item..
        });
      if (changes.clip.currentValue.hlsMeta) {
        const hlsMeta = Utils.tryParseJSON(changes.clip.currentValue.hlsMeta) || {};

        if (hlsMeta.errorMessage) {
          try {
            // updated  2022-08-18 to get a better error
            const msg =
              typeof hlsMeta.errorMessage !== 'string' ? Utils.objToString(hlsMeta.errorMessage) : hlsMeta.errorMessage;
            this.loadingStatus = `${this.translate.instant('ERRORS.UH_OH')} ${msg.substring(0, 90)}`;
          } catch (error) {
            console.warn(`We had an issue getting the error message!`, error);
            this.sentryService.captureError(error);
            this.loadingStatus = this.translate.instant('ERRORS.UNKNOWN');
          }
          this.progressType = 'determinate';
          // try a page refresh to auto-correct
          if (window && window.location && typeof window.location.reload === 'function') {
            window.location.reload();
          }
        } else if (hlsMeta.status === 'COMPLETE') {
          this.loadingStatus = this.translate.instant('EDITOR.COMPLETE');
          this.progressType = 'determinate';
          try {
            // upon transcode complete -> save stack ... is this caught in all cases?
            console.warn(`Dev: Tanscode complete -> save stack ... is this caught in all cases?`);
            this.stackEditComponent.saveStack();
          } catch (error) {
            console.warn(`Caught & ignored udring save:`, error);
          }
        } else if (hlsMeta.percentComplete) {
          // give a specific status which might be TRANSCODING or FINISHING (note all caps)
          const currentStatus = hlsMeta.status ? Utils.titleCase(hlsMeta.status) : 'Transcoding';
          this.loadingStatus = `${currentStatus}: ${hlsMeta.percentComplete}% complete`;
        } else if (hlsMeta.hls === true) {
          // this was the request we sent..
        } else if (!changes.clip.firstChange) {
          console.warn(`hlsMeta unhanded?`, hlsMeta);
        }
      }
    }
  }

  onClickItem() {
    this.closeSlider();
  }

  async deleteClip(event) {
    Utils.tryStopPropagation(event);
    await this.confirmDelete();
    this.closeSlider();
  }

  delete() {
    this.deleteClipIndex.emit(this.index);

    if (this.clip && this.clip.title) {
      this.toaster.present(`${this.translate.instant('EDITOR.REMOVED')} '${this.clip.title}'`);
    }
  }

  closeSlider() {
    if (this.slidingItem && typeof this.slidingItem.close === 'function') {
      this.slidingItem.close();
    }
  }

  async confirmDelete() {
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant('EDITOR.CLIP_TRANSCODING'),
      message: this.translate.instant('EDITOR.CONFIRM_REMOVE'),
      buttons: [
        {
          text: this.translate.instant('COMMON.CANCEL'),
          handler: () => {
            // console.log('Cancel, do nothing');
          },
        },
        {
          text: this.translate.instant('EDITOR.REMOVE'),
          handler: () => {
            this.delete();
          },
        },
      ],
    });
    return await confirm.present();
  }

  /**
   * @deprectaed to be removed
   */
  waitForTranscodeComplete() {
    this.clipService
      .waitForClipTranscoded(this.clip)
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} clipTranscoded res:`, res);
        this.loadingStatus = this.translate.instant('EDITOR.DONE');
        this.progressType = 'indeterminate';
        setTimeout(() => {
          this.transcodingCheckClicked = false;
          this.clip.source.uploading = false; // this should kick the component back to standard non-capture view?
          this.clipService.updateClipStore(res);
        }, 1000);
      })
      .catch((err) => {
        console.info(`${PAGE} Caught:`, err.message);
        this.loadingStatus = this.translate.instant('EDITOR.TRY_AGAIN');
        this.progressType = 'determinate';
        this.transcodingCheckTimedout = true;
        this.transcodingCheckClicked = false;
      });
  }

  /**
   * @deprectaed to be removed
   */
  checkTranscodeComplete(event) {
    Utils.tryStopPropagation(event);
    this.transcodingCheckClicked = true;
    this.progressType = 'indeterminate';
    this.loadingStatus = this.translate.instant('EDITOR.WAITING');
    this.waitForTranscodeComplete();
  }
}
