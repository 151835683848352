import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// had to import these for ngx-chips, per: https://github.com/Gbuomprisco/ngx-chips/issues/607
import { ALL_TAGS } from './tags';

const DEBUG_LOGS = false;
const PAGE = "[TaggerInput]";

@Component({
  selector: 'app-tagger-input',
  templateUrl: './tagger-input.component.html',
  styleUrls: ['./tagger-input.component.scss'],
})
export class TaggerInputComponent implements OnInit {

  @Input() id: string;
  @Input() heading: string;
  @Input() 
  set placeholderType(val) {
    // console.log(`set placeholderType`, val)
    if (val) {
      this._placeholderType = val;
      this.placeholder = `+ ${val} (then &crarr; or ,)`;
      this.initialPlaceholder = `Add ${val}s (then &crarr; or ,)`;
    }
  }
  get placeholderType(): string {
    return this._placeholderType || 'Tag'; 
  }
  
  @Input() placeholder: string = `+ ${this.placeholderType} (Enter,tab,comma)`;
  /**
   * String that sets the placeholder of the input for entering new terms when there are 0 items entered.
   */
  @Input() initialPlaceholder: string = `Add ${this.placeholderType}s (Enter, tab, comma)`;

  @Input() items: string[] = [];// https://github.com/Gbuomprisco/ngx-chips#using-an-array-of-objects
  @Input() autocompleteItems: string[] = [];// https://github.com/Gbuomprisco/ngx-chips#using-an-array-of-objects

  @Output() tagSelected = new EventEmitter<string>();
  @Output() tagRemoved = new EventEmitter<string>();
  @Output() tagAdded = new EventEmitter<string>();

  /**
   * appendToBody
   * If set to false, the dropdown will not be appended to the body, 
   * but will remain in its parent element. Useful when using the components inside popups or dropdowns. Defaults to true.
   */
  appendToBody: boolean = true; // @todo: is the flex center-xy causing issues here?

  private _placeholderType: string = "Tag";
  
  constructor() {
    DEBUG_LOGS && console.log(`${PAGE} init`);//id is undefined still...
  }

  // ngOnInit() {
  //   console.log(`${PAGE} init id=${this.id}`);//input is avail here, not in construct
  // }

  // getTagsFromMultiTagItems() {
  //   return this.multiTagItems.reduce((prev, curr) => {
  //     return [...prev, curr.display];
  //   }, []);
  // }

  onTagSelected(item) {
    DEBUG_LOGS && console.log(`${PAGE} onTagSelected: %o`,item);
    this.tagSelected.emit(item);
  }

  onTagRemoved(item) {
      DEBUG_LOGS && console.log(`${PAGE} onTagRemoved: %o`,item);
      this.tagRemoved.emit(item);
  }

  onTagAdded(item) {
    DEBUG_LOGS && console.log(`${PAGE} onTagAdded: %o`,item);
    this.tagAdded.emit(item.value);
  }

  ngOnInit() {
    const allTags = ALL_TAGS;
    // todo: check the timing here, does the input exist with project.tags at OnInit?
    allTags.map(str => {
      if (!this.autocompleteItems.some(e => e === str)) {
        // dne
        const pusher: string = str;
        this.autocompleteItems.push(pusher);
      }
    });
  }
}

