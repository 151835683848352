<fs-base-modal mode="light">
  <div class="col">
    <fs-project-member
      [userId]="userId"
      [projects]="projects"
      [viewOnly]="viewOnly"
      [isStudio]="isStudio"
      [showRecentStacks]="showRecentStacks"
      (dismiss)="dismiss()"
    ></fs-project-member>

    <div class="col ion-text-center ion-padding ion-margin-top">
      <ion-button color="primary" fill="clear" size="medium" (click)="dismiss()">
        {{ 'COMMON.DONE' | translate }}
      </ion-button>
    </div>
  </div>

</fs-base-modal>
