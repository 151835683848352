<fs-list-title *ngIf="showTitle" 
  icon="people-outline" 
  [list]="userList" class="user-carousel__title dark no-top-margin left-margin"
>{{ titleTranslateKey | translate }}</fs-list-title>
<!-- style="display:block;margin-right:80px;" -->
<fs-carousel
  [items]="users"
  [itemType]=""
  [canLoadMore]="false"
  [isLoadingMore]="false"
  [itemType]="itemType"
  [currentUsername]="currentUserId"
  [heading]="''"
  [options]="{}"
  [isProjectMember]="true"
  class="user-carousel dark"
  (itemClick)="onClickItem($event)"
></fs-carousel>
<!-- 
  (itemClick)="onClickItem($event)"
  (loadMore)="loadMore.emit($event)"
  (newItemClick)="addNewStackButtonClick()" 
-->
