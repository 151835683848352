import { NgModule } from "@angular/core";
import { CollapseDirective } from "./collapse.directive";
import { ImageFadeInDirective } from "./image-fade-in.directive";
import { ImageFallbackDirective } from "./image-fallback.directive";
import { SelectInputOnClickDirective } from "./select-input-on-click.directive";

@NgModule({
  imports: [],
  declarations: [
    CollapseDirective, 
    ImageFadeInDirective, 
    ImageFallbackDirective,
    SelectInputOnClickDirective
  ],
  exports: [
    CollapseDirective, 
    ImageFadeInDirective, 
    ImageFallbackDirective,
    SelectInputOnClickDirective
  ],
})
export class DirectivesModule {}
