/** @format */

import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { IonReorderGroup, AlertController, ModalController, ItemReorderCustomEvent } from '@ionic/angular';
import { IonItemSliding } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from '@services/events.service';
import { ToasterService } from '@services/toaster.service';
import { MyStackService } from '@services/mystack.service';
import { Clip } from '@shared/models/clip.model';
import { Utils } from '@shared/utils';
import { UserService } from '@services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { SHARING_METHOD, SocialSharingService, socialSharingServiceProvider } from '@app/core/social-sharing';
import { Stack } from '@shared/models/stack.model';
import { ITEM_TYPE } from '@shared/models/layout.model';
import { CollabModalComponent } from '@stacks/shared/components';
import { StackService } from '@stacks/shared/services';
import { WATCH_ROUTE } from '@app/app-routing.module';
import { TRANSLATE_ROOT } from '@onboarding/shared/onboarding.model';
import { ConfigService } from '@app/core/config';

const DEBUG_LOGS = false;
/**
 * Scroll to the current item when playing
 * Does work, but disabling due to poor UX if you are editing while it's playing
 */
const ENABLE_SCROLL_TO_ITEM = false;
/** the px height of each item, for scrollTop calc */
const ITEM_EL_HEIGHT = 78;
const PAGE = '[MyStack]';

@Component({
  selector: 'app-my-stack',
  templateUrl: './my-stack.component.html',
  styleUrls: ['./my-stack.component.scss'],
  providers: [socialSharingServiceProvider],
})
export class MyStackComponent implements OnInit, OnDestroy {
  @Input() stack: Stack;
  @Input() userId: string = '';
  @Input() isSaving: boolean = false;
  @Input() isMine: boolean = false;
  @Input() isCollaborative: boolean = false;
  @Input() openInvite: boolean = false;
  @Input() canCollaborate: boolean = false;
  @Input() isPreviewOpen: boolean = false;
  @Input() isVideoPlaying: boolean = false;
  @Input() disableReorder: boolean = false;
  @Input() playerId: string = 'my-stack-player';
  @Input() scrollContainer: ElementRef;

  @Input() //clips: Clip[];
  set clips(val: Clip[]) {
    DEBUG_LOGS && console.log(`${PAGE} clips updated:`, val);
    if (Array.isArray(val)) {
      this._clips = val.filter((clip) => clip && clip.id && clip.projectId);
      this.items = this._clips;
    }
  }
  get clips() {
    return this._clips;
  }

  @Input() // currentIndex: number;
  set currentIndex(val: number) {
    DEBUG_LOGS && console.log(`${PAGE} currentIndex updated:`, val);
    this._currentIndex = val;
    this.scrollToItem(val);
  }
  get currentIndex() {
    return this._currentIndex;
  }

  @Output() playClipIndex = new EventEmitter<number>();
  @Output() pauseClipIndex = new EventEmitter<number>();
  @Output() closePreviewPlayer = new EventEmitter<boolean>();
  @Output() continuePublish = new EventEmitter<boolean>();
  @Output() saveStack = new EventEmitter<void>();
  @Output() reStack = new EventEmitter<void>();
  @Output() newStack = new EventEmitter<void>();
  @Output() acceptInvite = new EventEmitter<void>();
  @Output() addClips = new EventEmitter<void>();

  /** build the url onInit based on appConfig */
  collabShareUrl = window?.location?.href ?? '';

  showingQr = false;
  watchRoute = '/' + WATCH_ROUTE;
  translatePath = TRANSLATE_ROOT;

  /**
   * The actual items to reorder, which should be different from the clips Input
   */
  public items: Clip[] = [];

  /** Saving the isAdmin to pass to children (HLS Transcode MVP-742) */
  isAdmin = false;

  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;

  // not used for now, passing isMine & isCollaborative as Inputs
  // stack$ = this.store.select(selectMyStack);

  private _clips: Clip[] = [];
  private _currentIndex: number = 0;
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private userService: UserService,
    private mystackService: MyStackService,
    private toaster: ToasterService,
    private modalCtrl: ModalController,
    private stackService: StackService,
    private socialSharingService: SocialSharingService,
    private configService: ConfigService,
    private events: EventsService
  ) {}

  ngOnInit() {
    /**
     * Check currentUser Cognito Groups for Admin access to platform
     */
    this.userService.userIsGlobalAdmin$
      .pipe(
        takeUntil(this.onDestroy$)
        // filter((isAdmin) => isAdmin)
      )
      .subscribe((isAdmin) => {
        // console.log(`sub userGroups isAdmin:`, { isAdmin, stack: this.clip });
        this.isAdmin = !!isAdmin;
      });

    setTimeout(() => {
      // wait for stack to exist..
      this.calcCollabShareUrl();
    }, 900);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async calcCollabShareUrl() {
    const appConfig = await this.configService.appConfig;
    // will the stack alwaysd exist here?
    DEBUG_LOGS && console.log('appConfig', { appConfig, stack: this.stack });
    this.collabShareUrl =
      this.stack?.stackId && this.stack?.isCollaborative && !this.stack?.dtePublished && appConfig.stackShareUrl
        ? `${appConfig.stackShareUrl}${this.stack.projectId}/${this.stack.stackId}`
        : window?.location?.href ?? '';
    //return this.stack?.stackId ? `${host}/${EDIT_ROUTE}/${this.stack.stackId}` : '';
  }

  /**
   * move index to top - one of the items just emitted
   */
  onMoveTop(index: number) {
    const clips = [...this.clips];
    // arrayMove(arr, fromIndex, toIndex): in-place arr
    Utils.arrayMove(clips, index, 0);
    console.log(`onMoveTop`, { clips, fromIndex: index, toIndex: 0 });
    this.mystackService.reorderClips(clips);
  }
  onMoveBottom(index: number) {
    const clips = [...this.clips];
    // arrayMove(arr, fromIndex, toIndex): in-place arr
    Utils.arrayMove(clips, index, clips.length - 1);
    console.log(`onMoveBottom`, { clips, fromIndex: index, toIndex: clips.length - 1 });
    this.mystackService.reorderClips(clips);
  }

  onPlayClipIndex(index: number) {
    DEBUG_LOGS && console.log(`${PAGE} onPlayClipIndex [${index}]`);
    this.playClipIndex.emit(index);
  }

  onPauseClipIndex(index: number) {
    DEBUG_LOGS && console.log(`${PAGE} onPauseClipIndex [${index}]`);
    this.pauseClipIndex.emit(index);
  }

  deleteClip(slidingItem: IonItemSliding, index: number, clip: Clip) {
    DEBUG_LOGS && console.log(`${PAGE} deleteClip [${index}]`);
    this.deleteClipByIndex(index);
    slidingItem.close();
    this.toaster.present(`Removed '${clip.title}' from Editor`);
  }

  deleteClipByIndex(index: number) {
    if (typeof index === 'number' && index >= 0) {
      DEBUG_LOGS && console.log(`${PAGE} deleteClipByIndex: ${index}`);
      this.mystackService.removeClipByIndex(index);
    } else {
      console.warn(`${PAGE} deleteClipByIndex !number?`, index);
    }
  }

  sortByTitle() {
    try {
      this.mystackService.sortByTitle(this.clips);
    } catch (error) {
      console.warn(error);
      this.toaster.present(`Uh oh! We ran into an issue - please try again.`);
    }
  }
  sortDate() {
    try {
      this.mystackService.sortDate(this.clips);
    } catch (error) {
      console.warn(error);
      this.toaster.present(`Uh oh! We ran into an issue - please try again.`);
    }
  }

  // trimClip(slidingItem: IonItemSliding, index: number, clip: Clip) {
  //   console.log(`${PAGE} TODO: trimClip [${index}]`, clip);
  //   slidingItem.close();
  //   // this.toaster.present('Trim Clip Feature Coming Soon...');
  // }

  clearStack() {
    this.confirmClearStack();
  }

  async confirmClearStack() {
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant('EDITOR.CLEAR_EDITOR'),
      message: this.translate.instant('EDITOR.CLEAR_EDITOR_MESSAGE'),
      cssClass: 'confirm-alert',
      buttons: [
        {
          text: this.translate.instant('COMMON.CANCEL'),
          cssClass: 'alert-button-cancel',
          handler: () => {
            // console.log('Cancel, do nothing');
          },
        },
        {
          text: this.translate.instant('EDITOR.CLEAR_CLIPS'), //'Clear Clips',
          cssClass: 'alert-button-warning',
          handler: () => {
            DEBUG_LOGS && console.log('Ok, it gone. bye bye.');
            this.mystackService.clearAllClips();
            this.closePreviewPlayer.emit(true);
          },
        },
      ],
    });
    return await confirm.present();
  }

  trackByStackItem(index: number, obj: Clip): string {
    if (!obj || !obj.id) {
      return `${Math.floor(Math.random() * 1000)}`; // random number between 0 and 1000
    }

    if (obj.projectId) {
      return `${obj.projectId}/${obj.id}`;
    } else {
      return `${Math.floor(Math.random() * 100)}/${obj.id}`;
    }
  }

  /**
   *  Ionic Reorder (https://ionicframework.com/docs/api/reorder)
   */
  doReorder(ev: ItemReorderCustomEvent) {
    if (!ev || !ev.detail || typeof ev.detail.from !== 'number' || typeof ev.detail.to !== 'number') {
      console.warn(`${PAGE} doReorder event not correct - missing from/to`, ev);
      return;
    }
    // MVP-914 When the user interacts with the clips (dragging, etc) the preview window should close automatically
    // and pause..
    this.pauseClipIndex.emit(this.currentIndex); // this calls pauseClipIndev
    // this.closePreviewPlayer.emit(true);

    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    DEBUG_LOGS && console.log(`Dragged from index '${ev.detail.from}' to '${ev.detail.to}'`);

    // Before complete is called with the items they will remain in the
    // order before the drag
    // console.log('Before complete', this.items);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    this.items = ev.detail.complete(this.items);

    // After complete is called the items will be in the new order
    // console.log('After complete', this.items);

    // const changeEvt: ReorderStackClips = {
    //   from: ev.detail.from,
    //   to: ev.detail.to,
    //   clips: this.items,
    // };
    this.mystackService.reorderClips(this.items);
  }

  toggleReorderGroup() {
    this.reorderGroup.disabled = !this.reorderGroup.disabled;
  }

  scrollToItem(index: number) {
    if (!ENABLE_SCROLL_TO_ITEM) return;
    if (!this.scrollContainer) {
      console.log(`${PAGE} scrollToItem missing scrollContainer`, this.scrollContainer);
      return;
    }
    // const els = document.querySelectorAll(`[data-index='${index}']`);
    try {
      const scrollSpeed = 1100;
      // subtract 1 to keep one played item above, instead of current at top
      const scrollTop = index === 0 ? 0 : (index - 1) * ITEM_EL_HEIGHT;
      Utils.smoothScrollTo(this.scrollContainer, scrollTop, scrollSpeed);
    } catch (error) {
      console.error('Scroll to item failed', error);
    }
  }

  copyLink() {
    this.socialSharingService
      .shareTo(
        {
          id: this.stack.stackId,
          title: this.stack.title,
          description: this.stack.description,
          shareUrl: this.collabShareUrl,
          // tags: ['CreateTogether'],
          type: ITEM_TYPE.Stacks,
        },
        SHARING_METHOD.CLIPBOARD
      )
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async inviteCrew() {
    const modal = await this.modalCtrl.create({
      component: CollabModalComponent,
      componentProps: {
        stack: this.stack,
      },
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(`Modal dismissed`, data);
    // if (data) // if true, it was done... do we care?
  }

  removeCrew() {
    this.stackService.removeCollaborative(this.stack.projectId, this.stack.stackId);
  }
}
