/** @format */

import { Component, Input } from '@angular/core';
import { Stack } from '@shared/models/stack.model';
import { ItemReorderCustomEvent, LoadingController, NavController } from '@ionic/angular';
import { StackService } from '@stacks/shared/services';
import { DraggableList, ListOptions } from '../fs-list.component';
import { WATCH_ROUTE } from '@app/app-routing.module';

const DEBUG_LOGS = false;
const PAGE = '[ListStackItems]';

@Component({
  selector: 'app-list-stack-items',
  templateUrl: './list-stack-items.component.html',
  styleUrls: ['./list-stack-items.component.scss'],
})
export class ListStackItemsComponent implements DraggableList {
  @Input() items: Stack[];
  @Input() options: ListOptions;

  constructor(
    private stackService: StackService,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController
  ) {}

  trackByItem(index: number, item: Stack) {
    return item?.stackId ?? index;
  }

  open(item: Stack) {
    this.navCtrl.navigateForward(`/${WATCH_ROUTE}/${item.projectId}/${item.stackId}`);
  }

  /**
   * reorder v2 - featured is descending (larger = more featured)
   * if dragging up, make featured 1 more than the next
   * if dragging down, make featured 1 less than the previous
   */
  async reorder(event: Event) {
    const customEvent = event as ItemReorderCustomEvent;
    const loading = await this.loadingCtrl.create({
      message: 'Updating stacks...',
    });
    await loading.present();
    customEvent.detail.complete();
    // eslint-disable-next-line prefer-const
    let { from, to } = customEvent.detail;

    // if the item was dragged past the bottom of the list, 'to' will be ==items.length (outside array indexes)
    if (to >= this.items.length) {
      if (from === to - 1) {
        // we just dragged the last one and didn't change the value
        DEBUG_LOGS && console.warn(`${PAGE} moved past the end!`, { to, from, oldLen: this.items.length });
        loading.dismiss();
        return;
      }
      to = this.items.length - 1; // make it the last one in the array
    }

    const thisStack = this.items[from];

    const items = [...this.items];
    [items[from], items[to]] = [items[to], items[from]];

    /**
     * find the featured value of item we dragged past in list,
     * if dragging up, make featured 1 more than the next
     * if dragging down, make featured 1 less than the previous
     * or if last, make it a 1
     */
    let newFeatured = 1;
    if (from > to) {
      // moved up list
      const checkIndex = to + 1;
      newFeatured = items[checkIndex].featured + 1;
      DEBUG_LOGS &&
        console.log(`${PAGE} moved up list`, { to, checkIndex, newFeatured, title: items[checkIndex].title });
    } else {
      // moved down list
      const checkIndex = to - 1;
      DEBUG_LOGS && console.log(`${PAGE} checkIndex`, { checkIndex, len: items.length, items });
      if (checkIndex >= items.length - 1) {
        newFeatured = 1;
        DEBUG_LOGS && console.log(`${PAGE} moved down list to bottom, make 1`);
      } else {
        newFeatured = items[checkIndex].featured - 1;
        DEBUG_LOGS &&
          console.log(`${PAGE} moved down list`, { to, checkIndex, newFeatured, title: items[checkIndex].title });
      }
    }
    if (newFeatured <= 0) {
      newFeatured = 1;
    }

    // await this.stacksService.updateStack(thisStack, [
    //   { prop: 'featured', value: newFeatured },
    // ]);
    this.stackService.setStackFeatured({
      projectId: thisStack.projectId,
      stackId: thisStack.stackId,
      featured: newFeatured,
      fromIdx: from,
      toIdx: to,
    });

    // reload stacks - not needed, since we're subscribed to them from teh store, they will be updated automatically
    loading.dismiss();
  }

  async toggleFeatured(item: Stack) {
    // const loading = await this.loadingCtrl.create({
    //   message: 'Updating stacks...',
    // });
    // await loading.present();
    // v1: const featuredItemsLength = this.items.filter((i) => i.featured > 0).length; // just make it feature = 1
    // await this.stacksService.updateStack(item, [
    //   {
    //     prop: 'featured',
    //     // if the stack has a number value for featured > 0, make it zero, else 1
    //     value: typeof item.featured === 'number' && item.featured > 0 ? 0 : 1,
    //   },
    // ]);
    this.stackService.setStackFeatured({
      projectId: item.projectId,
      stackId: item.stackId,
      featured: typeof item.featured === 'number' && item.featured > 0 ? 0 : 1,
    });
    // reload stacks - not needed, since we're subscribed to them from the store, they will be updated automatically
    // loading.dismiss();
  }
}
