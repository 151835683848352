<ng-template #selectedPriceTpl >
  <ion-item lines="none ion-padding-bottom">
    <ion-label class="ion-text-capitalize" style="white-space: wrap;">
      {{ selectedPriceName }}
    </ion-label>
    <ion-note slot="end" color="light">{{ costUnit }}{{ selectedCost }}<span>/{{ selectedPriceUnit }}</span></ion-note>
  </ion-item>
</ng-template>

<ng-template #stepperActions 
  let-showPrevRoute="showPrevRoute" 
  let-showPrev="showPrev" 
  let-showNext="showNext" 
  let-disableNext="disableNext" 
  let-showCheckoutBtn="showCheckoutBtn">
  <div class="stepper-actions">
    <ng-template #spacerDiv>
      <div></div>
    </ng-template>
    <ion-button *ngIf="showPrevRoute && !showPrev" fill="clear" class="action-left" (click)="routeBack()">
      {{ 'COMMON.BACK' | translate }}
      <ion-icon slot="start" name="arrow-back"></ion-icon>
    </ion-button>
    <ion-button *ngIf="showPrev else spacerDiv" fill="clear" class="action-left" (click)="backSlide()">
      {{ 'COMMON.BACK' | translate }}
      <ion-icon slot="start" name="arrow-back"></ion-icon>
    </ion-button>
    <ion-button *ngIf="showNext else spacerDiv" 
      fill="solid" size="large" class="action-right" 
      [disabled]="disableNext"
      (click)="nextSlide()">
      {{ 'COMMON.NEXT' | translate }}
      <ion-icon slot="end" name="arrow-forward"></ion-icon>
    </ion-button>
    <div *ngIf="showCheckoutBtn" class="checkout-action">
      <fs-checkout-button 
        [disabled]="!enableBilling || (hasTiers && numMembers < 1) || isProOnProPage"
        [planId]="planId" 
        [priceId]="selectedPriceId" 
        [referralCode]="referralCode" 
        [routeId]="eventType"
        [projectId]="selectedProjectId$ | async"
        [projectTitle]="selectedProject?.title"
        [eventDate]="selectedEventDate"
        (errored)="signupErrored($event)"
        (completed)="signupCompleted($event)"></fs-checkout-button>
    </div>
  </div>
</ng-template>

<ng-template #loadingTpl>
  <div class="loading-wrap">
    <ion-spinner></ion-spinner> {{ 'COMMON.LOADING' | translate}}...
  </div>
</ng-template>

<div *ngIf="(plansLoaded$ | async) === true; else loadingTpl" class="stepper-wrap">
  <!-- autoHeight causing slider to not show.. [autoHeight]="true" [updateAutoHeight]="600" -->
  <swiper-container #swiper [allowTouchMove]="false" [allowSlideNext]="allowSlideNext" [allowSlidePrev]="allowSlidePrev"  [modules]="swiperModules" (init)="initSlider($event)">
    <!-- 
      TODO: Handle Tiers, if desired - not fully implemented..
    -->
    <swiper-slide *ngIf="!isProPlan && hasTiers">
      <p>
        {{ translatePath + 'SIGNUP_BODY_TIERED' | translate }}
      </p>
      <ion-item lines="none" class="tiered-item">
        <ion-label position="stacked" class="ion-text-wrap">{{ translatePath + 'SIGNUP_NUM_MEMBERS_PLACEHOLDER' | translate }}: <span class="bold">{{ numMembers }}</span></ion-label>
        <!-- <ion-label position="stacked" class="ion-text-capitalize">{{ 'COMMON.MINUTES' | translate }}</ion-label> -->
        <ion-range 
          [ticks]="true" 
          [snaps]="true" 
          [min]="1" 
          [max]="5" 
          [pin]="true" 
          [value]="selectedRangeTier"
          debounce="100" 
          [pinFormatter]="pinFormatter" 
          (ionChange)="onRangeChange($event)"
        ></ion-range>
    
        <ion-note slot="end" color="light">{{ costUnit }}{{ cost }}</ion-note>
      </ion-item>
      <p [innerHTML]="translatePath + 'SIGNUP_BODY_PRICING' | translate" class="ion-padding-top"></p>
      <ng-container 
        *ngTemplateOutlet="stepperActions;context:{ showPrevRoute: true, showNext: true }">
      </ng-container>
    </swiper-slide>

    <swiper-slide *ngIf="planPrices?.length > 1">
      <ion-list *ngIf="!isProPlan || !isActivePro else alreadyActiveProTpl" lines="none" class="ion-padding-bottom ion-margin-bottom">
        <ion-radio-group 
          [value]="selectedPriceId"
          (ionChange)="updatePricing($event)"
          class="pricing__checkbox"
        >
          <ion-list-header>
            <ion-label class="pricing__checkbox__title">
              {{ planTitle }}
            </ion-label>
          </ion-list-header>
      
          <ion-item *ngFor="let price of planPrices; index as i;">
            <ion-radio label-placement="end" [value]="price.id" class="ion-text-wrap">
              <div class="pricing__checkbox__text">
                {{ price?.name }}
                <ion-note color="light">{{ costUnit }}{{ price?.price }}<span>/{{ price?.periodUnit }}</span></ion-note>
              </div>
            </ion-radio>
            <!-- aria-label="{{ price?.name ?? 'Plan' }}" -->
            <!-- <ion-note slot="end" color="light">{{ costUnit }}{{ price?.price }}<span>/{{ price?.periodUnit }}</span></ion-note> -->
          </ion-item>
        </ion-radio-group>
      </ion-list>

      <div class="action-spacer"></div>

      <ng-container 
        *ngTemplateOutlet="stepperActions;context:{ showPrevRoute: true, showPrev: hasTiers, showNext: !(isProPlan && isActivePro) }">
      </ng-container>
    </swiper-slide>

    <swiper-slide *ngIf="!isProPlan">
      <ng-container *ngTemplateOutlet="selectedPriceTpl"></ng-container>
      <ion-grid class="ion-padding-top">
        <ion-row class="ion-align-items-center">
          <ng-container *ngIf="(myProjectsAdminLength$ | async) > 0">
            <ion-col size="12" size-sm="6" style="margin-top:-22px">
              {{ 'ONBOARDING.COMMON.SELECT_EXISTING_PROJECT' | translate }}
              <app-form-select-myprojects
                [defaultProjectId]="defaultProjectId"
                [onlyAdmin]="true"
                [showLabel]="false"
                (selected)="projectChanged($event)"
              ></app-form-select-myprojects>
            </ion-col>
            <ion-col size="12" size-sm="2" class="italic ion-padding-horizontal ion-text-center">
              {{ 'COMMON.OR' | translate | uppercase }}
            </ion-col>
          </ng-container>
          <ion-col size="12" size-sm="4">
            <ion-button [fill]="(myProjectsAdminLength$ | async) > 0 ? 'outline' : 'solid'" (click)="createProject()">
              {{ 'ONBOARDING.COMMON.CREATE_PROJECT' | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p *ngIf="selectedProjectHasActiveEvent" class="orange bold ion-text-center">
              "{{ selectedProject?.title }}" {{ 'ONBOARDING.COMMON.PROJECT_HAS_EVENT' | translate }}
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="action-spacer double-height"></div>
      <ng-container 
        *ngTemplateOutlet="stepperActions;context:{ disableNext: selectedProjectHasActiveEvent || !selectedProject?.id, showPrevRoute: true, showPrev: planPrices?.length > 1 || hasTiers, showNext: true }">
      </ng-container>
    </swiper-slide>

    <!-- only weddings need an event date atm -->
    <swiper-slide *ngIf="isWeddingRoute">
      <ng-container *ngTemplateOutlet="selectedPriceTpl"></ng-container>

      <h3>{{ 'ONBOARDING.COMMON.EVENT_DATE' | translate }}</h3>
      <ion-datetime-button datetime="selEventDate"></ion-datetime-button>
      <!-- see modal outside of container -->

      <div class="action-spacer double-height"></div>
      <ng-container 
        *ngTemplateOutlet="stepperActions;context:{ showPrevRoute: true, showPrev: true, showNext: true }">
      </ng-container>
    </swiper-slide>
    
    <ng-container *ngIf="!isProOnProPage">
      <swiper-slide class="token-slide">
        <ion-item lines="none ion-padding-bottom">
          <ion-label class="ion-text-capitalize" style="white-space: wrap;">
            {{ selectedPriceName }}
          </ion-label>
          <ion-note slot="end" color="light">{{ costUnit }}{{ selectedCost }}<span>/{{ selectedPriceUnit }}</span></ion-note>
        </ion-item>

        <h3>{{ 'AUTH.SIGNUP.SHOW_TOKEN_BUTTON' | translate }}</h3>
        <!-- no expandable button, always show..
        <fs-button
          class="show-token-button"
          buttonStyle="clear"
          color="primary"
          [disabled]="showingCodeInput"
          (click)="showingCodeInput = true"
        >
          {{ 'AUTH.SIGNUP.SHOW_TOKEN_BUTTON' | translate }}
        </fs-button> -->
  
        <div class="token-input-field">
          <fs-form-input
            [label]="'AUTH.INVITE_REFERRAL_CODE' | translate"
            type="text"
            [control]="referralCodeFormControl | formControlCast"
            (blur)="codeBlur($event)"
          ></fs-form-input>
        </div>

        <p>{{ 'AUTH.SIGNUP.TOKEN_DESCRIPTION' | translate }}</p>
  
        <div class="action-spacer"></div>
        <ng-container 
          *ngTemplateOutlet="stepperActions;context:{ showPrev: true, showNext: false, showCheckoutBtn: true }">
        </ng-container>
      </swiper-slide>
    </ng-container>
  
  </swiper-container>
  
</div>

<div *ngIf="!isProPlan" class="teams-section">
  <h3 [innerHTML]="translatePath + 'SIGNUP_OTHER_EVENT' | translate"></h3>
  <ion-button fill="clear" class="text-btn" [routerLink]="otherEventSignupRoute">{{ translatePath + 'SIGNUP_OTHER_EVENT_ACTION' | translate }}</ion-button>
</div>

<div class="teams-section event-contact">
  <h3 [innerHTML]="translatePath + 'SIGNUP_CONTACT_Q' | translate"></h3>
  <p>
    <ion-button fill="clear" class="text-btn" [routerLink]="['/contact']" [queryParams]="{topic: 'Filmstack My ' + (isWeddingRoute ? 'Wedding' : 'Event')}">{{ 'COMMON.CONTACT_US' | translate }}</ion-button>
    <span [innerHTML]="translatePath + 'SIGNUP_CONTACT' | translate"></span>
  </p>
</div>

<p class="italic disclaimer" [innerHTML]="'ONBOARDING.COMMON.DISCLAIMER' | translate"></p>

<!-- <ng-template #proPlanTpl>
  <swiper-slide>
    <ion-list *ngIf="!isActivePro else alreadyActiveProTpl" lines="none" class="ion-padding-bottom ion-margin-bottom">
      <ion-radio-group 
        [value]="selectedPriceId"
        (ionChange)="updatePricing($event)"
        class="pricing__checkbox"
      >
        <ion-list-header>
          <ion-label class="pricing__checkbox__title">
            {{ planTitle }}
          </ion-label>
        </ion-list-header>
    
        <ion-item *ngFor="let price of planPrices; index as i;">
          <ion-radio label-placement="end" [value]="price.id" class="ion-text-wrap">
            <div class="pricing__checkbox__text">
              {{ price?.name }}
              <ion-note color="light">{{ costUnit }}{{ price?.price }}<span>/{{ price?.periodUnit }}</span></ion-note>
            </div>
          </ion-radio>
        </ion-item>
    
      </ion-radio-group>
    </ion-list>
    <ng-container 
      *ngTemplateOutlet="stepperActions;context:{ showPrev: true, showNext: true }">
    </ng-container>
  </swiper-slide>
</ng-template> -->

<ng-template #alreadyActiveProTpl>
  <p class="ion-padding-vertical bold orange ion-text-center">
    {{ 'BILLING.ALREADY_PRO' | translate }}
    <fs-button size="default" (click)="editSubscription()">{{ 'AUTH.PLANS.EDIT_SUBSCRIPTION' | translate }}</fs-button>
  </p>

  <h3>{{ 'BILLING.ADD_EVENT_INSTEAD' | translate }}</h3>

  <div class="action-buttons ion-margin-bottom">
    <ion-button 
      *ngFor="let meta of metaPlanOptions"
      fill="outline" 
      [routerLink]="[meta.actionUrl, 'signup']"
    >{{ 
        meta.translatePath + 'CREATE_EVENT' | translate 
      }}</ion-button>
  </div>
</ng-template>

<ion-modal [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime id="selEventDate" 
      presentation="date" 
      [min]="minimumEventDate" 
      [value]="selectedEventDate" 
      [showDefaultButtons]="true"
      [doneText]="'COMMON.OK'  | translate" [cancelText]="'COMMON.CANCEL'  | translate"
      (ionChange)="eventDateChanged($event)">
      <span slot="title">{{ 'ONBOARDING.COMMON.SELECT_EVENT_DATE' | translate }}</span>
    </ion-datetime>
  </ng-template>
</ion-modal>

 <!-- <div class="actions skinny-limit-width">
    <fs-checkout-button 
      [disabled]="!enableBilling || (hasTiers && numMembers < 1) || isProOnProPage"
      [planId]="planId" 
      [priceId]="selectedPriceId" 
      [referralCode]="referralCode" 
      [routeId]="onboardingType"
      [projectId]="selectedProjectId$ | async"
      [projectTitle]="selectedProject?.title"
      [eventDate]="selectedEventDate"
      (errored)="signupErrored($event)"
      (completed)="signupComplete($event)"></fs-checkout-button>
  </div> -->
