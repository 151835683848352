<!-- can be wrapped in project-member-modal -->

<ng-template #loadingTpl>
  <div class="col ion-padding">
    <div class="member ion-padding">
      <ion-spinner></ion-spinner>
    </div>
  </div>  
</ng-template>
<ng-template #noUserTpl>
  <div class="col ion-padding-horizontal">
    <div class="member ion-padding-vertical">
      {{ 'USER.NOT_FOUND' | translate }}
    </div>
  </div>  
</ng-template>
<div *ngIf="selectedUser$ | async as user; else loadingTpl" class="col ion-padding-horizontal">
  <div *ngIf="user?.userId?.length > 0 else noUserTpl" class="member">
    <!-- avatar -->
    <div
      class="member__avatar"
      [style.background-image]="'url(' + avatarUrl + '), url(' + avatarFallback + ')'"
      draggable="false"
    ></div>
    <h2 class="member__name">
      {{ user.name || user.userId }}

      <ion-button *ngIf="user.bio"
        [title]="showingBio ? 'Hide Bio' : 'Show Bio'"
        fill="clear"
        size="small"
        class="member__name__caret-btn"
        (click)="showingBio = !showingBio"
      >
        <ion-icon
          slot="icon-only"
          class="member__name__caret no-pointer-events"
          name="caret-down"
          [class.member__name__caret--expanded]="showingBio"
        ></ion-icon>
      </ion-button>
      
    </h2>

    <div class="member__bio" *ngIf="user.bio && showingBio">
      <div>{{ user.bio }}</div>
    </div>

    <div class="member__stats">
      <ul>
        <li title="{{ 'COMMON.PROJECTS' | translate }}">
          <ion-icon name="folder" class="no-pointer-events"></ion-icon>
          {{ user.memberProjects?.length || 0 }}
        </li>
        <li title="{{ 'COMMON.STACKS' | translate }}">
          <ion-icon name="layers-outline" class="no-pointer-events"></ion-icon>
          {{ 
            ((recentStacks.items$ | async)?.length > 0) 
              ? (recentStacks.items$ | async).length 
              : user.stacks?.length || 0 
          }}
        </li>
        <li title="{{ 'STUDIO.CLIPS_WATCHED' | translate }}">
          <ion-icon name="play-outline" class="no-pointer-events"></ion-icon>
          {{ user.numClipsWatched || 0 }}
        </li>
      </ul>
    </div>
    <div class="member__description">
      <div *ngIf="user.location" class="member__description__content">
        <strong>{{ 'STUDIO.SETTINGS.LABEL_LOCATION' | translate }}</strong>
        <span>{{ user.location }}</span>
      </div>
      <div *ngIf="joined" class="member__description__content">
        <strong>{{ 'USER.JOINED' | translate }}</strong>
        <span>{{ joined }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="showRecentStacks && (recentStacks.items$ | async)?.length > 0" class="member__stacks">
    <fs-list-title icon="logo-buffer" style="display:block;margin-right:80px;">
      <span class="ion-hide-sm-up">{{ 'MEMBERS.RECENT_STACKS' | translate }}</span>
      <span class="ion-hide-sm-down">{{ 'MEMBERS.RECENT_STACK_STORIES' | translate }}</span>
    </fs-list-title>
    
    <div *ngFor="let stackList of [recentStacks]" class="list-wrap">
      <app-stack-list *ngIf="(stackList.list$ | async) as list" 
        [layout]="{
          type: layoutType.Carousel,
          options: {
            hasAddNewItemButton: false,
            showAvatar: false
          }
        }" 
        [items]="stackList.items$ | async" 
        [canLoadMore]="!!list.nextToken" 
        [isLoading]="list.loading"
        (loadMore)="loadMoreStacks(list)" 
        (itemClick)="dismiss.emit()"
      ></app-stack-list>
      <!-- [heading]="stackList.label"  -->
    </div>
    
  </div>

  <div class="ion-text-left ion-margin-bottom">
    <fs-list-title class="no-top-margin ion-padding-bottom" icon="folder-outline">{{ 'COMMON.PROJECTS' | translate }}</fs-list-title>
    <app-project-member-projects
      [member]="user"
      [isStudio]="isStudio"
      [viewOnly]="viewOnly"
      (closeModal)="dismiss.emit()"
    ></app-project-member-projects>
  </div>
  
</div>
