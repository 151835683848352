/** @format */

import { ITEM_TYPE } from '@shared/models/layout.model';

export enum SHARING_METHOD {
  CLIPBOARD = 'Clipboard',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  LINKEDIN = 'LinkedIn',
  WEB_API = 'navigator.share',
  NATIVE = 'Native',
  QR = 'QR',
}

/**
 * url: A string representing the URL to be shared.
 * title: A string representing the title to be shared, usually document.title.
 * text: Any text you want to include.
 *
 * ref: https://css-tricks.com/how-to-use-the-web-share-api/
 */
export interface ShareableItem {
  id: string;
  title: string;
  description?: string;
  shareUrl: string;
  type: ITEM_TYPE;
  tags?: string[];
  shareCount?: number;
}

export interface ShareResponse {
  shareUrl: string;
  itemType: ITEM_TYPE; // The type of shared content.
  method: SHARING_METHOD; // The method in which the content is shared.
}

export interface SharingParams {
  title: string;
  url: string;
  text: string;
  hashtags: string[];
  itemType?: ITEM_TYPE;
}
