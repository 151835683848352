/** @format */

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { HOMEPAGE } from '@app/app.config';

const PAGE = '[TermsPage]'; // eslint-disable-line @typescript-eslint/no-unused-vars

@Component({
  selector: 'app-terms',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit {
  title: string = '';
  @Input() isModal: boolean = false;
  @Input() allowClickHome: boolean = true;

  constructor(private navCtrl: NavController, private viewCtrl: ModalController, private route: ActivatedRoute) {}

  ngOnInit() {
    // console.log(`${PAGE} oninit isModal`, this.isModal);
    this.route.paramMap.pipe(
      tap((params: ParamMap) => {
        // eslint-disable-next-line eqeqeq
        this.isModal = params.get('isModal') && params.get('isModal') == 'true' ? true : this.isModal;
        console.log(`${PAGE} route.paramMap isModal`, this.isModal);
      })
    );
  }

  openHomePage() {
    this.dismiss();
    if (this.allowClickHome) {
      this.navCtrl.navigateForward(`/${HOMEPAGE}`);
    }
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
