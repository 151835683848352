<section [ngSwitch]="layout.type">
  <ng-container *ngSwitchCase="layoutTypes.Carousel">
    <fs-carousel
      [items]="items | searchItems: searchText:searchFields"
      [canLoadMore]="false"
      [isLoadingMore]="false"
      [itemType]="itemTypes.Clips"
      [heading]="heading"
      [options]="layout.options"
      [isModerated]="isModerated"
      [canModerate]="canModerate"
      (itemClick)="onClickItem($event)"
      (loadMore)="loadMore.emit($event)"
    ></fs-carousel>
  </ng-container>
  <ng-container *ngSwitchCase="layoutTypes.Grid">
    <!-- [items]="items | searchItems: searchText:searchFields" -->
    <fs-grid
      [items]="items"
      [itemType]="itemTypes.Clips"
      [heading]="heading"
      [canEdit]="canEdit"
      [isLoading]="isLoading"
      [options]="layout.options"
      [isModerated]="isModerated"
      [canModerate]="canModerate"
      (itemClick)="onClickItem($event)"
      
    ></fs-grid>
    <!-- (loadMore)="loadMore.emit($event)"-->
  </ng-container>
  <ng-container *ngSwitchDefault>
    Unknown Layout...
  </ng-container>
</section>
