/** @format */

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DiscoverCardClick, ITEM_TYPE, LayoutOptions, FlexiblePartialItem } from '@shared/models/layout.model';
import { Subject } from 'rxjs';
import { UserService } from '@services/user.service';
import { getId as getStackId } from '@store/selectors/stacks.selectors';
import { getId as getClipId } from '@store/selectors/clips.selectors';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

export class CarouselOptions extends LayoutOptions {
  // inherited from LayoutOptions:
  // hasAddNewItemButton = false;
  // hasNewItemButtonAtEnd = false;
  // isEvent = false;

  constructor(opts: object) {
    super(opts);
    if (opts != null) {
      Object.entries(opts)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, value]) => Object.getOwnPropertyNames(this).includes(key))
        .forEach(([key, value]) => (this[key] = value));
    }
  }
}

const DEBUG_LOGS = false;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fs-carousel',
  templateUrl: './fs-carousel.component.html',
  styleUrls: ['./fs-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FsCarouselComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() items: FlexiblePartialItem[];
  // we need to know if more items can be loaded after the end has been reached
  @Input() canLoadMore: boolean;
  @Input() isLoadingMore = false;
  @Input() itemType: ITEM_TYPE;
  @Input() heading?: string;
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;
  @Input() canShare = true;
  @Input() isProjectMember = false;
  @Input() currentUsername;

  /**
   * @Input Setter Getter
   * https://stackoverflow.com/a/40764091/4982169
   */
  @Input()
  set options(obj: object) {
    this._options = new CarouselOptions(obj);
  }
  get options(): CarouselOptions {
    return this._options;
  }

  @Output() newItemClick = new EventEmitter<void>();
  @Output() itemClick = new EventEmitter<DiscoverCardClick>();
  @Output() loadMore = new EventEmitter<void>();

  scrolledLeft$ = new Subject<boolean>();
  scrolledRight$ = new Subject<boolean>();
  itemTypes = ITEM_TYPE;
  // loadMore will only emit once for if the user has scrolled to the end and items.length !== loadMoreEmittedForItemAmount
  loadMoreEmittedForItemAmount = 0;
  showAddNewItemButtonForItemAmount: number;

  @ViewChild('carousel', { static: false }) carousel: ElementRef;

  private _options: CarouselOptions;
  private _timeoutScrollTo;

  constructor(private userService: UserService, private platform: Platform) {}

  ngOnInit(): void {
    this.platform.ready().then(() => {
      /**
       * "Add New item for desktop when user has less than 3 items in carousel. Show on mobile only when no items."
       * https://filmstacker.atlassian.net/browse/MVP-1178
       */
      this.showAddNewItemButtonForItemAmount = this.platform.is('desktop') ? 3 : 1;
    });
    if (!this.currentUsername) {
      this.currentUsername = this.userService.getUsername();
    }
  }

  async ngAfterViewInit() {
    // @note setTimeout hack because its children is disconnected from life cycle hook due to componentFactory
    await new Promise((resolve) => setTimeout(resolve));
    this.calculateScrolls();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.carousel) {
      // @note setTimeout hack because its children is disconnected from life cycle hook due to componentFactory
      await new Promise((resolve) => setTimeout(resolve));
      this.calculateScrolls();

      // scroll to items when length or currentUser changes
      if (this.itemType === this.itemTypes.Users) {
        const {
          items: { currentValue: currentItemValue = [], previousValue: previousItemValue = null } = {},
          currentUsername: {
            currentValue: currentUsernameValue = [],
            previousValue: previousUsernameValue = null,
          } = {},
        } = changes;
        if (
          (currentItemValue?.length > 0 && currentItemValue.length !== previousItemValue?.length) ||
          (currentUsernameValue && currentUsernameValue !== previousUsernameValue)
        ) {
          // console.log({ msg: 'do it', currentUsernameValue, itemType: this.itemType });
          const scrollLeftWas = this.carousel?.nativeElement?.scrollLeft;
          clearTimeout(this._timeoutScrollTo);
          this._timeoutScrollTo = setTimeout(() => {
            this.scrollItemIntoView(scrollLeftWas);
          }, 2000);
        }
      }
    }
  }

  addNewItemButtonClick() {
    this.newItemClick.emit();
  }

  calculateScrolls() {
    const { scrollLeft, scrollWidth, clientWidth } = this.carousel.nativeElement;
    this.scrolledRight$.next(scrollLeft + clientWidth === scrollWidth);
    this.scrolledLeft$.next(scrollLeft === 0);
    // check if the end of the carousel has been reached and if loadMore needs to be emitted
    const width = scrollWidth - clientWidth;
    if (
      this.items &&
      this.items.length > 0 &&
      this.canLoadMore &&
      scrollLeft === width &&
      this.items.length !== this.loadMoreEmittedForItemAmount
    ) {
      this.loadMoreEmittedForItemAmount = this.items.length;
      this.loadMore.emit();
    }
  }

  navigate(direction: 'right' | 'left') {
    const { scrollLeft, scrollWidth, clientWidth } = this.carousel.nativeElement;
    const buffer = 125;
    const left =
      direction === 'right'
        ? Math.min(scrollLeft + clientWidth - buffer, scrollWidth - clientWidth)
        : Math.max(scrollLeft - clientWidth + buffer, 0);
    this.carousel.nativeElement.scrollTo({
      top: 0,
      left,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackByFn(_index: number, item: any) {
    // NOTE: this.itemType is not defined...
    if (!item) {
      return _index;
    }
    if (item.projectId && item.stackId) {
      return getStackId(item.projectId, item.stackId);
    } else if (item.projectId && item.id) {
      return getClipId(item.projectId, item.id);
    } else if (item.id) {
      return item.id;
    } else if (item.userId) {
      return item.userId;
    } else {
      return _index;
    }
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  private _scrollTimeout;
  private _closeTimeout;
  private _itemSelectedTimeout;
  /* eslint-enable @typescript-eslint/member-ordering */

  private scrollItemIntoView(origScrollLeft = 0) {
    clearTimeout(this._scrollTimeout);
    this._scrollTimeout = setTimeout(() => {
      try {
        const OFFSET_PADDING = 100;
        if (this.carousel && this.carousel.nativeElement) {
          const container = this.carousel.nativeElement;
          const selected = container.querySelector('.carousel__container__content .user-slide.user-slide__selected');
          if (selected?.offsetLeft > 0) {
            // if the user has moved the scroller, don't animate, plz
            if (container.scrollLeft === origScrollLeft) {
              const offsetLeft = selected.offsetLeft;
              DEBUG_LOGS &&
                console.log(`scrollItemIntoView`, {
                  offsetLeft,
                  scrollLeft: container.scrollLeft,
                  origScrollLeft,
                  selected,
                  container,
                });
              // container.scrollLeft = selected[0].offsetLeft;
              this.carousel.nativeElement.scrollTo({
                top: 0,
                left: offsetLeft > OFFSET_PADDING ? offsetLeft - OFFSET_PADDING : 0,
                behavior: 'smooth',
              });
            } else {
              DEBUG_LOGS &&
                console.log('Skipping scroll since it was changed...', {
                  scrollLeft: container.scrollLeft,
                  origScrollLeft,
                  selected,
                });
            }
          } else {
            !environment.production && console.log('User carousel selected item not found?', { container, selected });
          }
        }
      } catch (error) {
        console.log(`Caught Error in scrollItemIntoView`, error);
      }
    }, 500); // 500ms animation
  }
}
