/**
 * @format
 */

import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { selectMyProjects, selectRecentProjects } from '@store/selectors/projects.selectors';
import { ConfigService } from '@app/core/config';
import { Project, sortProjectTitleAlpha } from '@projects/shared/project.model';
import { EnvironService } from '@services/environ.service';
import { ProjectService } from '@projects/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';
import { ModalController } from '@ionic/angular';
import { ProjectFormComponent } from '@projects/project-form/project-form.component';

const DEBUG_LOGS = false;

@Component({
  selector: 'app-form-select-myprojects',
  templateUrl: './form-select-myprojects.component.html',
  styleUrls: ['./form-select-myprojects.component.scss'],
})
export class FormSelectMyProjects implements OnInit, OnDestroy {
  /** Affects the stacked label */
  @Input() showLabel = true;
  @Input() isCapture = false;
  @Input() onlyAdmin = false;
  /** If available, use this projectId as the default selected (from stackId) */
  @Input() defaultProjectId: string;
  @Output() selected = new EventEmitter<Partial<Project>>();

  projectSelected: Partial<Project> | number = -2;
  myProjects: Partial<Project>[] = [];
  recentProjects: Partial<Project>[] = [];

  private get isProjectSelected(): boolean {
    return (this.projectSelected as Partial<Project>)?.id?.length > 0 ?? false;
  }
  /** @todo widget config */
  private configProjects: Project[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private configService: ConfigService,
    private environService: EnvironService,
    private projectService: ProjectService,
    private userService: UserService,
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    // instead of passing the async to the backend
    this.projectService
      .getRecentProjects()
      .pipe(
        takeUntil(this.onDestroy$)
        // if we care about edit access here, which we don't...
        // map((projects: Project[]) =>
        //   projects.map((project) => ({ ...project, canEdit: this.projectService.isProjectAdmin(project) }))
        // )
      )
      .subscribe((projects) => {
        if (this.onlyAdmin) {
          this.userService.userId$.pipe(take(1)).subscribe((userId) => {
            this.recentProjects = projects.filter((project) => this.projectService.isProjectAdmin(project, userId));
          });
        } else {
          this.recentProjects = projects;
        }
        DEBUG_LOGS && console.log(`FormSelect recentProjects:`, this.recentProjects);
      });

    let timeout;
    /**
     * 1. get the activeProject and select that one if it's mine & is {eventType} template
     * 2. Filter myProjects by eventType template
     * 3. auto-select if only one project exists
     */
    combineLatest([
      this.userService.userId$,
      this.environService.activeProjectId$,
      this.store.select(selectMyProjects),
      this.store.select(selectRecentProjects),
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        map((args) => {
          DEBUG_LOGS && console.log(`FormSelect pre-filter:`, args);
          return args;
        }),
        filter(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([userId, activeProjectId, projects, recent]) =>
            Array.isArray(projects) &&
            projects.length > 0 &&
            // wait until they're all loaded.. (not undefined in array)
            projects.filter((p) => p && p.id).length === projects.length
          // but for recent, we don't care if they are all loaded, just use what's there so far
          // && recent.filter(p => p && p.id).length === recent.length
        )
      )
      .subscribe(([userId, activeProjectId, projects, recent]) => {
        const wasSelected = `${this.projectSelected}`;
        if (this.onlyAdmin) {
          projects = projects.filter((project) => this.projectService.isProjectAdmin(project, userId));
        }
        // projects are array and length > 0 based on filter above
        this.myProjects = projects.sort(sortProjectTitleAlpha);
        // we need to make sure that the recent are YOURS!
        this.recentProjects =
          Array.isArray(recent) && recent.length > 0
            ? recent.filter((r) => r?.id && projects.findIndex((proj) => proj.id === r.id) > -1)
            : [];

        DEBUG_LOGS &&
          console.log(`FormSelect projects`, {
            projectSelected: this.projectSelected,
            activeProjectId,
            defaultProjectId: this.defaultProjectId,
            projects,
            recent: this.recentProjects,
            isProjectSelected: this.isProjectSelected,
          });
        // if (this.isProjectSelected) {
        //   // already a set value, but we might have just added more projects, so let's check again
        //   return;
        // }
        if (this.defaultProjectId) {
          const defaultExists = this.myProjects.find((p) => p.id === this.defaultProjectId);
          if (defaultExists) {
            DEBUG_LOGS &&
              console.log(`FormSelect projects defaultExists`, {
                defaultProjectId: this.defaultProjectId,
                defaultExists,
              });
            this.projectSelected = defaultExists as Partial<Project>;
          }
        }
        if (!this.isProjectSelected && activeProjectId) {
          const activeExists = this.myProjects.find((p) => p.id === activeProjectId);
          if (activeExists) {
            DEBUG_LOGS &&
              console.log(`FormSelect projects activeExists`, { projectSelected: this.projectSelected, activeExists });
            this.projectSelected = activeExists as Partial<Project>;
          }
        }
        if (!this.isProjectSelected) {
          // let's look at recentProjects..
          const recentExists = this.recentProjects.find(
            (proj) => proj && proj.id && this.myProjects.find((p) => p.id === proj.id)
          );
          DEBUG_LOGS &&
            console.log(`FormSelect projects recentExists`, { projectSelected: this.projectSelected, recentExists });
          if (recentExists) {
            this.projectSelected = recentExists as Partial<Project>;
          }
        }
        if (!this.isProjectSelected && projects.length === 1) {
          [this.projectSelected] = projects; // Using ES6 destructuring to get 1st element of array, returns undefined array.isEmpty
        }

        // check to see if we should emit, if it changed
        if (this.isProjectSelected && this.projectSelected !== wasSelected) {
          clearTimeout(timeout);
          // avoid the ExpressionChangedAfterItHasBeenCheckedError
          timeout = setTimeout(() => {
            this.selected.emit(this.projectSelected as Partial<Project>);
          }, 600);
        }
        // ...options ready for select
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Project was selected, update with this value
   */
  projectChanged(event) {
    if (event && event.target && event.target.value && event.target.value.id) {
      this.projectSelected = event.target.value as Partial<Project>;
      // avoid the ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
        this.selected.emit(this.projectSelected as Partial<Project>);
      }, 600);
      /**
       * @todo handle this event in parents?
       */
      // set the activeProject in EnvironStore to save it for next Capture
      // this.environService.setActiveProjectId(event.target.value.id);
    } else {
      console.warn(`projectChanged not as expected?`, event);
    }
  }

  compareProjectsWith(p1, p2) {
    return p1 && p2 && typeof p1 !== 'number' && typeof p2 !== 'number' ? p1.id === p2.id : p1 === p2;
  }

  /**
   * @todo implement appConfig allowPublishTo for buildProjectOptions
   */
  async getProjectsFromAppConfig() {
    const config = await this.configService.appConfig;
    if (Array.isArray(config.allowPublishTo) && config.allowPublishTo.length > 0) {
      this.projectService.loadProjectPreviewsByIds(config.allowPublishTo);
      this.projectService
        .getProjectsFromStoreByIds(config.allowPublishTo)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((projects: Project[]) => {
          this.configProjects = (projects || []).filter((p) => p && p.title && p.id);
          // this.projects.push(...this.configProjects);
          // if (this.configProjects.length === 1) {
          //   this.hideProjectSelect = true;
          //   setTimeout(() => {
          //     this.stackForm.patchValue({
          //       projectId: this.configProjects[0].id,
          //     });
          //   }, 900);
          // }
        });
    }
  }

  async createProject() {
    this.userService.userId$.pipe(take(1)).subscribe(async (userId) => {
      const projectCreateModal = await this.modalCtrl.create({
        component: ProjectFormComponent,
        componentProps: {
          userId,
          // eventType: this.routeId,
        },
      });
      projectCreateModal.present();
      projectCreateModal.onDidDismiss().then((res) => {
        if (res?.data?.project?.id) {
          const projectId = res.data.project.id;
          this.defaultProjectId = projectId;
          // this.store.dispatch(environSetActiveProject({ projectId }));
        }
      });
    });
  }
}
