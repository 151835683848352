<ion-header *ngIf="isModal">

  <app-clipper-segment-bar
    [showBackButton]="true"
    [selectedSegment]="selectedSegment"
    (onSelectSegment)="onSelectSegment($event)"
    (onDismiss)="dismiss()"
  ></app-clipper-segment-bar>

</ion-header>

<ion-content color="dark">

  <div [ngSwitch]="selectedSegment" *ngIf="clip; else noClip" class="segment-wrap" [ngClass]="{ 'modal': isModal }">

    <div *ngSwitchCase="segmentNames.Trim" class="trim segment-view">
      <app-trimmer
        [clip]="clip"
        [currentUserId]="currentUserId$ | async"
        [project]="project$ | async"
      ></app-trimmer>
    </div>
      
    <div *ngSwitchCase="segmentNames.Poster" class="poster segment-view">
      <app-select-poster
        [clip]="clip"
        [currentUserId]="currentUserId$ | async"
        [project]="project$ | async"
      ></app-select-poster>
    </div>
    
    <div *ngSwitchCase="segmentNames.Settings" class="settings segment-view">
      <app-clip-settings
        [clip]="clip"
        [currentUserId]="currentUserId$ | async"
        [project]="project$ | async"
      ></app-clip-settings>
    </div>
  </div>



  <ng-template #noClip>
    <p class="ion-padding ion-text-center">{{ 'ERRORS.NO_CLIP_FOUND' | translate }}</p>
  </ng-template>

</ion-content>

<ion-footer *ngIf="isModal">
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        {{ cancelText }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>