/**
 * @format
 * this component was originally in the user-menu - but it is not needed.
 * user-menu.component will billingService.initUser
 */

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { State, selectSubscriptionStatus, SubscriptionViewModel, selectMinutes } from '@billing/store';
import { BillingService } from '@billing/shared/services';
import { STUDIO_SETTINGS_ROUTE } from '@app/app-routing.module';
import { ENABLE_BILLING_MINUTES } from '@app/app.config';

@Component({
  selector: 'fs-subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.scss'],
})
export class SubscriptionStatusComponent {
  subscriptionVm$: Observable<SubscriptionViewModel> = this.store.select(selectSubscriptionStatus);
  minutes$: Observable<number> = this.store.select(selectMinutes);

  minutesEnabled = ENABLE_BILLING_MINUTES;

  constructor(private store: Store<State>, private billingService: BillingService, private navCtrl: NavController) {}

  // ngAfterViewInit() {
  //   // 2024-01-15 moved to UserEffects.loginSuccess to catch the login flow even after component loaded
  //   //   this.billingService.initUser();
  // }

  openCheckout() {
    console.warn(`[SubscriptionStatus] TODO: OPEN ADD-ON NOT DEFAULT`);
    try {
      // this.billingService.openCheckout();
    } catch (error) {
      console.warn('[SubscriptionStatus] openCheckout caught:', error);
    }
  }

  navStudioSettings() {
    this.navCtrl.navigateForward(`/${STUDIO_SETTINGS_ROUTE}`);
  }

  addMinutes() {
    this.navStudioSettings();
  }
}
