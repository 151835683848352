/**
 * @todo translations in submit method..
 * @format
 */

import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { Subject, Observable, EMPTY, of } from 'rxjs';
import { takeUntil, switchMap, distinctUntilChanged, tap, filter, map } from 'rxjs/operators';
import { Project, ProjectMember } from '@projects/shared/project.model';
import { ToasterService } from '@services/toaster.service';
import { ProjectMemberService } from '@members/shared/services/project-member.service';
import { ProjectService } from '@projects/shared/services/project.service';
import { User, DEFAULT_USER_AVATAR_TERTIARY } from '@shared/models/user.model';
import { PROJECT_MEMBER_ROLE } from '@members/shared/project-member.model';
import { Utils } from '@shared/utils';
import { SentryService } from '@services/analytics/sentry.service';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { selectMemberQueryResults } from '@store/selectors/members.selectors';
import { addMember as addMemberAction, updateMember as updateMemberAction } from '@store/actions/projects.actions';
import { ProjectMemberModalComponent } from '@members/project-member-modal/project-member-modal.component';
// import _uniqBy from 'lodash/uniqBy';
import { TranslateService } from '@ngx-translate/core';
import { ModalInviteSuccessComponent } from '@shared/components/modal-invite-success/modal-invite-success.component';
import { getInviteTypeFromProject, getTranslatePath } from '@onboarding/shared/onboarding.model';
import { AddMemberModalComponent } from '@members/add-member-modal/add-member-modal.component';
import { CommunityRulesPage } from '@pages/community-rules/community-rules.page';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';

const DEBUG_LOGS = false;
const PAGE = '[AddMember]';

/**
 * TODO:
 *  roleInCurrentProject - verify on Studio, and project role
 * submitAddUser - undo temp Promise.resolve
 */
@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
})
export class AddMemberComponent implements OnInit, OnDestroy {
  @Input() isStudio: boolean = false;
  @Input()
  set project(value) {
    this._project = value;
    if (!this.isStudio) {
      this.projects$ = of([this.project]);
    }
  }
  get project(): Project {
    return this._project;
  }
  get projectId(): string {
    return this.project && this.project.id;
  }

  @Output() addSuccess = new EventEmitter();
  @Output() dismiss = new EventEmitter();

  /** small block of text saying crew must purchase upload minutes */
  showBillingText = false;

  searchResults$: Observable<(User & { roleInCurrentProject?: PROJECT_MEMBER_ROLE })[]>;
  addProjectRole: PROJECT_MEMBER_ROLE;

  loadingAddCrew = false; // trigger the spinner
  avatarFallback = DEFAULT_USER_AVATAR_TERTIARY;

  selectedUserIds: { userId: string; username: string }[] = [];

  submitEmailAttempt = false;
  message = ''; // show the results

  projects$: Observable<Project[]>;
  projectsInactiveUsers$: Observable<ProjectMember[]>;
  viewingInactiveUsers = false;

  get routeId() {
    return getInviteTypeFromProject(this.project);
  }
  get translatePath() {
    return getTranslatePath(this.routeId);
  }

  private _project: Project;
  private inactiveUsers: ProjectMember[] = [];
  private searchChanged$: Subject<string> = new Subject();
  private onDestroy$ = new Subject<void>();

  constructor(
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    private toaster: ToasterService,
    private projectMemberService: ProjectMemberService,
    private projectService: ProjectService,
    private sentryService: SentryService,
    private store: Store<State>,
    private navCtrl: NavController,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    // isStudio ? (myProjects$ | async) : [ project ]
    this.projects$ = this.isStudio ? this.projectService.getMyProjects() : of([this.project]);
    this.projectsInactiveUsers$ = this.projects$.pipe(
      map((projects) =>
        projects.reduce((acc, project) => {
          if (project && Array.isArray(project.members)) {
            acc.push(
              ...project.members.filter((member) => !member.isActive).map((m) => ({ ...m, projectId: project.id }))
            );
          }
          return acc;
        }, [])
      ),
      tap((res) => {
        DEBUG_LOGS && console.log(`${PAGE} projectsInactiveUsers$:`, res);
        this.inactiveUsers = res;
      })
    );

    this.searchResults$ = this.store.select(selectMemberQueryResults).pipe(
      distinctUntilChanged(),
      map((users) =>
        users.map((u) => {
          if (!u) u = new User(u);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (u as any).selected = false;
          u.avatar = this.projectMemberService.getUserAvatar(u.userId);
          // DEBUG_LOGS && console.log(`searchResultsMap`, {
          //   userId: u.userId,
          //   projectId: this.project && this.project.id,
          //   memberProjects: u.memberProjects,
          // });
          const projectId = this.projectId;
          let roleInCurrentProject = null;
          if (projectId && Array.isArray(u.memberProjects)) {
            const proj = u.memberProjects.filter((p) => p.isActive).find((p) => p.projectId === projectId);
            roleInCurrentProject = proj && proj.role ? proj.role : null;
          }
          // now, check for projects owned
          if (!roleInCurrentProject && projectId && Array.isArray(u.projects)) {
            const projOwned = u.projects.find((p) => p.id === projectId);
            roleInCurrentProject = projOwned && projOwned.id ? PROJECT_MEMBER_ROLE.OWNER : null;
          }
          return {
            ...u,
            roleInCurrentProject,
          };
        })
      )
    );

    this.searchChanged$
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        filter((search) => typeof search === 'string' && search.length > 1),
        switchMap((search) => {
          this.projectMemberService.queryMembers(search);
          return EMPTY;
        })
      )
      .subscribe(() => {
        // console.log('Not empty, just subscribed..')
      });
  }

  ngOnDestroy() {
    this.projectMemberService.resetQueryMembers();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  searchChanged(event) {
    if (event) {
      DEBUG_LOGS && console.log(`${PAGE} searchChanged:`, event);
      this.searchChanged$.next(event);
    } else {
      DEBUG_LOGS && console.log(`${PAGE} searchChanged reset:`, event);
      this.selectedUserIds = [];
      this.searchChanged$.next('');
      this.projectMemberService.resetQueryMembers();
    }
    this.message = '';
  }

  onClickUser(user) {
    if (!user || !user.userId) {
      console.warn(`${PAGE} onClickUser missing user?`, user);
    }
    if (this.isUserSelected(user.userId)) {
      // deselect
      this.selectedUserIds.splice(
        this.selectedUserIds.findIndex((id) => id.userId === user.userId),
        1
      );
    } else {
      // select
      this.selectedUserIds.push({ userId: user.userId, username: user.username || user.userId });
      DEBUG_LOGS && console.log(`${PAGE} onClickUser select user:`, user);
    }
  }

  isUserSelected(userId): boolean {
    return this.selectedUserIds.findIndex((id) => id.userId === userId) > -1;
  }

  viewInactiveUsers(inactiveUsers) {
    DEBUG_LOGS && console.log(`${PAGE} viewInactiveUsers`, { inactiveUsers });
    this.selectedUserIds = [];
    this.searchChanged$.next('');
    this.projectMemberService.resetQueryMembers();
    if (!this.viewingInactiveUsers) {
      this.projectMemberService.addToQueryMemberResults(
        inactiveUsers.map((u) => ({
          userId: u.userId,
          username: u.username,
          avatar: u.avatar,
          memberProjects: [u],
        }))
      );
    }
    this.viewingInactiveUsers = !this.viewingInactiveUsers;
  }

  async viewProjectMember(event, user) {
    event.stopPropagation();
    DEBUG_LOGS && console.log(`${PAGE} viewProjectMember`, { user });
    // const appConfig = await this.configService.appConfig;
    // if (appConfig.isWidgetActive) return;
    const modal = await this.modalController.create({
      component: ProjectMemberModalComponent,
      componentProps: {
        userId: user.userId,
        viewOnly: true,
        // projects: this.projects || [],
      },
      cssClass: 'modal-lg',
    });

    return await modal.present();
  }

  /**
   * project-member-projects emitted this change
   * check if it's an update to existing or new
   * (both check if toAdd already has this user,
   * and if this user is !isActive which would cause an API update rather than create)
   */
  onProjectRoleChanged({ role, project }) {
    if (!role || !project || !project.id) {
      console.warn(`${PAGE} onProjectRoleChanged missing project or role?`, { role, project });
      return;
    }
    this.addProjectRole = role;
    // this.toAdd = _uniqBy([{ role, projectId: project.id }, ...this.toAdd], 'projectId');
    DEBUG_LOGS && console.log(`${PAGE} project role changed`, { role, projectId: project.id });
  }

  /**
   * Combine toAdd with selectedUserIds
   * determine isUpdate (!isActive) or create
   *
   * @todo refactor this nonsense
   */
  submitAddUser() {
    this.loadingAddCrew = true;
    this.message = '';
    try {
      DEBUG_LOGS &&
        console.log(`${PAGE} submitAddUser...`, {
          addProjectRole: this.addProjectRole,
          selectedUserIds: this.selectedUserIds,
        });

      if (!this.addProjectRole || this.addProjectRole.length < 1 || this.selectedUserIds.length < 1) {
        this.loadingAddCrew = true;
        this.message = `We're missing required info to perform operation. Please reload and try again.`;
      }

      /**
       * loop the toAdd and look at each for each selectedUserIds
       * create ProjectMembers as updates
       */
      const updates: ProjectMember[] = [];
      // const inactiveUsers = await lastValueFrom(this.projectsInactiveUsers$); //rxjs7
      const projectId = this.projectId;
      this.selectedUserIds.forEach(({ userId, username }) => {
        if (this.addProjectRole && projectId) {
          const isCurrentlyInactive = this.inactiveUsers.find(
            (u) => userId && u?.userId === userId && u.projectId === projectId
          );

          const member: ProjectMember = {
            isActive: true,
            role: this.addProjectRole,
            userId,
            username,
            projectId,
          };
          if (isCurrentlyInactive) {
            // update user instead of creating
            this.store.dispatch(updateMemberAction({ member }));
          } else {
            this.store.dispatch(addMemberAction({ member }));
          }
          updates.push(member);
        } else {
          console.warn(`${PAGE} submitAddUser '${userId}' missing required props: role or projectId`, {
            addProjectRole: this.addProjectRole,
            projectId,
          });
        }
      }); // end forEach selectedUserIds

      if (updates.length > 0) {
        const membersAdded = Utils.makeCommaSeparatedString(updates.map((u) => u.username));
        const membersPlural = updates.length > 1 ? 's' : '';
        this.message += `Success. Added ${membersAdded} as member${membersPlural} to the project!`;
        this.addProjectRole = PROJECT_MEMBER_ROLE.CREW;
        this.selectedUserIds = [];
        this.addSuccess.emit({});
      } else {
        this.message += `No updates were performed.`;
      }

      this.loadingAddCrew = false;
    } catch (error) {
      console.warn(`${PAGE} submitAddUser caught:`, error);
      this.sentryService.captureError(error);
      this.message = `Oops! We caught an error. Please try again.`; // ${error && error.message ? error.message : ''}`;
      this.toaster.present(`Oops! something didn't go as planned... Please try again.`);
      this.loadingAddCrew = false;
    }
  }

  /**
   * https://xd.adobe.com/view/61615fe1-b7c1-47a9-493b-3c7268fe8988-1750/screen/3073281d-aabd-4185-8cf2-8812fdea0563
   */
  async openSuccessDialog() {
    this.dismiss.emit();
    this.addSuccess.emit({});
    const modal = await this.modalController.create({
      component: ModalInviteSuccessComponent,
      componentProps: {
        heading: this.translate.instant('MEMBERS.INVITE.HEADING'),
        btnText: this.translate.instant('MEMBERS.INVITE.CTA'),
        project: this.project,
      },
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.navToProject) {
      this.navCtrl.navigateForward(`/${PROJECT_DETAIL_ROUTE}/${this.project.id}`);
    } else if (data && data.openAddMemberDialog) {
      // re-open this component modal again
      this.openAddMemberDialog();
    }
  }

  /**
   * This is essentially opening up yourself, is it weird?
   */
  async openAddMemberDialog() {
    const modal = await this.modalController.create({
      component: AddMemberModalComponent,
      componentProps: {
        project: this.project,
        isStudio: this.isStudio,
      },
      cssClass: 'crew-member-modal fullheight-modal',
    });
    return await modal.present();
  }

  async openCommunityRules() {
    // /community-rules";dismiss.emit()
    const modal = await this.modalController.create({
      component: CommunityRulesPage,
      componentProps: {
        isModal: true,
        // returnUrl
      },
    });
    return await modal.present();
  }
}
