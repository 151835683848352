/** @format */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, EMPTY, from } from 'rxjs';
import {
  tap,
  withLatestFrom,
  filter,
  mergeMap,
  concatMap,
  catchError,
  map,
  take,
  delay,
  switchMap,
} from 'rxjs/operators';
import { State } from '../reducers'; // eslint-disable-line  @typescript-eslint/no-unused-vars
import * as resetActions from '../actions/reset.actions'; // eslint-disable-line  @typescript-eslint/no-unused-vars
import * as projectActions from '../actions/projects.actions'; // eslint-disable-line  @typescript-eslint/no-unused-vars
import * as memberActions from '../actions/members.actions';
import {
  ProjectGroup,
  selectProjectState,
  selectProjectIds,
  selectProjectEntities,
} from '@store/selectors/projects.selectors';
import { getUserId } from '@store/selectors/user.selectors';
import { FilterEntityTypes } from '@store/reducers/viewstate.reducers';
import { EnvironService } from '../../services/environ.service';
import { getIsMemberRole, Project } from '@projects/shared/project.model';
import { User } from '@shared/models/user.model';
// import { ProjectMember } from '@members/shared/project-member.model';
import { ProjectsApiService } from '@app/core/api/projects-api.service';
import { UsersApiService } from '@app/core/api/users-api.service';
import { SentryService } from '@services/analytics/sentry.service';
import _difference from 'lodash/difference';
import { ProjectCrewApiService } from '@app/core/api/project-crew-api.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { selectMembersState } from '@store/selectors/members.selectors';
import { getTopPermission } from '@members/shared/project-member.model';
import { UpdateParam } from '@app/core/api/api-types';

const DEBUG_LOGS = false;
const PAGE = '[ProjectStoreEffects]';

const FIELD_TO_DETERMINE_FULL_ENTITY = 'description';

@Injectable()
export class ProjectsEffects {
  /*
   * If effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  /**
   * ProjectActions.setSubscriptionEvent -> presist to DB
   * @note this should still happen in
   */
  persistSetSubscription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectActions.setSubscriptionEvent),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectProjectState)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        // if action.doDbUpdate
        filter(([[action, state], userId]) => action?.doDbUpdate === true && state.entities[action.id]?.id?.length > 0), // && state.ids.indexOf(action.id) > 0),
        tap(([[action, state], userId]) => {
          const project = state.entities[action.id];
          if (project?.id?.length < 1) {
            console.warn(`${PAGE} setSubscriptionEvent caught invalid project`, { action, project });
            return EMPTY;
          }
          const updates: UpdateParam[] = [];
          // take the vals set and update those, make sure you don't overwrite the
          for (const [prop, value] of Object.entries(action)) {
            // [ProjectStoreEffects] setSubscriptionEvent caught eventConfig invalid value
            if (prop === 'eventConfig') {
              try {
                if (value && (typeof value === 'string' || Object.keys(value).length > 0)) {
                  updates.push({
                    prop,
                    value,
                  });
                }
              } catch (error) {
                console.warn(error);
              }
              continue;
            }
            // the state was already changed in the reducer, so we should update everything in the action, since the action.doDbUpdate === true
            if (prop !== 'doDbUpdate' && prop !== 'id' && prop !== 'type' && value !== undefined && value !== null) {
              updates.push({
                prop,
                value,
              });
            }
          }
          if (updates.length > 0) {
            DEBUG_LOGS &&
              console.log(`${PAGE} setSubscriptionEvent persistSetSubscription -> api`, { action, updates });

            this.projectApi.updateProject(project, updates).catch((error) => {
              this.captureError(error, 'setSubscriptionEvent caught');
            });
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * on addMine, be sure it's in the store
   */
  addMine$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.addMine.type),
      // Combine ProjectState with userId to check if this is adding MINE
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectProjectState)),
          withLatestFrom(this.store$.select(getUserId))
        )
      ),
      map(([[{ ids = [] }, state], userId]) => ({
        userId,
        state,
        /** state.ids as string[] was necessary due to ngrx entity key being of type number or string... ugg */
        // true if NOT the id is there, is an entity, is entity.field
        ids: ids.filter((id) => id && !(state.ids as string[]).includes(id)),
      })),
      tap(({ ids, userId, state }) => DEBUG_LOGS && console.log('addMine', { ids, userId, state })),
      // stop here if it's already loaded...
      filter(({ ids, userId }) => ids.length > 0),
      mergeMap(({ ids, userId }) =>
        // GETPROJECT OK
        from(ids).pipe(
          mergeMap((id) =>
            this.projectApi.getProject(id).pipe(map((project) => projectActions.loadByIdSuccess({ project, userId })))
          ),
          catchError((error) => of(projectActions.loadFail({ error: error.message, id: ids.join(',') })))
        )
      )
    )
  );

  /**
   * On Set Hero Image
   */
  setProjectHero$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectActions.setHero.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectProjectState)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        filter(([[action, state], userId]) => action && action.url && action.url.length > 0), // && state.ids.indexOf(action.id) > 0),
        tap(([[action, state], userId]) => {
          DEBUG_LOGS && console.log(`${PAGE} action -> api`, { action });
          this.projectApi.updateProjectHero(action.id, action.url, userId).catch((error) => {
            this.captureError(error, 'setProjectHero caught');
          });
          this.analyticsService.projectPosterUpdated(action.id, action.url);
        })
      ),
    { dispatch: false }
  );

  /**
   * On AddMember Action update record in DB, add to store, add to MINE projects if mine
   * could be simplified, but for now duplicated 3x times..
   */
  addProjectMemberToDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.addMember.type),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectProjectEntities)),
          withLatestFrom(this.store$.select(selectMembersState)),
          withLatestFrom(this.store$.pipe(select(getUserId)))
        )
      ),
      map(([[[action, projectsEntities], membersState], userId]) => ({
        member: action.member,
        projectsEntities,
        membersState,
        userId,
      })),
      map(({ member, projectsEntities, membersState, userId }) => {
        // moved these checks to the Reducers (project & member) as well
        const isMemberRole = getIsMemberRole(member?.userId, projectsEntities[member.projectId]);
        const topPermission = getTopPermission(member, isMemberRole);
        const existsIndex =
          projectsEntities[member.projectId] && projectsEntities[member.projectId].members
            ? projectsEntities[member.projectId].members.findIndex((m) => m.userId === member.userId)
            : -1;
        const isCurrentlyActive =
          existsIndex > -1 ? projectsEntities[member.projectId].members[existsIndex].isActive : false;

        if (DEBUG_LOGS) {
          console.log(`${PAGE} addMemberToDb pre-filter action:`, {
            member,
            projectsEntities,
            membersState,
            userId,
            isMemberRole,
            topPermission,
            existsIndex,
            isCurrentlyActive,
            // the reducer already ran, so it should be currentlyActive and existing,
            // or if it's not then we still want the change, as long as it's the topPermission - do it regardless
            test: member.role === topPermission, // && (existsIndex < 0 || !isCurrentlyActive),
          });
        }

        return { member, projectsEntities, membersState, userId, topPermission, existsIndex, isCurrentlyActive };
      }),
      filter(
        ({ member, projectsEntities, membersState, userId, topPermission, existsIndex, isCurrentlyActive }) =>
          member?.userId?.length > 0 &&
          // avoid existing member being overwritten by crew member token accept MVP-1257
          // validate that we are not downgrading this user! (updated 2023-04-14)
          // if this role === topPermission
          member.role === topPermission
        // [2024-01-16] the reducer already ran, so it should be currentlyActive and existing,
        // or if it's not then we still want the change, as long as it's the topPermission - do it regardless
        //  && (
        //    // and it's not currently there
        //    existsIndex < 0 ||
        //    // or it's not currently inactive
        //    !isCurrentlyActive
        //  )
      ),
      // filter(([[{ member }, state], userId]) => member && member.projectId && state.ids.indexOf(member.projectId) < 0),
      // eslint-disable-next-line arrow-body-style
      mergeMap(({ member, projectsEntities, membersState, userId }) => {
        // if the user was inactive, we should update that user instead of creating, although the create handles the failover, below..

        // why was this here... does it happen in a specific case?
        // 2022-07-21 tried it and it fails since the state = PROJECT state, not members, but might work if we had that state too?
        // if (
        //   !member.isActive ||
        //   (
        //     projectsEntities && projectsEntities[member.projectId]
        //     && Array.isArray(projectsEntities[member.projectId].members)
        //     && projectsEntities[member.projectId].members.find((m) => m && m.userId === member.userId)
        //   )
        // ) {
        //   // member already exists, update
        //   // member = { ...member, isActive: true };
        //   return this.projectMemberApi.updateProjectCrewMember(member).pipe(
        //     take(1),
        //     map((res) => ({ user:  {
        //       userId: member.userId,
        //       name: member.username,
        //       avatar: member.avatar,
        //       memberProjects: [member],
        //     }})),
        //     switchMap(({ user }) => [
        //       memberActions.addToProject({ user, projectId: member.projectId }),
        //       ...(user.userId === userId && member.projectId )
        //         ? [projectActions.removeMine({ id: member.projectId })] : []
        //     ]),
        //   );
        // }
        return this.projectMemberApi
          .createProjectCrew({
            ...member,
            updatedBy: userId,
          })
          .pipe(
            take(1),
            map((res) => ({
              user: {
                userId: member.userId,
                name: member.username,
                avatar: member.avatar,
                memberProjects: [member],
              },
            })),
            switchMap(({ user }) => [
              memberActions.addToProject({ user, projectId: member.projectId }),
              ...(user.userId === userId && member.projectId
                ? [projectActions.addMine({ ids: [member.projectId] })]
                : []),
            ]),
            catchError((error) => {
              if (
                error &&
                Array.isArray(error.errors) &&
                error.errors.length > 0 &&
                error.errors[0].errorType &&
                error.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'
              ) {
                DEBUG_LOGS && console.log(`${PAGE} record already exists`, error);
                return this.projectMemberApi
                  .updateProjectCrewMember({
                    projectId: member.projectId,
                    userId: member.userId,
                    username: member.username,
                    // here we might be looking at an invite accept, set to active but don't overwrite role
                    // role: $role
                    isActive: true,
                    updatedBy: userId,
                  })
                  .pipe(
                    take(1),
                    map((res) => ({
                      user: {
                        userId: member.userId,
                        name: member.username,
                        avatar: member.avatar,
                        memberProjects: [member],
                      },
                    })),
                    switchMap(({ user }) => [
                      memberActions.addToProject({ user, projectId: member.projectId }),
                      ...(user.userId === userId && member.projectId
                        ? [projectActions.addMine({ ids: [member.projectId] })]
                        : []),
                    ])
                  );
              } else {
                DEBUG_LOGS && console.log(`${PAGE} caught error`, error);
                throw error;
              }
            })
          );
      }),
      catchError((error) => {
        this.sentryService.captureError(error);
        return EMPTY;
      })
    )
  );

  /**
   * On updateMember Action update record in DB, add to store, add to MINE projects if mine
   * could be simplified, but for now duplicated 3x times..
   */
  updateProjectMemberDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.updateMember.type),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectProjectState)),
          withLatestFrom(this.store$.pipe(select(getUserId)))
        )
      ),
      map(([[action, state], userId]) => ({ member: action.member, state, userId })),
      tap(({ member, state, userId }) => {
        DEBUG_LOGS && console.log(`${PAGE} updateProjectMemberDb pre-filter action:`, { member, state, userId });
      }),
      filter(({ member, state, userId }) => member && member.userId && member.userId.length > 0),
      // filter(([[{ member }, state], userId]) => member && member.projectId && state.ids.indexOf(member.projectId) < 0),
      // eslint-disable-next-line arrow-body-style
      mergeMap(({ member, state, userId }) => {
        return this.projectMemberApi
          .updateProjectCrewMember({
            ...member,
            updatedBy: userId,
          })
          .pipe(
            take(1),
            map((res) => ({
              user: {
                userId: member.userId,
                name: member.username,
                avatar: member.avatar,
                memberProjects: [member],
              },
            })),
            switchMap(({ user }) => [
              memberActions.addToProject({ user, projectId: member.projectId }),
              ...(user.userId === userId && member.projectId
                ? [projectActions.addMine({ ids: [member.projectId] })]
                : []),
            ]),
            catchError((error) => {
              // no need to check for DynamoDB:ConditionalCheckFailedException
              // per docs: Edits an existing item's attributes, or adds a new item to the table if it does not already exist.
              DEBUG_LOGS && console.log(`${PAGE} updateProjectMemberDb$ caught error`, error);
              return EMPTY;
            })
          );
      }),
      catchError((error) => {
        this.sentryService.captureError(error);
        return EMPTY;
      })
    )
  );

  ////  selectProjectIds

  /**
   * On AddMember Action, ensure the project is loaded
   */
  addMemberToProjectExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.addMember.type),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectProjectIds)),
          withLatestFrom(this.store$.select(getUserId))
        )
      ),
      tap(([[action, projectIds], userId]) => {
        DEBUG_LOGS && console.log(`${PAGE} addMemberToProjectExists pre-filter action:`, { action, projectIds });
      }),
      /** as string[] was necessary due to ngrx entity key being of type number or string... ugg */
      filter(
        ([[{ member }, projectIds], userId]) =>
          member && member.projectId && (projectIds as string[]).indexOf(member.projectId) < 0
      ),
      switchMap(([[{ member }, projectIds], userId]) =>
        this.projectApi.getProject(member.projectId).pipe(
          map((project) =>
            projectActions.loadByIdSuccess({ project, userId: member.userId === userId ? userId : null })
          ),
          catchError((error) => of(projectActions.loadFail({ error, id: member.projectId })))
        )
      ),
      catchError((error) => {
        this.sentryService.captureError(error);
        return of(resetActions.resetStore());
      })
    )
  );

  /**
   * V5 Batch Load By Ids
   */
  batchLoadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.loadBatchIds.type),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectProjectState)),
          withLatestFrom(this.store$.select(getUserId))
        )
      ),
      tap(([[action, state], userId]) => {
        DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, { action, state });
      }),
      filter(([[action, state], userId]) => action.ids.length > 0),
      // stop here if it's already loaded & there's projects...
      // filter(([action, state]) => ((Array.isArray(state.loading) && state.loading.includes(ProjectGroup.Mine)) || (Array.isArray(state.loaded) && !state.loaded.includes(ProjectGroup.Mine)) || (!Array.isArray(state.mine) || state.mine.length < 1) || (state.nextTokenMine && state.nextTokenMine.length > 0))),
      mergeMap(([[action, state], userId]) => {
        const ids = action.ids.filter((id) => id && id.length > 0 && id !== 'undefined');
        /** this was necessary due to ngrx entity key being of type number or string... ugg */
        const stateIds = state.ids as string[];

        /*
          Filter out the ids that are already in state, 
          get the ones that are not
        */
        // lodash.difference (R)eturns the values from array that are not present in the other arrays
        const newIds = _difference(ids, stateIds);
        DEBUG_LOGS && console.log(`post-filter mergeMap -> batchLoadById`, { newIds, ids, stateIds });

        if (newIds.length < 1) {
          // no ids to get!
          // check if these are mine
          if (action.group === ProjectGroup.Mine) {
            return of(projectActions.addMine({ ids }));
          }
          return EMPTY;
        }

        return this.projectApi.batchGetProjects(newIds).pipe(
          take(1),
          // tap(res => {console.log(res)}),
          map((res) => (Array.isArray(res) ? [...res.filter((p) => p && p.id && p.id.length > 0)] : [])),
          // tap(res => {console.log(res)}),
          map((projects) =>
            projectActions.loadSuccess({
              projects,
              listId: action.group,
              selected: null,
              nextToken: null,
              isLoadMore: true,
              filters: {
                id: action.group,
                ...(action.group === ProjectGroup.Mine || action.group === ProjectGroup.Recent ? { userId } : {}),
              },
            })
          ),
          catchError((error) => of(projectActions.loadFail({ error })))
        );
      }),
      // if we see an error here, need to reset store
      // TODO: resetStore Effect to reload the app
      // catchError(error => of(new projectActions.ResetAction()))
      catchError((error) => {
        console.error(error);
        return of(projectActions.loadFail({ error }));
      })
    )
  );

  loadFilteredProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.loadFilteredProjects.type, projectActions.loadMoreFilteredProjects.type),
      mergeMap(({ listId, filters, limit, nextToken }) =>
        this.load({ nextToken, limit, onlyFeatured: filters.type === FilterEntityTypes.Featured, filters }).pipe(
          map(({ projects, nextToken: token }) =>
            projectActions.loadSuccess({ projects, listId, nextToken: token, filters })
          ),
          catchError((error) => of(projectActions.loadFail({ error })))
        )
      ),
      catchError((error) => {
        this.sentryService.captureError(error);
        return of(resetActions.resetStore());
      })
    )
  );

  /**
   * Load Public Projects and Member Roles by UserId
   * @note removed loadAllForCurrentUserId 2021-08-06 and just use UserEffects.loginSuccess$
   * Load All Projects and Member Roles for Current UserId
   */
  loadByUserId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          projectActions.loadPublicByUserId.type
          // projectActions.loadAllForCurrentUserId.type, // removed loadAllForCurrentUserId 2021-08-06
        ),
        // Retrieve part of the current state telling us if history is loaded
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectProjectState)))),
        // stop here if it's already loaded & there's projects...
        filter(
          ([{ userId }, state]) =>
            (userId && Array.isArray(state.loading) && state.loading.includes(userId)) ||
            (Array.isArray(state.loaded) && !state.loaded.includes(userId))
          // || (state.nextTokenFeatured && state.nextTokenFeatured.length > 0)
        ),
        mergeMap(([action, state]) => {
          const { userId } = action;
          const isCurrentUser = false; // action.type === projectActions.loadAllForCurrentUserId.type; // removed loadAllForCurrentUserId 2021-08-06
          DEBUG_LOGS &&
            console.log(`${PAGE} loadByUserId$ post-filter -> loadUserProjects action:`, { action, isCurrentUser }); //, state);

          /**
           * Get the UserData with Projects & memberProjects, similar to UserEffect.loginSuccess$
           * @ref user.effects#269
           *
           * 1. projectsAction.loadSuccess(projects)
           * 2. load the projectIds from memberProjects
           * 3. memberActions.loadSuccess(memberProjects)
           */

          return from(this.usersApi.getUserWithProjects(userId, isCurrentUser)).pipe(
            take(1),
            tap((user) => {
              DEBUG_LOGS && console.log(`${PAGE} loadByUserId$ userData response`, { user });
            }),
            map((user) => user as User),
            filter((user) => user && user.userId && user.userId.length > 0),
            mergeMap((user) => {
              const projects = user && Array.isArray(user.projects) && user.projects.length > 0 ? user.projects : [];
              const memberProjects =
                user && Array.isArray(user.memberProjects) && user.memberProjects.length > 0 ? user.memberProjects : [];

              if (!isCurrentUser) {
                // dev verify api no private
                const privProj = projects.filter((p) => p.privacy !== 'PUBLIC');
                const inActiveMem = memberProjects.filter((p) => !p.isActive);
                if (privProj.length > 0 || inActiveMem.length > 0) {
                  const msg = `User (${user.userId}) getUserWithProjects call returned unexpected results?`;
                  console.warn(msg, { privProj, inActiveMem });
                  this.sentryService.captureMessage(
                    `${msg} (NON-PUBLIC) privProj.len='${privProj.length}', inActiveMem.len='${inActiveMem.length}'`
                  );
                }
              }

              if (projects.length > 0) {
                // * 1. projectsAction.loadSuccess(projects)
                // actually adding them here, as the memberProjects will follow a different flow
                const listId = isCurrentUser ? ProjectGroup.Mine : user.userId;
                this.store$.dispatch(
                  projectActions.loadSuccess({
                    projects,
                    nextToken: null, //((user as any).projects.nextToken as string),
                    listId,
                    selected: null,
                    userId: isCurrentUser ? user.userId : null,
                    filters: {
                      id: listId,
                      userId: user.userId,
                    },
                  })
                );
              }

              DEBUG_LOGS && console.log(`forkJoin result:`, { user, projects, memberProjects });

              // * 2. load the projectIds from memberProjects
              return of(memberProjects).pipe(
                take(1),
                tap((projectMembers) => {
                  DEBUG_LOGS && console.log(`pre-delay loadByUserId getProjectsFromUserMemberIds`, projectMembers);
                }),
                // add a delay to allow other calls to complete (fill store before re-loading same) _difference
                delay(300),
                map((projectMembers) => [
                  ...projectMembers.map((p) => p.projectId).filter((id) => id && id.length > 0),
                ]),
                tap((projectIds) => {
                  DEBUG_LOGS && console.log(`loadByUserId getProjectsFromUserMemberIds pluck projectIds:`, projectIds);
                }),
                map((projectIds) =>
                  projectIds.length > 0
                    ? this.store$.dispatch(
                        projectActions.loadBatchIds({
                          ids: projectIds,
                          group: isCurrentUser ? ProjectGroup.Mine : user.userId,
                        })
                      )
                    : EMPTY
                ),
                // tap(memberProjects => { console.log(`from(user.memberProjects`, memberProjects)}),

                catchError((e) => {
                  console.warn(`${PAGE} loadByUserId getProjectsFromUserMemberIds caught`, e);
                  this.captureError(e, `loadByUserId getProjectsFromUserMemberIds caught`);
                  return EMPTY;
                })
              );
            }),
            catchError((e) => {
              console.warn(`${PAGE} loadByUserId usersApi.getUserWithProjects caught`, e);
              this.captureError(e, `loadByUserId usersApi.getUserWithProjects caught`);
              return EMPTY;
            })
          );
        }), // mergeMap
        // if we see an error here, need to reset store
        // TODO: resetStore Effect to reload the app
        // catchError(error => of(new projectActions.ResetAction()))
        catchError((error) => {
          console.error(error);
          this.sentryService.captureError(error);
          return EMPTY; // ngrx v10 migration: Returning an EMPTY observable without { dispatch: false } now produces a type error.
        })
      ),
    { dispatch: false }
  );
  // );

  /**
   * V4 Load Featured
   */
  loadFeaturedProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.loadFeatured.type),
      // Retrieve part of the current state telling us if history is loaded
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectProjectState)))),
      tap(([action, state]) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, action); //, state);
      }),
      // stop here if it's already loaded & there's projects...
      filter(
        ([action, state]) =>
          (Array.isArray(state.loading) && state.loading.includes(ProjectGroup.Featured)) ||
          (Array.isArray(state.loaded) && !state.loaded.includes(ProjectGroup.Featured)) ||
          !Array.isArray(state.mine) ||
          state.ids.length < 1 ||
          (state.nextTokenFeatured && state.nextTokenFeatured.length > 0)
      ),
      mergeMap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} post-filter mergeMap action:`, action); //, state);
        return this.load({ nextToken: state.nextTokenFeatured, onlyFeatured: true }).pipe(
          map(({ projects, nextToken }) =>
            projectActions.loadSuccess({
              projects,
              listId: ProjectGroup.Featured,
              nextToken,
              selected: null,
              isLoadMore: !!state.nextTokenFeatured,
              filters: {
                id: ProjectGroup.Featured,
              },
            })
          ),
          catchError((error) => of(projectActions.loadFail({ error })))
        );
      }),
      // if we see an error here, need to reset store
      // TODO: resetStore Effect to reload the app
      // catchError(error => of(new projectActions.ResetAction()))
      catchError((error) => {
        console.error(error);
        return of(resetActions.resetStore());
      })
    )
  );

  loadProjectsSuccessNextToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.loadSuccess.type),
      // Retrieve part of the current state telling us if history is loaded
      // map(action => (action as projectActions.loadSuccess.)),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectProjectState)))),
      tap(([action, state]) => {
        // DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, action);//, state);
      }),
      // stop here if it's already loaded & there's projects...
      filter(([action, state]) => {
        const { nextToken } = action;
        // true if there's a nextToken
        return nextToken && nextToken.length > 0;
      }),
      mergeMap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} loadSuccess mergeMap nextToken action:`, action);
        const { nextToken: actionToken, listId } = action;
        return this.load({ nextToken: actionToken, onlyFeatured: listId === ProjectGroup.Featured }).pipe(
          map(({ projects, nextToken }) =>
            projectActions.loadSuccess({ projects, listId, nextToken, selected: null, isLoadMore: true })
          ),
          catchError((error) => of(projectActions.loadFail({ error })))
        );
      }),
      // if we see an error here, need to reset store
      // TODO: resetStore Effect to reload the app
      // catchError(error => of(new projectActions.ResetAction()))
      catchError((error) => {
        console.error(error);
        return of(projectActions.loadFail({ error }));
      })
    )
  );

  /**
   * Load By ID
   */
  loadProjectById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.loadById.type),
      // Combine ProjectState with userId to check if this is adding MINE
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectProjectState)),
          withLatestFrom(this.store$.select(getUserId))
        )
      ),
      tap(([[action, state], userId]) => {
        DEBUG_LOGS && console.log(`${PAGE} pre-filter load proj - action:`, action, state);
      }),
      // stop here if it's already loaded...
      filter(([[action, state], userId]) => {
        const { id } = action;
        /** this was necessary due to ngrx entity key being of type number or string... ugg */
        const stateIds = state.ids as string[];
        // true if NOT the id is there, is an entity, is entity.field
        return !(stateIds.indexOf(id) > 0 && state.entities[id] && state.entities[id][FIELD_TO_DETERMINE_FULL_ENTITY]);
      }),
      tap(([[action, state], userId]) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        DEBUG_LOGS && console.log(`${PAGE} post-filter load proj - action:`, action, state);
      }),
      switchMap(([[{ id }, state], userId]) =>
        // GETPROJECT OK
        this.projectApi.getProject(id).pipe(
          map((project) => projectActions.loadByIdSuccess({ project, userId })),
          catchError((error) => of(projectActions.loadFail({ error: error.message, id })))
        )
      )
    )
  );

  /**
   * Load Preview By ID
   */
  loadProjectPreviewById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.loadPreviewById.type),
      // Combine ProjectState with userId to check if this is adding MINE
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectProjectState)),
          withLatestFrom(this.store$.select(getUserId))
        )
      ),
      map(([[action, state], userId]) => {
        DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, action, state);
        return {
          id: action.id,
          /** as string[] was necessary due to ngrx entity key being of type number or string... ugg */
          stateIds: state.ids as string[],
          stateEntities: state.entities,
          userId,
        };
      }),
      // stop here if it's already loaded...
      filter(
        ({ id, stateIds, stateEntities, userId }) =>
          // true if NOT the id is there, is an entity, is entity.field
          !(stateIds.indexOf(id) > 0 && stateEntities[id] && stateEntities[id]['title'])
      ),
      tap(({ id, stateIds, stateEntities, userId }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        DEBUG_LOGS && console.log(`${PAGE} post-filter load proj:`, { id, stateIds, stateEntities, userId });
      }),
      mergeMap(({ id, stateIds, stateEntities, userId }) =>
        // GETPROJECT OK
        this.projectApi.getProjectPreview(id).pipe(
          map((project) => projectActions.loadByIdSuccess({ project, userId })),
          catchError((error) => of(projectActions.loadFail({ error, id })))
        )
      )
    )
  );

  /** SelectByIdAction -> get latest from DB
   * when selecting a Project, be sure the entity in the store has a description
   * if not, grab the full item from the api
   */
  selectProjectById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.selectById.type),
      // Combine ProjectState with userId to check if this is adding MINE
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectProjectState)),
          withLatestFrom(this.store$.select(getUserId))
        )
      ),
      map(([[action, state], userId]) => {
        DEBUG_LOGS && console.log(`${PAGE} action:`, { userId, action });
        // this.environService.setActiveProjectId(action.id); // doing this in environ.reducers
        return {
          id: action.id,
          /** as string[] was necessary due to ngrx entity key being of type number or string... ugg */
          stateIds: state.ids as string[],
          stateEntities: state.entities,
          userId,
        };
      }),
      // stop here if it's already loaded...
      filter(
        ({ id, stateIds, stateEntities, userId }) =>
          // true if NOT the id is there, is an entity, is entity.field
          !(stateIds.indexOf(id) > 0 && stateEntities[id] && stateEntities[id][FIELD_TO_DETERMINE_FULL_ENTITY])
      ),
      mergeMap(({ id, stateIds, stateEntities, userId }) =>
        // GETPROJECT OK
        this.projectApi.getProject(id).pipe(
          map((project) => projectActions.loadByIdSuccess({ project, userId })),
          catchError((error) => of(projectActions.loadFail({ error, id })))
        )
      )
    )
  );

  /**
   * V1: this works...
   * but, since we're using ngrx-ionic-storage it will hydrate automatically,
   * could just switchMap to LoadSuccessAction
   */

  loadProjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.load.type),
      // Retrieve part of the current state telling us if history is loaded
      withLatestFrom(this.store$.select(selectProjectState)),
      // stop here if it's already loaded & there's projects...
      filter(([action, state]) => state.loading.length > 0 || state.loaded.length < 1 || state.ids.length < 1),
      mergeMap(([action, state]) =>
        this.load({}).pipe(
          map(({ projects, nextToken }) =>
            projectActions.loadSuccess({ projects, selected: null, nextToken, listId: ProjectGroup.Other })
          ),
          catchError((error) => of(projectActions.loadFail({ error })))
        )
      )
    )
  );

  /**
   * @deprecated - use Loading in UserEffect.loginSuccess
   * V4 Load My Projects
   */
  loadMyProjects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectActions.loadMine.type),
        // Retrieve part of the current state telling us if history is loaded
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectProjectState)))),
        tap(([action, state]) => {
          // DEBUG_LOGS &&
          console.warn(`${PAGE} @deprecated action:`, action); //, state);
        })
        // // stop here if it's already loaded & there's projects...
        // filter(([action, state]) => ((Array.isArray(state.loading) && state.loading.includes(ProjectGroup.Mine)) || (Array.isArray(state.loaded) && !state.loaded.includes(ProjectGroup.Mine)) || (!Array.isArray(state.mine) || state.mine.length < 1) || (state.nextTokenMine && state.nextTokenMine.length > 0))),
        // mergeMap(([action, state]) => {
        //   DEBUG_LOGS && console.log(`*** ${PAGE} post-filter mergeMap -> api`);
        //   return this.loadMine().pipe(
        //     map(({projects, nextToken}) => projectActions.loadSuccess({projects, group: ProjectGroup.Mine, nextToken, selected: null})),
        //     catchError(error => of(projectActions.loadFail({ error })))
        //   );
        // }),
        // // if we see an error here, need to reset store
        // // TODO: resetStore Effect to reload the app
        // // catchError(error => of(new projectActions.ResetAction()))
        // catchError(error => {
        //   console.error(error);
        //   return of(projectActions.loadFail({ error }));
        // })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<projectActions.ActionsUnion>,
    private store$: Store<State>,
    private usersApi: UsersApiService,
    private projectApi: ProjectsApiService,
    private projectMemberApi: ProjectCrewApiService,
    private environService: EnvironService,
    private sentryService: SentryService,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * Helper method for Errors
   */
  private captureError(error, msg = ''): void {
    const e = error && Array.isArray(error.errors) && error.errors.length > 0 ? error.errors[0] : error;
    console.warn(`${PAGE} ${msg} captureError`, e);
    this.sentryService.captureError(e);
  }

  /**
   * Helper method for loading from API
   */
  private load({
    limit = 20,
    nextToken = null,
    onlyFeatured = false,
    filters = null,
  }): Observable<{ projects: Project[]; nextToken?: string }> {
    /**
     * @todo re-implement this for WIDGET (but, somewhere else)
     */
    // const silos = this.configService.getSilos();
    // if (Array.isArray(silos) && silos.length > 0) {
    //   DEBUG_LOGS && console.log(`${PAGE} load silos:`, silos);
    //   return this.getProjectsById(silos).map(projects => {
    //     DEBUG_LOGS && console.log(`${PAGE} load getProjectsById:`, projects);
    //     return {
    //       projects: projects
    //     };
    //   });
    // }
    if (onlyFeatured) {
      return this.projectApi.getFeaturedProjects(limit, nextToken, filters).pipe(
        tap((res) => {
          DEBUG_LOGS && console.log(`${PAGE} getFeaturedProjects:`, res.projects);
        }),
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map(({ projects, nextToken }) => ({
          projects,
          nextToken,
        }))
      );
    }

    return this.projectApi.listProjectsPreview({ limit, nextToken, filterFeatured: onlyFeatured, filters }).pipe(
      tap(
        (res) => {
          DEBUG_LOGS && console.log(`${PAGE} load:`, res.projects);
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map(({ projects, nextToken }) => ({
          projects,
          nextToken,
        }))
      )
    );
  }
}
