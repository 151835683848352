<ng-container *ngIf="item; then hasItem; else hasNoItem"> </ng-container>

<ng-template #hasItem>
  <div class="project-slide" [class.active-event]="isEvent" (click)="onClicked($event, item)">
    <div
      class="project-slide__image"
      [style.background-image]="'url(' + item.hero + ')'"
    ></div>
    <div class="project-slide__tint"></div>

    <div *ngIf="role" class="project-slide__role">
      {{ role }}
    </div>
    <div *ngIf="level" class="project-slide__level subscription-color subscription-chip light">
      {{ level }}
    </div>

    <ng-template #defaultItemTpl>
      <div class="project-slide__title">
        {{ item.title }}
      </div>
  
      <div class="project-slide__content">
        <div class="project-slide__content__stats">
          <div class="project-slide__content__stats__item">
            <ion-icon name="play-sharp"></ion-icon>&nbsp;{{ item.views }}
          </div>
          <div class="project-slide__content__stats__item">
            <ion-icon name="heart-sharp"></ion-icon>&nbsp;{{ item.votes }}
          </div>
          <div class="project-slide__content__stats__item">
            <ion-icon name="film-sharp"></ion-icon>&nbsp;{{ 0 }}
          </div>
          <div class="project-slide__content__stats__item">
            <ion-icon name="people-sharp"></ion-icon>&nbsp;{{ item.members && item.members.length ? item.members.length : 0 }}
          </div>
        </div>
        <div class="project-slide__content__text">
          {{ item.mission }}
        </div>
      </div>
    </ng-template>

    <div *ngIf="isEvent else defaultItemTpl" class="project-event">
      <div class="project-slide__title">
        {{ item.title }}
      </div>
      <!-- 
          Owner: Username (not shown if my event on edit studio)
          Status : Active: Expired
          Expires: Renews: 
          Event Date
      -->
      <div class="project-slide__content ion-text-capitalize">
        <div class="project-slide__content__event">
          <div>
            <span class="italic">{{ 'COMMON.OWNER' | translate }}: </span><b>{{ item?.subscriptionBy ?? item.owner }}</b>
          </div>
          <div>
            <span class="italic">{{ 'AUTH.STATUS' | translate }}: </span><b>{{ (item.subscriptionStatus === 1 ? 'COMMON.ACTIVE' : 'COMMON.INACTIVE') | translate }}</b>
          </div>

          <!-- TODO:
              COMMON.EXPIRES 
              COMMON.RENEWS -->
          <!-- <div *ngIf="item">
            <span class="italic">{{ 'COMMON.EXPIRES_OR_RENEWS' | translate }}: </span><b>{{ 'GET DATE' }}</b>
          </div> -->
          <!-- <div>
            <span class="italic">{{ 'AUTH.PLANS.SUBSCRIPTION_LEVEL' | translate }}: </span><b>{{ project.eventConfig?.subscriptionLevel ?? project.subscriptionLevel }}</b>
          </div> -->
          <div>
            <span class="italic">{{ 'COMMON.EVENT_DATE' | translate }}: </span>{{ item.eventDate }}
          </div>
        </div>
        <!-- <div class="project-slide__content__text">
          {{ item.mission }}
        </div> -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #hasNoItem>
  <div class="project-slide"></div>
</ng-template>
