import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-poster-image-selector',
  templateUrl: './poster-image-selector.component.html',
  styleUrls: ['./poster-image-selector.component.scss'],
})
export class PosterImageSelectorComponent {
  @Input() posters: string[];
  @Input() currentPoster: string;

  constructor(private modalCtrl: ModalController) {}

  /**
   * to display the uploaded poster as well.
   * @note: uploaded posters aren't displayed anymore if they aren't selected the next time.
   */
  getPosters() {
    return this.currentPoster && this.posters.indexOf(this.currentPoster) !== -1
      ? [...new Set([this.currentPoster, ...this.posters])]
      : this.posters;
  }

  selectPoster(posterUrl: string) {
    this.modalCtrl.dismiss({
      selected: posterUrl,
    });
  }
}
