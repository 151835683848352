/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils';
import { FlexiblePartialItem } from '@shared/models/layout.model';

@Pipe({
  name: 'searchItems',
})
export class SearchItemsPipe implements PipeTransform {
  transform(items: FlexiblePartialItem[], searchText: string, searchFields: string[] = []): FlexiblePartialItem[] {
    // console.log(`[searchItemsPipe] transform`, {items, searchText, searchFields});
    if (!items || !Array.isArray(items)) return [];
    if (!searchText) return items;
    if (searchFields.length < 1) {
      return items;
      // searchText = searchText.toLowerCase();
      // ...this would be string[] but not what we're dealing with here..
      // return items.filter((item) =>
      //   item && typeof item.toLowerCase === 'function' ? item.toLowerCase().includes(searchText) : false
      // );
    }
    return Utils.filterPropsByValue(searchFields, items as FlexiblePartialItem[], searchText);
  }
}
