/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';
import { SubscriptionLevel, SubscriptionStatus } from '@billing/shared';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { Project } from '@projects/shared/project.model';
import { SubscriptionViewModel } from '@billing/store';
import { selectMyProjectsActiveEvent } from '@store/selectors/projects.selectors';
import { UserService } from '@services/user.service';

@Component({
  selector: 'fs-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit {
  @Input() showTitle = true;
  @Input() showOwner = true;
  @Input() eventProjects: (Project & { canEdit: boolean })[] = [];
  @Input() subscription: SubscriptionViewModel = {
    isActive: SubscriptionStatus.InActive,
    level: SubscriptionLevel.Unsubscribed,
  };

  @Output() editEventSubscription = new EventEmitter();

  routeProjectDetail = `/${PROJECT_DETAIL_ROUTE}`;
  projectsWithEvents$: Observable<(Project & { canEdit: boolean })[]>;

  constructor(private store: Store<State>, private userService: UserService) {}

  ngOnInit() {
    this.projectsWithEvents$ = this.store
      .select(selectMyProjectsActiveEvent)
      .pipe(filter((p) => typeof p !== 'undefined'))
      .pipe(
        withLatestFrom(this.userService.userId$),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map(([projects, userId]) =>
          projects.map((project) => ({
            ...project,
            canEdit: false, //this.projectService.isProjectAdmin(project, userId),
          }))
        )
      );
  }

  createProjectEvent(event) {
    console.log(`createProjectEvent`, event);
  }
}
