/** @format */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, selectSubscriptionLevelNameTranslateKey } from '@billing/store';

@Component({
  selector: 'fs-subscription-mini-status-edit',
  templateUrl: './subscription-mini-status-edit.component.html',
  styleUrls: ['./subscription-mini-status-edit.component.scss'],
})
export class SubscriptionMiniStatusEditComponent {
  @Input() color = 'light';
  @Input() showEditButton = true;
  @Input() showPlan = true;
  @Input() isDisabled = false;
  @Input() level = '';
  @Output() clicked = new EventEmitter<Event>();

  // get us eventDate plz

  subscriptionLevel$: Observable<string> = this.store.select(selectSubscriptionLevelNameTranslateKey);
  // .pipe(map((lvl) => this.level || lvl));

  // make sure that the ionic trigger is unique in html
  triggerId = Math.random().toString(36).substring(2);

  get buttonTitleTranslateKey() {
    return this.showPlan ? 'AUTH.PLANS.EDIT_PROFILE_SUBSCRIPTION' : 'STUDIO.EDIT';
  }

  constructor(private store: Store<State>) {}

  onClick(event) {
    if (!this.isDisabled) {
      this.clicked.emit(event);
    }
  }
}
