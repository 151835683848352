/**
 * Question Model
 *
 * @format
 */

const PAGE = '[QuestionModel]';

export interface IQuestionsOptionRet {
  key: string;
  vals: Array<string>;
}

export interface IQuestionOption {
  text: string;
  text_create?: string; // if diff on StackCreatePage
  val: string; // format as 'key=val&key=val' or 'val&val'
  next?: number;
  input?: number;
  action?: string;
  optionType?: string;
  selected?: boolean;
}
export interface IQuestionButton {
  text: string;
  text_create?: string; // if diff on StackCreatePage
  className?: string;
  next?: number;
  link?: string;
}

export class Question {
  id: number = -1;
  required: boolean = false;
  test: string = '';
  title: string = '';
  title_create?: string; // if diff on StackCreatePage
  ques: string = '';
  ques_create?: string = ''; // if diff on StackCreatePage
  optionType: string = ''; // swipe | checklist | mixed
  optionTypeCreate?: string; // if diff on StackCreatePage
  options: IQuestionOption[] = []; // can also be suggested tags
  multi_tags?: string[];
  btns: IQuestionButton[] = [];
  environKey: string; // key for environData

  constructor(fields: object) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      if (fields.hasOwnProperty(f)) {
        this[f] = fields[f];
      }
    }
  }

  /**
   * expect either environKey or Option.val format as 'key=val&key=val' or 'val&val'
   * @param ques the question
   */
  static getEnvironKey(ques: Question): string {
    if (ques.environKey) {
      return ques.environKey;
    }
    if (ques.options && ques.options.length > 0) {
      let keys = [];
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < ques.options.length; i++) {
        const [key, val] = ques.options[i].val.split('=');

        if (!key) {
          continue;
        }
        if (!val) {
          // only one thing was passed, val(s)
          continue;
        }
        keys = this.mergeUnique(keys, [key]);
      } //end for
      if (keys.length > 1) {
        console.log(`${PAGE} getEnvironKey TODO: HANDLE MORE THAN ONE!`, keys);
      }
      if (keys.length > 0) {
        return keys[0];
      }
    }
    return '';
  }

  /**
   * expect Option.val format as 'key=val&key=val' or 'val&val'
   * @param str val to extract return
   */
  static getOptionRets(str: string): IQuestionsOptionRet[] {
    const ret = [];
    // let ret:[IQuestionsOptionRet] = [{
    //   key: "",
    //   vals: []
    // }];

    //get defs split by &
    const defs = str.split('&');
    if (defs.length < 1 || !defs[0]) {
      console.log(`${PAGE} getOptionVals NO PAYLOAD to update!`, defs);
      return ret;
    }

    const getArrayOfVals = (strVals: string): Array<string> => strVals.split(',');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < defs.length; i++) {
      const [key, val] = defs[i].split('=');

      if (!key) {
        continue;
      }
      if (!val) {
        // only one thing was passed, val(s)
        ret.push({ key: '', vals: getArrayOfVals(key) });
        continue;
      }

      // let remove = false;
      // if (key.indexOf("!") === 0) {
      //   remove = true;
      //   key = key.substring(1);//remove the not!
      // }

      ret.push({ key, vals: getArrayOfVals(val) });
    } //end for defs

    return ret;
  }

  /**
   * expect Option.val format as 'key=val&key=val' or 'val&val'
   * @param str val to extract return
   */
  static getOptionValsArray(str: string): Array<string> {
    let arr = [];

    //get defs split by &
    const defs = str.split('&');
    if (defs.length < 1 || !defs[0]) {
      console.log(`${PAGE} getOptionVals NO PAYLOAD to update!`, defs);
      return arr;
    }

    const getArrayOfVals = (strVals: string): Array<string> => strVals.split(',');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < defs.length; i++) {
      const [key, val] = defs[i].split('=');

      if (!key) {
        continue;
      }
      if (!val) {
        // only one thing was passed, val(s)
        arr = this.mergeUnique(arr, getArrayOfVals(key));
        continue;
      }

      arr = this.mergeUnique(arr, getArrayOfVals(val));
    } //end for defs

    return arr;
  }

  private static mergeUnique(arr1, arr2) {
    return arr1.concat(arr2.filter((item) => arr1.indexOf(item) === -1));
  }
}
