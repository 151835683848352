/** @format */

import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { LAYOUT_TYPE, ITEM_TYPE, DiscoverCardClick, LAYOUT_SETTINGS } from '@shared/models/layout.model';
import { STACK_SEARCH_FIELDS, Stack } from '@shared/models/stack.model';
import { NavController } from '@ionic/angular';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { EDIT_ROUTE, WATCH_ROUTE } from '@app/app-routing.module';

@Component({
  selector: 'app-stack-list',
  templateUrl: './stack-list.component.html',
  styleUrls: ['./stack-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackListComponent {
  public itemTypes = ITEM_TYPE;
  public layoutTypes = LAYOUT_TYPE;
  public searchFields = STACK_SEARCH_FIELDS;

  @Input() items: Stack[] | undefined; // undefined => loading state, array => fully loaded
  @Input() canLoadMore = false;
  @Input() isLoading = false;
  @Input() layout: LAYOUT_SETTINGS;
  @Input() heading?: string;
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;
  @Input() canShare = true;
  @Input() isProjectMember = false;
  @Output() loadMore = new EventEmitter<void>();
  @Output() newStackClick = new EventEmitter<void>();
  @Output() itemClick = new EventEmitter<DiscoverCardClick>();

  constructor(private navCtrl: NavController, private toaster: ToasterService) {}

  onClickItem(event: DiscoverCardClick) {
    this.itemClick.emit(event);
    if (event.item && (event.item as Stack).dtePublished) {
      this.navWatchStack(event);
    } else {
      this.navEditStack(event);
    }
  }

  addNewStackButtonClick() {
    this.newStackClick.emit();
  }

  navWatchStack(event: DiscoverCardClick) {
    const stack: Stack | null = event && event.item ? (event.item as Stack) : null;
    if (stack && stack.projectId && stack.stackId) {
      this.navCtrl.navigateForward(`/${WATCH_ROUTE}/${stack.projectId}/${stack.stackId}`);
    } else {
      this.toaster.present(`Oops! There was an error opening that Stack. Please Try Again.`);
    }
  }
  navEditStack(event: DiscoverCardClick) {
    const stack: Stack | null = event && event.item ? (event.item as Stack) : null;
    if (stack && stack.projectId && stack.stackId) {
      this.navCtrl.navigateForward(`/${EDIT_ROUTE}/${stack.projectId}/${stack.stackId}`);
    } else {
      this.toaster.present(`Oops! There was an error opening that Stack. Please Try Again.`);
    }
  }
}
