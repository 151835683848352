/**
 * eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

/* eslint-disable @angular-eslint/directive-selector, @typescript-eslint/naming-convention */
/**
 * An image directive based on
 * https://medium.com/@sub.metu/angular-fallback-for-broken-images-5cd05c470f08
 */
import { Directive, Input, HostBinding } from '@angular/core';
import { DEFAULT_USER_AVATAR } from '@shared/models/user.model';

@Directive({
  selector: 'img[fallback]',
  /**
   * @todo refactor to support https://angular.io/styleguide#style-06-03
   * HostBinding & HostListener instead of host metadata
   */
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(error)': 'onError()',
    '(load)': 'load()',
    '[src]': 'src',
    // '[attr.src]': 'finalImage'    //the attribute of the host element we want to update. in this case, <img 'src' />
  },
})
export class ImageFallbackDirective {
  @Input() src: string;
  @Input() fallback: string = DEFAULT_USER_AVATAR;
  // @Input() loading: string = '/assets/images/loading-spinner.svg';
  // @Input('img-src') targetSource: string;
  @HostBinding('class') className;

  // finalImage: any; //property bound to our host attribute.

  // ngAfterViewInit() {
  //   console.log(`imgFallback targetSource='${this.src}' fallback='${this.fallback}'`);
  // }

  onError() {
    // console.log(`ImgFallback onError ${this.src}`);
    this.src = this.fallback;
    this.className = 'image-fallback';
  }
  load() {
    this.className = 'image-loaded';
  }

  /**
   * when getting from S3, we will see a 404 if DNE
   * send an xhr for HEAD of the image
   */
  checkUrlExists(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('HEAD', url, true);

      xhr.onreadystatechange = () => {
        if (xhr.readyState >= XMLHttpRequest.HEADERS_RECEIVED && xhr.status >= 200 && xhr.status < 400) {
          // console.log(`xhr`, { status: xhr.status, readyState: xhr.readyState, url });
          resolve(url);
        } else if (xhr.readyState >= XMLHttpRequest.HEADERS_RECEIVED && xhr.status >= 400) {
          // console.log(`xhr error`, { status: xhr.status, readyState: xhr.readyState, url });
          reject(xhr.status);
        }
        xhr.abort();
      };
      xhr.onerror = (e) => {
        reject(e);
      };
      xhr.send();
    });
  }
}
