<fs-base-modal title="" #modal>
  <div *ngIf="(isLoggedIn$ | async) === true; else notLoggedIn" class="container reduced-top-margin">
    <!-- <h2 class="ion-padding-horizontal ion-margin-horizontal top-margin">{{ 'COMMON.PUBLISH_STACK' | translate }}</h2> -->
    <ng-container 
      *ngIf="stack$ | async as stack">
      <ng-container 
        *ngIf="isProjAdmin || (stack?.userId && (userId$ | async) === stack.userId); else notMine">

        <div *ngIf="isSaving || isFormSaving" class="indicate-saving-top">
          <ion-spinner></ion-spinner>
          {{ 'COMMON.SAVING' | translate }}
        </div>

        <div *ngIf="message" class="error">
          {{ message }}
        </div>

        <app-stack-form-details 
          [isPublishing]="isPublishing"
          [isSaving]="isSaving"
          (isFormSaving)="isFormSaving = $event"
          (publish)="confirmPublishStack($event)"
          (cancel)="closeModal({ stack })"
        ></app-stack-form-details>
        
      </ng-container>
    </ng-container>
    <ng-template #notMine>
      <p class="ion-text-center">
        {{ 'EDITOR.ERROR_NOT_YOURS' | translate }}
      </p>
    </ng-template>
  </div>
  
  <ng-template #notLoggedIn>
    <!-- LOGGED OUT -->
    <div class="container top-margin">
      <h2 class="ion-padding-horizontal ion-margin-horizontal">{{ 'AUTH.LOGIN_TO_CONTINUE' | translate }}</h2>
      <fs-login-form></fs-login-form>
      <!-- [routeOnSuccess]="returnUrl" -->
    </div>
  </ng-template>

</fs-base-modal>