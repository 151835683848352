<ion-list class="light users-popover" lines="none">
  <ion-item
    *ngFor="let user of users"
    button
    small
    detail="false"
    (click)="viewUserProfile(user.userId)"
  >
    <ion-avatar class="users-popover__avatar" slot="start">
      <img
        class="users-popover__avatar__image"
        [src]="user.userId | avatarUrl:'dark'"
        [fallback]="avatarFallback"
        imageFadeIn
        [alt]="user?.name ?? user.userId"
        [title]="user?.name ?? user.userId"
      />
    </ion-avatar>
    <!-- <ion-text style="padding-left: 0; white-space: nowrap" slot="start"> -->
    <ion-label>
      {{ user?.name ?? user.userId }}
    </ion-label>
  </ion-item>
</ion-list>
