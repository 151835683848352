/** @format */

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UpdatesService } from '@services/updates.service';
import { environment } from '../../../../environments/environment';

@Component({
  standalone: true,
  selector: 'app-version-reload',
  templateUrl: './version-reload.component.html',
  styleUrls: ['./version-reload.component.scss'],
  imports: [IonicModule, CommonModule],
})
export class VersionReloadComponent implements OnInit {
  public version: string; //get App VERSION from ENV

  constructor(private updatesService: UpdatesService) {}

  ngOnInit(): void {
    this.version = environment && environment.version ? 'v' + environment.version : '[dev]';
  }

  async presentReloadOptions() {
    return await this.updatesService.presentReloadOptions();
  }
}
