/** @format */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import * as ViewstateActions from '../actions/viewstate.actions';

export const featureKey = 'viewstate';

/**
 * The Filter coming from the topnav search input
 */
export const GLOBAL_QUERY_ID = 'global';

export enum FilterEntityTypes {
  Featured = 'FEATURED',
  Recent = 'RECENT',
  Unused = 'UNUSED / NOT USED',
  Mine = 'MINE',
  StackDrafts = 'STACK_DRAFTS',
  MostViewed = 'VIEWED',
  // CollabDrafts = 'COLLAB_DRAFTS', // use StackDrafts
  Private = 'PRIVATE',
  /** not ready yet @see app.config.ENABLE_TRENDING_STACKS */
  // Trending = 'TRENDING',
}

export interface FilterEntity {
  /**
   * id: PageType = “discover” | “project-{ID}”;
   * e.g. single project pages with filters could be saved as page type “project-{ID}”
   * @required - the store needs the id for the Entity
   * Error caused: The entity passed to the `selectId` implementation returned undefined
   */
  id: string;
  type?: FilterEntityTypes; // 'FEATURED' | 'RECENT' | 'NOT USED';
  q?: string;
  tags?: string; // e.g. “wildlife,sea,land” concatenated by “,”
  users?: string; // e.g. “simwicki,jd” concatenated by “,”
  projectId?: string; // we need the projectId for api calls on project page
  userId?: string; // we need the userId for Studio api calls
  isApproved?: boolean; // Project.isModerated => stack.isApproved
  isCollaborative?: number; // Project.isModerated => stack.isCollaborative
  /** for stacksByUser */
  isPublic?: boolean;
  isPrivate?: boolean;
  isDraft?: boolean;
  isTopDrawer?: boolean;
}

interface TourSeenState {
  overview: boolean;
  capture: boolean;
  widgetSplash: boolean;
}

export interface State extends EntityState<FilterEntity> {
  tourSeen: TourSeenState;
  acceptedGDPR: boolean;
}

/**
 * @note In this case this would be optional since primary key is id
 * but it's a bit nice to have the logic there to be modified, rather than hidden in docs i don't want to read ;)
 */
export function selectFilterId(a: FilterEntity): string {
  return a.id;
}
// export const adapter: EntityAdapter<FilterEntity> = createEntityAdapter<FilterEntity>();
export const adapter: EntityAdapter<FilterEntity> = createEntityAdapter<FilterEntity>({
  selectId: selectFilterId,
});

export const initialState: State = adapter.getInitialState({
  // entities of type filter entity are already added by the adapter
  tourSeen: {
    overview: false,
    capture: false,
    widgetSplash: false,
  },
  acceptedGDPR: false,
});

const viewstateReducer = createReducer(
  initialState,

  on(ViewstateActions.gdprAccepted, (state) => ({
    ...state,
    acceptedGDPR: true,
  })),

  /*
   * Tour Seen
   */

  on(ViewstateActions.tourSeenOverview, (state) => ({
    ...state,
    tourSeen: { ...state.tourSeen, overview: true },
  })),

  on(ViewstateActions.resetTourSeenOverview, (state) => ({
    ...state,
    tourSeen: { ...state.tourSeen, overview: false },
  })),

  on(ViewstateActions.tourSeenCapture, (state) => ({
    ...state,
    tourSeen: { ...state.tourSeen, capture: true },
  })),

  on(ViewstateActions.tourSeenWidgetSplash, (state) => ({
    ...state,
    tourSeen: { ...state.tourSeen, widgetSplash: true },
  })),

  /*
   * Filters
   */

  on(ViewstateActions.setFilter, (state, { filter }) => {
    // only set it of it's valid
    if (filter && filter.id) {
      // need to throttle this call?
      return adapter.upsertOne(filter, state);
    } else {
      console.warn(`setFilter invalid - missing id`);
    }
    return state;
  }),

  on(ViewstateActions.resetFilter, (state, { id }) => {
    // only set it of it's valid
    if (id && state.entities[id]) {
      return adapter.upsertOne({ ...state.entities[id], q: null, tags: '', users: '' }, state);
    }
    return state;
  }),

  on(ViewstateActions.removeFilter, (state, { id }) => adapter.removeOne(id, state)),
  on(ViewstateActions.resetAll, () => ({
    ...initialState,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return viewstateReducer(state, action);
}

/** @note MVP-867-upgrade-ionic-angular changed <AppState> to <State> */
export const selectViewState = createFeatureSelector<State>(featureKey);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectViewState);

// selectors moved to separate file

// export const selectTourSeenOverview = createSelector(selectViewState, (state) => state.tourSeen.overview);

// export const selectTourSeenCapture = createSelector(selectViewState, (state) => state.tourSeen.capture);

// export const selectTourWidgetSplash = createSelector(selectViewState, (state) => state.tourSeen.widgetSplash);

// export const getLists = createSelector(
//   selectEntities,
//   selectIds,
//   (entities, ids) => (ids as string[]).map(id => entities[id])
// );

// export const selectAcceptedGDPR = createSelector(selectViewState, (state) => state.acceptedGDPR);

// /**
//  * Get By ID - wrap the selector inside a factory function to create different instances of the selector
//  * https://ngrx.io/guide/store/selectors#using-selectors-with-props
//  *
//  * USAGE:
//  * this.store.select(selectFilter(), { id: 'filterId' }))
//  *
//  * MVP-970 ngrx deprecated
//  */
// export const selectFilter = () =>
//   createSelector(selectEntities, (entities, props) => (entities && props && props.id ? entities[props.id] : {}));
