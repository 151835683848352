/** @format */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { LAYOUT_TYPE, ITEM_TYPE, LAYOUT_SETTINGS } from '@shared/models/layout.model';
import { STACK_SEARCH_FIELDS } from '@shared/models/stack.model';
import { ToasterService } from '@services/toaster.service';
import { DiscoverCardClick } from '@shared/models/layout.model';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';
import { Clip } from '@shared/models/clip.model';
import { Project } from '@projects/shared/project.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clip-list',
  templateUrl: './clip-list.component.html',
  styleUrls: ['./clip-list.component.scss'],
})
export class ClipListComponent {
  public itemTypes = ITEM_TYPE;
  public layoutTypes = LAYOUT_TYPE;
  public searchFields = STACK_SEARCH_FIELDS;

  @Input() items: Partial<Clip>[] | undefined; // undefined => loading state, array => fully loaded
  @Input() heading?: string;
  @Input() layout: LAYOUT_SETTINGS;
  @Input() canEdit: boolean = false;
  @Input() searchText?: string;
  @Input() canLoadMore = false;
  @Input() isLoading = false;
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;
  @Output() loadMore = new EventEmitter<void>();

  constructor(private navCtrl: NavController, private toaster: ToasterService, private translate: TranslateService) {}

  onClickItem(event: DiscoverCardClick) {
    console.warn('TODO: Why is this nav project? this was clicked from a clip...?', event);
    this.navProject(event);
  }

  private navProject(event: DiscoverCardClick) {
    if (event && event.item && (event.item as Project).id) {
      this.navCtrl.navigateForward([`/${PROJECT_DETAIL_ROUTE}`, (event.item as Project).id]);
    } else {
      this.toaster.present(this.translate.instant('ERRORS.OPENING_PROJECT'));
    }
  }
}
