/** @format */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ShareResponse } from '../social-sharing.model';

@Component({
  selector: 'app-copy-to-clipboard-feedback-popover',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './copy-to-clipboard-feedback-popover.component.html',
  styleUrls: ['./copy-to-clipboard-feedback-popover.component.scss'],
})
export class CopyToClipboardFeedbackPopoverComponent {
  @Input() copyResult: ShareResponse;
}
