/** @format */

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { slideInOut } from '@app/animations';
import { Stack, STACK_DEFAULT_POSTER } from '@shared/models/stack.model';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as mystackActions from '@store/actions/mystack.actions';
import * as stackActions from '@store/actions/stacks.actions';
import { selectAddToStackPayloadMeta, selectStackEdit } from '@store/selectors/stacks.selectors';
import { UserService } from '@services/user.service';
import { ROOT_EDIT_URL } from '@app/app-routing.module';
import { NavController, PopoverController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { FormStackCreate } from '@shared/components/form-stack-create/form-stack-create.component';
import { StackPopoverActionsComponent } from '@shared/components/fs-carousel/fs-slide/stack-slide/stack-popover-actions/stack-popover-actions.component';
import { Utils } from '@shared/utils';

const DEBUG_LOGS = false;
/** allow you to stay where you were, watching or discovering without navigating, the flash_mystack should get your attn? */
const NAV_AFTER_ADD_CLIPS = false;

@Component({
  selector: 'app-top-navbar-drawer',
  templateUrl: './top-navbar-drawer.component.html',
  styleUrls: ['./top-navbar-drawer.component.scss'],
  /** If you use an animation in a template, you also must list that animation in the component's animations metadata ... every time. */
  animations: [slideInOut], // <-- Don't forget!
})
export class TopNavbarDrawerComponent implements OnInit, OnDestroy {
  @Input() label: string; // = 'Stack Drafts'; // will be translated
  @Input() showLabel = true;
  @Input() theme: 'light' | 'dark' = 'dark';
  @Input()
  set items(value: Stack[]) {
    DEBUG_LOGS && console.log(`set items`, value);
    this._items = Array.isArray(value) ? value : [];
    clearTimeout(this.showFormTimeout);
    if (this._items.length > 0) {
      this.scrollItemIntoView();
    } else {
      // it's an empty array so there's none, show the form to create
      // wait a moment to allow ui to ready
      this.showFormTimeout = setTimeout(() => {
        if (this._items.length < 1) {
          console.warn('timeout showForm');
          this.showingCreateForm = true;
        }
      }, 1200);
    }
  }
  get items(): Stack[] {
    return this._items;
  }
  @Input() handleActions = true;
  @Input() selectedItemSuccess = false;
  @Input() showingCreateForm = false;
  @Output() selected = new EventEmitter<Partial<Stack>>();
  @Output() newItem = new EventEmitter<Partial<Stack>>();
  @Output() closed = new EventEmitter<void>();

  @ViewChild('drawerItems', { static: false }) drawerItems: ElementRef;
  @ViewChild(FormStackCreate) formStackCreate: FormStackCreate;

  publishedItem: Partial<Stack>;
  // listId = 'top_navbar_drawer';
  selectedStackId = '';
  selectedStackDraft$ = new BehaviorSubject<string>('');
  // addToStackPlaylist$ = this.store.select(selectAddToStackPlaylist); // replaced with Payload.playlist
  addToStackPayload$ = this.store.select(selectAddToStackPayloadMeta);

  isLoadingCreate = false;
  isCreateFormValid = false;
  // get isCreateFormValid(): boolean {
  //   return this.formStackCreate && this.formStackCreate.isFormValid;
  // }

  popoverOpen$ = new Subject<boolean>();

  isLoggedIn$ = this.userService.isLoggedIn$;

  returnUrl = '';
  stackDefaultPoster = STACK_DEFAULT_POSTER;

  private showFormTimeout;
  private _items: Stack[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private userService: UserService,
    private _elementRef: ElementRef,
    private navCtrl: NavController,
    private popoverCtrl: PopoverController,
    private translate: TranslateService,
    public router: Router
  ) {}

  ngOnInit() {
    this.returnUrl = this.router.url;
    // //due to being rendered by parent ngIf=isDrawerExpanded, this will get triggered mutliple times..
    // // doing this in topnav instead
    // this.list$ = this.store.select(selectList(this.listId));
    // this.items$ = this.store.select(selectStackListItems(this.listId));
    this.store
      .select(selectStackEdit)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(Boolean),
        distinctUntilChanged((a: Stack, b: Stack) => a && b && a.projectId === b.projectId && a.stackId === b.stackId)
      )
      .subscribe((stack: Stack) => {
        const { projectId, stackId } = stack; //splitStackId(id);
        DEBUG_LOGS &&
          console.log(`draft stackEdit:`, { projectId, stackId, showingCreateForm: !!this.showingCreateForm });
        // check if this is editingPublished or a draft..
        // add stack if not draft
        if (
          !stack.error &&
          stack.dtePublished &&
          !this.items.find((s) => s && s.projectId === projectId && s.stackId === stackId)
        ) {
          console.log(`drawer currentEdit not draft`, { projectId, stackId, stack });
          this.publishedItem = stack;
        } else {
          // console.log(`drawer currentEdit is draft`, { projectId, stackId, stack });
          this.publishedItem = null;
        }
        this.selectedStackId = stackId;

        if (!this.showingCreateForm) {
          this.selectedStackDraft$.next(stackId);
          this.scrollItemIntoView();
        }
      });

    // note: loadFilteredStacks for list done in parent so we don't rebuild each time

    this.addToStackPayload$
      .pipe(
        takeUntil(this.onDestroy$)
        // filter((playlist) => Array.isArray(playlist) && playlist.length > 0)
      )
      .subscribe(({ playlist, ...args }) => {
        DEBUG_LOGS &&
          console.log(`addToStackPayload:`, { playlist, ...args, showingCreateForm: !!this.showingCreateForm });
        const {
          // projectId = '',
          // title = '',
          // description = '',
          // poster = '',
          isCreateNew = false,
        } = args;
        let translatePath = '';
        if (isCreateNew) {
          translatePath = 'STACK.ACTIONS.RESTACK_NEW_DRAFT';
        } else if (Array.isArray(playlist) && playlist.length > 1) {
          translatePath = 'STACK.ACTIONS.ADD_ALL_TO_STACK_DRAFTS';
        } else if (Array.isArray(playlist) && playlist.length === 1) {
          translatePath = 'STACK.ACTIONS.ADD_ALL_TO_STACK_DRAFTS';
        }

        if (translatePath) {
          this.translate
            .get(translatePath)
            .pipe(take(1))
            .subscribe((text) => {
              this.label = text;
            });
        } else {
          this.label = '';
        }

        if (isCreateNew) {
          console.log({ msg: 'isCreateNew', showingCreateForm: !!this.showingCreateForm });
          this.selectedStackDraft$.next('');
          this.showingCreateForm = true;
        } else {
          this.selectedStackDraft$.next(this.selectedStackId);
          this.showingCreateForm = false;
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  navEditor({ projectId, stackId }) {
    DEBUG_LOGS && console.log(`drawer navEditor`, { projectId, stackId });
    if (this.returnUrl.startsWith('/stack/edit')) {
      this.navCtrl.navigateForward([ROOT_EDIT_URL, projectId, stackId], {
        animated: false,
        state: { hideDrawerOnLoad: true },
      });
    } else {
      this.navCtrl.navigateForward([ROOT_EDIT_URL, projectId, stackId], { state: { hideDrawerOnLoad: true } });
    }
  }

  selectItem(evt: MouseEvent, item: Partial<Stack>) {
    evt.preventDefault();
    // evt.stopPropagation();
    if (item && item.stackId) {
      DEBUG_LOGS && console.log(`Drawer draft selected`, item);
      const { projectId, stackId } = item;
      this.selectedStackDraft$.next(stackId);

      // if we have full routing & we're not just selecting it, go there
      if (this.handleActions && projectId && stackId) {
        this.addToStackPayload$.pipe(take(1)).subscribe(({ playlist }) => {
          if (Array.isArray(playlist) && playlist.length > 0) {
            this.store.dispatch(stackActions.addToStackIdPlaylist({ projectId, stackId }));
            this.showSuccessDrawerItem({ thenClose: true, thenNavEditor: NAV_AFTER_ADD_CLIPS, projectId, stackId });
          } else {
            !environment.production && console.log(`Drawer No Playlist -> navEditor`);
            this.resetElements();
            this.closeDrawer();
            this.navEditor({ projectId, stackId }); // this will selectIdEdit on nav
          }
          // do we want to do this here? why not allow adding to a non-selected stack?
          // this.store.dispatch(stackActions.selectIdEdit({ projectId, stackId }));
        });
      }
      // if (!this.handleActions) {
      //   this.scrollItemIntoView();
      // }
    }
    this.selected.emit(item);
  }

  onNewClicked(evt) {
    try {
      evt.preventDefault();
      // evt.stopPropagation();
      this.selectedStackDraft$.next('');

      if (!this.showingCreateForm) {
        // expand the form
        this.showingCreateForm = true;
      } // else evt propogated
      this.scrollItemIntoView();
    } catch (error) {
      console.warn(error);
    }
  }

  continueNew(evt) {
    try {
      evt.preventDefault();
      // evt.stopPropagation();//doesn't stop from item parent
      this.formStackCreate.createStack();
    } catch (error) {
      console.warn(error);
    }
  }

  onCreateFormLoading(isLoading: boolean) {
    this.isLoadingCreate = !!isLoading;
  }

  onCreateFormValid(isValid: boolean) {
    this.isCreateFormValid = !!isValid;
  }

  /**
   * Item Create Success - move this item into view and select
   */
  onCreateFormSuccess(stack) {
    DEBUG_LOGS && console.log(`createSuccess`, stack);

    this.store.dispatch(mystackActions.newMyStack({ stack }));

    const { projectId, stackId, title, playlist } = stack;
    this.selectedStackDraft$.next(stackId);

    // add clips to mystack, nav to Editor Details
    this.showingCreateForm = false;
    // the createForm should have already added the playlist..
    if (Array.isArray(playlist) && playlist.length > 0) {
      // this.store.dispatch(
      //   mystackActions.addClipIds({ ids: playlist.map((p) => ({ projectId: p.projectId, id: p.id })) })
      // );
    }
    if (this.handleActions) {
      this.showSuccessDrawerItem({ thenClose: true, thenNavEditor: NAV_AFTER_ADD_CLIPS, projectId, stackId });
      // do this on route, allow the DB to exist..
      // except some routes don't nav immediately, so do this now
      this.store.dispatch(stackActions.selectIdEdit({ projectId, stackId }));
    }

    // this.scrollItemIntoView();
    this.newItem.emit({ projectId, stackId, title, playlist });
  }

  delayedCloseDrawer(delay = 300) {
    clearTimeout(this._closeTimeout);
    this._closeTimeout = setTimeout(() => {
      this.closeDrawer();
    }, delay);
  }

  closeDrawer() {
    if (this.handleActions) {
      this.store.dispatch(stackActions.resetAddToStackPlaylist());
    }
    this.showingCreateForm = false;
    // reset the selected from New
    this.selectedStackDraft$.pipe(take(1)).subscribe((selectedId) => {
      if (this.selectedStackId && this.selectedStackId !== selectedId) {
        console.log({ msg: 'selectedStackId !== selectedId', selectedId, selectedStackId: this.selectedStackId });
        this.selectedStackDraft$.next(this.selectedStackId);
      }
    });
    this.closed.emit();
  }

  showSuccessDrawerItem({
    thenClose = false,
    thenNavEditor = false,
    projectId,
    stackId,
  }: {
    thenClose?: boolean;
    thenNavEditor?: boolean;
    projectId?: string;
    stackId?: string;
  }) {
    this.selectedItemSuccess = true;
    clearTimeout(this._itemSelectedTimeout);
    this._itemSelectedTimeout = setTimeout(() => {
      this.selectedItemSuccess = false;
      // this.store.dispatch(stackActions.resetAddToStackPlaylist());
      if (thenNavEditor && projectId && stackId) {
        this.navEditor({ projectId, stackId });
        this.closeDrawer();
      } else if (thenClose) {
        this.closeDrawer();
      }
    }, 1200);
  }

  resetElements() {
    this.selectedItemSuccess = false;
  }

  async openPopover(event: MouseEvent, stack) {
    Utils.tryStopPropagation(event);
    Utils.tryPreventDefault(event);
    console.log(`openPopover stack:`, stack);
    this.popoverOpen$.next(true);
    const popover = await this.popoverCtrl.create({
      component: StackPopoverActionsComponent,
      componentProps: {
        stack,
        canShare: false,
        canFlag: false,
        canAddToStack: false,
        // add the moderation actions
        // isModerated: this.isModerated,
        // canAddToStack: this.canPlay,
        // canApprove: this.canApprove,
        // canFeature: this.canModerate,
      },
      event,
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();
    console.log(`dismissed`, { data, url: this.router.url });
    if (data && data.type && data.type === 'edit') {
      if (this.router.url.startsWith('/stack/edit')) {
        // we're already on the edit page, open the publish
        this.closeDrawer();
      }
    }
    this.popoverOpen$.next(false);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _scrollTimeout;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _closeTimeout;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _itemSelectedTimeout;

  private scrollItemIntoView() {
    clearTimeout(this._scrollTimeout);
    this._scrollTimeout = setTimeout(() => {
      try {
        const OFFSET_PADDING = 100;
        // .querySelector('.top-navbar drawer__items .drawer .drawer__items')
        // drawer__items__item--selected
        if (this.drawerItems && this.drawerItems.nativeElement) {
          const container = this.drawerItems.nativeElement;
          // const items = container.getElementsByClassName('drawer__items__item');
          const selected = container.getElementsByClassName('drawer__items__item--selected');
          if (selected && selected.length > 0) {
            DEBUG_LOGS &&
              console.log(`scrollItemIntoView`, {
                offsetLeft: selected[0].offsetLeft,
                scrollLeft: container.scrollLeft,
                selected,
                container,
              });
            // container.scrollLeft = selected[0].offsetLeft;
            this.drawerItems.nativeElement.scrollTo({
              top: 0,
              left:
                selected[0].offsetLeft && selected[0].offsetLeft > OFFSET_PADDING
                  ? selected[0].offsetLeft - OFFSET_PADDING
                  : 0,
              behavior: 'smooth',
            });
          }
        }
      } catch (error) {
        console.log(`Caught Error in scrollItemIntoView`, error);
      }
    }, 500); // 500ms animation
  }
}
