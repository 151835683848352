/** @format */

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProjectAdminComponentGuard } from '@projects/project-admin/project-admin.guard';
// this needs attention before used
// import { NoAuthGuard } from './core/guards/no-auth.guard';

export const DEFAULT_BACK_ROUTE = 'stack/home';
export const NAV_PAGE_ON_LOGIN = 'stack/studio';
/** if the route is home page on login, change to this: */
export const HOME_PAGE_ON_LOGIN_EFFECT = 'stack/studio';
export const STUDIO_PAGE = 'stack/studio';
export const STUDIO_SETTINGS_ROUTE = 'stack/studio/settings';
export const DISCOVER_PAGE = 'stack/discover';
export const HOME_PAGE = 'home';
export const LOGIN_PAGE = 'login';
export const SIGNUP_PAGE = 'signup';
export const CONFIRM_AUTH_PAGE = 'confirm-account';
export const FORGOT_PASS_PAGE = 'reset-password';
export const FORGOT_PASS_STEP2_PAGE = 'reset-password';
export const WATCH_ROUTE = 'stack/play';
export const EDIT_ROUTE = 'stack/edit';
export const EDIT_CLIP_ROUTE = 'stack/clip';
export const PROJECT_DETAIL_ROUTE = 'stack/discover/project';
export const COMMUNITY_ROUTE = 'stack/community';
export const HELP_ROUTE = 'help';

export const ROOT_EDIT_URL = `/${EDIT_ROUTE}`;

// export const SEARCH_PAGE = 'search-start';
// export const SEARCH_PAGE_ID = 'start';

/**
 * USAGE:
 * ActivatedRoute.snapshot.routeConfig.component.name
 */
// export const HOME_PAGE_COMPONENT_NAME = 'HomePage';

const routes: Routes = [
  /**
   * If we want to show the tabs on the home page - uncomment next line
   */
  // { path: 'home', redirectTo: '/stack/home', pathMatch: 'full' },

  // hijack home for landing
  // { path: 'home', redirectTo: '/landing', pathMatch: 'full' },
  // { path: 'stack/home', redirectTo: '/landing', pathMatch: 'full' },

  // note that we lose the queryparams in the redirectTo (why?) so we added the invite as route 
  // events/accept/projectId/inviteCode
  { 
    path: 'events/live/wedding-mba-2024', 
    // redirectTo: 'events/weddings/accept/wedding-mba_20241008', 
    redirectTo: 'events/weddings/accept/wedding-mba_20241008/vegas2024mba', 
    pathMatch: 'prefix'
  },

  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingPageModule),
  },

  { path: 'dashboard', redirectTo: '/stack/studio', pathMatch: 'full' },
  { path: 'studio', redirectTo: '/stack/studio', pathMatch: 'full' },
  { path: 'studio/edit', redirectTo: '/stack/studio/settings', pathMatch: 'full' },
  { path: 'studio/settings', redirectTo: '/stack/studio/settings', pathMatch: 'full' },
  { path: 'discover', redirectTo: '/stack/discover', pathMatch: 'full' },

  { path: 'projects', redirectTo: '/stack/discover/projects', pathMatch: 'full' },
  { path: 'project/:id', redirectTo: '/stack/discover/project/:id', pathMatch: 'full' },
  {
    path: 'project/:id/edit',
    loadChildren: () => import('./projects/project-admin/project-admin.module').then((m) => m.ProjectAdminModule),
    canActivate: [ProjectAdminComponentGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/auth/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'confirm-account',
    loadChildren: () =>
      import('./pages/auth/confirm-account/confirm-account.module').then((m) => m.ConfirmAccountPageModule),
  },
  { path: 'confirm', redirectTo: '/confirm-account', pathMatch: 'full' },
  
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password/:email',
    loadChildren: () => import('./pages/auth/reset-password/set-password/set-password.module').then(m => m.SetPasswordPageModule)
  },
  // handle auth redirects
  { path: 'forgot-password', redirectTo: '/reset-password', pathMatch: 'full' },
  { path: 'forgot/:email', redirectTo: '/reset-password/:email', pathMatch: 'full' },

  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpPageModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then((m) => m.PrivacyPageModule),
  },
  {
    path: 'community-rules',
    loadChildren: () =>
      import('./pages/community-rules/community-rules.module').then((m) => m.CommunityRulesPageModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then((m) => m.TermsPageModule),
  },

  { path: 'capture', redirectTo: '/stack/capture', pathMatch: 'full' },
  { path: 'clip', redirectTo: '/stack/clip', pathMatch: 'prefix' },

  // TODO: Stack/Theater forward to Stack Player

  {
    path: 'social',
    loadChildren: () => import('./pages/social/social.module').then((m) => m.SocialPageModule),
  },

  {
    path: 'tour',
    loadChildren: () => import('./pages/widget-splash/widget-splash.module').then((m) => m.WidgetSplashPageModule),
  },
  { path: 'widget-tour', redirectTo: '/tour', pathMatch: 'full' },
  { path: 'splash', redirectTo: '/tour', pathMatch: 'full' },
  
  /* TABS */
  {
    path: 'stack',
    loadChildren: () => import('./pages/tabs-main/tabs-main.module').then((m) => m.TabsMainPageModule),
  },

  {
    path: 'pro',
    loadChildren: () => import('./pro/pro.module').then((m) => m.ProModule),
  },
  {
    path: 'teams',
    redirectTo: 'events', pathMatch: 'prefix',
  },
  {
    path: 'wedding',
    redirectTo: 'events/weddings', pathMatch: 'prefix',
  },
  {
    path: 'weddings',
    redirectTo: 'events/weddings', pathMatch: 'prefix',
  },
  {
    path: 'events',
    loadChildren: () => import('./teams/teams.module').then((m) => m.TeamsModule),
  },

  {
    path: 'community',
    loadChildren: () => import('./pages/community/community.module').then((m) => m.CommunityPageModule),
  },

  // { // not routable directly, used in pro & teams
  //   path: 'onboarding',
  //   loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  // },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  // { path: '', redirectTo: '/landing', pathMatch: 'full' },

  // Fallback when no prior routes are matched
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // relativeLinkResolution: 'legacy',
      // enableTracing: true // debug
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
