/** @format */

import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

export class UsernameValidator {
  static minLength: number = 2;

  /**
   *  ^                 # the beginning of the string
        [a-zA-Z0-9_-]*  #  any character of: 'a' to 'z', 'A' to 'Z', 
                        #  '0' to '9', '_', '-' (0 or more times)
      $                 # before an optional \n, and the end of the string
   */
  static pattern: string = '^[a-zA-Z0-9_-]*$'; // only lowercase for Cognito, but allow uppercase and toLowerCase() from input
  /** @todo how to translate this ? */
  static patternDesc: string = 'Username can only contain letters, numbers, dashes(-) and underscores (_)';

  private static _didTranslate = false;
  static setTranslations(translate: TranslateService): void {
    if (!UsernameValidator._didTranslate) {
      UsernameValidator._didTranslate = true;
      translate
        .get(['ERRORS.FORM.USERNAME_PATTERN'])
        .pipe(take(1))
        .subscribe((res) => {
          UsernameValidator.patternDesc = res['ERRORS.FORM.USERNAME_PATTERN'];
        });
    }
  }

  static isValid(control: AbstractControl): { minlength?: boolean; invalidchars?: boolean } | null {
    const val = control.value;
    if (val && val.length > 0) {
      if (val.length < UsernameValidator.minLength) {
        return {
          minlength: true,
        };
      }
      const regex = RegExp(UsernameValidator.pattern);
      if (!regex.test(val)) {
        return {
          invalidchars: true,
        };
      }
    }

    return null;
  }

  /**
   * async check
   * @param control
   */
  static checkAvail(control: AbstractControl): Promise<{ taken: boolean } | null> {
    return new Promise((resolve) => {
      // syncronous
      if (control.value.toLowerCase() === 'jd') {
        resolve({
          taken: true,
        });
      } else {
        resolve(null);
      }

      //async
      //Fake a slow response from server
      // setTimeout(() => {
      //   if(control.value.toLowerCase() === "jd2") {

      //     resolve({
      //       "taken": true
      //     });

      //   } else {
      //     resolve(null);
      //   }
      //   }, 2000);
    });
  }
}
