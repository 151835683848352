/**
 * The customers will be redirected to this URL upon successful checkout.
 * The hosted page id and state will be passed as parameters to this URL.
 *
 * @note
 * Although the customer will be redirected to the redirect_url after successful checkout,
 * we do not recommend relying on it for completing critical post-checkout actions.
 * This is because redirection may not happen due to unforeseen reasons such as user closing the tab,
 * or exiting the browser, and so on. If there is any synchronization that you are doing after the redirection,
 * you will have to have a backup. Chargebee recommends listening to appropriate webhooks such as
 * subscription_created or invoice_generated to verify a successful checkout.
 * Redirect URL configured in Settings > Hosted Pages Settings would be overriden by this redirect URL.
 *
 * http://yoursite.com?id=<hosted_page_id>&state=succeeded
 *
 * @format
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';
import {
  DEFAULT_BACKGROUND_POSITION,
  DEFAULT_BACKGROUND_URL,
  TRANSLATE_ROOT,
} from '@onboarding/shared/onboarding.model';
import { OnboardingService } from '@onboarding/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-onboarding-success',
  templateUrl: './onboarding-success.component.html',
  styleUrls: ['./onboarding-success.component.scss'],
})
export class OnboardingSuccessComponent implements OnInit {
  translatePath = TRANSLATE_ROOT;
  actionUrl = '/studio';
  routeId: string;
  backgroundUrl = DEFAULT_BACKGROUND_URL;
  backgroundPosition = DEFAULT_BACKGROUND_POSITION;
  params = '';
  isSuccess = false;

  private onDestroy$ = new Subject<void>();

  constructor(private onboardingService: OnboardingService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.onboardingService.routeOnboardingMeta$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ routeId, translatePath = '', actionUrl = '', backgroundUrl = '', backgroundPosition = '' }) => {
        this.routeId = routeId;
        this.translatePath = translatePath;
        this.actionUrl = `/${PROJECT_DETAIL_ROUTE}/`; // todo: have the projectId
        this.backgroundUrl = backgroundUrl;
        this.backgroundPosition = backgroundPosition;
        console.log('OnboardingSuccess', { routeId, actionUrl });
      });

    // queryparams
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      console.log('OnboardingSuccess queryparams:', params);
      try {
        // id=<hosted_page_id>&state=succeeded
        if (params?.id && params?.state) {
          this.isSuccess = true;
          this.params = JSON.stringify({ id: params.id, state: params.state });
          console.log(`OnboardingSuccess queryParams found:`, { id: params.id, state: params.state });
        } else {
          this.isSuccess = false;
          this.params = JSON.stringify({ status: 'not found', params });
        }
      } catch (error) {
        console.warn(error);
      }
    });
  }
}
