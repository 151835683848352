/** @format */
import * as Reset from '../actions/reset.actions';
import * as WatchLater from '../actions/watch-later.actions';

const PAGE = '[WatchLaterStore]';

export const featureKey = 'watchLater';

export interface State {
  loaded: boolean;
  loading: boolean;
  ids: string[];
  // entities: { [id: string]: Clip }; // use the Clips store for all entities, ref'd by the id[] here
}

export const initialState: State = {
  loaded: false,
  loading: false,
  ids: [],
};

export function reducer(state = initialState, action: Reset.ActionsUnion | WatchLater.ActionsUnion): State {
  switch (action.type) {
    case Reset.resetStore.type:
    case Reset.resetStoreThenReboot.type:
    case Reset.resetStoreOnLogout.type:
    case WatchLater.reset.type: {
      return initialState;
    }

    case WatchLater.load.type: {
      if (state.loaded) {
        console.log(`${PAGE} already loaded - no change`);
        return state;
      }
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case WatchLater.loadSuccess.type: {
      const { clips } = action;

      const newClipIds = clips.map((clip) => clip.id);

      return {
        loaded: true,
        loading: false,
        ids: [...state.ids, ...newClipIds],
      };
    }

    case WatchLater.loadFail.type: {
      const { error } = action;
      console.warn(`${PAGE} load fail:`, error);
      return initialState;
    }

    case WatchLater.addClip.type: {
      const { clip } = action;

      return Object.assign({}, state, {
        ids: [...state.ids, clip.id],
      });
    }

    case WatchLater.removeClip.type: {
      const { clip } = action;

      return Object.assign({}, state, {
        ids: state.ids.filter((clipId) => clipId !== clip.id),
      });
    }

    case WatchLater.clearAllClips.type: {
      console.log('Clearing all watchLater - assuming you have confirmed this action...');
      return initialState;
    }

    // TODO: instead use Effect to => LOAD_SUCCESS
    case WatchLater.hydrated.type: {
      console.log(`${action.type} TODO: Effect verifying enitities exist in clips store`);
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
      });
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;

export const getIds = (state: State) => state.ids;
