/**
 * Error in PROD "fatal media error encountered, cannot recover -> destroy"
 * Works in DEV?
 * Following PR: https://bitbucket.org/filmstacker/filmstacker-app-ionic4/pull-requests/180
 * 2022-10-24 changing back to 'hls.js' from 'hls.js/dist/hls.min.js'
 *
 * Sentry-12M, 14C
 * [HlsDriver] internalException (otherError): internalException
 * Uncaught ReferenceError: [si, he] is not defined
 *
 * @format
 */

import Hls from 'hls.js/dist/hls.min.js';
// import Hls from 'hls.js';
import { PlyrDriver, PlyrDriverCreateParams, PlyrDriverDestroyParams, PlyrDriverUpdateSourceParams } from '@app-plyr';
import * as Plyr from 'plyr';

const DEBUG_LOGS = false;
/** MVP-1288 Android HLS Video stopping - try using hls.js in all cases to see if better */
const USE_NATIVE_HLS_IF_AVAILABLE = false;

/**
 * When re-creating the HLS instance, we can take the current bandwidth
 * and default the quality level to this bandwidth...
 * but, the negative is that it will cause a longer black screen while buffering
 *
 * We are deciding _not_ to carry the bandwidth forward for the initial testing,
 * but this could be revisited later
 * @see docs/VIDEO_PLAYER.md
 */
const CARRY_BANDWIDTH_FORWARD = false;
/**
 * Deciding which is better, toggle for newSource method
 * Per threads in github and stackoverflow, it appears that
 * detach/reattach media works for mid-rolls, but typically
 * the authors recommend destroy/create
 * @see docs/VIDEO_PLAYER.md
 */
const DESTROY_HLS_ON_NEW_SOURCE = true;

/**
 * HLS Player Driver with Default Driver fallbacks
 * https://github.com/smnbbrv/ngx-plyr#custom-plyr-driver
 * https://github.com/smnbbrv/ngx-plyr/blob/master/src/app/hlsjs-plyr-driver/hlsjs-plyr-driver.ts
 * https://github.com/video-dev/hls.js
 * hls.js is not supported on platforms that do not have Media Source
 * Extensions (MSE) enabled.
 *
 * When the browser has built-in HLS support (check using `canPlayType`),
 * we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
 * element through the `src` property. This is using the built-in support
 * of the plain video element, without using hls.js.
 *
 * Note: it would be more normal to wait on the 'canplay' event below however
 * on Safari (where you are most likely to find built-in HLS support) the
 * video.src URL must be on the user-driven white-list before a 'canplay'
 * event will be emitted; the last video event that can be reliably
 * listened-for when the URL is not on the white-list is 'loadedmetadata'.
 *
 * To check for native browser support first and then fallback to Hls.js, swap these conditionals, see tradeoffs:
 * then, updated with additional logic for browser support, based on
 * https://github.com/video-dev/hls.js/pull/2954#issuecomment-670021358
 *
 */
export class PlyrDriverHlsjs implements PlyrDriver {
  public hlsSupported = false;

  hls; // will be new Hls() if needed

  private usingHls = false;
  private loaded = false;

  /**
   * get the current bandwidth estimate
   * abrEwmaDefaultEstimate: 500000
   * https://github.com/video-dev/hls.js/blob/master/docs/API.md#hlsbandwidthestimate
   */
  private get bandwidthEstimate(): number {
    return this.hls && !isNaN(this.hls.bandwidthEstimate) ? this.hls.bandwidthEstimate : 500000;
  }

  constructor(private autoload: boolean) {}

  create(params: PlyrDriverCreateParams) {
    if (DEBUG_LOGS) {
      params.options.debug = true; // add plyr debug messages
    }

    const videoEl = params.videoElement;
    try {
      /**
       * First check for native browser HLS support
       * (https://github.com/video-dev/hls.js/pull/2954#issuecomment-670021358)
       */
      if (
        USE_NATIVE_HLS_IF_AVAILABLE &&
        videoEl &&
        typeof videoEl.canPlayType === 'function' &&
        videoEl.canPlayType('application/vnd.apple.mpegurl')
      ) {
        this.hlsSupported = true;
        this.usingHls = false;
        // console.log(`videoEl.canPlayHls debug:`, { videoEl, params })
        console.log(`[HlsDriver] canPlayType HLS natively, no need for Hls.js.`);
        return new Plyr(videoEl, params.options);
        // videoEl.src = params.source;
        /**
         * If no native HLS support, check if hls.js is supported
         */
      } else if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.attachMedia(videoEl);
        this.hlsSupported = true;
        this.usingHls = true;
        // https://github.com/dailymotion/hls.js/blob/master/docs/API.md
        this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          DEBUG_LOGS && console.log('[HlsDriver] video and hls.js are now bound together...');
        });
        this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          DEBUG_LOGS &&
            console.log('[HlsDriver] manifest loaded, found ' + data.levels.length + ' quality levels', data);
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.hls.on(Hls.Events.DESTROYING, (event, data) => {
          DEBUG_LOGS && console.log('[HlsDriver] Destroying...');
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.hls.on(Hls.Events.MEDIA_DETACHED, (event, data) => {
          DEBUG_LOGS && console.log('[HlsDriver] MediaSource detached from media element...');
          // causes Error: Uncaught TypeError: Cannot read properties of undefined (reading 'call')
          // https://sentry.io/organizations/filmstacker/issues/3658680968
        });

        let retries = 5;

        this.hls.on(Hls.Events.ERROR, (event, data) => {
          try {
            const { type: errorType, details: errorDetails, fatal: errorFatal } = data;

            if (errorFatal) {
              switch (errorType) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // try to recover network error
                  if (retries > 0) {
                    retries -= 1;
                    setTimeout(() => {
                      console.log('fatal network error encountered, try to recover');
                      // https://github.com/video-dev/hls.js/issues/1838#issuecomment-406426627
                      this.hls.startLoad();
                    }, 100);
                  } else {
                    console.warn('fatal network error encountered, UNMOUNTED');
                  }
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  if (retries > 0) {
                    retries -= 1;
                    setTimeout(() => {
                      // https://github.com/video-dev/hls.js/issues/1838#issuecomment-406426627
                      console.log('fatal media error encountered, try to recover');
                      this.hls.recoverMediaError();
                    }, 100);
                  } else {
                    console.warn('fatal media error encountered, UNMOUNTED');
                  }

                  break;
                default:
                  // cannot recover
                  console.warn('fatal media error encountered, cannot recover -> destroy');
                  console.log({ event, data });
                  this.hls.destroy();
                  break;
              }
            } else {
              switch (errorDetails) {
                case 'internalException':
                  // Sentry FILMSTACKERV4WEBAPP-12M debug otherError: internalException
                  // Error: Uncaught ReferenceError: si is not defined
                  console.warn(`[HlsDriver] internalException (${errorType}): ${errorDetails}`);
                  break;
                case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                default:
                  console.warn(`[HlsDriver] ${errorType}: ${errorDetails}`);
                  break;
              }
            }
          } catch (error) {
            console.error(error);
          }
        });
        DEBUG_LOGS && console.log(`[HlsDriver] no native HLS support, using hls.js.`, params);
      } else {
        this.hlsSupported = false;
        this.usingHls = false;
        console.log(`[HlsDriver] HLS *NOT* supported.`);
      }
      // always return this
      return new Plyr(videoEl, params.options);
    } catch (error) {
      console.error(`[HlsDriver] Caught`, error);
      // still return here?
      return new Plyr(videoEl, params.options);
    }
  }

  updateSource(params: PlyrDriverUpdateSourceParams) {
    DEBUG_LOGS &&
      console.log(`[HlsPlyrDriver] updateSource`, { usingHls: this.usingHls, autoload: this.autoload, params });
    if (!this.usingHls) {
      params.plyr.source = params.source;
      // //// commenting out - this is needed if the video source type is 'video' and not 'application/vnd.apple.mpegurl'
      // if (params.source.sources && Array.isArray(params.source.sources) && params.source.sources.length > 0 && params.source.sources[0].src) {
      //   params.videoElement.src = params.source.sources[0].src;
      // } else {
      //   console.warn(`[HlsPlyrDriver] unexpected case - no source to update?`)
      //   params.plyr.source = params.source;
      // }
      // params.videoElement.poster = params.source.poster;
    } else if (this.autoload && Array.isArray(params.source.sources) && params.source.sources.length > 0) {
      // needed to validate that this new clip has sources
      this.load(params.source.sources[0].src);
    } else {
      // poster does not work with autoload
      params.videoElement.poster = params.source.poster;
    }
  }

  destroy(params: PlyrDriverDestroyParams) {
    params.plyr.destroy();
    if (this.usingHls && typeof this.hls.destroy === 'function') {
      this.hls.detachMedia();
      this.hls.destroy();
    }
  }

  load(src: string) {
    DEBUG_LOGS && console.log(`[HlsPlyrDriver] load src...`, { src, loaded: this.loaded, usingHls: this.usingHls });
    if (!this.loaded) {
      this.loaded = true;
      if (this.usingHls && typeof this.hls.loadSource === 'function') {
        DEBUG_LOGS && console.log(`[HlsPlyrDriver] loadSource...`);
        this.hls.loadSource(src);
      }
    }
  }

  newSource(params: PlyrDriverUpdateSourceParams, doDestroy = DESTROY_HLS_ON_NEW_SOURCE) {
    try {
      if (!this.usingHls) {
        params.plyr.source = params.source;
        // // commenting out - this is needed if the video source type is 'video' and not 'application/vnd.apple.mpegurl'
        // if (params.source.sources && Array.isArray(params.source.sources) && params.source.sources.length > 0 && params.source.sources[0].src) {
        //   params.videoElement.src = params.source.sources[0].src;
        // } else {
        //   console.warn(`[HlsPlyrDriver] unexpected case - no source to update?`)
        //   params.plyr.source = params.source;
        // }
      } else if (typeof this.hls.detachMedia === 'function') {
        const src = params.source.sources[0].src;

        if (doDestroy) {
          /**
           * Configuration parameters provided to hls.js upon instantiation of Hls object.
           * https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning
           */
          const hlsCreateParams: {
            startPosition: number;
            debug: boolean;
            abrEwmaDefaultEstimate?: number;
            testBandwidth?: boolean;
          } = {
            // ref for future research..
            startPosition: -1,
            debug: false,
          };

          if (CARRY_BANDWIDTH_FORWARD) {
            const bandwidthEstimate = this.bandwidthEstimate;
            DEBUG_LOGS && console.log(`[HlsDriver] bandwidthEstimate`, bandwidthEstimate);
            hlsCreateParams.abrEwmaDefaultEstimate = bandwidthEstimate; // update this from current instance
            hlsCreateParams.testBandwidth = false; // per docs, disable to use our estimate
          }

          this.hls.destroy();
          this.hls = null;
          this.hls = new Hls(hlsCreateParams);
          this.hls.loadSource(src);
          this.hls.attachMedia(params.videoElement);
        } else {
          // is this more efficient?
          this.hls.detachMedia();
          this.hls.loadSource(src);
          this.hls.attachMedia(params.videoElement);
        }
      } else {
        console.warn(`[HlsDriver] ERROR: newSource not able to hls.detachMedia`);
      }
    } catch (error) {
      console.warn(`[HlsDriver] newSource Caught:`, error);
    }
  }
}
/*
  @see docs/VIDEO_PLAYER.md

  // Destroy the player instance and insatiate a new player for each item you would like to play. Here's an example from our demo page 
  // https://github.com/video-dev/hls.js/issues/2639#issuecomment-613713094
  // https://github.com/video-dev/hls.js/blob/feature/v1.0.0/demo-timeline/src/player.ts

  if (!Hls.isSupported()) {
    return;
  }

  if (this.hls) {
    this.hls.destroy();
    this.hls = null;
  }

  this.hls = new Hls({});
  this.hls.loadSource(this.url);
  this.hls.attachMedia(this.video);

  
  // You can call play() here,
  // and should if this code is in response to a click,
  // mouse or touch event.
*/
