/** @format */

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { IonItemSliding, ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { EventsService } from '@app/core/services/events.service';
import { ClipsCoreService } from '@app/core/services/clips.service';
import { ToasterService } from '@app/core/services/toaster.service';
import { Clip, getFilmingDate, DEFAULT_POSTER } from '@app/shared/models/clip.model';
import { ClipperComponent, ClipperSegments } from '@app/modules/clipper/clipper.component';
import { Utils } from '@app/shared/utils';
import { MAX_VIDEO_CAPTURE_LENGTH_SECONDS } from '@app/app.config';
import { DEFAULT_USER_AVATAR } from '@shared/models/user.model';
import { ProjectMemberService } from '@members/shared/services';

const DEBUG_LOGS = false;

const PAGE = '[MyStackItem]';

@Component({
  selector: 'app-my-stack-item',
  templateUrl: './my-stack-item.component.html',
  styleUrls: ['./my-stack-item.component.scss'],
})
export class MyStackItemComponent implements OnInit, OnDestroy {
  @Input() displayIndex: boolean = true; // for dev: show this for debugging
  @Input() isPreviewOpen: boolean = false;
  @Input() isVideoPlaying: boolean = false;
  @Input() slidingItem: IonItemSliding;

  @Input() clip: Clip;
  @Input() playlistLength: number = 0; // to know when we're last
  @Input() index: number;
  // set index(val:number) {
  //   // console.log(`${PAGE} set index: ${val}`);
  //   this.itemIndex = val + 1;
  // }
  // get index() { return this.itemIndex - 1; }
  @Input() currentIndex: number;
  /** user is GLOBAL ADMIN */
  @Input() isAdmin = false;
  @Input() isMyStack = false;
  @Input() isMyClip = false;

  @Output() playClipIndex = new EventEmitter<number>();
  @Output() pauseClipIndex = new EventEmitter<number>();
  @Output() deleteClipIndex = new EventEmitter<number>();
  @Output() moveTop = new EventEmitter<number>();
  @Output() moveBottom = new EventEmitter<number>();

  // public itemIndex: number = 0;
  public poster: string = DEFAULT_POSTER;
  public dateFilmed: string = '';
  public tooLong = false;

  public isCollapsedDetails: boolean = true;
  public bDisplayIndex = false;
  avatarFallback = DEFAULT_USER_AVATAR;
  userAvatarUrl: string;
  clipOwner$: Observable<string>;

  private subManager = new Subscription();

  constructor(
    private toaster: ToasterService,
    private events: EventsService,
    private clipService: ClipsCoreService,
    private memberService: ProjectMemberService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.poster = this.getPoster();

    if (this.clip) {
      this.dateFilmed = this.clip && (this.clip.filmingDate || this.clip.created) ? getFilmingDate(this.clip) : '';

      this.tooLong = Utils.convertDurationToSeconds(this.clip.duration) > MAX_VIDEO_CAPTURE_LENGTH_SECONDS; // more than 3 minutes?

      this.clipOwner$ = this.memberService.getPublicUsername(this.clip.userId);
      this.userAvatarUrl = this.memberService.getUserAvatar(this.clip.userId);
    }

    const subCloseDeets = this.events.subscribe('mystack:item:details:close', this.onCloseCollapse);
    this.subManager.add(subCloseDeets);
  }

  ngOnDestroy() {
    this.subManager.unsubscribe();
  }

  getPoster() {
    return this.clipService.getPoster(this.clip);
  }

  onClickItem(event: MouseEvent) {
    DEBUG_LOGS && console.log(`${PAGE} onClickItem`, event);
    this.toggleCollapse();
  }

  toggleDetails(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    // this.toaster.present(`Info: ${item.title}`);
    this.toggleCollapse();
  }

  toggleCollapse() {
    this.isCollapsedDetails = !this.isCollapsedDetails;
    if (!this.isCollapsedDetails) {
      this.events.publish('mystack:item:details:close', this.index);
    }
  }
  onCloseCollapse = (index) => {
    if (this.index !== index) {
      // DEBUG_LOGS && console.log(`close all other item deets (${this.index}) payload index:`, index);
      this.isCollapsedDetails = true;
    }
  };

  closeSlider() {
    if (this.slidingItem && typeof this.slidingItem.close === 'function') {
      this.slidingItem.close();
    }
  }

  onClickPlay(event) {
    Utils.tryStopPropagation(event);
    console.log(`${PAGE} onClickPlay`, {
      index: this.index,
      currentIndex: this.currentIndex,
      isPreviewOpen: this.isPreviewOpen,
    });
    this.playClipIndex.emit(this.index);
  }

  onClickPause(event) {
    Utils.tryStopPropagation(event);
    console.log(`${PAGE} onClickPause`, {
      index: this.index,
      currentIndex: this.currentIndex,
      isPreviewOpen: this.isPreviewOpen,
    });
    this.pauseClipIndex.emit(this.index);
  }

  onSwipeDelete(event: CustomEvent) {
    DEBUG_LOGS && console.log(`${PAGE} onSwipeDelete [${this.index}]`, { event, slidingItem: this.slidingItem });
    if (this.isMyClip || this.isMyStack) {
      this.delete();
      if (typeof this.slidingItem.close === 'function') {
        this.slidingItem.close();
      }
      if (this.clip && this.clip.title) {
        this.toaster.present(`Removed '${this.clip.title}'`);
      }
    }
    // this.hasSurpassedSlidingThreshold().then((pastThreshold: boolean) => {
    //   if (pastThreshold) {
    //     // was here
    //   }
    // })
  }

  deleteClip() {
    console.log(`${PAGE} deleteClip [${this.index}]`, { slidingItem: this.slidingItem });
    this.delete();
    if (typeof this.slidingItem.close === 'function') {
      this.slidingItem.close();
    }
    if (this.clip && this.clip.title) {
      this.toaster.present(`Removed '${this.clip.title}'`);
    }
  }

  onDeleteClip(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.delete();
  }
  onTrimClip(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.trimClip();
  }
  onEditClip(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.editClip();
  }
  onMoveTop(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.moveTop.emit(this.index);
  }
  onMoveBottom(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.moveBottom.emit(this.index);
  }

  delete() {
    // console.log(`${PAGE} delete`, index);
    this.deleteClipIndex.emit(this.index);
  }

  async trimClip() {
    // this.toaster.present("Trim Clip Feature Coming Soon...");
    const modal = await this.modalCtrl.create({
      component: ClipperComponent,
      componentProps: {
        selectedSegment: ClipperSegments.Trim,
        clip: this.clip,
        isModal: true,
      },
      cssClass: 'fullheight-modal',
    });
    return await modal.present();
  }

  async editClip() {
    // this.toaster.present("Trim Clip Feature Coming Soon...");
    const modal = await this.modalCtrl.create({
      component: ClipperComponent,
      componentProps: {
        selectedSegment: ClipperSegments.Settings,
        clip: this.clip,
        isModal: true,
      },
      cssClass: 'fullheight-modal',
    });
    return await modal.present();
  }

  /**
   * ion-sliding https://ionicframework.com/docs/api/item-sliding#methods
   * @returns boolean if has surpassed threshold
   * not working... caches the first value and never updates? giving up for now..
   */
  private async hasSurpassedSlidingThreshold(): Promise<boolean> {
    if (this.slidingItem && typeof this.slidingItem.getOpenAmount === 'function') {
      // getOpenAmount
      const openAmount = await this.slidingItem.getOpenAmount();
      const slidingRation = await this.slidingItem.getSlidingRatio();
      console.log(`${PAGE} checkSlidingThreshold:`, { openAmount, slidingRation });
      return false;
    }
    return false;
  }
}
