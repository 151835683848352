/** @format */

import { createAction, props, union } from '@ngrx/store';
import { Clip } from '@shared/models/clip.model';
import { FilterEntity } from '@store/reducers/viewstate.reducers';
import { Stack } from '@shared/models/stack.model';

const SIZE_FETCH_ITEMS = 10;

export const subUpdate = createAction('[Clips] Subscription Update', props<{ clip: Clip; userId?: string }>());
export const setPoster = createAction(
  '[Clips] Set Poster Image',
  props<{ projectId: string; id: string; poster: string }>()
);

export const reset = createAction('[Clips] Reset');
export const search = createAction('[Clips] Search', props<{ search: string }>());
export const searchComplete = createAction('[Clips] Search Complete', props<{ clips: Clip[] }>());
// export const select = createAction('[Clips] Select', props<{ projectId: string; id: string }>());
export const addClips = createAction('[Clips] Add Clips', props<{ clips: Clip[] }>());
export const addClip = createAction('[Clips] Add Clip', props<{ clip: Clip }>());
export const updateClip = createAction('[Clips] Update Clip', props<{ clip: Clip }>());
export const updateClipTranscoding = createAction(
  '[Clips] Update Clip Transcoding',
  props<{ projectId: string; id: string; updates: Partial<Clip> }>()
);
export const deleteClip = createAction('[Clips] Delete Clip', props<{ clip: Clip }>());
export const load = createAction('[Clips] Load Clip', props<{ projectId: string; id: string }>());

export const loadFail = createAction('[Clips] Load Clip Fail', props<{ id: string; error: string | Error }>());
export const loadFailBatch = createAction('[Clips] Load Clip Fail Batch', props<{ ids: string[]; error: string | Error }>());
export const loadingClip = createAction('[Clips] Loading Clip', props<{ projectId: string; id: string }>());
// export const incrementNumStacks = createAction(
//   '[Clips] Increment NumStacks',
//   props<{ projectId: string; id: string }>()
// );
export const hydrated = createAction('[Clips] Hydrated');
export const play = createAction('[Clips] Play', props<{ clip: Clip }>());

export const setApproved = createAction(
  '[Clips] Set isApproved',
  props<{ projectId: string; id: string; userId: string; isApproved: boolean }>()
);

export const loadFilteredClips = createAction(
  '[Clips] Load Filtered Clips',
  (payload: { listId: string; filters: FilterEntity; limit?: number }) => ({
    limit: SIZE_FETCH_ITEMS,
    apiLimit: SIZE_FETCH_ITEMS,
    nextToken: '',
    ...payload,
  })
);
export const loadMoreFilteredClips = createAction(
  '[Clips] Load More Filtered Clips',
  (payload: {
    listId: string;
    filters: FilterEntity;
    nextToken: string;
    limit?: number; // the requested limit from UI
    apiLimit?: number; // keep track of our current limit sent to api, separate from the requested limit from UI
  }) => ({
    limit: SIZE_FETCH_ITEMS,
    apiLimit: SIZE_FETCH_ITEMS,
    ...payload,
  })
);

export const noMoreToLoad = createAction('[Clips] No More To Load', props<{ listId: string }>());

export const loadSuccess = createAction(
  '[Clips] Load Success',
  props<{
    clips: Clip[];
    filters?: FilterEntity;
    listId?: string; // string to allow for projectId nextTokens
    nextToken?: string;
    isLoadMore?: boolean;
  }>()
);

export const loadBatchIds = createAction(
  '[Clips] Load Clips by IDs Batch',
  props<{
    ids: { projectId: string; id: string }[];
    stack?: Stack; // included for Missing Clip Notifications
  }>()
);

/**
 * @deprecated in favor of loadFilteredClips
 */
export const loadClipsByProjectId = createAction(
  '[Clips] Load Clips By Project ID',
  (payload: { projectId: string; listId: string; filters?: FilterEntity; limit?: number }) => ({
    limit: SIZE_FETCH_ITEMS,
    nextToken: '',
    ...payload,
  })
);
/**
 * @deprecated in favor of loadMoreFilteredClips
 */
export const loadMoreClipsByProjectId = createAction(
  '[Clips] Load Clips By Project ID',
  (payload: { projectId: string; listId: string; nextToken: string; filters?: FilterEntity; limit?: number }) => ({
    limit: SIZE_FETCH_ITEMS,
    ...payload,
  })
);

// do not export return value
const actions = union({
  addClip,
  addClips,
  deleteClip,
  hydrated,
  load,
  loadBatchIds,
  loadClipsByProjectId,
  loadFail,
  loadFailBatch,
  loadFilteredClips,
  loadingClip,
  loadMoreClipsByProjectId,
  loadMoreFilteredClips,
  loadSuccess,
  noMoreToLoad,
  play,
  reset,
  search,
  searchComplete,
  setApproved,
  setPoster,
  subUpdate,
  updateClip,
  updateClipTranscoding,
});

// export only type
export type ActionsUnion = typeof actions;

/** Actions to ignore during local-storage-sync, for efficiency */
export const ignoreStorageSync = [
  addClip.type,
  addClips.type,
  loadSuccess.type,
  loadFilteredClips.type,
  loadBatchIds.type,
  play.type,
  subUpdate.type,
];
