/**
 * Filmstacker local implementation of https://github.com/natural-apptitude/ngrx-store-ionic-storage
 *
 * HISTORY
 * 2020-11-11 jd - Unable to build with Angular 9+, so imported directly into app, copied from https://github.com/natural-apptitude/ngrx-store-ionic-storage
 * 2022-02-09 jd - heavily modified to get hydration working again, and updated to ionic/storage 3.0.6 (MVP-1128)
 *
 * @format
 */
/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { ActionReducer } from '@ngrx/store';
import { defer, from, Observable, of, Subject } from 'rxjs';
import { catchError, last, map, mergeMap } from 'rxjs/operators';
import { Storage } from './ionic-storage';

const DEBUG_LOGS = false;
const STORAGE_KEY = 'FSR_APP_STATE';

// platformId is set to PLATFORM_BROWSER_ID value from https://github.com/angular/angular/blob/master/packages/common/src/platform_id.ts
// https://github.com/natural-apptitude/ngrx-store-ionic-storage/pull/36/commits/2a7558c8f7cc92668467a2d80a66aa0faa608f7b
// we assume we're always in a browser context (not server)
// const storage = new Storage({}, 'browser');

export const StorageSyncActions = {
  HYDRATED: 'FSR_APP_HYDRATED',
};

@Injectable()
export class StorageSyncEffects {
  static storage: Storage | null = null;
  static created$ = new Subject<void>();

  hydrate$: Observable<any> = createEffect(() =>
    defer(() =>
      from(fetchState()).pipe(
        map((state) => ({
          type: StorageSyncActions.HYDRATED,
          payload: state,
        })),
        catchError((e) => {
          console.warn(`error fetching data from store for hydration: ${e}`);

          return of({
            type: StorageSyncActions.HYDRATED,
            payload: {},
          });
        })
      )
    )
  );

  constructor(_storage: Storage) {
    // https://github.com/ionic-team/ionic-storage/blob/main/README.md#with-angular
    // If using a custom driver: await this.storage.defineDriver(/*...*/);
    _storage
      .create()
      .then((created) => {
        StorageSyncEffects.storage = created;
        StorageSyncEffects.created$.next();
        StorageSyncEffects.created$.complete();
        DEBUG_LOGS && console.log('[Storage] created.', created);
      })
      .catch((err) => {
        console.warn(err);
      });
  }
}

export interface StorageSyncOptions {
  keys?: string[];
  ignoreActions?: string[];
  hydratedStateKey?: string;
  onSyncError?: (err: any) => void;
}

const defaultOptions: StorageSyncOptions = {
  keys: [],
  ignoreActions: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onSyncError: (err) => {},
};

export function storageSync(options?: StorageSyncOptions) {
  const { keys, ignoreActions, hydratedStateKey, onSyncError } = Object.assign({}, defaultOptions, options || {});

  ignoreActions.push(StorageSyncActions.HYDRATED);
  ignoreActions.push('@ngrx/store/init');
  ignoreActions.push('@ngrx/effects/init');
  ignoreActions.push('@ngrx/store/update-reducers');
  ignoreActions.push('@ngrx/store-devtools/recompute');

  const hydratedState: any = {};

  return function storageSyncReducer(reducer: ActionReducer<any>) {
    return (state: any, action: any) => {
      const { type, payload } = action;
      // need to wait for this 2022-02-09
      const isHydrated = !!(hydratedStateKey && hydratedState[hydratedStateKey]);

      if (type === StorageSyncActions.HYDRATED) {
        state = Object.assign({}, state, payload);
        DEBUG_LOGS &&
          console.log('[Storage] HYDRATING:', { isHydrated, type, payload, state, hydratedStateKey, hydratedState });
        if (hydratedStateKey) {
          hydratedState[hydratedStateKey] = true;
        }
      }

      const nextState = Object.assign({}, reducer(state, action), hydratedState);

      if (isHydrated && ignoreActions.indexOf(type) === -1) {
        DEBUG_LOGS && console.log('[Storage] storageSyncReducer SAVE:', { type, payload, nextState });
        saveState(nextState, keys).catch((err) => onSyncError(err));
      }

      return nextState;
    };
  };
}

// get/setNested inspired by
// https://github.com/mickhansen/dottie.js
function getNested(obj: any, path: string): any {
  if (obj !== null && path) {
    // Recurse into the object.
    const parts = path.split('.').reverse();
    while (obj != null && parts.length) {
      obj = obj[parts.pop()];
    }
  }
  return obj;
}

function setNested(obj: any, path: string, value: any): any {
  if (obj != null && path) {
    const pieces = path.split('.'),
      length = pieces.length;
    let current = obj,
      piece,
      i;

    for (i = 0; i < length; i++) {
      piece = pieces[i];
      if (i === length - 1) {
        current[piece] = value;
      } else if (!current[piece]) {
        current[piece] = {};
      }
      current = current[piece];
    }
  }

  return obj;
}

function fetchState(): Observable<any> {
  return StorageSyncEffects.created$.pipe(
    last(),
    mergeMap(() =>
      from(
        StorageSyncEffects.storage.get(STORAGE_KEY).then((s) => {
          DEBUG_LOGS && console.log('[Storage] fetchState: ', s);
          return s || {};
        })
        // .catch((err) => {}) // allow hydrate$ to catchError
      )
    )
  );
}

function saveState(state: any, keys: string[]): Promise<void> {
  if (!StorageSyncEffects.storage) {
    return Promise.resolve();
  }
  // Pull out the portion of the state to save.
  if (keys) {
    state = keys.reduce((acc, k) => {
      const val = getNested(state, k);
      if (val) {
        setNested(acc, k, val);
      }
      return acc;
    }, {});
  }
  DEBUG_LOGS && console.log('[Storage] set: ', state);
  return StorageSyncEffects.storage.set(STORAGE_KEY, state);
}
