/** @format */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ENABLE_TRENDING_STACKS, ENABLE_BILLING } from '@app/app.config';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Project, PROJECT_PRIVACY, ProjectEventType } from '@projects/shared/project.model';
import { ProjectService } from '@projects/shared/services';
import { Subject } from 'rxjs';

const DEV_BYPASS_API = false;

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit, OnDestroy {
  @Input() userId = '';
  @Input() eventType = ProjectEventType.Projects;

  public enableShowTrending = ENABLE_TRENDING_STACKS;
  public enableBilling = ENABLE_BILLING;

  public projectForm = this.formBuilder.group({
    title: ['', [Validators.required]],
    mission: ['', Validators.required],
    description: [''], // !Validators.required
    owner: ['', Validators.required],
    privacy: [PROJECT_PRIVACY.PUBLIC],
    isModerated: [false],
    config: this.formBuilder.group({
      showRecentStacks: [false],
      showTrendingStacks: [{ value: false, disabled: !ENABLE_TRENDING_STACKS }],
    }),
    eventType: [this.eventType],
  });
  public isLoading = false;
  public error: string;
  public ProjectPrivacy = PROJECT_PRIVACY;
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loadingCtrl: LoadingController,
    private projectService: ProjectService,
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.projectForm.patchValue({
      owner: this.userId,
      ...(this.eventType !== ProjectEventType.Projects
        ? {
            eventType: this.eventType,
          }
        : {}),
    });
  }

  /**
   * @note issue with submitting the form from button outside the form el..
   * resolved with #createProjectForm="ngForm"
   * https://forum.ionicframework.com/t/form-submit-in-footer/71732/10
   * https://github.com/ionic-team/ionic-framework/issues/21194
   */
  async save() {
    this.isLoading = true;
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('COMMON.PLEASE_WAIT'),
    });
    try {
      await loader.present();
      const project: Project = new Project(this.projectForm.value);
      this.error = null;
      if (DEV_BYPASS_API) {
        project.id = 'dev';
      } else {
        await this.projectService.createProject(project);
      }
      loader.dismiss();
      const modal = await this.modalCtrl.getTop();
      modal.dismiss({
        project,
      });
    } catch (error) {
      console.warn(error);
      loader.dismiss();
      const errorMsg = await this.loadingCtrl.create({
        spinner: null, //'hide',
        message: this.translate.instant('ERRORS.GENERIC_OOPS'),
        duration: 5000,
        backdropDismiss: true,
      });
      await errorMsg.present();
    } finally {
      this.isLoading = false;
    }
  }

  get configGroup() {
    return this.projectForm.get('config') as FormGroup;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
