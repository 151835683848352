/** @format */

import { Observable as ZenObservable } from './../../../../node_modules/zen-observable-ts';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Observable } from 'rxjs';

/**
 * Convert API.graphql(graphqlOperation -> Rx Observable
 * 2023-06-27 RxJs upgrade mixed with Amplify@5 caused issue with API.graphql typing
 * https://stackoverflow.com/questions/66309283/convert-ecmascript-observable-zen-observable-to-rxjs-observable
 * gqlToRx(API.graphql(graphqlOperation(query, inputs)));
 */
export const gqlToRx = <T>(gql: ZenObservable<T> | Promise<GraphQLResult<T>>): Observable<T> => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const zenToRx = <T>(zenObservable: ZenObservable<T>): Observable<T> =>
    new Observable((observer) => zenObservable.subscribe(observer));
  // console.log({ gql, typeof: typeof gql });
  return zenToRx(gql as ZenObservable<T>);
};
