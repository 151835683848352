/** @format */

import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SHARING_METHOD } from '../social-sharing.model';
import { ENABLE_FACEBOOK, ENABLE_TWITTER, ENABLE_LINKEDIN } from '@app/app.config';
import { ShareableItem } from '../social-sharing.model';
import { PopoverAction } from '@shared/components/action-popover/action-popover.component';

@Component({
  selector: 'app-social-sharing-popover',
  templateUrl: './social-sharing-popover.component.html',
  styleUrls: ['./social-sharing-popover.component.scss'],
})
export class SocialSharingPopoverComponent {
  @Input() item: ShareableItem;

  actions: PopoverAction[] = [
    { type: SHARING_METHOD.FACEBOOK, text: 'Facebook', icon: 'logo-facebook', disabled: !ENABLE_FACEBOOK },
    { type: SHARING_METHOD.TWITTER, text: 'Twitter', icon: 'logo-twitter', disabled: !ENABLE_TWITTER },
    { type: SHARING_METHOD.LINKEDIN, text: 'LinkedIn', icon: 'logo-linkedin', disabled: !ENABLE_LINKEDIN },
    { type: SHARING_METHOD.CLIPBOARD, text: 'Copy Link', icon: 'link' },
    { type: SHARING_METHOD.QR, text: 'QR Code', icon: 'scan' }, // v4:'qr-scanner'
  ];

  constructor(private popoverCtrl: PopoverController) {}

  async onSelected(action: string) {
    this.dismiss({ action });
    // console.log(action);
  }

  async dismiss(data = { action: 'dismiss' }) {
    const popover = await this.popoverCtrl.getTop();
    if (popover) {
      popover.dismiss(data);
    }
  }
}
