/** @format */

import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { resetStoreThenReboot } from '@store/actions/reset.actions';
import { AlertController, ActionSheetController } from '@ionic/angular';
import { ToasterColor, ToasterPosition, ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';

const DEBUG_LOGS = false;
const PAGE = '[UpdateService]';

/**
 * Service Worker Update Service
 * https://angular.io/guide/service-worker-communications
 *
 */
@Injectable({
  providedIn: 'root',
})
export class UpdatesService {
  get isEnabled(): boolean {
    return this.updates.isEnabled;
  }

  private doClearStoreOnRefresh: boolean = true;

  constructor(
    private updates: SwUpdate,
    private appRef: ApplicationRef,
    private alertController: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private toaster: ToasterService,
    private store: Store<State>,
    private translate: TranslateService
  ) {
    this.subUpdatesAvailable(updates);
  }

  /**
   * Present ActionSheet of options to take to Reload Service Worker
   */
  async presentReloadOptions() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translate.instant('UPDATES.RELOAD_APP'),
      mode: 'ios',
      cssClass: 'reload-app-action-sheet',
      buttons: [
        {
          text: this.translate.instant('UPDATES.CLEAR_DATA'),
          role: 'destructive',
          icon: 'warning-outline',
          handler: () => {
            this.reloadApp();
          },
        },
        ...(this.isEnabled
          ? [
              {
                text: this.translate.instant('UPDATES.CHECK'),
                icon: 'cloud-download-outline',
                handler: () => {
                  this.checkForUpdates();
                },
              },
            ]
          : []),
        {
          text: this.translate.instant('UPDATES.REFRESH'),
          icon: 'refresh',
          handler: () => {
            this.refreshPage();
          },
        },
        {
          text: this.translate.instant('COMMON.CANCEL'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          },
        },
      ],
    });
    await actionSheet.present();
  }

  /**
   * use these events to notify the user of a pending update
   * or to refresh their pages when the code they are running is out of date
   * https://angular.io/guide/service-worker-communications#available-and-activated-updates
   */
  private subUpdatesAvailable(updates) {
    // console.log(`%c ${PAGE}! %c(v${environment.version})`,"color:#f37941;font-size:120%;", "font-size:80%;");
    DEBUG_LOGS && console.log(`${PAGE} subscribing to updates available...`);
    DEBUG_LOGS && console.log(updates);

    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.showUpdateAvailablePrompt();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });

    // the version of the application used by the service worker to serve a client might be in a broken state that cannot be recovered from without a full page reload
    // see: https://angular.io/guide/service-worker-communications#handling-an-unrecoverable-state
    updates.unrecoverable.subscribe((event) => {
      this.translate
        .get(['ERRORS.UNRECOVERABLE', 'ERRORS.PLEASE_RELOAD'])
        .pipe(take(1))
        .subscribe((t) => {
          this.toaster.present(
            `${t['ERRORS.UNRECOVERABLE']}: <br>${event.reason}<br><br>${t['ERRORS.PLEASE_RELOAD']}`,
            ToasterPosition.Middle,
            12000,
            ToasterColor.Light
          );
        });
    });
  }

  /**
   * If the current tab needs to be updated to the latest app version immediately,
   * it can ask to do so with the activateUpdate() method
   * https://angular.io/guide/service-worker-communications#forcing-update-activation
   *
   * Calling activateUpdate() without reloading the page could break lazy-loading in a currently running app,
   * especially if the lazy-loaded chunks use filenames with hashes, which change every version.
   * Therefore, it is recommended to reload the page once the promise returned by activateUpdate() is resolved.
   */
  private activateUpdate() {
    // triggered by updates.available & confirm prompt
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  private async showUpdateAvailablePrompt() {
    const alert = await this.alertController.create({
      cssClass: 'alert-update-available',
      header: this.translate.instant('UPDATES.NEW_VERSION_AVAIL'),
      message: this.translate.instant('UPDATES.NEW_VERSION_MSG'),
      buttons: [
        {
          text: this.translate.instant('UPDATES.LATER'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log(`${PAGE} Update Available -> LATER`);
          },
        },
        {
          text: this.translate.instant('UPDATES.RELOAD'),
          handler: () => {
            console.log(`${PAGE} Update Available -> RELOAD!`);
            this.activateUpdate();
          },
        },
      ],
    });

    await alert.present();
  }

  /**
   * ask the service worker to check if any updates have been deployed to the server.
   * The service worker checks for updates during initialization and on each navigation request—that is,
   * when the user navigates from a different address to your app.
   *
   * calling SwUpdate.checkForUpdate() will return rejected promises.
   * To avoid such an error, you can check whether the Angular service worker is enabled
   * using SwUpdate.isEnabled().
   */
  private checkForUpdates() {
    console.log(`${PAGE} checking for available updates...`);
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    appIsStable$.pipe(take(1)).subscribe(() => {
      if (this.isEnabled) {
        this.updates.checkForUpdate().catch((e) => {
          const msg = e && e.message ? e.message : this.translate.instant('UPDATES.ERROR');
          console.warn(`${PAGE} checkForUpdate caught: '${msg}'`, e);
          this.toaster.present(msg);
        });
      }
    });
  }

  /** UNUSED, FOR REF
   * ask the service worker to check if any updates have been deployed to the server.
   * The service worker checks for updates during initialization and on each navigation request—that is,
   * when the user navigates from a different address to your app.
   * However, you might choose to manually check for updates if you have a site
   * that changes frequently or want updates to happen on a schedule
   * @returns a Promise which indicates that the update check has completed successfully,
   * though it does not indicate whether an update was discovered as a result of the check
   * https://angular.io/guide/service-worker-communications#checking-for-updates
   */
  private checkForUpdatesEverySixHours() {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
  }

  private reloadApp() {
    if (this.doClearStoreOnRefresh) {
      console.log(`${PAGE} clearing store state and reloading app...`);
      // performing the reload in ResetEffects
      this.store.dispatch(resetStoreThenReboot());
    } else {
      console.log(`${PAGE} reloading app...`);
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  }
  private refreshPage() {
    console.log(`${PAGE} refreshing app...`);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }
}
