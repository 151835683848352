<!-- LOGIN FORM -->
<form
  *ngIf="mode === 'login'"
  class="fs-login-form"
  [formGroup]="loginForm"
  (keyup.enter)="loginUser()"
  (ngSubmit)="loginUser()"
>
  <fs-form-input
    label="Username or Email"
    [control]="loginForm.get('username') | formControlCast"
    [disabled]="loading"
  ></fs-form-input>
  <fs-form-input
    label="Password"
    type="password"
    [control]="loginForm.get('password') | formControlCast"
    [disabled]="loading"
  ></fs-form-input>
  <div class="fs-login-form__error" *ngIf="error">
    <div><strong>Error:</strong> {{ error }}</div>
  </div>
  <div class="ion-text-end" style="margin-top: 10px;margin-right:16px;">
    <div><a routerLink="/reset-password">{{ 'AUTH.FORGOT_PASSWORD' | translate }}</a>?</div>
  </div>
  <div class="fs-login-form__footer">
    <fs-button
      class="fs-login-form__footer__submit"
      type="submit"
      size="large"
      [disabled]="isBusy"
      >{{ 'COMMON.LOGIN' | translate }}</fs-button
    >
    <fs-button
      *ngIf="cancelText?.length > 0"
      class="fs-login-form__footer__cancel"
      buttonStyle="outline"
      (click)="cancelClick.emit()"
      >{{ cancelText }}</fs-button
    >
  </div>
</form>

<!-- NEW PASSWORD FORM -->
<form
  *ngIf="mode === 'new-password'"
  class="fs-login-form"
  [formGroup]="newPasswordForm"
  (ngSubmit)="submitNewPassword()"
>
  <p style="margin-left:16px;">{{ 'AUTH.NEW_PASSWORD_REQUIRED' | translate }}</p>
  <fs-form-input
    label="Current Password"
    type="password"
    [control]="newPasswordForm.get('password') | formControlCast"
    [disabled]="loading"
  ></fs-form-input>
  <fs-form-input
    label="New Password"
    type="password"
    [control]="newPasswordForm.get('newPassword') | formControlCast"
    [disabled]="loading"
  ></fs-form-input>
  <fs-form-input
    label="Repeat New Password"
    type="password"
    [control]="newPasswordForm.get('confirmPassword') | formControlCast"
    [disabled]="loading"
  ></fs-form-input>
  <div class="fs-login-form__error" *ngIf="error">
    <div><strong>Error:</strong> {{ error }}</div>
  </div>
  <ion-item class="fs-login-form__footer">
    <fs-button
      class="fs-login-form__footer__submit"
      type="submit"
      [disabled]="isBusy"
      >Change password and login</fs-button
    >
  </ion-item>
</form>
