<ion-content>
  <ng-container *ngIf="canEdit && !isYoutube; then editable else cannotEdit"></ng-container>
  
  <ng-template #cannotEdit>

    <div class="ion-padding ion-text-center ion-margin-top">
      <ion-text *ngIf="!isYoutube; else youtubeClip" color="light">
        {{ 'CLIP.SETTINGS.POSTER_PERMISSION' | translate }}
        <!-- Sorry, you are not able to Change the Poster Image for this Clip. -->
        <br><br>
        {{ 'CLIP.SETTINGS.POSTER_PERMISSION_2' | translate }}
        <!-- Only the person that uploaded the Clip can change the Poster Image. -->
      </ion-text>
      <ng-template #youtubeClip>
        <ion-text color="light">
          {{ 'CLIP.SETTINGS.POSTER_YOUTUBE' | translate }}
        </ion-text>
  
        <div *ngIf="clip?.poster" class="poster">
          <img [src]="clip.poster">
        </div>
      </ng-template>
    </div>

  </ng-template>
  

 

  <ng-template #editable>

    <div class="player-wrap diet-limit-width">
      <!--[class.video-fullscreen]="isLandscape"-->
      <app-video-player #selectPosterPlayer
        [playerId]="playerId" 
        [playlist]="[clip]"
        [autoplay]="false"
        [handlePlaylistDone]="false"
        [showOverlayPlay]="false"
        [showControls]="false"
        (playerReady)="onPlayerReady($event)"
        (loadedMetadata)="onLoadedMetadata($event)"
      ></app-video-player>
    </div>
    <div class="ion-text-center ion-padding diet-limit-width">
    
      <!-- title -->
      <div class="main-title">
        <h1>
          {{ 'CLIP.SETTINGS.POSTER_HELP' | translate }}
          <!-- Select poster image <small>Thumbnail of your clip</small> -->
        </h1>
      </div>
    
      <!-- range selection -->
      <ion-list class="video-range" lines="none">
        <ion-item class="image-range">
          <!-- <ion-label [style.left]="left+'%'">{{rangeLabel}}</ion-label> -->
          <ion-range 
            [min]="minRangeValue" 
            [max]="maxRangeValue" 
            [value]="sliderValue" 
            debounce="50" 
            pin="true"
            snaps="true"
            (ionChange)="onRangeChange($event)"></ion-range>
        </ion-item>
      </ion-list>
    
    
      <!-- save button -->
      <div class="ion-text-center action-buttons ion-padding-top ion-text-uppercase">
        <ion-button fill="outline" shape="round" color="primary" (click)="selectImage()" [disabled]="isSaving">
          <ion-spinner *ngIf="isSaving" slot="start" name="crescent"></ion-spinner>
          {{ 'CLIP.SETTINGS.POSTER_ACTION' | translate }}
        </ion-button>
    
      </div>
    
    </div>
</ng-template>

<div class="tab-spacer"></div>

  
</ion-content>