/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { MyStackModule } from '@app/modules/my-stack/my-stack.module';
import { VideoPlayerModule } from '@app/modules/video-player/video-player.module';
import { ExploreUiModule } from '@app/modules/explore/explore-ui/explore-ui.module';
// import { HistoryDrawerModule } from '@app/modules/history-drawer/history-drawer.module';
import { StackPlayComponent } from './stack-play/stack-play.component';
import * as fromComponents from './shared/components';
import * as fromCarousels from './carousels';
import { TaggerInputModule } from '@app/modules/tagger-input/tagger-input.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    ExploreUiModule,
    TaggerInputModule,
    VideoPlayerModule,
    MyStackModule,
    /**
     * @note Routes cannot be specified here since this Module is imported by other Modules
     * and the routes would be applied as children to those, which is not desired
     */
    // StacksRoutingModule
  ],
  declarations: [
    StackPlayComponent,
    ...fromComponents.components,
    ...fromCarousels.components
  ],
  exports: [...fromComponents.components, ...fromCarousels.components],
})
export class StacksModule {}
