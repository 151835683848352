/** @format */

import { Injectable } from '@angular/core';
import { Clip } from '@shared/models/clip.model';
import { Stack } from '@shared/models/stack.model';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { MailerApiService, MailTopic, SendMail } from '../api/mailer.api.service';
import { EventActions, EventsService } from './events.service';
import { UserService } from './user.service';

const PAGE = '[FlagService]';

@Injectable({
  providedIn: 'root',
})
export class FlagService {
  constructor(private mailerApi: MailerApiService, private userService: UserService, private events: EventsService) {
    this.events.subscribe(EventActions.FLAG, this.flag);
  }

  flag(item: Clip | Stack, flaggingDetails = '') {
    if (!item) {
      return false;
    }
    // this appears to work when not auth'd
    combineLatest([this.userService.userId$, this.userService.email$])
      .pipe(take(1))
      .subscribe((res) => {
        const email = res[0] || 'Anonymous';
        const name = res[1] || 'Anonymous';

        let itemType = 'unknown';
        let id = 'unknown';
        if ((item as Stack).stackId) {
          itemType = 'Stack';
          id = (item as Stack).stackId;
        } else if ((item as Clip).id) {
          itemType = 'Clip';
          id = (item as Clip).id;
        }

        const addl = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const addlItemDetails = item as any;
        if (addlItemDetails.clipId) {
          addl.push(`clipId: ${addlItemDetails.clipId}`);
        }
        if (addlItemDetails.clipProjectId) {
          addl.push(`clipProjectId: ${addlItemDetails.clipProjectId}`);
        }
        if (addlItemDetails.cliptitle) {
          addl.push(`cliptitle: ${addlItemDetails.cliptitle}`);
        }
        if (addlItemDetails.shareUrl) {
          addl.push(`shareUrl: ${addlItemDetails.shareUrl}`);
        }

        /**
         * Fixed in v2.8.6
         * This message should now be created correctly
         */
        const params: SendMail = {
          fromEmail: email,
          name,
          subject: `FLAGGED: ${itemType}`,
          topic: MailTopic.Flag,
          message: `${[
            flaggingDetails,
            [`Type: ${itemType}`, `Project ID: ${item.projectId}`, `ID: ${id}`, `Title: ${item.title}`, ...addl].join(
              '<br>'
            ),
          ].join('<br><br>')}`,
        };

        console.log(`flag SendMail params:`, { params });

        this.mailerApi
          .sendEmail(params)
          .pipe(take(1))
          .subscribe({
            next: (sendRes) => {
              console.log(`${PAGE} sendEmail res:`, sendRes);
            },
            error: (error) => {
              console.log(`${PAGE} sendEmail ERROR:`, error);
            },
          });
      });
  }
}
