/** @format */

import { Stack, STACK_DEFAULT_POSTER } from '@shared/models/stack.model';

export interface PathList {
  pathId: string;
  pathIndex: number;
  pathNum: number;
  title?: string;
  subTitle?: string;
  content?: string;
  poster: string;
  duration?: string;
  tags?: string[];
  iteration?: number;
  errorMessage?: string;
  didClickPlay?: boolean;
}

export const getExploreStackPathId = (stack) => `${stack.projectId}/${stack.stackId}`;

/**
 * Convert Stacks[] to a PathList[]
 * @param pathIndex if provided, would be 0 for prepend
 *                  or index being inserted at (currentPaths.length) for push
 */
export const convertStacksToPathList = ({
  stacks = [],
  pathIndex = 0,
}: // currentPaths = [],
// prepend = false
{
  stacks: Stack[];
  pathIndex?: number;
  // currentPaths?: PathList[], prepend?: boolean
}): PathList[] =>
  stacks
    .map((stack, index) => ({
      pathId: getExploreStackPathId(stack),
      // prepend = true is confusing - refactor that logic
      // pathIndex if provided, would be 0 for prepend
      // or index being inserted at (currentPaths.length)
      pathIndex: pathIndex + index,
      pathNum: pathIndex + index + 1,
      // pathIndex: (pathIndex !== 0) ? pathIndex + index : currentPaths.length + index,
      // pathNum: (pathIndex !== 0) ? pathIndex + index + 1 : currentPaths.length + index,
      title: stack.title || '',
      subTitle: '', // Array.isArray(stack.topics) ? stack.topics.join(' | ') : '',
      content: '', // unused
      poster: stack.poster || STACK_DEFAULT_POSTER,
      duration: stack.duration || '',
      tags: stack.tags || [],
      iteration: 0,
    }))
    // eslint-disable-next-line arrow-body-style
    .map((path) => {
      // console.log(`convertStacksToPathList`, path);
      return path;
    });

export function calcNumPaths(width: number): number {
  // console.log(`${PAGE} calcNumPaths resize width:`, width);
  if (!width) {
    width = 600;
  }
  // how many cols will fit at 220px width and 30px left margin?
  const cols = Math.floor(width / 180); // + 1; // add one for fun

  // console.log(`[ExploreModel] calcNumPaths width: ${width} cols: ${cols}`);
  return cols;

  // if (!width || width < 360) {
  //   return 1;
  // } else if (width < 480) {
  //   return 2;
  // } else if (width < 700) {
  //   return 3;
  // } else if (width < 960) {
  //   return 4;
  // } else if (width >= 960) {
  //   return 5;
  // } else {
  //   return 3;
  // }
}
