/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as stackActions from '@store/actions/stacks.actions';
import { StackList } from '@shared/models/list.model';
import { List, selectCollabStackListItems, selectList } from '@store/selectors/lists.selectors';
import { UserService } from '@services/user.service';
import { filter, take } from 'rxjs/operators';
import { FilterEntityTypes } from '@store/selectors/viewstate.selectors';
import { LAYOUT_TYPE } from '@shared/models/layout.model';

const COLLAB_DRAFTS_LIST_ID = 'my_collab_stack_drafts';
const COLLAB_DRAFTS_HEADING = 'STUDIO.COLLAB_DRAFT_STACKS.HEADING';
const COLLAB_DRAFTS_LABEL = 'STUDIO.COLLAB_DRAFT_STACKS.LABEL';

@Component({
  selector: 'fs-collab-drafts',
  templateUrl: './collab-drafts.component.html',
  styleUrls: ['./collab-drafts.component.scss'],
})
export class CollabDraftsComponent implements OnInit {
  stackList: StackList;
  layoutType = LAYOUT_TYPE;

  @Input() canModerate = false;
  @Input() heading: string;
  @Input() isModerated = false;
  @Input() isProjectMember = false;
  @Input() label: string;
  @Input() listId = COLLAB_DRAFTS_LIST_ID;
  @Input() projectId: string;
  @Input() showAvatar = true;
  @Input() showListIfEmpty = true;

  constructor(private translate: TranslateService, private store: Store<State>, private userService: UserService) {}

  ngOnInit(): void {
    this.stackList = {
      id: this.listId,
      label: this.labelWithDefault,
      list$: this.store.select(selectList(this.listId)),
      items$: this.store.select(selectCollabStackListItems(this.listId)), //.pipe(shareReplay(1))
    };

    this.userService.userId$
      .pipe(
        filter((id) => id && id.length > 0),
        take(1)
      )
      .subscribe((userId) => {
        if (!userId) {
          return;
        }
        this.store.dispatch(
          stackActions.loadFilteredStacks({
            listId: this.listId,
            filters: {
              // : FilterEntity
              type: FilterEntityTypes.StackDrafts,
              id: this.listId,
              q: null,
              isDraft: true,
              isCollaborative: 1,
              // userId,
              ...(this.projectId ? { projectId: this.projectId } : { userId }),
            },
          })
        );
      });
  }

  get labelWithDefault() {
    return this.label ?? this.translate.instant(COLLAB_DRAFTS_LABEL);
  }

  get headingWithDefault() {
    return this.heading ?? this.translate.instant(COLLAB_DRAFTS_HEADING);
  }

  loadMoreStacks(list: List) {
    this.store.dispatch(
      stackActions.loadMoreFilteredStacks({
        listId: list.id,
        filters: list.filters,
        nextToken: list.nextToken,
      })
    );
  }
}
