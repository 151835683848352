 <!--   eventType, canBeActive, 
        eventIsActive, 
        eventDate, 
        eventConfig, 
        subscriptionId,
        subscriptionBy, 
        subscriptionLevel, 
        subscriptionMinutes, 
        subscriptionStatus
  -->
<h3 *ngIf="showTitle">{{ 'PROJECT.ACTIVE_EVENTS_COLLAB' | translate }}:</h3>

<!-- creates circular dep...
<app-project-list
  [layout]="{
    type: layoutType.Carousel,
    options: {
      hasAddNewItemButton: false,
      newItemButtonAtEnd: true,
    }
  }"
  [items]="eventProjects"
  (newProjectClick)="createProjectEvent($event)"
></app-project-list> -->


<!-- <ion-grid>
  <ion-row>
    <ion-col size="6" sizeSm="6" sizeMd="4" sizeLg="3">
      <ion-card color="light">
        <img alt="Silhouette of mountains" src="https://ionicframework.com/docs/img/demos/card-media.png" />
        <ion-card-header>
          <ion-card-title>Card Title</ion-card-title>
          <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
        </ion-card-header>
      
        <ion-card-content>
          Here's a small text description for the card content. Nothing more, nothing less.
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col size="6" sizeSm="6" sizeMd="4" sizeLg="3">
      <ion-card color="light">
        <img alt="Silhouette of mountains" src="https://ionicframework.com/docs/img/demos/card-media.png" />
        <ion-card-header>
          <ion-card-title>Card Title</ion-card-title>
          <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
        </ion-card-header>
      
        <ion-card-content>
          Here's a small text description for the card content. Nothing more, nothing less.
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col size="6" sizeSm="6" sizeMd="4" sizeLg="3">
      <ion-card color="light">
        <img alt="Silhouette of mountains" src="https://ionicframework.com/docs/img/demos/card-media.png" />
        <ion-card-header>
          <ion-card-title>Card Title</ion-card-title>
          <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
        </ion-card-header>
      
        <ion-card-content>
          Here's a small text description for the card content. Nothing more, nothing less.
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid> -->


<ion-list lines="none">
  <ion-item *ngFor="let project of eventProjects" class="project-events__item">
    <ion-label class="ion-text-wrap">
      <ion-button *ngIf="project?.id else noId" [routerLink]="[routeProjectDetail, project.id]"
        fill="clear"
        size="default"
        class="ion-text-wrap ion-text-left">
        {{ project.title }}
      </ion-button>
      <ng-template #noId>
        {{ project.title || '?' }}
      </ng-template>
      <!-- 
          Owner: Username (not shown if my event on edit studio)
          Status : Active: Expired
          Expires: Renews: 
          Event Date
      -->
      <div class="ion-text-capitalize project-subscription-details">
        <div *ngIf="showOwner">
          <span class="italic">{{ 'COMMON.OWNER' | translate }}: </span><b>{{ project?.subscriptionBy ?? project.owner }}</b>
        </div>
        <div>
          <span class="italic">{{ 'AUTH.STATUS' | translate }}: </span><b>{{ (project.subscriptionStatus === 1 ? 'COMMON.ACTIVE' : 'COMMON.INACTIVE') | translate }}</b>
        </div>
        <div>
          <!-- COMMON.EXPIRES 
          COMMON.RENEWS -->
          <span class="italic">{{ 'COMMON.EXPIRES_OR_RENEWS' | translate }}: </span><b>{{ 'GET DATE' }}</b>
        </div>
        <!-- <div>
          <span class="italic">{{ 'AUTH.PLANS.SUBSCRIPTION_LEVEL' | translate }}: </span><b>{{ project.eventConfig?.subscriptionLevel ?? project.subscriptionLevel }}</b>
        </div> -->
        <div>
          <span class="italic">{{ 'COMMON.EVENT_DATE' | translate }}: </span>{{ project.eventDate }}
        </div>
      </div>
    </ion-label>
    <div slot="end" class="active-event-icon" title="Active Event">
      <ion-icon name="today-outline" class="no-user"></ion-icon>
    </div>
    <ion-button *ngIf="project?.canEdit && project?.subscriptionId && subscription.eventsSubIds.includes(project.subscriptionId); else noEditTpl" 
      slot="end" 
      (click)="editEventSubscription.emit({ event: $event, subscription, project })" 
      fill="outline"
      class="round"
      color="primary" class="projects-action">
      <ion-icon name="create-outline" slot="icon-only"></ion-icon>
    </ion-button>
    <ng-template #noEditTpl>
      <ion-button 
        slot="end" 
        [routerLink]="[routeProjectDetail, project.id]"
        fill="outline"
        class="round"
        color="medium" class="projects-action">
        <ion-icon name="information" slot="icon-only"></ion-icon>
      </ion-button>
    </ng-template>
  </ion-item>
</ion-list>