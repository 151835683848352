/**
 * ENVIRONmentData Store
 *
 * @format
 */
import { Action, createReducer, on } from '@ngrx/store';
import * as Environ from '../actions/environ.actions';
import * as Reset from '../actions/reset.actions';
import * as Stacks from '../actions/stacks.actions';
import * as ProjectActions from '../actions/projects.actions';
// import * as History from '../actions/history.actions';

const MAX_RECENT_PROJECTS = 5;
const newRecentProjectIds = (id, state) =>
  id
    ? [id, ...(state.recentProjectIds || []).filter((d) => d !== id).slice(0, MAX_RECENT_PROJECTS - 1)]
    : state.recentProjectIds;
export const featureKey = 'environ';

export const getId = (projectId, id): string => `${projectId}/${id}`;

export const splitId = (id): { projectId: string; id: string } => ({
  projectId: id ? id.split('/')[0] : '',
  id: id ? id.split('/')[1] : '',
});

export interface State {
  activeProjectId: string; //Project ID (If a user has opened a project, this field is updated)	User Action and this field is updated by Analytics
  // in this state we should also store the currentSelected items for saving state in localstorage
  // currentStackIdPlay?: { projectId: string; stackId: string };
  // currentStackIdEdit?: { projectId: string; stackId: string };
  recentProjectIds: string[]; // project ids in unique, recent sort

  // unused...

  permissionLevel: string; // Global (Filmstacker Admin), Regular (customer), Blocked //-> UserSyncData

  searchProjects: Array<string>; // widget silos
  searchTags: Array<string>;
  searchCollabs: Array<string>; // <User Name>, <User name> Any
  searchLocations: Array<string>; // <coordinates>, any
  searchDatetime: Array<string>; // <date/time>, any
  searchTemplates: Array<string>; // <Documentary / News>, < Characters Story>, <Music or Events>
  searchMood: Array<string>; // <Jaw dropping>, <funny>, etc etc
  searchLanguage: Array<string>; // <English>, other

  // Start Clip from AutoStart	clip ID	This is generated by AutoStart module
  // preferred masterstack length from AutoStart	#	This is generated by AutoStart module

  // activeProject: string;// activeProjUrl = "btm";//"global";
  // activeProjectTitle: string;
  // Active project: can invite other collaborators?	yes, no	User Action and this field is updated by Analytics
  // Active project: Can collaborator search / explore for clips outside of those associated with project?	yes, no	User Action and this field is updated by Analytics
  // Active project: Can collaborator invite other collaborators?	yes, no	User Action and this field is updated by Analytics
  // Active project: Can the collaborator upload / harvest clips into the project?	yes, no	User Action and this field is updated by Analytics
  // Active project: Can the collaborator change the project description, title and other settings?	yes, no	User Action and this field is updated by Analytics
  // current device informaton	hardware model, operating system version, unique device identifiers, and mobile network information
  // Internet Protocol Address
  // browser type
  // date and time
  // actual location		UserSyncData
  // User language		UserSyncData
}

export const initialState: State = {
  activeProjectId: '', //	Project ID (If a user has opened a project, this field is updated)	User Action and this field is updated by Analytics
  recentProjectIds: [],

  // unused...
  permissionLevel: '', // Global (Filmstacker Admin), Regular (customer), Blocked //-> UserSyncData
  searchProjects: [], // silos
  searchTemplates: [], // <Documentary / News>, < Characters Story>, <Music or Events>
  searchTags: [],
  searchLocations: [], // <coordinates>, any
  searchDatetime: [], // <date/time>, any
  searchMood: [], // <Jaw dropping>, <funny>, etc etc
  searchCollabs: [], // <User Name>, <User name> Any
  searchLanguage: [], // <English>, other
};

const environReducer = createReducer(
  initialState,

  on(
    Environ.reset,
    Reset.resetStore,
    Reset.resetStoreOnLogout,
    Reset.resetStoreThenReboot,
    Reset.resetStoreOnLogout,
    () => ({ ...initialState })
  ),

  on(Environ.currentClip, (state, { projectId, id }) =>
    Object.assign({}, state, {
      currentClip: { projectId, id },
    })
  ),

  on(Environ.activeProject, (state, { projectId }) => {
    if (!projectId) {
      return state;
    }

    return Object.assign({}, state, {
      activeProjectId: projectId,
    });
  }),

  /**
   * When StackActions.selectIdEdit set activeProjectId if not set
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(Stacks.selectIdEdit, (state, { projectId, stackId }) => {
    // don't update if there's no projectId or if there is already an activeProjectId set
    if (!projectId || state.activeProjectId) {
      return state;
    }
    // this should only trigger with an un-set activeProjectId, allow the Capture page to use this
    return Object.assign({}, state, {
      activeProjectId: projectId,
    });
  }),
  // set the activeProjectId if not already set

  on(Environ.recentProjects, (state, { ids = [] }) => ({
    ...state,
    recentProjectIds: ids,
  })),
  on(ProjectActions.selectById, (state, { id }) => ({
    ...state,
    recentProjectIds: newRecentProjectIds(id, state),
    activeProjectId: id || state.activeProjectId,
  })),
  on(ProjectActions.select, (state, { project = {} }) => ({
    ...state,
    recentProjectIds: newRecentProjectIds(project.id, state),
    activeProjectId: project.id || state.activeProjectId,
  })),

  on(ProjectActions.removeRecent, (state, { id }) => ({
    ...state,
    recentProjectIds: [...(state.recentProjectIds || []).filter((d) => d !== id)],
  })),

  on(ProjectActions.loadFail, (state, { id }) => ({
    ...state,
    recentProjectIds: [...(state.recentProjectIds || []).filter((d) => d !== id)],
    activeProjectId: state.activeProjectId === id ? '' : state.activeProjectId,
  })),

  // on(Environ.currentStack, Stacks.selectIdPlay, (state, { projectId, stackId }) =>
  //   Object.assign({}, state, {
  //     currentStackIdPlay: getId(projectId, stackId),
  //   }),),
  // on(Stacks.selectIdEdit, (state, { projectId, stackId }) =>
  //   Object.assign({}, state, {
  //     currentStackIdEdit: getId(projectId, stackId),
  //   }),),

  on(Environ.searchProjects, (state, { ids }) => {
    if (!Array.isArray(ids)) {
      return state;
    }
    return Object.assign({}, state, {
      searchProjects: ids,
    });
  })

  // on(Playlist.loadSuccess, (state, { current }) => {
  //   if (!current) {
  //     return state;
  //   }
  //   const { projectId = '', id = '' } = splitId(current);
  //   return Object.assign({}, state, {
  //     currentClip: {
  //       projectId,
  //       id,
  //     },
  //   });
  // }),
);

export function reducer(state: State | undefined, action: Action) {
  return environReducer(state, action);
}

export const getActiveProjectId = (state: State) => state.activeProjectId;
export const getRecentProjectIds = (state: State) => state.recentProjectIds;

export const getSearchProjects = (state: State) => state.searchProjects;
export const getSearchTemplates = (state: State) => state.searchTemplates;
export const getSearchTags = (state: State) => state.searchTags;
export const getSearchLocations = (state: State) => state.searchLocations;
export const getSearchDatetime = (state: State) => state.searchDatetime;
export const getSearchMood = (state: State) => state.searchMood;
export const getSearchCollabs = (state: State) => state.searchCollabs;
export const getSearchLanguage = (state: State) => state.searchLanguage;

// export const getSearchStart = (state: State) => {
//   return {
//     projects: state.searchProjects,
//     templates: state.searchTemplates,
//     searchTags: [],
//     searchLocations: [], // <coordinates>, any
//     searchDatetime: [], // <date/time>, any
//     searchMood: [],// <Jaw dropping>, <funny>, etc etc
//     searchCollabs: [],// <User Name>, <User name> Any
//     searchLanguage
//   }
// };

/**
 * Translate the readable key to SearchProp
 * @param key
 */
export function lookupSearchProp(key: string): string | null {
  switch (key) {
    case 'projects':
      return 'searchProjects';
    case 'templates':
      return 'searchTemplates';
    case 'tags':
      return 'searchTags';
    case 'mood':
      return 'searchMood';
    case 'location':
      return 'searchLocations';
    case 'datetime':
      return 'searchDatetime';
    default:
      return null;
  }
}
