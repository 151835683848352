/** @format */

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, selectSubscriptionLevelLabelTranslateKey } from '@billing/store';
// import { SubscriptionLevel, SUB_LEVEL_TRANSLATE_KEYS } from '@billing/shared/billing.model';

@Component({
  selector: 'fs-subscription-label',
  templateUrl: './subscription-label.component.html',
  styleUrls: ['./subscription-label.component.scss'],
})
export class SubscriptionLabelComponent {
  subscriptionLabelTranslateKey$: Observable<string> = this.store.select(selectSubscriptionLevelLabelTranslateKey);

  constructor(private store: Store<State>) {}
}
