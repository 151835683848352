/**
 * https://github.com/cordobo/angularx-qrcode#readme
 *
 * @format
 */

import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { QRCodeErrorCorrectionLevel } from 'qrcode';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent {
  @Input() qrCode: string;
  @Input() showDownload = true;
  public qrCodeDownloadLink: SafeUrl = '';

  /** RGBA color, color of dark module (foreground) */
  colorDark: string = '333333ff'; // '#000000ff';
  /** RGBA color, color of light module (background) */
  colorLight: string = 'e9eadeff'; // '#ffffffff';
  /** QR Correction level ('L', 'M', 'Q', 'H') */
  errorCorrectionLevel: QRCodeErrorCorrectionLevel = 'M';

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }
}
