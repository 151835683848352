<ng-container *ngIf="item; then hasItem; else hasNoItem"> </ng-container>

<ng-template #hasItem>
  <div class="stack-slide" [class.is-locked]="!canPlay && !canCollaborate">
    <fs-thumbnail-poster
      [item]="item"
      [isModerated]="isModerated"
      [canModerate]="canModerate"
      [currentUserId]="currentUsername"
      [canCollaborate]="canCollaborate"
      (click)="open($event, item)"
    ></fs-thumbnail-poster>
    <fs-ellipsis-button
      class="stack-slide__more"
      [class.stack-slide__more--active]="(popoverOpen$ | async) === true"
      (click)="openPopover($event)"
    ></fs-ellipsis-button>
    <h3 class="stack-slide__title" (click)="open($event, item)">
      {{ item.title }}
    </h3>
    <!-- tood: [likes]="item.likes" -->
    <fs-uploader-stats
      [date]="item.dteSaved"
      [views]="item.views"
      [shares]="item.shares"
      [likes]="-1"
      [stacks]="item.playlist?.length"
      [stacksTitle]="'CLIP.CLIPS_IN_PLAYLIST' | translate"
      [showAvatar]="showAvatar"
      [userId]="item.userId"
      [indicateHls]="!!item.hlsSrc"
      [indicateNeedsApproved]="isModerated && !item.isApproved"
    >
    </fs-uploader-stats>
    <div *ngIf="item.isCollaborative" class="indicator-icon" [title]="'STACK.COLLABORATIVE_DRAFT' | translate">
      <ion-icon name="people-outline" class="no-pointer-events"></ion-icon>
    </div>
    <!-- <div *ngIf="isModerated && !item.isApproved" class="approved-indicator" [title]="'STACK.ACTIONS.REQUIRES_MODERATION' | translate">
      <ion-button size="small" fill="clear" color="medium" (click)="moderate($event)">
        {{ 'STACK.ACTIONS.NEEDS_REVIEW' | translate }}
        <ion-icon name="lock-closed-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </div> -->
    <!-- for dev - to be removed -->
    <!-- <div *ngIf="item.hlsSrc" class="hls-indicator" title="Premiere Quality">
      <ion-icon name="aperture-sharp" class="no-pointer-events"></ion-icon>
    </div>
    <div *ngIf="item.isApproved" class="approved-indicator" title="Moderated">
      <ion-icon name="thumbs-up-outline" class="no-pointer-events"></ion-icon>
    </div> -->
    <!-- end remove -->
  </div>
</ng-template>

<ng-template #hasNoItem>
  <div class="stack-slide">
    <div class="stack-slide__image"></div>
    <div
      class="stack-slide__title"
      style="background: var(--background); width: 40%; height: 20px"
    ></div>
    <div
      class="stack-slide__content"
      style="background: var(--background); width: 80%; height: 20px"
    ></div>
  </div>
</ng-template>
