/**
 * Resolve missing type from
 * import { GraphQLResult } from 'aws-amplify/lib/API/types';
 *
 * @format
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

export interface GraphQlParams {
  limit?: number;
  nextToken?: string;
  filter?: object;
  projectId?: string;
  userId?: string;
}

export interface GraphQlParamsStacks extends GraphQlParams {
  stackId?: string;
  poster?: string;
  featured?: number;
  isApproved?: boolean;
  updatedBy?: string;
}

export interface ApiOptions {
  headers: any;
  endpoints: any;
  credentials?: any;
}
export interface GraphQLOptions {
  query: string | any;
  variables?: any;
}
export interface GraphQLResult {
  data?: any;
  errors?: [any];
  extensions?: {
    [key: string]: any;
  };
}

/**
 * Updates to make to a model
 * { prop: string, value: any }
 */
export interface UpdateParam {
  prop: string;
  value: string | number | boolean | object;
}
/**
 * Number Updates to make to a model
 * { prop: string, value: number }
 */
export interface UpdateParamInt {
  prop: string;
  value: number;
}

/**
 * Copying from src as they were removed from
 * import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
 * https://github.com/aws-amplify/amplify-ui/blob/legacy/legacy/amplify-ui-components/src/common/types/auth-types.ts
 */
export enum AuthState {
  SignUp = 'signup',
  SignOut = 'signout',
  SignIn = 'signin',
  Loading = 'loading',
  SignedOut = 'signedout',
  SignedIn = 'signedin',
  SigningUp = 'signingup',
  ConfirmSignUp = 'confirmSignUp',
  confirmingSignUpCustomFlow = 'confirmsignupcustomflow',
  ConfirmSignIn = 'confirmSignIn',
  confirmingSignInCustomFlow = 'confirmingsignincustomflow',
  VerifyingAttributes = 'verifyingattributes',
  ForgotPassword = 'forgotpassword',
  ResetPassword = 'resettingpassword',
  SettingMFA = 'settingMFA',
  TOTPSetup = 'TOTPSetup',
  CustomConfirmSignIn = 'customConfirmSignIn',
  VerifyContact = 'verifyContact',
}

export interface CognitoUserInterface {
  Session?: string | null;
  authenticationFlowType?: string;
  client?: {
    endpoint?: string;
    userAgent?: string;
  };
  keyPrefix?: string;
  pool?: {
    advancedSecurityDataCollectionFlag?: boolean;
    clientId?: string;
    userPoolId?: string;
  };
  username?: string;
  userConfirmed?: boolean;
  userSub?: string;
  challengeName?: string;
  challengeParam?: { [key: string]: any };
  unverified?: {
    email?: string;
    phone_number?: string;
  };
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  [attributes: string]: any;
}

export interface ICredentials {
  accessKeyId?: string;
  sessionToken?: string;
  secretAccessKey?: string;
  identityId?: string;
  authenticated: boolean;
  expiration?: Date;
  expired?: boolean;
  expireTime?: string;
  message?: string;
  name?: string;
}
