<fs-base-modal [title]="''" #modal>
  <div *ngIf="shareItem else noItem" class="container">
    
    <div class="">
      <fs-list-title class="ion-text-uppercase">{{ 'STACK.PUBLISHED' | translate }}</fs-list-title>
      <fs-page-title>{{ 'STACK.CHECK_OUT_SHARE' | translate }}</fs-page-title>
    </div>

    <div class="share-buttons row ion-padding">
      <ion-button fill="outline" shape="round" color="light" (click)="share(shareToOptions.FACEBOOK)"
        [title]="'SOCIAL.SHARE_FACEBOOK' | translate">
        <ion-icon slot="icon-only" name="logo-facebook"></ion-icon>
      </ion-button>
      <ion-button fill="outline" shape="round" color="light" (click)="share(shareToOptions.TWITTER)"
        [title]="'SOCIAL.SHARE_TWITTER' | translate">
        <ion-icon slot="icon-only" name="logo-twitter"></ion-icon>
      </ion-button>
      <ion-button fill="outline" shape="round" color="light" (click)="share(shareToOptions.LINKEDIN)"
        [title]="'SOCIAL.SHARE_LINKEDIN' | translate">
        <ion-icon slot="icon-only" name="logo-linkedin"></ion-icon>
      </ion-button>
    </div>

    <div class="row">
      <!-- 
        todo: how to implement a named input and pass readonly attr to fs-input? (how to accomplish the below?)
      -->
      <!-- <fs-input #inputShareUrl label="Share URL" [value]="[shareUrl]"></fs-input> -->
      <ion-item class="share-item">
        <ion-input #inputShareUrl readonly selectAllOnClick [value]="shareItem?.shareUrl"></ion-input>
        <ion-button shape="round" (click)="share(shareToOptions.CLIPBOARD, $event)"
          [title]="'SOCIAL.COPY_TO_CLIPBOARD' | translate">
          <ion-icon name="link" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-item>
    </div>

    <div class="action-buttons row ion-padding">
      <ion-button [disabled]="optimizingStack" fill="outline" color="light" (click)="watchStack()">
        <!-- timeout here -->
        <ng-container *ngIf="optimizingStack else readyToWatch">
          {{ 'STACK.OPTIMIZING_STACK' | translate }}
          <ion-spinner *ngIf="optimizingStack" slot="start" name="crescent"></ion-spinner>
        </ng-container>
        <ng-template #readyToWatch>
          <ion-icon slot="start" name="play"></ion-icon>
          {{ 'EDITOR.WATCH_STACK' | translate }}
        </ng-template>
      </ion-button>
      
      <ion-button fill="outline" color="light"  (click)="newStack()">
        <ion-icon slot="start" name="refresh"></ion-icon>
        {{ 'EDITOR.CREATE_NEW_STACK' | translate }}
      </ion-button>
    </div>
  </div>
  <ng-template #noItem>
    <p>
      {{ 'ERRORS.MISSING_DATA' | translate }}
    </p>
  </ng-template>
</fs-base-modal>
