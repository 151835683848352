/** @format */

import { Injectable } from '@angular/core';
import { Observable, throwError, from, of } from 'rxjs';
import { catchError, map, mergeMap, take, toArray } from 'rxjs/operators';
import _uniqWith from 'lodash/uniqWith';
import _chunk from 'lodash/chunk';
import { API, Storage } from 'aws-amplify';
// import { API, graphqlOperation } from 'aws-amplify'; // TODO: see definition of API.graphql use GraphQLAPI
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
// GraphQL Subscription needs to use Observable from zen-observable-ts (not from rxjs)
// import { Observable as ZenObservable } from './../../../../node_modules/zen-observable-ts';
import { gqlToRx } from './api-amplify-zen';

import { Utils } from '@shared/utils';
import { UserService } from '../services/user.service';
import { SentryService } from '../services/analytics/sentry.service';
import { Clip, DEFAULT_POSTER } from '@shared/models/clip.model';
import { ALL_CLIP_FIELDS, SUB_CLIP_FIELDS } from './api.models';
import { GraphQlParams, UpdateParam, UpdateParamInt } from './api-types';
import { environment, filestackConfig } from 'src/environments/environment';
import { MailerApiService, MailTopic, SendMail } from './mailer.api.service';
import { Stack } from '@shared/models/stack.model';
import { getId } from '@store/selectors/clips.selectors';
import { FilterEntity, FilterEntityTypes } from '@store/selectors/viewstate.selectors';

const DEBUG_LOGS = false;

//TODO: get from Amplify
const S3_CLIP_BUCKET = 'filmstacker-amp-v1-userfiles-master'; //"filmstackeramplify-userfiles-mobilehub-1717823512";
const S3_CLIP_BUCKET_REGION = 'us-west-2';
// const S3_CLIP_PREFIX = "videos/sources/";
// const S3_CLIP_PREFIX = "public/videos/";
// const S3_CLIP_PREFIX = "uploads/";
const S3_CLIP_PREFIX = 'uploads/videos/';
const S3_FOLDER = 'public/'; // todo: why is there no mention of "uploads" in the amplify storage docs?! (https://github.com/aws-amplify/amplify-js/issues/1636)

export const DEFAULT_FETCH_LIMIT = 20; // default to get
export const MAX_FETCH_LIMIT = 88; // cap the limit for doubling on nextToken loadMores

export const PUBLIC_CONTENT_URL = 'https://content.filmstacker.com/';

export enum ApiErrorCodes {
  ClipNoSources = 'Clip no sources',
  ClipNotFound = 'Clip not found',
}

export interface S3UploadResponse {
  key?: string;
  src?: string;
  error?: string;
  message?: string;
}

// clip.s3Object - can't get to work in appsync as of 9/13/18...
interface S3ObjectInput {
  bucket: string;
  key: string;
  region: string;
  localUri?: File;
  mimeType?: string;
}

/**
 * API Helper: Map Response to ClipModel
 */
export const convertClipResponse = (clip): Clip => {
  if (!clip) return clip;
  if (typeof clip.hlsMeta === 'string') {
    clip.hlsMeta = Utils.tryParseJSON(clip.hlsMeta);
  }

  if (!clip.sources) {
    return {
      error: ApiErrorCodes.ClipNoSources, // if the payload had an error, keep that one.
      ...clip,
    };
  }

  if (!clip.poster) {
    clip.poster = DEFAULT_POSTER;
  } else if (!clip.youtube_id && !clip.poster.startsWith('http')) {
    clip.poster = PUBLIC_CONTENT_URL + clip.poster;
  }

  clip.sources = clip.sources.map((source) => {
    if (source.src && !source.src.startsWith('http')) {
      source.src = PUBLIC_CONTENT_URL + source.src;
    }
    return source;
  });

  return new Clip(clip);
};

const PAGE = '[ClipsApi]';
/**
 * Clip API interactions
 */
@Injectable({
  providedIn: 'root',
})
export class ClipsApiService {
  types = {
    projectId: 'ID!',
    id: 'String!',
    userId: 'String',
    created: 'AWSDateTime',
    userIdentityId: 'String',
    geoLat: 'String',
    geoLng: 'String',
    filmingDate: 'AWSDateTime',
    language: 'String',
    source: 'ClipVideoFileInput',
    sourceUrl: 'String',
    sourcePath: 'String',
    sources: '[ClipVideoFileInput!]',
    tracks: '[ClipVideoTrackInput]',
    duration: 'String',
    title: 'String',
    description: 'String',
    poster: 'String',
    topics: '[String!]',
    tags: '[String!]',
    emotions: '[String!]',
    views: 'Int',
    votes: 'Int',
    featured: 'Int',
    suggested: 'Int',
    private: 'Boolean',
    privacy: 'CLIP_PRIVACY',
    // Clip cannot be trimmed
    isLocked: 'Boolean',
    // if Project.isModerated
    isApproved: 'Boolean',
    modified: 'AWSDateTime',
    youtube_id: 'String',
    youtube_id_nomusic: 'String',
    startTime: 'String',
    endTime: 'String',
    transcript: 'String',
    numStacks: 'Int',
    hlsSrc: 'String',
    hlsMeta: 'String',
    updatedBy: 'String',
  };

  constructor(
    private userService: UserService,
    private sentryService: SentryService,
    private mailerApi: MailerApiService
  ) {}

  /**
   * Subscribe to ClipUpdates
   * clipUpdated(projectId: ID id: String userId: String): Clip
   * unsubscribe before subscribe not needed, as we may have multiple subscriptions waiting for specific clip ids
   * https://docs.aws.amazon.com/appsync/latest/devguide/aws-appsync-real-time-data.html
   *
   * @param filters if props set will filter results
   * @returns Observable
   *
   * @note All subscription parameters must exist as a field in the returning type.
   * This means that the type of parameter must also match the type of the field in the returning object.
   * https://stackoverflow.com/questions/64308456/how-to-make-subscription-with-arguments-correctly-in-graphql
   */
  subscribeClipsUpdated({ projectId, id, userId }: { projectId?: string; id?: string; userId?: string }) {
    // : Observable<any>
    DEBUG_LOGS && console.log(`${PAGE} subscribeClipsUpdated...`, { projectId, id, userId });
    const inputs: Partial<Clip> = {};
    let inputDef = '';
    let inputVals = '';
    if (projectId) {
      inputs.projectId = projectId;
      inputDef += '$projectId: ID ';
      inputVals += 'projectId: $projectId ';
    }
    if (id) {
      inputs.id = id;
      inputDef += '$id: String ';
      inputVals += 'id: $id ';
    }
    if (userId) {
      inputs.userId = userId;
      inputDef += '$userId: String ';
      inputVals += 'userId: $userId ';
    }
    const endpoint = 'clipUpdated';
    const query = `subscription clipUpdated (${inputDef}) {
      clipUpdated (${inputVals}) {
        ${SUB_CLIP_FIELDS}
      }
    }`;

    /*
      Successful response :
      {
        "data": {
          "clipUpdated": {
            "projectId": "filmstacker-dev",
            "id": "jd_-_kailua-1MOV__202001311111",
            "hlsMeta": "{\"submitTime\":\"2021-08-20T19:02:46.000Z\",\"finishTime\":\"2021-08-20T19:03:19.000Z\",\"hlsFilename\":\"jd_-_kailua-1MOV__202001311111.m3u8\",\"mp4Filename\":\"jd_-_kailua-1MOV__202001311111_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4\",\"thumbFilename\":\"jd_-_kailua-1MOV__202001311111_thumb.0000000.jpg\",\"hlsDestination\":\"https://videos.filmstacker.com/public/filmstacker-dev/jd_-_kailua-1MOV__202001311111/\",\"mp4Destination\":\"https://videos.filmstacker.com/public/filmstacker-dev/jd_-_kailua-1MOV__202001311111/\",\"thumbDestination\":\"https://videos.filmstacker.com/public/filmstacker-dev/jd_-_kailua-1MOV__202001311111/\",\"updatedAt\":\"2021-09-03T20:41:25.932Z\"}",
            "hlsSrc": testSrc6,
            "modified": null
          }
        }
      }
    */

    DEBUG_LOGS && console.log(`${PAGE} calling gql ${endpoint}`, { inputs, query });

    // need Observable from rxjs not zen-observable-ts
    const apiObs = gqlToRx(API.graphql(graphqlOperation(query, inputs)));
    return apiObs.pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(({ provider, value }) => {
        if (!value || !value.data[endpoint]) {
          console.warn(`${PAGE} ${endpoint} pipe missing value.data.${endpoint}? value:`, value);
          throw new Error(`API Response missing value.data.${endpoint}`);
        }
        DEBUG_LOGS && console.log(`${PAGE} ${endpoint} pipe:`, value.data[endpoint]);
        return value.data[endpoint];
      }),
      catchError((e) => {
        console.warn(`${PAGE} subscribeClipsUpdated caught `, e.error || e);
        return throwError(e.error || e);
      })
    );

    // return the observable and allow the caller to handle subscription directly
    // .subscribe({
    //   next: (res) => {
    //     DEBUG_LOGS && console.log(`***${PAGE} ${endpoint} next:`, res);
    //   },
    //   error: (err) => {
    //     console.warn(`${PAGE} ${endpoint}: `, err);
    //   },
    //   complete: () => {
    //     DEBUG_LOGS && console.log(`${PAGE} ${endpoint} subClipUpdates => Complete`);
    //   }
    // });
  }

  /**
   * createFilterObject for graphQL
   * @returns object
   * 
   * https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js#filtering-queries
   * 
   * FilterEntity:
      type?: 'FEATURED' | 'RECENT';
      q?: string;
      tags?: string; // e.g. “wildlife,sea,land” concatenated by “,”
      users?: string; // e.g. “simwicki,jd” concatenated by “,”
   */
  createFilterObject(filters: FilterEntity): Record<string, object> {
    /**
     * DynamoDB values are case-sensitive in DB.
     * The Backend is concatenating the desired fields into 'searchField',
     * so we can simply query CONTAINS in searchField for q in LOWERCASE
     */
    let result: Record<string, object> = {};
    if (filters) {
      if (filters.q && typeof filters.q.toLowerCase === 'function') {
        // only alphanumeric
        result['searchField'] = { contains: filters.q.toLowerCase() };
      }
      if (filters.type === FilterEntityTypes.Unused) {
        result.or = [{ numStacks: { lt: 1 } }, { numStacks: { attributeExists: false } }];
      }
      if (filters.tags) {
        console.warn(`TODO: createFilterObject HANDLE tags`, filters);
      }
      // might need to rethink this for searching by users
      // current iteration is for FilterEntityTypes.Mine in Project Page
      // ? && filters.type === FilterEntityTypes.Unused
      if (filters.users) {
        // users?: string; // e.g. “simwicki,jd” concatenated by “,”
        const users = filters.users.split(',');
        if (users.length > 0) {
          if (users.length > 1) {
            console.warn(`TODO: createFilterObject HANDLE MULTIPLE users`, { users, filters });
          }
          result.userId = { eq: users[0] };
        }
      }
      if (filters.isApproved) {
        result.isApproved = { eq: true };
      }
    }
    if (Object.keys(result).length > 1) {
      result = { and: [result] };
    }
    DEBUG_LOGS && console.log(`${PAGE} createFilterObject`, { result, filters });
    return result;
  }

  /**
   * @deprecated use createFilterObject
   * 
   * createFilterString for graphQL
   * @returns string
   * 
   * @note consider refactoring to use filter object param instead of string
   * https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js#filtering-queries
   * 
   * FilterEntity:
      type?: 'FEATURED' | 'RECENT';
      q?: string;
      tags?: string; // e.g. “wildlife,sea,land” concatenated by “,”
      users?: string; // e.g. “simwicki,jd” concatenated by “,”
   */
  createFilterString(filters: FilterEntity): string {
    /**
     * DynamoDB values are case-sensitive in DB.
     * The Backend is conactenating the desired fields into 'searchField',
     * so we can simply query CONTAINS in searchField for q in LOWERCASE
     */
    const searchField = 'searchField';
    let result = '';
    if (filters) {
      if (filters.q) {
        // only alphanumeric
        const q = filters.q.toLowerCase();
        result += `${searchField}: { contains: "${q}" }`;
      }
      if (filters.tags) {
        console.warn(`TODO: createFilterString HANDLE tags`, filters);
      }
      if (filters.users) {
        console.warn(`TODO: createFilterString HANDLE users`, filters);
      }
      if (filters.type === FilterEntityTypes.Unused) {
        result += `or: [{ numStacks: { lt: 1 } }, { numStacks: { attributeExists: false } }]`;
      }
    }
    DEBUG_LOGS && console.log(`${PAGE} createFilterString`, { result, filters });
    return result;
  }

  /*
    v2.8 List API Logic
  */

  /**
   * queryClipsByProject(projectId: ID!, filter: TableClipFilterInput, limit: Int, nextToken: String ): ClipConnection
   */
  queryClipsByProject({
    projectId,
    limit = DEFAULT_FETCH_LIMIT,
    nextToken = '',
    filters = {},
  }): Observable<{ items: Clip[]; nextToken: string }> {
    const endpoint = 'queryClipsByProject';
    if (!projectId) {
      throw Promise.reject('No ID Provided');
    }
    const params: GraphQlParams = {
      projectId,
      limit,
    };

    if (nextToken) {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} [${projectId}] using nextToken`);
      params.nextToken = nextToken;
    }

    // let filterStr = '';
    if (Utils.isEmptyObj(filters)) {
      // default public
      // filterStr = `private:{ ne: true } privacy:{ eq: "PUBLIC" }`;
      params.filter = { private: { ne: true }, privacy: { eq: 'PUBLIC' } };
    } else {
      // filterStr = this.createFilterString(filters as FilterEntity);
      params.filter = this.createFilterObject(filters as FilterEntity);
    }

    // if (filterStr) {
    //   filterStr = `filter: { ${filterStr} }`;
    // }
    if (Object.keys(params.filter).length < 1) {
      params.filter = null;
    }

    const query = `query queryClipsByProject(
      $projectId: ID!
      $limit: Int
      $nextToken: String
      $filter: TableClipFilterInput
    ) {
      queryClipsByProject(
        projectId: $projectId
        limit: $limit
        nextToken: $nextToken 
        filter: $filter
      ) {
        items {
          ${ALL_CLIP_FIELDS}
        }
        nextToken
      }
    }`;

    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
        const obj = res.data[endpoint];
        if (obj && Array.isArray(obj.items)) {
          obj.items = obj.items.map(convertClipResponse);
        }
        return obj;
      })
    );
  }

  /**
   * queryClipsByUser(userId: String!, filter: TableClipFilterInput, limit: Int, nextToken: String ): ClipConnection
   */
  queryClipsByUser({
    userId,
    limit = DEFAULT_FETCH_LIMIT,
    nextToken = '',
    filters = {},
  }): Observable<{ items: Clip[]; nextToken: string }> {
    const endpoint = 'queryClipsByUser';
    if (!userId) {
      throw Promise.reject('No ID Provided');
    }
    const params: GraphQlParams = {
      userId,
      limit,
    };

    if (nextToken) {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} [${userId}] using nextToken`);
      params.nextToken = nextToken;
    }

    // let filterStr = '';
    if (Utils.isEmptyObj(filters)) {
      // default public - assuming that if we're in Studio then the userId is included in filters and therefore we can return private
      // filterStr = `private:{ ne: true } privacy:{ eq: "PUBLIC" }`;
      params.filter = { private: { ne: true }, privacy: { eq: 'PUBLIC' } };
    } else {
      // filterStr = this.createFilterString(filters as FilterEntity);
      params.filter = this.createFilterObject(filters as FilterEntity);
    }

    // if (filterStr) {
    //   filterStr = `filter: { ${filterStr} }`;
    // }
    if (Object.keys(params.filter).length < 1) {
      params.filter = null;
    }

    const query = `query queryClipsByUser(
      $userId: String!
      $limit: Int
      $nextToken: String
      $filter: TableClipFilterInput
    ) {
      queryClipsByUser(
        userId: $userId
        limit: $limit
        nextToken: $nextToken 
        filter: $filter
      ) {
        items {
          ${ALL_CLIP_FIELDS}
        }
        nextToken
      }
    }`;

    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
        const obj = res.data[endpoint];
        if (obj && Array.isArray(obj.items)) {
          obj.items = obj.items.map(convertClipResponse);
        }
        return obj;
      })
    );
  }

  /*
     Clips Effects Helpers
   */

  /**
   * Clips Effects Helper: get clips from api, notify via email if missing
   * respond back when all are complete
   *
   * @param clipIds array of projectId, id objects
   * @param stack is only needed for more accurate error checking
   */
  loadClipsByIds(
    clipIds: { projectId: string; id: string }[],
    stack?: Stack
  ): Observable<{ clips: Clip[]; error?: string }> {
    if (!Array.isArray(clipIds) || clipIds.length < 1) {
      console.warn(`${PAGE} loadClipsByIds - NO IDS?`, clipIds);
      return of({ clips: [], error: `No IDs were provided` });
    }
    DEBUG_LOGS && console.log(`${PAGE} loadClipsByIds`, clipIds);

    const devInjectrrors = false;
    if (devInjectrrors) {
      clipIds.push({ projectId: 'filmstacker-dev', id: 'dne1' });
      clipIds.splice(0, 0, { projectId: 'filmstacker-dev', id: 'dne2' });
      clipIds.splice(4, 0, { projectId: 'filmstacker-dev', id: 'dne3' });
    }

    /**
     * 1. remove null values
     * 2. take the index of null and assoc with input ClipId -> Flag for Missing (or in API??)
     * 3. clip.error: ApiErrorCodes.ClipNotFound
     */
    return this.batchGetClips(clipIds).pipe(
      take(1),
      map((res) => {
        if (!Array.isArray(res) || res.length < 1) {
          console.warn(`${PAGE} loadClipsByIds - res NOT ARRAY?`, res);
          return { clips: [], error: `Result NOT ARRAY` };
        }
        // from original input, which were not found (null or error)
        const indexNotFound = [];
        const clips = [];
        res.forEach((clip, index) => {
          // api errors or not found (null) - get index from original input
          if (!clip || !clip.id) {
            console.warn(`${PAGE} loadClipsByIds clip not found - remove from result!`, {
              index,
              clipId: clipIds[index],
            });
            indexNotFound.push(index);
          } else {
            // * 1. remove null values
            clips.push(clip);
          }
        });

        DEBUG_LOGS &&
          console.log(`${PAGE} loadClipsByIds batchGetClips res:`, {
            indexNotFound,
            indexErrorClipIds: indexNotFound.map((i) => clipIds[i]),
            clips,
            res,
          });

        if (clips.length === clipIds.length) {
          return { clips };
        }

        if (indexNotFound.length > 0) {
          // * 2. take the index of null and assoc with input ClipId -> Flag for Missing (eventually in API..)
          if (environment.production) {
            try {
              const notFoundClipIds = indexNotFound.map((index) => getId(clipIds[index].projectId, clipIds[index].id));

              const msg =
                clips.length < 1
                  ? `App.ClipService DELETE Stack! Playlist is Empty: Clips not Found in DB`
                  : `App.ClipService Clips not Found in DB`;
              const payload: { clips: string; stackId?: string } = {
                clips: notFoundClipIds.join(', '),
              };
              if (stack && stack.stackId) {
                if (stack.projectId === 'editor' && stack.stackId === 'mystack') {
                  payload.stackId = `${stack.projectId}/${stack.stackId}(${this.userService.getUserId()})`;
                } else {
                  payload.stackId = `${stack.projectId}/${stack.stackId}`;
                }
              }

              this.notifyErrorEmail(msg, payload);
            } catch (error) {
              console.warn(`${PAGE} Caught error while reporting`, error);
              this.sentryService.captureError(error);
            }
          } // end prod

          // this is done in mystack.service instead
          // if (stack && stack.projectId === 'editor' && stack.stackId === 'mystack') {
          //   // indexErrorClipIds.map(clip => // removeClip()
          // }
        }

        if (clips.length > 0) {
          // convertClipResponse already done in batchGetClips...
          return { clips };
        } else {
          /**
           * No clips, delete the Stack!
           */
          throw new Error("Hmm.. we couldn't find the clips for that Stack?");
        }
      }),
      catchError((e) => {
        console.warn(`${PAGE} batchClips pipe caught `, e);
        return throwError(() => e);
      })
    );
  }

  /**
   * Get Clip
   * @param projectId
   */
  getClip(projectId: string, clipId: string): Promise<Clip | Partial<Clip>> {
    // console.log(`${PAGE} getClip: ${projectId}/${clipId}`);
    const endpoint = 'getClip';
    if (!projectId || !clipId) {
      // throw Observable.throw("No projectId Provided");
      return Promise.reject('Missing required projectId or clipId');
    }
    const params = {
      projectId,
      id: clipId,
    };

    const action = `query getClip(
      $projectId: ID!
      $id: String!
    ) {
      getClip(
        projectId: $projectId
        id: $id
      ) {
        ${ALL_CLIP_FIELDS}
      }
    }`;

    //TODO: refactor to observable
    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>)
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} getClip res:`, res);
        if (!res || !res.data) {
          console.warn(`${PAGE} getClip Result Error:`, res);
          throw new Error('getClip API Error');
        }
        const clip = res.data[endpoint] || {};
        if (!clip.id) {
          this.sentryService.captureMessage(`getClip Not Found id: '${clipId}' projectId: '${projectId}'`);

          console.warn(`${PAGE} getClip not found`, {
            clip,
            projectId,
            clipId,
          });
          /**
           * clip does not exist in the DB,
           * it should be removed from this stack
           * return Partial<Clip>
           */
          return {
            projectId,
            clipId,
            error: ApiErrorCodes.ClipNotFound,
          };
        }
        return convertClipResponse(clip);
      })
      .catch((err) => {
        console.error(`${PAGE} getClip API Error`, err);
        if (err && Array.isArray(err.errors) && err.errors.length > 0) {
          console.error(err.errors[0]);
          throw err.errors[0];
        } else {
          throw err;
        }
      });
  }

  /**
   * @deprecated use queryFilteredClipsByProject
   * Get Clips for Project
   * queryClipsByProject(projectId: ID!, filter: TableClipFilterInput, limit: Int, nextToken: String ): ClipConnection
   * @param projectId
   */
  getClipsForProject(
    projectId: string,
    limit = 50,
    nextToken = null
  ): Observable<{ items: Clip[]; nextToken: string }> {
    const endpoint = 'queryClipsByProject';
    const params = {
      projectId,
      limit,
      nextToken: nextToken ? nextToken : undefined,
    };

    /*
      $projectId: ID!
      $filter: TableClipFilterInput, 
      $limit: Int, 
      $nextToken: String
    */
    const action = `query queryClipsByProject(
      $projectId: ID!
      $limit: Int
      $nextToken: String
    ) {
      queryClipsByProject(
        projectId: $projectId
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${ALL_CLIP_FIELDS}
        }
	      nextToken
      }
    }`;

    return from(API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        // converting response to Clip objects
        const obj = res.data[endpoint];
        if (obj && Array.isArray(obj.items)) {
          obj.items = obj.items.map(convertClipResponse);
        }
        return obj;
      })
    );
  }

  /**
   * @deprecated use queryFilteredClipsByUser
   * Get Clips for User
   * @param userId (string)
   */
  getClipsForUser(
    userId: string,
    limit = 50,
    nextToken = null,
    filter = null
  ): Promise<{ items: Clip[]; nextToken?: string }> {
    const endpoint = 'queryClipsByUser';
    DEBUG_LOGS && console.log(`${PAGE} getClipsForUser[${userId}]`);
    if (!userId) {
      return Promise.reject('No userId Provided');
    }
    const params: GraphQlParams = {
      userId,
      limit,
    };
    if (typeof nextToken === 'string' && nextToken) {
      DEBUG_LOGS && console.log(`${PAGE} getClipsForUser[${userId}] using nextToken`);
      params.nextToken = nextToken;
    }
    if (filter) {
      console.log(`${PAGE} getClipsForUser[${userId}] TODO: HANDLE filter`);
    }

    /*
      queryClipsByUser(userId: String!, filter: TableClipFilterInput, limit: Int, nextToken: String)
    */

    const action = `query queryClipsByUser(
      $userId: String!
      $limit: Int
      $filter: TableClipFilterInput, 
      $limit: Int, 
      $nextToken: String
    ) {
      queryClipsByUser(
        userId: $userId,
        limit: $limit, 
        filter: $filter,
        nextToken: $nextToken
      ) {
        items {
          ${ALL_CLIP_FIELDS}
        }
	      nextToken
      }
    }`;

    //TODO: refactor to observable
    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} getClipsForUser res:`, res);
      const obj = res.data[endpoint];
      if (obj && Array.isArray(obj.items)) {
        obj.items = obj.items.map(convertClipResponse);
      }
      return obj;
    });
  }

  /**
   * @todo 2021-10-22 focus here, new S3 bucket & API
   * see Cody's error
   * https://sentry.io/organizations/filmstacker/issues/2731843105/?project=1724619
   * @todo also not working on Native iOS
   *
   * @todo verify that they can do this action (paywall)
   * Authenticated only
   * @param clip
   */
  createClip(inputClip: Clip, cbLoadingProgress = null): Promise<Clip> {
    DEBUG_LOGS && console.log(`${PAGE} createClip`, inputClip);

    // if (!this.userService.loggedIn) {
    //   return Promise.reject("Not Authenticated");
    // }

    // deep clone to avoid manipulating the inputClip object
    // const clip = JSON.parse(JSON.stringify(inputClip));
    // couldn't we just (or, loDash?)
    const clip = {
      ...inputClip,
      userIdentityId: this.userService.getIdentityId(),
      userId: this.userService.getUserId(),
      created: Utils.getDateTimeString(),
    };

    delete clip.source.title;

    if (!clip.youtube_id) {
      delete clip.source.loading;
      delete clip.source.uploading;
      delete clip.source.uploaded;
      delete clip.source.posterTimeInt;
    }

    if (clip.source && clip.source.youtube_data) {
      clip.source.youtube_data = JSON.stringify(clip.source.youtube_data);
    }

    if (!clip.startTime) {
      delete clip.startTime;
    }
    if (!clip.endTime) {
      delete clip.endTime;
    }
    if (clip.source && !clip.source.startTime) {
      delete clip.source.startTime;
    }
    if (clip.source && !clip.source.endTime) {
      delete clip.source.endTime;
    }

    // stringify the hlsMeta (AWSJSON)
    try {
      if (clip.hlsMeta && typeof clip.hlsMeta !== 'string') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (clip as any).hlsMeta = JSON.stringify(clip.hlsMeta);
      }
    } catch (error) {
      console.warn(`createClip hlsMeta caught:`, error);
      delete clip.hlsMeta;
    }

    // handle v3 filestack uploader
    if (typeof clip.source.src == 'string' && clip.source.src.startsWith(filestackConfig.storeTo.clip.path)) {
      DEBUG_LOGS && console.log(`${PAGE} FILESTACK no saveVideoFileToS3 necessary...`);
      clip.source.src = clip.source.orig;
      delete clip.source.file;
      return this.createClipMutation(clip).then(convertClipResponse);
    }

    console.warn(`Filestack bypassed? Needs investigated!`, clip);
    this.sentryService.captureMessage(`Filestack bypassed? Needs investigated clipId: ${clip.projectId}/${clip.id}`);

    // handle v2 uploader with pre-saved s3 file
    if (typeof clip.source.orig == 'string' && clip.source.orig.startsWith(S3_CLIP_PREFIX)) {
      DEBUG_LOGS && console.log(`${PAGE} no saveVideoFileToS3 necessary...`);
      clip.source.src = S3_FOLDER + clip.source.orig;
      return this.createClipMutation(clip).then(convertClipResponse);
    }

    return this.saveVideoFileToS3(clip, cbLoadingProgress).then((res) => {
      if (res && res.src) {
        clip.source.src = res.src;
      } else {
        delete clip.source.src;
      }
      // clip.source.filepath = res;
      DEBUG_LOGS && console.log(`${PAGE} createClipMutation clip:`, clip);
      // return Promise.resolve(res);
      return this.createClipMutation(clip).then(convertClipResponse);
    });
  }

  createClipMutation(clip: Clip): Promise<Clip> {
    const endpoint = 'createClip';
    // $avatar: String
    // avatar: $avatar

    const action = `mutation CreateClip(
      $projectId: ID!
      $id: String!
      $userId: String!
      $created: AWSDateTime
      $userIdentityId: String
      $geoLat: String
      $geoLng: String
      $filmingDate: AWSDateTime
      $language: String
      $source: ClipVideoFileInput
      $sourceUrl: String
      $sourcePath: String
      $sources: [ClipVideoFileInput]
      $tracks: [ClipVideoTrackInput]
      $duration: String
      $title: String
      $description: String
      $poster: String
      $topics: [String!]
      $tags: [String!]
      $emotions: [String!]
      $views: Int
      $votes: Int
      $featured: Int
      $suggested: Int
      $privacy: CLIP_PRIVACY
      $private: Boolean
      $isLocked: Boolean
      $isApproved: Boolean
      $modified: AWSDateTime
      $youtube_id: String
      $youtube_id_nomusic: String
      $startTime: String
      $endTime: String
      $hlsSrc: String
      $hlsMeta: String
    ) {
      createClip(
        projectId: $projectId
        id: $id
        userId: $userId
        created: $created
        userIdentityId: $userIdentityId
        geoLat: $geoLat
        geoLng: $geoLng
        filmingDate: $filmingDate
        language: $language
        source: $source
        sourceUrl: $sourceUrl
        sourcePath: $sourcePath
        sources: $sources
        tracks: $tracks
        duration: $duration
        title: $title
        description: $description
        poster: $poster
        topics: $topics
        tags: $tags
        emotions: $emotions
        views: $views
        votes: $votes
        featured: $featured
        suggested: $suggested
        privacy: $privacy
        private: $private
        isLocked: $isLocked
        isApproved: $isApproved
        modified: $modified
        youtube_id: $youtube_id
        youtube_id_nomusic: $youtube_id_nomusic
        startTime: $startTime
        endTime: $endTime
        hlsSrc: $hlsSrc
        hlsMeta: $hlsMeta
      ) {
        ${ALL_CLIP_FIELDS}
      }
    }`;

    // console.log(API.configure());
    //TODO: refactor to observable
    return (API.graphql(graphqlOperation(action, clip)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} createClip res:`, res);
      return res.data[endpoint];
    });
    // const observable: Observable<object> = API.graphql(graphqlOperation(AppComponent.UserSubscription)) as Observable<object>;
    // observable.subscribe({
    //   next: (value: object) => {
    //     console.log(JSON.stringify(value));
    //   },
    //   error: (error: any) => {
    //     console.log(JSON.stringify(error));
    //   }
    // });
  }

  /**
   * Delete Clip
   * @param projectId
   * @param clipId
   */
  deleteClip(projectId: string, clipId: string): Promise<Partial<Clip>> {
    const endpoint = 'deleteClip';
    console.log(`${PAGE} deleteClip: ${projectId}/${clipId}`);
    if (!projectId || !clipId) {
      // throw Observable.throw("No projectId Provided");
      return Promise.reject('Missing required projectId or clipId');
    }
    const params = {
      projectId,
      id: clipId,
    };

    const action = `mutation deleteClip(
      $projectId: ID!
      $id: String!
    ) {
      deleteClip(
        projectId: $projectId
        id: $id
      ) {
        projectId
        id
      }
    }`;

    //TODO: refactor to observable
    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} deleteClip res:`, res);
      return res.data[endpoint];
    });
  }

  createS3ObjectInput(clip: Clip): S3ObjectInput {
    const folder = clip.projectId ? clip.projectId + '/' : '';
    return {
      bucket: S3_CLIP_BUCKET,
      key: S3_CLIP_PREFIX + folder + clip.source.filename,
      region: S3_CLIP_BUCKET_REGION,
      localUri: clip.source.file,
      mimeType: clip.source.type,
    };
  }

  saveVideoFileToS3(clip: Clip, cbLoadingProgress = null): Promise<S3UploadResponse> {
    let s3Obj: S3ObjectInput;

    //S3ObjectInput
    if (clip.source && clip.source.file) {
      if (!clip.source.type) {
        console.log(`${PAGE} createClip NO TYPE!:`, clip.source);
      }
      clip.source.type = clip.source.type || 'video/mp4'; // TODO: should this be defaulted?
      const newFilename = clip.id + '.' + Utils.mimeToExtension(clip.source.type);

      clip.source.filename = newFilename; // + "__" + clip.source.filename;
      // keep the original filename, in case it's needed for analytics..
      s3Obj = this.createS3ObjectInput(clip);
      clip.source.src = s3Obj.key;
      // clip.source.filepath = s3Obj.key;
      // clip.sourcePath = clip.s3Object.key;
      // delete clip.s3Object;

      // delete clip.source.file; // do this after the put
      DEBUG_LOGS && console.log(`${PAGE} saveVideoFileToS3 clip:`, clip);

      const params: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        level: any;
        contentType: string;
        progressCallback?: (progress: { loaded: string; total: string }) => void;
      } = {
        level: 'public',
        contentType: s3Obj.mimeType,
        // progressCallback(progress) {
        //   // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        // }
      };

      if (typeof cbLoadingProgress == 'function') {
        params.progressCallback = (progress) => {
          // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          cbLoadingProgress({ loaded: progress.loaded, total: progress.total });
        };
      } else {
        DEBUG_LOGS && console.log(`${PAGE} NO progressCallback()...`);
      }

      return (
        Storage.put(s3Obj.key, s3Obj.localUri, params)
          //   {
          //   level: 'public',
          //   contentType: s3Obj.mimeType,
          //   // progressCallback(progress) {
          //   //   // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          //   // }
          // })
          .then((result: { key: string }) => {
            DEBUG_LOGS && console.log(`${PAGE} s3.put res:`, result);
            if (!result.key) {
              console.info(`${PAGE} s3.put no key?`, result);
              result.key = s3Obj.key; //todo: better error handling here, necessary?
            }
            // // delete the file object at this point?
            delete clip.source.file;

            return {
              key: result.key,
              src: S3_FOLDER + result.key,
            };
          })
          .catch((err) => {
            console.warn(err);
            return {
              error: err.message || 'Unknown Upload Error. Please Try Again.',
            };
          })
      );
    } else if (clip.youtube_id) {
      DEBUG_LOGS && console.log(`${PAGE} youtube clip - skipping upload...`);
      return Promise.resolve({
        message: 'youtube',
        src: 'youtube',
      });
    } else {
      console.info(`${PAGE} createClip no blob...`);
      return Promise.resolve({
        error: 'No File to Upload. Please Delete this Clip and Capture again.',
      });
    }
  }

  // getClipFileUploadLocation() {
  //   // bucket: awsmobile.aws_user_files_s3_bucket, region: awsmobile.aws_user_files_s3_bucket_region
  // }

  /**
   * Increment Clip Int fields - Public
   * Will increment by 1 if field is sent as not null
   * @param stackId
   */
  incrementPublic(projectId: string, id: string, updates: UpdateParamInt[]): Promise<Clip> {
    const endpoint = 'incrementPublicClip';
    DEBUG_LOGS && console.log(`${PAGE} incrementPublic`, projectId, id, updates);

    const params = {
      projectId,
      id,
    };
    updates.forEach((update) => {
      if (typeof update.value === 'number') {
        // it should be a positive or negative or zero
        // dynamically create the update params for string query
        params[update.prop] = update.value;
      }
    });

    const action = `mutation incrementPublicClip(
      $projectId: ID!
      $id: String!
      $views: Int
      $votes: Int
      $likes: Int
		  $numStacks: Int
    ) {
      incrementPublicClip(
        projectId: $projectId
        id: $id
        views: $views
        votes: $votes
        likes: $likes
		    numStacks: $numStacks
      ) {
        ${ALL_CLIP_FIELDS}
      }
    }`;

    // projectId
    // id
    // views
    // votes
    // likes
    // numStacks
    //TODO: refactor to observable
    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} incrementPublicClip res:`, res);
      const obj = res.data[endpoint];
      return convertClipResponse(obj);
    });
  }

  /**
   * Update the Clip
   * @param clip
   * @param updates
   */
  updateClip(clip: Clip, updates: UpdateParam[]): Observable<Clip> {
    if (!clip || !clip.projectId || !clip.id || !clip.userId) {
      console.warn(`Missing Required Clip ids for API`, clip);
      return throwError(() => new Error('Missing Required Clip ids for API'));
    }
    const endpoint = 'updateClip';

    const params = {
      projectId: clip.projectId,
      id: clip.id,
      userId: clip.userId,
    };
    // building updates to avoid the item having null values for any props that are sent in the update command
    updates.forEach((update) => {
      // it could be number == zero
      if (update.value || typeof update.value === 'number' || typeof update.value === 'boolean') {
        if (update.prop === 'hlsMeta') {
          update.value = JSON.stringify(update.value);
        }
        // dynamically create the update params for string query
        params[update.prop] = update.value;
      }
    });

    if (params['userId'] !== clip.userId) {
      console.warn(`${PAGE} DEV: What happens if userId is modified, since it's an index?`);
    }

    let definition = '',
      mapping = '';

    Object.keys(params).forEach((key: string) => {
      if (this.types[key]) {
        definition += `$${key}: ${this.types[key]} `;
        mapping += `${key}: $${key} `;
        // definition += `$${key}: ${types[key]}${i < Object.keys(params).length-1 ? ', ': ''}`;
        // mapping += `${key}: $${key}${i < Object.keys(params).length-1 ? ', ': ''}`;
      }
    });
    const statement = `mutation UpdateClip(${definition}) {
      updateClip(${mapping}) {
        ${ALL_CLIP_FIELDS}
      }
    }`;

    return from(API.graphql(graphqlOperation(statement, params)) as Promise<GraphQLResult>).pipe(
      map((res) => convertClipResponse(res.data[endpoint]))
    );
  }

  /**
   * batch get Clips by IDs
   * works, except if missing record at beginning or anywhere other than last -> it returns null for all
   * https://docs.aws.amazon.com/appsync/latest/devguide/tutorial-dynamodb-batch.html#error-handling
   * 
   * ids: [{
            projectId: "filmstacker"
            id: "masterstackr_-_Co-lab-first-day-tour__201907030352"
          },{
            projectId: "filmstacker"
            id: "masterstackr_-_Co-lab-first-day__201907030351"
          }]
   
      ids: [ClipIdsInput]
      input ClipIdsInput {
        projectId: ID!
        id: String!
      }
   */
  private batchGetClips(clipIds: { projectId: string; id: string }[]): Observable<Clip[]> {
    /**
     * we need to enfore max limit of 100 items in batchGet - it fails if not
     * https://filmstacker.atlassian.net/browse/MVP-766
     */
    const MAX_BATCH = 100;
    if (!Array.isArray(clipIds)) {
      return throwError(() => new Error(`ClipIDs not array?`));
    }
    // if (clipIds.length > 100) { // no need to check this, just chunk
    const chunks: { projectId: string; id: string }[][] = _chunk(clipIds, MAX_BATCH);
    // => [['a', 'b', 'c'], ['d']]

    DEBUG_LOGS && console.log(`${PAGE} batchGetClips`, { clipIdsLength: clipIds.length, chunks });

    // chunks.forEach
    return from(chunks).pipe(
      mergeMap((chunk: { projectId: string; id: string }[]) =>
        // `from` emits each chunk separately
        this.batchGetClipsChunked(chunk)
      ),
      // collect all into an array
      toArray(),
      // combine the array of arrays
      map((arrays) => [].concat(...arrays))
      // tap((res) => console.log(`map final:`, res)),
    );
  }

  private batchGetClipsChunked(clipIds: { projectId: string; id: string }[]): Observable<Clip[]> {
    const uniqueClipId = (array) =>
      _uniqWith(array, (a: Clip, b: Clip) => a.id === b.id && a.projectId === b.projectId);

    const params = {
      ids: uniqueClipId(clipIds),
    };
    const endpoint = 'batchGetClips';

    const query = `
      query batchGetClips (
        $ids: [ClipIdsInput]
      ) {
        batchGetClips(
          ids: $ids
        ) {
          items {
            ${ALL_CLIP_FIELDS}
          }
          unprocessedKeys
        }
      }
    `;
    // DEBUG_LOGS && console.log(`${PAGE} batchGetClips params:`, params);

    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} batchGetClips res:`, res);
        if (res.data[endpoint].unprocessedKeys) {
          this.sentryService.captureMessage(
            `batchGetClips unprocessedKeys found num=${clipIds.length} ids: ${JSON.stringify(clipIds)}`
          );
        }
        return (res.data[endpoint].items || []).map(convertClipResponse);
      }),
      catchError((e) => {
        if (e && Array.isArray(e.errors) && e.errors.length > 0) {
          const { errorType = '', message = '' } = e.errors[0];

          // potential api error fixed with uniqueClipId(clipIds)
          // if (message.indexOf('keys contains duplicates') > 0) {
          //   console.log(`${PAGE} batchGetClips error = ` + 'keys contains duplicates');
          // }
          console.warn(`${PAGE} batchGetClips caught:`, {
            errorType,
            message,
            error: e.errors[0],
            e,
          });
          this.sentryService.captureError(e.errors[0]);
        } else {
          console.warn(`${PAGE} batchGetClips caught:`, e);
          this.sentryService.captureError(e);
        }
        return throwError(() => e);
      })
    );
  }

  /**
   * Notify Admins by Email
   * @todo refactor from deprecated userService
   */
  private notifyErrorEmail(subject: string = 'Error Notification from app.clipApiService', msg: string | object) {
    // todo: refactor from deprecated userService below..
    // combineLatest([
    //   this.userService.userId$,
    //   this.userService.email$
    // ]).pipe(take(1)).subscribe((res) => {
    //   console.log(`Here?`, res)
    // });
    const email = this.userService.getUser().email || 'Anonymous';
    const name = this.userService.getUsername() || 'Anonymous';

    const { messageHtml, messageText } = this.mailerApi.buildMessage(msg);

    const params: SendMail = {
      fromEmail: email,
      name,
      subject,
      topic: MailTopic.Error,
      message: messageHtml,
      messageText,
    };

    if (environment.production) {
      this.mailerApi
        .sendEmail(params)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            DEBUG_LOGS && console.log(`${PAGE} sendEmail res:`, res);
          },
          error: (error) => {
            console.error(`${PAGE} sendEmail ERROR:`, error);
          },
        });
    } else {
      console.warn(`${PAGE} flag`, params);
    }
  }
}
