/** @format */

import keyBy from 'lodash/keyBy';
import { Project } from '@projects/shared/project.model';

export enum PROJECT_MEMBER_ROLE {
  OWNER = 'OWNER',
  EXECUTIVE = 'EXECUTIVE',
  PRODUCER = 'PRODUCER',
  CREW = 'CREW',
}

export class ProjectMember {
  userId: string;
  username: string;
  role: PROJECT_MEMBER_ROLE;
  isActive: boolean;
  createdAt?: string;
  avatar?: string;
  projectId?: string;
  projects?: Project[];

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

/**
 * Return the Top-Level Permission for the Crew, comparing a crew instance to another crew position
 * @returns PROJECT_MEMBER_ROLE
 */
export const getTopPermission = (
  projectMember: ProjectMember,
  permission: PROJECT_MEMBER_ROLE
): PROJECT_MEMBER_ROLE => {
  if (!projectMember || !projectMember.role) {
    return null;
  }
  if (projectMember.role === PROJECT_MEMBER_ROLE.OWNER || permission === PROJECT_MEMBER_ROLE.OWNER) {
    return PROJECT_MEMBER_ROLE.OWNER;
  }
  if (
    projectMember.role === PROJECT_MEMBER_ROLE.CREW &&
    (permission === PROJECT_MEMBER_ROLE.PRODUCER || PROJECT_MEMBER_ROLE.EXECUTIVE)
  ) {
    return permission;
  }
  if (projectMember.role === PROJECT_MEMBER_ROLE.PRODUCER && permission === PROJECT_MEMBER_ROLE.EXECUTIVE) {
    return permission;
  }
  if (
    permission === PROJECT_MEMBER_ROLE.CREW &&
    (projectMember.role === PROJECT_MEMBER_ROLE.PRODUCER || projectMember.role === PROJECT_MEMBER_ROLE.EXECUTIVE)
  ) {
    return projectMember.role;
  }
  return projectMember.role;
};

/**
 * Sort by Permission, then Alpha
 * @returns ProjectMember[]
 */
export const sortByName = (projectMembers: ProjectMember[], includeInactive: boolean = false): ProjectMember[] => {
  projectMembers = Array.isArray(projectMembers) ? projectMembers : [];
  if (!includeInactive) {
    projectMembers = projectMembers.filter((m) => m.isActive);
  }
  // sort alpha
  return projectMembers.sort((a, b) => {
    const crewA = (a.username || a.userId).toLowerCase();
    const crewB = (b.username || b.userId).toLowerCase();
    return crewA < crewB ? -1 : crewA > crewB ? 1 : 0;
  });
};

/**
 * Sort by Permission, then Alpha
 * @returns ProjectMember[]
 */
export const sortByPermission = (
  projectMembers: ProjectMember[],
  includeInactive: boolean = false
): ProjectMember[] => {
  // sort alpha
  const sortedProjectMembers = sortByName(projectMembers, includeInactive);

  const getValOfPerm = (user) => {
    const ret = (val) => {
      const subIfInactive = 10; // value of permission if !active
      if (!includeInactive) return val;
      return typeof user.isActive === 'boolean' && !user.isActive ? val - subIfInactive : val;
    };
    switch (user.role) {
      case PROJECT_MEMBER_ROLE.OWNER:
        return ret(4);
      case PROJECT_MEMBER_ROLE.EXECUTIVE:
        return ret(3);
      case PROJECT_MEMBER_ROLE.PRODUCER:
        return ret(2);
      case PROJECT_MEMBER_ROLE.CREW:
        return ret(1);
      default:
        return ret(0);
    }
  };

  // return sorted by permission
  return sortedProjectMembers.sort((a, b) => {
    const PMA = getValOfPerm(a);
    const PMB = getValOfPerm(b);

    return PMA > PMB ? -1 : PMA < PMB ? 1 : 0;
  });
};

export const isAllowedFor = (allowedRoles: PROJECT_MEMBER_ROLE[] = [], project: Project, userId: string): boolean => {
  let isAllowed = false;
  if (userId && project) {
    if (project.owner === userId) {
      // allowed for all
      return true;
    }
    if (Array.isArray(project.members)) {
      // if the user exists multiple times, we need to check for max permission if duplicates
      // while also filtering for isActive (project.members.filter((member) => member.isActive))
      const userRoles = project.members.reduce((prev, curr) => {
        if (curr?.userId === userId && curr.isActive) {
          const exists = prev.find((p) => p.userId === curr.userId);
          if (exists?.userId && getTopPermission(curr, exists.role) === exists.role) {
            // already exists with higher role => skip
            return prev;
          }
          return [...prev, curr];
        }
        return prev;
      }, []);

      const projectMembersMap = keyBy(userRoles, 'userId');
      isAllowed = allowedRoles.some((r) => projectMembersMap[userId] && projectMembersMap[userId].role === r);
    }
  }

  return isAllowed;
};
