/** @format */

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fs-form-item',
  templateUrl: './form-item.component.html',
  styleUrls: ['./form-item.component.scss'],
})
export class FormItemComponent {
  @Input() label = '';
  @Input() labelPlacement = 'stacked';
  @Input() value = '';
  @Input() placeholder = '';
  @Input() errorMessage = '';
  @Input() type = 'text';
  @Output() changed = new EventEmitter<string>();
}
