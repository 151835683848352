/** @format */

/* eslint-disable @typescript-eslint/naming-convention */
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { User } from '@shared/models/user.model';
import { Stack } from '@shared/models/stack.model';
import { Project } from '@projects/shared/project.model';
import { ProjectMember, getTopPermission } from '@members/shared/project-member.model';
import * as MemberActions from '../actions/members.actions';
import * as ProjectActions from '../actions/projects.actions';
import * as StackActions from '../actions/stacks.actions';
import * as UserActions from '../actions/user.actions';
import _partition from 'lodash/partition';
import _mergeWith from 'lodash/mergeWith';
import _uniq from 'lodash/uniq';
import { environment } from 'src/environments/environment';

export const featureKey = 'members';

const DEBUG_LOGS = false;
const DEBUG_LOGS_DEEP = false;

// const sortAlpha = (a, b) => (a && b && a.toLowerCase() > b.toLowerCase() ? 1 : -1);

/** https://lodash.com/docs/4.17.15#mergeWith */
const mergeCustomizer = (objValue, srcValue) => {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    // put the new one first, so that is the one that is kept if they match
    return _uniq(srcValue.concat(objValue));
  }
};

/**
 * Community Members Store
 * 
 * ref: great post about Searching in ngrx
 * https://stackoverflow.com/questions/46199432/implementing-search-with-ngrx-store-angular-2
 * 
 * 
 * Usage
 * Project Page 
 * ProjectMembers will already be in store due to onProjectActions.projectLoadSuccess
 * select MembersByProject
 
 * Studio Page 
 * we already have all MyProjects due to onProjectActions.projectLoadSuccess
 * select MembersForMyProjects (combine with state.projects.mine)
 
 * ProjectMemberDetail (modal contents)
 * load UserData from DB (+API)
 * 
 * MemberProjects component
 * get projectPreviews from store or api, add members onProjectActions.projectLoadSuccess (+API)
 
 * HomePage
 * Carousel of "Community" Users (click)=Discover filtered (+API)
 
 * DiscoverPage
 * ProjectMembers & Stack Creators in filter carousel (click)=filter Discover (+API)
 
 * Selectors
 * getMembersByProjectId(projectId)
 * getMemberNumProjects(userId)
 * getMembersForMyProjects (combine with state.projects.mine)
 * 
 * Sorts
 * usersWithMostStacks // sort for Discover
 * 
 * getUsersPopular // how is this determined? what is the sort for the home page? 
 
 * Actions
 * onProjectActions.projectLoadSuccess => AddMembersFromProject -> map to entity
 * 
 * LoadMemberDetails (+API)
 * AddMemberToProject (+API)
 * InviteEmailToProject (+API)
 * SearchMembers (+API)
 * ApplyAsMember (+API)
 * 
 * onStackActions.loadSuccess => AddMemberFromStack(s)
 * onWatchLaterActions.add => AddMemberFromStack
 
 * Effects
 * LoadMemberDetails(userId)
 * load UserData from API
 * load projectPreviews from store or api, add members onProjectActions.projectLoadSuccess 
 * 
 */

/**
 * @ref User Model   
 
    entities: USER
      userId: string;
      identityId?: string;
      name?: string; // username
      avatar?: string;
      projects?: string[];
      memberProjects?: ProjectMember[];
          role: PROJECT_MEMBER_ROLE;
          isActive: boolean;
          createdAt?: string;
          avatar?: string;
          projectId?: string;
          projects?: Project[];
      bio?: string;
      location?: string;
      //  stats
      numClipsWatched?: number;
      numClipsAddedToStack?: number;
      numStacksPublished?: number;
      numStacksWatched?: number;
      numShares?: number;
      numVotes?: number;
      votes?: Array<string>; // ids of the items voted on
 
      stacks: string[] (projectId/stackId)
 */

/**
 * Actions just for effects
 *
 * MemberActions.loadMember
 * MemberActions.loadMembers
 *
 */

const PAGE = '[MemberStore]';

export interface State extends EntityState<User> {
  // additional entity state properties
  selectedId: string | null;
  nextTokens: { [group: string]: string | null };
  loadedProjects: string[];
  // loading: string[];
  query: string;
  queryResults: User[];
}

export function selectId(a: User): string {
  // primary key
  return a.userId;
}

export function sortByName(a: User, b: User): number {
  return (a.name || a.userId).localeCompare(b.name || b.userId);
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId,
  sortComparer: sortByName,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  nextTokens: {},
  loadedProjects: [],
  // loading: [],
  query: null,
  queryResults: [],
});

const getUserName = (user, state) => {
  const currentUser = state.entities[user.userId];
  return currentUser && currentUser.name !== currentUser.userId ? currentUser.name : user.name || user.userId;
};

const userReducer = createReducer(
  initialState,

  on(MemberActions.subUpdate, (state, { memberProject }) => {
    console.log({ memberProject, state });
    const userId = memberProject.userId;
    if (memberProject && userId && state.entities[userId] && state.entities[userId].userId) {
      // this is an item we are tracking
      const entity = Object.assign({}, state.entities[userId]);
      const memberProjects = Array.isArray(entity.memberProjects)
        ? entity.memberProjects.filter((p) => p && p.projectId !== memberProject.projectId)
        : [];
      // const item = entity.memberProjects.find(el => el && el.projectId === memberProject.projectId && el.userId === userId)
      console.log(`[MembersStore] subUpdate:`, { memberProject, state });
      return adapter.upsertOne(
        {
          ...entity,
          memberProjects: [...memberProjects, memberProject],
        },
        state
      );
    } else if (memberProject && userId) {
      // we already checked if (item.userId === userId || (item.projectId && myProjectIds.includes(item.projectId)))
      // so we do indeed want this, but it does not currently exist
      !environment.production && console.log(`[MembersStore] subUpdate new:`, { memberProject });
      return adapter.addOne(memberProject, state);
    } else {
      // ignoring clips we don't care about..
      console.log(`subUpdate ignoring:`, memberProject);
      return state;
    }
  }),
  on(UserActions.subUpdate, (state, { user }) => {
    const userId = user.userId;
    if (userId && state.entities[userId] && state.entities[userId].userId) {
      // this is an item we are tracking
      const entity = Object.assign({}, state.entities[userId]);
      // const item = entity.memberProjects.find(el => el && el.projectId === memberProject.projectId && el.userId === userId)
      DEBUG_LOGS && console.log(`[MembersStore] user.subUpdate:`, { user, entity });
      return adapter.upsertOne(
        {
          ...entity,
          ...user,
        },
        state
      );
    } else if (userId) {
      // this is not a member on any project, but could it be someone new on a project?
      // could also be ME!
      !environment.production && console.log(`[MembersStore] user.subUpdate ignoring new community member?`, { user });
      return state; // adapter.addOne(user, state);
    } else {
      // ignoring no userId..
      return state;
    }
  }),

  /**
   * Get Project Members from Project Actions
   */
  on(ProjectActions.loadByIdSuccess, (state, { project }) => {
    if (!project || !project.id) return state;
    if (state.loadedProjects.includes(project.id)) {
      return state;
    }
    const members = getEntitiesFromProjects([project], state.entities);
    DEBUG_LOGS && console.log(`${PAGE} ProjectActions.loadByIdSuccess [${project.id}]`, { members, project });
    return adapter.upsertMany(members, { ...state, loadedProjects: [...state.loadedProjects, project.id] });
  }),

  on(ProjectActions.loadSuccess, (state, { projects }) => {
    const newProjects = (Array.isArray(projects) ? projects : []).filter((p) => !state.loadedProjects.includes(p.id));
    if (newProjects.length < 1) return state;
    const newProjectIds = newProjects.map((p) => p.id);
    const members = getEntitiesFromProjects(newProjects, state.entities);

    DEBUG_LOGS && console.log(`${PAGE} ProjectActions.loadSuccess`, { members, newProjectIds, projects });
    return adapter.upsertMany(members, { ...state, loadedProjects: [...state.loadedProjects, ...newProjectIds] });
  }),

  on(ProjectActions.addMember, (state, { member }) => {
    DEBUG_LOGS && console.log(`${PAGE} ProjectActions.addMember`, { member });
    if (!member || !member.userId || !member.role || !member.projectId) {
      return state;
    }
    const userId = member.userId;
    const current = state.entities[userId]
      ? { ...state.entities[userId] }
      : { userId, name: member.username, memberProjects: [] };
    const existsIndex =
      current && Array.isArray(current.memberProjects)
        ? current.memberProjects.findIndex((m) => m.projectId === member.projectId)
        : -1;

    if (existsIndex > -1) {
      // validate that we are not downgrading this user!
      // if this role !== topPermission & existing is currently Active, skip update
      if (
        member.role !== getTopPermission(current.memberProjects[existsIndex], member.role) &&
        current.memberProjects[existsIndex].isActive
      ) {
        return state;
      }
      current.memberProjects[existsIndex] = member;
    } else {
      current.memberProjects = [...current.memberProjects, member];
    }

    return Object.assign({}, state, {
      entities: Object.assign({}, state.entities, {
        [userId]: current,
      }),
    });
  }),

  on(ProjectActions.updateMember, (state, { member }) => {
    DEBUG_LOGS && console.log(`${PAGE} ProjectActions.updateMember`, { member });
    if (!member || !member.userId || !member.role || !member.projectId) {
      return state;
    }
    const userId = member.userId;
    const current = state.entities[userId]
      ? { ...state.entities[userId] }
      : { userId, name: member.username, memberProjects: [] };
    const existsIndex =
      current && Array.isArray(current.memberProjects)
        ? current.memberProjects.findIndex((m) => m.projectId === member.projectId)
        : -1;

    if (existsIndex > -1) {
      current.memberProjects[existsIndex] = member;
    } else {
      current.memberProjects = [...current.memberProjects, member];
    }

    return Object.assign({}, state, {
      entities: Object.assign({}, state.entities, {
        [userId]: current,
      }),
    });
  }),

  /**
   * Get Stack Creators from Stack Actions
   */
  on(StackActions.loadSuccess, (state, { stacks }) => {
    const members = getEntitesFromStacks(stacks);
    DEBUG_LOGS && console.log(`${PAGE} StackActions.loadSuccess`, { members, stacks });
    return adapter.upsertMany(members, state);
  }),

  on(MemberActions.addToProject, (state, { projectId, user }) => {
    DEBUG_LOGS && console.log(`DEV: ${PAGE} addToProject`, { projectId, user });

    if (!user || !user.userId || !projectId) {
      return state;
    }
    if (!Array.isArray(user.memberProjects)) {
      console.warn(`${PAGE} no memberProjects to add?`, user);
    }
    const current = state.entities[user.userId] ? { ...state.entities[user.userId] } : user;
    const existsIndex =
      current && Array.isArray(current.memberProjects)
        ? current.memberProjects.findIndex((m) => m.projectId === projectId)
        : -1;

    if (existsIndex > -1) {
      // should this be [0] ?
      current.memberProjects[existsIndex] = user.memberProjects[0];
    } else {
      current.memberProjects = [...current.memberProjects, ...user.memberProjects];
    }
    !environment.production &&
      console.log(
        `DEV: (user.memberProjects.length = ${user.memberProjects?.length}) current should be userId key (${user.userId})?`,
        {
          projectId,
          current,
          existsIndex,
          current_memberProjects: current.memberProjects,
          user_memberProjects: user.memberProjects,
        }
      );
    return Object.assign({}, state, {
      entities: Object.assign({}, state.entities, {
        [user.userId]: current,
        // [projectId]: current,
      }),
    });
  }),

  on(MemberActions.inviteEmailToProject, (state, { projectId, email }) => {
    DEBUG_LOGS && console.log(`${PAGE} inviteEmailToProject`, { projectId, email });
    return state;
  }),
  on(MemberActions.applyAsMember, (state, { projectId, userId }) => {
    DEBUG_LOGS && console.log(`${PAGE} applyAsMember`, { projectId, userId });
    return state;
  }),

  on(MemberActions.updateMemberAvatar, (state, { userId, avatar }) => {
    // DEBUG_LOGS &&
    console.log(`${PAGE} updateMemberAvatar`, { userId, avatar });
    return adapter.upsertOne({ userId, avatar }, state);
  }),

  on(MemberActions.loadSuccess, (state, { user }) => {
    if (!user || !user.userId) {
      DEBUG_LOGS && console.warn(`${PAGE} loadSuccess user not found`);
      return state;
    }
    const currentUser = state.entities[user.userId] || {};
    DEBUG_LOGS && console.log(`${PAGE} loadSuccess`, { user, currentUser });
    try {
      DEBUG_LOGS &&
        console.log(`${PAGE} loadSuccess`, {
          user: {
            projects: (user.projects || []).map((p) => p.id),
            memberProjects: (user.memberProjects || []).map((p) => p.projectId),
          },
          currentUser: {
            projects: ((currentUser as User).projects || []).map((p) => p.id),
            memberProjects: ((currentUser as User).memberProjects || []).map((p) => p.projectId),
          },
        });
    } catch (error) {
      console.log(`Caught Error in Debug logs:`, error);
    }
    return adapter.upsertOne(
      { ..._mergeWith({}, currentUser, user, mergeCustomizer), name: getUserName(user, state), loaded: true },
      state
    );
    // return adapter.upsertOne({ ...user, loaded: true }, { ...state, loading: newFilteredArray(loading, user.userId) });
  }),
  on(MemberActions.loadMembersSuccess, (state, { users }) => {
    DEBUG_LOGS && console.log(`${PAGE} loadMembersSuccess`, { users });
    const members = users.map((u) => ({
      ..._mergeWith({}, state.entities[u.userId] || {}, u, mergeCustomizer),
      name: getUserName(u, state),
      loaded: true,
    }));
    return adapter.upsertMany(members, state);
  }),

  on(MemberActions.loadFail, (state, { error, users }) => {
    if (error?.message === 'NO_USER') {
      // user was not found, just show them as loaded
      console.log('User not found in DB:', (users || []).map((u) => u?.userId).join(', '));
      const members = users.map((u) => ({
        ...(state.entities[u.userId] ? state.entities[u.userId] : { userId: u.userId, name: u.userId }),
        loaded: true,
        pending: true, // see User.model.pending
      }));
      return adapter.upsertMany(members, state);
    }
    console.warn(`${PAGE} load Fail`, { error, users });
    return state;
  }),

  on(MemberActions.queryMembers, (state, { query }) => {
    DEBUG_LOGS && console.log(`${PAGE} queryMembers`, { query });
    // reset queryResults
    return { ...state, queryResults: [], query };
  }),
  on(MemberActions.queryMembersSuccess, (state, { users }) => {
    DEBUG_LOGS && console.log(`${PAGE} queryMembersSuccess`, { users });
    // reset queryResults
    return { ...state, queryResults: users };
  }),
  on(MemberActions.addMembersToQueryResults, (state, { users }) => {
    DEBUG_LOGS && console.log(`${PAGE} addMembersToQueryResults`, { users });
    return { ...state, queryResults: [...state.queryResults, ...users] };
  }),

  on(MemberActions.selectMember, (state, { userId }) => ({ ...state, selectedId: userId })),
  // entityStore Actions
  on(MemberActions.addMember, (state, { user }) => adapter.upsertOne(user, state)),
  on(MemberActions.upsertMember, (state, { user }) => adapter.upsertOne(user, state)),
  on(MemberActions.addMembers, (state, { users }) => adapter.addMany(users, state)),
  on(MemberActions.upsertMembers, (state, { users }) => adapter.upsertMany(users, state)),
  on(MemberActions.updateMember, (state, { update }) => adapter.updateOne(update, state)),
  on(MemberActions.updateMembers, (state, { updates }) => adapter.updateMany(updates, state)),
  on(MemberActions.mapMembers, (state, { entityMap }) => adapter.map(entityMap, state)),
  on(MemberActions.deleteMember, (state, { id }) => adapter.removeOne(id, state)),
  on(MemberActions.deleteMembers, (state, { ids }) => adapter.removeMany(ids, state)),
  on(MemberActions.deleteMembersByPredicate, (state, { predicate }) => adapter.removeMany(predicate, state)),
  on(MemberActions.clearMembers, (state) => adapter.removeAll({ ...state, selectedUserId: null }))
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

// export the selectors
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
export const getQuery = (state: State) => state.query;
export const getQueryResults = (state: State) => state.queryResults;

// moved to members.selectors.ts:

// // select the array of user ids
// export const selectMemberIds = selectIds;
// // select the dictionary of user entities
// export const selectMemberEntities = selectEntities;
// // select the array of users
// export const selectAllMembers = selectAll;
// // select the total user count
// export const selectMemberTotal = selectTotal;
// export const getQuery = (state: State) => state.query;
// export const getQueryResults = (state: State) => state.queryResults;

/**
 * Utility functions
 */

export function getId(projectId, id): string {
  return `${projectId}/${id}`;
}
export function splitId(id): { projectId: string; id: string } {
  return {
    projectId: id ? id.split('/')[0] : '',
    id: id ? id.split('/')[1] : '',
  };
}

/**
 * Utility mapping function for Stacks
 */
export function getEntitesFromStacks(stacks: Stack[]): User[] {
  try {
    const createEntity = (stack, currentUser?: User): User => {
      const stackId = getId(stack.projectId, stack.stackId);
      const currentStacks = currentUser && Array.isArray(currentUser.stacks) ? currentUser.stacks : [];
      const stackToAdd = !currentStacks.includes(stackId) ? [stackId] : [];
      return {
        userId: stack.userId,
        name: currentUser && currentUser.name !== currentUser.userId ? currentUser.name : stack.credits,
        stacks: [...currentStacks, ...stackToAdd],
        ...(stack.userIdentityId ? { identityId: stack.userIdentityId } : {}), // probably does not exist here
      };
    };

    if (!Array.isArray(stacks)) return [];

    const userMap: Map<string, User> = new Map();
    stacks.forEach((stack) => {
      if (!stack || !stack.projectId || !stack.stackId) {
        console.warn(`${PAGE} getEntitesFromStacks failed !stack || !stack.projectId || !stack.stackId`, stack);
        return;
      }
      const propertyValue = stack.userId;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      userMap.has(propertyValue)
        ? userMap.set(propertyValue, {
            ...userMap.get(propertyValue),
            ...createEntity(stack, userMap.get(propertyValue)),
          })
        : userMap.set(propertyValue, {
            ...createEntity(stack),
          });
    });
    return Array.from(userMap.values());
  } catch (error) {
    console.error(`${PAGE} getEntitesFromStacks caught`, error);
    return [];
  }
}

/**
 * Utility mapping function for Projects
 */
export function getEntitiesFromProjects(projects: Project[], currentMemberEntities): User[] {
  try {
    const getTopMemberProjects = (
      newMemberProject: ProjectMember,
      currentMemberProjects: ProjectMember[]
    ): ProjectMember[] => {
      const memberProjects = []; // return value
      if (currentMemberProjects.length > 0) {
        // if current project is already in currentMemberProjects, check permission level and add top
        const matchIndex = currentMemberProjects.findIndex(
          (m) =>
            m &&
            m.projectId &&
            newMemberProject &&
            newMemberProject.projectId &&
            m.projectId === newMemberProject.projectId
        );
        if (matchIndex >= 0) {
          // we already have this project listed, let's see which to keep
          // compare iActive and getTopPermission
          const match = currentMemberProjects[matchIndex];
          if (match.isActive === newMemberProject.isActive) {
            // they are either both active or not active, compare role
            const topRole = getTopPermission(newMemberProject, match.role);
            DEBUG_LOGS_DEEP &&
              console.log(
                `***${PAGE} getEntitiesFromProject createEntity match useNew? '${newMemberProject.role === topRole}'`,
                { topRole, match, matchIndex }
              );
            if (newMemberProject.role === topRole) {
              currentMemberProjects[matchIndex] = newMemberProject;
            }
          } else if (!match.isActive) {
            // assuming newIsActive since they are not the same, use new
            currentMemberProjects[matchIndex] = newMemberProject;
            DEBUG_LOGS_DEEP &&
              console.log(`***${PAGE} getEntitiesFromProject createEntity match not active use new`, {
                match,
                matchIndex,
                new: newMemberProject,
              });
          }
          // else current match isActive but our new is not, use current
          memberProjects.push(...currentMemberProjects);
        } else {
          // no match
          memberProjects.push(...currentMemberProjects, newMemberProject);
        }
      } else {
        // no current
        memberProjects.push(newMemberProject);
      }
      return memberProjects;
    };

    const createEntity = (newUser: User, currentUser?: User): User => {
      // DEBUG_LOGS && console.log(`${PAGE} getEntitiesFromProject createEntity`, { newUser, currentUser });
      // newUser will ALWAYS only have 1 memberProject
      const newMemberProject = newUser.memberProjects[0];

      // // take into account the current entity for deep merge - done with currentMemberEntities in Map constructor
      // const storeMemberProjects: ProjectMember[] = (currentMemberEntities[newUser.userId] && Array.isArray(currentMemberEntities[newUser.userId].memberProjects) ? currentMemberEntities[newUser.userId].memberProjects : []);
      // let [ inMap, notInMap ] = _partition(storeMemberProjects, (p) => currentUserMapMemberProjects.findIndex(s => s.projectId === p.projectId) >= 0);
      // const inMapTop = inMap.map(c => getTopMemberProjects(c, storeMemberProjects)).flat();
      // const currentMemberProjects = [ ...notInMap ];

      const currentMemberProjects =
        currentUser && Array.isArray(currentUser.memberProjects) ? currentUser.memberProjects : [];
      const memberProjects = getTopMemberProjects(newMemberProject, currentMemberProjects);

      DEBUG_LOGS_DEEP &&
        console.log(`***${newUser.userId}***${PAGE} getEntitiesFromProject createEntity`, {
          newMemberProject,
          currentMemberProjects,
          memberProjects,
          currentUser,
        });
      return {
        userId: newUser.userId,
        name: currentUser && currentUser.name !== currentUser.userId ? currentUser.name : newUser.name,
        memberProjects,
        ...(newUser.avatar ? { avatar: newUser.avatar } : {}), // probably does not exist here
      };
      // const currentProjects = (currentUser && Array.isArray(currentUser.projects)) ? currentUser.projects : [];
      // const projectsToAdd = (!currentProjects.includes(project.id)) ? [ project.id ] : [];
    };

    const userMap: Map<string, User> = new Map(Object.entries(currentMemberEntities));

    if (!Array.isArray(projects)) return [];

    projects.forEach((project) => {
      if (!project || !project.id) {
        console.warn(`${PAGE} getEntitiesFromProjects failed !project || !project.id`, project);
        return;
      }
      const users: User[] = (project.members || []).map((m) => ({
        userId: m.userId,
        name: m.username,
        memberProjects: [{ ...m, projectId: project.id }],
        ...(m.avatar ? { avatar: m.avatar } : {}), // probably does not exist here
        // projects: [ project.id ],
      }));

      if (!Array.isArray(users)) return;

      users.forEach((user) => {
        if (!user || !user.userId) {
          console.warn(`${PAGE} getEntitiesFromProjects failed !user || !user.userId`, user);
          return;
        }
        const propertyValue = user.userId;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        userMap.has(propertyValue)
          ? userMap.set(propertyValue, {
              ...userMap.get(propertyValue),
              ...createEntity(user, userMap.get(propertyValue)),
            })
          : userMap.set(propertyValue, {
              ...createEntity(user),
            });
      });
      // DEBUG_LOGS && console.log(`${PAGE} getEntitiesFromProject projects.forEach`, { users, project, userMap });
    });

    DEBUG_LOGS_DEEP && console.log(`${PAGE} getEntitiesFromProject userMap`, { userMap });

    // we need to update the username for OWNER records, or any that are not correct
    for (const [key, value] of userMap) {
      const [nameWrong, nameCorrect] = _partition(
        Array.isArray(value.memberProjects) ? value.memberProjects : [],
        (u) => u.username !== value.name
      );
      if (nameWrong.length > 0) {
        DEBUG_LOGS_DEEP && console.log(`MAPDEV ${value.userId} ${value.name} update wrong names`, nameWrong);
        userMap.set(key, {
          ...value,
          memberProjects: [
            ...nameCorrect,
            ...nameWrong.map((m) => ({
              ...m,
              username: value.name,
            })),
          ],
        });
      }
    }
    return Array.from(userMap.values());
  } catch (error) {
    console.error(`${PAGE} getEntitiesFromProjects caught`, error);
    return [];
  }
}
