/** @format */

import { Component } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  ParamMap,
  ActivationEnd,
  NavigationStart,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Platform, NavController, ToastController } from '@ionic/angular';
import { interval, of } from 'rxjs';
import { take, filter, map, startWith, switchMap } from 'rxjs/operators';
/* removed cordova, configure in capacitor */
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { ConfigService } from '@app/core/config';
import { SentryService } from './core/services/analytics/sentry.service';
import { EventsService, EventActions, Orientation, ResizeObject } from './core/services/events.service';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { CoreLogicApiService } from './core/api/core-logic-api.service';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import {
  selectAcceptedGDPR,
  // selectTourSeenOverview,
  selectTourWidgetSplash,
} from '@store/selectors/viewstate.selectors';
import { gdprAccepted } from '@store/actions/viewstate.actions';
import { searchProjects as environSetProjectSilos } from '@store/actions/environ.actions';
import { load as loadMyStack } from '@store/actions/mystack.actions';
import { interactWithDom } from '@store/actions/user.actions';
import { TranslateService } from '@ngx-translate/core';
import { TokensService } from '@tokens/shared/services/tokens.service';
import { TOKEN_QUERY_PARAM, TOKEN_QUERY_PARAM_OLD } from '@tokens/shared/tokens.model';
import { DISCOVER_PAGE, PROJECT_DETAIL_ROUTE, WATCH_ROUTE } from './app-routing.module';
import { VideoPlayerService } from './modules/video-player/shared/services/video-player.service';
/** added to support swiper.js per https://ionicframework.com/docs/angular/slides */
import { register } from 'swiper/element/bundle';
import { ENABLE_BILLING } from './app.config';
import { BillingService } from '@billing/shared';

/**
 * let status bar overlay webview
 * https://capacitorjs.com/docs/apis/status-bar#setoverlayswebview
 * @note ios11 strangeness, appears to solve with false
 */
const NATIVE_STATUSBAR_OVERLAYS_WEBVIEW = false;
/**
 * set status bar to hex ,but only with overlaysWebView=false
 * https://capacitorjs.com/docs/apis/status-bar#backgroundcoloroptions
 */
const NATIVE_STATUSBAR_COLOR_HEX = '#66ad9e';

const DEBUG_NAV_QUERY = false;
const DEBUG_APP_CONFIG = false;

const fadeOutLoadingEl = async () => {
  // console.log("[App] fadeOutLoadingEl...");
  const LOADING_DELAY = 420; //1200;//ms
  const REMOVE_EL_DELAY = 1000; //2000;//3100;//ms // - have it align with the transistion css in index.html
  setTimeout(() => {
    const loadingEl = document.getElementById('splash-app-loading');
    if (loadingEl && loadingEl.style) {
      loadingEl.style.opacity = '0';
      setTimeout(() => {
        // console.log("[App] fadeOutLoadingEl - remove from dom...");
        document.body.removeChild(loadingEl);
      }, REMOVE_EL_DELAY);
    } else {
      // console.warn(`Unable to find #splash-app-loading - uh oh!`);
    }
  }, LOADING_DELAY);
};

// added to support swiper.js per https://ionicframework.com/docs/angular/slides
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  // delayed check after store hydrated
  hydrating$ = interval(4400).pipe(
    take(1),
    switchMap(() => of(false)),
    startWith(true)
  );

  gdprAccepted$ = this.store.select(selectAcceptedGDPR);

  orientation: Orientation = Orientation.Portrait;

  private _resizeTimer: NodeJS.Timeout;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private configService: ConfigService,
    private translateService: TranslateService,
    public sentryService: SentryService,
    public events: EventsService,
    public analyticsService: AnalyticsService,
    public videoPlayerService: VideoPlayerService,
    public coreApi: CoreLogicApiService,
    public tokensService: TokensService,
    public billingService: BillingService,
    public route: ActivatedRoute,
    public router: Router,
    public store: Store<State>,
    public toastCtrl: ToastController
  ) {
    this.initializeApp();

    /**
     * combined filter query parameter check and config query param check
     * @note if we handle setFilter in page components like Discovery directly we'd keep it more explicit
     */
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        take(1)
      )
      .subscribe((activatedRoute: ActivationEnd) => {
        DEBUG_NAV_QUERY && console.log(`[App] activatedRoute:`, activatedRoute);
        /**
         * check URL for queryParams for config, setup AppConfig
         */
        this.setupAppConfig(activatedRoute.snapshot);

        /**
         * @note if we handle setFilter in page components like Discovery directly we'd keep it more explicit
         * check if the initial route supports filter parameters and send them to the store
         */
        // if (activatedRoute.snapshot.data.usedFilterId) {
        //   const filterItem = {
        //     id: activatedRoute.snapshot.data.usedFilterId,
        //     q: activatedRoute.snapshot.queryParams.q,
        //   };
        //   this.store.dispatch(setFilter({ filter: filterItem }));
        // }
      });

    /**
     * Simulate queryParamsHandling: 'merge' for each routing, if match queryParamsToKeep.
     * ref: https://github.com/angular/angular/issues/12664#issuecomment-464947780
     */
    this.router.events
      .pipe(
        /* Important, must NOT be NavigationEnd or you won't prevent navigation and the 'back' button of your browser won't work */
        filter((e) => e instanceof NavigationStart),
        map((e) => e as NavigationStart)
      )
      .subscribe((event) => {
        // keep 'token' until all the invites are converted, then remove it MVP-1242
        const queryParamsToKeep = ['widget', 'dev', TOKEN_QUERY_PARAM, TOKEN_QUERY_PARAM_OLD];
        try {
          const nav = router.getCurrentNavigation();
          const currentQueryParams = nav?.extractedUrl?.queryParams ?? {};
          const prevQueryParams = nav?.previousNavigation?.finalUrl?.queryParams ?? null;
          const toAdd = {};

          // quick check if prevQueryParams has the keepers
          if (prevQueryParams) {
            for (const prop in prevQueryParams) {
              if (queryParamsToKeep.indexOf(prop) > -1) {
                if (currentQueryParams[prop]?.length === 0) {
                  // only if it's not blank - if the prop is '' then we should remove it
                  delete currentQueryParams[prop];
                  delete toAdd[prop];
                } else {
                  toAdd[prop] = prevQueryParams[prop];
                }
              }
            }
          }
          if (Object.keys(toAdd).length < 1) {
            DEBUG_NAV_QUERY &&
              console.log(`[App] NavStart no queryParams to add, done..`, {
                currentQueryParams,
                prevQueryParams,
                nav,
                event,
              });
            return;
          }

          // if prevQueryParams has the keepers but currentQueryParams does not, add, else done
          DEBUG_NAV_QUERY &&
            console.log(`[App] NavStart toAdd:`, { toAdd, currentQueryParams, prevQueryParams, nav, event });
          const newKeys = Object.keys(toAdd).filter((key) => !(key in currentQueryParams));
          if (newKeys.length < 1) {
            DEBUG_NAV_QUERY && console.log(`[App] NavStart current nav has the keepers already..`);
            return;
          }

          const newParams = newKeys.reduce((obj, key) => ({ ...obj, [key]: toAdd[key] }), {});

          // add them to the nav
          const queryParams = {
            ...currentQueryParams,
            ...newParams,
          };
          const newExtras = {
            ...nav.extras,
            queryParams,
          };
          DEBUG_NAV_QUERY &&
            console.log(`[App] NavStart:`, {
              newKeys,
              newParams,
              newExtras,
              extras: nav.extras,
              currentQueryParams,
              prevQueryParams,
              toAdd,
            });

          this.router.navigate([event.url], newExtras);
        } catch (error) {
          console.warn(`[App] Caught in NavStart`, error);
        }
      });
  }

  onGdprAccept() {
    this.store.dispatch(gdprAccepted());
  }

  /**
   * sent from html template
   * removed arg event: Event (unused)
   *
   * @todo window:orientationchange event is deprecated and needs updated to ScreenOrientation change
   * https://filmstacker.atlassian.net/browse/MVP-1377
   *
   */
  onOrientationChange() {
    // console.log("[App] onOrientationChange event:", event);

    //v2 try platform
    // console.log("[App] orientationchange platform:", this.platform);
    let orient = Orientation.Portrait;
    setTimeout(() => {
      // added in a timeout to wait for platform to update...
      // console.log("[App] onOrientationChange timeout platform:",this.platform);
      if (this.platform.isPortrait()) {
        orient = Orientation.Portrait;
      } else if (this.platform.isLandscape()) {
        orient = Orientation.Landscape;
      }

      console.log('[App] onOrientationChange', { orient });
      // let choice: string = (this.nav.getActive !== 'player' || 'editor' (=full-screen-video) -> 'Capture'
      this.events.publish(EventActions.ORIENTATION_CHANGE, { orientation: orient });
      this.orientation = orient;
    }, 300);

    // v1 not stable in chrome anymore..
    // let wind:Window = <Window>event.target || window;
    // let orientation = (wind && wind.orientation);
    // // var orientation = screen.orientation || screen.mozOrientation || screen.msOrientation;
    // // if (wind && wind.screen && wind.screen.orientation && typeof wind.screen.orientation.angle === 'number') {}
    // console.log("[App] orientationchange orientation: %o",orientation);

    // let orient:string = (orientation && typeof orientation.toString === 'function') ? orientation.toString() : "unknown";
    // if (orientation == 0 || orientation == 180) {
    //   orient = "portrait";
    // } else if (orientation == 90 || orientation == -90) {
    //   orient = "landscape";
    // }
  }

  /**
   * sent from html template
   * removed arg for event: Event, unused
   */
  onWindowResize() {
    clearTimeout(this._resizeTimer);
    this._resizeTimer = setTimeout(() => {
      // Run code here, resizing has "stopped"
      const resizeObj = this.getResizeObject();
      // console.log("[App] onWindowResize event: %o",resizeObj);
      this.events.publish(EventActions.RESIZE, resizeObj);
    }, 250);
  }

  private initializeApp() {
    this.platform.ready().then(async (readySource) => {
      // Platform is ready and our plugins are available, execute any required native code
      // Here you can do any higher level native things you might need.

      // readySource states which platform ready was used.
      // For example, when Cordova is ready, the resolved ready source is cordova.
      // The default ready source value will be dom.
      // console.log(`[App] platform.readySource:`, readySource);//'dom'
      // This will print an array of the current platforms
      console.log(
        `[App] Platform.readySource: '${readySource}', Device Platforms: '${this.platform.platforms().join(',')}'`
      );

      this.translateService.setDefaultLang('en');

      // const isNative = this.platform.is('cordova') || readySource === 'cordova';
      if (Capacitor.isNativePlatform()) {
        await this.initializeNative();
      } else {
        // dom, pwa
        this.sentryService.init({ platforms: this.platform.platforms() });
      }

      /**
       * @v1 wip
       * MVP-1040 Real Time Data
       */
      this.coreApi.watchAllData();

      this.onWindowResize();
      this.onOrientationChange();

      this.analyticsService.startTracker();

      // create listeners - for both web & native
      this.createPlatformListeners();

      fadeOutLoadingEl();

      setTimeout(() => {
        // this is required due to hydration of store from localstorage, to ensure getMyStack set reliably
        this.store.dispatch(loadMyStack());

        if (ENABLE_BILLING) {
          this.billingService.loadPlans();
        }
      }, 1400);
    });

    this.watchForInteraction();

    /*
     * @deprecated - use router.events.pipe(filter((event) => event instanceof ActivationEnd)...
     * check URL for queryParams for config, setup AppConfig
     */
    // this.route.queryParamMap
    //   .pipe(
    //     skip(1), // the first one blank since it's a BehaviorSubject
    //     take(1),
    //   )
    //   .subscribe(
    //     (queryParams: ParamMap) => this.setupAppConfig(queryParams),
    //     (err) => console.warn(`[App] subRoute Error: `, err),
    //   );
  }

  private async initializeNative() {
    /**
     * https://ionicframework.com/docs/native/status-bar/
     * let status bar overlay webview
     * https://ionicframework.com/docs/native/status-bar/#overlaysWebView
     */
    // Display content under transparent status bar (Android only)
    StatusBar.setOverlaysWebView({ overlay: NATIVE_STATUSBAR_OVERLAYS_WEBVIEW });
    /** set status bar to hex ,but only with overlaysWebView=false */
    StatusBar.setBackgroundColor({ color: NATIVE_STATUSBAR_COLOR_HEX });
  
    // TODO: native Sentry
    // native Google Analytics?

    /** 
     * https://capacitorjs.com/docs/apis/splash-screen 
     */ 
    // // Show the splash for an indefinite amount of time:
    // await SplashScreen.show({
    //   autoHide: false,
    // });
    // Show the SplashScreen for showDuration and then automatically hide it:
    await SplashScreen.show({
      // the showDuration delay should be equal to FadeSplashScreenDuration param value in config.xml
      showDuration: 1000, //300,
      autoHide: true,
    });
    // https://forum.ionicframework.com/t/android-splashscreen-fade-animation-on-hide-not-working/120130/2

    // create listeners - moved to web & native...
    // this.createPlatformListeners();
  }

  /**
   * create listeners
   */
  private createPlatformListeners() {
    try {
      this.platform.pause.subscribe(() => {
        console.log('[INFO] App paused -> pause videos');
        this.videoPlayerService.pause();
      });

      this.platform.resume.subscribe(() => {
        console.log('[INFO] App resumed, pausing video?');
        // check the route, if it's not stack/play then pause all players
        if (!this.router.url.includes(WATCH_ROUTE)) {
          this.videoPlayerService.pause();
        }
        // this.toaster.present("App Reloading...");
        // this.reload();
      });
    } catch (error) {
      console.warn(error);
    }

    /**
     * MVP-989 Stop Video Player when not active
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/blur_event
     * handle in state where windowFocused: boolean
     */
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      window.addEventListener('blur', (evt) => {
        // console.log(`Window.blur... pause app.`);
        // this.store.dispatch(windowBlurred());
        this.videoPlayerService.pause();
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      window.addEventListener('focus', (evt) => {
        // console.log(`Window.focus... unpause app.`);
        // this.store.dispatch(wondowFocused());
      });
    } catch (error) {
      console.warn(`No Window, no eventListener`, error);
    }
  }

  /**
   * get and update config
   */
  private async setupAppConfig(snapshot: ActivatedRouteSnapshot) {
    const queryParams: ParamMap = snapshot.queryParamMap;

    if (snapshot.params.projectId) {
      this.configService.projectId = snapshot.params.projectId;
    }
    if (snapshot.params.stackId) {
      this.configService.stackId = snapshot.params.stackId;
    }

    // add the TokenId to the service
    // MVP-1242 keep token until we can replace with invite (also can remove tokenId || snapshot.params.tokenId)
    const tokenId =
      snapshot.queryParams[TOKEN_QUERY_PARAM] ||
      snapshot.queryParams[TOKEN_QUERY_PARAM_OLD] ||
      snapshot.queryParams.tokenId;
    if (tokenId) {
      // console.log(`[App] Router tokenId: ${tokenId}`);
      this.tokensService.setTokenId(tokenId);
    }

    DEBUG_APP_CONFIG && console.log(`[App] checking config queryParamMap...`, { queryParams, params: snapshot.params });

    const config = await this.configService.loadConfigWithQueryParams(queryParams);

    const navExtras = {}; // { queryParamsHandling: 'merge' };

    if (config.isWidgetActive) {
      if (Array.isArray(config.silos) && config.silos.length > 0) {
        this.store.dispatch(environSetProjectSilos({ ids: config.silos }));
      }

      const navToProject = () => {
        if (config.startPageParams && config.startPageParams.id) {
          this.navCtrl.navigateRoot(`/${PROJECT_DETAIL_ROUTE}/${config.startPageParams.id}`, navExtras);
        } else if (config.projectId) {
          this.navCtrl.navigateRoot(`/${PROJECT_DETAIL_ROUTE}/${config.projectId}`, navExtras);
        } else {
          console.log(`[App] config missing startPage`, {
            startPage: config.startPage,
            startPageParams: config.startPageParams,
          });
          this.navCtrl.navigateRoot(`/${DISCOVER_PAGE}`, navExtras);
        }
      };

      /**
       * If we have a projectId and stackId, let's watch it!
       */
      if (this.configService.projectId && this.configService.stackId) {
        DEBUG_APP_CONFIG &&
          console.log(`[App] config load stack:`, `${this.configService.projectId}/${this.configService.stackId}`);
        this.navCtrl.navigateRoot(
          `/stack/play/${this.configService.projectId}/${this.configService.stackId}`,
          navExtras
        );
      } else if (config.startPage) {
        switch (config.startPage) {
          case 'splash': {
            setTimeout(() => {
              this.store
                .select(selectTourWidgetSplash)
                .pipe(take(1))
                .subscribe((seen) => {
                  console.log(`[App] widget splash seen: ${seen}`);
                  if (!seen) {
                    this.navCtrl.navigateRoot('/tour', navExtras);
                  } else {
                    navToProject();
                  }
                });
            }, 300);
            break;
          }
          case 'project': {
            navToProject();
            break;
          }
          case 'discover': {
            DEBUG_APP_CONFIG &&
              console.log(`[App] setToStart`, { startPage: config.startPage, startPageParams: config.startPageParams });
            this.navCtrl.navigateRoot(`/${DISCOVER_PAGE}`, navExtras);
            break;
          }
          default: {
            console.warn(`[App] Widget startPage UNHANDLED`, config.startPage);
          }
        }
      }
    } else {
      // widget not active
      // console.log(`[App] widget not active`);
    }
  }

  private getResizeObject(): ResizeObject {
    // https://stackoverflow.com/questions/38737341/how-to-get-device-width-and-height-in-ionic-2-using-type-script
    // platform width & height become cached values
    return {
      width: window?.innerWidth ?? this.platform.width(),
      height: window && window.innerHeight ? window.innerHeight : this.platform.height(),
    };
  }

  /**
   * currently unused
   * @see updates.service
   */
  private reload() {
    // if (this.splashScreen) {
    //   this.splashScreen.show();
    // }
    if (window?.location && typeof window.location.reload === 'function') {
      window.location.reload();
    } else {
      console.log('[INFO] Reload not possible...');
      // if (this.splashScreen) {
      //   this.splashScreen.hide();
      // }
    }
  }

  /**
   * Check if user has interacted with the browser Document
   * Avoid NotAllowedError OnPlay
   * in app.component to catch all cases, not just player page
   */
  private watchForInteraction() {
    const events = ['mousemove', 'scroll', 'keydown', 'click', 'touchstart'];

    let didInteract = false;
    const handleInteraction = (evt) => {
      if (!didInteract) {
        console.log(`[App] interaction.${evt && evt.type}`);
        this.store.dispatch(interactWithDom());
        didInteract = true;
      } else {
        console.log(`[App] IGNORED interaction.${evt && evt.type}`);
      }
    };

    /**
     * once
     * A Boolean indicating that the listener should be invoked at most once after being added.
     * If true, the listener would be automatically removed when invoked.
     * https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
     */
    const options = {
      once: true,
    };
    try {
      const source =
        document && document.body && typeof document.body.addEventListener === 'function' ? document.body : window;

      events.forEach((event) => {
        source.addEventListener(event, handleInteraction, options);
      });
    } catch (error) {
      console.warn(`No Window, no eventListner`, error);
    }

    /**
     * create the listener for fullscreen
     * https://developer.mozilla.org/en-US/docs/web/api/fullscreen_api#Notification
     * check document.fullscreenElement !== null
     *
     * https://stackoverflow.com/questions/32920967/media-query-for-fullscreen
     *
     * @note this doesn't appear to trigger on ios with just fullscreenchange
     * fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange webkitbeginfullscreen webkitendfullscreen
     */
    try {
      document.addEventListener('fullscreenchange', () => {
        const isActive = document.fullscreenEnabled && document.fullscreenElement !== null;
        console.log(`document.fullscreen => ${isActive ? 'body.is-fullscreen' : isActive}`);
        document.body.classList.toggle('is-fullscreen', isActive);
      });
    } catch (error) {
      console.warn(`Error during document.fullscreenchange eventListener`, error);
      document.body.classList.toggle('is-fullscreen', false);
    }
  }

  /**
   * Example on handling theme changes
   * add this to template:
   * [class]="theme"
   */
  //   onThemeChange(theme: boolean) {
  //     // this.themeService.setDarkTheme(theme);
  //     // this.theme = (theme) ? 'my-dark-theme' : 'my-light-theme';
  //     // console.log(theme);
  //     // if (this.overlayContainer) {
  //     //   const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
  //     //   const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
  //     //   if (themeClassesToRemove.length) {
  //     //     overlayContainerClasses.remove(...themeClassesToRemove);
  //     //   }
  //     //   overlayContainerClasses.add(this.theme);
  //     // }
  //   }
}
