import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { Clip } from '@app/shared/models/clip.model';
import { Project } from '@projects/shared/project.model';
import { UserService } from '@app/core/services/user.service';
import { ProjectService } from '@projects/shared/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

const PAGE = '[Clipper]';

export enum ClipperSegments {
  Trim = "TRIM",
  Split = "SPLIT",
  Poster = "POSTER",
  Settings = "SETTINGS",
  // Preview = "PREVIEW",
}

@Component({
  selector: 'app-clipper',
  templateUrl: './clipper.component.html',
  styleUrls: ['./clipper.component.scss'],
})
export class ClipperComponent implements OnInit, OnDestroy {

  @Input()
  set clip(val) {
    this._clip = val;
    if (val && val.projectId) {
      /**
       * @todo validate this logic...
       */
      this.project$ = this.projectService.getProject(val.projectId);
    }
  }
  get clip() {
    return this._clip;
  }
  @Input() selectedSegment: ClipperSegments = ClipperSegments.Settings;
  @Input() isModal: boolean = false;
  @Input() cancelText: string = "Cancel"; // if isModal, show in footer
  @Input() returnUrl: string;
  @Input() defaultReturnUrl = '/stack/studio';

  // @ViewChild(ClipSettingsComponent, {static: false}) clipSettingsComponent: ClipSettingsComponent;
  // @ViewChild(TrimmerComponent, {static: false}) trimmerComponent: TrimmerComponent;
  // @ViewChild(SelectPosterComponent, {static: false}) selectPosterComponent: SelectPosterComponent;

  currentUserId$: Observable<string>;
  project$: Observable<Project>;
  segmentNames = ClipperSegments;

  private _clip: Clip;
  private onDestroy$ = new Subject<void>();

  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router
  ) { }


  ngOnInit() {
    // intended as a "Cancel" button, but not necessary with the back button
    // this.returnUrl = this.returnUrl || this.defaultReturnUrl;
    this.selectedSegment = this.isSegment(this.selectedSegment) ? this.selectedSegment : ClipperSegments.Settings;
    
    this.currentUserId$ = this.userService.userId$;

    this.route.queryParams.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((params) => {
      if (params?.returnUrl) {
        this.returnUrl = params.returnUrl;//JSON.parse(params.returnUrl);
        console.log(`${PAGE} queryParams returnUrl:`, this.returnUrl);
      } else if (this.router.getCurrentNavigation()?.extras?.state) {
        this.returnUrl = this.router.getCurrentNavigation().extras.state.returnUrl || '';
        console.log(`${PAGE} navState:`, { returnUrl: this.returnUrl });
      }
    });
  }

  isSegment(name: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ((Object as any).values(ClipperSegments).includes(name));
  }

  dismiss() {
    if (!this.isModal) return;
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'clip': this.clip,
    });
  }

  onSelectSegment(event) {
    if (event && this.isSegment(event)) {
      this.selectedSegment = event;
    } else {
      console.warn(`${PAGE} onSelectSegment NO VALUE or !isSegment?:`, event);
    }
  }

 

  navTrim() {
    this.selectedSegment = ClipperSegments.Trim;
  }
  navPoster() {
    this.selectedSegment = ClipperSegments.Poster;
  }
  navSettings() {
    this.selectedSegment = ClipperSegments.Settings;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
