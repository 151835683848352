/** @format */

import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  getInviteAcceptPath,
  getInviteTypeFromProject,
  OnboardingTypes,
  TRANSLATE_ROOT,
} from '@onboarding/shared/onboarding.model';
import { Project } from '@projects/shared/project.model';
import { UserService } from '@services/user.service';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { selectMyProjects } from '@store/selectors/projects.selectors';
import { Utils } from '@shared/utils';
import { ToasterService } from '@services/toaster.service';
import { MailerApiService, MailTopic, SendMail } from '@app/core/api/mailer.api.service';
import { ConfigService } from '@app/core/config';
import { TokensService } from '@tokens/shared/services/tokens.service';
import { getReturnUrl, Token, TokenActions, TokenPayload, TOKEN_QUERY_PARAM } from '@tokens/shared/tokens.model';
import { PROJECT_MEMBER_ROLE } from '@members/shared/project-member.model';
import { combineLatest, Subject } from 'rxjs';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';

const DEBUG_LOGS = false;
const ROOT_APP_URL = 'https://app.filmstacker.com/';

@Component({
  selector: 'app-invite-form',
  templateUrl: './invite-form.component.html',
  styleUrls: ['./invite-form.component.scss'],
})
export class InviteFormComponent implements OnInit, OnDestroy {
  @Input() projectId = '';
  @Input() translatePath = TRANSLATE_ROOT;
  @Input() actionUrl = '/invite';
  @Input()
  set routeId(value) {
    this._routeId = value || OnboardingTypes.Teams;
    let messagePath;
    switch (value) {
      case OnboardingTypes.Weddings: {
        messagePath = 'EMAILS.ONBOARDING.WEDDINGS.INVITE_MESSAGE';
        break;
      }
      default: {
        messagePath = 'EMAILS.ONBOARDING.EVENTS.INVITE_MESSAGE';
      }
    }
    DEBUG_LOGS && console.log(`[InviteForm]`, { routeId: value, messagePath });
    this.translate
      .get(messagePath)
      .pipe(take(1))
      .subscribe((res: string) => {
        if (!res) {
          return console.warn(`[INVITE] missing translation?`);
        }
        this.projectMemberInviteForm.patchValue({
          message: res,
        });
      });
  }
  get routeId() {
    return this._routeId;
  }

  @Output() inviteSuccess = new EventEmitter();

  isLoggedIn$ = this.userService.isLoggedIn$;

  projectMemberInviteForm = this.formBuilder.group({
    projectId: ['', Validators.required],
    message: ['', Validators.required],
    emails: ['', Validators.required],
  });

  /** the emails we will contact */
  emails: string[] = [];
  loadingAttempt = false;
  message = '';

  hideProjectSelect = true;
  projectOptions = [];
  // myProjects$ = this.store.select(getMyProjects);

  private _appUrl = ROOT_APP_URL;
  private onDestroy$ = new Subject<void>();
  private _projects: Project[] = [];
  private _routeId: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<State>,
    private translate: TranslateService,
    private mailerApi: MailerApiService,
    private configService: ConfigService,
    private tokensService: TokensService,

    private toaster: ToasterService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    /**
     * 1. get the activeProject and select that one if it's mine & is {eventType} template
     * 2. Filter myProjects by eventType template
     * 3. auto-select if only one project exists
     * @see stack-form-details for similar implementation
     */
    //  combineLatest([
    //   this.myProjects$,
    //   from(this.projectId),//this.environService.activeProjectId$,
    //   this.onboardingService.routeOnboardingType$
    // ])
    this.store
      .select(selectMyProjects)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((projects) => {
        this._projects = Array.isArray(projects) ? projects : [];
        const activeProjectId = this.projectId;

        const routeTemplates = [];
        switch (this.routeId) {
          case OnboardingTypes.Weddings: {
            routeTemplates.push(...['wedding', 'weddings']);
            break;
          }
          case OnboardingTypes.Teams:
            routeTemplates.push(...['team', 'teams']);
            break;
          case OnboardingTypes.Pro:
            routeTemplates.push(...['pro']);
            break;
          default: {
            // nada
          }
        }

        DEBUG_LOGS &&
          console.log(`[InviteForm] getMyProjects`, {
            projects,
            routeTemplates,
            routeId: this.routeId,
            activeProjectId,
          });

        const checkAndUpdateProjectId = (projs: Project[] = []): boolean => {
          // don't return on already set form, as this will be called multiple times while building the myProjects from crew & owned api calls
          // if (this.projectMemberInviteForm.get('projectId').value) {
          //   // we already have a value, we're done
          //   return true;
          // }
          if (projs.length > 0) {
            this.buildProjectOptions(projs);
            if (projs.length === 1) {
              DEBUG_LOGS && console.log(`[InviteForm] only one project -> use that!`);
              this.projectMemberInviteForm.patchValue({
                projectId: projs[0].id,
              });
            } else if (activeProjectId && projs.some((p) => p && p.id === activeProjectId)) {
              // if there's an activeProject, and it's included
              DEBUG_LOGS && console.log(`[InviteForm] includes activeProject -> use that!`, activeProjectId);
              this.projectMemberInviteForm.patchValue({
                projectId: activeProjectId,
              });
            }
            this.hideProjectSelect = projs.length === 1;
            return true; // options ready for select
          }
          return false;
        };

        // check if we have myprojects that match the projectId found in route
        const routed = this.projectId ? this._projects.filter((p) => p && p.id === this.projectId) : [];
        if (!checkAndUpdateProjectId(routed)) {
          // check if we have myprojects that match the template based on routeId
          const filtered = this._projects.filter(
            (p) => p && p.template && routeTemplates.includes(p.template.toLowerCase())
          );
          if (!checkAndUpdateProjectId(filtered)) {
            // myProjects do not have matching routeTemplates
            DEBUG_LOGS &&
              console.log(`[InviteForm] combineLatest myProjects do not have matching routeTemplates...yet`, {
                projects,
                activeProjectId,
                routeTemplates,
                filtered,
              });
            checkAndUpdateProjectId(this._projects);
          }
        }
      });

    // this is not the correct logic, as a widget could not accept tokens, only the root app
    // this.configService.appConfig.then(({ shareUrl }) => {
    //   this._appUrl = shareUrl;
    // });
  }

  // ngAfterViewChecked(): void {
  //   /**
  //    * this was migrated from the onboarding-invite - TBD if still needed...
  //    * was added to avoid expression change after it was checked ng-valid-true for the projectId causing input-select to change & hide
  //    * https://stackoverflow.com/questions/53901392/expression-has-changed-after-it-was-checked-previous-value-ng-valid-true-c
  //    */
  //   this.changeDetectorRef.detectChanges();
  // }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * TextArea emails change
   * split the string on each occurrence of a space or comma (split(/[, ]+/)), or newline  (.split(/\r?\n/))
   * The plus + matches the preceding item (comma or space) one or more times, which means both a comma and a space would count as 1 match.
   * filter exists and passes basic valid email test
   */
  emailsChange(value) {
    try {
      if (!value || typeof value.split !== 'function') return;
      this.emails = value.split(/[, \r?\n/]+/).filter((element) => element && Utils.REGEX_EMAIL.test(element));
    } catch (error) {
      console.warn(error);
    }
  }

  /**
   * @todo why the url pro? just always make it teams!
   *
   * Send the invitations
   * @see add-member.component refactor similar function implementation?
   */
  async sendInvites() {
    this.message = '';
    if (!Array.isArray(this.emails) || this.emails.length < 1) {
      return;
    }
    this.loadingAttempt = true;

    try {
      combineLatest([this.userService.username$, this.userService.email$])
        .pipe(take(1))
        .subscribe(async ([name, userEmail]) => {
          const emails = this.emails;
          const message = this.projectMemberInviteForm.get('message').value;
          const projectId = this.projectMemberInviteForm.get('projectId').value;

          const proj = this._projects.find((p) => p && p.id === projectId);
          const projectsTextArr = [];
          if (proj && proj.id && proj.title) {
            projectsTextArr.push({
              id: projectId,
              title: proj.title,
              img: proj.hero,
              shareUrl: proj.shareUrl,
              // mission: proj.mission,
            });
          } else {
            console.warn(`[INVITE] sendInvite missing project Data?`, {
              proj,
              projects: this._projects,
              projectId,
            });
            this.toaster.present(`We had an issue getting the Project Details? Please try again..`);
            return;
          }

          // set everyone as crew, initially
          const projectRole = PROJECT_MEMBER_ROLE.CREW;
          /** the url to have displayed in the email */
          const returnUrl = `${this._appUrl}${getInviteAcceptPath(this.routeId as OnboardingTypes)}/${projectId}`;
          const appProjectUrl = `${this._appUrl}${PROJECT_DETAIL_ROUTE}/${projectId}`;
          /**
           * @todo when migrating, here handle different eventType
           */
          // const eventType = getEventTypeFromOnboarding(this.routeId);
          const eventType = getInviteTypeFromProject(proj);
          const payload: TokenPayload = {
            /** the url to redirect upon signup/in */
            returnUrl: getReturnUrl(projectId),
            acceptUrl: returnUrl,
            message,
            // eventType,// moved to parent
          };

          // create a querystring adder to shareUrl
          const tokenQueryParam = TOKEN_QUERY_PARAM;
          // const tokenQuery = `?${tokenQueryParam}=`; // ${tokenId}`;

          const messageNoNewlines = Utils.convertNewlinesToBr(message);

          const getEmailTranslatePath = (el) => {
            switch (this.routeId) {
              case OnboardingTypes.Weddings: {
                return 'EMAILS.ONBOARDING.WEDDINGS.' + el;
              }
              default: {
                return 'EMAILS.ONBOARDING.EVENTS.' + el;
              }
            }
          };

          const footer = this.translate.instant(getEmailTranslatePath('INVITE_INSTRUCTION'));

          // loop all the emails..
          const promises = [];

          for (const email of this.emails) {
            /**
             *
             */
            const token: Token = new Token({
              // email,
              sentToEmail: email,
              fromEmail: userEmail, // user that sent the token
              projectId,
              projectRole,
              action: TokenActions.ProjectInvite,
              payload,
              eventType,
            });

            promises.push(
              this.tokensService.createToken(token).then((res) => {
                const { id: tokenId, message: createTokenMessage } = res;

                /**
                 * @todo refactor to mailerService.sendInviteEmail
                 * see member-invite-modal.component
                 */

                // a token exists now
                // create message with token queryparam
                const urlWithToken = (url) =>
                  url && tokenId && tokenQueryParam ? `${url}?${tokenQueryParam}=${tokenId}` : url;
                const { projectsHtml, projectsText } = this.mailerApi.buildProjectsListMessage(
                  projectsTextArr.map((item) => ({ ...item, shareUrl: urlWithToken(appProjectUrl) }))
                );

                const params: SendMail = {
                  tokenId,
                  tokenQueryParam,
                  toEmail: email,
                  fromEmail: `${name} (${userEmail})`,
                  // name, // this is the toName... confusing!
                  subject: this.translate.instant(getEmailTranslatePath('INVITE_SUBJECT')),
                  topic: MailTopic.InviteToCrew,
                  // toEmail: 'jd@filmstacker.com',//must be confirmed in AWS SES
                  message: messageNoNewlines + '<br><br>' + projectsHtml,
                  messageText: Utils.stripHtml(messageNoNewlines, { leaveBrTags: true }) + '<br><br>' + projectsText,
                  footer,
                  heading: this.translate.instant(getEmailTranslatePath('INVITE_HEADING')),
                  buttonLabel: this.translate.instant(getEmailTranslatePath('INVITE_CTA')),
                  returnUrl,
                };

                DEBUG_LOGS &&
                  console.log(`[INVITE] sendInvite sendingEmail:`, {
                    toEmail: email,
                    userEmail,
                    projectsText,
                    message,
                    footer,
                    returnUrl,
                    params,
                    createTokenMessage,
                  });

                return this.mailerApi
                  .sendEmail(params)
                  .pipe(take(1))
                  .subscribe((result) => result);
              })
            );
          } //end emails loop

          DEBUG_LOGS && console.log(`sendInvites:`, { emails, message, userEmail, projectId });

          return await Promise.all(promises)
            .then((allResults) => {
              DEBUG_LOGS && console.log(`[INVITE] sendEmail success res:`, allResults);
              this.resetOnSuccess(
                this.translate.instant(this.translatePath + 'INVITE_SUCCESS', { value: allResults.length })
              );
            })
            .catch((allError) => {
              console.warn(`[INVITE] sendEmail ERROR:`, allError);
              this.message = this.translate.instant('ERRORS.GENERIC_OOPS');
            });
        });
    } catch (error) {
      console.warn(`[Invite] sendInvites FAILED:`, error);
      this.toaster.present(this.translate.instant('MEMBERS.INVITE.ERROR'));
    } finally {
      this.loadingAttempt = false;
    }
  }

  /**
   * @dev - is this better? yes... optimize the delay in showing the popup on select click
   * @todo implement appConfig allowPublishTo
   * @see publish-stack.page.getProjects for implementation reference
   */
  private buildProjectOptions(projects: Project[]): { text: string; value: string }[] {
    // let allProjects = [];
    // /**
    //  */
    // if (this.configProjects.length > 0) {
    //   // combine myProjects with configProjects
    //   allProjects = Utils.addOrReplaceMultipleObjectsToNewArray(projects || [], this.configProjects, 'id').sort(
    //     Utils.sortObjArrayAlphaBy
    //   );
    // } else {
    //   allProjects = projects;
    // }
    this.projectOptions = projects
      .sort((a, b) => Utils.sortObjArrayAlphaBy(a, b, 'title'))
      .map((item) => ({
        text: item.title,
        value: item.id,
      }));

    return this.projectOptions;
  }

  private resetOnSuccess(msg) {
    this.message = msg;

    this.projectMemberInviteForm.reset({
      // projectId: this.projectId
      message: this.projectMemberInviteForm.get('message').value,
      projectId: this.projectMemberInviteForm.get('projectId').value,
      emails: ' ',
    });
    // this.inviteForm.updateValueAndValidity();
    // this.inviteForm.controls.emails.setErrors(null);
    this.inviteSuccess.emit(true);
  }
}
