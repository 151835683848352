/** @format */

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Platform, IonicSlides } from '@ionic/angular';
import { EventActions, EventsService, ResizeObject } from '@app/core/services/events.service';
import { ToasterService } from '@app/core/services/toaster.service';
import {
  PathList,
  convertStacksToPathList,
  calcNumPaths,
  getExploreStackPathId,
} from '@app/modules/explore/shared/explore.model';
import { ExploreService } from '@app/core/services/explore.service';
import { Clip, getClipTaxNameArray } from '@app/shared/models/clip.model';
import { Stack } from '@shared/models/stack.model';
import { ClipsCoreService } from '@services/clips.service';
import { SentryService } from '@services/analytics/sentry.service';
import { Utils } from '@shared/utils';

// import { calcNumPaths } from '@app/core/services/explore.service';
import { INewPlaylistEvent } from '../explore-ui.component';

const DEBUG_LOGS = false;
const SHOW_PATH_ACTION_BTNS = true;

const LOADING_TIMEOUT_MS = 5000;

const CURRENT_PLAYLIST_PATH_ID = 0;

const PAGE = '[ExplorePathList]';

@Component({
  selector: 'app-explore-path-list',
  templateUrl: './explore-path-list.component.html',
  styleUrls: ['./explore-path-list.component.scss'],
})
export class ExplorePathListComponent implements OnInit, OnDestroy {
  /*
    todo:

    refreshPath()
  */

  @Input() drawerId: string;
  @Input() showCurrentClip = false;
  @Input()
  get currentClip(): Clip {
    return this._currentClip;
  }
  set currentClip(value) {
    if (value && value.id) {
      // changed if there's no current clip, or this is a new id
      const changed = !this._currentClip || (this._currentClip.id && this._currentClip.id !== value.id);
      this._currentClip = value;
      if (changed) {
        this.onChangedCurrentClip();
      }
    }
  }

  /**
   * @deprecated
   */
  @Input() currentPlaylist: Clip[] = [];
  // set currentPlaylist(value) {
  //   if (Array.isArray(value)) {
  //     this._currentPlaylist = value;
  //     this.checkHaveAll();
  //   }
  // }
  // get currentPlaylist(): Clip[] {
  //   return this._currentPlaylist;
  // }
  /**
   * @deprecated
   */
  @Input() currentPlaylistIndex: number = 0;
  // set currentPlaylistIndex(value) {
  //   if (typeof value === 'number' && value >= 0) {
  //     this._currentPlaylistIndex = value;
  //     this.checkHaveAll();
  //   }
  // }
  // get currentPlaylistIndex(): number {
  //   return this._currentPlaylistIndex;
  // }

  // @Input() currentStack: Stack; // removed this since currentStack is only needed in service

  @Input() numPaths = 3;

  // @Input() scroller: any;// Scroll; // this messes up ngOnChanges - ensure we need this!

  @Output() newPlaylist = new EventEmitter<INewPlaylistEvent>();

  // https://ionicframework.com/docs/api/components/slides/Slides/
  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  swiperModules = [IonicSlides];
  slidesPerView = 3;

  get swiper() {
    return this.swiperRef?.nativeElement?.swiper;
  }

  // get currentClip() {
  //   if (
  //     Array.isArray(this.currentPlaylist)
  //     && this.currentPlaylist[this.currentPlaylistIndex]
  //     && this.currentPlaylist[this.currentPlaylistIndex].id
  //   ) {
  //     return this.currentPlaylist[this.currentPlaylistIndex];
  //   }
  //   return {
  //     id: '',
  //     projectId: ''
  //   }
  // }

  paths: PathList[] = [];
  loading = true;

  errorMessage: string;

  pathTitle: string = 'Loading';
  pathDeets: string = '';
  duration: string = '';

  /**
   * No More to Load More, change last slide template
   */
  noMoreLoadMore = false;

  /*
    USED? 
  */

  /**
   * @deprecated
   */
  pathStack: Stack;
  /**
   * @deprecated
   */
  pathPlaylist: Clip[];
  // isCurrentPlaylist: boolean = false;

  loaded: boolean = false;
  playlistLoaded: boolean = false;
  /**
   * @deprecated
   * this is on the PathList model
   */
  refreshes: number = 0;

  chips: string[];
  // items: IClipItem[];

  poster: string;
  tags: string[];

  showPathActionBtns = SHOW_PATH_ACTION_BTNS;
  currentPlaylistPathId = CURRENT_PLAYLIST_PATH_ID;

  loadingMore = false;

  // end used?

  // private _initialSlideOptions = {
  //   // initialSlide: 0,
  //   // speed: 400,
  //   // slidesPerView: 3, // need to determine this OnInit
  //   // freeMode: true,
  //   // spaceBetween: 6,
  //   // effect: 'slide',
  //   parallax: false, // no longer exists...
  // };

  private explorePropsReady = false;
  private screenWidth: number;

  private _currentPlaylist: Clip[] = [];
  private _currentPlaylistIndex: number = 0;
  private _currentStack: Stack;

  private _loadingTimer;
  // private _loadedInitialPaths = false;
  private _currentClip: Clip;

  private pathStacks: { [id: string]: Stack } = {};

  private onDestroy$ = new Subject<void>();
  private subscriptions: Subscription = new Subscription();

  constructor(
    private toaster: ToasterService,
    private platform: Platform,
    private exploreService: ExploreService,
    private clipService: ClipsCoreService,
    private events: EventsService,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    const subResize = this.events.subscribe(EventActions.RESIZE, this.onResize);
    this.subscriptions.add(subResize);

    // do we need scroller?
    // const subScroll = this.events.subscribe('explore:scroll:top', this.scrollToTop);
    // this.subscriptions.add(subScroll);

    this.platform.ready().then(() => {
      this.screenWidth = this.platform.width();
      this.slidesPerView = calcNumPaths(this.screenWidth);
      DEBUG_LOGS && console.log(`${PAGE} screenWidth (${this.screenWidth}) slidesPerView: '${this.slidesPerView}'`);
    });

    if (this.loading) {
      this.resetLoadingTimer();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.subscriptions.unsubscribe();
  }

  tryAgainOnError() {
    console.warn(`${PAGE} tryAgainOnError errorMessage: '${this.errorMessage}'`);
    this.tryAgainNoResults();
  }

  tryAgainNoResults() {
    DEBUG_LOGS && console.log(`${PAGE} tryAgainNoResults`);
    this.resetLoadingTimer();
    this.getPathways();
  }

  async loadMore() {
    this.loadingMore = true;
    DEBUG_LOGS && console.log(`${PAGE} loadMore`);
    try {
      // load another
      const stacks = await this.loadPathways(1);
      // to the index
      this.paths.push(...convertStacksToPathList({ stacks, pathIndex: this.paths.length }));
      this.updatePathStacks(stacks);
      // this.pathStacks.push(...stacks);
      this.loadingMore = false;
    } catch (error) {
      console.warn(`${PAGE} loadMore caught:`, error);
      this.noMoreLoadMore = true;
      this.loadingMore = false;
    }
  }

  async reloadPath({ pathId, pathIndex }) {
    DEBUG_LOGS && console.log(`${PAGE} reloadPathId`, { pathId, pathIndex });
    try {
      // load another
      const stacks = await this.loadPathways(1);
      // to the index
      const index = this.paths.findIndex((p) => p.pathId === pathId);
      this.paths.splice(index, 1, ...convertStacksToPathList({ stacks, pathIndex }));
      this.updatePathStacks(stacks);
      // this.pathStacks.splice(pathIndex, 1, ...stacks);
    } catch (error) {
      console.warn(`${PAGE} reloadPathIndex caught:`, error);
      // remove it?
      // this.paths.splice(pathIndex, 1);
      this.onListError(pathIndex, `Sorry, unable to Reload Path?`);
    }
  }

  async playPath({ pathId, pathIndex }) {
    DEBUG_LOGS &&
      console.warn(`${PAGE} playPath`, {
        // pathIndex,
        pathId,
        stack: this.pathStacks[pathId],
        // path: this.paths[pathIndex], // this is not a correct value..
        pathStacks: this.pathStacks,
        paths: this.paths,
      });
    try {
      // const path = this.paths[pathIndex];
      // deep clone, so we can remove this one
      const pathStack = JSON.parse(JSON.stringify(this.pathStacks[pathId]));

      const params: INewPlaylistEvent = {
        pathId,
        // pathIndex,
        pathStack,
      };
      DEBUG_LOGS && console.log(`${PAGE} playPath:`, { params });

      this.newPlaylist.emit(params);
      // now refresh this path
      // this.reloadPath({ pathId, pathIndex });
      // no, not reload, remove it - actually, just allow the child to hide itself...
      this.removePath({ pathId, pathIndex });
    } catch (error) {
      console.warn(`${PAGE} playPathIndex caught:`, error);
      this.onListError(pathIndex, `Sorry, unable to Play Path?`);
    }
  }

  // async reloadPathIndex(pathIndex: number) {
  //   DEBUG_LOGS && console.log(`${PAGE} reloadPathId`, { pathIndex });
  //   try {
  //     // load another
  //     const stacks = await this.loadPathways(1);
  //     const items = convertStacksToPathList({ stacks, pathIndex });
  //     // to the index
  //     this.paths.splice(pathIndex, 1, ...items);
  //     this.updatePathStacks(items[0].pathId, stacks[0]);
  //     // this.pathStacks.splice(pathIndex, 1, ...stacks);
  //   } catch (error) {
  //     console.warn(`${PAGE} reloadPathIndex caught:`, error);
  //     // remove it?
  //     // this.paths.splice(pathIndex, 1);
  //     this.onListError(pathIndex, `Sorry, unable to Reload Path?`);
  //   }
  // }

  // async playPathIndex(pathIndex: number) {
  //   DEBUG_LOGS && console.log(`${PAGE} playPathIndex`, {
  //     pathIndex,
  //     stack: this.pathStacks[pathIndex],
  //     path: this.paths[pathIndex],
  //     pathStacks: this.pathStacks,
  //     paths: this.paths
  //   });
  //   try {
  //     const path = this.paths[pathIndex];
  //     // deep clone, so we can remove this one
  //     const pathStack = JSON.parse(JSON.stringify(this.pathStacks[pathIndex]));

  //     const params: INewPlaylistEvent = {
  //       pathId: pathIndex,
  //       pathStack,
  //     };
  //     DEBUG_LOGS && console.log(`${PAGE} playPath:`, { params });

  //     this.newPlaylist.emit(params);
  //     // now refresh this path
  //     this.reloadPathIndex(pathIndex);
  //   } catch (error) {
  //     console.warn(`${PAGE} playPathIndex caught:`, error);
  //     this.onListError(pathIndex, `Sorry, unable to Play Path?`);
  //   }
  // }

  /*
     private
   */

  private updatePathStacks(stacks: Stack[] = []) {
    stacks.forEach((stack) => {
      this.pathStacks[getExploreStackPathId(stack)] = stack;
    });
  }

  private onListError(pathId: string, message: string) {
    try {
      this.paths.find((p) => p.pathId === pathId).errorMessage = message;
    } catch (error) {
      console.warn(`${PAGE} onListError (${pathId}) '${message}'`);
      // just silently fail
    }
  }

  private onError(msg: string) {
    // this.onError('Oops! there was a disturbance in the Force... please try again.');
    this.errorMessage = msg || `Oops! please try again.`;
    this.loading = false;
    this.loaded = false;
  }

  /**
   * starting the loading timer, set loading to false after LOADING_TIMEOUT_MS
   */
  private resetLoadingTimer() {
    this.loading = true;
    clearTimeout(this._loadingTimer);
    this._loadingTimer = setTimeout(() => {
      this.loading = false;
    }, LOADING_TIMEOUT_MS);
  }

  /**
   * handle the Input() currentClip that just changed
   */
  private onChangedCurrentClip() {
    if (this.currentClip && this.currentClip.id) {
      DEBUG_LOGS && console.log(`${PAGE} onChangedCurrentClip...`, this.currentClip);
      this.getPathways();
    }
  }

  private async getPathways(initalNum = 3) {
    try {
      if (this.loading && this.paths.length < 1) {
        // first time through
        const stacks = await this.loadPathways(initalNum);
        // rethink model extending Stack
        this.paths = convertStacksToPathList({ stacks });
        this.updatePathStacks(stacks);
        // this.pathStacks.push(...stacks);
      } else {
        // load another
        const stacks = await this.loadPathways(1);
        // to the front
        this.paths.unshift(...convertStacksToPathList({ stacks, pathIndex: this.paths.length }));
        this.updatePathStacks(stacks);
      }
      this.loading = false;
    } catch (error) {
      console.warn(`${PAGE} getPathways caught error:`);
      console.error(error);
      if (error && Array.isArray(error.errors) && error.errors.length > 0) {
        console.warn(error.errors[0]);
        if (error.errors[0].message) {
          console.warn(error.errors[0].message);
        }
      }
      // throw error; // fail silently here
      this.sentryService.captureError(error);
    }
  }

  /** v5
   * get multiple at once
   *
   * @todo do not put the error here, each caller should handle
   *
   * need to fail gently
   * - if no more, just don't add
   * - if no more loadMore, just hide loadMore
   */
  private loadPathways(num = 3): Promise<Stack[]> {
    DEBUG_LOGS && console.log(`${PAGE} loadPathways num:`, num);

    return this.exploreService
      .getPathways({
        pathId: `${this.drawerId}`, //this.pathId,
        num,
        currentPaths: this.paths,
      })
      .then(({ paths }) => {
        DEBUG_LOGS && console.log(`${PAGE} loadPathways getPathways res:`, paths);
        return paths;
      })
      .catch((err) => {
        console.warn(`${PAGE} loadPathways getPathways caught error:`, err);
        throw err;
      });
  }

  /**
   * remove this id from paths
   * initially, let's just let it hide itself
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private removePath({ pathId, pathIndex }) {
    const index = this.paths.findIndex((p) => p.pathId === pathId);
    // // this.paths.splice(index, 1);
    // this.paths[index].hide
    if (index >= 0) {
      this.paths[index].didClickPlay = true;
    }
  }

  private onResize = (event: ResizeObject) => {
    // console.log(`${PAGE} onResize:`, event);
    this.screenWidth = event.width;
    // is this causing the TemplateChanged Warning?
    this.slidesPerView = calcNumPaths(this.screenWidth);
    DEBUG_LOGS && console.log(`${PAGE} onResize slidesPerView: ${this.slidesPerView}`, event);
    this.updateSlides();
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _updateSlidesTimeout;
  /**
   * Update the underlying slider implementation.
   * Call this if you’ve added or removed child slides
   */
  private updateSlides() {
    clearTimeout(this._updateSlidesTimeout);
    DEBUG_LOGS && console.log(`${PAGE} updateSlides`);
    this._updateSlidesTimeout = setTimeout(() => {
      if (this.swiper && typeof this.swiper.update === 'function') {
        this.swiper.update();
      } else {
        console.warn(`${PAGE} no swiper.update?`);
      }
    }, 600);
  }

  /*
      TO BE REFACTORED
   */

  // scrollToTop = (drawerId) => {
  //   // if (drawerId === this.drawerId) {
  //   //   if (this.scroller && this.scroller._scrollContent && this.scroller._scrollContent.nativeElement) {
  //   //     //credit to: https://stackoverflow.com/questions/44099796/how-can-i-use-content-scrollto-for-ion-scroll-in-ionic2
  //   //     let scroll = this.scroller._scrollContent.nativeElement;
  //   //     scroll.scrollTop = 0;
  //   //   } else {
  //   //     console.log(`${PAGE} unable to access scroller._scrollContent.nativeElement ?`);
  //   //   }
  //   // }
  // };

  // resizeNumPaths() {
  //   // TODO: getting error in Chrome on inspector orientation change?
  //   // also, will this cause new pathways to be generated?
  //   // console.log(`${PAGE} resizeNumPaths platform:`, this.platform);
  //   // if (this.respondToResizeNumPaths) {
  //   //   this.numPaths = 0; //calcNumPaths(this.screenWidth) + 1;
  //   //   this.slidesPerView = this.numPaths - 1, // TODO: validate this logic
  //   //   DEBUG_LOGS && console.log(`${PAGE} slidesPerView: ${this.slidesPerView} numPaths; ${this.numPaths}`);
  //   //   this.swiper?.slideTo(0);
  //   // }
  // }

  // // private slideChanged() {
  // //   // let currentIndex = this.swiper?.getActiveIndex();
  // //   // console.log(`${PAGE} slider Current index is ${currentIndex}`);
  // // }

  // private resizeSlides() {
  //   // console.log(`${PAGE} resizeSlides`);
  //   this.updateSlides();
  // }

  // private onPathLoaded = ({ drawerId, pathId }) => {
  //   // console.log(`${PAGE} onPathLoaded`, {drawerId, pathId});
  //   this.resizeSlides();
  // };

  // private emitLoaded() {
  //   this.events.publish('explore:path:loaded', { drawerId: this.drawerId });
  // }

  // private playPath() {
  //   const params: INewPlaylistEvent = {
  //     pathId: 0, //this.pathId,
  //     pathStack: this.pathStack,
  //     pathPlaylist: this.pathPlaylist,
  //   };
  //   DEBUG_LOGS && console.log(`${PAGE} playPath:`, params);
  //   this.newPlaylist.emit(params);
  //   this.refreshes = 0;
  //   this.events.publish('explore:scroll:top', this.drawerId);
  //   // this.closeDrawer(); //happens in parent on emit
  // }

  // private refreshPath() {
  //   // this.toaster.present(`Refresh Storyline...`);
  //   this.loading = true;
  //   this.refreshes++;
  //   this.events.publish('explore:scroll:top', this.drawerId);
  //   this.getExplorePathway();
  // }

  // private closeDrawer() {
  //   this.events.publish('explore:close', this.drawerId);
  // }

  // private getPathway() {
  //   // if (this.pathId === CURRENT_PLAYLIST_PATH_ID && this.currentPlaylist && this.currentPlaylist.length > 0) {
  //   //   // this.pathTitle = "Current Storyline";
  //   //   this.pathPlaylist = this.currentPlaylist;
  //   //   this.getPlaylistDetailsFromClips(this.currentPlaylist);
  //   //   this.loading = false;
  //   //   this.loaded = true;
  //   // } else {
  //   //   // this.pathTitle = "Storyline " + (this.pathId - 1);
  //   //   this.getExplorePathway();
  //   // }
  // }

  /**
   * v1
   */
  // private getExplorePathway() {
  //   // { currentClip, currentPlaylist, currentStack, pathId = 0, iter = 0 }
  //   // this.exploreService
  //   //   .getPathway({
  //   //     currentClip: { projectId: this.currentClip.projectId, id: this.currentClip.id },
  //   //     currentPlaylist: this.currentPlaylist,
  //   //     currentStack: null, //{ projectId: this.currentStack.projectId, stackId: this.currentStack.stackId },
  //   //     pathId: '1', //this.pathId,
  //   //     iter: this.refreshes,
  //   //   })
  //   //   .then((stack) => {
  //   //     DEBUG_LOGS && console.log(`${PAGE} getExplorePathway res:`, stack);
  //   //     this.pathStack = stack;
  //   //     this.pathPlaylist = stack.clips;
  //   //     this.getPlaylistDetails(stack);
  //   //     this.errorMessage = '';
  //   //     this.loading = false;
  //   //     this.loaded = true;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.info(`${PAGE} getExplorePathway caught error::`, err);
  //   //     this.errorMessage = 'Oops! there was a disturbance in the Force... please try again.';
  //   //     this.loading = false;
  //   //     this.loaded = false;
  //   //   });
  // }

  /**
   * review this logic...
   */
  private getPlaylistDetails(stack: Stack) {
    if (!stack) {
      this.errorMessage = "Oops! these aren't the droids we were looking for... please try again.";
      return false;
    }

    const durations: Array<string> = [],
      // tags: Array<string> = [],
      maxTags = 30,
      allClipTags: Array<string> = [],
      maxTagsPerClip = 3;

    // v1 use stack instead of clip[]
    this.poster = stack.poster;
    this.tags = stack.tags || [];
    const playlist = stack.playlist || [];
    const clips = stack.clips || [];

    // console.log(`${PAGE} getPlaylistDetails:`, {clips, playlist});

    if (!clips || clips.length < 1) {
      // console.log(`${PAGE} getPlaylistDetails - No Clips...`);
    }

    // v2 order by clip flow, reducing duplicates
    const numClipTags = this.tags.length;
    for (const clip of clips) {
      // console.log(`${PAGE} getPlaylistDetails clip:`, clip);

      if (numClipTags < maxTags) {
        // let itemTags: string[] = [], // skip the bunding by clip (v1)
        const clipTags = getClipTaxNameArray(clip);
        // console.log(`${PAGE} getPlaylistDetails clipTags:`, clipTags);
        let numTagsThisClip = 0;
        for (const clipTag of clipTags) {
          if (numTagsThisClip >= maxTagsPerClip) {
            break;
          }
          const indexOfTag = allClipTags.findIndex((i) => i === clipTag);
          if (indexOfTag < 0 && numTagsThisClip < maxTagsPerClip) {
            allClipTags.push(clipTag);
            numTagsThisClip++;
          }
        }
      }

      if (!this.poster) {
        this.poster = this.clipService.getPoster(clip);
      }

      if (clip.duration) {
        durations.push(clip.duration);
      }
    }

    // console.log(`${PAGE} getPlaylistDetails durations:`, durations);
    this.duration = Utils.getTotalDuration(durations);
    // console.log(`${PAGE} getPlaylistDetails duration:`, this.duration);

    this.pathDeets = `${playlist.length} Clip${playlist.length > 1 ? 's' : ''}`; // @ ${length}`;

    // this.emitLoaded();

    return true;
  }

  /**
   * @deprecated
   *  dev version with clips returned, now v1 Stack
   */
  private getPlaylistDetailsFromClips(playlist: Clip[]) {
    const durations: Array<string> = [],
      allClipTags: Array<string> = [];
    // maxTagsPerClip = 3;

    // v3 use stack instead of clip[]
    // stack.poster
    // stack.tags, etc

    // v2 order by clip flow, reducing duplicates
    for (const clip of playlist) {
      // console.log(`${PAGE} getPlaylistDetails clip:`, clip);
      // let itemTags: string[] = [], // skip the bunding by clip (v1)
      const clipTags = getClipTaxNameArray(clip);
      // console.log(`${PAGE} getPlaylistDetails clipTags:`, clipTags);
      for (const clipTag of clipTags) {
        const indexOfTag = allClipTags.findIndex((i) => i === clipTag);
        if (indexOfTag < 0) {
          allClipTags.push(clipTag);
          // skip the bunding by clip (v1)
          // if (itemTags.length <= maxTagsPerClip) {
          //   itemTags.push(clipTag);
          // }
        }
      }

      // skip the bunding by clip (v1)
      // items.push({
      //   poster: this.clipService.getPoster(clip),
      //   tags: itemTags
      // });

      if (!this.poster) {
        this.poster = this.clipService.getPoster(clip);
      }

      if (clip.duration) {
        durations.push(clip.duration);
      }
    }

    this.tags = allClipTags;

    // console.log(`${PAGE} getPlaylistDetails durations:`, durations);
    this.duration = Utils.getTotalDuration(durations);
    // console.log(`${PAGE} getPlaylistDetails duration:`, this.duration);

    this.pathDeets = `${playlist.length} Clip${playlist.length > 1 ? 's' : ''}`; // @ ${length}`;

    // this.emitLoaded();
  }
}
