/** @format */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ClipperSegments } from '../clipper.component';
import { ENABLE_FEATURE_SPLIT_CLIPS, ENABLE_FEATURE_TRIM_CLIPS } from '@app/app.config';

@Component({
  selector: 'app-clipper-segment-bar',
  templateUrl: './clipper-segment-bar.component.html',
  styleUrls: ['./clipper-segment-bar.component.scss'],
})
export class ClipperSegmentBarComponent {
  @Input() showBackButton: boolean = false;
  @Input() selectedSegment: ClipperSegments = ClipperSegments.Settings;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelectSegment = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDismiss = new EventEmitter<void>();

  public enableTrimmer = ENABLE_FEATURE_TRIM_CLIPS;
  public enableSplitter = ENABLE_FEATURE_SPLIT_CLIPS;

  public segmentNames = ClipperSegments;

  segmentChanged(event) {
    if (event && event.detail && event.detail.value) {
      this.onSelectSegment.emit(event.detail.value);
    } else {
      console.warn(`[ClipperSegmentBar] segmentChanged NO detail.value?:`, event);
    }
  }
}
