/** @format */

import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, Event as RouterEvents } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'fs-tab-fab-container',
  templateUrl: './tab-fab-container.component.html',
})
export class TabFabContainerComponent implements OnDestroy, AfterViewInit {
  @ViewChild('portalContent') portalContent: TemplateRef<unknown>;

  outlet: DomPortalOutlet;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  portal: TemplatePortal<any>;

  initialUrl = this.router.url;

  private subscriptions = new Subscription();

  constructor(private _viewContainerRef: ViewContainerRef, private router: Router) {}

  ngAfterViewInit() {
    this.attachPortal();
    // watch over route changes to detach or re-attach portals.
    // reason: if a route has a defined portal in its template and the user routes away from it,
    // that route only gets detached from the change detection. thus it will stay in the DOM, no update logic.
    // that is why we work on the update logic manually ourselves.
    const subscription = this.router.events
      .pipe(
        filter((e: Event | RouterEvent | RouterEvents): e is RouterEvent => e instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((route: RouterEvent) => {
        if (route.url === this.initialUrl) {
          // for change detection reasons we need to delay this operation
          setTimeout(() => {
            this.attachPortal();
          });
        }
        if (this.outlet.hasAttached()) {
          this.outlet.detach();
        }
      });
    this.subscriptions.add(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private attachPortal() {
    this.outlet = new DomPortalOutlet(document.querySelector('#tab-portal-container'));
    this.portal = new TemplatePortal(this.portalContent, this._viewContainerRef);
    this.outlet.attachTemplatePortal(this.portal);
  }
}
