/** @format */

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { HOMEPAGE } from '@app/app.config';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.page.html',
  styleUrls: ['./privacy.page.scss'],
})
export class PrivacyPage implements OnInit {
  title: string = '';
  @Input() isModal: boolean = false;
  @Input() allowClickHome: boolean = true;

  constructor(private navCtrl: NavController, private viewCtrl: ModalController, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.pipe(
      tap((params: ParamMap) => {
        // eslint-disable-next-line eqeqeq
        this.isModal = params.get('isModal') && params.get('isModal') == 'true' ? true : this.isModal;
      })
    );
  }

  openHomePage() {
    this.dismiss();
    if (this.allowClickHome) {
      this.navCtrl.navigateForward(`/${HOMEPAGE}`);
    }
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
