import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ComponentRef,
} from "@angular/core";
import { Stack } from "@shared/models/stack.model";
import { Project, ProjectMember } from "@projects/shared/project.model";
import * as types from "./index";
import { DiscoverCardClick, ITEM_TYPE } from "@shared/models/layout.model";
import { User } from "@shared/models/user.model";
import { Clip } from "@shared/models/clip.model";

@Component({
  selector: "fs-slide",
  template: ` <ng-template #slide></ng-template> `,
  styleUrls: ["./fs-slide.component.scss"],
})
export class FsSlideComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: Partial<Clip> | Partial<Stack> | Partial<Project> | Partial<ProjectMember> | Partial<User> | null;
  @Input() itemType: ITEM_TYPE;
  @Input() currentUsername: string;
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;
  @Input() canShare = true;
  @Input() showAvatar = true;
  @Input() isProjectMember = false;
  @Input() isEvent = false;
  // set canModerate(bool: boolean) {
  //   // not getting pciked up fro new slides ?
  //   if (this.isStackSlide) { // ProjectSlide does not have/need this Input prop
  //     this.updateInstanceInputs('canModerate', bool);
  //   }
  // }
  @Output() itemClick = new EventEmitter<DiscoverCardClick>();
  
  @ViewChild("slide", { static: true, read: ViewContainerRef })
  private slide: ViewContainerRef;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private componentRef: ComponentRef<any>;
  private isStackSlide = false;

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const {
          currentValue,
          firstChange,
          previousValue,
        } = changes[propName];

        if (firstChange || currentValue !== previousValue) {
          this.updateInstanceInputs(propName, currentValue)
        }
      }
    }
  }

  ngOnInit() {
    /**
     * Should have a way to get component type dynamically
     * 2020-07-01 JD Fix Error "No component factory found for undefined" (finally applied 2021-04-01 with warn return)
     */
    if (this.itemType) {
      const className = this.getComponentName(this.itemType);
      // console.log("Class name: ", className);

      if (!types[className]) {
        console.warn(`[fs-slide] Type '${className}' NOT FOUND: `, types);
        return;
      }

      this.isStackSlide = className === 'StackSlideComponent';
      
      /**
       * @deprecated note in lint:
       * Angular no longer requires component factories to dynamically create components. 
       * Use different signature of the createComponent method, which allows passing Component class directly.
       * 
       * Note: since v13, dynamic component creation via ViewContainerRef.createComponent does not require resolving component factory: component class can be used directly
       * @see https://dev.to/railsstudent/dynamically-load-components-in-angular-13-5474
       * @see https://stackoverflow.com/questions/70946038/replace-deprecated-angular-componentfactoryresolver-componentfactory
       * 
       * 2026-06-27 jd fixed based on links above
       * 
       */
      this.componentRef = this.slide.createComponent(types[className]);
      // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      //   types[className]
      // );
      // this.componentRef = this.slide.createComponent(componentFactory);
      this.componentRef.instance.item = this.item;
      this.componentRef.instance.currentUsername = this.currentUsername;
      if (this.isStackSlide) { // ProjectSlide does not have/need this Input prop
        this.componentRef.instance.isModerated = this.isModerated;
        // how to allow updates after init? in setter?
        this.componentRef.instance.canModerate = this.canModerate;
        this.componentRef.instance.canShare = this.canShare;
        this.componentRef.instance.isProjectMember = this.isProjectMember;
      }
      if (this.isStackSlide || className === 'ClipSlideComponent') {
        this.componentRef.instance.showAvatar = this.showAvatar;
      }
      if (className === 'ProjectSlideComponent') {
        this.componentRef.instance.isEvent = this.isEvent;
      }
      // connect the Outputs too, apparently..
      this.componentRef.instance.itemClick = this.itemClick;
    }
  }

  updateInstanceInputs(key, val) {
    // this.componentRef.instance.currentUsername = this.currentUsername;
    // not getting picked up for new slides ?
    if (this.componentRef && this.componentRef.instance) {
      if (key === 'isModerated' || key === 'canModerate' ) {
        // ProjectSlide does not have/need this Input prop
        if (this.isStackSlide) { 
          this.componentRef.instance[key] = val;
        }
      } else {
        this.componentRef.instance[key] = val;
      }
    }
  }

  getComponentName(name: string) {
    return (
      name.charAt(0).toUpperCase() +
      name.toLowerCase().slice(1, -1) +
      "SlideComponent"
    );
  }

  ngOnDestroy() {
    if (this.componentRef && typeof this.componentRef.destroy === 'function') { // hotfix(Sentry-22): Cannot read property 'destroy' of undefined
      this.componentRef.destroy();
    }
  }
}
