/** @format */

import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { selectMyStack } from '@store/selectors/mystack.selectors';
import { selectMyProjects } from '@store/selectors/projects.selectors';
import { Project } from '@projects/shared/project.model';
import { MyStackService } from '@services/mystack.service';
import { ProjectService } from '@projects/shared/services';
import { Utils } from '@shared/utils';
import { DEFAULT_STACK_POSTER } from '@services/publish-stack.service';
import { UserService } from '@services/user.service';
import { STACK_PRIVACY, Stack } from '@shared/models/stack.model';
import { getDoneTranscoding } from '@shared/models/clip.model';
import { ClipsCoreService } from '@services/clips.service';
import { ModalController } from '@ionic/angular';
import { ALL_TAGS } from '@app/modules/tagger-input/tags';
import { PosterImageSelectorComponent } from '@stacks/poster-image-selector/poster-image-selector.component';
import { TranslateService } from '@ngx-translate/core';

const DEBUG_LOGS = false;
const PAGE = '[StackFormDetails]';

@Component({
  selector: 'app-stack-form-details',
  templateUrl: './stack-form-details.component.html',
  styleUrls: ['./stack-form-details.component.scss'],
})
export class StackFormDetails implements OnInit, OnDestroy {
  @Input() isSaving = false;
  @Input() isPublishing = false;

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<Partial<Stack>>();
  @Output() isFormSaving = new EventEmitter<boolean>();
  @Output() publish = new EventEmitter<Partial<Stack>>();

  /** @todo reenable tags with refactor */
  enableTags = false;
  allTagItems = ALL_TAGS;
  stackForm = this.formBuilder.group({
    poster: [DEFAULT_STACK_POSTER],
    title: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(64)])],
    description: ['', Validators.compose([Validators.maxLength(360)])],
    // change from private<boolean> to privacy enum
    // isPublic: [true],
    privacy: [STACK_PRIVACY.PUBLIC],
    projectId: ['', Validators.required],
    clips: this.formBuilder.array([]),
    tags: this.formBuilder.array([]),
  });

  projectOptions = [];
  myProjects$ = this.store.select(selectMyProjects);
  /** @todo this will change to be the stackById from store */
  stack$ = this.store.select(selectMyStack);
  stackId = '';

  userId$ = this.userService.userId$;
  isLoggedIn$ = this.userService.isLoggedIn$;

  errorMsg = '';
  hasClips = false;
  hasClipsTranscoding = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private modalCtrl: ModalController,
    private userService: UserService,
    private projectService: ProjectService,
    private clipService: ClipsCoreService,
    private formBuilder: UntypedFormBuilder,
    private mystackService: MyStackService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    // we need to disable the stackForm.projectId, since we are not currently able to change that
    // this.stackForm.get('projectId').disable();

    /**
     * watch mystack and update form when it's updated
     * @see publish-stack.page
     */
    this.stack$.pipe(takeUntil(this.onDestroy$)).subscribe((stack: Stack) => {
      if (!stack) return;

      this.stackId = stack.stackId ? stack.stackId : '';

      const clips = Array.isArray(stack.clips) ? stack.clips : [];
      this.stackForm.setControl('clips', this.formBuilder.array(clips));
      // check if there's transcoding clips
      this.hasClipsTranscoding = clips.some((clip) => !getDoneTranscoding(clip));
      this.hasClips = clips.some((clip) => getDoneTranscoding(clip));

      let poster = DEFAULT_STACK_POSTER;
      if (stack && stack.poster && stack.poster !== DEFAULT_STACK_POSTER) {
        poster = stack.poster;
      } else if (stack && (!stack.poster || stack.poster === DEFAULT_STACK_POSTER)) {
        // get the first clip poster that is not the default, to pre-select the stack poster
        const firstClipWithPoster = (Array.isArray(stack.clips) ? stack.clips : []).find(
          (clip) => clip && clip.poster && clip.poster !== DEFAULT_STACK_POSTER
        );
        if (firstClipWithPoster) {
          poster = firstClipWithPoster.poster;
        }
      }

      // consider the collab drafts content
      if (stack.isCollaborative && !stack.description) {
        // MVP-1335 : do not set description to be collab-instructions - confusing, reverted
        // this.translate.get('STACK.COLLAB_DEFAULT_STACK_DESC').pipe(take(1)).subscribe((res) => {
        this.stackForm.patchValue({
          title: stack.title,
          privacy: stack.privacy,
          poster,
          // description: res
        });
        // });
      } else if (stack.description) {
        this.stackForm.patchValue({
          title: stack.title,
          privacy: stack.privacy,
          // isPublic: !stack.private,
          poster,
          description: stack.description,
        });
      } else {
        this.stackForm.patchValue({
          title: stack.title,
          privacy: stack.privacy,
          // isPublic: !stack.private,
          poster,
        });
      }

      /**
       * it appears to matter if we set projectId to an empty string, since that is not a valid option
       * if we do, the set/patchValue in combineLatest, below, does not update the UI
       */
      if (stack.projectId) {
        // build project options
        this.projectService
          .getProjectsFromStoreByIds([stack.projectId])
          .pipe(take(1))
          .subscribe((projects: Project[]) => {
            this.projectOptions =
              projects.length > 0 && projects[0].id
                ? [
                    {
                      text: projects[0].title,
                      value: projects[0].id,
                    },
                  ]
                : [
                    {
                      text: 'Unknown Project?',
                      value: -1,
                    },
                  ];

            if (this.stackForm.get('projectId').value !== stack.projectId) {
              this.stackForm.patchValue({
                projectId: stack.projectId,
              });
            }
          });
      } else {
        // console.warn(`Did not set up the project dropdown!`);
        // this happens when the stack is published and reset...
      }

      /**
       * get the tags currently in the formControl
       * if there are none, add the ones from the stack
       * @todo fix this is persisting the previous stack tags with new ones..
       */
      // const currentTags = this.stackForm.get('tags').value;
      // if (!Array.isArray(currentTags) || currentTags.length < 1) {
      //   const tags = this.formBuilder.array(this.getTagsArray(stack));
      //   this.stackForm.setControl('tags', tags);
      // }
      if (Array.isArray(stack.tags) && stack.tags.length > 0) {
        this.stackForm.patchValue({
          tags: stack.tags,
        });
      } else if (!Array.isArray(this.stackForm.get('tags').value) || this.stackForm.get('tags').value.length > 0) {
        // reset it
        this.stackForm.patchValue({
          tags: this.formBuilder.array([]),
        });
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getFormValues() {
    return {
      stackId: this.stackId,
      title: this.stackForm.get('title').value,
      description: this.stackForm.get('description').value,
      // isPublic: this.stackForm.get('isPublic').value,
      privacy: this.stackForm.get('privacy').value,
      projectId: this.stackForm.get('projectId').value,
      /** clips needs updated when re-arranging */
      clips: this.stackForm.get('clips').value,
      poster: this.stackForm.get('poster').value,
      tags: (this.stackForm.get('tags').value || []).reduce((acc, item) => {
        acc.push(item.value);
        return acc;
      }, []),
    };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _loadingTimeout;
  showSaving(timeout = 900) {
    this.isSaving = true;
    this.isFormSaving.emit(true);
    clearTimeout(this._loadingTimeout);
    this._loadingTimeout = setTimeout(() => {
      // just making it show loading for a bit, since we only dispatched actions here
      this.isSaving = false;
      this.isFormSaving.emit(false);
    }, timeout);
  }

  /**
   * Form submit handler
   */
  publishStack() {
    // check poster not set, prompt
    const poster = this.stackForm.get('poster').value;
    if (!poster || poster === DEFAULT_STACK_POSTER) {
      this.stack$.pipe(take(1)).subscribe((stack) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.openPosterSelector({}, stack).then((selectedPoster) => {
          this.publish.emit(this.getFormValues());
        });
      });
    } else {
      this.publish.emit(this.getFormValues());
    }
  }

  // resetMyStack() {
  //   this.mystackService.resetMyStack();
  //   this.stackForm.reset();
  //   // reset stackForm will be done on mystackService.resetMyStack action in stack$.pipe
  // }

  async openPosterSelector(event, stack: Stack) {
    Utils.tryStopPropagation(event);

    if (!stack || !Array.isArray(stack.clips) || stack.clips.length < 1) {
      return;
    }
    const posters = stack.clips
      .map((clip) => this.clipService.getPoster(clip, 'high'))
      .filter((clip, index, clips) => clips.indexOf(clip) === index);
    const currentPoster = this.stackForm.get('poster').value;
    const modal = await this.modalCtrl.create({
      component: PosterImageSelectorComponent,
      componentProps: {
        posters,
        currentPoster,
      },
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data && data.selected) {
      this.stackForm.patchValue({ poster: data.selected });
      this.mystackService.updatePoster(data.selected); // update the poster to save it on refresh
      return data.selected;
    }
    return '';
  }

  /**
   * @todo update the mystack store on change
   */
  onTagRemoved(item: string) {
    let stackTags = this.stackForm.get('tags').value;
    stackTags = Utils.removeFromArray(stackTags, item);
    this.stackForm.patchValue({ tags: stackTags });
    DEBUG_LOGS && console.log(`${PAGE} onTagItemRemoved: %o`, item, stackTags);
  }

  /**
   * @todo update the mystack store on change
   */
  onTagAdded(item: string) {
    const stackTags = this.stackForm.get('tags').value;
    Utils.addToArrayUnique(stackTags, item.toLowerCase());
    DEBUG_LOGS && console.log(`${PAGE} onTagItemAdded: %o`, item, stackTags);
  }

  /**
   * MVP-1082 Feature: updates on change, persist, with loading state for buttons
   */
  onPropChanged(key, value) {
    this.stack$.pipe(take(1)).subscribe((stack) => {
      switch (key) {
        case 'title':
          if (typeof value === 'string' && value !== stack[key]) {
            DEBUG_LOGS && console.log(`onPropChanged`, { key, value });
            this.showSaving();
            this.mystackService.updateTitle(value);
          }
          break;
        case 'description':
          if (typeof value === 'string' && value !== stack[key]) {
            DEBUG_LOGS && console.log(`onPropChanged`, { key, value });
            this.showSaving();
            this.mystackService.updateDescription(value);
          }
          break;
        case 'privacy':
          DEBUG_LOGS && console.log(`onPropChanged`, { key, value, stack, stackVal: stack[key] });
          if (Object.values(STACK_PRIVACY).includes(value) && value !== stack[key]) {
            this.showSaving();
            this.mystackService.updatePrivacy(value);
          }
          break;
        // case 'isPublic':
        //   this.showSaving();
        //   key = 'private';
        //   // switching from isPublic to private..
        //   if (typeof value === 'string') {
        //     value = value === 'false';
        //   } else {
        //     value = !value;
        //   }
        //   if (typeof value === 'boolean' && value !== stack[key]) {
        //     DEBUG_LOGS && console.warn(`IGNORING onPropChanged`, { key, value, stack, stackVal: stack[key] });
        //     this.showSaving();
        //     // this.mystackService.updatePrivacy(value);
        //   }
        //   break;
        default:
          console.warn(`unhandled propChange: '${key}'`);
      }
    });
  }

  /**
   * @deprecated
   * This should no longer be necessary, as the save is happening on form changes..
   */
  private saveStack() {
    console.log(`saveStack`, { stackForm: this.stackForm });
    if (!this.stackForm.valid) {
      this.errorMsg = this.translate.instant('EDITOR.ERROR_FORM');
      return;
    }
    this.errorMsg = '';
    // this.showSaving(); // do this in parent
    this.save.emit(this.getFormValues());
  }
}
