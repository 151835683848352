/** @format */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectProject } from '@store/selectors/projects.selectors';
import { Clip } from '@shared/models/clip.model';
import { DEFAULT_USER_AVATAR } from '@shared/models/user.model';
import { Stack, STACK_DEFAULT_POSTER } from '@shared/models/stack.model';
import { ProjectMemberService } from '@members/shared/services';

const FALLBACK_IMG = '/assets/images/default-stack-poster-sm.png'; // appended to the style.background-image as second url, in case poster 404

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fs-thumbnail-poster',
  templateUrl: './thumbnail-poster.component.html',
  styleUrls: ['./thumbnail-poster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailPosterComponent implements OnInit {
  @Input() isPlaying = false;
  @Input() isCurrent = false;
  @Input() wasPlayed = false; // todo: handle addional actions like add to stack
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;
  @Input() currentUserId: string;
  @Input() showAvatar = false;
  @Input() showActionIcon = true;
  @Input() showDuration = true;
  @Input() canCollaborate = false;
  // @Input() posterFallback = FALLBACK_IMG;

  @Input() set item(value: Clip | Stack) {
    this._item = value;

    this.posterBgImg = value?.poster ? 'url(' + value.poster + ')' : 'url(' + this.posterFallback + ')';
  }
  get item(): Clip | Stack {
    return this._item;
  }

  posterFallback = FALLBACK_IMG;
  isMine = false;
  hasProvider = false;
  avatarUrl: string;
  avatarFallback = DEFAULT_USER_AVATAR;
  posterBgImg = 'url(' + this.posterFallback + ')'; //'url(' + item.poster + '), url(' + this.fallbackImg + ')';

  private _item: Clip | Stack;

  constructor(private store: Store, private memberService: ProjectMemberService) {}

  ngOnInit() {
    this.isMine = this.currentUserId === this.item.userId;
    if (this.showAvatar && this.item && this.item.userId) {
      this.avatarUrl = this.memberService.getUserAvatar(this.item.userId);
    }
    if (this.isClip(this.item) && (this.item.providerId || this.item.youtube_id)) {
      /**
       * @todo handle Vimeo too
       * Currently, hasProvider => logo-youtube
       */
      this.hasProvider = true;
    }
    if (this.isStack(this.item) && (!this.item.poster || this.item.poster === STACK_DEFAULT_POSTER)) {
      this.store
        .select(selectProject(this.item.projectId))
        .pipe(
          filter((p) => !!p?.id),
          take(1)
        )
        .subscribe((proj) => {
          if (proj.hero) {
            this.posterBgImg = 'url(' + proj.hero + ')';
          }
        });
    }
  }

  public isStack(item: Clip | Stack): item is Stack {
    return 'stackId' in item;
  }

  /**
   * if it's a youtube/vimeo clip there may not be sources or a source
   */
  public isClip(item: Clip | Stack): item is Clip {
    return 'source' in item || 'sources' in item || 'providerId' in item;
  }
}
