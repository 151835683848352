/** @format */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import { map, catchError, concatMap, withLatestFrom, tap, switchMap, filter, take, exhaustMap } from 'rxjs/operators';
import { ChargebeeApiService } from './api/chargebee-api.service';
import {
  loadSuccess as loadProjectsSuccess,
  setSubscriptionEvent as setProjectSubscriptionEvent,
} from '@store/actions/projects.actions';
import * as billingActions from './billing.actions';
import * as fromBilling from './billing.selectors';
import { State } from '@store/reducers';
import { getUserBillingInfo } from '@store/selectors/user.selectors';
import { SentryService } from '@services/analytics/sentry.service';
import {
  ChargebeeSubscription,
  BillingSubscription,
  SubscriptionStatus,
  getEventSubscriptions,
  ChargebeeCustomer,
  ChargebeeEndpointResult,
} from '@billing/shared/billing.model';
import { ChargebeeService } from '@billing/shared/services/chargebee.service';
import { ProjectGroup, selectMyProjects } from '@store/selectors/projects.selectors';
import { Project } from '@projects/shared/project.model';

const DEBUG_LOGS = false;
const PAGE = '[BillingEffects]';

interface ProjectUpdates {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventConfig?: any;
  eventDate?: string;
  eventIsActive?: boolean;
  eventType?: string;
  subscriptionId?: string;
  subscriptionLevel?: string;
  subscriptionStatus?: number;
  subscriptionMinutes?: number;
}

@Injectable()
export class BillingEffects {
  loadPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(billingActions.loadPlans),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(fromBilling.selectBilling)))),
      tap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} loadPlans$ pre-filter`, { action, state });
      }),
      filter(([action, state]) => state && state.plans.length < 1),
      switchMap(([action, state]) =>
        from(this.chargebeeService.getPlans()).pipe(
          map(({ plans, addons }) => billingActions.loadPlansSuccess({ plans, addons })),
          catchError((err) => {
            console.warn(err);
            return EMPTY;
          })
        )
      )
      // ), { dispatch: false });
    )
  );

  loadCustomerSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(billingActions.loadCustomerSubscriptions),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(fromBilling.selectBilling))
          // withLatestFrom(this.store$.select(ViewstateSelectors.selectEntities)),
        )
      ),
      tap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} loadCustomerSubscriptions$ pre-filter`, { action, state });
      }),
      filter(([{ userId }, state]) => userId && userId.length > 0),
      // Get Chargebee User and Subscriptions of current loggedIn User, if exists
      switchMap(([{ userId }, state]) =>
        this.chargebeeApi.getUserSubscriptions(userId).pipe(
          switchMap((existing: ChargebeeEndpointResult[]) => {
            // DEBUG_LOGS && console.log(`${PAGE} loadCustomerSubscriptions$ getUserSubscriptions res`, { existing });
            if (!Array.isArray(existing) || (Array.isArray(existing) && existing.length < 1)) {
              // no subscriptions, let's check for customer data
              return this.chargebeeApi.getUser(userId).pipe(
                map((res) => {
                  if (!res || !res['customer']) {
                    // chargebee user resource_not_found - done Loading!
                    this.store$.dispatch(billingActions.doneLoadingNoUser());
                    return false;
                  }
                  return res['customer'] as ChargebeeCustomer;
                }),
                filter((customer) => !!customer),
                map((customer) => customer as ChargebeeCustomer),
                map((customer) => [{ customer }])
              );
            }
            return of(existing);
          }),
          filter((existing) => Array.isArray(existing) && existing.length > 0),
          map((existing: ChargebeeEndpointResult[]) => {
            // assume that for any number of items, the customer will be the same (existing[0].customer)
            const customer = existing.find((exist) => exist && exist.customer)?.customer;
            
            // clear the info we don't need
            /* eslint-disable @typescript-eslint/no-explicit-any*/
            delete (customer as any).payment_method;
            delete (customer as any).billing_address

            const subscriptions: ChargebeeSubscription[] = existing
              .filter((item) => item?.subscription?.id)
              .map((item) => {
                
                delete (item.subscription as any).shipping_address;
                return item.subscription
              });
            /* eslint-enable @typescript-eslint/no-explicit-any*/
            DEBUG_LOGS && console.log(`${PAGE} existing getUserSubscriptions:`, { customer, subscriptions });
            return billingActions.loadCustomerSubscriptionsSuccess({ customer, subscriptions });
          }),
          catchError((err) => {
            console.warn(err);
            return EMPTY;
          })
        )
      )
      // ), { dispatch: false });
    )
  );

  /**
   * OnSuccessLoad, validate that any of my projects have the correct & current config
   * (validate webhooks didn't miss anything done in the console)
   * (done in reducer: determine SubscriptionLevel for crew on projects with active events)
   */
  loadCustomerSubscriptionsSuccessProjectCheckDB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(billingActions.loadCustomerSubscriptionsSuccess),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(fromBilling.selectBilling)),
          withLatestFrom(this.store$.select(selectMyProjects))
        )
      ),
      map(([[action, state], myProjects]) => ({
        action,
        state,
        myProjects,
        ...this.checkProjectEventStatus(state, myProjects),
      })),
      // update the boolean flag to allow for doDbUpdate
      tap(() => (this._didReceiveSubscriptions = true)),
      filter(({ updates }) => updates.length > 0),
      exhaustMap(({ action, state, myProjects, myProjectsActiveSubs, cbEventSubs, updates }) => {
        const { doDbUpdate } = this.checkForDbUpdate(updates);
        // here do we have the project, or how to catch it not existing?

        DEBUG_LOGS &&
          console.log(
            `${PAGE} loadCustomerSubscriptionsSuccessProjectCheck compare with activeSubs -> Update Projects`,
            {
              myProjectsActiveSubs,
              cbEventSubs,
              updates,
              doDbUpdate,
            }
          );
        return updates.map((update) => setProjectSubscriptionEvent({ ...update, doDbUpdate }));
      }),
      catchError((err) => {
        console.warn(err);
        return EMPTY;
      })
      // ), { dispatch: false });
    )
  );

  /**
   * on load MY Projects Success, inspect them to validate against BillingStore
   * @note this will likely happen before the billingState has been set so await state.loaded in filter
   */
  loadMyProjectsSuccessCheckDB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjectsSuccess),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(fromBilling.selectBilling)),
          withLatestFrom(this.store$.select(selectMyProjects))
        )
      ),
      // wait for billing state to be loaded, otherwise we'd get wrong comparisons
      filter(([[action, state], myProjects]) => action?.listId === ProjectGroup.Mine && state.loaded),
      map(([[action, state], myProjects]) => ({
        action,
        state,
        myProjects,
        // check the new projects (we've already inspected the current myProjects)
        ...this.checkProjectEventStatus(state, myProjects),
      })),
      filter(({ updates }) => updates.length > 0),
      exhaustMap(({ action, state, myProjects, myProjectsActiveSubs, cbEventSubs, updates }) => {
        const { doDbUpdate } = this.checkForDbUpdate(updates);
        DEBUG_LOGS &&
          console.log(`${PAGE} loadMyProjectsSuccessCheckDB$ compare with activeSubs -> Update Projects`, {
            myProjectsActiveSubs,
            cbEventSubs,
            updates,
            action,
            doDbUpdate,
          });
        return updates.map((update) => setProjectSubscriptionEvent({ ...update, doDbUpdate }));
      }),
      catchError((err) => {
        console.warn(err);
        return EMPTY;
      })
      // ), { dispatch: false });
    )
  );

  createCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(billingActions.createCustomer),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(fromBilling.selectBilling)),
          withLatestFrom(this.store$.select(getUserBillingInfo))
        )
      ),
      map(([[action, state], { userId, name, email }]) => ({ action, state, userId, name, email })),
      tap(({ action, state, userId, email, name }) => {
        DEBUG_LOGS && console.log(`${PAGE} createCustomer$ pre-filter`, { action, state, userId, email, name });
      }),
      filter(({ action, state, userId, email }) => userId && email && (!state.userId || state.userId === userId)),
      switchMap(({ action, state, userId, email, name }) =>
        this.chargebeeApi.createCustomer({ id: userId, email, first_name: name }).pipe(
          map((customer) => {
            if (customer && customer.id) {
              // handle customer existing -> get userWithSubs
              DEBUG_LOGS && console.log(`${PAGE} handle customer existing -> get userWithSubs:`, { customer });
              this.chargebeeApi.getUserSubscriptions(userId).pipe(
                take(1),
                filter((existing) => Array.isArray(existing) && existing.length > 0),
                map((existing) => {
                  // assume that for any number of items, the customer will be the same (existing[0].customer)
                  const existingCustomer = existing[0].customer;
                  const subscriptions: ChargebeeSubscription[] = existing.map((item) => item.subscription);
                  DEBUG_LOGS &&
                    console.log(`${PAGE} existing getUserSubscriptions:`, {
                      customer: existingCustomer,
                      subscriptions,
                    });
                  return billingActions.loadCustomerSubscriptionsSuccess({ customer: existingCustomer, subscriptions });
                })
              );
            }
            DEBUG_LOGS && console.log(`${PAGE} createCustomer:`, { customer });
            return billingActions.loadCustomerSubscriptionsSuccess({ customer, subscriptions: [] });
            // return customer
          }),
          catchError((error) => {
            console.warn(error);
            return EMPTY;
          })
        )
      )
      // ), { dispatch: false });
    )
  );

  /**
   * track if we already did the update, only do it once
   * either in loadMyProjectsSuccessCheckDB or loadCustomerSubscriptionsSuccessProjectCheck
   */
  private _didUpdateProjectIds: string[] = [];
  /**
   * verify if the subscriptions have been received
   * @see _didUpdateProjectIds
   */
  private _didReceiveSubscriptions = false;

  // private actions$: Actions<userActions.ActionsUnion>,
  constructor(
    private actions$: Actions,
    private chargebeeApi: ChargebeeApiService,
    private chargebeeService: ChargebeeService,
    private store$: Store<State>,
    private sentryService: SentryService
  ) {}

  captureError(error, msg = ''): void {
    const e = error && Array.isArray(error.errors) && error.errors.length > 0 ? error.errors[0] : error;
    console.warn(`[BillingEffects] ${msg} captureError`, e);
    this.sentryService.captureError(e);
  }

  /**
   * Check if we need to do DB Updates
   * loadCustomerSubscriptionsSuccessProjectCheck or
   */
  private checkForDbUpdate(updates: ProjectUpdates[]): { doDbUpdate: boolean } {
    if (!this._didReceiveSubscriptions) {
      DEBUG_LOGS &&
        console.log('checkForDbUpdate: we did not recieve subscriptions yet, we will wait for that before acting...');
      return {
        doDbUpdate: false,
      };
    }
    const projectsNotYetUpdated = updates.filter(
      (proj) => proj && proj.id && !this._didUpdateProjectIds.includes(proj.id)
    );
    if (projectsNotYetUpdated.length > 0) {
      // we have action to take
      const projectIdsNotYetUpdated = projectsNotYetUpdated.map((proj) => proj.id);
      DEBUG_LOGS &&
        console.log('checkForDbUpdate doDbUpdate:', {
          projectIdsNotYetUpdated,
          didUpdateProjectIds: [...this._didUpdateProjectIds],
        });
      this._didUpdateProjectIds = [...this._didUpdateProjectIds, ...projectIdsNotYetUpdated];
      return {
        doDbUpdate: true,
      };
    }
    DEBUG_LOGS && console.log('checkForDbUpdate - no doDbUpdate necessary...');
    return {
      doDbUpdate: false,
    };
  }

  /**
   * Check my projects to see if DB Needs Updated
   */
  private checkProjectEventStatus(
    state,
    projects
  ): {
    needsHandled: boolean;
    updates: ProjectUpdates[];
    myProjectsActiveSubs?: Project[];
    cbEventSubs?: BillingSubscription[];
  } {
    if (!state.loaded) {
      console.warn(`!state.loaded`, state);
      return {
        needsHandled: false,
        updates: [],
      };
    }
    // determine SubscriptionLevel for crew on projects with active events - done in reducer
    // do any of myprojects have a subscription BY ME?
    const myProjectsActiveSubs = projects.filter(
      (p) => p?.subscriptionStatus === SubscriptionStatus.Active && p?.subscriptionBy === state.userId
    );
    const cbEventSubs = getEventSubscriptions(state.chargebeeSubscriptions);
    // if myProject is not included in cb actives, then it should be made InActive
    const projMineShouldNotBe = myProjectsActiveSubs.filter(
      (proj) => proj?.id && !cbEventSubs.some((sub) => sub?.projectId === proj?.id)
    );
    const projCbActiveShouldBe = cbEventSubs.filter(
      (sub) => !myProjectsActiveSubs.some((proj) => proj?.id === sub.projectId)
    );
    // const needsHandled = projCbActiveShouldBe.length > 0 || projMineShouldNotBe.length > 0;
    const updates: ProjectUpdates[] = [];
    if (projMineShouldNotBe.length > 0) {
      // update the DB via updateProject action
      updates.push(
        ...projMineShouldNotBe.map((proj) => ({
          id: proj.id,
          subscriptionId: '',
          subscriptionStatus: SubscriptionStatus.InActive,
          eventIsActive: false,
          // subscriptionLevel
          // subscriptionMinutes
        }))
      );
    }
    if (projCbActiveShouldBe.length > 0) {
      // update the DB via updateProject action
      // first make sure there's a project.id - the project might have been deleted...
      updates.push(
        ...projCbActiveShouldBe.map((sub) => ({
          id: sub.projectId,
          subscriptionId: sub.id,
          subscriptionStatus: sub.status,
          subscriptionLevel: sub.level,
          eventDate: sub.eventDate,
          eventIsActive: true,
          // subscriptionMinutes
        }))
      );
    }
    if (updates.length > 0) {
      DEBUG_LOGS &&
        console.warn('inspect DB update', {
          updates,
          projCbActiveShouldBe,
          projMineShouldNotBe,
          myProjectsActiveSubs,
          cbEventSubs,
        });
    }
    return {
      needsHandled: updates.length > 0,
      updates,
      myProjectsActiveSubs,
      cbEventSubs,
    };
  }
}
