/** @format */

import { Component, OnDestroy, OnChanges, Input } from '@angular/core';
import { AlertController, RangeCustomEvent } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Clip, ClipVideoFile, convertVideoFileToClip } from '@app/shared/models/clip.model';
import { ToasterService } from '@app/core/services/toaster.service';
import { ClipsCoreService } from '@app/core/services/clips.service';
import { Utils } from '@app/shared/utils';
import { UpdateParam } from '@app/core/api/api-types';
import { MAX_VIDEO_CAPTURE_LENGTH_SECONDS, MAX_VIDEO_CAPTURE_LENGTH_TEXT } from '@app/app.config';
import { Project } from '@projects/shared/project.model';
import { ProjectService } from '@projects/shared/services/';
import { VideoPlayerService } from '@app/modules/video-player/shared/services/video-player.service';
// import { RangeValue } from '@ionic/core';

const DEBUG_LOGS = false;
const DEV_NO_SAVE = false; // stop the save process for dev'ing

/**
 * Feature: create a new clip if it's been used in a stack - in dev
 * for now, allow Trimming a Clip even if it was used, for flexibility
 */
const ENABLE_FEATURE_TRIM_USED = true;

const PAGE = '[Trimmer]'; // eslint-disable-line @typescript-eslint/no-unused-vars
/**
 * @todo C.3. Video preview shows the frame at the time of the handle you are dragging (start handle or end handle)
 * @todo D.1. When trimming handles dragged to desired location, video shows starting from left (starting) handle time
 */

@Component({
  selector: 'app-trimmer',
  templateUrl: './trimmer.component.html',
  styleUrls: ['./trimmer.component.scss'],
})
export class TrimmerComponent implements OnChanges, OnDestroy {
  @Input() currentUserId: string;
  @Input() video: ClipVideoFile;
  @Input() clip: Clip;
  @Input() project: Project;

  title: string = 'Trimmer';
  playerId = 'trimmer-player';

  isSaving: boolean = false;
  hasEditPermission: boolean = false;
  clipTrimLocked: boolean = false;
  usedInStacks: boolean = false;
  maxVideoLengthText = MAX_VIDEO_CAPTURE_LENGTH_TEXT;

  enableFeatureTrimUsed = ENABLE_FEATURE_TRIM_USED;

  rangeObject = { lower: 0, upper: 100 };

  playlist: Clip[] = [];
  rangeMin: number = 0;
  rangeMax: number = 100;
  minLabel: string = '';
  maxLabel: string = '';
  left: number = 0;
  right: number = 0;
  totalDuration: number = 0;

  private didTrimUsedAlert = false;
  private startTime: number = 0;
  private endTime: number = 0;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private alertCtrl: AlertController,
    private clipService: ClipsCoreService,
    private projectService: ProjectService,
    private toaster: ToasterService,
    private videoPlayerService: VideoPlayerService
  ) {}

  ngOnChanges() {
    this.getClipDetails();
  }

  getClipDetails() {
    if (!this.clip && !this.video) {
      return; // no clip yet
    } else if (!this.clip && this.video) {
      this.clip = convertVideoFileToClip(this.video);
    }

    if (this.clip.numStacks && this.clip.numStacks > 0) {
      this.usedInStacks = true;

      if (!this.enableFeatureTrimUsed && !this.didTrimUsedAlert) {
        this.didTrimUsedAlert = true;
        this.alertAlreadyUsed();
      }
    }

    this.checkCanEdit();

    DEBUG_LOGS && console.log(`${PAGE} getClipDetails`, { hasEditPermission: this.hasEditPermission, clip: this.clip });

    if (this.hasEditPermission) {
      if (!this.clip.id) {
        // clip has not been saved to DB yet, create temp id
        this.clip.id = `${this.currentUserId}__${Math.floor(Date.now() / 1000)}`;
      }

      if (this.clip.duration) {
        this.handleDuration();
      }
    }
  }

  checkCanEdit() {
    // if there's a clip and userId, check for canEdit permission
    if (this.currentUserId && this.clip && this.clip.userId && this.clip.userId === this.currentUserId) {
      this.hasEditPermission = true;
    } else if (this.projectService.isProjectAdmin(this.project, this.currentUserId)) {
      this.clipTrimLocked = typeof this.clip.isLocked === 'boolean' ? this.clip.isLocked : false;
      this.hasEditPermission = !this.clipTrimLocked;
    } else {
      this.hasEditPermission = false;
    }
  }

  /**
   * Form Submit
   */
  saveTrim() {
    this.isSaving = true;

    if (!this.trimValuesValid()) {
      this.isSaving = false;
      return;
    }

    const clipUpdates: UpdateParam[] = [];

    const startChanged = this.startTime && this.startTime !== this.clip.startTime,
      endChanged = this.endTime && this.endTime !== this.clip.endTime;

    if (!startChanged && !endChanged) {
      this.isSaving = false;
      this.toaster.present(`No changes to Save.`);
      return;
    }

    if (startChanged) {
      // this.clip.source.startTime = startTime; // only if we need to re-transcode
      this.clip.startTime = this.startTime;
      clipUpdates.push({
        prop: 'startTime',
        value: this.startTime,
      });
    }
    if (endChanged) {
      // this.clip.source.endTime = endTime; // only if we need to re-transcode
      this.clip.endTime = this.endTime;
      clipUpdates.push({
        prop: 'endTime',
        value: this.endTime,
      });
    }

    if (startChanged || endChanged) {
      clipUpdates.push({
        prop: 'duration',
        value: Utils.convertSecondsToDuration(Math.round(this.endTime - this.startTime)),
      });
    }

    if (DEV_NO_SAVE) {
      console.log(`${PAGE} updateClip(dev):`, { clip: this.clip, clipUpdates });
      setTimeout(() => {
        this.isSaving = false;
        this.toaster.present(`Feature in DEV - not saved. startTime=${this.startTime} endTime=${this.endTime}`);
      }, 1000);
      return;
    }

    console.log(`${PAGE} updateClip(dev):`, { clip: this.clip, clipUpdates });

    this.clipService
      .updateClip(this.clip, clipUpdates)
      .pipe(
        take(1),
        catchError((err) => {
          this.isSaving = false;
          console.warn('error in source. Details:', err);
          throw err;
        })
      )
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.toaster.present(`Clip Updated.`);
        },
        error: (err) => {
          this.isSaving = false;
          console.warn(err);
          this.toaster.present(`Oops, Clip not updated - please refresh and try again.`);
        },
      });
  }

  async alertAlreadyUsed() {
    const alert = await this.alertCtrl.create({
      header: `Unable to Trim`,
      subHeader: `This Clip has already been used in a published Filmstack, so we'll need to create a new Clip.`,
      message: `This feature is in Development, and is coming soon!`,
      buttons: ['Ok'],
    });
    return await alert.present();
  }

  async alertTooLong(secs) {
    let current = secs.toFixed(1); //Math.round(secs * 10) / 10; // one decimal place
    if (current === MAX_VIDEO_CAPTURE_LENGTH_SECONDS.toFixed(1)) {
      current = (secs + 0.1).toFixed(1);
    }
    const alert = await this.alertCtrl.create({
      header: `Filmstacker works best with shorter clips`,
      subHeader: `Max clip duration is ${MAX_VIDEO_CAPTURE_LENGTH_TEXT}, you're at ${current} seconds. <br><br>Please trim a bit more...`,
      buttons: ['Ok'],
    });
    return await alert.present();
  }

  handleDuration(duration = '00:00:00') {
    if (!this.clip) {
      console.warn(`${PAGE} handleDuration - No Clip?`, this.clip);
      return;
    }
    DEBUG_LOGS && console.log(`${PAGE} handleDuration (${duration}) clip.duration: ${this.clip.duration}`);
    if (duration && duration !== '00:00:00' && duration !== this.clip.duration) {
      this.clip.duration = duration;
    }
    if (!this.clip.duration) {
      this.clip.duration = '00:00:00';
    }

    let hour = 0;
    let minute = 0;
    let seconds = 0;
    const durations = this.clip.duration.split(':');
    switch (durations.length) {
      case 3: {
        hour = parseInt(durations[0], 10);
        minute = parseInt(durations[1], 10);
        seconds = parseInt(durations[2], 10);
        break;
      }
      case 2: {
        minute = parseInt(durations[0], 10);
        seconds = parseInt(durations[1], 10);
        break;
      }
      case 1: {
        console.log(`${PAGE} only seconds in the duration..`);
        seconds = parseInt(durations[0], 10);
        break;
      }
      default:
        console.warn(`${PAGE} no durations?`);
    }

    // set maximum range value

    if (this.clip.source && typeof this.clip.source.durationInSeconds === 'number') {
      this.rangeMax = this.scaleTimeIn(this.clip.source.durationInSeconds);
      this.totalDuration = this.clip.source.durationInSeconds;
    } else if (this.clip.source && this.clip.source.duration) {
      this.totalDuration = Utils.convertDurationToSeconds(this.clip.source.duration);
      this.rangeMax = this.scaleTimeIn(this.totalDuration);
    } else if (durations.length >= 1) {
      this.totalDuration = hour * 3600 + minute * 60 + seconds;
      this.rangeMax = this.scaleTimeIn(this.totalDuration);
    } else {
      console.warn(`${PAGE} unable to determine duration | rangeMax`, { durations, clip: this.clip });
      this.rangeMax = 0;
      this.totalDuration = 0;
    }

    if (this.clip.startTime !== null && !isNaN(this.clip.startTime) && typeof this.clip.startTime === 'string') {
      this.clip.startTime = parseFloat(this.clip.startTime);
    }
    if (this.clip.endTime !== null && !isNaN(this.clip.endTime) && typeof this.clip.endTime === 'string') {
      this.clip.endTime = parseFloat(this.clip.endTime);
    }

    this.startTime = typeof this.clip.startTime === 'number' && this.clip.startTime >= 0 ? this.clip.startTime : 0;
    this.endTime =
      typeof this.clip.endTime === 'number' && this.clip.endTime > 0 ? this.clip.endTime : this.totalDuration;

    // set range object
    this.rangeObject = { lower: this.scaleTimeIn(this.startTime), upper: this.scaleTimeIn(this.endTime) };

    // set range's label
    this.minLabel = Utils.convertSecondsToDuration(Math.round(this.startTime));
    this.maxLabel = hour > 0 ? this.clip.duration : `${durations[1]}:${durations[2]}`;

    DEBUG_LOGS &&
      console.log(`${PAGE} DEV handleDuration rangeMax parse with ms`, {
        durations,
        totalDuration: this.totalDuration,
        clipDuration: this.clip.duration,
        rangeMax: this.rangeMax,
        clip: this.clip,
        startTime: this.startTime,
        endTime: this.endTime,
        rangeObject: this.rangeObject,
      });

    this.videoPlayerService.seekTo(this.playerId, this.startTime, this.endTime);
  }

  /**
   * set video's duration as per range value changes
   * Debounced this event as elem attr
   */
  onRangeChange(event: Event) {
    const customEvent = event as RangeCustomEvent;
    this.videoPlayerService.pause(this.playerId);

    const newRangeObject = customEvent.detail.value;

    if (typeof newRangeObject == 'number') {
      /**
       * @todo during refactor of trimmer - validate this logic: will this ever be a number?
       *
       * Trim action needs dual knobs on range => {lower: number, upper: number}
       */
      // DEBUG_LOGS && console.log(`${PAGE} onRangeChange: `, { customEvent });
    } else {
      // scale back to seconds
      const lowerSecs = this.scaleTimeOut(newRangeObject.lower);
      const upperSecs = this.scaleTimeOut(newRangeObject.upper);

      // console.log(`${PAGE} getTrimDuration  DEV `, {upperSecs, upper: this.rangeObject.upper, rangeO: this.rangeObject});

      // set the range's label
      const lowerArray = [lowerSecs.toString()];
      const upperArray = [upperSecs.toString()];
      this.minLabel = Utils.getTotalDurationNoHours(lowerArray);
      this.maxLabel = Utils.getTotalDurationNoHours(upperArray);

      // change the poistion of label (%)
      // this.left = (lowerSecs * 100) / this.totalDuration;
      // this.right = 100 - (upperSecs * 100) / this.totalDuration;

      // set starttime and endtime of video
      this.startTime = lowerSecs;
      this.endTime = upperSecs;
      // DEBUG_LOGS && console.log(`${PAGE} onRangeChange Start time: ${this.startTime} End time: ${this.endTime}`);
      this.videoPlayerService.seekTo(this.playerId, this.startTime, this.endTime);
    }
  }

  // reset duration of video
  resetDuration() {
    this.rangeObject = { lower: 0, upper: this.rangeMax };
    this.startTime = 0;
    this.endTime = this.rangeMax;
  }

  timeTinyScrub(event, knob: 'start' | 'end', dir: 'less' | 'more') {
    if (!event || !event.value) return;
    // DEBUG_LOGS && console.log(`${PAGE} timeTinyScrub val: ${event.value}`, { knob, dir, event });
    let lower: number, upper: number;
    if (knob === 'start' && typeof event.value.lower === 'number') {
      const val: number = event.value.lower;
      switch (dir) {
        case 'more': {
          lower = val + 30;
          break;
        }
        case 'less':
        default:
          lower = val - 30 >= 0 ? val - 30 : 0;
      }
      this.rangeObject = {
        lower,
        upper: event.value.upper >= lower ? event.value.upper : lower + 30,
      };
    } else if (knob === 'end' && typeof event.value.upper === 'number') {
      const val: number = event.value.upper;
      switch (dir) {
        case 'more': {
          upper = val + 30 < this.rangeMax ? val + 30 : this.rangeMax;
          break;
        }
        case 'less':
        default:
          upper = val - 30 > event.value.lower ? val - 30 : event.value.lower + 30;
      }
      this.rangeObject = {
        lower: event.value.lower,
        upper,
      };
    } else {
      console.warn(`${PAGE} timeTinyScrub UNHANDLED val: ${event.value}`, { knob, dir, event });
    }

    // DEBUG_LOGS && console.log(`${PAGE} timeTinyScrub result:`, { result: this.rangeObject, knob, dir, event });
  }

  // skipLeft(event) {
  //   if (!event || !event.value || typeof event.value.lower !== 'number') return;
  //   // DEBUG_LOGS && console.log(`${PAGE} startTimeTinyLess lower: ${event.value.lower} to: ${event.value.lower - 30}`, event);
  //   if (event.value.lower - 30 >= 0) {
  //     this.rangeObject = {
  //       lower: event.value.lower - 30,
  //       upper: event.value.upper,
  //     };
  //   }
  // }
  // skipRight(event) {
  //   if (!event || !event.value || typeof event.value.upper !== 'number') return;
  //   // DEBUG_LOGS && console.log(`${PAGE} skipRight upper: ${event.value.upper} to: ${event.value.upper + 30} rangeMax: ${this.rangeMax}`, event);
  //   if (event.value.upper + 30 <= this.rangeMax) {
  //     this.rangeObject = {
  //       lower: event.value.lower,
  //       upper: event.value.upper + 30,
  //     };
  //   }
  // }

  onLoadedMetadata = (event) => {
    if (event && event.playerId && event.playerId === this.playerId) {
      DEBUG_LOGS && console.log(`${PAGE} onLoadedMetadata... ${event.playerId}`, event);

      if (event.payload && typeof event.payload.duration === 'number' && event.payload.duration > 0) {
        if (!this.clip.source) {
          this.clip.source = {};
        }
        this.clip.source.durationInSeconds = event.payload.duration;
        this.handleDuration(Utils.convertSecondsToDuration(event.payload.duration));
      }
    }
  };

  ngOnDestroy() {
    this.videoPlayerService.pause(this.playerId);
    this.subscriptions.unsubscribe();
  }

  /**
   * Validate the form values
   */
  private trimValuesValid(): boolean {
    if (typeof this.endTime !== 'number') {
      console.warn(`${PAGE} endTime NaN?`, this.endTime);
      this.endTime = 0;
    }
    if (typeof this.startTime !== 'number') {
      console.warn(`${PAGE} startTime NaN?`, this.startTime);
      this.startTime = 0;
    }
    if (this.endTime <= 0) {
      console.warn(`${PAGE} endTime ZERO?`, this.endTime);
      this.toaster.present(`Oops! endTime really should not be Zero... please reload and try again!`);
      // return false;
    }
    if (this.endTime - this.startTime > MAX_VIDEO_CAPTURE_LENGTH_SECONDS) {
      // TODO: endTime is not correct...
      DEBUG_LOGS &&
        console.log(`${PAGE} DEV ENDTIME OK? getTrimValues`, {
          endTime: this.endTime,
          startTime: this.startTime,
          diff: this.endTime - this.startTime,
          maxDuration: MAX_VIDEO_CAPTURE_LENGTH_SECONDS,
          tooLong: this.endTime - this.startTime > MAX_VIDEO_CAPTURE_LENGTH_SECONDS,
        });

      this.alertTooLong(this.endTime - this.startTime);

      return false;
    }
    return true;
  }

  private scaleTimeIn(seconds: number): number {
    // convert to framerate
    // second=1000ms, so 24fps= 1000/24, milliseconds per frame = 41.667ms
    // for now, just ms:
    return seconds * 1000;
  }
  private scaleTimeOut(ms: number): number {
    // back to seconds
    return ms / 1000;
  }
}
