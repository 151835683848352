/**
 * Config
 *
 * @author: jd@filmstacker.com
 * @format
 */
/* eslint-disable @typescript-eslint/no-inferrable-types */

// HARD DISABLE for topnav
export const ENABLE_SIGNUP: boolean = true;

export const HOMEPAGE: string = 'stack/home';
export const STARTPAGE: string = 'home'; //'project';//'home'; // change from home when deving on home page html
export const STARTPAGE_PARAMS: { [id: string]: string } = { id: 'filmstacker' }; // only needed if 'project' STARTPAGE

export const GUIDES_PROJECT: string = 'filmstacker-guides';

export const HIDE_CAPTURE_TAB_UNAUTHED: boolean = false; // TODO: verify the active index when this is changed post-render
export const SHOW_CAPTURE_TAB: boolean = true; // allow it to be shown
// export const SHOW_PUBLISH_TAB: boolean = false; // hide in UI always

// features
export const ENABLE_BILLING: boolean = true;
export const ENABLE_BILLING_MINUTES: boolean = false;
/** Show Trending on ProjectDetail pages (current feature is only shown on DiscoverPage, globally) */
export const ENABLE_TRENDING_STACKS: boolean = false;
export const ENABLE_EXPLORE: boolean = false;
export const ENABLE_MULTI_UPLOADS: boolean = true;
export const ENABLE_AUDIO_EDITOR: boolean = false;
export const ENABLE_FEATURE_WATCH_LATER: boolean = false;
export const ENABLE_FEATURE_USER_MESSAGING: boolean = false;
export const ENABLE_FEATURE_USER_FOLLOW: boolean = false;
export const ENABLE_FEATURE_SPLIT_CLIPS: boolean = false;
export const ENABLE_FEATURE_TRIM_CLIPS: boolean = false;

// sharing
export const ENABLE_SHARE: boolean = true;
export const ENABLE_FACEBOOK: boolean = true;
export const ENABLE_TWITTER: boolean = true;
export const ENABLE_LINKEDIN: boolean = true;

export const ENABLE_CLIP_SHARE: boolean = false; // do not share Clips

// menu & topnav
export const SHOW_FEATURES: boolean = false;
export const SHOW_PLANS: boolean = false;
export const SHOW_TOUR: boolean = false;
export const SHOW_CAREERS: boolean = false;
export const SHOW_BRAND: boolean = false;
export const SHOW_SETTINGS: boolean = false;

export const SHOW_PROJECTS_IN_DISCOVER: boolean = true;

// capture
export const MAX_VIDEO_CAPTURE_LENGTH_MINUTES: number = 5; // 3 minutes
export const MAX_VIDEO_CAPTURE_LENGTH_SECONDS: number = MAX_VIDEO_CAPTURE_LENGTH_MINUTES * 60; // 3 minutes
/** @note this is now in en.json (CLIP.CAPTURE.LIMITS) - use that instead */
export const MAX_VIDEO_CAPTURE_LENGTH_TEXT: string = '5-minutes';
export const MAX_VIDEO_CAPTURE_SIZE_MB: number = 2000; // 2GB = Filestack Plan max
export const MAX_IMG_UPLOAD_SIZE_MB: number = 25;

/**
 * chars to keep of filename, to avoid failures in transcoding
 */
export const CLIP_TITLE_MAX_LENGTH_TRANSCODING = 32;

// export const TEXT_MESSAGE_ON_LOGOUT: string = 'You have Logged Out.'; // moved to i18/en.json

export const AVATAR_URL_PREFIX = 'https://content.filmstacker.com/public/avatars/';
