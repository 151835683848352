/** @format */

import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, EMPTY, iif, Observable, of, Subject, TimeoutError } from 'rxjs';
import {
  takeUntil,
  filter,
  take,
  catchError,
  map,
  tap,
  switchMap,
  delay,
  distinctUntilChanged,
  timeout,
} from 'rxjs/operators';
import {
  DEFAULT_PROJECT_POSTER,
  DEFAULT_PROJECT_POSTER_WEDDINGS,
  isViewableByUserId,
  Project,
  sortProjectTitleAlpha,
} from '@projects/shared/project.model';
import { User } from '@shared/models/user.model';
import { ProjectMember, PROJECT_MEMBER_ROLE } from '@members/shared/project-member.model';
import { UserService } from '@services/user.service';
import { ToasterService } from '@services/toaster.service';
import { ProjectMemberService } from '@members/shared/services/project-member.service';
import { ProjectService } from '@projects/shared/services';
import get from 'lodash/get';
import each from 'lodash/each';
import _uniqBy from 'lodash/uniqBy';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';

const DEBUG_LOGS = false;
/* timeout and continue if you have not finished loading projects, in ms */
const TIMEOUT_PROJECTS = 3000;

const PAGE = '[ProjectMemberProjects]';

@Component({
  selector: 'app-project-member-projects',
  templateUrl: './project-member-projects.component.html',
  styleUrls: ['./project-member-projects.component.scss'],
})
export class ProjectMemberProjectsComponent implements OnInit, OnDestroy {
  @Input() showMyRole = true;
  @Input() isStudio = false; // if my studio, add myProjects
  @Input() addMember = false; // if adding Member
  @Input() projects: Project[]; // if adding crew
  @Input() viewOnly = false;
  @Input()
  set member(value: User) {
    if (value && value.userId) {
      this._member$.next(value);
    }
  }
  // get member(): User {
  //   return this._member;
  // }

  @Output() closeModal = new EventEmitter<void>();
  @Output() roleChanged = new EventEmitter<{ role: string; project: Project }>();

  /**
   * if on studio
   */
  myProjects$: Observable<Project[]>;

  defaultRoleFormValue = 'Select';

  projectsForm: UntypedFormGroup;
  projectsControls: UntypedFormGroup[];

  doneLoading = false;
  isSaving = false;
  /** @see User.model.pending */
  isPendingUser = false;

  memberProjects: ProjectMember[] = [];
  /** @todo: how to change based on getProjectDefaultHeroUrl, pass as fn? */
  projectPosterFallback = DEFAULT_PROJECT_POSTER;
  

  /**
   * Improving logic for getStudioCrew with observables 2021-08-08
   */
  get member$(): Observable<User> {
    return this._member$.asObservable();
  }
  private _member$: BehaviorSubject<User> = new BehaviorSubject({ userId: null });

  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private navCtrl: NavController,
    private userService: UserService,
    private projectService: ProjectService,
    private toaster: ToasterService,
    private projectMemberService: ProjectMemberService
  ) {}

  ngOnInit() {
    if (this.isStudio) {
      this.defaultRoleFormValue = 'Add as';
      this.myProjects$ = this.projectService.getMyProjects();
    }

    this.watchMemberUpdates();

    if (this.addMember) {
      // default to crew for the add-member modal
      this.defaultRoleFormValue = 'CREW';
      this.userService.userId$.pipe(filter(Boolean), take(1), delay(300)).subscribe((currentUserId: string) => {
        DEBUG_LOGS && console.log(`${PAGE} addMember ${currentUserId} projects:`, this.projects);
        this.projects = (this.projects || []).sort(sortProjectTitleAlpha);
        this.buildForm({ projects: this.projects, currentUserId });
      });
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  watchMemberUpdates() {
    DEBUG_LOGS && console.log(`${PAGE} watchMemberUpdates... `, this._member$.getValue());
    // watch member subject for updates, if the keys that affect the form were updated, re-create the form
    const member$ = this.member$.pipe(
      filter((m) => m && m.loaded && Array.isArray(m.memberProjects)),
      // distinctUntilChanged((curr, prev) => curr.length === prev.length),
      // remove duplicates
      map((member) => ({ ...member, memberProjects: _uniqBy(member.memberProjects, 'projectId') })),
      map((member) => ({
        member,
        memberProjectIds: member.memberProjects.filter((p) => p && p.projectId).map((p) => p.projectId),
      })),
      tap(({ member, memberProjectIds }) => {
        // this.member = member; // setting this here causes a "Maximum call stack size exceeded error"...
        this.memberProjects = member.memberProjects;
        if (memberProjectIds.length < 1) {
          this.doneLoading = true;
        }
        this.isPendingUser = member?.pending ?? false;
        DEBUG_LOGS && console.log(`${PAGE} watchMemberUpdates tap member$ `, { memberProjectIds });
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(({ member, memberProjectIds }) => memberProjectIds.length > 0)
    );

    const studioProjects$ = iif(() => this.isStudio, this.myProjects$, of([]));

    let aFoundProjects = [];
    combineLatest([member$, this.userService.userId$, studioProjects$])
      .pipe(
        takeUntil(this.onDestroy$),
        map(([{ member, memberProjectIds }, currentUserId, myProjects]) => ({
          member,
          memberProjectIds,
          currentUserId,
          myProjects,
        })),
        switchMap(({ member, memberProjectIds, currentUserId, myProjects }) =>
          this.projectService.getProjectsFromStoreByIds(memberProjectIds).pipe(
            map((projects: Project[]) => {
              const foundProjects = (projects || []).filter((p) => p && p.id && p.id.length > 0);
              // save them for the end?
              if (foundProjects.length !== aFoundProjects.length) {
                aFoundProjects = foundProjects;
              }
              return {
                projects,
                foundProjects,
                member,
                memberProjectIds,
                currentUserId,
                myProjects,
              };
            }),
            timeout({
              first: TIMEOUT_PROJECTS,
              // with: (args) => throwError(() => args)
            }),
            distinctUntilChanged(({ foundProjects: curr }, { foundProjects: prev }) => curr.length === prev.length),
            // filter that they are all there ...what if some are private?...
            filter(({ projects, foundProjects }) => projects.length === foundProjects.length),
            catchError((err) => {
              if (err instanceof TimeoutError) {
                DEBUG_LOGS && console.log("timed out, let's use the ones we've found", { aFoundProjects, err });
                return of({
                  projects: aFoundProjects,
                  member,
                  myProjects,
                  currentUserId,
                });
              }
              console.warn('sth else happened', err);
              return EMPTY;
            }),
            // eslint-disable-next-line @typescript-eslint/no-shadow
            map(({ projects, member, currentUserId, myProjects }) => {
              DEBUG_LOGS &&
                console.log(
                  `watchMemberUpdates combineLatest projectService.getProjectsFromStoreByIds post-filter:`,
                  projects
                );
              return {
                member,
                projects: projects.filter((p) => isViewableByUserId(currentUserId, p)).sort(sortProjectTitleAlpha),
                myProjects,
                currentUserId,
              };
            })
          )
        )
        // ,tap((data) => DEBUG_LOGS && console.log(`watchMemberUpdates tap combineLatest post SwitchMap:`, data))
      )
      .subscribe(({ member, projects, currentUserId, myProjects }) => {
        DEBUG_LOGS && console.log('handleMemberUpdates res:', { member, projects, currentUserId, myProjects });
        this.projects = _uniqBy([...myProjects, ...projects], 'id');
        this.buildForm({ member, projects: this.projects, currentUserId });
      });
  }

  // /**
  //  * @deprecated using observable
  //  */
  // memberUpdated() {
  //   DEBUG_LOGS && console.log(`${PAGE} member updated`, this.member);
  //   // member input was updated, if the keys that affect the form were updated, re-create the form
  //   if (Array.isArray(this.member.memberProjects)) {
  //     const memberProjectIds = this.member.memberProjects.filter(p => p && p.projectId).map(p => p.projectId);
  //     if (memberProjectIds.length < 1) {
  //       this.doneLoading = true;
  //       return;
  //     }

  //     this.projectService.getProjectsFromStoreByIds(memberProjectIds).pipe(
  //       filter(Array.isArray),
  //       // filter that they are all there
  //       filter((projects: Project[]) => projects.length === projects.filter((p) => p && p.id && p.id.length > 0).length),
  //       take(1),
  //     ).subscribe((projects: Project[]) => {
  //       DEBUG_LOGS && console.warn(`${PAGE} projectsFromStore:`, { projects, memberProjectIds });
  //       this.projects = projects.sort(sortProjectTitleAlpha);
  //       this.buildForm();
  //     });
  //   }
  // }

  buildForm({
    projects = [],
    member = {},
    currentUserId = '',
  }: {
    projects: Project[];
    member?: Partial<User>;
    currentUserId: string;
  }) {
    if (!Array.isArray(projects)) {
      console.warn(`${PAGE} buildForm - projects !Array:`, projects);
      return;
    }

    const projectMemberForms = new UntypedFormArray([]);

    const getUserMemberRole = (userId, projMembers = []) => {
      const userMember = projMembers.find((item) => item.userId === userId);
      return userMember && userMember.role ? userMember.role : '';
    };

    for (const project of projects) {
      if (!project) {
        // one of the projects is not yet loaded, we need to skip this one for now, or wait longer
        DEBUG_LOGS && console.warn(`${PAGE} buildForm - missing project? (projects:`, projects);
        continue;
      }

      const projMembers = Array.isArray(project.members) ? project.members : [];

      if (this.addMember) {
        const canEditMembers = this.projectService.isProjectAdmin(project, currentUserId);

        const currentUserMemberRole =
          this.showMyRole && currentUserId ? getUserMemberRole(currentUserId, projMembers) : '';

        DEBUG_LOGS &&
          console.log(`${PAGE} buildForm addMember...`, {
            projectId: project.id,
            // role: [{value: 'none', disabled: !canEditMembers}],
            canEditProjectMembers: canEditMembers,
            currentUserMemberRole,
            project,
          });

        if (canEditMembers) {
          // add-member modal - the projectId is known and i am admin
          this.defaultRoleFormValue = 'CREW';
          const projectFormGroup = this.formBuilder.group({
            projectId: [project.id],
            // userId: [crewMember.userId],
            // username: [crewMember.username || crewMember.userId],
            isActive: [{ value: true, disabled: true }],
            role: [{ value: this.defaultRoleFormValue, disabled: !canEditMembers }],
            canEditProjectMembers: [canEditMembers],
            currentUserMemberRole: [currentUserMemberRole],
            project,
          });

          projectMemberForms.push(projectFormGroup);

          // notify parent that we selected CREW, update form values
          this.roleChanged.emit({ role: this.defaultRoleFormValue, project });
        }
      } else {
        // !this.addMember
        if (!member || !Array.isArray(member.memberProjects)) {
          console.warn(`${PAGE} No member.memberProjects`, { member, project });
          continue;
        }
        const crewMember = member.memberProjects.find((p) => p && p.projectId && p.projectId === project.id) || {
          // create a temp member as inactive
          userId: member.userId,
          username: member.username,
          isActive: false,
          role: PROJECT_MEMBER_ROLE.CREW,
        };

        const canEditProjectMembers =
          this.viewOnly || crewMember.role === PROJECT_MEMBER_ROLE.OWNER
            ? false
            : this.projectService.isProjectAdmin(project, currentUserId);

        const currentUserMemberRole =
          this.showMyRole && currentUserId ? getUserMemberRole(currentUserId, projMembers) : '';

        // DEBUG_LOGS && console.log(`${PAGE} buildForm:`, { crewMember, canEditProjectMembers, projMembers, project });

        const projectFormGroup = this.formBuilder.group({
          projectId: [project.id],
          userId: [crewMember.userId],
          username: [crewMember.username || crewMember.userId],
          isActive: [{ value: crewMember.isActive ? true : false, disabled: !canEditProjectMembers }],
          role: [
            { value: crewMember.role ? crewMember.role : this.defaultRoleFormValue, disabled: !canEditProjectMembers },
          ],
          canEditProjectMembers: [canEditProjectMembers],
          currentUserMemberRole: [currentUserMemberRole],
          project,
        });

        projectMemberForms.push(projectFormGroup);
      }
    }

    this.projectsForm = this.formBuilder.group({
      projects: projectMemberForms,
    });

    this.projectsControls = get(this.projectsForm.controls.projects, 'controls');
  }

  onActiveChanged(event, index) {
    const prop = 'isActive';
    try {
      if (event && event.detail && (event.detail.value || typeof event.detail.value === 'boolean')) {
        const val = event.detail.value;
        const proj = this.projects[index];
        const projectMember = this.memberProjects.find((p) => p && p.projectId && p.projectId === proj.id);
        if (projectMember[prop] !== val) {
          DEBUG_LOGS && console.log(`${PAGE} activeChanged`, { val, projectMember });
          projectMember[prop] = val;
          this.projectMemberService.updateProjectMember(projectMember);
          this.presentSavedToast();
          // .pipe(
          //   take(1),
          //   catchError((e) => {
          //     console.warn(`${PAGE} updateProjectMember caught`, e);
          //     this.toaster.present(`Oops! Something went wrong. Please try again.`);
          //     return EMPTY;
          //   })
          // )
          // .subscribe((res) => {
          //   DEBUG_LOGS && console.log(`${PAGE} update res:`, res);
          //   this.presentSavedToast();
          // });
        } else {
          DEBUG_LOGS &&
            console.log(`${PAGE} activeChanged but didn't actually change?`, {
              projectMemberIsActive: projectMember[prop],
              formVal: val,
            });
        }
      } else {
        console.log(`${PAGE} activeChanged but no value?`, index, event);
      }
    } catch (error) {
      console.error(`${PAGE} caught`, error);
      this.toaster.present(
        `Oops! Something happened. Please try again. ${error && error.message ? 'Message: ' + error.message : ''}`
      );
    }
  }

  onRoleChanged(event, index) {
    const prop = 'role';
    try {
      if (event && event.detail && (event.detail.value || typeof event.detail.value === 'boolean')) {
        const val: string = event.detail.value;
        const proj = this.projects[index];
        DEBUG_LOGS && console.log(`${PAGE} onRoleChanged`, { val, index, projectId: proj && proj.id });

        if (!val || val === this.defaultRoleFormValue) {
          DEBUG_LOGS && console.log(`${PAGE} onRoleChanged default role selected..`);
          return;
        }

        if (this.addMember) {
          // adding member, emit the change
          this.roleChanged.emit({ role: val, project: proj });
        } else {
          // update immediately
          const projectMember = this.memberProjects.find((p) => p && p.projectId && p.projectId === proj.id);
          if (projectMember[prop] !== val) {
            // DEBUG_LOGS &&
            DEBUG_LOGS && console.log(`${PAGE} activeChanged`, { val, projectMember });
            projectMember[prop] = val as PROJECT_MEMBER_ROLE;
            this.projectMemberService.updateProjectMember(projectMember);
            this.presentSavedToast();
            // .pipe(
            //   take(1),
            //   catchError((e) => {
            //     console.warn(`${PAGE} updateProjectMember caught`, e);
            //     this.toaster.present(`Oops! Something went wrong. Please try again.`);
            //     return EMPTY;
            //   })
            // )
            // .subscribe((res) => {
            //   DEBUG_LOGS && console.log(`${PAGE} update res:`, res);
            //   this.presentSavedToast();
            // });
          } else {
            DEBUG_LOGS &&
              console.log(`${PAGE} activeChanged but didn't actually change?`, {
                projectMemberRole: projectMember[prop],
                formVal: val,
              });
          }
        }
      } else {
        console.log(`${PAGE} activeChanged but no value?`, index, event);
      }
    } catch (error) {
      console.error(`${PAGE} caught`, error);
      this.toaster.present(
        `Oops! Something happened. Please try again. ${error && error.message ? 'Message: ' + error.message : ''}`
      );
    }
  }

  async saveChanges() {
    DEBUG_LOGS && console.log(`${PAGE}  saveChanges...`);
    if (this.projectsForm.invalid || this.projectsForm.pristine) {
      DEBUG_LOGS && console.log('invalid');
      return;
    }
    if (this.projectsForm.pristine) {
      return await this.toaster.present(`No changes to save...`);
    }
    // here set loading for button
    this.isSaving = true;
    try {
      each(this.projectsControls, (group) => {
        if (!group.pristine) {
          const item = group.value;
          if (!item) {
            console.warn(`${PAGE} saveChanges no value?!`, group);
            return;
          }
          const request = {
            projectId: item.projectId,
            userId: item.userId,
            username: item.username,
            role: item.role,
            isActive: item.isActive,
          };

          if (DEBUG_LOGS) {
            console.group('---updateProjectCrewMember REQUEST:---');
            console.log(request);
            console.groupEnd();
          }

          /**
           * @todo VERIFY this works! - it appears to on 3.2.5
           */
          this.projectMemberService.updateProjectMember(request);
          // this.projectCrewApiService.updateProjectCrewMember(request);
        }
      });
      this.presentSavedToast();
    } catch (error) {
      console.error(error);
      this.toaster.present(`Oops! Something happened. Please try again.`);
    } finally {
      this.isSaving = false;
    }
  }

  async presentSavedToast() {
    return await this.toaster.present(`Success. Changes saved.`);
  }

  navProject(id) {
    // console.log(`navProject ${id}`);
    this.navCtrl.navigateForward(`/${PROJECT_DETAIL_ROUTE}/${id}`);
    this.closeModal.emit();
  }
}
