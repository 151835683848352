<ng-container *ngIf="((stackList.items$ | async)?.length > 0 || showListIfEmpty) && (stackList.list$ | async) as list">
  <h3>{{ headingWithDefault }}</h3>
  <app-stack-list 
    [layout]="{
      type: layoutType.Carousel,
      options: {
        hasAddNewItemButton: false
      }
    }"
    [items]="stackList.items$ | async"
    [canLoadMore]="!!list.nextToken"
    [isLoading]="list.loading"
    [heading]="stackList.label"
    (loadMore)="loadMoreStacks(list)"
  ></app-stack-list>
</ng-container>

