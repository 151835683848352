<div class="grid">
  <div class="container">
    <div class="row">
      <!-- STACKS -->
      <ng-container *ngIf="itemType === itemTypes.Stacks">
        <div
          class="col-lg-3 col-md-4 col-sm-6 col-12"
          *ngFor="let item of items"
        >
          <app-stack-slide 
            [item]="asStack(item)" 
            [showAvatar]="options.showAvatar"
            [isModerated]="isModerated"> </app-stack-slide>
        </div>
      </ng-container>

      <!-- CLIPS -->
      <ng-container *ngIf="itemType === itemTypes.Clips">
        <ng-container *ngIf="myStackClipIds$ | async as myStackClipIds">
          <ng-container *ngIf="isLoading">
            <div class="grid__container__empty">
              <ion-spinner></ion-spinner> <span class="loading-spinner-text">Loading...</span>
            </div>
          </ng-container>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-12"
            *ngFor="let item of items"
          >
            <app-clip-tile
              [item]="asClip(item)"
              [isAddedToMyStack]="myStackClipIds.indexOf(getClipId(item)) !== -1"
              [canEdit]="canEdit"
              [currentUserId]="userService.userId$ | async"
              [isModerated]="isModerated"
              [canModerate]="canModerate"
              [showAvatar]="options.showAvatar"
            >
            </app-clip-tile>
          </div>

          <!-- empty array: items are loaded but there are no actual items -->
          <ng-container *ngIf="!isLoading && items && items.length === 0">
            <div class="grid__container__empty">
              {{ 'LISTS.NO_ITEMS' | translate }}
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
