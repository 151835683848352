/** @format */

import { Directive, Output } from '@angular/core';
import { PickerDisplayMode, PickerOptions } from 'filestack-js';
import * as filestackDefaultPickerOptions from './filestack.config.json';
import { Subject } from 'rxjs';
import _merge from 'lodash/merge';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '@shared/utils';
import { Platform } from '@ionic/angular';
import { FilestackService, PickerFileError, PickerFileMetadata, PickerUploadDone } from '@app/core/filestack/filestack.service';
import { SentryService } from '@services/analytics/sentry.service';

@Directive()
export abstract class BaseFileUpload {
  projectId;
  userId;

  @Output() fileUploadFinished: Subject<PickerFileMetadata>;
  /** PickerFileErrorCallback === PickerFileError */
  @Output() fileUploadFailed: Subject<PickerFileError>;
  /**
   * onUploadDone: PickerUploadDoneCallback
   * Called when all files have been uploaded.
   */
  @Output() uploadDone: Subject<PickerUploadDone>;

  protected allowedFileTypes;
  protected customPickerOptions = {};

  constructor(
    protected filestackService: FilestackService,
    protected translate: TranslateService,
    protected platform: Platform,
    protected sentryService: SentryService
  ) {
    this.fileUploadFinished = new Subject();
    this.fileUploadFailed = new Subject();
    this.uploadDone = new Subject();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openFilePicker(event: Event) {
    const client = this.filestackService.init();
    /**
     * https://filestack.github.io/filestack-js/interfaces/pickeroptions.html
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pickerOptions: PickerOptions = _merge((filestackDefaultPickerOptions as any).default, {
      displayMode: PickerDisplayMode.overlay,
      allowManualRetry: false,
      // onUploadDone: (res) => this.uploadSuccess.next(res),
      onFileSelected: (file: PickerFileMetadata) => this.onFileSelected(file),
      onFileUploadFinished: (file: PickerFileMetadata) => this.fileUploadFinished.next(file),
      onFileUploadFailed: (file: PickerFileMetadata, error: Error) => this.fileUploadFailed.next({ file, error }),
      onUploadDone: (files: PickerUploadDone) =>
        this.uploadDone.next(files),
    });
    for (const cpo in this.customPickerOptions) {
      if (this.customPickerOptions.hasOwnProperty(cpo)) {
        pickerOptions[cpo] = this.customPickerOptions[cpo];
      }
    }
    if (this.platform.is('cordova')) {
      pickerOptions['fromSources'] = ['local_file_system'];
    }

    const picker = client.picker(pickerOptions);
    picker.open().catch((err) => console.log(err)); //this.uploadError.next(err));
  }

  makeFileUploadName(file: PickerFileMetadata, userId = this.userId) {
    if (!userId) {
      userId = 'anon';
    }
    const sep = '_-_';
    const safeName = Utils.urlSafeFileName(file.filename);
    return (
      Utils.urlSafeString(userId) +
      sep +
      Utils.removeFileExt(safeName) +
      '_' +
      Utils.urlDate(new Date()) +
      '.' +
      Utils.getFileExt(safeName)
    ).toLowerCase();
  }

  /**
   * Called whenever user selects a file, used to verify the selected file before upload
   * If you throw any error in this function it will reject the file selection.
   * The error message will be displayed to the user as an alert.
   * https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#onfileselected
   *
   * @todo This currently only works for local uploads. Should migrate to acceptFn callback usage for files uploaded from local_source, as well as, from cloud providers.
   * https://www.filestack.com/docs/uploads/pickers/web/#acceptFn
   */
  onFileSelected(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        return reject(this.translate.instant('FILE_UPLOAD.FILE_NOT_FOUND'));
      }

      /*       
      if (this.customPickerOptions['maxSize'] && file.size > this.customPickerOptions['maxSize']) {
        return reject(
          this.translate.instant(
            'FILE_UPLOAD.SIZE_EXCEEDS_LIMIT',
            {
              file_size_mb: Utils.convertBytesToMB(file.size),
              file_size_limit_mb: Utils.convertBytesToMB(this.customPickerOptions['maxSize'])
            }
          )
        );
      } */
      if (this.allowedFileTypes && !this.allowedFileTypes.includes(file.mimetype)) {
        return reject(
          this.translate.instant('FILE_UPLOAD.FORMAT_NOT_SUPPORTED', {
            not_supported_file_type: file.mimetype,
            supported_file_types: this.allowedFileTypes.join(', '),
          })
        );
      }
      const name = this.makeFileUploadName(file, this.userId);
      resolve({ ...file, name });
    });
  }
}
