/** @format */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DEFAULT_POSTER } from '@shared/models/clip.model';
import { Utils } from '@shared/utils';

const LOADING_TIMEOUT_MS = 600;

const PAGE = '[ExplorePathList]';

@Component({
  selector: 'app-explore-path-item',
  templateUrl: './explore-path-item.component.html',
  styleUrls: ['./explore-path-item.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExplorePathItemComponent implements OnInit {
  @Input() pathId: string;
  @Input() pathIndex: number;
  @Input() pathNum: number;
  // @Input() drawerId: string; // is this needed?
  @Input() title = '';
  @Input() subTitle = '';
  @Input() content = '';
  @Input() duration = '';
  @Input() poster = DEFAULT_POSTER;
  @Input() tags: string[] = [];
  /**
   * display title and subTitle?
   */
  @Input() showTitleDetails = true;
  @Input() errorMessage = '';

  @Output() reloadPath = new EventEmitter<{
    pathId: string;
    pathIndex: number;
  }>();
  @Output() playPath = new EventEmitter<{
    pathId: string;
    pathIndex: number;
  }>();

  didClickPlay = false;
  loading = true;
  loaded = false;
  // private refreshes = 0;

  ngOnInit() {
    this.initializePathLoading();
  }

  initializePathLoading() {
    this.loading = true;
    // this.refreshes = 0;
    this.errorMessage = '';
    setTimeout(() => {
      if (this.loading) {
        this.loading = false;
      }
    }, LOADING_TIMEOUT_MS);
  }

  newPath() {
    // this.refreshes++;
    this.initializePathLoading();
    this.reloadPath.emit({ pathId: this.pathId, pathIndex: this.pathIndex });
  }

  onPlayPath(event) {
    Utils.tryStopPropagation(event);
    console.log(`${PAGE} playPath`, { pathIndex: this.pathIndex, pathId: this.pathId });
    this.playPath.emit({ pathId: this.pathId, pathIndex: this.pathIndex });
    this.didClickPlay = true;
  }
}
