<div class="video-player-wrap" [style.height]="videoHeight">

  <div *ngIf="!currentClip && (!stack || !stack.hlsSrc)">
    <div class="loading-wrap">
      <div class="ion-text-center">
        <div class="spinner" *ngIf="loading">
          <ion-spinner name="crescent"></ion-spinner>
        </div>
        {{ loadingText }}
      </div>
    </div>
  </div>

  <!-- 
    Overlays
  -->
  <div [class.overlay-showing]="showOverlay" class="video-overlay" (click)="onOverlayClick($event)">
    <app-overlay-complete 
      [stack]="stack"
      [project]="project"
      (watchAgain)="onWatchAgain($event)"
    ></app-overlay-complete>
  </div>

  <!-- 
    TODO: Social Provider videos
    we need to keep this in DOM and just pause when the currentClip is not HLS, Youtube or Vimeo
    we need to track the currentClip in the playlist to see if there's a youtube or vimeo id
      if so, pause HLS stream, play the other video then come back

    [hidden]="currentClip.providerId"
  -->

  <!-- 
    If stack?.hlsSrc && currentClip.providerId - pause HLS and hide player?

    Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. 
    Previous value: 'false'. Current value: 'true'.. Find more at https://angular.io/errors/NG0100
    Line#48? autoplay
  -->
  <app-video-player-plyr
    *ngIf="stack?.hlsSrc else clipPlayer"
    [stack]="stack"
    [playlist]="playlist"
    [currentIndex]="currentIndex"
    [playerId]="playerId"
    [autoplay]="autoplay"
    [isSafari]="isSafari"
    [cssHeight]="videoHeight"
    [cssWidth]="videoWidth"
    [showControls]="showControls"
    [showOverlayPlay]="showOverlayPlay"
    [useTrimmerControls]="useTrimmerControls"
    [showRestart]="showRestart"
    [readyToPlay]="didInteract$ | async"
    (playerReady)="onPlayerReady($event)"
    (loadedMetadata)="onLoadedMetadata($event)"
    (videoPlaying)="onVideoPlaying($event)"
    (videoEnded)="onVideoEnded($event)"
  ></app-video-player-plyr>
  <ng-template #clipPlayer>
    <!-- 
      If currentClip.providerId - play this one while pausing HLS
    -->
    <app-video-player-plyr
      *ngIf="currentClip"
      [currentClip]="currentClip"
      [playerId]="playerId"
      [autoplay]="autoplay"
      [isSafari]="isSafari"
      [cssHeight]="videoHeight"
      [cssWidth]="videoWidth"
      [showControls]="showControls"
      [showOverlayPlay]="showOverlayPlay"
      [useTrimmerControls]="useTrimmerControls"
      [showRestart]="showRestart"
      [doHlsDestroy]="doHlsDestroy"
      [readyToPlay]="didInteract$ | async"
      (playerReady)="onPlayerReady($event)"
      (loadedMetadata)="onLoadedMetadata($event)"
      (videoPlaying)="onVideoPlaying($event)"
      (videoEnded)="onVideoEnded($event)"
    ></app-video-player-plyr>
  </ng-template>

</div>
