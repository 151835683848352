import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@shared/shared.module';
import { DirectivesModule } from '@app/directives/directives.module';
import { TokensModule } from '@tokens/tokens.module';
import { StacksModule } from '@stacks/stacks.module';
import { AddMemberComponent } from './add-member/add-member.component';
import { ApplyAsMemberComponent } from './apply-as-member/apply-as-member.component';
import { ProjectMemberComponent } from './project-member/project-member.component';
import { ProjectMemberModalComponent } from './project-member-modal/project-member-modal.component';
import { ProjectMemberListComponent } from './project-member-list/project-member-list.component';
import { ProjectMemberProjectsComponent } from './project-member-projects/project-member-projects.component';
import { AddMemberModalComponent } from './add-member-modal/add-member-modal.component';
import * as fromComponents from './shared/components';
/** 
 * @note Routes cannot be specified since this Module is imported by other Modules
 * and the routes would be applied as children to those, which is not desired
 */
// import { ProjectMembersRoutingModule } from './project-members-routing.module';


@NgModule({
    declarations: [
        AddMemberComponent,
        AddMemberModalComponent,
        ApplyAsMemberComponent,
        ProjectMemberComponent,
        ProjectMemberListComponent, 
        ProjectMemberProjectsComponent, 
        ProjectMemberModalComponent,
        ...fromComponents.components
    ],
    imports: [
        CommonModule,
        IonicModule,
        SharedModule,
        DirectivesModule,
        TokensModule,
        StacksModule
    ],
    exports: [
        AddMemberComponent,
        ApplyAsMemberComponent,
        ProjectMemberComponent,
        ProjectMemberListComponent,
        AddMemberModalComponent,
        ...fromComponents.components
    ]
})
export class ProjectMembersModule { }
