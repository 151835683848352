<ion-content>
  <ng-container *ngIf="canEdit; then editable else cannotEdit"></ng-container>
  
  <ng-template #cannotEdit>  
    <div class="ion-padding ion-text-center ion-margin-top">
      <ion-text color="light">
        Sorry, you are not able to Edit this Clip.
        <br><br>
        Only the person that uploaded the Clip can change the Settings.
      </ion-text>
    </div>
  </ng-template>

  <ng-template #editable>

    <div class="player-wrap diet-limit-width">
      <!--[class.video-fullscreen]="isLandscape"-->
      <app-video-player #selectPosterPlayer
        [playerId]="playerId" 
        [playlist]="[clip]" 
        [autoplay]="false"
        [showOverlayPlay]="true"
        [handlePlaylistDone]="false"
      ></app-video-player>
    </div>

    <div class="limit-width ion-padding ion-text-center diet-limit-width">

      <div class="main-title">
        <h1>Settings &amp; Metadata</h1>
        <h5>The more details you add, the more likely your clip will be discovered.</h5>
      </div>

      <ng-template #noClip>
        <p  class="ion-text-center">
          Oops! We didn't load the Clip correctly, please try again.
        </p>
      </ng-template>

      <form *ngIf="clip; else noClip" [formGroup]="formGroup" (ngSubmit)="confirmTranscode()">
        <ion-list class="setting-list" lines="none">
          
          <ion-item>
            <ion-input formControlName="titleInput" type="text" 
              label="Clip Title"
              labelPlacement="floating"
              [class.invalid]="!formGroup.controls.titleInput.valid && (formGroup.controls.titleInput.dirty || submitAttempt)"
              (ionChange)="onChangeTitle($event)"
            ></ion-input>
          </ion-item>
          <ion-item *ngIf="!formGroup.controls.titleInput.valid && (formGroup.controls.titleInput.dirty || submitAttempt)">
            <!-- formGroup.controls.titleInput.errors?.pattern -->
            <p class="error">
              Please enter a valid Title <br>
              <i>Max length 140 characters (currently: {{curTitleLen}}), no</i> / &gt; &lt; \
            </p>
          </ion-item>
          <ion-item>
            <ion-textarea name="descInput" [autoGrow]="true" [debounce]="50" [rows]="3"
              [maxlength]="maxDescLen" type="text"
              labelPlacement="floating"
              label="Clip Description ({{curDescLen}}/{{maxDescLen}})"
              formControlName="descInput"
              [class.invalid]="!formGroup.controls.descInput.valid && (formGroup.controls.descInput.dirty || submitAttempt)" 
              (ionChange)="onChangeDesc($event)">
            </ion-textarea>
          </ion-item>
          <ion-item *ngIf="!formGroup.controls.descInput.valid && (formGroup.controls.descInput.dirty || submitAttempt)">
            <p class="error">
              Please enter a valid Description<br>
              <i>Max length {{maxDescLen}} characters, no</i> &gt; or &lt;
            </p>
          </ion-item>

          
          <!-- language dropdown -->
          <ion-item *ngIf="(appConfig | async)?.enableClipLanguage" class="language-select">
            <ion-select name="language" formControlName="language" interface="popover" placeholder="Select Language">
              <div slot="label">{{ 'COMMON.LANGUAGE' | translate }}</div>
              <ion-select-option value="english">{{ 'COMMON.ENGLISH' | translate }}</ion-select-option>
              <ion-select-option value="spanish">{{ 'COMMON.SPANISH' | translate }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item slot="header" id="open-modal" class="datetime-modal-action">
            <ion-label class="bold">{{ 'COMMON.DATE' | translate }}</ion-label>
            <ion-note slot="end">{{ formGroup.value.filmingDate | date:'short'}}</ion-note>
          </ion-item>

          <ion-modal trigger="open-modal">
            <ng-template>
              <ion-content>
                <ion-datetime
                  #filmingdate
                  presentation="date-time"
                  size="cover"
                  formControlName="filmingDate"
                  showDefaultButtons="true"
                  (ionChange)="onChangeDate($event)"
                >
                </ion-datetime>
              </ion-content>
            </ng-template>
          </ion-modal>
          <ng-container *ngIf="(appConfig | async)?.allowPrivateUploads">
            <ion-item class="ion-margin-top toggle-item-pad-left">
              <!-- public viewing -->
              <ion-toggle 
                formControlName="publicViewing" 
                justify="space-between"
                [enableOnOffLabels]="true">
                <div class="ion-text-uppercase bold">
                  {{ 'CLIP.SETTINGS.PUBLIC_VIEWING' | translate }}
                </div>
                <div class="instructions ion-text-wrap">
                  {{ 'CLIP.SETTINGS.PUBLIC_VIEWING_HINT' | translate }}
                </div>
              </ion-toggle>
            </ion-item>
          </ng-container>

          <ion-item *ngIf="enableTags" class="ion-margin-top">
            <!-- <app-tagger-input 
              [id]="'clip_uploader'"
              [heading]="'Add Tags'"
              [items]="clip.tags"
              [autocompleteItems]="tagAutocompleteItems"
              (tagSelected)="onTagSelected($event)"
              (tagRemoved)="onTagRemoved($event)"
              (tagAdded)="onTagAdded($event)"
            ></app-tagger-input> -->
          </ion-item>

        </ion-list>


        <ion-list class="setting-list map-list" lines="none">
          <!-- location -->
          <ion-list-header class="no-margin-bottom">
            <ion-label>
              {{ 'CLIP.SETTINGS.LOCATION' | translate }}
            </ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label *ngIf="mapLoaded && !mapLoadingCanceled" class="goof-title instructions ion-text-wrap">
              {{ 'CLIP.SETTINGS.LOCATION_HELP' | translate }}
            </ion-label>
            <ion-label *ngIf="!mapLoaded || mapLoadingCanceled" class="goof-title instructions ion-text-wrap">
              {{ 'CLIP.SETTINGS.LOCATION_PROMPT' | translate }}
            </ion-label>
          </ion-item>
          <ion-item *ngIf="!mapLoaded && mapLoadingCanceled" class="ion-text-center">
            <ion-button shape="round" fill="outline" size="default" (click)="retryLocationAccess()" class="margin-center">Get My Location</ion-button>
          </ion-item>
          <ion-card class="ion-no-margin">
            <ion-item *ngIf="!mapLoaded && !mapLoadingCanceled" class="ion-text-center">
                <ion-spinner name="crescent" class="margin-center"></ion-spinner>
            </ion-item>
            <ion-item class="location-item ion-text-center">
              <div #map id="map" [class.map-loaded]="mapLoaded"></div>
            </ion-item>
          </ion-card>
          
        </ion-list>

        <div class="ion-text-center ion-padding ion-margin-top">

          <app-terms-policy-modals
            [agreeTo] = "'POLICIES.AGREE_TO_TERMS_PREFIX' | translate"
          ></app-terms-policy-modals>
          
        </div>

        <div class="action-buttons ion-text-center ion-padding ion-margin-top ion-text-uppercase">
          <ion-button fill="solid" shape="round" color="primary" (click)="save()" [disabled]="isSaving">
            <ion-spinner *ngIf="isSaving" slot="start" name="crescent"></ion-spinner>
            {{ 'COMMON.SAVE' | translate }}
          </ion-button>

          <ion-button *ngIf="canHlsTranscode" 
            type="submit"
            style="position:absolute;right:20px" color="medium" size="small" fill="outline" 
            title="Transcode to HLS">
            <ion-icon slot="icon-only" name="beer-outline"></ion-icon>
          </ion-button>
        </div>
        
      </form>


    
    </div>
  </ng-template>

  <div class="tab-spacer"></div>
</ion-content>
