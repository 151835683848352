
<div class="container">
  <h2>
    {{ translatePath + 'HOW_IT_WORKS' | translate }}
  </h2>
  <h1>
    {{ translatePath + 'HOW_INTRO' | translate }} 
  </h1>
  <div class="content-list">
    <ol>
      <li>
        <b>
          {{ translatePath + 'HOW_1_TITLE' | translate }} 
        </b>
        <div>
          <!-- 
            During the signup, choose the size of the project based on how much content will be uploaded. 
            You can also buy more minutes later if you need to.
          -->
          {{ translatePath + 'HOW_1_TEXT' | translate }} 
        </div>
      </li>
      <li>
        <b>
          {{ translatePath + 'HOW_2_TITLE' | translate }} 
        </b>
        <div>
          {{ translatePath + 'HOW_2_TEXT' | translate }} 
        </div>
      </li>
      <li>
        <b>
          {{ translatePath + 'HOW_3_TITLE' | translate }} 
        </b>
        <div>
          {{ translatePath + 'HOW_3_TEXT' | translate }} 
        </div>
      </li>
    </ol>
  </div>
</div>
