<div *ngIf="loading" class="path-list-top-spacer ion-text-center ion-margin-vertical">
  <ion-spinner></ion-spinner>
  <p class="ion-text-center">
    <ion-text color="tertiary"> Loading Storylines... </ion-text>
  </p>
</div>

<div *ngIf="!loading && paths.length === 0" class="path-list-top-spacer ion-text-center ion-margin-vertical">
  <p class="ion-text-center ion-padding-vertical ion-margin-top">
    <ion-text color="secondary"> No Storylines were returned. </ion-text>
  </p>
  <ion-button fill="outline" (click)="tryAgainNoResults()" title="Get New Storylines">
    <ion-icon slot="start" name="refresh"></ion-icon>
    Hmm... Explore Again
  </ion-button>
</div>

<div *ngIf="errorMessage" class="path-list-top-spacer pathway-error ion-margin-vertical">
  <p>
    {{ errorMessage }}
  </p>

  <ion-button fill="outline" (click)="tryAgainOnError()" title="Get A New Storyline">
    <ion-icon slot="start" name="refresh"></ion-icon>
    Explore Again
  </ion-button>
</div>

<div *ngIf="!errorMessage" [hidden]="loading" class="path-list path-list-top-spacer">
  <swiper-container *ngIf="paths.length > 0" #swiper 
      effect="slide" 
      [initialSlide]="0" 
      [slidesPerView]="slidesPerView" 
      [speed]="400" 
      [freeMode]="true" 
      [spaceBetween]="6" 
      [modules]="swiperModules">
    <ng-container *ngFor="let path of paths; let i = index">
      <swiper-slide [hidden]="path?.didClickPlay" class="path-slide" [class.first-slide]="i === 0">
        <app-explore-path-item
          [pathId]="path?.pathId"
          [pathIndex]="path?.pathIndex"
          [pathNum]="path?.pathNum"
          [poster]="path?.poster"
          [title]="path?.title"
          [subTitle]="path?.subTitle"
          [content]="path?.content"
          [duration]="path?.duration"
          [tags]="path?.tags"
          [showTitleDetails]="true"
          [errorMessage]="path?.errorMessage"
          (reloadPath)="reloadPath($event)"
          (playPath)="playPath($event)"
        ></app-explore-path-item>
      </swiper-slide>
    </ng-container>
   

    <swiper-slide class="path-slide slide-load-more">
      <div class="ion-text-center ion-padding ion-margin">
        <div *ngIf="noMoreLoadMore; else loadMoreSlide">
          <h3>
            <ion-text color="medium">
              Watch more clips to see more pathways... 
            </ion-text>
          </h3>
        </div>
        <ng-template #loadMoreSlide>
          <h3> Load More Storylines </h3>
          <ion-fab-button (click)="loadMore()" [disabled]="loadingMore">
            <ion-icon *ngIf="!loadingMore" name="dice-outline"></ion-icon>
            <ion-spinner *ngIf="loadingMore"></ion-spinner>
            <!-- <ion-icon name="sync"></ion-icon> -->
          </ion-fab-button>
        </ng-template>
      </div>
    </swiper-slide>
  </swiper-container>
</div>

 <!-- <span class='poster-outline outline-one'></span>
      <span class='poster-outline outline-two'></span> -->

<!-- 

<div *ngIf="false && currentClip && !errorMessage" [hidden]="loading" class="path-list">

  <div class="clip-item-wrap">
    <div class="clip-item">
      <div class="poster-wrap" (click)="playPath()">
        <ion-button fill="clear" class="poster-play" title="Watch">
          <ion-icon color="light" slot="icon-only" name="play-sharp"></ion-icon>
        </ion-button>
        <div class="poster" [style.background-image]="'url('+ poster +')'"></div>
        <span class='poster-outline outline-one'></span>
        <span class='poster-outline outline-two'></span>
      </div>
      <div class="title-wrap">
          <h4 class="ion-no-margin">{{pathTitle}}</h4>
          <p class="ion-no-margin" class="path-deets">{{pathDeets}}</p>
      </div>
    </div>
    <div class="chip-wrap">
      <ion-chip *ngFor="let chip of tags">
        <ion-label>{{chip}}</ion-label>
      </ion-chip>
    </div>
  </div>


  <div *ngIf="false && showPathActionBtns" class="actions ion-text-center">
      
      <ion-button fill="outline" shape="round" color="primary" (click)="playPath()" title="Play Storyline">
          <ion-icon slot="start" name="play-sharp"></ion-icon>
          Watch
      </ion-button>
      <ion-button fill="outline" shape="round" (click)="closeDrawer()" title="Return to current Storyline">
          <ion-icon slot="start" name="arrow-undo-outline"></ion-icon>
          Return
      </ion-button>

      <ion-button hidden fill="outline" shape="round" (click)="refreshPath()" title="Get A New Storyline">
          <ion-icon slot="start" name="refresh"></ion-icon>
          Refresh
      </ion-button>
      
    </div>

</div> -->
