/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnboardingTypes, ROUTE_DATA_PROP } from '@onboarding/shared/onboarding.model';
import { OnboardingLandingComponent } from '@onboarding/onboarding-landing/onboarding-landing.component';
import { OnboardingSignupComponent } from '@onboarding/onboarding-signup/onboarding-signup.component';
// import { OnboardingSetupComponent } from '@onboarding/onboarding-setup/onboarding-setup.component';
import { OnboardingSuccessComponent } from '@onboarding/onboarding-success/onboarding-success.component';
import { OnboardingInviteComponent } from '@onboarding/onboarding-invite/onboarding-invite.component';
import { OnboardingAcceptComponent } from '@onboarding/onboarding-accept/onboarding-accept.component';
// import { OnboardingTourComponent } from '@onboarding/onboarding-tour/onboarding-tour.component';

const routes: Routes = [
  {
    path: 'signup',
    component: OnboardingSignupComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Pro },
  },
  {
    path: 'signup/:projectId',
    component: OnboardingSignupComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Pro },
  },
  {
    path: 'success',
    component: OnboardingSuccessComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Pro },
  },
  {
    path: 'invite',
    component: OnboardingInviteComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Pro },
  },
  {
    path: 'invite/:projectId',
    component: OnboardingInviteComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Pro },
  },
  {
    path: 'accept',
    component: OnboardingAcceptComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Pro },
  },
  {
    path: 'accept/:projectId',
    component: OnboardingAcceptComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Pro },
  },
  // { path: 'detail/:id', component: StackDetailComponent },
  {
    path: '',
    component: OnboardingLandingComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Pro },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProRoutingModule {}
