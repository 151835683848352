<ion-header>
  <app-top-navbar title="" [backButtonReturnUrl]="returnUrl">
    <!-- SLOT: anything added here will go below the ion-navbar -->
  </app-top-navbar>
</ion-header>

<ion-content class="light">
  <div class="container ion-padding diet-limit-width">
    <h2>
      {{ translatePath + 'SIGNUP_TITLE' | translate }}
    </h2>

    <ng-container *ngIf="isLoggedIn$ | async as isLoggedIn; else notLoggedIn">
      <h1 *ngIf="enableBilling">
        <ng-container *ngIf="hasTiers else defaultBillingTitle">
          {{ translatePath + 'SIGNUP_SUBTITLE_TIERED' | translate }}
        </ng-container>
        <ng-template #defaultBillingTitle>
          {{ translatePath + 'SIGNUP_SUBTITLE' | translate }}
        </ng-template>
      </h1>
      
      <p *ngIf="plan?.description else defaultPlanDescription">
        {{ plan.description }}
      </p>
      <ng-template #defaultPlanDescription>
        <p>
          {{ translatePath + 'SIGNUP_BODY_1' | translate }}
        </p>
      </ng-template>

      <ng-template #billingDisabled>
        <p class="ion-padding-vertical bold orange">
          {{ 'BILLING.FEATURE_DISABLED' | translate }}
          <ion-button 
            fill="clear" 
            class="text-btn" 
            [routerLink]="['/contact']"
            [queryParams]="{ topic: 'EMAILS.CONTACT_TOPIC_WEDDINGS' | translate }">{{ 
              'COMMON.CONTACT_US' | translate 
            }}</ion-button>
        </p>
      </ng-template>

      <ng-container *ngIf="enableBilling else billingDisabled">
        
        <fs-signup-stepper
          [eventType]="onboardingType"
          [projectId]="projectId"
          [translatePath]="translatePath"
          [plan]="plan"
          [planPrices]="planPrices"
          [subscriptionId]="subscriptionId"
          [isProPlan]="isProPlan"
          [isActivePro]="isActivePro"
          [metaPlanOptions]="metaPlanOptions"
          [defaultProjectId]="selectedProjectId"
          (setDefaultProjectId)="setDefaultProjectId($event)"
          (projectCreated)="onProjectCreated($event)"
          (signupComplete)="signupComplete($event)"
          (signupError)="signupError($event)"
        ></fs-signup-stepper>

        <ion-alert
          [isOpen]="isAlertOpenSignupSuccess"
          [header]="translatePath + 'SIGNUP_SUCCESS' | translate"
          [subHeader]="translatePath + 'SIGNUP_SUCCESS_ALERT_SUBHEAD' | translate"
          [message]="translatePath + 'SIGNUP_SUCCESS_ALERT_MESSAGE' | translate"
          [buttons]="alertButtonsSignupSuccess"
          (didDismiss)="handleSuccessAlert($event)"
        ></ion-alert>

      </ng-container>

      <!-- moved to signup-stepper:
      <p class="italic disclaimer" [innerHTML]="'ONBOARDING.COMMON.DISCLAIMER' | translate"></p>
      <div class="actions skinny-limit-width">
        <fs-checkout-button 
          [disabled]="!enableBilling || (hasTiers && numMembers < 1) || isProOnProPage"
          [planId]="planId" 
          [priceId]="selectedPriceId" 
          [referralCode]="referralCode" 
          [routeId]="onboardingType"
          [projectId]="selectedProjectId$ | async"
          [projectTitle]="selectedProject?.title"
          [eventDate]="selectedEventDate"
          (errored)="signupErrored($event)"
          (completed)="signupComplete($event)"></fs-checkout-button>
      </div> -->
    </ng-container>
    
    <ng-template #notLoggedIn>
      <!-- LOGGED OUT -->
      <div>
        <fs-button
          class="signup-button"
          size="large"
          buttonStyle="clear"
          routerLink="/signup" [queryParams]="{returnUrl: actionUrl}"
          >{{ 'AUTH.NO_ACCOUNT_SIGNUP' | translate }}</fs-button
        >
      </div>
      <div class="container">
        <h3>{{ 'AUTH.EXISTING_ACCOUNT' | translate }}</h3>
        <fs-login-form 
          [routeOnSuccess]="actionUrl" 
          [cancelText]="'AUTH.NO_ACCOUNT_SIGNUP' | translate"
          (cancelClick)="routeToSignup()"></fs-login-form>
      </div>
    </ng-template>
  </div>

</ion-content>
