/** @format */
import * as Clips from '../actions/clips.actions';

export const featureKey = 'search';

export interface State {
  ids: string[];
  loading: boolean;
  query: string;
}

export const initialState: State = {
  ids: [],
  loading: false,
  query: '',
};

export function reducer(state = initialState, action: Clips.ActionsUnion): State {
  switch (action.type) {
    case Clips.search.type: {
      const { search } = action;

      if (search === '') {
        return {
          ids: [],
          loading: false,
          query: search,
        };
      }

      return Object.assign({}, state, {
        query: search,
        loading: true,
      });
    }

    case Clips.searchComplete.type: {
      const { clips } = action;

      return {
        ids: (clips || []).map((clip) => clip.id),
        loading: false,
        query: state.query,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIds = (state: State) => state.ids;

export const getQuery = (state: State) => state.query;

export const getLoading = (state: State) => state.loading;
