/** @format */

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PopoverController, NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Stack } from '@shared/models/stack.model';
import { StackPopoverActionsComponent } from './stack-popover-actions/stack-popover-actions.component';
import { EDIT_ROUTE, WATCH_ROUTE } from '@app/app-routing.module';
import { DiscoverCardClick, ITEM_TYPE } from '@shared/models/layout.model';

@Component({
  selector: 'app-stack-slide',
  templateUrl: './stack-slide.component.html',
  styleUrls: ['./stack-slide.component.scss'],
})
export class StackSlideComponent {
  @Input() item: Stack;
  /** should be currentUserId, really */
  @Input() currentUsername: string;
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  /** isProjectAdmin, canFeature too */
  @Input() canModerate = false;
  @Input() canShare = true;
  @Input() showAvatar = true;
  @Input() isProjectMember = false;
  
  @Output() itemClick = new EventEmitter<DiscoverCardClick>();

  popoverOpen$ = new Subject<boolean>();

  get isMine(): boolean {
    return this.currentUsername === this.item.userId;
  }
  get canPlay(): boolean {
    return this.isMine || !this.isModerated || this.canModerate || (this.isModerated && this.item.isApproved);
  }
  get canApprove(): boolean {
    return this.isModerated && this.canModerate;
  }
  get canCollaborate(): boolean {
    return this.isProjectMember && this.item && this.item.isCollaborative && this.item.isCollaborative > 0;
  }

  constructor(private popoverCtrl: PopoverController, private navCtrl: NavController) {}

  open(event, item: Stack) {
    if (!item || !item.projectId || !item.stackId) {
      console.warn(`[StackSlide] open !item or ids?`, item);
      return;
    }
    if (!this.canPlay && !this.canCollaborate) {
      console.log(`Stack must be Moderated first...`);
      return;
    }
    if (item.dtePublished) {
      this.navCtrl.navigateForward(`/${WATCH_ROUTE}/${item.projectId}/${item.stackId}`);
    } else {
      /**
       * we are defaulting to an open stack action,
       * if it's a collaborative Draft & i can collaborate -> autoOpenCapture
       */
      this.navCtrl.navigateForward(`/${EDIT_ROUTE}/${item.projectId}/${item.stackId}`, {
        ...(!this.isMine && item.isCollaborative && this.canCollaborate ? {
          state: {
            autoOpenCapture: true
          }
        } : {})
      });
    }
    this.itemClick.emit({ event, item, itemType: ITEM_TYPE.Stacks });
  }

  async openPopover(event: MouseEvent) {
    this.popoverOpen$.next(true);
    const popover = await this.popoverCtrl.create({
      component: StackPopoverActionsComponent,
      componentProps: {
        stack: this.item,
        // add the moderation actions
        isModerated: this.isModerated,
        canAddToStack: this.canPlay,
        canShare: this.canShare && this.canPlay,
        canApprove: this.canApprove,
        canFeature: this.canModerate,
        isProjectMember: this.isProjectMember,
      },
      event,
    });
    await popover.present();
    await popover.onWillDismiss();
    this.popoverOpen$.next(false);
  }
}
