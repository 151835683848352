import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**
 * @note Routes cannot be specified here since this Module is imported by other Modules
 * and the routes would be applied as children to those, which is not desired
 */
const routes: Routes = [
  // {
  //   path: 'hls',
  //   loadChildren: () => import('./pages/hls-dev/hls-dev.module').then(m => m.HlsDevPageModule)
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VideoPlayerRoutingModule { }
