/** @format */

import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { IonicSlides, SelectCustomEvent } from '@ionic/angular';
import { FlagService } from '@services/flag.service';
import { Clip } from '@shared/models/clip.model';
import { Stack } from '@shared/models/stack.model';
import { BaseModalComponent } from '../ui/modal/base-modal.component';
import { Utils } from '@shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { NgxPopperjsTriggers, NgxPopperjsPlacements } from 'ngx-popperjs';

const STEPS = {
  selection: { id: 'selection', back: null, next: 'subSelection' },
  subSelection: {
    id: 'subSelection',
    back: 'selection',
    next: 'additionalText',
  },
  additionalText: { id: 'additionalText', back: 'subSelection', next: 'sent' },
  sent: { id: 'sent', back: null, next: null },
};

// type Steps = keyof typeof STEPS;

interface Reason {
  id: number;
  text: string;
  tooltip?: string;
  items: { id: number; text: string }[];
}

@Component({
  selector: 'app-modal-report',
  templateUrl: './modal-report.component.html',
  styleUrls: ['./modal-report.component.scss'],
})
export class ModalReportComponent implements OnInit {
  @Input() reportedItem: Clip | Stack;

  @ViewChild('modal', { static: false, read: BaseModalComponent })
  modal: BaseModalComponent;

  // https://ionicframework.com/docs/angular/slides
  @ViewChild('swiper', { static: false }) swiperRef: ElementRef | undefined;
  swiperModules = [IonicSlides];
  allowSlidePrev = false;
  allowSlideNext = false;
  get swiper() {
    return this.swiperRef?.nativeElement?.swiper;
  }

  PopperTriggers = NgxPopperjsTriggers;
  PopperPlacements = NgxPopperjsPlacements;
  selectedCategory: Reason;
  selectedReason: Reason['items'][0];
  additionalDetails = '';
  STEPS = STEPS;
  currentStep = this.STEPS.selection;
  // build the categories OnInit for translations
  categories = [];

  get title() {
    switch (this.currentStep.id) {
      case STEPS.selection.id:
      case STEPS.subSelection.id:
      case STEPS.additionalText.id:
        return this._reportVideoText;
    }
    return '';
  }

  // translated OnInit
  private _reportVideoText = 'Report video';

  constructor(private flagService: FlagService, private translate: TranslateService) {}

  ngOnInit() {
    this.translate
      .get([
        'REPORT.VIDEO',
        'REPORT.CHILD_ABUSE',
        'REPORT.CHILD_ABUSE_DESC',
        'REPORT.TERRORISM',
        'REPORT.TERRORISM_DESC',
        'REPORT.SEXUAL_CONTENT',
        'REPORT.SEXUAL_CONTENT_DESC',
        'REPORT.SEXUAL_CONTENT_1',
        'REPORT.SEXUAL_CONTENT_2',
        'REPORT.SEXUAL_CONTENT_3',
        'REPORT.SEXUAL_CONTENT_4',
        'REPORT.SEXUAL_CONTENT_5',
        'REPORT.SEXUAL_CONTENT_6',
        'REPORT.VIOLENT',
        'REPORT.VIOLENT_DESC',
        'REPORT.VIOLENT_1',
        'REPORT.VIOLENT_2',
        'REPORT.VIOLENT_3',
        'REPORT.VIOLENT_4',
        'REPORT.HATEFUL',
        'REPORT.HATEFUL_DESC',
        'REPORT.HATEFUL_1',
        'REPORT.HATEFUL_2',
        'REPORT.HATEFUL_3',
        'REPORT.HATEFUL_4',
        'REPORT.HARMFUL',
        'REPORT.HARMFUL_DESC',
        'REPORT.HARMFUL_1',
        'REPORT.HARMFUL_2',
        'REPORT.HARMFUL_3',
        'REPORT.HARMFUL_4',
        'REPORT.FRAUD',
        'REPORT.FRAUD_DESC',
        'REPORT.FRAUD_1',
        'REPORT.FRAUD_2',
        'REPORT.FRAUD_3',
        'REPORT.FRAUD_4',
        'REPORT.FRAUD_5',
        'REPORT.FRAUD_6',
        'REPORT.FRAUD_7',
        'REPORT.FRAUD_8',
        'REPORT.INFRINGE',
        'REPORT.INFRINGE_DESC',
        'REPORT.INFRINGE_1',
        'REPORT.INFRINGE_2',
        'REPORT.INFRINGE_3',
        'REPORT.CAPTIONS',
        'REPORT.CAPTIONS_DESC',
        'REPORT.CAPTIONS_1',
        'REPORT.CAPTIONS_2',
        'REPORT.CAPTIONS_3',
      ])
      .pipe(take(1))
      .subscribe((res) => {
        console.log(res);
        this._reportVideoText = res['REPORT.VIDEO'];
        this.categories = [
          {
            id: 1,
            text: res['REPORT.CHILD_ABUSE'],
            tooltip: res['REPORT.CHILD_ABUSE_DESC'],
            items: [],
          },
          {
            id: 2,
            text: res['REPORT.TERRORISM'],
            tooltip: res['REPORT.TERRORISM_DESC'],
            items: [],
          },
          {
            id: 3,
            text: res['REPORT.SEXUAL_CONTENT'],
            tooltip: res['REPORT.SEXUAL_CONTENT_DESC'],
            items: [
              { id: 301, text: res['REPORT.SEXUAL_CONTENT_1'] },
              { id: 302, text: res['REPORT.SEXUAL_CONTENT_2'] },
              { id: 303, text: res['REPORT.SEXUAL_CONTENT_3'] },
              { id: 304, text: res['REPORT.SEXUAL_CONTENT_4'] },
              { id: 305, text: res['REPORT.SEXUAL_CONTENT_5'] },
              { id: 306, text: res['REPORT.SEXUAL_CONTENT_6'] },
            ],
          },
          {
            id: 4,
            text: res['REPORT.VIOLENT'],
            tooltip: res['REPORT.VIOLENT_DESC'],
            items: [
              { id: 401, text: res['REPORT.VIOLENT_1'] },
              { id: 402, text: res['REPORT.VIOLENT_2'] },
              { id: 403, text: res['REPORT.VIOLENT_3'] },
              { id: 404, text: res['REPORT.VIOLENT_4'] },
            ],
          },
          {
            id: 5,
            text: res['REPORT.HATEFUL'],
            tooltip: res['REPORT.HATEFUL_DESC'],
            items: [
              { id: 501, text: res['REPORT.HATEFUL_1'] },
              { id: 502, text: res['REPORT.HATEFUL_2'] },
              { id: 503, text: res['REPORT.HATEFUL_3'] },
              { id: 504, text: res['REPORT.HATEFUL_4'] },
            ],
          },
          {
            id: 6,
            text: res['REPORT.HARMFUL'],
            tooltip: res['REPORT.HARMFUL_DESC'],
            items: [
              { id: 601, text: res['REPORT.HARMFUL_1'] },
              { id: 602, text: res['REPORT.HARMFUL_2'] },
              { id: 603, text: res['REPORT.HARMFUL_3'] },
              { id: 604, text: res['REPORT.HARMFUL_4'] },
            ],
          },
          {
            id: 7,
            text: res['REPORT.FRAUD'],
            tooltip: res['REPORT.FRAUD_DESC'],
            items: [
              { id: 701, text: res['REPORT.FRAUD_1'] },
              { id: 702, text: res['REPORT.FRAUD_2'] },
              { id: 703, text: res['REPORT.FRAUD_3'] },
              { id: 704, text: res['REPORT.FRAUD_4'] },
              { id: 705, text: res['REPORT.FRAUD_5'] },
              { id: 706, text: res['REPORT.FRAUD_6'] },
              { id: 707, text: res['REPORT.FRAUD_7'] },
              { id: 708, text: res['REPORT.FRAUD_8'] },
            ],
          },
          {
            id: 8,
            text: res['REPORT.INFRINGE'],
            tooltip: res['REPORT.INFRINGE_DESC'],
            items: [
              { id: 801, text: res['REPORT.INFRINGE_1'] },
              { id: 802, text: res['REPORT.INFRINGE_2'] },
              { id: 803, text: res['REPORT.INFRINGE_3'] },
            ],
          },
          {
            id: 9,
            text: res['REPORT.CAPTIONS'],
            tooltip: res['REPORT.CAPTIONS_DESC'],
            items: [
              { id: 901, text: res['REPORT.CAPTIONS_1'] },
              { id: 902, text: res['REPORT.CAPTIONS_2'] },
              { id: 903, text: res['REPORT.CAPTIONS_3'] },
            ],
          },
        ] as Reason[];
      });
  }

  onSelectCategory(event: Event) {
    const customEvent = event as SelectCustomEvent;

    this.selectedCategory = this.categories
      .filter((category) => category.id === customEvent.detail.value)
      .find(Boolean);
  }

  onSelectReason(event: Event) {
    const customEvent = event as SelectCustomEvent;

    this.selectedReason = this.selectedCategory.items
      .filter((reason) => reason.id === customEvent.detail.value)
      .find(Boolean);
  }

  initSlider(event) {
    const { detail = [] } = event;
    if (detail.length > 0) {
      const swiper = detail[0];
      console.log(swiper);
      if (swiper) {
        swiper.allowTouchMove = false;
        swiper.on('slideChange', () => {
          // slideChangeTransitionEnd
          this.slideChange();
        });
      }
    }
  }

  next() {
    if (this.currentStep.next) {
      this.nextSlide();
      this.currentStep = STEPS[this.currentStep.next];

      // make sure we skip the step, if the user selected a category that has no reasons
      if (this.currentStep.id === STEPS.subSelection.id && this.selectedCategory.items.length === 0) {
        this.currentStep = STEPS[this.currentStep.next];
      }
    }
  }

  back() {
    if (this.currentStep.back) {
      this.backSlide();
      this.currentStep = STEPS[this.currentStep.back];

      // make sure we unselect the reason, in case the user selects a different category
      if (this.currentStep.id === STEPS.selection.id) {
        this.selectedReason = undefined;
      }

      // make sure we skip the step, if the user selected a category that has no reasons
      if (this.currentStep.id === STEPS.subSelection.id && this.selectedCategory.items.length === 0) {
        this.currentStep = STEPS[this.currentStep.back];
      }
    }
  }

  sendReport() {
    this.flagService.flag(
      this.reportedItem,
      [
        `Reported category: ${this.selectedCategory.text}`,
        `Reported reason: ${this.selectedReason ? this.selectedReason.text : '-'}`,
        `Additional details: ${Utils.escapeHtml(this.additionalDetails)}`,
      ].join('<br>')
    );
    this.nextSlide();
    this.currentStep = this.STEPS.sent;
  }

  slideChange() {
    // re-disable the slide in UI
    this.swiper.allowSlideNext = false;
    this.swiper.allowSlidePrev = false;
    this.modal.scrollToTop();
  }

  private nextSlide() {
    if (!this.swiper) {
      return console.warn('no swiper?', this.swiperRef);
    }
    console.log({ allowSlideNext: this.allowSlideNext });
    this.swiper.allowSlideNext = true;
    this.swiper.slideNext();
  }

  private backSlide() {
    if (!this.swiper) {
      return console.warn('no swiper?');
    }
    console.log({ allowSlidePrev: this.allowSlidePrev });
    this.allowSlidePrev = true;
    this.swiper.allowSlidePrev = true;
    this.swiper.slidePrev();
  }
}
