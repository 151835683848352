/** @format */

import { Injectable } from '@angular/core';
import { Observable, throwError, of, lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import * as clipActions from '../store/actions/clips.actions';
import {
  selectClipIds,
  selectClipEntities,
  selectClipLoadingIds,
  selectClip,
  selectClipsByIds,
  getId,
} from '@store/selectors/clips.selectors';

import { Storage } from 'aws-amplify';
import { Utils } from '@shared/utils';
import { Clip, DEFAULT_POSTER, sortRecent, HLS_META_TRANSCODE } from '@shared/models/clip.model';
import { ClipsApiService, S3UploadResponse } from '../api/clips-api.service';
import { UpdateParam } from '../api/api-types';
import { YoutubeService } from './youtube.service';
// import _partition from 'lodash/partition';

const DEBUG_LOGS = false;

const UPLOAD_TIMEOUT_POST_TRANSCODE_MS = 30000; // wait ms before "_AFTER_UPLOAD" actions (20sec)
// const CREATE_CLIP_AFTER_UPLOAD: boolean = false; // after upload, get the new Clip from API and add to store
// export const ADD_TO_MYSTACK_AFTER_UPLOAD: boolean = false; // if CreateClip, also add it to MyStack

/**
 * chars to keep of filename, to avoid failures in transcoding
 */
const MAX_TITLE_LENGTH_CLIP_TITLE = 32;

/**
 * take an uploaded Video filename and convert to app filename
 */
export const makeNewFilename = (str): string => {
  const defaultName = 'filmclip';
  const filename = typeof str === 'string' && str.length > 0 ? str : defaultName;

  if (filename.length > MAX_TITLE_LENGTH_CLIP_TITLE) {
    const split = MAX_TITLE_LENGTH_CLIP_TITLE / 2;
    const front = str.substring(0, split - 1); // leave a char for the _
    const back = str.substring(str.length - split);
    return `${front}_${back}`;
  }
  return filename;
};

const PAGE = '[ClipsCoreService]';

@Injectable({
  providedIn: 'root',
})
export class ClipsCoreService {
  uploadedClips: string[] = []; // todo: move to store, but for now, keep them

  /*
   * ngrx Store connections
   * why are Entity ids <string[] | number[]> ?
   */
  getClipIds$: Observable<string[] | number[]> = this.store.select(selectClipIds);
  getClipEntities$: Observable<{ [id: string]: Clip }> = this.store.select(selectClipEntities);
  getClipLoadingIds$: Observable<string[]> = this.store.select(selectClipLoadingIds);

  constructor(private store: Store<State>, private clipApi: ClipsApiService, private youtubeService: YoutubeService) {}

  setClipIsApproved({
    projectId,
    id,
    userId,
    isApproved = true,
  }: {
    projectId: string;
    id: string;
    userId: string;
    isApproved: boolean;
  }) {
    this.store.dispatch(clipActions.setApproved({ projectId, id, userId, isApproved }));
  }

  /**
   * Add / Update Clip in Store
   */
  updateClipStore(clip: Clip): void {
    DEBUG_LOGS && console.log(`${PAGE} update Clip store`, clip);
    this.store.dispatch(clipActions.updateClip({ clip }));
  }
  updateClips(clips: Clip[]): void {
    DEBUG_LOGS && console.log(`${PAGE} update Clip store`, clips);
    this.store.dispatch(clipActions.addClips({ clips }));
  }

  updateClip(clip: Clip, updates: UpdateParam[] = null): Observable<Clip | string> {
    if (Array.isArray(updates) && updates.length > 0) {
      DEBUG_LOGS && console.log(`${PAGE} update Clip api`, updates, clip);
      return this.updateClipApi(clip, updates).pipe(
        take(1),
        map((res) => {
          DEBUG_LOGS && console.log(`${PAGE} update Clip store`, res);
          this.store.dispatch(clipActions.updateClip({ clip: res }));
          return res;
        })
      );
    } else {
      DEBUG_LOGS && console.log(`${PAGE} update Clip store`, clip);
      this.store.dispatch(clipActions.updateClip({ clip }));
      return of('No API Changes, updated store.');
    }
  }

  /**
   * create a string id
   * @param username
   * @param titleId
   */
  createClipId(username, titleId) {
    return Utils.urlSafeString(username + '_-_' + titleId + '_' + Utils.urlDate(new Date()));
  }

  /**
   * create a string id for uploading temp video for clipper
   * @param username
   * @param titleId
   */
  createClipperUploadClipId(username, titleId, prependUsername = true) {
    return Utils.urlSafeString((prependUsername ? username + '_-_' : '') + titleId + '_' + Utils.urlDate(new Date()));
  }

  /**
   * Amplify Storage (S3) access
   * Currently UNUSED
   */
  getClipFromStorage(key: string) {
    return Storage.get(key).then((link) => ({
      link: link as string,
      src: key,
    }));
  }
  /**
   * Amplify Storage (S3) access
   * Currently UNUSED
   * now path is: filestackConfig.storeTo.path
   */
  listUserClipsFromStorage(username: string) {
    return Storage.list(`uploads/videos/${username}_-_`).then((res) => res);
  }

  /**
   * Delete Clip from Amplify Storage (S3)
   * @see my-stack-capture-item.doUpload()
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteClipFromUserStorage(key: string): Promise<any> {
    DEBUG_LOGS && console.log(`${PAGE} deleteClipFromUserStorage`, key);
    return Storage.remove(key).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} deleteClipFromUserStorage`, res);
      return res;
    });
  }

  /**
   * Get Clip By ID
   */
  getClip(projectId: string, id: string): Observable<Clip> {
    this.store.dispatch(clipActions.load({ projectId, id })); // ensure it's in the store (Effect)
    return this.store.select(selectClip(getId(projectId, id)));
  }

  /**
   * Get Clips By IDs
   * select from Store and return vals as Observable
   */
  selectClips(ids: { projectId: string; id: string }[]): Observable<Clip[]> {
    // DEBUG_LOGS &&
    console.log(`${PAGE} selectClips:`, { ids });
    this.store.dispatch(clipActions.loadBatchIds({ ids }));
    return this.store.select(selectClipsByIds(ids.map((c) => getId(c.projectId, c.id))));
  }

  /**
   * upload clip and add to store
   * @param clip
   */
  createClip(clip: Clip, cbLoadingProgress = null): Promise<Clip> {
    DEBUG_LOGS && console.log(`${PAGE} createClip`, clip);
    // TODO: first, check if it already exists

    // add the new HLS transcoding instruction
    if (!clip.hlsMeta) {
      clip.hlsMeta = {};
    }

    clip.hlsMeta = {
      ...clip.hlsMeta,
      ...HLS_META_TRANSCODE,
    };

    return this.clipApi
      .createClip(clip, cbLoadingProgress)
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} createClip res:`, res);
        return new Clip(res);
      })
      .catch((err) => {
        if (
          err &&
          Array.isArray(err.errors) &&
          err.errors.length > 0 &&
          err.errors[0] &&
          err.errors[0].errorType &&
          err.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'
        ) {
          console.info(`${PAGE} createClip already Exists:`, err);
          return clip;
        }
        console.error(`${PAGE} createClip err:`, err);
        if (err && Array.isArray(err.errors) && err.errors.length > 0 && err.errors[0].message) {
          throw err.errors[0].message;
        }
        throw err;
      });
  }

  // private _checkTranscodedTimeout;
  // private _checkTranscodedIteration: number = 0; // just for dev logging
  /**
   * UPLOAD_TIMEOUT_POST_TRANSCODE_ACTIONS - tried delaying by up to 3 seconds,
   * but it takes around 10sec before transcode complete and then s3 moved
   *
   * @todo: graphql subscription (or Pub-Sub) to notify when done
   */
  waitForClipTranscoded(clip: Clip, maxIterations = 7): Promise<Clip> {
    const delay = UPLOAD_TIMEOUT_POST_TRANSCODE_MS;
    let iteration = 0;

    if (!clip.projectId || !clip.id) {
      return Promise.reject('Missing required args');
    }

    // v2 refactor , based on: https://stackoverflow.com/questions/38213668/promise-retry-design-patterns
    // consider refactor to Util using rxjs retryWhen https://www.learnrxjs.io/operators/error_handling/retrywhen.html

    const processResult = (res) => {
      DEBUG_LOGS && console.log(`Clip Transcoded in ${(iteration * delay) / 1000} seconds (${iteration} times)`);
      return res;
    };
    const errorHandler = (err) => {
      console.warn(err);
      throw new Error(`Tried ${iteration} times - Transcode not done yet (${(iteration * delay) / 1000} seconds)`);
    };

    const rejectDelay = (reason) =>
      new Promise((_resolve, reject) => {
        setTimeout(reject.bind(null, reason), delay);
      });

    const attempt = () => {
      iteration++;
      DEBUG_LOGS && console.log(`${PAGE} checkClipTranscoded -> getClip (iter=${iteration})...`);
      return this.clipApi.getClip(clip.projectId, clip.id);
    };

    const test = (testClip) => {
      DEBUG_LOGS && console.log(`${PAGE} testing clip transcoded (iter=${iteration})...`);
      const transcodeSuccess = testClip && Array.isArray(testClip.sources) && testClip.sources.length > 0;
      if (transcodeSuccess) {
        return testClip;
      } else {
        // do it again...
        throw new Error(`Clip not yet transcoded. (iter=${iteration})`);
      }
    };

    let p: Promise<void> = Promise.reject();
    for (let i = 0; i < maxIterations; i++) {
      p = p.catch(attempt).then(test).catch(rejectDelay);
    }
    return p.then(processResult).catch(errorHandler);
  }

  /**
   * just upload clip
   * @param clip
   */
  uploadClip(clip: Clip, cbLoadingProgress = null): Promise<S3UploadResponse> {
    DEBUG_LOGS && console.log(`${PAGE} uploadClip`, clip);

    return this.clipApi.saveVideoFileToS3(clip, cbLoadingProgress).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} saveVideoFileToS3 res:`, res);
      if (res.error) {
        throw new Error(res.error);
      }
      return res;
    });
  }

  /**
   * graphql:
   * getClipsByProject(projectId: ID!, filter: TableClipFilterInput, limit: Int, nextToken: String ): ClipConnection
   */
  getClipsForProject(
    projectId: string,
    limit = 50,
    nextToken = null
  ): Promise<{ clips: Clip[]; nextToken: string; error?: string }> {
    // appsync: getClipsByProject(projectId: ID!, filter: TableClipFilterInput, limit: Int, nextToken: String ): ClipConnection
    return lastValueFrom(this.clipApi.getClipsForProject(projectId, limit, nextToken))
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} getClipsForProject`, res);
        if (res && Array.isArray(res.items)) {
          const clips: Clip[] = res.items.map((clip) => new Clip(clip)).sort(sortRecent);
          this.store.dispatch(clipActions.addClips({ clips }));
          return { clips, nextToken: res.nextToken };
        } else {
          console.warn(`${PAGE} getClipsForProject !res || !Array??`, res);
          return { clips: [], nextToken: '', error: 'items_not_array' };
        }
      })
      .catch((err) => {
        console.error(`${PAGE} getClipsForProject Error`, err);
        throw err;
        // return { clips: [], nextToken: '',  error: (err && err.message) ? err.message : 'unknown_error' };
      });
  }
  // Simon's branch:
  //   ).pipe(
  //     take(1)
  //   ).subscribe(res => console.log(`${PAGE} loadClip res:`,res));
  // }

  getClipsForUser(
    userId: string,
    limit = 50,
    nextToken = null
  ): Promise<{ clips: Clip[]; nextToken: string; error?: string }> {
    return this.clipApi
      .getClipsForUser(userId, limit, nextToken)
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} getClipsForUser`, res);

        let clips: Clip[] = [];
        if (res && Array.isArray(res.items)) {
          clips = res.items.map((clip) => new Clip(clip)).sort(sortRecent);
          this.store.dispatch(clipActions.addClips({ clips }));
          return { clips, nextToken: res.nextToken };
        } else {
          console.warn(`${PAGE} getClipsForUser !res || !Array??`, res);
          return { clips: [], nextToken: '', error: 'items_not_array' };
        }
      })
      .catch((err) => {
        console.error(`${PAGE} getClipsForUser Caught:`, err);
        if (err && Array.isArray(err.errors) && err.errors.length > 0) {
          console.error(`${PAGE} getClipsForUser Error:`, err.errors[0]);
        }
        throw err;
      });
  }

  /**
   * Get Clip Description or default res if not found
   * @param res (string): return value if no description found
   */
  getDescription(clip: Clip, res: string = '') {
    if (!clip) {
      return res;
    }
    if (clip.description) {
      return clip.description;
    } else if (clip.youtube_id && clip.source && clip.source.youtube_data) {
      return this.youtubeService.getMetadataDescription(clip.source.youtube_data) || '';
    } else {
      return res;
    }
  }

  /**
   * @deprecated use app/clips/shared/services/clips.service instead
   */
  getPoster(clip: Clip, res: string = 'default') {
    let poster = DEFAULT_POSTER;
    if (clip) {
      if (clip.poster) {
        poster = clip.poster;
      } else if (clip.youtube_id) {
        poster = this.youtubeService.getThumbnail(clip.youtube_id, res).url;
      }
    }
    return poster;
  }

  /**
   * @deprecated use method in clipsModule/shared/services/clips.service
   * Take the poster string and return poster time from the transcoded name
   * note that the poster is 1-based, so we need to subtract 1
   */
  getPosterTimeFromPoster(clip: Clip) {
    if (clip && clip.poster) {
      const sNum = clip.poster.substring(clip.poster.lastIndexOf('--'), clip.poster.lastIndexOf('.'));
      const sTime = sNum.replace(/-/g, '').replace(/0/gi, '');
      try {
        const parsed = parseInt(sTime, 10);
        return parsed > 0 ? parsed - 1 : 0;
      } catch (error) {
        return 0;
      }
    }
    return 0;
  }

  /**
   * @deprecated use method in clipsModule/shared/services/clips.service
   * Update the poster image to match the new posterTime
   *
   * TODO: verify it exists in s3
   * potential ref: https://stackoverflow.com/questions/9815762/detect-when-an-image-fails-to-load-in-javascript
   *
   * https://videos.filmstacker.com/public/filmstacker-dev/jd_-_jd-note_202203021348/jd_-_jd-note_202203021348_thumb.0000000.jpg
   */
  updatePosterToPosterTime(clip: Clip, posterTime: number) {
    if (clip && clip.poster) {
      let newPoster = clip.poster;
      // let posterName = Utils.removeFileExt(clip.poster);
      const num = clip.poster.substring(clip.poster.lastIndexOf('--'), clip.poster.lastIndexOf('.'));
      if (num.length > 2 && typeof posterTime === 'number') {
        // Note that posterTime is index 0, but the poster images were transcoded with index of 1
        let sTime = (Math.floor(posterTime) + 1).toString();
        // make a string equal to num.length - 2 (not counting the dashes)
        while (sTime.length < num.length - 2) sTime = '0' + sTime;

        newPoster = newPoster.replace(num, `--${sTime}`);

        console.info(
          `${PAGE} change clip.poster (TODO: verify it exists in s3) posterTime: ${posterTime} to:`,
          newPoster
        );
      } else {
        console.warn(`${PAGE} clip.poster - unable to find poster number substr?`, clip.poster);
      }
      return newPoster;
    } else {
      console.warn(`${PAGE} missing clip.poster - unable to change`, clip);
      return null;
    }
  }

  /**
   * upload clip and add to store
   * @param clip
   */
  deleteClip(clip: Clip): Promise<Partial<Clip>> {
    DEBUG_LOGS && console.log(`${PAGE} deleteClip`, clip);
    return this.clipApi
      .deleteClip(clip.projectId, clip.id)
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} deleteClip res:`, res);
        this.store.dispatch(clipActions.deleteClip({ clip }));
        // return 'Clip deleted';
        return res;
      })
      .catch((err) => {
        console.error(`${PAGE} deleteClip err:`, err);
        throw err;
      });
  }

  /**
   * Update a Clip - supply the Clip and an array of UpdateParam{ prop: string, value: any }
   * @param clip
   * @param updates
   * @returns the changed props + ids
   */
  private updateClipApi(clip: Clip, updates: UpdateParam[]): Observable<Clip> {
    // could return a Promise from api if desired...
    if (!clip || !clip.projectId || !clip.id) {
      return throwError(() => new Error('Missing Required Clip ids'));
    }
    return this.clipApi.updateClip(clip, updates);
  }
}
