<!-- @format -->

<ion-button #ionButton
  class="fs-button"
  strong="true"
  [expand]="blocked ? 'block' : null"
  [color]="color"
  [fill]="buttonStyle === 'clear' ? 'clear' : buttonStyle === 'outline' ? 'outline' : 'solid'"
  [type]="type"
  [ngClass]="{
    'fs-button--primary': buttonStyle === 'primary',
    'fs-button--outline': buttonStyle === 'outline',
    'fs-button--clear': buttonStyle === 'clear',
    'fs-button--large': size === 'large',
    'fs-button--no-padding': iconOnly
  }"
  [disabled]="disabled"
>
  <div class="fs-button__content">
    <ion-spinner *ngIf="loading" slot="start" name="crescent"></ion-spinner>
    <ng-content></ng-content
    ><ion-icon
      *ngIf="icon"
      style="margin: -5px 0 -5px 10px; position: relative; top: -3px;"
      [slot]="iconOnly ? 'icon-only' : 'end'"
      [name]="icon"
    ></ion-icon>
  </div>
</ion-button>
