/**
 * MVP-1128 -> MVP-1245
 * https://github.com/ionic-team/ionic-storage/pull/269
 * As a temporary workaround until a new release is made, you can copy this file into your project
 * and replace the @ionic/storage-angular with @ionic/storage
 * file below from: https://github.com/ionic-team/ionic-storage/blob/152c32dd965825601be97cabc0cc713ac1373fb3/angular/src/index.ts
 *
 * @format
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any */
import { isPlatformServer } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { InjectionToken, NgModule, PLATFORM_ID } from '@angular/core';
import { Storage, StorageConfig, Drivers } from '@ionic/storage';
// todo: MVP-1245 convert to ionic/storage-angular
// import { Storage, StorageConfig } from '@ionic/storage-angular';

// eslint-disable-next-line @typescript-eslint/naming-convention
const StorageConfigToken = new InjectionToken<any>('STORAGE_CONFIG_TOKEN');

export { StorageConfig, StorageConfigToken, Storage, Drivers };

class NoopStorage extends Storage {
  constructor() {
    super();
  }

  async create() {
    return this;
  }
  async defineDriver() {}

  get driver(): string | null {
    return 'noop';
  }

  async get(key: string) {
    return null;
  }

  async set(key: string, value: any) {}

  async remove(key: string): Promise<any> {}

  async clear(): Promise<void> {}

  async length(): Promise<number> {
    return 0;
  }

  async keys() {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async forEach(iteratorCallback: (value: any, key: string, iterationNumber: Number) => any): Promise<void> {}

  setEncryptionKey(key: string) {}
}

export function provideStorage(platformId: any, storageConfig: StorageConfig): Storage {
  if (isPlatformServer(platformId)) {
    // When running in a server context return the NoopStorage
    return new NoopStorage();
  }

  return new Storage(storageConfig);
}

@NgModule()
export class IonicStorageModule {
  static forRoot(storageConfig: StorageConfig = null): ModuleWithProviders<IonicStorageModule> {
    return {
      ngModule: IonicStorageModule,
      providers: [
        { provide: StorageConfigToken, useValue: storageConfig },
        {
          provide: Storage,
          useFactory: provideStorage,
          deps: [PLATFORM_ID, StorageConfigToken],
        },
      ],
    };
  }
}
