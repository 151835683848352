/** @format */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of, from, EMPTY } from 'rxjs';
import { tap, filter, withLatestFrom, map, catchError, mergeMap, concatMap } from 'rxjs/operators';
import _difference from 'lodash/difference';
import { State } from '../reducers/index'; // eslint-disable-line  @typescript-eslint/no-unused-vars
import * as clipActions from '../actions/clips.actions';
// import * as historyActions from '../actions/history.actions'; // todo: MVP-1160 history of stacks + progress
// import * as mystackActions from '../actions/mystack.actions';
import { getId, selectClipEntities, splitId } from '../selectors/clips.selectors';
import { selectClipsState, selectClipIds } from '../selectors/clips.selectors';
import { ClipsApiService, DEFAULT_FETCH_LIMIT, MAX_FETCH_LIMIT } from '@app/core/api//clips-api.service';
import { SentryService } from '@services/analytics/sentry.service';
import { FilterEntity } from '@store/reducers/viewstate.reducers';
import { UpdateParam } from '@app/core/api/api-types';
import { Clip } from '@shared/models/clip.model';

// export const SEARCH_DEBOUNCE = new InjectionToken<number>('Search Debounce');
// export const SEARCH_SCHEDULER = new InjectionToken<Scheduler>(
//   'Search Scheduler'
// );

/*
  https://blog.angularindepth.com/switchmap-bugs-b6de69155524
  
  switchMap should only be used in effects/epics for read actions 
  and only when the backend response is not required after another action of the same type is dispatched
  
  With actions for which the ordering is important, concatMap should be used
*/

/**
 * If no project ID is sent on a filteredListQuery, use this
 * (likely shouldn't happen)
 */
const DEFAULT_PROJECT_ID = 'filmstacker-guides';
const FIELD_TO_DETERMINE_FULL_ENTITY = 'userIdentityId'; // not really needed, but it's an option

const DEBUG_LOGS = false;

const PAGE = '[ClipStoreEffects]';

@Injectable()
export class ClipEffects {
  /**
   * On Add, get the entity if not exists..
   */
  subUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clipActions.subUpdate.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectClipEntities)))),
        map(([{ clip }, state]) => ({ clip, state })),
        filter(({ clip, state }) => clip && clip.projectId && clip.id && clip.id.length > 0),
        mergeMap(({ clip, state }) => {
          // we already checked if (item.userId === userId || myProjectIds.includes(item.projectId))
          const { projectId, id: clipId } = clip;
          const id = getId(projectId, clipId);
          if (!state[id] || !state[id].id) {
            // so we do indeed want this, but it does not currently exist - allow the Effect to handle loading it
            DEBUG_LOGS && console.warn(`[ClipsEffect] subUpdate new:`, { clip });
            return from(this.clipApi.getClip(projectId, clipId)).pipe(
              filter((res) => res && res.id === clipId),
              mergeMap((newClip) => [clipActions.addClip({ clip })])
            );
          }
          return EMPTY;
        }),
        catchError((error) => {
          this.captureError(error, 'subUpdate caught');
          this.sentryService.captureError(error);
          return EMPTY;
        })
      )
    // { dispatch: false },
  );

  /**
   * On Approve Stack
   */
  setClipApproved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clipActions.setApproved.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectClipsState)))),
        filter(([action, state]) => action && action.projectId && action.id && action.id.length > 0),
        mergeMap(([{ projectId, id, userId, isApproved }, state]) => {
          const prop = 'isApproved';
          const updates: UpdateParam[] = [
            {
              prop,
              value: isApproved,
            },
          ];
          DEBUG_LOGS && console.log(`${PAGE} action -> api`, { updates, projectId, id, userId, isApproved });
          return this.clipApi.updateClip(new Clip({ projectId, id, userId }), updates);
        }),
        catchError((error) => {
          this.captureError(error, 'setStackApproved caught');
          this.sentryService.captureError(error);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  /**
   * v2.8 ListsStore
   */
  loadFilteredClips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clipActions.loadFilteredClips.type, clipActions.loadMoreFilteredClips.type),
      mergeMap(({ listId, filters, limit, nextToken, apiLimit }) => {
        filters = filters as FilterEntity;
        //  const projectId = (filters as FilterEntity)?.projectId;
        //  const userId = (filters as FilterEntity)?.userId;
        //  const filterType = (filters as FilterEntity)?.type;
        limit = typeof limit === 'number' && limit > 0 ? limit : DEFAULT_FETCH_LIMIT;
        apiLimit = typeof apiLimit === 'number' && apiLimit > 0 ? apiLimit : limit;
        let numReceived = 0;
        // DEBUG_LOGS && console.log(`${PAGE} loadFilteredClips$ check limits`, { limit, apiLimit, projectId, listId, filters });

        DEBUG_LOGS &&
          console.log(`loadFilteredClips dev:`, {
            projectId: filters?.projectId,
            userId: filters?.userId,
            filterType: filters?.type,
            filters,
          });
        /**
         * IIFE get API query observable based on action payload
         *
         * if (projectId) => queryFilteredStacksByProject
         * else if (userId) => queryStacksByUser
         * else queryFilteredStacks
         */
        const query$: Observable<{ items: Clip[]; nextToken: string }> = ((_filters) => {
          const { projectId, userId, type } = _filters;
          // switch (type) {
          //   case FilterEntityTypes.Unused:
          //   case FilterEntityTypes.Recent:
          //   default:
          // }
          if (projectId) {
            return this.clipApi.queryClipsByProject({ projectId, limit: apiLimit, filters: _filters, nextToken });
          } else if (userId) {
            return this.clipApi.queryClipsByUser({ userId, limit: apiLimit, filters: _filters, nextToken });
          } else {
            return this.clipApi.queryClipsByProject({
              projectId: DEFAULT_PROJECT_ID,
              limit: apiLimit,
              filters: _filters,
              nextToken,
            });
          }
        })(filters);

        /**
         * @deprecated refactored to IIFE query$
         */
        // const query =
        //   filters?.projectId
        //   ? this.clipApi.queryClipsByProject({ projectId: filters?.projectId, limit: apiLimit, filters, nextToken })
        //   : filters?.userId
        //     ? this.clipApi.queryClipsByUser({ userId: filters?.userId, limit: apiLimit, filters, nextToken })
        //     : this.clipApi.queryClipsByProject({ projectId: DEFAULT_PROJECT_ID, limit: apiLimit, filters, nextToken });

        return query$.pipe(
          map((result) => {
            const { items, nextToken: token } = result;
            // check if we got enough items
            numReceived += Array.isArray(items) && items.length > 0 ? items.length : 0;
            // const currentItems = state && state[listId] && Array.isArray(state[listId].itemIds) ? state[listId].itemIds : [];
            if (numReceived < limit && token && token.length > 0) {
              /**
               * we need more items, loadMore
               * @todo the timeout needs tuned...
               */
              const newApiLimit = (limit + apiLimit) * 2 < MAX_FETCH_LIMIT ? (limit + apiLimit) * 2 : MAX_FETCH_LIMIT;
              const leftToGet = limit - items.length;
              DEBUG_LOGS &&
                console.log(`${PAGE} loadFilteredClips$ (${listId}) we need more items...(leftToGet: ${leftToGet})`, {
                  newApiLimit,
                  leftToGet,
                  limit,
                  apiLimit,
                  projectId: filters?.projectId,
                  listId,
                  filters,
                  items,
                  tokenExists: token && token.length > 0,
                });
              this.store$.dispatch(
                clipActions.loadMoreFilteredClips({
                  listId,
                  filters,
                  nextToken: token,
                  limit: leftToGet,
                  apiLimit: newApiLimit,
                })
              );
              // setTimeout(() => {
              //   DEBUG_LOGS && console.warn(`${PAGE} loadFilteredClips$ (${listId}) DELAYED more items...(leftToGet: ${leftToGet})`, { limit, leftToGet, apiLimit: newApiLimit, projectId, listId, filters, token });
              // }, 1000);
            } else if ((!token || token.length < 1) && Array.isArray(items) && items.length < 1) {
              DEBUG_LOGS &&
                console.log(`${PAGE} loadFilteredClips$ (${listId}) NO MORE TO LOAD`, {
                  items,
                  projectId: filters?.projectId,
                  listId,
                  filters,
                  limit,
                  apiLimit,
                  tokenExists: token && token.length > 0,
                });
              this.store$.dispatch(clipActions.noMoreToLoad({ listId }));
            } else {
              DEBUG_LOGS &&
                console.log(`${PAGE} loadFilteredClips$ api result`, {
                  items,
                  projectId: filters?.projectId,
                  listId,
                  filters,
                  limit,
                  apiLimit,
                  tokenExists: token && token.length > 0,
                });
            }
            return result;
          }),
          // if we have any items, also loadSuccess
          filter(({ items }) => Array.isArray(items) && items.length > 0),
          map((result) => {
            const { items, nextToken: token } = result;
            return clipActions.loadSuccess({
              clips: items,
              listId,
              nextToken: token,
              filters,
              isLoadMore: !!nextToken, // if the request was done with a nextToken, the items will be appended
            });
          }),
          catchError((error) => of(clipActions.loadFail({ id: '', error })))
        );
      })
    )
  );

  /**
   * @deprecated use loadFilteredClips$
   */
  loadClipsByProjectId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clipActions.loadClipsByProjectId.type, clipActions.loadMoreClipsByProjectId.type),
      mergeMap(({ projectId, listId, filters, limit, nextToken }) =>
        this.clipApi.getClipsForProject(projectId, limit, nextToken).pipe(
          map(({ items, nextToken: token }) =>
            clipActions.loadSuccess({
              clips: items,
              listId,
              nextToken: token,
              filters,
              isLoadMore: !!nextToken, // if the request was done with a nextToken, the items will be appended
            })
          ),
          catchError((error) => of(clipActions.loadFail({ error, id: '-' })))
        )
      )
    )
  );

  /**
   * V8 Batch Load By Ids
   * @todo verify fixed MVP-784 Bug: Clip Effects batchLoadByIds$ state doubling
   */
  batchLoadByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clipActions.loadBatchIds.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectClipIds)))),
      tap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, { action, state });
      }),
      filter(([action, state]) => Array.isArray(action.ids) && action.ids.length > 0),
      mergeMap(([action, stateIds]) => {
        /*
        Filter out the ids that are already in state, 
        get the ones that are not
        */
        const actionIds = action.ids.filter((c) => c.projectId && c.id).map((clip) => getId(clip.projectId, clip.id));
        // lodash.difference (R)eturns the values from array that are not present in the other arrays
        /** as string[] this was necessary due to ngrx entity key being of type number or string... ugg */
        const newIds = _difference(actionIds, stateIds as string[]);
        DEBUG_LOGS && console.log(`post-filter mergeMap -> batchLoadById`, { newIds, actionIds, stateIds });

        if (newIds.length < 1) {
          return EMPTY;
        }

        return this.clipApi.loadClipsByIds(newIds.map(splitId), action.stack).pipe(
          map(({ clips }) => {
            DEBUG_LOGS && console.log(`loadClipsByIds res`, { clips });

            if (clips.length !== newIds.length) {
              console.warn(
                `${PAGE} (RESET HISTORY) batchLoadByIds$ clips.length(${clips.length}) !== (${newIds.length}) actionIds.length`,
                { clips, actionIds }
              );
              // MVP-784 reset the History State
              // todo: MVP-1160 history of stacks + progress
              // this.store$.dispatch(historyActions.reset());
            }
            return clipActions.addClips({ clips });
          }),
          catchError((error) => {
            // unable to get the failing ids here due tot throwError, do it in the api
            // if (action.stack && action.stack.projectId === 'editor' && action.stack.stackId === 'mystack') {
            //   console.log(`loadClipsByIds caught`, { error, newIds })
            // }
            this.sentryService.captureError(error);
            return of(clipActions.loadFailBatch({ error, ids: newIds }));
          })
        );
      }),
      // if we see an error here, need to reset store
      // TODO: resetStore Effect to reload the app
      // catchError(error => of(new projectActions.ResetAction()))
      catchError((error) => {
        console.error(error);
        this.sentryService.captureError(error);
        return EMPTY;
        // return of(projectActions.loadFail({ error }));
      })
    )
  );
  // , {dispatch: false});

  /**
   * ngrx v7
   * ensure the entity exists in the store
   * if not, grab the full item from the api
   */

  loadClip$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(clipActions.load.type),
      // Retrieve part of the current state telling us if exists
      withLatestFrom(this.store$.select(selectClipsState)),
      tap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} action:`, action, state);
      }),
      // stop here if it's being loaded...
      filter(([{ projectId, id }, state]) => {
        const entityId = getId(projectId, id);
        // true if not already loading
        return state.loadingIds.indexOf(entityId) < 0;
      }),
      // stop here if it's already loaded...
      filter(([{ projectId, id }, state]) => {
        /** as string[] this was necessary due to ngrx entity key being of type number or string... ugg */
        const stateIds = state.ids as string[];
        const entityId = getId(projectId, id);
        // true if NOT the id is there, is an entity, is entity.field
        return !(
          stateIds.indexOf(entityId) >= 0 &&
          state.entities[entityId] &&
          state.entities[entityId][FIELD_TO_DETERMINE_FULL_ENTITY]
        );
      }),
      // it's not loaded yet...
      // set it as being loaded
      tap(([{ projectId, id }, state]) => {
        this.store$.dispatch(clipActions.loadingClip({ projectId, id }));
      }),
      // get it
      // use mergeMap with actions that should be neither aborted nor ignored and for which the ordering is unimportant
      mergeMap(([{ projectId, id }, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} load Clip:`, projectId, id);
        return from(this.clipApi.getClip(projectId, id)).pipe(
          map((clip) => {
            if (clip.error) {
              return clipActions.loadFail({ error: clip.error, id: getId(projectId, id) });
            }
            return clipActions.addClip({ clip: clip as Clip });
          }),
          catchError((error) => {
            this.sentryService.captureError(error);
            return of(clipActions.loadFail({ error, id: getId(projectId, id) }));
          })
        );
      })
    )
  );

  /**
   * Search Action - not used yet, but works
   */
  // @Effect()
  // search$: Observable<Action> = this.actions$
  //   .ofType(clipActions.SEARCH)
  //   .debounceTime(this.debounce, this.scheduler || async)
  //   .map((action: clipActions.SearchAction) => action.payload)
  //   .switchMap(query => {
  //     if (query === '') {
  //       return empty();
  //     }

  //     const nextSearch$ = this.actions$.ofType(clipActions.SEARCH).skip(1);

  //     return this.clipService
  //       .searchClips({ search: query })
  //       .takeUntil(nextSearch$)
  //       .map((clips: Clip[]) => new clipActions.SearchCompleteAction({clips}))
  //       .catch(() => of(new clipActions.SearchCompleteAction({clips:[]})));
  //   });

  constructor(
    private actions$: Actions<clipActions.ActionsUnion>,
    private store$: Store<State>,
    private clipApi: ClipsApiService, // if need scheduler (once you figure out what it is..) // @Optional()
    private sentryService: SentryService
  ) {}

  /**
   * Helper method for Errors
   */
  private captureError(error, msg = ''): void {
    const e = error && Array.isArray(error.errors) && error.errors.length > 0 ? error.errors[0] : error;
    console.warn(`${PAGE} ${msg} captureError`, e);
    this.sentryService.captureError(e);
  }

  // private debounce: number = 300,
  // /**
  //  * You inject an optional Scheduler that will be undefined
  //  * in normal application usage, but its injected here so that you can mock out
  //  * during testing using the RxJS TestScheduler for simulating passages of time.
  //  */
  // @Optional()
  // @Inject(SEARCH_SCHEDULER)
  // private scheduler: Scheduler
}
