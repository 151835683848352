/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Token } from '@tokens/shared/tokens.model';
import { lastValueFrom } from 'rxjs';

/**
 *
 * @todo Implementation Notes
 *
 * 1. once used, delete from DB
 * 2. Handle EROR: Invalid Token Id
 * 3. Re-send Invitation -> replace invites for that same user/feature combo (delete them)
 *
 *
 * API Gateway connection, not GQL
 * https://filmstacker.atlassian.net/browse/MVP-516
 * ref other api gateway: src/app/billing/api/chargebee-api.service.ts
 *
 * /tokens/
 * POST tokens/ - generate a token with payload
 * GET tokens/{id} - get a token
 */
const TOKENS_ENDPOINT = environment.tokensWidgetsApiUrl + '/tokens';

/**
 * @todo add auth token
 */
//   http.get('someurl', {
//     Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
//  });
const DEBUG_LOGS = false;

@Injectable({
  providedIn: 'root',
})
export class TokensApiService {
  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getToken(id: string): Promise<any> {
    return lastValueFrom(this.http.get(`${TOKENS_ENDPOINT}/object/${id}`))
      .then((res) => {
        DEBUG_LOGS && console.log(`[TokensAPI] getToken res:`, res);
        return res;
      })
      .catch((error) => {
        if (error && error.error && error.error.error_code === 'resource_not_found') {
          DEBUG_LOGS && console.log(`[TokensAPI] getToken token not found:`, error);
          return false;
        } else {
          console.warn(`[TokensAPI] getToken caught error:`, error);
          throw error;
        }
      });
  }

  /**
   * API endpoint needs updated to filter by projectId...
   * Build querystring ?projectId=pid&userId=uid
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listTokens({ projectId, userId }: { projectId?: string; userId?: string }): Promise<any> {
    const aQuery = [];
    let query = '';

    if (projectId) {
      aQuery.push(`projectId=${projectId}`);
    }
    if (userId) {
      aQuery.push(`userId=${userId}`);
    }
    if (aQuery.length > 0) {
      query = '?' + aQuery.join('&');
    }
    DEBUG_LOGS && console.log(`Tokens API listTokens:`, { projectId, userId, query, aQuery });
    return lastValueFrom(this.http.get(`${TOKENS_ENDPOINT}${query}`))
      .then((res) => {
        DEBUG_LOGS && console.log(`[TokensAPI] listTokens res:`, res);
        return res;
      })
      .catch((error) => {
        console.warn(`[TokensAPI] listTokens caught error:`, error);
        throw error;
      });
  }

  createToken(payload) {
    console.log(`[TokensAPI] createToken payload:`, payload);
    return lastValueFrom(
      this.http.put(
        `${TOKENS_ENDPOINT}`,
        payload
        // this.getFormUrlEncoded({
        //   ...payload,
        // }),
        // { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }
      )
    ).catch((error) => {
      console.warn(`[TokensAPI] createToken caught error:`, error);
      if (error.error && error.error.error) {
        throw error.error.error;
      }
      throw error;
    });
  }

  /**
   * deleteToken
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteToken(id: string): Promise<any> {
    DEBUG_LOGS && console.log(`[TokensAPI] deleteToken id:`, id);
    return lastValueFrom(this.http.delete(`${TOKENS_ENDPOINT}/object/${id}`))
      .then((res) => {
        DEBUG_LOGS && console.log(`[TokensAPI] deleteToken res:`, res);
        return res;
      })
      .catch((error) => {
        if (error && error.error && error.error.error_code === 'resource_not_found') {
          DEBUG_LOGS && console.log(`[TokensAPI] deleteToken token not found:`, error);
          return false;
        } else {
          console.warn(`[TokensAPI] deleteToken caught error:`, error);
          throw error;
        }
      });
  }

  /**
   * Instead of direct delete, apply with Pinpoint events in api?
   * and delete all duplicates for this invite token
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applyToken(token: Partial<Token> = {}): Promise<any> {
    return this.update({
      ...token,
      accepted: true,
    });
    // // how it was before.. in tokens.service
    // return this.deleteToken(payload.id);
  }

  enableToken({ tokenId, expires, userId }: { tokenId: string; expires?: string; userId: string }) {
    return this.update({
      id: tokenId,
      ...(expires ? { expires } : {}),
      isActive: true,
      updatedBy: userId,
    });
  }
  disableToken({ tokenId, expires, userId }: { tokenId: string; expires?: string; userId: string }) {
    return this.update({
      id: tokenId,
      ...(expires ? { expires } : {}),
      isActive: false,
      updatedBy: userId,
    });
  }

  /**
   * Example of Post
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(token: Partial<Token> = {}): Promise<any> {
    if (!token.id) {
      return Promise.reject(`Missing ID`);
    }

    console.log(`update token`, token);
    // return Promise.resolve('dev');

    return lastValueFrom(
      this.http.post(
        `${TOKENS_ENDPOINT}`,
        token
        // { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }
      )
    ).catch((error) => {
      console.warn(`[TokensAPI] put caught error:`, error);
      throw error;
    });
  }

  private getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property in toConvert) {
      if ({}.hasOwnProperty.call(toConvert, property)) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(toConvert[property]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
    }
    return formBody.join('&');
  }
}
