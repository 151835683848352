/** @format */

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { selectMyStackClipIds } from '@store/selectors/mystack.selectors';
import { getId } from '@store/selectors/clips.selectors';
import { ITEM_TYPE, LayoutOptions, DiscoverCardClick, FlexiblePartialItem } from '@shared/models/layout.model';
import { UserService } from '@services/user.service';
import { Stack } from '@shared/models/stack.model';
import { Clip } from '@shared/models/clip.model';

export class GridOptions extends LayoutOptions {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fs-grid',
  templateUrl: './fs-grid.component.html',
  styleUrls: ['./fs-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FsGridComponent {
  @Input() items: FlexiblePartialItem[];
  @Input() itemType: ITEM_TYPE;
  @Input() heading?: string;
  @Input() isLoading = false;
  @Input() canEdit: boolean = false;
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;

  /**
   * @Input Setter Getter
   * https://stackoverflow.com/a/40764091/4982169
   */
  @Input()
  set options(obj: object) {
    this._options = new GridOptions(obj);
  }
  get options(): GridOptions {
    return this._options;
  }

  myStackClipIds$: Observable<string[]> = this.store.select(selectMyStackClipIds);

  @Output() itemClick = new EventEmitter<DiscoverCardClick>();

  itemTypes = ITEM_TYPE;

  private _options: GridOptions;

  constructor(private store: Store<State>, public userService: UserService) {}

  onClickItem(item) {
    this.itemClick.emit(item);
  }

  // getClipId = getId;
  public getClipId(item: FlexiblePartialItem): string {
    const { projectId, id } = item as Clip;
    return getId(projectId, id);
  }

  public asStack(item: FlexiblePartialItem): Stack {
    return item as Stack;
  }

  public asClip(item: FlexiblePartialItem): Clip {
    return item as Clip;
  }
}
