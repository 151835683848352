/** @format */

import { Injectable } from '@angular/core';
import { Utils } from '@shared/utils';
import { extractStackUpdates, Stack, StackDraftPublishInput, StackPublishInput, STACK_PRIVACY } from '@shared/models/stack.model';
import { HLS_META_UPGRADE } from '@shared/models/clip.model';
import { StacksService } from './stacks.service';
import { MyStackService } from './mystack.service';
import { UserService } from './user.service';
import { SentryService } from './analytics/sentry.service';
import { StacksApiService, DEFAULT_STACK_POSTER_IMG } from '../api/stacks-api.service';
import { ConfigService } from '@app/core/config';
import { PROJECT_MEMBER_ROLE } from '@projects/shared/project.model';
import * as stackActions from '@store/actions/stacks.actions';
import * as mystackActions from '@store/actions/mystack.actions';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { AnalyticsService } from './analytics/analytics.service';
import { ProjectService } from '@projects/shared/services';
import { ProjectRole } from './analytics/google-analytics.service';

export const DEFAULT_STACK_POSTER = DEFAULT_STACK_POSTER_IMG;

const DEBUG_LOGS = false;
const PAGE = '[PublishService]';

/**
 * create shareUrl similar to
 * https://stacks.filmstacker.com/projectId/Cody-Sheehy-AKA-Masterstcker_CAP-Water-is-Critical-To-Tucson_2016216
 * with an index.html created by aws lambda based on the uploaded stackUrl.json
 */
@Injectable({
  providedIn: 'root',
})
export class PublishStackService {
  constructor(
    private stackApi: StacksApiService,
    private stackService: StacksService,
    private mystackService: MyStackService,
    private projectService: ProjectService,
    private userService: UserService,
    private configService: ConfigService,
    private sentryService: SentryService,
    private analyticsService: AnalyticsService,
    private store: Store<State>
  ) {}

  /**
   * MVP-995 Save Draft
   * if there are already clips, save those too
   * @todo we need an ID for the navigation
   */
  createStackDraft(input: StackDraftPublishInput): Stack {
    DEBUG_LOGS && console.log(`${PAGE} saveStackDraft input:`, input);
    if (!input.projectId) {
      throw new Error('No projectId found - please add clips and try again.');
    }
    const { stack } = extractStackUpdates(input, DEBUG_LOGS);
    // here, instead of using "mystack" store, use stacks, set currentEditStackId
    this.store.dispatch(stackActions.addDraft({ stack }));
    DEBUG_LOGS && console.log(`${PAGE} createStackDraft addDraft...`, stack);
    return stack;
  }
  saveStackUpdates(input: StackPublishInput | Partial<Stack>): Stack {
    DEBUG_LOGS && console.log(`${PAGE} saveStackUpdates input:`, input);
    if (!input.projectId) {
      throw new Error('No projectId found - please add clips and try again.');
    }
    const { stack, updates } = extractStackUpdates(input, DEBUG_LOGS);
    this.store.dispatch(mystackActions.updateMyStack({ stack, updates }));
    // doing the stack update in effect
    // this.store.dispatch(stackActions.update({ stack, updates }));
    return stack;
  }

  /**
   * Called by stack-form-details
   * could be a save stack process as well
   */
  async publishStack(input: StackPublishInput | Stack): Promise<Stack> {
    // save the views and shares on re-publish by passing the input to constructor MVP-1265
    const stack = new Stack(input);
    DEBUG_LOGS && console.log(`${PAGE} PublishStack input:`, input);

    if ((input?.clips ?? []).length === 0) {
      /*
        Moved this check into the caller to avoid error..
        Error: Uncaught (in promise): Error: No playlist found - please add clips and try again.
      */
      throw new Error('No playlist found - please add clips and try again.');
    }

    const now = new Date();
    const isoDate = now.toISOString();
    stack.dtePublished = isoDate;

    stack.projectId = input.projectId;
    stack.stackId = input.stackId ? input.stackId : Utils.autoId(input.title, input.userId);

    /**
     * Handle Config for Widget
     */
    try {
      const appConfig = await this.configService.appConfig;//.then((appConfig) => {
      if (appConfig.isWidgetActive) {
        if (appConfig.stackShareUrl) {
          if (appConfig.stackShareUrlIncludesProject) {
            stack.shareDomain = `${appConfig.stackShareUrl}${stack.projectId}/${stack.stackId}`;
          } else {
            stack.shareDomain = `${appConfig.stackShareUrl}${stack.stackId}`;
          }
        }
      } else if ((input as StackPublishInput).project && (input as StackPublishInput).project.config) {
        /**
         * Check Project config for widget too
         */
        const config = Utils.tryParseJSON((input as StackPublishInput).project.config);
        DEBUG_LOGS && console.log(`${PAGE} PublishStack project config:`, { config });
        if (config && config.shareStacksToDomain && config.stackShareDomainUrl) {
          if (config.stackShareDomainUrlIncludesProject) {
            stack.shareDomain = `${config.stackShareDomainUrl}${stack.projectId}/${stack.stackId}`;
          } else {
            stack.shareDomain = `${config.stackShareDomainUrl}${stack.stackId}`;
          }
        }
      }
      // });
    } catch (error) {
      this.sentryService.captureError(error);
    }
    

    try {
      stack.title = input.title.trim();
      stack.description = (input.description || '').trim();
      stack.private = input.private;
      stack.privacy = input.private ? STACK_PRIVACY.PRIVATE : STACK_PRIVACY.PUBLIC;
      stack.shareUrl = this.stackApi.createShareUrl(stack.projectId, stack.stackId);
      stack.playlist = input.clips.map((clip, index) => ({
        projectId: clip.projectId,
        id: clip.id,
        order: index,
      }));
      // calc the total duration for the stack
      const durations = input.clips.filter((clip) => clip && clip.duration).map((clip) => clip.duration);
      stack.duration = Utils.getTotalDuration(durations);

      /** this is the key to make it a DRAFT */
      stack.dtePublished = isoDate;
      stack.dteSaved = isoDate;
      stack.userId = input.userId;
      stack.credits = input.credits;
      // handle custom poster upload - might have an auth token in the poster for ui view,
      // replace with actual src
      stack.poster = input.posterSrc || input.poster;
      // use identityId instead of avatar url
      if ((input as StackPublishInput).identityId) {
        stack.userIdentityId = (input as StackPublishInput).identityId;
      }
      stack.tags = input.tags;

      // set this to be upgraded
      stack.hlsMeta = JSON.stringify({
        // ...stack.hlsMeta || {},
        ...HLS_META_UPGRADE
      });

      this.saveUpdatesToStore({ ...stack, clips: input.clips });

      DEBUG_LOGS && console.log(`${PAGE} publishing stack...`, stack);

      /**
       * MVP-995 Stack Drafts
       */
      const createdStack = await this.stackApi.createStack(stack);
      DEBUG_LOGS && console.log(`${PAGE} api.createStack res:`, createdStack);

      // increment numStacks for each clip
      if (!createdStack || !createdStack.stackId) {
        throw new Error('Error creating stack - no ID?');
      }
      stack.shareUrl = createdStack.shareUrl;

      this.mystackService.publishStack(stack);
      this.stackService.addStack(stack); // could be updated to allow reducer to update based on mystack action, if the input was changed to stack array

      /**
       * Now watch for updates from the store...
       */
      this.stackService.subStacksUpdated(stack.projectId, stack.stackId);

      // update analytics
      const meMember = ((input as StackPublishInput).project && Array.isArray((input as StackPublishInput).project.members)) ? this.projectService.getRoleOfUserId((input as StackPublishInput).project, input.userId) : '';
      let role = ProjectRole.Public;
      // there's gotta be a better way to do this?
      if (meMember && meMember.role) {
        switch (meMember.role) {
          case PROJECT_MEMBER_ROLE.OWNER:
            role = ProjectRole.Owner;
            break;
          case PROJECT_MEMBER_ROLE.EXECUTIVE:
            role = ProjectRole.Executive;
            break;
          case PROJECT_MEMBER_ROLE.PRODUCER:
            role = ProjectRole.Producer;
            break;
          case PROJECT_MEMBER_ROLE.CREW:
            role = ProjectRole.Owner;
            break;
          default:
            role = ProjectRole.Public;
        }
      }
      this.analyticsService.stackPublished(stack.projectId, stack.stackId, role);
      // increment numStacksPublished
      this.userService.stackPublished();

    } catch (error) {
      this.sentryService.captureError(error);
      throw error;
    }

    return stack;
  }

  /**
   * save state of my stack to store to save for reload
   */
  saveUpdatesToStore(stack: Stack) {
    this.mystackService.prePublishStack(stack);
  }
}
