/** @format */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as clipActions from '@store/actions/clips.actions';
import * as mystackActions from '@store/actions/mystack.actions';
import { Utils } from '@shared/utils';
import {
  ClipVideoFile,
  Clip,
  convertVideoFileToClip,
  HLS_META_TRANSCODE,
  DEFAULT_POSTER,
  isHlsComplete,
} from '@shared/models/clip.model';
import { ClipsApiService } from '@app/core/api/clips-api.service';
import { SentryService } from '@services/analytics/sentry.service';
import { YoutubeService } from '@services/youtube.service';
import { environment, filestackConfig } from 'src/environments/environment';
import { AnalyticsService } from '@services/analytics/analytics.service';

const DEBUG_LOGS = false;
const PAGE = '[ClipsService]';

@Injectable({
  providedIn: 'root',
})
export class ClipsService {
  constructor(
    private store: Store<State>,
    private sentryService: SentryService,
    private clipsApi: ClipsApiService,
    private youtubeService: YoutubeService,
    private analyticsService: AnalyticsService
  ) {}

  getPoster(clip: Clip, res: string = 'default') {
    let poster = DEFAULT_POSTER;
    if (clip) {
      if (clip.poster) {
        poster = clip.poster;
      } else if (clip.youtube_id) {
        poster = this.youtubeService.getThumbnail(clip.youtube_id, res).url;
      }
    }
    return poster;
  }

  /**
   * Create a new Clip from the FileStack Upload
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async createClipFromFilestack(filestackResult: any, projectId: string, userId: string) {
    DEBUG_LOGS && console.log(`createClipFromFilestack filestackResult =`, filestackResult);
    const {
      uploadTags: {
        lastModifiedDate = null, // = (new Date()).toISOString(),
        // filesize = filestackResult.size, // don't use the stringified from uploadTags
        duration = 0,
      } = {},
    } = filestackResult;
    // MVP-1169 file selected metadata
    !environment.production &&
      console.log(`get size & updatedDate from filestackResult:`, { lastModifiedDate, duration, filestackResult });

    if (!filestackResult || !projectId) {
      console.warn(`${PAGE} createClipFromFilestack missing required params`, { filestackResult, projectId });
      throw new Error('Missing required parameters - unable to create clip.');
    }

    const newVideo: ClipVideoFile = {
      filename: filestackResult.filename,
      type: filestackResult.mimetype,
      size: filestackResult.size,
      lastModifiedDate: new Date().toISOString(),
      loading: true,
    };

    // get additional metadata if it exists.
    if (lastModifiedDate) {
      newVideo.lastModifiedDate = lastModifiedDate;
    } else if (filestackResult.originalFile) {
      if (
        filestackResult.originalFile.lastModifiedDate &&
        typeof filestackResult.originalFile.lastModifiedDate.toISOString === 'function'
      ) {
        newVideo.lastModifiedDate = filestackResult.originalFile.lastModifiedDate.toISOString();
      } else if (filestackResult.originalFile.lastModified) {
        newVideo.lastModifiedDate = new Date(filestackResult.originalFile.lastModified).toISOString();
      }
    }

    // create our clip...
    const clip = convertVideoFileToClip(newVideo);
    clip.source = clip.source || {};
    // it is important that this startsWith(filestackConfig.storeTo.path) in the clipApi logic
    clip.source.src = filestackConfig.storeTo.clip.path + newVideo.filename;
    clip.source.orig = filestackResult.key;
    clip.source.filename = newVideo.filename;
    clip.source.type = newVideo.type;
    clip.source.loading = newVideo.loading;
    clip.source.lastModifiedDate = newVideo.lastModifiedDate;

    if (filestackResult.originalFile && filestackResult.originalFile.name) {
      clip.source.file = filestackResult.originalFile;
    }
    // this must match for api to do its magic...
    clip.id = Utils.removeFileExt(newVideo.filename);
    clip.projectId = projectId;

    if (typeof clip.filmingDate !== 'string') {
      clip.filmingDate = Utils.getDateTimeString(clip.filmingDate);
    }

    DEBUG_LOGS && console.log(`${PAGE} createClipFromFilestack -> createClip:`, clip);

    // kick off the createClip flow, subscribe to updates, add to mystack
    // as soon as we return, the Capture Page will nav to the Stack Editor
    return this.createClip(clip).then((newClip) => {
      DEBUG_LOGS &&
        console.log(`${PAGE} createClipFromFilestack -> now watch updates & addToStack (createClip result:`, newClip);
      // watch changes and push to store
      this.subClipsUpdated(newClip.projectId, newClip.id, userId);
      // add the clip to the Editor
      this.store.dispatch(mystackActions.addClip({ clip: newClip }));

      try {
        // analytics
        const size =
          newVideo && newVideo.size && typeof newVideo.size.toFixed === 'function' ? newVideo.size.toFixed(0) : false;
        const seconds =
          clip.source && clip.source.durationInSeconds && typeof clip.source.durationInSeconds.toFixed === 'function'
            ? clip.source.durationInSeconds.toFixed(3)
            : false;
        this.analyticsService.clipUpload({
          projectId: newClip.projectId,
          id: newClip.id,
          userId: newClip.userId,
          ...(seconds && { seconds }),
          ...(size && { size }),
        });
      } catch (error) {
        console.warn(`Analytics error:`, error);
      }
    });
  }

  /**
   * upload clip and add to store
   * @param clip
   * 1. Create the clip in DB
   * 2.
   */
  createClip(clip: Clip): Promise<Clip> {
    DEBUG_LOGS && console.log(`${PAGE} createClip`, clip);

    // add the new HLS transcoding instruction
    clip.hlsMeta = {
      ...(clip.hlsMeta || {}),
      ...HLS_META_TRANSCODE,
    };

    return this.clipsApi
      .createClip(clip)
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} createClip res:`, res);
        // add the clip to the Store
        const newClip = new Clip(res);
        this.store.dispatch(clipActions.addClip({ clip: newClip }));
        return newClip;
      })
      .catch((err) => {
        if (
          err &&
          Array.isArray(err.errors) &&
          err.errors.length > 0 &&
          err.errors[0] &&
          err.errors[0].errorType &&
          err.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'
        ) {
          console.info(`${PAGE} createClip already Exists:`, err);
          return new Clip(clip);
        }
        console.error(`${PAGE} createClip err:`, err);
        if (err && Array.isArray(err.errors) && err.errors.length > 0 && err.errors[0].message) {
          throw err.errors[0].message;
        }
        throw err;
      });
  }

  /**
   * clipUpdate GraphQL Subscription
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  subClipsUpdated(projectId: string, id = '', userId = '') {
    const params: { projectId: string; id?: string } = {
      projectId,
    };
    if (id) {
      params.id = id;
    }
    // not currently using userId on this GQL subscription in the API
    // if (userId) {  params.userId = userId; }

    // keeping the sub variable local so there can be multiple open subs
    const sub = this.clipsApi.subscribeClipsUpdated(params).subscribe({
      next: (res) => {
        // todo: convert to handleSubClips(res) when this not needed by editor
        DEBUG_LOGS && console.log(`${PAGE} clipsUpdatedSubscription next:`, res);
        if (!res || !res.id || !res.projectId) {
          throw new Error('Missing clip id in subscription?');
        }
        const updates: Partial<Clip> = {};
        let hlsMeta;
        if (res.hlsMeta) {
          try {
            hlsMeta = typeof res.hlsMeta === 'string' ? JSON.parse(res.hlsMeta) : res.hlsMeta;
            updates.hlsMeta = hlsMeta;
          } catch (error) {
            console.log(`Caught error during JSON.parse(res.hlsMeta):`, {
              hlsMeta: res.hlsMeta,
              error: error.message || error,
            });
          }
        }
        if (res.hlsSrc) {
          updates.hlsSrc = res.hlsSrc;
        }
        if (Array.isArray(res.sources) && res.sources.length > 0) {
          updates.sources = res.sources;
        }
        if (res.poster) {
          updates.poster = res.poster;
        }
        if (res.duration) {
          updates.duration = res.duration;
        }

        if (Object.keys(updates).length > 0) {
          // take the updates and flow to store...
          this.store.dispatch(
            clipActions.updateClipTranscoding({
              projectId: res.projectId,
              id: res.id,
              updates,
            })
          );
          DEBUG_LOGS &&
            console.log(`${PAGE} clipsUpdatedSubscription updateClipTranscoding:`, {
              projectId: res.projectId,
              id: res.id,
              updates,
            });
        }

        if (isHlsComplete(new Clip(updates))) {
          // when complete, this.unsubClipsUpdated()
          sub.unsubscribe();
        }
      },
      error: (err) => {
        console.warn(`${PAGE} clipsUpdatedSubscription ERROR:`, err);
        this.sentryService.captureError(err);

        if (projectId && id) {
          this.store.dispatch(
            clipActions.updateClipTranscoding({
              projectId,
              id,
              updates: {
                hlsMeta: {
                  errorMessage: err.message || err.errorMessage || err,
                },
              },
            })
          );
        }
        sub.unsubscribe();
      },
      complete: () => {
        DEBUG_LOGS && console.log(`${PAGE} clipsUpdatedSubscription => Complete`);
      },
    });
  }
  //end GraphQL Subscription

  /**
   * Take the poster string and return poster time from the transcoded name
   * example poster path:
   * https://videos.filmstacker.com/public/filmstacker-dev/jd_-_jd-note_202203021348/jd_-_jd-note_202203021348_thumb.0000000.jpg
   */
  getPosterTimeFromPoster(clip: Clip) {
    if (clip && clip.poster) {
      const sNum = clip.poster.substring(clip.poster.lastIndexOf('_thumb.'), clip.poster.lastIndexOf('.'));
      // there shouldn't be any dashes anymore
      // const sTime = sNum.replace(/-/g, '').replace(/0/gi, '');
      try {
        const parsed = parseInt(sNum, 10);
        return parsed > 0 ? parsed : 0;
      } catch (error) {
        return 0;
      }
    }
    return 0;
  }

  /**
   * Update the poster image to match the new posterTime
   *
   * @todo verify it exists in s3
   * potential ref: https://stackoverflow.com/questions/9815762/detect-when-an-image-fails-to-load-in-javascript
   *
   * example poster path:
   * https://videos.filmstacker.com/public/filmstacker-dev/jd_-_jd-note_202203021348/jd_-_jd-note_202203021348_thumb.0000000.jpg
   */
  updatePosterToPosterTime(clip: Clip, posterTime: number) {
    if (clip && clip.poster) {
      let newPoster = clip.poster;
      const sNum = ('0000000' + posterTime).slice(-7);
      const startIndex = newPoster.lastIndexOf('_thumb.') + '_thumb.'.length;
      const endIndex = newPoster.lastIndexOf('.');
      newPoster = newPoster.slice(0, startIndex) + sNum + newPoster.slice(endIndex);
      console.log(`${PAGE} change clip.poster (TODO: verify it exists in s3) posterTime: ${posterTime} to:`, {
        sNum,
        newPoster,
      });
      return newPoster;
    } else {
      console.warn(`${PAGE} missing clip.poster - unable to change`, clip);
      return null;
    }
  }

  /**
   * @deprecated unused - use subClipsUpdated
   * Do what we need with the clip we just received
   */
  private handleSubClips(res) {
    DEBUG_LOGS && console.log(`${PAGE} handleSubClips next:`, res);
    if (!res || !res.id || !res.projectId) {
      throw new Error('Missing clip id in subscription?');
    }
    // if any values exist not null, add them as changes?
    const updates: Partial<Clip> = {};
    let hlsMeta;
    if (res.hlsMeta) {
      try {
        hlsMeta = typeof res.hlsMeta === 'string' ? JSON.parse(res.hlsMeta) : res.hlsMeta;
        updates.hlsMeta = hlsMeta;
      } catch (error) {
        console.log(`Caught error during JSON.parse(res.hlsMeta):`, {
          hlsMeta: res.hlsMeta,
          error: error.message || error,
        });
      }
    }
    if (res.hlsSrc) {
      updates.hlsSrc = res.hlsSrc;
    }
    if (Array.isArray(res.sources) && res.sources.length > 0) {
      updates.sources = res.sources;
    }
    if (res.poster) {
      updates.poster = res.poster;
    }
    if (res.duration) {
      updates.duration = res.duration;
    }

    // take the updates and flow to store...
    // this.store.dispatch(clipActions.updateClipTranscoding({
    //   projectId: res.projectId,
    //   id: res.id,
    //   updates,
    // }));
  }
}
