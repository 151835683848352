/** @format */

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { IonicSlides, ModalController, RadioGroupCustomEvent } from '@ionic/angular';
import {
  OnboardingMeta,
  OnboardingTypes,
  TRANSLATE_ROOT,
  getProjectEventTypeFromOnboardingType,
} from '@onboarding/shared/onboarding.model';
import {
  ChargebeeCustomer,
  ChargebeePlanId,
  DEFAULT_PRICE_RANGE_TIER,
  Plan,
  PlanPrice,
  PlanPriceTier,
  getPriceFromTiers,
} from '@billing/shared/billing.model';
import { ProjectFormComponent } from '@projects/project-form/project-form.component';
import { Project, isEventActive } from '@projects/shared/project.model';
import { UserService } from '@services/user.service';
import { activeProject as environSetActiveProject } from '@store/actions/environ.actions';
import { Store } from '@ngrx/store';
import { TokensService } from '@tokens/shared/services/tokens.service';
import { FormControl } from '@angular/forms';
import { ENABLE_BILLING } from '@app/app.config';
import { selectPlansLoaded } from '@billing/store';
import { Router } from '@angular/router';
import { PROJECT_DETAIL_ROUTE, STUDIO_SETTINGS_ROUTE } from '@app/app-routing.module';
import { BillingService } from '@billing/shared';
import { selectMyProjectsAdmin } from '@store/selectors/projects.selectors';

const NUM_DAYS_FROM_TODAY_INIT_EVENT_DATE = 1;
const DEBUG_LOGS = false;

@Component({
  selector: 'fs-signup-stepper',
  templateUrl: './signup-stepper.component.html',
  styleUrls: ['./signup-stepper.component.scss'],
})
export class SignupStepperComponent implements OnInit, OnChanges {
  @Input() projectId: string;
  @Input() eventType: OnboardingTypes | string = OnboardingTypes.None;
  // do we get & set to do all the needed logic when plan changes?
  @Input() plan: Plan;
  @Input() planPrices: PlanPrice[] = [];
  @Input() tiers: PlanPriceTier[] = [];
  @Input() translatePath = TRANSLATE_ROOT;
  @Input() metaPlanOptions: OnboardingMeta[] = [];
  @Input() isProPlan = false;
  @Input() isActivePro = false;
  @Input() subscriptionId: string;
  @Input() defaultProjectId = '';

  @Output() projectCreated = new EventEmitter<Partial<Project>>();
  @Output() setDefaultProjectId = new EventEmitter<string>();
  @Output() eventDateSelected = new EventEmitter<string>();
  @Output() signupComplete = new EventEmitter<{
    selectedProjectId: string;
    selectedEventDate: string;
    event: {
      successPageId: string;
      customer: ChargebeeCustomer;
    };
  }>();
  @Output() signupError = new EventEmitter<string>();

  enableBilling = ENABLE_BILLING;

  plansLoaded$ = this.store.select(selectPlansLoaded);
  myProjectsAdminLength$ = this.store.select(selectMyProjectsAdmin).pipe(map((projects) => projects?.length ?? 0));

  get planId(): ChargebeePlanId {
    return this.plan?.id;
  }
  get hasTiers(): boolean {
    return this.tiers.length > 0;
  }

  get isWeddingRoute(): boolean {
    return this.eventType === OnboardingTypes.Weddings;
  }
  get otherEventSignupRoute(): string {
    return `/events/${this.isWeddingRoute ? '' : 'weddings/'}signup${this.projectId ? '/' + this.projectId : ''}`;
  }

  get isProOnProPage(): boolean {
    return this.isProPlan && this.isActivePro;
  }

  cost = 0;
  numMembers = 0;
  minimumEventDate = new Date().toISOString();

  selectedPriceId = '';
  selectedRangeTier = DEFAULT_PRICE_RANGE_TIER;

  get planTitle(): string {
    return this.plan?.title ?? 'Plan';
  }

  get costUnit(): string {
    return this.plan && this.plan.costUnit ? this.plan.costUnit : '$';
  }

  get selectedCost(): number {
    return this.planPrices.find((price) => price?.id === this.selectedPriceId)?.price ?? 0;
  }
  get selectedPriceName(): string {
    return this.planPrices.find((price) => price?.id === this.selectedPriceId)?.name ?? 'Unknown';
  }
  get selectedPriceUnit(): string {
    return this.planPrices.find((price) => price?.id === this.selectedPriceId)?.periodUnit ?? '';
  }

  selectedProjectId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedProject: Project;
  selectedProjectHasActiveEvent = false;

  // showingCodeInput = false;
  referralCodeFormControl = new FormControl('');
  referralCode = '';

  // https://ionicframework.com/docs/angular/slides
  @ViewChild('swiper', { static: false }) swiperRef: ElementRef | undefined;
  swiperModules = [IonicSlides];
  allowSlidePrev = false;
  allowSlideNext = false;
  get swiper() {
    return this.swiperRef?.nativeElement?.swiper;
  }

  /** our selectedEventDate is just the date part of the ISO string */
  get selectedEventDate(): string {
    return this._selectedEventDate.split('T')[0];
  }
  private set selectedEventDate(value) {
    this._selectedEventDate = value || new Date().toISOString();
  }
  // we'll update this onInit to addDays...
  private _selectedEventDate = new Date().toISOString();
  // private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private userService: UserService,
    private tokensService: TokensService,
    private billingService: BillingService,
    private modalCtrl: ModalController,
    private router: Router
  ) {}

  ngOnInit() {
    const addDaysForEventDate = (numDays = 0) => {
      const date = new Date();
      date.setDate(date.getDate() + numDays);
      return date;
    };
    this._selectedEventDate = addDaysForEventDate(NUM_DAYS_FROM_TODAY_INIT_EVENT_DATE).toISOString();

    this.tokensService.tokenId$.pipe(filter(Boolean), take(1)).subscribe((tokenId: string) => {
      this.referralCodeFormControl.setValue(tokenId);
      // this.showingCodeInput = true;
    });
  }

  /**
   * we can watch all the changes - just plan?
   * You can also use .previousValue and .firstChange for comparing old and new values
   */
  ngOnChanges(changes: SimpleChanges) {
    // isProPlan: changes.isProPlan.currentValue {previousValue: false, currentValue: true, firstChange: false}
    // plan: changes.plan.currentValue
    // planPrices
    // metaPlanOptions
    if (changes.plan && changes.plan.currentValue !== changes.plan.previousValue) {
      DEBUG_LOGS && console.log('[signup-stepper] plan changed:', changes);
      if (this.hasTiers) {
        this.numMembers = this.getNumMembersFromRange(DEFAULT_PRICE_RANGE_TIER);
        this.cost = getPriceFromTiers(this.numMembers, this.tiers);
      } else {
        // pro plan select monthly plan initially
        if (this.planPrices.length === 1) {
          this.selectedPriceId = this.planPrices[0].id;
        } else {
          this.selectedPriceId = this.planPrices.find((price) => price?.periodUnit === 'month')?.id ?? '';
        }
        DEBUG_LOGS && console.log({ selectedPriceId: this.selectedPriceId });
        if (this.isProPlan) {
          // input will not be shown, update
          this.numMembers = 1;
          this.cost = this.planPrices.find((price) => price?.id === this.selectedPriceId)?.price ?? 0;
        }
      }
    } else {
      DEBUG_LOGS && console.log('[signup-stepper] ignoring changes:', changes);
    }
  }

  initSlider(event) {
    const { detail = [] } = event;
    if (detail.length > 0) {
      const swiper = detail[0];
      // DEBUG_LOGS && console.log(swiper)
      if (swiper) {
        swiper.allowTouchMove = false;
        swiper.on('slideChange', () => {
          // slideChangeTransitionEnd
          this.slideChange();
        });
        // swiper.updateSize();
      }
    }
  }

  slideChange() {
    // re-disable the slide in UI
    this.swiper.allowSlideNext = false;
    this.swiper.allowSlidePrev = false;
    // this.content.scrollToTop();
    if (this.swiper.isBeginning) {
      this.allowSlidePrev = false;
    }
  }

  /** we don't want to emit the first time to avoid overwriting the state with dropdown's init value */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _isFirstProjectChange = true;
  projectChanged(project) {
    DEBUG_LOGS && console.log('onboardingSignup projectChanged:', project);
    this.selectedProjectId$.next(project?.id ?? '');
    this.selectedProject = project;
    // if there's already an active Event for this project, let's notify
    this.selectedProjectHasActiveEvent = isEventActive(project);

    if (this._isFirstProjectChange) {
      this._isFirstProjectChange = false;
    } else {
      this.setDefaultProjectId.emit(project?.id ?? '');
    }
  }

  codeBlur(value: string) {
    this.referralCode = value;
  }

  /**
   * in this range ui, we are getting value 1-5 representing quarters, so the tiers must align with that
   * 1 = 1
   * 2 = 25
   * 3 = 50
   * 4 = 75
   * 5 = 100
   */
  getNumMembersFromRange(value) {
    switch (value) {
      case 1:
        return 1;
      case 2:
      case 3:
      case 4:
        return (value - 1) * 25;
      case 5:
        return (value - 1) * 25;
      default:
        return 0;
    }
  }

  /**
   * hasTiers ion-range
   */
  pinFormatter(value: number) {
    return `${value > 1 ? (value - 1) * 25 : 1}`;
  }

  /**
   * in this range ui, we are getting value 1-5 representing quarters, so the tiers must align with that
   * 1 = 1
   * 2 = 25
   * 3 = 50
   * 4 = 75
   * 5 = 100
   */
  onRangeChange(event: Event) {
    const { detail: { value = 0 } = {} } = event as CustomEvent;
    this.numMembers = this.getNumMembersFromRange(value);
    this.cost = this.numMembers > 0 ? getPriceFromTiers(this.numMembers, this.tiers) : 0;
  }

  updatePricing(event: Event) {
    const { detail: { value } = {} } = event as RadioGroupCustomEvent;
    this.selectedPriceId = value;
  }

  eventDateChanged(event: Event) {
    const { detail: { value } = {} } = event as CustomEvent;
    DEBUG_LOGS && console.log('eventDateChanged', { value, selectedEventDate: value });
    this.eventDateSelected.emit(value);
  }

  /**
   * go to the next slide
   * note: removed the idea of validate here and added to template
   */
  nextSlide() {
    if (!this.swiper) {
      return console.warn('no swiper?', this.swiperRef);
    }
    this.swiper.allowSlideNext = true;
    this.swiper.slideNext();
    // // removed and added to template - need to check the selectedProject, not eventSubscriptions (only mine)
    // if (validate) {
    //   DEBUG_LOGS && console.log('nextSlide + validate..');
    //   // the selectedProject should not already have the same subscription
    //   combineLatest([
    //     this.selectedProjectId$,
    //     this.store.select(selectEventSubscriptions),
    //   ])
    //     .pipe(take(1))
    //     .subscribe(([selectedProjectId, eventSubscriptions])  => {
    //       DEBUG_LOGS && console.log({ selectedProjectId, eventSubscriptions, selectedProject: this.selectedProject });
    //       // this.selectedProject
    //       if (eventSubscriptions?.length > 0) {
    //         const found = eventSubscriptions.find((sub) => {
    //           sub.cf_project_id === selectedProjectId
    //         });
    //         if (found) {
    //           DEBUG_LOGS && console.warn('found:', found);
    //           return;
    //         }
    //       }

    //       this.swiper.allowSlideNext = true;
    //       this.swiper.slideNext();
    //     });
    // }
  }

  backSlide() {
    if (!this.swiper) {
      return console.warn('no swiper?');
    }
    this.allowSlidePrev = true;
    this.swiper.allowSlidePrev = true;
    this.swiper.slidePrev();
  }

  signupErrored(event) {
    console.warn('signupErrored', event);
    // do in parent: this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
    this.signupError.emit(event);
  }

  signupCompleted(event: { successPageId: string; customer: ChargebeeCustomer }) {
    DEBUG_LOGS && console.log(`signupComplete:`, { event });
    this.selectedProjectId$.pipe(take(1)).subscribe((selectedProjectId) => {
      this.signupComplete.emit({
        selectedProjectId,
        selectedEventDate: this.selectedEventDate,
        event,
      });
    });
  }

  routeBack() {
    if (this.isProPlan || !this.projectId) {
      this.router.navigateByUrl(`/${STUDIO_SETTINGS_ROUTE}`);
    } else {
      this.router.navigateByUrl(`/${PROJECT_DETAIL_ROUTE}/${this.projectId}`);
    }
  }

  /**
   * Edit Subscription
   */
  async editSubscription() {
    if (this.subscriptionId) {
      this.billingService.openEditSubscription(this.subscriptionId);
    } else {
      console.warn('No Subscription ID?');
    }
  }

  async createProject() {
    this.userService.userId$.pipe(take(1)).subscribe(async (userId) => {
      const projectCreateModal = await this.modalCtrl.create({
        component: ProjectFormComponent,
        componentProps: {
          userId,
          eventType: getProjectEventTypeFromOnboardingType(this.eventType),
        },
      });
      projectCreateModal.present();
      const { data } = await projectCreateModal.onWillDismiss();
      if (data?.project?.id?.length > 0) {
        // update the project selected
        this.projectCreatedSuccess(data.project);
      }
    });
  }

  private projectCreatedSuccess(project: Partial<Project>) {
    const projectId = project?.id;
    if (projectId) {
      // this will get called again after the setActiveProject selector returns... so we don't really need it..
      // this.projectChanged(project);
      // but we do need to set this so the form-select updates to this as preferred
      this.setDefaultProjectId.emit(projectId);
      DEBUG_LOGS && console.log(`Project Created -> environSetActiveProject: '${projectId}'`);
      this.store.dispatch(environSetActiveProject({ projectId }));
      this.projectCreated.emit(project);
    }
  }
}
