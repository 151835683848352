/**
 * Interface to store/reducers/environ
 *
 * @format
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import { getEnvironActiveProjectId } from '@store/selectors/environ.selectors';
import * as environActions from '../store/actions/environ.actions';
// import { EnvironData } from '../models/environData';

const PAGE = '[EnvironService]'; // eslint-disable-line @typescript-eslint/no-unused-vars

export interface IEnvironUpdatePayload {
  key: string;
  vals: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class EnvironService {
  /*
    Store connections
  */
  public activeProjectId$: Observable<string> = this.store.select(getEnvironActiveProjectId);

  constructor(private store: Store<State>) {}

  setActiveProjectId(projectId: string) {
    this.store.dispatch(environActions.activeProject({ projectId }));
  }

  /**
   * Update the Search/Start environ for sending to Explore
   */
  updateSearchStart(update: IEnvironUpdatePayload) {
    this.store.dispatch(environActions.updateSearchStart(update));
  }

  /**
   * @deprecated
   */
  // getActiveProject(): Observable<string> {
  //   return from(this.activeProjectId);
  //   // return this.store.select(fromStore.getEnvironActiveProjectUrl);
  // }

  // setActiveProject(projectId: string) {
  //   this.activeProjectId = projectId;
  //   // this.store.dispatch(environActions.SetActiveProjectAction(projectId));
  // }
}
