<div class="avatar-stack" #avatarStackContainer>
  <div class="avatar-stack__item" *ngFor="let user of avatarStack.show; trackBy: trackByFn">
    <ion-avatar class="avatar-stack__item__avatar">
      <img class="avatar-stack__item__avatar__image"
        [src]="user.userId | avatarUrl"
        [fallback]
        imageFadeIn
        [alt]="user.userId"
        [title]="user.name || user.userId"
        (click)="viewUserProfile(user.userId)" />
    </ion-avatar>
  </div>
  <div class="avatar-stack__item" *ngIf="avatarStack?.hide?.length; let hiddenUsersCount">
    <ion-badge
      class="avatar-stack__item__badge"
      color="primary"
      (click)="viewUserList($event)"
    >+{{hiddenUsersCount}}
  </ion-badge>
  </div>
</div>