/** @format */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Clip } from '@shared/models/clip.model';
import { PopoverController, NavController, ModalController, AlertController } from '@ionic/angular';
import { MyStackService } from '@services/mystack.service';
import { UserService } from '@services/user.service';
import { ClipsCoreService } from '@services/clips.service';
import { VideoService } from '@services/video.service';
import { ToasterService } from '@services/toaster.service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ModalReportComponent } from '@shared/components/modal-report/modal-report.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PopoverAction } from '@shared/components/action-popover/action-popover.component';
import { EDIT_CLIP_ROUTE } from '@app/app-routing.module';

@Component({
  selector: 'app-clip-popover-actions',
  templateUrl: './clip-popover-actions.component.html',
  styleUrls: ['./clip-popover-actions.component.scss'],
})
export class ClipPopoverActionsComponent implements OnInit, OnDestroy {
  @Input() clip: Clip;
  @Input() canEdit = false;
  @Input() canAddToStack = true;
  @Input() canApprove = false;
  @Input() showText = true;

  actions: PopoverAction[] = [];

  private didAddUserActions = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private popoverCtrl: PopoverController,
    private mystackService: MyStackService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private userService: UserService,
    private clipService: ClipsCoreService,
    private videoService: VideoService,
    private toaster: ToasterService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.translate
      .get([
        'STACK.ACTIONS.REPORT',
        'STACK.ACTIONS.ADD_TO_STACK',
        'CLIP.APPROVE',
        'CLIP.UNAPPROVE',
        'CLIP.EDIT',
        'COMMON.DELETE',
      ])
      .pipe(take(1))
      .subscribe((translated) => {
        this.actions.push({ type: 'flag', text: translated['STACK.ACTIONS.REPORT'], icon: 'flag-outline' });

        if (this.canAddToStack) {
          this.actions.unshift({
            type: 'add-to-stack',
            text: translated['STACK.ACTIONS.ADD_TO_STACK'],
            icon: 'add-outline',
          });
        }
        if (this.canApprove) {
          if (this.clip.isApproved) {
            // remove-circle-outline
            this.actions.unshift({
              type: 'un-approve',
              text: translated['CLIP.UNAPPROVE'],
              icon: 'lock-closed-outline',
            });
          } else {
            this.actions.unshift({ type: 'approve', text: translated['CLIP.APPROVE'], icon: 'checkmark' });
          }
        }
        this.userService.userId$.pipe(takeUntil(this.onDestroy$)).subscribe((userId) => {
          // console.log(`sub userId '${userId}' clip:`, this.clip);
          if (userId && this.clip && this.clip.userId === userId) {
            // IS the current user's clip
            if (!this.didAddUserActions) {
              this.didAddUserActions = true;
              this.actions.push({
                type: 'edit',
                text: translated['CLIP.EDIT'],
                icon: 'create-outline',
              });
              this.actions.push({
                type: 'delete',
                text: translated['COMMON.DELETE'],
                icon: 'trash-outline',
              });
            }
          } else if (this.canEdit) {
            this.actions.push({
              type: 'edit',
              text: translated['CLIP.EDIT'],
              icon: 'create-outline',
            });
          } else if (this.didAddUserActions) {
            // they might have just logged off
            this.actions = this.actions.filter((a) => a.type !== 'delete' && a.type !== 'edit');
            this.didAddUserActions = false;
          }
        });

        /**
         * Check currentUser Cognito Groups for Admin access to platform
         * DEV HLS TRANSCODE MVP-742
         */
        this.userService.userIsGlobalAdmin$
          .pipe(
            takeUntil(this.onDestroy$),
            filter((isAdmin) => !!isAdmin)
          )
          .subscribe(() => {
            if (this.clip && !this.clip.hlsSrc && !this.clip.youtube_id) {
              this.actions.push({ type: 'admin_hls', text: 'Transcode HLS', icon: 'aperture-sharp' }); // aperture-sharp
            }
          });
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async onSelected(type: string) {
    // console.log(type);
    switch (type) {
      case 'approve': {
        this.clipService.setClipIsApproved({
          projectId: this.clip.projectId,
          id: this.clip.id,
          userId: this.clip.userId,
          isApproved: true,
        });
        break;
      }
      case 'un-approve': {
        this.clipService.setClipIsApproved({
          projectId: this.clip.projectId,
          id: this.clip.id,
          userId: this.clip.userId,
          isApproved: false,
        });
        break;
      }
      case 'edit': {
        const returnUrl = this.router.url;
        this.navCtrl.navigateForward(`/${EDIT_CLIP_ROUTE}/${this.clip.projectId}/${this.clip.id}`, {
          state: { returnUrl },
          queryParams: { returnUrl },
        });
        // in case the actions are displayed in a modal we want to close the modal
        const modal = await this.modalCtrl.getTop();
        if (modal) {
          modal.dismiss();
        }
        break;
      }
      case 'add-to-stack': {
        if (this.clip && this.clip.id) {
          this.mystackService.addClipsToMyStack([this.clip]);
        } else {
          this.toaster.present(this.translate.instant('CLIP.ERROR_NOT_FOUND'));
        }
        break;
      }
      case 'flag': {
        const modal = await this.modalCtrl.create({
          component: ModalReportComponent,
          componentProps: { reportedItem: this.clip },
        });
        await modal.present();
        break;
      }
      case 'delete': {
        this.confirmDelete();
        break;
      }
      case 'admin_hls': {
        // this.toaster.present(`Transcode to HLS...`);
        this.confirmTranscode();
        break;
      }
      default:
        console.log(`@todo action ${type}`);
    }
    const popover = await this.popoverCtrl.getTop();
    if (popover) {
      popover.dismiss();
    }
  }

  async confirmDelete() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-confirm-delete',
      header: this.translate.instant('CLIP.CONFIRM_DELETE'),
      subHeader: this.translate.instant('CLIP.ACTION_CONFIRM'),
      message: this.translate.instant('CLIP.CONFIRM_DELETE_MSG', {
        title: this.clip.title
          ? this.translate.instant('CLIP.CONFIRM_DELETE_TITLE', { value: this.clip.title })
          : this.translate.instant('CLIP.CONFIRM_DELETE_NO_TITLE'),
      }),
      buttons: [
        {
          text: this.translate.instant('COMMON.CANCEL'),
          role: 'cancel',
          handler: () => {
            console.log('Confirm Canceled...');
          },
        },
        {
          text: this.translate.instant('COMMON.DELETE'),
          cssClass: 'ion-color-secondary',
          handler: () =>
            this.clipService
              .deleteClip(this.clip)
              .then(() => {
                this.toaster.present(this.translate.instant('CLIP.DELETED'));
              })
              .catch((error) => {
                console.warn(`[ClipPopover] deleteClip caught:`, error);
                this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
              }),
        },
      ],
    });
    return await alert.present();
  }

  /**
   * DEV HLS TRANSCODE MVP-742
   */
  async confirmTranscode() {
    return await this.videoService.confirmSendClipForHLSTranscode(this.clip);
  }
}
