<!-- @format -->

<div class="stack-form">
  <div *ngIf="showHeading" class="stack-form__heading">
    <h1>{{ 'EDITOR.START_STACK' | translate }}</h1>
  </div>
  
  <form [formGroup]="stackCreateForm" class="stack-form__form" (ngSubmit)="createStack()">
    <div *ngIf="errorMsg" class="stack-form__error">
      <strong>Error:</strong> {{ errorMsg }}
    </div>
  
    <fs-form-input
      [placeholder]="'EDITOR.FORM.TITLE_PLACEHOLDER' | translate"
      [control]="stackCreateForm.get('title') | formControlCast"
      [showErrorTextOnError]="showErrorTextOnError"
      class="stack-form__form__title"
      [debounce]="1000"
      (onInput)="titleInput($event)"
    ></fs-form-input>
    <!-- [label]="'COMMON.TITLE' | translate" -->
    <!-- [hint]="'EDITOR.FORM.TITLE_HINT' | translate" -->
  
    <!-- Causing an issue with project.title? -->
    <app-form-select-myprojects
      [showLabel]="false"
      [defaultProjectId]="addToStackPayload?.projectId"
      (selected)="projectChanged($event)"
    >
    </app-form-select-myprojects>

    <!-- <fs-form-select
      [disabled]="projectOptions.length === 1"
      [label]="'EDITOR.FORM.PROJECT_LABEL' | translate"
      [placeholder]="'EDITOR.FORM.PROJECT_PLACEHOLDER' | translate"
      [hint]="'EDITOR.FORM.PROJECT_HINT_CREATE' | translate"
      [items]="projectOptions"
      [control]="stackCreateForm.get('projectId')"
    ></fs-form-select> -->
  
    <div *ngIf="showSubmit" class="ion-text-center ion-margin-top ion-padding-top">
      <ion-buttons slot="end">
        <!-- [iconOnly]="true" -->
        <fs-button
          type="submit"
          size="default"
          [disabled]="!stackCreateForm.valid"
          icon="arrow-forward-outline"
        >{{ 'EDITOR.SAVE_STACK_DRAFT' | translate }}</fs-button>
      </ion-buttons>
    </div>
  </form>
</div>
