<div class="add-project" [class.active-event]="isEvent">
    <ion-button shape="circle" size="large">
        <ion-icon name="add-outline"></ion-icon>
    </ion-button>
    <div *ngIf="isEvent else notEvent" class="add-project__title">{{
        'PROJECT.CREATE_EVENT' | translate
    }}</div>
    <ng-template #notEvent>
        <div class="add-project__title">{{'STUDIO.PROJECTS.CREATE' | translate}}</div>
    </ng-template>
</div>