/** @format */

import { Component, ViewChild, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseModalComponent } from '@shared/components/ui/modal/base-modal.component';
import { Project } from '@projects/shared/project.model';
import { SocialSharingService, socialSharingServiceProvider, SHARING_METHOD } from '@app/core/social-sharing';
import { ShareableItem } from '@app/core/social-sharing/social-sharing.model';
import { ITEM_TYPE } from '@shared/models/layout.model';

// const STEPS = {
//   selection: { id: 'selection', back: null, next: 'subSelection' },
//   additionalText: { id: 'additionalText', back: 'subSelection', next: 'sent' },
//   sent: { id: 'sent', back: null, next: null },
// };
// type Steps = keyof typeof STEPS;

@Component({
  selector: 'app-modal-invite-success',
  templateUrl: './modal-invite-success.component.html',
  styleUrls: ['./modal-invite-success.component.scss'],
  providers: [socialSharingServiceProvider],
})
export class ModalInviteSuccessComponent {
  @Input() heading: string;
  @Input() btnText: string;
  @Input() project: Project;

  title = '';
  shareToOptions = SHARING_METHOD;

  @ViewChild('modal', { static: false, read: BaseModalComponent })
  modal: BaseModalComponent;

  constructor(private socialSharingService: SocialSharingService, private modalController: ModalController) {}

  openAddMemberDialog() {
    this.modalController.dismiss({
      openAddMemberDialog: true,
    });
  }

  navToProject() {
    // this.project.id
    this.modalController.dismiss({
      navToProject: true,
    });
  }

  share(shareTo: SHARING_METHOD) {
    const shareItem: ShareableItem = {
      id: this.project.id,
      title: this.project.title,
      description: this.project.description,
      shareUrl: this.project.shareUrl,
      type: ITEM_TYPE.Projects,
      // tags: ,
    };

    this.socialSharingService.shareTo(shareItem, shareTo).catch((error) => {
      console.error(error);
    });
  }
}
