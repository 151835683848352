/** @format */

import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { PopoverController, AlertController, ModalController, NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Stack, STACK_PRIVACY } from '@shared/models/stack.model';
import { Clip } from '@shared/models/clip.model';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as stackActions from '@store/actions/stacks.actions';
import { addToStackDrawer } from '@store/actions/stacks.actions';
import { MyStackService } from '@services/mystack.service';
import { StacksService as StacksCoreService } from '@services/stacks.service';
import { StackService } from '@stacks/shared/services';
import { VideoService } from '@services/video.service';
import { UserService } from '@services/user.service';
import { ToasterService } from '@services/toaster.service';
import { SocialSharingService, socialSharingServiceProvider } from '@app/core/social-sharing';
import { ModalReportComponent } from '@shared/components/modal-report/modal-report.component';
import { ShareableItem } from '@app/core/social-sharing/social-sharing.model';
import { ITEM_TYPE } from '@shared/models/layout.model';
import { TranslateService } from '@ngx-translate/core';
import { CollabModalComponent } from '@stacks/shared/components';
import { PopoverAction } from '@shared/components/action-popover/action-popover.component';
import { EDIT_ROUTE } from '@app/app-routing.module';
// import { ManageStacksPage } from '@app/modals/manage-stacks/manage-stacks.page';

@Component({
  selector: 'app-stack-popover-actions',
  templateUrl: './stack-popover-actions.component.html',
  styleUrls: ['./stack-popover-actions.component.scss'],
  providers: [socialSharingServiceProvider],
})
export class StackPopoverActionsComponent implements OnInit, OnDestroy {
  @Input() stack: Stack;
  @Input() currentClip: Clip;
  /** MVP-1003 Project.isModerated */
  @Input() isModerated = false;
  /** allow to skip the embedded handler methods */
  @Input() doHandleAddToStack = true;
  @Input() canAddToStack = true;
  @Input() canShare = true;
  @Input() canApprove = false;
  @Input() canFeature = false; // ===canModerate
  @Input() canFlag = true; // only since Drafts don't need flagged..
  @Input() isProjectMember = false;

  @Output() addToStack = new EventEmitter();

  actions: PopoverAction[] = [];

  private didAddUserActions = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private navCtrl: NavController,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private mystackService: MyStackService,
    private stacksService: StacksCoreService,
    private stackService: StackService,
    private userService: UserService,
    private videoService: VideoService,
    private socialSharingService: SocialSharingService,
    private toaster: ToasterService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    // console.log(`isProjectMember='${this.isProjectMember}`)
    // get the translations
    this.translate
      .get([
        'COMMON.SHARE',
        'COMMON.EDIT',
        'COMMON.DELETE',
        'STACK.ACTIONS.REPORT',
        'STACK.ACTIONS.ADD_TO_STACK',
        'STACK.ACTIONS.INVITE_CREW',
        'STACK.ACTIONS.REMOVE_CREW',
        'STACK.ACTIONS.REMOVE_CREW_TIP',
        'STACK.ACTIONS.FEATURE',
        'STACK.ACTIONS.UNFEATURE',
        'STACK.ACTIONS.APPROVE',
        'STACK.ACTIONS.UNAPPROVE',
      ])
      .pipe(take(1))
      .subscribe((translated) => {
        if (this.canShare) {
          this.actions.unshift({ type: 'share', text: translated['COMMON.SHARE'], icon: 'share-outline' });
        }
        if (this.canAddToStack) {
          this.actions.unshift({
            type: 'add-to-stack',
            text: translated['STACK.ACTIONS.ADD_TO_STACK'],
            icon: 'add-outline',
          });
        }
        if (this.canFeature) {
          if (this.stack.featured && this.stack.featured > 0) {
            this.actions.push({
              type: 'un-feature',
              text: translated['STACK.ACTIONS.UNFEATURE'],
              icon: 'star-outline',
            });
          } else {
            this.actions.push({ type: 'feature', text: translated['STACK.ACTIONS.FEATURE'], icon: 'star' });
          }
        }
        // check moderation
        if (this.isModerated && this.canApprove && this.stack?.privacy === STACK_PRIVACY.PUBLIC) {
          if (this.stack.isApproved) {
            // put it at the end
            this.actions.push({
              type: 'un-approve',
              text: translated['STACK.ACTIONS.UNAPPROVE'],
              icon: 'lock-closed-outline',
            });
          } else {
            // at the top
            this.actions.unshift({ type: 'approve', text: translated['STACK.ACTIONS.APPROVE'], icon: 'checkmark' });
          }
        }
        if (this.canFlag) {
          this.actions.push({ type: 'flag', text: translated['STACK.ACTIONS.REPORT'], icon: 'flag-outline' });
        }

        this.userService.userId$.pipe(takeUntil(this.onDestroy$)).subscribe((userId) => {
          // if it's a draft and not private, allow to make collaborative MVP-1176
          if (!this.stack.dtePublished && !this.stack.private && this.stack.privacy !== STACK_PRIVACY.PRIVATE) {
            // if it is mine or i'm a projectAdmin (canModerate)
            if ((userId && this.stack.userId === userId) || this.canFeature) {
              if (this.stack.isCollaborative) {
                this.actions.push({
                  type: 'remove-collab',
                  text: translated['STACK.ACTIONS.REMOVE_CREW'],
                  tooltip: translated['STACK.ACTIONS.REMOVE_CREW_TIP'],
                  icon: 'person-remove-outline',
                });
              } else {
                this.actions.push({
                  type: 'make-collab',
                  text: translated['STACK.ACTIONS.INVITE_CREW'],
                  icon: 'person-add-outline',
                });
              }
            }
          }
          if (userId && this.stack && this.stack.userId === userId) {
            // IS the current user's stack
            if (!this.didAddUserActions) {
              this.didAddUserActions = true;
              this.actions.push({ type: 'edit', text: translated['COMMON.EDIT'], icon: 'create-outline' });
              this.actions.push({ type: 'delete', text: translated['COMMON.DELETE'], icon: 'trash-outline' });
            }
          } else if (this.didAddUserActions) {
            // they might have just logged off
            this.actions = this.actions.filter((a) => a.type !== 'delete' && a.type !== 'edit');
            this.didAddUserActions = false;
          } else if (userId && this.isProjectMember) {
            // if i'm a projectMember then allow to edit, removing edit if it was there before
            this.actions = this.actions.filter((a) => a.type !== 'edit');
            this.actions.push({ type: 'edit', text: translated['COMMON.EDIT'], icon: 'create-outline' });
          }
        });
      });

    // /**
    //  * Check currentUser Cognito Groups for Admin access to platform
    //  */
    // this.userService.userIsGlobalAdmin$.pipe(
    //   takeUntil(this.onDestroy$),
    //   filter((isAdmin) => !!isAdmin)
    // ).subscribe((isAdmin) => {
    //   console.log(`sub userGroups isAdmin:`, { isAdmin, stack: this.stack });
    //   /**
    //    * DEV HLS TRANSCODE MVP-742
    //    * @todo implement when ready...
    //    */
    //   if (this.stack && !this.stack.hlsSrc) {
    //     this.actions.push({ type: 'admin_hls', text: 'Transcode HLS', icon: 'aperture-sharp' }); // beer-outline
    //   }
    // });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async onSelected(type: string) {
    // console.log('on selected', type, this.stack);
    const res = { type };
    switch (type) {
      case 'make-collab': {
        const modal = await this.modalCtrl.create({
          component: CollabModalComponent,
          componentProps: {
            stack: this.stack,
          },
        });
        modal.present();
        // const data = await modal.onWillDismiss();
        // console.log(`Modal dismissed`, data);
        break;
      }
      case 'remove-collab': {
        this.stackService.removeCollaborative(this.stack.projectId, this.stack.stackId);
        break;
      }
      case 'feature': {
        // if can approve moderation & this was not approved, set true, else set whatever it is currently
        const isApproved = this.isModerated && this.canApprove && !this.stack.isApproved ? true : this.stack.isApproved;
        this.stackService.setStackFeatured({
          projectId: this.stack.projectId,
          stackId: this.stack.stackId,
          featured: 1,
          isApproved,
        });
        break;
      }
      case 'un-feature': {
        this.stackService.setStackFeatured({
          projectId: this.stack.projectId,
          stackId: this.stack.stackId,
          featured: 0,
        });
        break;
      }
      case 'approve': {
        this.stackService.setStackIsApproved({
          isApproved: true,
          projectId: this.stack.projectId,
          stackId: this.stack.stackId,
        });
        break;
      }
      case 'un-approve': {
        this.stackService.setStackIsApproved({
          isApproved: false,
          projectId: this.stack.projectId,
          stackId: this.stack.stackId,
        });
        break;
      }
      case 'add-to-stack': {
        if (this.doHandleAddToStack) {
          this.chooseStackDraft();
        }
        // instead of confirm here, allow the parent to handle the event
        // this.confirmAddClipsToStack();
        this.addToStack.emit();
        break;
      }
      case 'share': {
        const shareItem: ShareableItem = {
          title: this.stack.title,
          description: this.stack.description,
          shareUrl: this.stack.shareUrl,
          type: ITEM_TYPE.Stacks,
          id: this.stack.stackId,
          tags: this.stack.tags,
        };
        this.socialSharingService
          .share(shareItem)
          .then((response) => {
            this.store.dispatch(
              stackActions.share({ projectId: this.stack.projectId, stackId: this.stack.stackId, response })
            );
          })
          .catch((error) => this.toaster.present(error.message));
        break;
      }
      case 'flag': {
        const modal = await this.modalCtrl.create({
          component: ModalReportComponent,
          componentProps: { reportedItem: this.stack },
        });
        await modal.present();
        break;
      }
      case 'edit': {
        /**
         * if current route is already editor, open the publish modal & close out the drawer
         * else nav to editor?
         */
        this.navCtrl.navigateForward(`/${EDIT_ROUTE}/${this.stack.projectId}/${this.stack.stackId}`);
        break;
      }
      case 'delete': {
        this.confirmDelete();
        break;
      }
      case 'admin_hls': {
        this.confirmTranscode();
        break;
      }
      default:
        console.log(`@todo action ${type}`);
    }
    const popover = await this.popoverCtrl.getTop();
    if (popover) {
      popover.dismiss(res);
    }
  }

  /**
   * MVP-506
   * 1. Open StackDrafts topnav Drawer
   * 2. Choose the Add All or Add this one as an overlay on the draft poster?
   */
  async chooseStackDraft() {
    if (this.stack && Array.isArray(this.stack.playlist) && this.stack.playlist.length > 0) {
      const { playlist = [], projectId, title, description, poster } = this.stack;
      // MVP-1342 add title and description if create new (Restack)
      this.store.dispatch(
        addToStackDrawer({
          playlist,
          isCreateNew: false,
          projectId,
          title,
          description,
          poster,
        })
      );
    }
  }

  /**
   * @deprecated MVP-506
   */
  async confirmAddClipsToStack() {
    // return this.chooseStackDraft();

    const buttons = [];
    if (this.stack && Array.isArray(this.stack.playlist) && this.stack.playlist.length > 0) {
      buttons.push({
        text: this.translate.instant('CLIP.ALL_CLIPS'), //'All Clips',
        handler: () => {
          this.mystackService.addClipIdsToMyStack(this.stack.playlist);
        },
      });
    }
    if (this.currentClip && this.currentClip.id) {
      buttons.push({
        text: this.translate.instant('CLIP.THIS_CLIP'), //'This Clip',
        handler: () => {
          this.mystackService.addClipIdsToMyStack([{ projectId: this.currentClip.projectId, id: this.currentClip.id }]);
        },
      });
    }
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('CLIP.ADD_CLIPS_TO_EDITOR'), //'Add Clips To Stack Editor',
      // message: 'Add All Clips from this Stack?',
      buttons: [
        ...buttons,
        {
          text: this.translate.instant('COMMON.CANCEL'),
          role: 'cancel',
          handler: () => true,
        },
      ],
    });
    return await alert.present();
  }

  async confirmDelete() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-confirm-delete',
      header: this.translate.instant('STACK.CONFIRM_DELETE'), // 'Are you sure you want to delete this Stack?',
      subHeader: this.translate.instant('STACK.ACTION_CONFIRM'), //'This action can not be undone.',
      message: this.translate.instant('STACK.CONFIRM_DELETE_MSG', {
        title: this.stack.title
          ? this.translate.instant('STACK.CONFIRM_DELETE_TITLE', { value: this.stack.title })
          : this.translate.instant('STACK.CONFIRM_DELETE_NO_TITLE'),
      }),
      buttons: [
        {
          text: this.translate.instant('COMMON.CANCEL'),
          role: 'cancel',
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          handler: (_) => {
            console.log('Confirm Canceled...');
          },
        },
        {
          text: this.translate.instant('COMMON.DELETE'),
          role: 'destructive',
          cssClass: 'alert-button-warning',
          handler: () =>
            this.stacksService
              .deleteStack(this.stack)
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              .then((res) => {
                this.toaster.present(this.translate.instant('STACK.STACK_DELETED'));
              })
              .catch((error) => {
                console.warn(`[StackPopover] deleteStack caught:`, error);
                this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
              }),
        },
      ],
    });

    return await alert.present();
  }

  /**
   * @deprecated no need to translate, will be removed...
   * DEV HLS TRANSCODE MVP-742
   */
  async confirmTranscode() {
    const alert = await this.alertCtrl.create({
      header: 'Add this Stack to the HLS Transcoding queue?',
      // subHeader: `We'll loop the Clips and create HLS version of them too, if not already done.`,
      subHeader: `Currently all Clips must be HLS for this to work.`,
      message: `Note that if there's Youtube Clips included in this Stack, we're going to cancel this request...`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          handler: (_) => {
            console.log('Confirm Canceled...');
          },
        },
        {
          text: 'Transcode',
          cssClass: 'ion-color-secondary',
          handler: () =>
            this.videoService
              .sendStackForHLSTranscode(this.stack)
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              .then((res) => {
                this.toaster.present(`Submitted for Transcoding...`);
              })
              .catch((error) => {
                console.warn(`[StackPopover] confirmTranscode caught:`, error);
                this.toaster.present(`Oops! There was an error. Please refresh and try again.`);
              }),
        },
      ],
    });
    return await alert.present();
  }
}
