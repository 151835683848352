/** @format */

import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

/** Per Cognito Settings
 * PASSWORDs:
    Require numbers
    Require uppercase letters
    Require lowercase letters
    8 character(s)
 */

export class PasswordValidator {
  static minLength: number = 8;

  /*
      ^	The password string will start this way
      (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
      (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
      (?=.*[0-9])	The string must contain at least 1 numeric character
      X  (?=.[!@#\$%\^&])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
      (?=.{8,})	The string must be eight characters or longer
  */
  // static patternWithSpecialChars: string = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";
  static pattern: string = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';

  /** translated via setTranslations in base-form.component */
  static patternDesc: string = `Password must contain at least 1 lowercase and 1 uppercase alphabetical character, at least 1 number and be at least ${PasswordValidator.minLength} characters long`;
  // static patternDescShort: string = `Requires lowercase, uppercase and a number`;

  private static _didTranslate = false;
  static setTranslations(translate: TranslateService): void {
    if (!PasswordValidator._didTranslate) {
      PasswordValidator._didTranslate = true;
      translate
        .get(['ERRORS.FORM.PASS_PATTERN'], { minLength: PasswordValidator.minLength })
        .pipe(take(1))
        .subscribe((res) => {
          PasswordValidator.patternDesc = res['ERRORS.FORM.PASS_PATTERN'];
        });
    }
  }

  static isValid(control: UntypedFormControl): {
    minlength?: boolean;
    lowercase?: boolean;
    uppercase?: boolean;
    number?: boolean;
    pattern?: boolean;
    required?: boolean;
  } | null {
    const val = control.value;
    if (val && val.length > 0) {
      if (val.length < PasswordValidator.minLength) {
        return {
          minlength: true,
        };
      }
      const regex = RegExp(PasswordValidator.pattern);
      if (!regex.test(val)) {
        if (!RegExp('^(?=.*[a-z])').test(val)) {
          return {
            lowercase: true,
          };
        }
        if (!RegExp('^(?=.*[A-Z])').test(val)) {
          return {
            uppercase: true,
          };
        }
        if (!RegExp('^(?=.*[0-9])').test(val)) {
          return {
            number: true,
          };
        }
        return {
          pattern: true,
          // "nospecialchars": true
          // 'invalid chars': true,
        };
      }
    } else {
      return {
        required: true,
      };
    }

    return null;
  }

  /**
   * async check
   * @param control
   */
  //  static checkAvail(control: FormControl): any {
  //   return new Promise(resolve => {
  //     // sync
  //     // if(control.value.toLowerCase() === "notValid1") {
  //     //   resolve({
  //     //     "taken": true
  //     //   });
  //     // } else {
  //     //   resolve(null);
  //     // }

  //     // async
  //     //Fake a slow response from server
  //     setTimeout(() => {
  //       if(control.value.toLowerCase() === "notValid1") {
  //         resolve({
  //           "taken": true
  //         });
  //       } else {
  //         resolve(null);
  //       }
  //     }, 2000);
  //   });
  // }
}
