<ng-container
  *ngIf="(addMember || memberProjects?.length > 0) && projectsForm; else loadingProjects"
>
  <form [formGroup]="projectsForm" (ngSubmit)="saveChanges()">
    <ion-list class="list" lines="none">
      <ng-container
        *ngFor="let projectFormGroup of projectsControls; let i = index"
      >
        <ng-container [formGroup]="projectFormGroup">
          <ion-item
            *ngIf="
              projectFormGroup.value.canEditProjectMembers ||
              projectFormGroup.controls.isActive.value
            "
          >
            <ion-thumbnail slot="start">
              <img src="{{ projectFormGroup.value.project.hero }}" [fallback]="projectPosterFallback" />
            </ion-thumbnail>

            <div class="list__content">
              <ion-label>
                <div style="display: flex">
                  <a href="" (click)="$event.preventDefault(); navProject(projectFormGroup.value.project.id)" title="View Project">{{
                    projectFormGroup.value.project.title
                  }}</a>
                </div>
                <div>
                  <small>
                    <span class="margin-right">
                      <ion-icon name="person"></ion-icon>
                      {{ projectFormGroup.value.project.members?.length }}
                    </span>
                    <span
                      *ngIf="projectFormGroup.controls.currentUserMemberRole.value"
                      class="margin-right"
                    >
                      {{ 'COMMON.ME' | translate }}: {{ projectFormGroup.controls.currentUserMemberRole.value }}
                    </span>
                    <span *ngIf="projectFormGroup.value.project.privacy !== 'PUBLIC'" class="margin-right">
                      {{ projectFormGroup.value.project.privacy }}
                    </span>
                    <span *ngIf="isPendingUser">
                      [ {{ 'COMMON.PENDING_USER' | translate }} ]
                    </span>
                  </small>
                </div>
              </ion-label>

              <div
                class="list__content__actions"
                *ngIf="projectFormGroup.value.canEditProjectMembers"
              >
                <ion-select *ngIf="!addMember && (projectFormGroup.value.isActive || projectFormGroup.value.role !== defaultRoleFormValue)" 
                  name="isActive"
                  formControlName="isActive"
                  interface="popover"
                  aria-label="Is Active?"
                  (ionChange)="onActiveChanged($event, i)"
                >
                  <ion-select-option [value]="true">{{ 'COMMON.ACTIVE' | translate }}</ion-select-option>
                  <ion-select-option [value]="false"
                    >{{ 'COMMON.INACTIVE' | translate }}</ion-select-option
                  >
                </ion-select>

                <ion-select
                  name="role"
                  formControlName="role"
                  interface="popover"
                  aria-label="Role"
                  (ionChange)="onRoleChanged($event, i)"
                >
                  <!-- error in ion-select type? aria-label="{{ 'COMMON.ROLE' | translate }}" -->
                  <ion-select-option 
                    *ngIf="defaultRoleFormValue !== 'PRODUCER' && defaultRoleFormValue !== 'CREW'" 
                    [value]="defaultRoleFormValue">{{ defaultRoleFormValue }}...</ion-select-option>
                  <ion-select-option value="PRODUCER">{{ 'COMMON.PRODUCER' | translate }}</ion-select-option>
                  <ion-select-option value="CREW">{{ 'COMMON.CREW' | translate }}</ion-select-option>
                </ion-select>
              </div>
              <div
                class="list__content__actions"
                style="flex-basis: 0"
                *ngIf="!projectFormGroup.value.canEditProjectMembers"
              >
                <ion-text slot="end" class="">
                  {{ projectFormGroup.controls.role.value }}
                </ion-text>
              </div>
            </div>
          </ion-item>
        </ng-container>
      </ng-container>
    </ion-list>
  </form>
</ng-container>

<ng-template #loadingProjects>
  <div class="ion-padding ion-text-center ion-margin">
    <div *ngIf="!doneLoading; else noResults" class="loading">
      <ion-spinner name="crescent"></ion-spinner>
    </div>
    <ng-template #noResults>
      <div>{{ 'COMMON.NO_PROJECTS' | translate }}</div>
    </ng-template>
  </div>
</ng-template>
