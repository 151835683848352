/** @format */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum COMMAND {
  PLAY = 'play',
  PAUSE = 'pause',
  SEEKTO = 'seekto',
}

@Injectable({
  providedIn: 'root',
})
export class VideoPlayerService {
  /**
   * @todo is there a better way to capture payerId in the instances?
   */
  private _command = new Subject<{
    cmd: COMMAND;
    playerId: string;
    payload?: { startTime?: number; endTime?: number };
  }>();

  get command$() {
    return this._command.asObservable();
  }

  public play(playerId = '*') {
    this._command.next({ cmd: COMMAND.PLAY, playerId });
  }

  public pause(playerId = '*') {
    this._command.next({ cmd: COMMAND.PAUSE, playerId });
  }

  public seekTo(playerId: string, startTime: number, endTime?: number) {
    this._command.next({ cmd: COMMAND.SEEKTO, playerId, payload: { startTime, endTime } });
  }
}
