/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ShareableItem,
  SHARING_METHOD,
  SocialSharingService,
  socialSharingServiceProvider,
} from '@app/core/social-sharing';
import { createTokenQrCode, getInviteTypeFromProject, TRANSLATE_ROOT } from '@onboarding/shared/onboarding.model';
import { Project } from '@projects/shared/project.model';
import { ITEM_TYPE } from '@shared/models/layout.model';
import { getEventTypeFromOnboarding, getExpires, isTokenExpired, Token } from '@tokens/shared/tokens.model';
import { environment } from 'src/environments/environment';
import { TokensService } from '@tokens/shared/services/tokens.service';
import { AlertController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { UserService } from '@services/user.service';
import { take } from 'rxjs/operators';
import { ToasterPosition, ToasterService } from '@services/toaster.service';

@Component({
  selector: 'fs-qr-manage',
  templateUrl: './qr-manage.component.html',
  styleUrls: ['./qr-manage.component.scss'],
  providers: [socialSharingServiceProvider],
})
export class QrManageComponent implements OnInit {
  @Input() isStudio: boolean = false;
  @Input() translatePath = TRANSLATE_ROOT;
  @Input()
  set project(value) {
    this._project = value;
    if (!this.isStudio) {
      if (this.project && this.project.id) {
        this.tokensService
          .listTokens({ projectId: this.project.id })
          .then((tokens) => {
            /**
             * @todo need to create a persistent token if not exists...
             * need to throttle?
             *
             * set qrCode to the persistentToken
             *
             * @todo handle multiple persistent tokens with filter and loop
             * @note we want all, not just active - handle logic in template
             */
            !environment.production && console.log(`Tokens [${tokens.length}]:`, tokens);
            // const activeTokens = tokens.filter((t) => t && t.id && (t.isActive || !t.hasOwnProperty('isActive')));
            // !environment.production && console.log(`Tokens active [${activeTokens.length}]:`, activeTokens);
            if (Array.isArray(tokens) && tokens.length > 0) {
              this.persistentToken = tokens.find((t) => t && t.persistToken);
              // build the qr code if there's a persistentToken
              this.qrCode = this.hasPersistentToken ? createTokenQrCode(this.project, this.persistentTokenId) : '';
              this.loadingTokens = false;
            } else {
              this.persistentToken = null;
            }
          })
          .catch((error) => {
            console.warn(error);
          });
      }
    }
  }
  get project(): Project {
    return this._project;
  }
  get projectId(): string {
    return this.project && this.project.id;
  }
  get routeId() {
    return getInviteTypeFromProject(this.project);
  }

  // @ViewChild('togglePublicInvite', { static: false }) togglePublicInvite: IonToggle;

  loadingTokens = true; // trigger the spinner
  qrCode = '';
  generatingQr = false;
  persistentToken: Token;
  get hasPersistentToken(): boolean {
    return this.persistentToken?.id?.length > 0;
  }
  get persistentTokenId(): string {
    return this.persistentToken?.id;
  }
  get persistentTokenActive(): boolean {
    return this.persistentToken?.isActive;
  }
  get persistentTokenExpired() {
    return isTokenExpired(this.persistentToken);
  }

  shareToOptions = SHARING_METHOD;

  private _project: Project;

  constructor(
    private toaster: ToasterService,
    private alertController: AlertController,
    private tokensService: TokensService,
    private socialSharingService: SocialSharingService,
    private userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.loadingTokens = false;
    }, 5600);
  }

  addDays() {
    this.enableTokenUpdateExpired(30, this.persistentToken?.expires ?? Date.now());
  }

  renewExpiration() {
    this.enableTokenUpdateExpired();
  }

  // trying button...
  // toggleDisabledChanged(event: Event) {
  //   const customEvent = event as ToggleCustomEvent;
  //   const checked = !!customEvent.detail.checked;
  // }

  /**
   * When disabling, set the expires to now so we track when it happened, and then it will expire
   * If you don't want an expiring QR, delete & create new...
   */
  async disablePublicInvite() {
    try {
      const res = await this.tokensService.disableToken({
        tokenId: this.persistentTokenId,
        expires: new Date().toISOString(),
      });
      if (res?.success && res?.data) {
        this.toaster.present(this.translate.instant('ONBOARDING.COMMON.INVITE.UPDATED'), ToasterPosition.Bottom);
        this.updatePersistentToken(res.data);
      } else {
        console.warn('Response but not success?', res);
        this.toaster.present(this.translate.instant('ERRORS.GENERIC'), ToasterPosition.Bottom);
      }
    } catch (error) {
      console.warn('disablePublicInvite Caught error', error);
      this.toaster.present(this.translate.instant('ERRORS.GENERIC'), ToasterPosition.Bottom);
    }
  }

  async confirmDeleteAndNew() {
    const alert = await this.alertController.create({
      header: this.translate.instant('ONBOARDING.COMMON.INVITE.CONFIRM_DELETE.HEADER'),
      subHeader: this.translate.instant('ONBOARDING.COMMON.INVITE.CONFIRM_DELETE.SUBHEAD'),
      message: this.translate.instant('ONBOARDING.COMMON.INVITE.CONFIRM_DELETE.MESSAGE'),
      buttons: [
        {
          text: this.translate.instant('COMMON.CANCEL'),
          role: 'cancel',
          handler: () => {
            //
          },
        },
        {
          text: this.translate.instant('ONBOARDING.COMMON.INVITE.CONFIRM_DELETE.ACTION'),
          role: 'destructive',
          handler: () => {
            this.deleteAndGenerateNew();
          },
        },
      ],
    });

    await alert.present();
  }

  async generateQr() {
    if (!this.projectId) {
      return console.warn('No projectId?');
    }
    if (this.persistentTokenId) {
      this.enableTokenUpdateExpired();
    } else {
      // console.log(`Creating the persistentToken...`);
      this.generatingQr = true;
      combineLatest([this.userService.userId$, this.userService.email$])
        .pipe(take(1))
        .subscribe(([userId, userEmail]) => {
          this.tokensService
            .createPersistentToken({
              userId,
              userEmail,
              projectId: this.projectId,
              eventType: getEventTypeFromOnboarding(this.routeId),
            })
            .then((res) => {
              this.generatingQr = false;
              this.updatePersistentToken(res);
              this.qrCode = this.hasPersistentToken ? createTokenQrCode(this.project, this.persistentTokenId) : '';
            })
            .catch((err) => {
              console.error(err);
              this.generatingQr = false;
            });
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  share(shareTo: SHARING_METHOD, event?: MouseEvent) {
    if (!this.project?.id) {
      return;
    }
    const shareItem: ShareableItem = {
      id: this.project.id,
      title: this.project.title,
      // description: this.project.description,
      shareUrl: this.qrCode,
      type: ITEM_TYPE.Projects,
      // tags: ,
    };

    this.socialSharingService.shareTo(shareItem, shareTo).catch((error) => {
      console.error(error);
    });
  }

  private updatePersistentToken(updates) {
    this.persistentToken = {
      ...(this.persistentToken || {}),
      ...(updates || {}),
    };
  }

  private async enableTokenUpdateExpired(days = 30, from = null) {
    try {
      const res = await this.tokensService.enableToken({
        tokenId: this.persistentTokenId,
        expires: getExpires(days, from),
      });
      if (res?.success && res?.data) {
        this.toaster.present(this.translate.instant('ONBOARDING.COMMON.INVITE.UPDATED'), ToasterPosition.Bottom);
        this.updatePersistentToken(res.data);
      } else {
        console.warn('Response but not success?', res);
        this.toaster.present(this.translate.instant('ERRORS.GENERIC'), ToasterPosition.Bottom);
      }
    } catch (error) {
      console.warn('AddDays Caught error', error);
      this.toaster.present(this.translate.instant('ERRORS.GENERIC'), ToasterPosition.Bottom);
    }
  }

  private async deleteAndGenerateNew() {
    try {
      await this.tokensService.deleteToken(this.persistentTokenId);
      this.persistentToken = null;
      this.generateQr();
    } catch (error) {
      console.warn('deleteAndGenerateNew Caught:', error);
      this.toaster.present(this.translate.instant('ERRORS.GENERIC'), ToasterPosition.Bottom);
    }
  }
}
