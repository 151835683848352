<!-- @format -->

<ng-container *ngIf="stack$ | async as stack">
  <div class="stack-form__heading ion-padding-horizontal ion-margin-horizontal">
    <h1>
      {{ 'EDITOR.PUBLISH_STACK' | translate }} 
      <!-- <span *ngIf="!stack.dtePublished">{{ 'STACK.DRAFT' | translate }}</span> -->
    </h1>
    <h3 *ngIf="hasClipsTranscoding"> {{ 'EDITOR.CLIPS_TRANSCODING' | translate }} </h3>
  </div>

  <div *ngIf="errorMsg">
    <div class="stack-form__error">
      <strong>Error:</strong> {{ errorMsg }}
    </div>
  </div>

  <form 
    [formGroup]="stackForm"
    (ngSubmit)="publishStack()"
    class="update-stack-form"
  >
    <div *ngIf="hasClips"
      class="stack-form__thumbnail"
      [style.background-image]="'url(' + stackForm.get('poster').value + ')'"
      (click)="openPosterSelector($event, stack)"
    >
      <ion-fab-button
        (click)="openPosterSelector($event, stack)"
        class="stack-form__thumbnail__button"
        title="Choose Stack Poster"
        [disabled]="!stack.clips || stack.clips.length < 1"
      >
        <ion-icon name="images"></ion-icon>
      </ion-fab-button>
    </div>

    <!-- if (change) [debounce]="1000" -->
    <fs-form-input class="form-item"
      [placeholder]="'EDITOR.FORM.TITLE_PLACEHOLDER' | translate"
      [hint]="'EDITOR.FORM.TITLE_HINT' | translate"
      [control]="stackForm.get('title') | formControlCast"
      [initValue]="stack?.title"
      [debounce]="1000"
      (blur)="onPropChanged('title', $event)" 
    ></fs-form-input>

    <!-- if (change) [debounce]="1000" -->
    <fs-form-textarea class="form-item"
      [placeholder]="'EDITOR.FORM.DESC_PLACEHOLDER' | translate"
      [hint]="'EDITOR.FORM.DESC_HINT' | translate"
      [control]="stackForm.get('description') | formControlCast"
      
      (blur)="onPropChanged('description', $event)" 
    ></fs-form-textarea>

    <!-- 
      Try using... but it can't change anyways!
      <app-form-select-myprojects
        (selected)="projectChanged($event)"
      >
      </app-form-select-myprojects>
      ...and remove all the code for myProjects!
    -->
    <fs-form-select
      [label]="'EDITOR.FORM.PROJECT_LABEL' | translate"
      [placeholder]="'EDITOR.FORM.PROJECT_PLACEHOLDER' | translate"
      [items]="projectOptions"
      [control]="stackForm.get('projectId') | formControlCast"
      [disabled]="true"
      [hint]="stack.stackId ? ('EDITOR.FORM.PROJECT_HINT_CHANGE' | translate) : ''"
    ></fs-form-select>
    <!-- 
      [initValue]="stack.projectId" causing issue?
      (change)="setProjectId($event)" not changeable in curent usage 
    -->

    <!--
      TODO: MVP-1283
        'PUBLIC': { 
          title: 'COMMON.PUBLIC' | translate,
          description: 'EDITOR.FORM.PUBLIC_DESC' | translate
        },
        'UNLISTED': {
          title: 'EDITOR.FORM.UNLISTED_LABEL' | translate,
          description: 'EDITOR.FORM.UNLISTED_DESC' | translate
        },
        'PRIVATE': {
          title: 'COMMON.PRIVATE' | translate,
          description: 'EDITOR.FORM.PRIVATE_DESC' | translate
        }
    -->
    <fs-form-radio
      [values]="{
        'PUBLIC': { 
          title: 'COMMON.PUBLIC' | translate,
          description: 'EDITOR.FORM.PUBLIC_DESC' | translate
        },
        'PRIVATE': {
          title: 'COMMON.PRIVATE' | translate,
          description: 'EDITOR.FORM.PRIVATE_DESC' | translate
        }
      }"
      [header]="'COMMON.PRIVACY' | translate"
      [control]="stackForm.get('privacy') | formControlCast"
      (change)="onPropChanged('privacy', $event)" 
    ></fs-form-radio>

    <fs-form-item *ngIf="enableTags" [label]="'COMMON.ADD_TAGS' | translate">
      <app-tagger-input
        [id]="'stack_form'"
        [items]="stackForm.get('tags').value"
        [autocompleteItems]="allTagItems"
        (tagRemoved)="onTagRemoved($event)"
        (tagAdded)="onTagAdded($event)"
      ></app-tagger-input>
    </fs-form-item>

    <div class="ion-text-center ion-margin-top ion-padding-top">
      <p *ngIf="!stack.clips || stack.clips.length < 1">{{ 'EDITOR.FORM.ADD_CLIPS' | translate }}</p>
      <h3 *ngIf="hasClipsTranscoding" class="ion-text-left ion-padding"> {{ 'EDITOR.CLIPS_TRANSCODING_WAIT' | translate }} </h3>
      
      <p *ngIf="stack.isCollaborative && !stack.dtePublished">{{ 'EDITOR.PUBLISH.COLLAB_NOTE' | translate }}</p>

      <ion-button
        [disabled]="!stackForm.valid"
        size="large"
        fill="outline"
        class="ion-padding-horizontal ion-margin-top"
        (click)="cancel.emit()"
      >
        {{ 'EDITOR.BACK_TO_EDIT' | translate }}
        <!-- &nbsp;<span *ngIf="stack.dtePublished">{{ 'COMMON.STACK' | translate }} </span>
        <span *ngIf="!stack.dtePublished">{{ 'STACK.DRAFT' | translate }} </span> -->
      </ion-button>

      <ion-button
        type="submit"
        [disabled]="!stackForm.valid || isPublishing || hasClipsTranscoding"
        size="large"
        class="ion-padding-horizontal ion-margin-top"
      >
        <ion-spinner *ngIf="isPublishing" slot="start" name="crescent"></ion-spinner>
        <span *ngIf="!stack.dtePublished">{{ 'COMMON.PUBLISH_STACK' | translate }} </span>
        <span *ngIf="stack.dtePublished">{{ 'COMMON.REPUBLISH_STACK' | translate }} </span>
        <ion-icon slot="end" name="send-outline"></ion-icon>
      </ion-button>

      <p *ngIf="stack.dteSaved" class="ion-padding ion-margin-horizontal indicate-saved">
        <ion-spinner *ngIf="isSaving" slot="start" name="crescent"></ion-spinner>
        Last Saved: {{ stack.dteSaved | date:'medium' }}
      </p>

    </div>
  </form>

</ng-container>
