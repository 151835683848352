/** @format */

import { Directive, HostListener, Input } from '@angular/core';
import {
  MAX_VIDEO_CAPTURE_SIZE_MB,
  MAX_VIDEO_CAPTURE_LENGTH_MINUTES,
  MAX_VIDEO_CAPTURE_LENGTH_SECONDS,
} from '@app/app.config';
import { makeCaptureClipFilename } from '@clips/shared/clips.model';
import { FilestackService, PickerFileMetadata } from '@app/core/filestack/filestack.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseFileUpload } from './base-file-upload';
import { environment, filestackConfig } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { SentryService } from '@services/analytics/sentry.service';

@Directive({
  selector: '[clipUpload]',
})
export class ClipUploadDirective extends BaseFileUpload {
  @Input() projectId: string = '';
  @Input() userId: string = 'anon';

  /**
   * Needs to align with transcode-pipeline-step-functions,
   * only includes these:
   * .mpg, .mp4, .m4v, .mov, .m2ts
   *
   * @note we can add video/mpeg (need to enforce .mpg ext & not .mpeg) & video/MP2T (enforce .m2ts ext) if desired
   */
  protected allowedFileTypes: string[] = ['video/mp4', 'video/x-m4v', 'video/quicktime'];
  protected customPickerOptions = {
    maxSize: MAX_VIDEO_CAPTURE_SIZE_MB * 1024 * 1024, // 600 MB
    maxFiles: 24,
    accept: 'video/*',
    storeTo: {
      path: filestackConfig.storeTo.clip.path, // note that projectId is not ready yet
      container: filestackConfig.storeTo.clip.container,
      region: filestackConfig.storeTo.clip.region,
    },
    uploadConfig: {
      tags: {},
    },
    fromSources: ['local_file_system', 'video', 'googledrive', 'onedrive', 'facebook', 'box', 'url'],
  };

  constructor(
    filestackService: FilestackService,
    translate: TranslateService,
    platform: Platform,
    sentryService: SentryService
  ) {
    super(filestackService, translate, platform, sentryService);
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    // we need to get the projectId Input which is not available at construct...
    if (this.projectId) {
      const path = this.customPickerOptions.storeTo.path;
      this.customPickerOptions.storeTo.path = path + this.projectId + '/';
      this.customPickerOptions.uploadConfig.tags = { projectId: this.projectId };
    }
    this.openFilePicker(event);
  }

  makeFileUploadName(file: PickerFileMetadata, userId = this.userId) {
    return makeCaptureClipFilename(userId, file.filename);
  }

  /**
   * Called whenever user selects a file, used to verify the selected file before upload
   * If you throw any error in this function it will reject the file selection.
   * The error message will be displayed to the user as an alert.
   * https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#onfileselected
   *
   * @todo This currently only works for local uploads. Should migrate to acceptFn callback usage for files uploaded from local_source, as well as, from cloud providers.
   * https://www.filestack.com/docs/uploads/pickers/web/#acceptFn
   */
  onFileSelected(file: PickerFileMetadata): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (!file || !file.originalFile) {
        reject(this.translate.instant('FILE_UPLOAD.FILE_NOT_FOUND'));
      }
      if (this.allowedFileTypes && !this.allowedFileTypes.includes(file.mimetype)) {
        reject(
          this.translate.instant('FILE_UPLOAD.FORMAT_NOT_SUPPORTED', {
            not_supported_file_type: file.mimetype,
            supported_file_types: this.allowedFileTypes.join(', '),
          })
        );
      }
      this.checkVideoLength(file.originalFile)
        .then(({ durationOK, durationMessage }) => {
          console.log({ durationOK, durationMessage });
          if (!durationOK) {
            console.log(durationMessage);
            reject(
              `${this.translate.instant('FILE_UPLOAD.DURATION_EXCEEDS_LIMIT', {
                minutes: MAX_VIDEO_CAPTURE_LENGTH_MINUTES,
                duration: durationMessage,
              })}. ${this.translate.instant('FILE_UPLOAD.FILENAME')}: '${file.filename || 'Unknown'}'`
            );
          }
          const name = this.makeFileUploadName(file, this.userId);
          console.log(`[Capture] onFileSelected filename:`, {
            name,
            durationMessage,
            originalName: file.filename,
            file,
          });

          // MVP-1092 Capture date not correct on iOS
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const originalFile = file.originalFile as any;
          const lastModifiedDate = originalFile?.lastModifiedDate ?? new Date();
          const filelastModified = originalFile?.FilelastModified ?? Date.now();

          /** @todo send Pinpoint event with details MVP-730 */
          const pinpointEvent = {
            platforms: this.platform.platforms(),
            file: {
              filename: file.filename || '',
              name,
              mimetype: file.mimetype || 'unknown',
              FilelastModified: filelastModified,
              lastModifiedDate: lastModifiedDate.toString(),
              lastModifiedDateISO: lastModifiedDate.toISOString(),
              filesize: file.size || 0,
              source: file.source || 'unknown',
            },
          };

          !environment.production && console.log(`todo: pinpoint event with clip details:`, pinpointEvent);

          // add the size and modified date to the clip file so we can get it back when done
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const upload_tags = this.customPickerOptions.uploadConfig.tags;

          this.customPickerOptions.uploadConfig.tags = {
            ...upload_tags,
            lastModifiedDate: pinpointEvent.file.lastModifiedDateISO,
            duration: durationMessage,
            // filesize: pinpointEvent.file.filesize.toString(), // not needed...
          };

          // Return original object with updated filename:
          resolve({ ...file, name });
          // Or reject the selection with reject()
        })
        .catch((error) => {
          console.error(error);
          reject(this.translate.instant('ERRORS.CAUGHT_ERROR'));
        });
    });
  }

  /**
   * Check the Duration of the File does not exceed the max
   */
  checkVideoLength(file): Promise<{ durationOK: boolean; durationMessage: string }> {
    // return Promise.resolve({ durationOK: true, durationMessage: 'Skip for better UX?' });
    return new Promise((resolve) => {
      try {
        // const reader = new FileReader(), // create a FileReader (not needed, instead using video element onDurationChange)
        const video = document.createElement('video'); // create video element;
        video.preload = 'metadata'; // more efficient

        const checkDuration = () => {
          if (!video || !video.duration) {
            throw new Error('checkVideoLength checkDuration missing video or duration?');
          }
          const duration = video.duration;
          console.log(`getDuration`, { video, duration, maxCaptureLength: MAX_VIDEO_CAPTURE_LENGTH_SECONDS });

          // clean up
          video.removeEventListener('durationchange', checkDuration);
          (window.URL || window.webkitURL).revokeObjectURL(video.src);

          if (duration <= MAX_VIDEO_CAPTURE_LENGTH_SECONDS) {
            resolve({ durationOK: true, durationMessage: 'Ok to go!' });
          } else {
            try {
              const durationMessage = (duration / 60).toFixed(1);
              resolve({
                durationOK: false,
                durationMessage: `${durationMessage}`,
              });
            } catch (error) {
              console.log(`parseFloat duration (${duration}) caught`, error);
              resolve({
                durationOK: false,
                durationMessage: this.translate.instant('ERRORS.ERROR'),
              });
            }
          }
        };

        video.addEventListener('durationchange', checkDuration);
        // video.addEventListener('loadedmetadata', checkMetadata); // duration is more efficient and has all we need...

        video.addEventListener('error', (event) => {
          console.error(`checkVideoLength video listener caught error`, event);
          const { name = 'unknown', type = 'unknown', size = 'unknown' } = file;
          this.sentryService.captureMessage(
            `checkVideoLength video listener caught error filename='${name}' fileType='${type}' filesize='${size}'`
          );
          resolve({
            durationOK: false,
            durationMessage: this.translate.instant('ERRORS.ERROR'),
          });
        });

        video.src = (window.URL || window.webkitURL).createObjectURL(file);
      } catch (error) {
        const { name = 'unknown', type = 'unknown', size = 'unknown' } = file;
        console.error(`checkVideoLength caught error filename='${name}' fileType='${type}' filesize='${size}'`);
        console.error(`checkVideoLength caught`, error);
        this.sentryService.captureError(error);
        resolve({
          durationOK: false,
          durationMessage: this.translate.instant('ERRORS.ERROR'),
        });
      }
    });
  }
}
