/** @format */

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalComponent } from '@shared/components/ui/modal/base-modal.component';
import { Stack } from '@shared/models/stack.model';
import { StackService } from '@stacks/shared/services';
import { take } from 'rxjs';

@Component({
  selector: 'app-collab-modal',
  templateUrl: './collab-modal.component.html',
  styleUrls: ['./collab-modal.component.scss'],
})
export class CollabModalComponent implements OnInit {
  @Input() stack: Stack;

  @ViewChild('modal', { static: false, read: BaseModalComponent })
  modal: BaseModalComponent;

  descControl = new FormControl('');
  private defaultDescText = '';

  constructor(private stackService: StackService, private translate: TranslateService) {}

  ngOnInit() {
    this.translate
      .get('STACK.COLLAB_DEFAULT_STACK_DESC')
      .pipe(take(1))
      .subscribe((res) => {
        this.defaultDescText = res;
        this.descControl.setValue(res);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  invite(event) {
    // if the desc was modified from the default, update
    const newDescription = this.descControl.value !== this.defaultDescText ? this.descControl.value : '';
    this.stackService.makeCollaborative(this.stack.projectId, this.stack.stackId, newDescription);
    this.modal.dismiss(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cancel(event) {
    this.modal.dismiss();
  }

  // messageChanged(event) {
  //   console.log({ descVal: this.descControl.value, event });
  // }
}
