import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Based on https://forum.ionicframework.com/t/ion-input-select-all-text-on-focus/79406/2
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ion-searchbar[selectAllOnClick], ion-input[selectAllOnClick]'
})
export class SelectInputOnClickDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('click')
  selectAll() {
    try {
      // access to the native input element
      const nativeEl: HTMLInputElement = this.el.nativeElement.querySelector('input');
  
      if (nativeEl) {
        if (nativeEl.setSelectionRange) {
          // select the text from start to end
          return nativeEl.setSelectionRange(0, nativeEl.value.length);
        }
        nativeEl.select();
      }
    } catch (error) {
      console.log(`Caught error in SelectInputOnClickDirective.selectAll`, error)
    }
  }

}
