import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ExploreUiComponent } from './explore-ui.component';
import { ExploreCurrentClipComponent } from './explore-current-clip/explore-current-clip.component';
import { ExplorePathItemComponent } from './explore-path-item/explore-path-item.component';
import { ExplorePathListComponent } from './explore-path-list/explore-path-list.component';
import { ExploreDrawerComponent } from './explore-drawer/explore-drawer.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule
  ],
  declarations: [
    ExploreUiComponent,
    ExploreCurrentClipComponent,
    ExplorePathItemComponent,
    ExplorePathListComponent,
    ExploreDrawerComponent
  ],
  exports: [
    ExploreUiComponent,
    ExploreCurrentClipComponent,
    ExplorePathItemComponent,
    ExplorePathListComponent,
    ExploreDrawerComponent
  ],
  // added to support swiper.js per https://ionicframework.com/docs/angular/slides
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ExploreUiModule {}
