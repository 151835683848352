/** Tags for Home Page Filters */
export const HOME_TAGS: string[] = [
  'arts',
  'conservation',
  'cooking',
  'education',
  'filmmaking',
  'fire',
  'oceans',
  'sailing',
  'science',
  'technology',
  'travel',
  'water',
  'weddings',
  'wildlife',
];

/** Tags for autocomplete inputs */
export const ALL_TAGS: string[] = [
  ...HOME_TAGS,
  // common
  'accessibility',
  'adventure',
  'amazing',
  'astronomy',
  'beach',
  'challenge',
  'civilization',
  'decisions',
  'ecosystems',
  'environment',
  'equality',
  'evolution',
  'explanation',
  'fun',
  'future',
  'internet',
  'kids',
  'learning',
  'life',
  'macro',
  'micro',
  'media',
  'meditation',
  'memories',
  'mindfulness',
  'pets',
  'programming',
  'revolution',
  'society',
  'solutions',
  'space',
  'yoga',
  // make people better
  'genetics',
  'CRISPR',
  'Samira Kiani',
  'George Church',
  'Antonio Regalado',
  'biosecurity',
  'lab'
];
