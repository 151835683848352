/** @format */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from '@services/events.service';
import { ToasterService } from '@services/toaster.service';
import { MyStackService } from '@services/mystack.service';
import { ConfigService } from '@app/core/config';
import { ENABLE_FEATURE_WATCH_LATER, ENABLE_CLIP_SHARE, ENABLE_FEATURE_TRIM_CLIPS } from '@app/app.config';
import { Clip } from '@shared/models/clip.model';
import { ModalReportComponent } from '@shared/components/modal-report/modal-report.component';
import { VideoService } from '@services/video.service';
import { Utils } from '@shared/utils';

const PAGE = '[ClipDetailActions]';

@Component({
  selector: 'app-clip-detail-actions',
  templateUrl: './clip-detail-actions.component.html',
  styleUrls: ['./clip-detail-actions.component.scss'],
})
export class ClipDetailActionsComponent implements OnInit {
  @Input() clip: Clip;
  @Input() currentPage: string;
  @Input() showAddToStack: boolean = false;
  @Input() showFavorite: boolean = false;
  @Input() adminHls = false;
  @Input() canEdit = false;
  @Input() canRemove = false;
  @Input() playlistLength: number = 0; // to know when we're last
  @Input() index = 0;

  @Output() moveTop = new EventEmitter<MouseEvent>();
  @Output() moveBottom = new EventEmitter<MouseEvent>();
  @Output() deleteClip = new EventEmitter<MouseEvent>();
  @Output() trimClip = new EventEmitter<MouseEvent>();
  @Output() editClip = new EventEmitter<MouseEvent>();
  @Output() closed = new EventEmitter<MouseEvent>();

  public isFavorite: boolean = false;
  public isFlagged: boolean = false;

  public showWatchLater: boolean = ENABLE_FEATURE_WATCH_LATER;
  public showShare: boolean = ENABLE_CLIP_SHARE; // do not share Clips
  public showTrim: boolean = ENABLE_FEATURE_TRIM_CLIPS;

  constructor(
    // private store: Store<fromStore.State>,
    private configService: ConfigService,
    private events: EventsService,
    private mystackService: MyStackService,
    private toaster: ToasterService,
    private modalCtrl: ModalController,
    private videoService: VideoService
  ) {}

  ngOnInit() {
    this.configService.appConfig.then((config) => {
      this.showShare = this.showShare && config.allowSharing;
    });
  }

  async onClickAdminHls(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    return await this.videoService.confirmSendClipForHLSTranscode(this.clip);
  }

  onClickShare(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.toaster.present(`Share Feature Coming Soon... ${this.clip.title}`);
  }
  async onClickFlag(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    const modal = await this.modalCtrl.create({
      component: ModalReportComponent,
      componentProps: { reportedItem: this.clip },
    });
    await modal.present();
  }
  onClickAddToStack(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.mystackService.addClipsToMyStack([this.clip]);
  }
  onClickWatchLater(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    // this.store.dispatch(watchLaterActions.addClip({ clip: this.clip }));
    this.toaster.present(`Feature Coming Soon! Add to Watch Later: ${this.clip.title}`);
  }

  onClickMoveTop(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.moveTop.emit(event);
  }
  onClickMoveBottom(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.moveBottom.emit(event);
  }
  onClickDelete(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.deleteClip.emit(event);
  }
  onClickTrim(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.trimClip.emit(event);
  }
  onClickEdit(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.editClip.emit(event);
  }

  onClickFavorite(event: MouseEvent) {
    Utils.tryStopPropagation(event);
    this.isFavorite = !this.isFavorite;
    console.log(`${PAGE} (TODO) Favorite... ${this.clip.title}`);
  }

  onClickClose() {
    this.closed.emit();
  }
}
