/** @format */

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PickerFileError, PickerFileMetadata, PickerUploadDone } from '@app/core/filestack/filestack.service';
import { ClipsService } from '@clips/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { SentryService } from '@services/analytics/sentry.service';
import { ToasterService } from '@services/toaster.service';
import { UserService } from '@services/user.service';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-capture-clip-button',
  templateUrl: './capture-clip-button.component.html',
  styleUrls: ['./capture-clip-button.component.scss'],
})
export class CaptureClipButtonComponent {
  @Input() projectId: string;
  @Input() isShowing = false;
  @Input()
  set startUpload(val) {
    if (val) {
      this.clickButton();
    }
  }
  @Output() uploadSuccess = new EventEmitter<PickerFileMetadata[]>();
  @Output() uploadError = new EventEmitter<Error>();

  @ViewChild('uploadBtn', { read: ElementRef }) uploadBtn: ElementRef;

  userId$ = this.userService.userId$;

  constructor(
    private userService: UserService,
    private clipsService: ClipsService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private sentryService: SentryService
  ) {}

  clickButton() {
    // console.log(`Capturing to project: ${this.projectId}`)
    try {
      this.uploadBtn.nativeElement.click();
    } catch (error) {
      console.warn(error);
      this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
    }
  }

  /**
   * Filestack is done with all
   * {
   *    filesFailed: PickerFileMetadata[],
   *    filesUploaded: PickerFileMetadata[]}
   * }
   */
  onUploadDone(res: PickerUploadDone) {
    try {
      if (!res) {
        console.error(`onUploadDone no filestackResult?!?`);
        this.sentryService.captureMessage(`onUploadDone no filestackResult?`);
        throw new Error(this.translate.instant('ERRORS.CLIP_CREATE'));
      }
      const { filesFailed = [], filesUploaded = [] } = res;
      if (filesFailed.length > 0) {
        this.toaster.present(this.translate.instant('CLIP.CAPTURE.ERROR_UPLOADING_CLIPS', { num: filesFailed.length }));
      }
      // this.success.emit(filesUploaded);
      // // do we need a delay here to await the actions being done?
      interval(300)
        .pipe(take(1))
        .subscribe(() => {
          this.uploadSuccess.emit(filesUploaded);
        });
    } catch (error) {
      if (error && error.message) {
        this.toaster.present(error.message);
      }
      this.uploadError.emit(error);
    }
  }

  /**
   * Filestack is done with one or more
   * @see capture.page original implementation
   */
  onFileUploadFinished(res: PickerFileMetadata) {
    try {
      if (!res) {
        console.error(`onFileUploadFinished no filestackResult?!?`);
        this.sentryService.captureMessage(`onFileUploadFinished no filestackResult?`);
        throw new Error(this.translate.instant('ERRORS.CLIP_CREATE'));
      }

      this.userId$.pipe(take(1)).subscribe((userId) => {
        this.clipsService.createClipFromFilestack(res, this.projectId, userId);
      });
    } catch (error) {
      if (error && error.message) {
        this.toaster.present(error.message);
      } else {
        this.toaster.present(this.translate.instant('ERRORS.CLIP_CREATE'));
        // this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
      }
      this.uploadError.emit(error);
    }
  }

  onFileUploadFailed(res: PickerFileError) {
    console.log('### fileUploadFailed', res);
    this.sentryService.captureError(`Filestack onFileUploadFailed`);
    if (res && res.error.message) {
      this.toaster.present(res.error.message);
    } else {
      this.toaster.present(this.translate.instant('ERRORS.CLIP_CREATE'));
    }
    this.uploadError.emit(res.error);
  }
}
