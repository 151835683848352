/** @format */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from, EMPTY } from 'rxjs';
import { tap, withLatestFrom, filter, concatMap, mergeMap, catchError, map, switchMap, take } from 'rxjs/operators';
import { State } from '../reducers';
import * as memberActions from '../actions/members.actions';
import * as projectActions from '../actions/projects.actions';
import { selectMembersState, selectCurrentMember } from '../selectors/members.selectors';
import { selectProjectIds } from '../selectors/projects.selectors';
import { UsersApiService } from '@app/core/api/users-api.service';
import { User } from '@shared/models/user.model';

const DEBUG_LOGS = false;
const PAGE = '[MembersStoreEffects]';

@Injectable()
export class MembersEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  /* ACTIONS:

    export const selectMember = createAction('[MemberStore] Select MemberId', props<{ userId: string }>());

    export const loadMember = createAction(
      '[MemberStore] Load Member', 
      props<{ userId: string }>());

    export const loadMembers = createAction(
      '[MemberStore] Load Members', 
      props<{ userIds: string[] }>());

    export const addToProject = createAction(
      '[MemberStore] Add Member To Project', 
      props<{ projectId: string, user: User }>());

    export const inviteEmailToProject = createAction(
      '[MemberStore] Invite Email To Project', 
      props<{ projectId: string, email: string }>());

    export const applyAsMember = createAction(
      '[MemberStore] applyAsMember', 
      props<{ projectId: string, userId: string }>());


    * Effects
        * LoadMemberDetails(userId)
          * load UserData from API
          * load projectPreviews from store or api, add members onProjectActions.projectLoadSuccess 
  */

  /**
   * On Select Member -> Load UserData
   * memberLoad$ Effect calls userApi.getUserWithProjects
   */
  memberSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(memberActions.selectMember.type),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectCurrentMember)),
          withLatestFrom(this.store$.select(selectProjectIds))
        )
      ),
      map(([[{ userId }, selectedMember], projectIds]) => ({
        userId,
        selectedMember,
        projectIds: projectIds as string[],
      })),
      // don't filter if the user is loaded, assume that is true, either:
      // 1. memberLoad$ Effect calls userApi.getUserWithProjects
      // 2. avatar-stack membersLoad$ will only get basic public data, not projects,
      // so let's verify the projects are all loaded:
      tap(({ userId, selectedMember, projectIds }) => {
        // check if case #2
        if (
          selectedMember &&
          selectedMember.loaded &&
          (selectedMember.memberProjects?.length > 0 || selectedMember.memberProjects?.length > 0)
        ) {
          const owned = selectedMember.projects?.length > 0 ? selectedMember.projects.map((p) => p && p.id) : [];
          const memberProjects =
            selectedMember.memberProjects?.length > 0 ? selectedMember.memberProjects.map((p) => p && p.projectId) : [];
          const projectIdsNotExisting = [...owned, ...memberProjects].filter((p) => p && projectIds.indexOf(p) < 0);
          DEBUG_LOGS &&
            console.log(`${PAGE} memberSelected$ !loaded -> loadProjects`, {
              projectIdsNotExisting,
              userId,
              selectedMember,
            });
          this.store$.dispatch(projectActions.loadBatchIds({ ids: projectIdsNotExisting }));
          // this.store$.dispatch(projectActions.loadPublicByUserId({ userId }));
        }
      }),
      // if not loaded, memberLoad$ Effect calls userApi.getUserWithProjects
      filter(({ userId, selectedMember, projectIds }) => !selectedMember || !selectedMember.loaded),
      map(({ userId, selectedMember, projectIds }) => memberActions.loadMember({ userId }))
    )
  );

  /**
   * On Search Members -> Load UserData
   */
  searchMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(memberActions.queryMembers.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectMembersState)))),
      // check if we have the user's info already
      filter(([action, state]) => typeof action.query === 'string' && action.query.length > 0),
      switchMap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE}`, { action, state });
        if (!state.query) {
          return EMPTY;
        }
        return this.userApi.queryUsers(state.query).pipe(
          tap((res) => {
            if (res && res.nextToken) {
              console.warn(`${PAGE} ${action.type} userApi.queryUsers nextToken!`);
            } else {
              DEBUG_LOGS && console.log(`${PAGE} ${action.type} userApi.queryUsers res:`, res);
            }
          }),
          // we don't need to filter for new users => loadMembersSuccess, just put them in the store.queryResults
          // map((res) => (res.items as User[]).filter((user) => (state.ids as string[]).indexOf(user.userId) < 0)),
          map((res) => res.items as User[]),
          map((users) => memberActions.queryMembersSuccess({ users })),
          catchError((error) => of(memberActions.loadFail({ error, users: [] })))
        );
      })
    )
  );

  /**
   * On Load Member
   * if not already loading -> getPublicUserInfo
   * iif user has avatar from db -> loadSuccess
   *   else
   */
  memberLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(memberActions.loadMember.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectMembersState)))),
      // check if we have are loading the user already
      filter(([action, state]) => this.membersLoading.indexOf(action.userId) < 0),
      // check if we have the user's info already
      filter(([action, state]) => !state.entities[action.userId] || !state.entities[action.userId].loaded),
      tap(([action, state]) => {
        // we don't have it, let's note that we're loading
        this.loadingMember(action.userId);
        // this.store$.dispatch(memberActions.loadingMember({ userId: action.userId }));
      }),
      mergeMap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} post-filter load userData`, { action });
        return from(this.userApi.getUserWithProjects(action.userId)).pipe(
          withLatestFrom(this.store$.select(selectProjectIds)),
          tap(([user, stateProjectIds]) => {
            if (!user) {
              throw new Error('NO_USER');
            }
            if (Array.isArray(user.projects) && user.projects.length > 0) {
              this.store$.dispatch(projectActions.loadSuccess({ projects: user.projects }));
            }
            /** as string[] this was necessary due to ngrx entity key being of type number or string... ugg */
            const stateIds = stateProjectIds as string[];
            // get the memberProjects that are missing (there should not be any missing user.projects, those are now loaded in store)
            const missingMemberProjs = Array.isArray(user.memberProjects)
              ? user.memberProjects.filter((p) => stateIds.indexOf(p.projectId) < 0)
              : [];
            const needToGet = missingMemberProjs.map((m) => m.projectId);
            DEBUG_LOGS &&
              console.warn(`${PAGE} getUserWithProjects api response:`, { user, missingMemberProjs, needToGet });
            this.store$.dispatch(projectActions.loadBatchIds({ ids: needToGet }));
            this.doneLoadingMember(user.userId);
            // return [user, stateProjectIds];
          }),
          map(([user, stateProjectIds]) => memberActions.loadSuccess({ user })),
          // could check for avatar here, but let's use the avatat by userId logic elsewhere
          // mergeMap(user =>
          //   iif(() => user.avatar && user.avatar !== DEFAULT_USER_AVATAR,
          //     of(memberActions.loadSuccess({ user })),
          //     from(this.userService.getUserAvatar(user.identityId, DEFAULT_USER_AVATAR)).pipe(
          //       map(avatar => {
          //         if (avatar !== DEFAULT_USER_AVATAR) {
          //           DEBUG_LOGS && console.log(`${PAGE} getUserAvatar UPDATE avatar`, { avatar, user });
          //           // this.store.dispatch(MemberActions.updateMemberAvatar({ userId, avatar }));
          //           user.avatar = avatar;
          //         }
          //         return memberActions.loadSuccess({ user });
          //       }),
          //       catchError(error => of(memberActions.loadFail({ error, users: [{userId: action.userId}] })))
          //     )
          //   )
          // ),
          catchError((error) => of(memberActions.loadFail({ error, users: [{ userId: action.userId }] })))
        );
      })
    )
  );

  /**
   * On Load Members multiple
   * if not already loading -> getPublicUserInfo
   * iif user has avatar from db -> loadSuccess
   *   else
   */
  membersLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(memberActions.loadMembers.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectMembersState)))),
        tap(([action, state]) => {
          DEBUG_LOGS &&
            console.log(`${PAGE} membersLoad pre-filter:`, { action, state, loading: this.membersLoading.join(',') });
        }),
        map(([action, state]) => ({
          // check if we have info or are loading the user already
          userIds: action.userIds.filter(
            (id) => (!state.entities[id] || !state.entities[id].loaded) && this.membersLoading.indexOf(id) < 0
          ),
          state,
        })),
        mergeMap(({ userIds, state }) => {
          // we don't have it, let's note that we're loading
          userIds.map((id) => this.loadingMember(id));
          DEBUG_LOGS &&
            console.log(`${PAGE} membersLoad post-filter load multiple`, {
              userIds,
              loading: this.membersLoading.join(','),
            });
          // `from` emits each userId separately
          return from(userIds).pipe(
            // load each
            mergeMap((userId) =>
              from(this.userApi.getPublicUserInfo(userId)).pipe(
                take(1),
                tap((user) => {
                  DEBUG_LOGS && console.log(`${PAGE} getPublicUserInfo response:`, { user });
                  this.doneLoadingMember(user.userId);
                  this.store$.dispatch(memberActions.loadSuccess({ user }));
                }),
                // map((user) => of(memberActions.loadSuccess({ user }))), // dispatch: false
                catchError((error) => of(memberActions.loadFail({ error, users: [{ userId }] })))
              )
            )
          );
          // return EMPTY;
        }),
        catchError((error) => {
          console.warn(error);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  /**
   * On Add Member -> api.createProjectCrew
   * instead, projectEffects
   */
  // memberSave$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(memberActions.addMember.type),
  //     concatMap(action => of(action).pipe(
  //       withLatestFrom(this.store$.pipe(select(fromStore.selectMembersState)))
  //     )),
  //     tap(([action, state]) => {
  //       DEBUG_LOGS && console.log(`${PAGE} ${action.type} action`, { action });
  //     }),
  //     mergeMap(([action, state]) => {
  //       DEBUG_LOGS && console.log(`${PAGE} add member!`, { action });
  //       const user = action.user;
  //       const member: ProjectMember = {
  //         userId: user.userId,
  //         username: user.name || user.userId,

  //       };
  //       return this.memberApi.createProjectCrew(action.user).pipe(
  //         map((user) => memberActions.loadSuccess({ user })),
  //         catchError(error => of(memberActions.loadFail({ error, users: [selectedMember] })))
  //       )
  //     })
  // ), { dispatch:false });

  /**
   *
   */
  // userSetHandle$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(userActions.setUsername.type),
  //     map(action => action as any),
  //     concatMap(action => of(action).pipe(
  //       withLatestFrom(this.store$.pipe(select(fromStore.selectUserState)))
  //     )),
  //     mergeMap(([action, state]) => {
  //       return from(this.userService.updatePublicUsername(action.username)).pipe(
  //         tap(res => {
  //           DEBUG_LOGS && console.log(`${PAGE} userService.updatePublicUsername res:`, res);
  //           const prop = 'name';
  //           const updates: UpdateParam[] = [{
  //             prop,
  //             value: action.username,
  //           }];
  //           DEBUG_LOGS && console.log(`${PAGE} action -> api ${prop}`, { action, updates });
  //           from(this.userApi.updateUser(state, updates)).pipe(
  //             tap(updateRes => {
  //               DEBUG_LOGS && console.log(`${PAGE} userApi.updateUser res:`, { updateRes });
  //             }),
  //             catchError(error => {
  //               console.warn(`${PAGE} caught`, error);
  //               this.sentryService.captureError(error);
  //               return EMPTY
  //             }),
  //           );
  //         }),
  //         catchError(error => {
  //           if (error && error.code && error.code === 'AliasExistsException') {
  //             console.log(`${PAGE} Cognito:`, error.message);
  //             this.toaster.present(`Sorry, that name is already used - please try a different username.`, 'top');
  //             return of({ type: userActions.ActionTypes.SetUsernameFail })
  //           } else {
  //             console.warn(`${PAGE} caught`, error);
  //             this.sentryService.captureError(error);
  //             return EMPTY
  //           }
  //         }),
  //       );
  //     }),
  // // ));
  // ), { dispatch: false });

  /**
   * On stackPublished
   */
  // userStackPublished$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(userActions.ActionTypes.StackPublished),
  //     concatMap(action => of(action).pipe(
  //       withLatestFrom(this.store$.pipe(select(fromStore.getUserId)))
  //     )),
  //     tap(([action, userId]) => {
  //       const prop = 'numStacksPublished';
  //       DEBUG_LOGS && console.log(`${PAGE} action -> api ${prop}`, { action });
  //       const updates: UpdateParamInt[] = [{
  //         prop,
  //         value: 1, // anything defined should increment, no need for state.numClipsWatched specific
  //       }];
  //       this.userApi.incrementPublic(userId, updates).catch(error => {
  //         console.warn(`${PAGE} caught`, error);
  //         this.sentryService.captureError(error);
  //       });
  //     }),
  // ), { dispatch: false });

  /**
   * Track the currently loading Members here, instead of in store
   * since no other component cares
   */
  private membersLoading: string[] = [];
  private loadingMember = (userId) => {
    if (!this.membersLoading.includes(userId)) {
      this.membersLoading.push(userId);
    }
  };
  private doneLoadingMember = (userId) => {
    this.membersLoading = this.membersLoading.filter((item) => item !== userId);
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private actions$: Actions<memberActions.ActionsUnion>,
    private store$: Store<State>,
    private userApi: UsersApiService
  ) {}
}
