/** @format */

import { createAction, props, union } from '@ngrx/store';
import { FilterEntity } from '../reducers/viewstate.reducers';

/**
 * @todo
 * Simon - note that there's a difference in filter or filterS (we'll need to confirm i didn't break anything here...)
 */
export const setFilter = createAction('[Viewstate] Set Filter', props<{ filter: FilterEntity }>());
export const setFilters = createAction('[Viewstate] Set Filters []', props<{ filters: FilterEntity[] }>());

export const removeFilter = createAction('[Viewstate] Remove Filter', props<{ id: string }>());
export const resetFilter = createAction('[Viewstate] Reset Filter', props<{ id: string }>());

export const resetAll = createAction('[Viewstate] Reset All');

export const tourSeenOverview = createAction('[Viewstate] Tour Seen: Overview');
export const resetTourSeenOverview = createAction('[Viewstate] Reset Tour Seen: Overview');

export const tourSeenCapture = createAction('[Viewstate] Tour Seen: Capture');

export const gdprAccepted = createAction('[Viewstate] GDPR Accepted');

export const tourSeenWidgetSplash = createAction('[Viewstate] Tour Seen Widget Splash');

// do not export return value
const actions = union({
  setFilter,
  removeFilter,
  resetFilter,
  resetAll,
  resetTourSeenOverview,
  tourSeenOverview,
  tourSeenCapture,
  gdprAccepted,
  tourSeenWidgetSplash,
});

// export only type
export type ActionsUnion = typeof actions;
