<!-- @format -->

<ion-item *ngIf="myProjects && myProjects.length > 0 else noProjects" lines="none" class="select-myprojects">
  <ion-select label-placement="stacked" interface="popover" [value]="projectSelected" [compareWith]="compareProjectsWith"
    [interfaceOptions]="{ cssClass: 'wide select-with-headings' }" (ionChange)="projectChanged($event)">
    <div *ngIf="showLabel" slot="label">
      {{ (isCapture ? 'CLIP.CAPTURE.SELECT_PROJECT_LABEL' : 'PROJECT.SELECT_PROJECT') | translate }}
    </div>
    <ion-select-option [value]="-1" [disabled]="true" class="select-heading not-bold">
      {{ 'PROJECT.SELECT_PROJECT' | translate }}
    </ion-select-option>
    <ng-container *ngIf="recentProjects && recentProjects.length > 0">
      <ion-select-option [value]="-2" [disabled]="true" class="select-heading">
        {{ 'PROJECT.RECENT_PROJECTS' | translate }}
      </ion-select-option>
      <ion-select-option *ngFor="let project of recentProjects" [value]="project">
        {{ project?.title || project?.id || ('COMMON.LOADING' | translate) }}
      </ion-select-option>
      <ion-select-option [value]="-3" [disabled]="true" class="select-heading ion-padding-top">
        {{ 'PROJECT.ALL' | translate }}
      </ion-select-option>
    </ng-container>
    <ion-select-option *ngFor="let project of myProjects" [value]="project">
      {{ project?.title || project?.id || ('COMMON.LOADING' | translate) }}
    </ion-select-option>
  </ion-select>
</ion-item>

<ng-template #noProjects>
  <!-- {{ 'CLIP.CAPTURE.NO_PROJECTS' | translate }} -->
  <ion-button class="select-myprojects-create-project-btn" (click)="createProject()">
    {{ 'STUDIO.FAB.CREATE_PROJECT' | translate }}
  </ion-button>
</ng-template>