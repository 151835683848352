/** @format */

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Project } from '@projects/shared/project.model';

@Component({
  selector: 'fs-project-member-modal',
  templateUrl: './project-member-modal.component.html',
  styleUrls: ['./project-member-modal.component.scss'],
})
export class ProjectMemberModalComponent {
  @Input() userId: string;
  @Input() projects: Project[];
  @Input() viewOnly = true;
  @Input() isStudio = false;
  @Input() showRecentStacks = true;

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
