/** @format */

import { Injectable } from '@angular/core';
import { Client, ClientOptions, init, PickerFileMetadata, PickerInstance, PickerOptions } from 'filestack-js';
import { filestackConfig } from 'src/environments/environment';

export { PickerFileMetadata } from 'filestack-js';

export interface PickerFileSelected {
  file: PickerFileMetadata;
}
export interface PickerUploadDone {
  filesUploaded: PickerFileMetadata[]; 
  filesFailed: PickerFileMetadata[];
}
export interface PickerFileError {
  file: PickerFileMetadata;
  error: Error;
}

@Injectable({
  providedIn: 'root'
})
export class FilestackService {
  private clientInstance: Client;
  private clientOptions: ClientOptions;
  private apikey: string;

  constructor() {
    this.apikey = filestackConfig.apiKey;
  }

  private get client(): Client {
    if (!this.clientInstance) {
      return this.init();
    }

    return this.clientInstance;
  }

  private set client(client: Client) {
    this.clientInstance = client;
  }

  /**
   * Initialize filestack client
   * @param apikey - Filestack apikey
   * @param clientOptions - Client options
   */
  init(apikey?: string, clientOptions?: ClientOptions): Client {
    this.client = init(apikey || this.apikey, clientOptions || this.clientOptions);

    return this.client;
  }

  /**
   * Instance of picker class
   * @param options - picker options
   */
  picker(options?: PickerOptions): PickerInstance {
    return this.client.picker(options);
  }
}
