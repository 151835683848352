/** @format */

import { createAction, props, union } from '@ngrx/store';
import { User } from '@shared/models/user.model';
// import { User } from '@shared/models/user.model';

export const subUpdate = createAction('[User] Subscription Update', props<{ user: Partial<User> }>());

export const loginSuccess = createAction('[User] Login Success', props<{ user: Partial<User> }>());
export const updateUserData = createAction('[User] Update UserData', props<{ user: Partial<User> }>());

export const logout = createAction('[User] Logout');
export const reset = createAction('[User] Reset');

export const setAuthState = createAction('[User] Set Auth State', props<{ authState: string }>());

export const setAvatar = createAction('[User] Set Avatar', props<{ avatar: string; tempAvatar?: string }>());
export const setName = createAction('[User] Change Name', props<{ name: string }>());
export const setUsername = createAction('[User] Change Username', props<{ username: string }>());
export const setUsernameFail = createAction('[User] Failed Setting Username (Cognito)');

export const setEmail = createAction('[User] Change Email', props<{ email: string }>());
export const setLocation = createAction('[User] Change Location', props<{ location: string }>());
export const setCountry = createAction('[User] Change Country', props<{ country: string }>());
export const setBio = createAction('[User] Change Bio', props<{ bio: string }>());

export const interactWithDom = createAction('[User] Interacted with DOM');

/*
  INCREMENTORS
*/

export const clipAddedToStack = createAction('[User] Clip Added To Stack');
export const stackPublished = createAction('[User] Stack Published');

/*
  not used yet
*/
export const signin = createAction('[User] Signin', props<{ username: string; password: string }>());

// do not export return value
const actions = union({
  signin,
  loginSuccess,
  updateUserData,
  logout,
  reset,
  setAuthState,
  setAvatar,
  setName,
  setUsername,
  setUsernameFail,
  setEmail,
  setLocation,
  setCountry,
  setBio,
  clipAddedToStack,
  stackPublished,
  interactWithDom,
  subUpdate,
});

// export only type
export type ActionsUnion = typeof actions;

/** Actions to ignore during local-storage-sync, for efficiency */
export const ignoreStorageSync = [loginSuccess.type, updateUserData.type, interactWithDom.type, subUpdate.type];
