<ion-item [class.current]="index === currentIndex" lines="none" 
          text-wrap="true" 
          (click)="onClickItem()"
          class="mystack-item mystack-capture-item">

  <ion-reorder slot="start">
      <ion-thumbnail class="poster">
        <div *ngIf="poster" class="handle clip-poster" [ngStyle]="{'background-image': 'url(' + poster + ')'}"></div>
  
        <div *ngIf="clip?.duration && clip?.duration !== '00:00'" class="clip-length">{{clip?.duration | duration}}</div> 
        <div class="handle poster-play ion-no-margin loading-spinner">
            <ion-spinner name="crescent" [color]="progressBarColor" size="large"></ion-spinner>
        </div>

        <div class="poster-text">{{ posterText }}</div>
        
        <!-- <div *ngIf="bDisplayIndex" class="clip-index">{{index}}</div> -->
      </ion-thumbnail>
  </ion-reorder>
  
  <ion-label>
    <h2 class="clip-title keep-top ion-text-wrap">{{ clip?.title | sentenceCase }}</h2>
    <div *ngIf="dateFilmed" class="date">{{ dateFilmed }}</div>
    <ion-progress-bar [value]="progressValue" [buffer]="progressBuffer" [type]="progressType" [color]="progressBarColor"></ion-progress-bar>
    <div class="progress-status">
      {{loadingStatus}} 
    </div>
  </ion-label>

  <ion-button *ngIf="transcodingCheckTimedout" slot="end" fill="clear" color="light" (click)="checkTranscodeComplete($event)" [disabled]="transcodingCheckClicked" title="Check Again" class="timeout-refresh-btn">
    <ion-icon  slot="icon-only" name="refresh"></ion-icon>
  </ion-button>
      
  <ion-reorder slot="end">
    <ion-icon name="reorder-two-outline" size="large" class="handle" color="light"></ion-icon>
  </ion-reorder>

</ion-item>

 <!--
    removed expandable to avoid accidental deleting of clips during upload/capture
    if expandable swipe delete, add this: 
    ion-item-options (ionSwipe)="deleteClip()" 
    ion-item-option expandable (click)="deleteClip()"
  -->
<ion-item-options side="end" (click)="closeSlider()">
  <ion-item-option (click)="deleteClip($event)" color="secondary">
    <ion-icon slot="top" name="remove-circle-outline"></ion-icon>
    Remove
  </ion-item-option>
</ion-item-options>
