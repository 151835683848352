/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProjectService } from '@projects/shared/services';
import { Project } from '@projects/shared/project.model';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';

@Injectable({
  providedIn: 'root',
})
export class ProjectAdminComponentGuard {
  project$: Observable<Project>;

  constructor(private projectService: ProjectService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const projectId = route.paramMap.get('id');
    const handleFalse = () => {
      this.router.navigate([`/${PROJECT_DETAIL_ROUTE}`, projectId]);
      return false;
    };
    return this.projectService.getProjectUserIsAdmin(projectId).pipe(
      map((res) =>
        // console.log(`** project-admin.guard - res: '${res}'`, typeof res);
        res ? true : handleFalse()
      ),
      catchError((err) => {
        console.warn(`canActivate getProjectUserIsAdmin caught:`, err);
        return of(handleFalse());
      })
    );
  }
}
