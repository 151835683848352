/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, selectPlans } from '@billing/store';
import { getPlanFromOnboardingType, OnboardingMeta, OnboardingTypes, TRANSLATE_ROOT } from '../shared/onboarding.model';
import { OnboardingService } from '../shared/services/onboarding.service';
import {
  Plan,
  ChargebeePlanId,
  PlanPrice,
  PlanPriceTier,
  SubscriptionStatus,
  SubscriptionLevel,
} from '@billing/shared/billing.model';
import { ENABLE_BILLING } from '@app/app.config';
import { setSubscriptionEvent as setProjectSubscriptionEvent } from '@store/actions/projects.actions';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { PROJECT_DETAIL_ROUTE, STUDIO_SETTINGS_ROUTE } from '@app/app-routing.module';
import { getUserSubscriptionState } from '@store/selectors/user.selectors';
import { LoadingController } from '@ionic/angular';
import { Project, ProjectEventType } from '@projects/shared/project.model';

const DEBUG_LOGS = false;

@Component({
  selector: 'app-onboarding-signup',
  templateUrl: './onboarding-signup.component.html',
  styleUrls: ['./onboarding-signup.component.scss'],
})
export class OnboardingSignupComponent implements OnInit, OnDestroy {
  // @ViewChild('checkoutButton') checkoutButton: CheckoutButtonComponent;
  // triggerCheckout: Subject<number> = new Subject<number>();
  // iTriggers = 0;

  get projectId() {
    return this.selectedProjectId || this.route.snapshot.paramMap.get('projectId');
  }

  enableBilling = ENABLE_BILLING;
  translatePath = TRANSLATE_ROOT;
  actionUrl = '/signup';
  returnUrl: string;
  onboardingType: OnboardingTypes;
  // backgroundUrl = DEFAULT_BACKGROUND_URL;
  // backgroundPosition = DEFAULT_BACKGROUND_POSITION;
  isLoggedIn$ = this.userService.isLoggedIn$;
  subscriptionId: string;

  planId: ChargebeePlanId;
  isActivePro = false;
  isProPlan = false;
  plan: Plan;
  planPrices: PlanPrice[] = [];
  metaPlanOptions: OnboardingMeta[] = [];

  // get + switch based on onboardingType?
  public alertButtonsSignupSuccess = [
    {
      text: 'Skip', // will be translated onInit
      role: 'cancel',
    },
    {
      text: 'OK',
      role: 'confirm',
    },
  ];
  isAlertOpenSignupSuccess = false;

  get isWeddingRoute(): boolean {
    return this.onboardingType === OnboardingTypes.Weddings;
  }
  get currentSignupRoute(): string {
    return `/events/${this.isWeddingRoute ? 'weddings/' : ''}signup${this.projectId ? '/' + this.projectId : ''}`;
  }
  get otherEventSignupRoute(): string {
    return `/events/${this.isWeddingRoute ? '' : 'weddings/'}signup${this.projectId ? '/' + this.projectId : ''}`;
  }
  /** handle the project selection from signup-stepper */
  selectedProjectId: string;

  get hasTiers(): boolean {
    return this.tiers.length > 0;
  }
  private tiers: PlanPriceTier[] = [];
  private onDestroy$ = new Subject<void>();
  /**
   * OnDestroy is not being called reliably due to route not fully changing (nested) & component being re-used...
   * so we are duplicating onInit without onDestroy - using this to avoid
   */
  private didInit = false;

  constructor(
    private store: Store<State>,
    private onboardingService: OnboardingService,
    private userService: UserService,
    private toaster: ToasterService,
    private loadingCtrl: LoadingController,
    private translate: TranslateService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // billingService.loadPlans happens in app.component.initializeApp
    if (this.didInit) {
      return;
    }

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      if (params?.returnUrl) {
        this.returnUrl = params.returnUrl;
      } else if (this.router.getCurrentNavigation()?.extras?.state) {
        this.returnUrl = this.router.getCurrentNavigation().extras.state?.returnUrl ?? '';
      }
    });
    this.selectedProjectId = this.route.snapshot.paramMap.get('projectId');

    combineLatest([
      this.onboardingService.routeOnboardingMeta$,
      this.store.select(selectPlans),
      this.store.select(getUserSubscriptionState),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ([
          /* eslint-disable @typescript-eslint/no-unused-vars */
          { routeId, translatePath = '', actionUrl = '', backgroundUrl = '', backgroundPosition = '' },
          { plans, addons },
          { userId, subscriptionId, subscriptionLevel, subscriptionStatus, subscriptionMinutes },
          /* eslint-enable @typescript-eslint/no-unused-vars */
        ]) => {
          if (!routeId) return; // not the current route or metadata not available
          this.onboardingType = routeId;
          this.translatePath = translatePath;
          this.actionUrl = actionUrl + 'signup'; // this component, used in login-form onsuccess

          try {
            this.metaPlanOptions = [];
            if (subscriptionStatus === SubscriptionStatus.Active) {
              // we have an active sub - check if it is an upgrade or downgrade
              // console.log('here determine upgrade?', {
              //   subscriptionId,
              //   subscriptionLevel,
              //   subscriptionStatus,
              //   routeId: this.routeId
              // });
              this.isActivePro = subscriptionLevel === SubscriptionLevel.Pro;
              this.subscriptionId = subscriptionId;
              // subscriptionLevel !=== getTopSubscriptionInfo()

              if (this.isActivePro && this.onboardingType === OnboardingTypes.Pro) {
                this.metaPlanOptions = [
                  this.onboardingService.getMetaForRoute(OnboardingTypes.Teams),
                  this.onboardingService.getMetaForRoute(OnboardingTypes.Weddings),
                ];
                DEBUG_LOGS && console.log({ action: 'metaPlanOptions', metaPlanOptions: this.metaPlanOptions });
              }
            }
            // plans
            if (plans?.length > 0) {
              const { planId, plan, isProPlan, tiers } = getPlanFromOnboardingType(routeId, plans);
              if (plan && plan.id) {
                this.planId = plan.id;
                this.plan = plan;
                this.tiers = tiers;
                this.planPrices =
                  plan.prices?.sort((a, b) => (a && b && a.price && b.price && a.price > b.price ? 1 : -1)) ?? [];
                this.isProPlan = isProPlan;
                DEBUG_LOGS &&
                  console.log(`[ONBOARDING.SIGNUP] routeId: '${routeId}'`, {
                    isProPlan,
                    planId,
                    plan,
                    translatePath: this.translatePath,
                  });
              } else if (routeId !== OnboardingTypes.None) {
                console.warn('no plan?', { routeId, planId, plan, isProPlan, tiers, plans });
              }
            }
          } catch (error) {
            console.warn(error);
          }
        }
      );

    this.translate
      .get(['COMMON.SKIP', 'COMMON.OK'])
      .pipe(take(1))
      .subscribe((t) => {
        this.alertButtonsSignupSuccess[0].text = t['COMMON.SKIP'];
        this.alertButtonsSignupSuccess[1].text = t['COMMON.OK'];
      });

    this.didInit = true;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.didInit = false; // should already happen, but let'ds make sure ;)
  }

  /**
   * sent from form-select-dropdown within signup-stepper
   * update the selected Project and URL to handle reload (but don't actually navigate)
   */
  setDefaultProjectId(projectId) {
    if (projectId && projectId !== this.projectId) {
      DEBUG_LOGS && console.log(`setDefaultProjectId '${projectId}' !== current this.projectId='${this.projectId}'`);
      if (!this.projectId) {
        this.location.replaceState(`${this.currentSignupRoute}/${projectId}`);
      } else {
        this.location.replaceState(`/events/${this.isWeddingRoute ? 'weddings/' : ''}signup/${projectId}`);
      }
      this.selectedProjectId = projectId;
    }
  }

  /** navigate if eventType has changed */
  onProjectCreated(project: Partial<Project>) {
    if (
      (project.eventType === ProjectEventType.Weddings && !this.isWeddingRoute) ||
      (project.eventType !== ProjectEventType.Weddings && this.isWeddingRoute)
    ) {
      DEBUG_LOGS &&
        console.log('Routing to other event type..', {
          project: project.eventType,
          onboardingType: this.onboardingType,
        });
      this.router.navigateByUrl(this.otherEventSignupRoute);
    }
  }

  signupError(event) {
    console.warn('signupErrored', event);
    this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
  }

  /**
   * event = {
   *    selectedEventDate: "2023-09-15",
   *    successPageId: "JfZRUupQKtmkcuJDqcd9QQV6qCyjkZI9b4",
   *    selectedProjectId: "",
   *    event: {
   *       customer: ChargebeeCustomer
   *    }
   * }
   */
  signupComplete(event) {
    const {
      selectedProjectId,
      selectedEventDate,
      event: {
        successPageId = 'success-' + new Date().toISOString(),
        // customer
      } = {},
    } = event;
    this.selectedProjectId = selectedProjectId;

    if (!this.isProPlan && selectedProjectId) {
      // update the Project to include the eventType we just set
      const payload = {
        id: selectedProjectId,
        eventConfig: {}, // { expiresDate, renewsDate }
        eventDate: selectedEventDate,
        eventIsActive: true,
        eventType: this.onboardingType,
        subscriptionId: 'successPageId=' + successPageId,
        subscriptionLevel:
          this.onboardingType === OnboardingTypes.Weddings ? SubscriptionLevel.Wedding : SubscriptionLevel.Event,
        subscriptionStatus: SubscriptionStatus.Active,
        // subscriptionMinutes
        doDbUpdate: true, // persist this in DB as Effect
      };

      console.log(`setProjectSubscriptionEvent:`, { payload, event });

      this.store.dispatch(setProjectSubscriptionEvent(payload));

      // MVP-1367 if this is a wedding, allow the user the ability to create collab drafts
      if (this.onboardingType === OnboardingTypes.Weddings) {
        this.isAlertOpenSignupSuccess = true;
      } else {
        // not wedding, is event...
        this.toaster.present(this.translate.instant('ONBOARDING.EVENTS.SIGNUP_SUCCESS'));
        this.routeOnSuccess(selectedProjectId);
      }
    } else {
      // this.isProPlan || !selectedProjectId
      this.toaster.present(this.translate.instant('ONBOARDING.SIGNUP_SUCCESS'));
      this.routeOnSuccess(null);
    }
  }

  routeOnSuccess(projectId: string) {
    if (projectId) {
      this.router.navigateByUrl(`/${PROJECT_DETAIL_ROUTE}/${projectId}`, { state: { tutorial: 'SIGNUP_SUCCESS' } });
    } else {
      this.router.navigateByUrl(`/${STUDIO_SETTINGS_ROUTE}`, { state: { tutorial: 'SIGNUP_SUCCESS' } });
    }
  }

  routeToSignup() {
    this.router.navigate(['/signup'], { queryParams: { returnUrl: this.actionUrl } });
  }

  async handleSuccessAlert(event) {
    this.isAlertOpenSignupSuccess = false;
    if (event?.detail?.role === 'confirm') {
      const loading = await this.loadingCtrl.create({
        message: this.translate.instant('ONBOARDING.COMMON.CREATING_WEDDING_STACKS'),
      });
      try {
        combineLatest([this.userService.userId$, this.userService.username$])
          .pipe(take(1))
          .subscribe(([userId, username]) => {
            this.onboardingService
              .createCollabStacksForWedding({ userId, username, projectId: this.selectedProjectId })
              .then((newStacks) => {
                DEBUG_LOGS && console.log('Created Stacks:', newStacks);
                loading.dismiss();
                this.routeOnSuccess(this.selectedProjectId);
              });
          });
      } catch (error) {
        console.error(error);
        loading.dismiss();
        this.routeOnSuccess(this.selectedProjectId);
        this.toaster.present(this.translate.instant('ERRORS.STACK_CREATE'));
      }
    } else {
      this.routeOnSuccess(this.selectedProjectId);
    }
  }
}
