/** @format */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { OnboardingSetupComponent } from './onboarding-setup/onboarding-setup.component';
import { OnboardingLandingComponent } from './onboarding-landing/onboarding-landing.component';
import { OnboardingSignupComponent } from './onboarding-signup/onboarding-signup.component';
import { OnboardingInviteComponent } from './onboarding-invite/onboarding-invite.component';
import { OnboardingAcceptComponent } from './onboarding-accept/onboarding-accept.component';
import { OnboardingTourComponent } from './onboarding-tour/onboarding-tour.component';
import { OnboardingHowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { OnboardingSectionGetStartedComponent } from './components/section-get-started/section-get-started.component';
import { OnboardingSuccessComponent } from './onboarding-success/onboarding-success.component';
import { ProjectMembersModule } from '@members/project-members.module';
import { BillingModule } from '@billing/billing.module';
import { SignupStepperComponent } from './onboarding-signup/signup-stepper/signup-stepper.component';
/**
 * @note Routes cannot be specified since this Module is imported by other Modules
 * and the routes would be applied as children to those, which is not desired
 */
// import { OnboardingRoutingModule } from './onboarding-routing.module';

@NgModule({
  imports: [CommonModule, IonicModule, SharedModule, TranslateModule, ProjectMembersModule, BillingModule],
  declarations: [
    OnboardingSetupComponent,
    OnboardingLandingComponent,
    OnboardingSignupComponent,
    OnboardingInviteComponent,
    OnboardingTourComponent,
    OnboardingHowItWorksComponent,
    OnboardingSectionGetStartedComponent,
    OnboardingSuccessComponent,
    OnboardingAcceptComponent,
    SignupStepperComponent,
  ],
  exports: [
    // OnboardingLandingComponent,
    // OnboardingSuccessComponent,
  ],
  // added to support swiper.js per https://ionicframework.com/docs/angular/slides
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OnboardingModule {}
