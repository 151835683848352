/** @format */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Project } from '@projects/shared/project.model';
import { Stack } from '@shared/models/stack.model';
import { MyStackService } from '@services/mystack.service';
import { DISCOVER_PAGE, PROJECT_DETAIL_ROUTE, ROOT_EDIT_URL } from '@app/app-routing.module';

@Component({
  selector: 'app-overlay-complete',
  templateUrl: './overlay-complete.component.html',
  styleUrls: ['./overlay-complete.component.scss'],
})
export class OverlayCompleteComponent {
  @Input() stack: Stack;
  @Input() project: Project;

  @Output() watchAgain = new EventEmitter<boolean>();

  constructor(private mystackService: MyStackService, private navCtrl: NavController) {}

  addToMystack() {
    this.mystackService.addClipIdsToMyStack(this.stack.playlist);
    // then nav to editor too?
    this.navCtrl.navigateForward(ROOT_EDIT_URL);
  }
  navProject() {
    this.navCtrl.navigateForward([`/${PROJECT_DETAIL_ROUTE}`, this.stack.projectId]);
  }
  discover() {
    this.navCtrl.navigateForward(`/${DISCOVER_PAGE}`);
  }
}
