<!--
Pro: Upgrade    # minutes    cart-icon
-->
<ion-item lines="none">
  <ng-container *ngIf="(subscriptionVm$ | async)?.isActive && (subscriptionVm$ | async) as subscription; else inActive">
    <ng-container *ngIf="minutesEnabled else minutesNotEnabled">
      <ng-container *ngIf="minutes$ | async as minutes; else noMinutes">
        <ion-button
          slot="end"
          fill="clear"
          color="primary"
          class="minutes-text"
          [title]="'ADD_MINUTES_TO_CAPTURE' | translate"
          (click)="openCheckout()">
            {{ minutes }} {{ 'COMMON.MINUTES' | translate }}        
        </ion-button>
        
        <ion-button
          slot="end"
          shape="round"
          fill="outline"
          color="primary"
          class="checkout-icon"
          [title]="'ADD_MINUTES_TO_CAPTURE' | translate"
          (click)="openCheckout()">
            <ion-icon slot="icon-only" name="cart-outline" class="no-pointer-events"></ion-icon>
        </ion-button>
      </ng-container>
    
      <ng-template #noMinutes>
        <ion-button
          slot="end"
          fill="clear"
          color="primary"
          class="minutes-text"
          [title]="'AUTH.PLANS.ADD_MINUTES' | translate"
          (click)="addMinutes()">
            {{ 'AUTH.PLANS.ADD_MINUTES' | translate }}
        </ion-button>
        <ion-button
          slot="end"
          shape="round"
          fill="outline"
          color="primary"
          class="checkout-icon"
          [title]="'AUTH.PLANS.ADD_MINUTES' | translate"
          (click)="navStudioSettings()">
            <ion-icon slot="icon-only" name="create-outline" class="no-pointer-events"></ion-icon>
        </ion-button>
        
      </ng-template>
    </ng-container>
    <ng-template #minutesNotEnabled>
      <ion-button
        slot="end"
        fill="clear"
        color="primary"
        class="minutes-text"
        [title]="'AUTH.PLANS.EDIT_PROFILE_SUBSCRIPTION' | translate"
        (click)="navStudioSettings()">
          {{ 'AUTH.PLANS.EDIT_PROFILE_SUBSCRIPTION' | translate }}   
      </ion-button>
      <ion-button
        slot="end"
        shape="round"
        fill="outline"
        color="primary"
        class="checkout-icon"
        [title]="'AUTH.PLANS.EDIT_PROFILE_SUBSCRIPTION' | translate"
        (click)="navStudioSettings()">
          <ion-icon slot="icon-only" name="settings-outline" class="no-pointer-events"></ion-icon>
      </ion-button>
      
      <!-- <ion-button
        slot="end"
        shape="round"
        fill="outline"
        color="primary"
        class="checkout-icon"
        [title]="'AUTH.PLANS.EDIT_PROFILE_SUBSCRIPTION' | translate"
        (click)="navStudioSettings()">
          <ion-note color="light" class="well">
            {{ subscription?.label }}
          </ion-note>
          <ion-icon slot="icon-only" name="create-outline" class="no-pointer-events"></ion-icon>
      </ion-button> -->
    </ng-template>
  </ng-container>

  <ng-template #inActive>
    <ion-button
      slot="end"
      fill="clear"
      color="primary"
      class="minutes-text"
      [title]="'AUTH.PLANS.SUBSCRIBE_CAPTURE' | translate"
      (click)="navStudioSettings()">
        {{ 'AUTH.PLANS.SUBSCRIBE_CAPTURE' | translate }}   
    </ion-button>
    <ion-button
      slot="end"
      shape="round"
      fill="outline"
      color="primary"
      class="checkout-icon"
      [title]="'AUTH.PLANS.SUBSCRIBE_CAPTURE' | translate"
      (click)="navStudioSettings()">
        <ion-icon slot="icon-only" name="create-outline" class="no-pointer-events"></ion-icon>
    </ion-button>
  </ng-template>
</ion-item>
