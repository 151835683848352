/** @format */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamMap } from '@angular/router';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { map, share } from 'rxjs/operators';
import _merge from 'lodash/merge';
import { AppConfig } from './config.model';
import { SentryService } from '../services/analytics/sentry.service';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';

const DEBUG_LOGS = false;
const WIDGETS_DATA_FOLDER = 'assets/widgets';
/** to handle 'require', app needs 'resolveJsonModule: true' on tsconfig.app */
declare const require: NodeRequire;
const defaultConfig = require('./default_app_config.json');

/**
 * App Config based on /assets/config.json
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  projectId: string;
  stackId: string;

  private _config$ = new ReplaySubject<AppConfig>(1);

  constructor(private sentryService: SentryService, private http: HttpClient) {}

  get appConfig(): Promise<AppConfig> {
    return lastValueFrom(this._config$.asObservable().pipe(share()));
  }

  /**
   * look at the url to determine config overrides
   * ex: ?stack=masterstackr_Anti-Aging_20190219&project=tomorrow-lab&widget=none
   *
   * @todo get queryParams from ActivatedRoute on Init
   * Should avoid need to loadConfigWithQueryParams call
   * But [is not so easy to get queryParams from service](https://github.com/angular/angular/issues/11023)
   */
  public loadConfigWithQueryParams(params: ParamMap): Promise<AppConfig> {
    const [widgetId, projectId] = this.getQueryParams(params, ['widget', 'project']);
    DEBUG_LOGS &&
      console.log(`[ConfigService] loadConfigWithQueryParams`, { widgetId, projectId, params, defaultConfig });
    return this.buildAppConfig(widgetId, projectId).then((appConfig) => {
      this._config$.next(appConfig);
      this._config$.complete();

      return appConfig;
    });
  }

  public getStackDiscoverPath(): Promise<string> {
    return this.appConfig.then((config) => {
      let startPath = '/stack/discover';
      if (config.isWidgetActive) {
        if (config.startPage && config.startPage === 'project' && config.startPageParams && config.startPageParams.id) {
          startPath = `/${PROJECT_DETAIL_ROUTE}/${config.startPageParams.id}`;
        } else if (config.projectId) {
          startPath = `/${PROJECT_DETAIL_ROUTE}/${config.projectId}`;
        } else if (config.startPage) {
          startPath = config.startPage;
        }
      }
      return startPath;
    });
  }

  private getQueryParams(params: ParamMap, labels: string[]): string[] {
    const values = [];
    labels.forEach((label) => {
      if (params && params.has(label)) {
        values.push(params.get(label));
      } else {
        values.push(null);
      }
    });
    // DEBUG_LOGS && console.log(`[ConfigService] getQueryParams`, { values, labels, params });
    return values;
  }

  private async buildAppConfig(widgetId: string, projectId: string): Promise<AppConfig> {
    let appConfig = { ...defaultConfig };

    try {
      if (!!widgetId) {
        const widgetConfig: AppConfig = await lastValueFrom(this.getWidgetData(widgetId));
        widgetConfig.isWidgetActive = true;
        // deep merge the objects, to get all nested props
        // this did not deeply merge: appConfig = { ...defaultConfig, ...widgetConfig };
        appConfig = _merge(appConfig, widgetConfig); // lodash merge mutates appConfig, and returns it
      }
    } catch (err) {
      const { status = 0, statusText = '' } = err;
      const msg = `Can't load widget '${widgetId}' - http.status=${status} statusText='${statusText}`;
      console.log(msg);
      this.sentryService.captureMessage(msg);
    } finally {
      /**
       * @todo confirm highest priority queryParam.projectId vs widget.projectId
       */
      if (!!projectId) {
        appConfig.projectId = projectId;
      }
    }
    DEBUG_LOGS && console.log(`[ConfigService] buildAppConfig`, { defaultConfig, appConfig, widgetId, projectId });
    return Promise.resolve(appConfig);
  }

  private getWidgetData(widgetId: string): Observable<AppConfig> {
    const widgetConfigPath = `${WIDGETS_DATA_FOLDER}/${widgetId}.json`;
    DEBUG_LOGS && console.log(`[ConfigService] getWidgetData`, { widgetId, widgetConfigPath });

    return this.http.get<AppConfig>(widgetConfigPath).pipe(map((data) => data));
  }
}
