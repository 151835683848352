<ng-container>
  <ion-content
    class="ion-padding-horizontal"
    [class.light]="mode === 'light'"
    [class.dark]="mode === 'dark'"
  >
  <!-- 
    from PR#101 - did we want this in the ion-content?
    <ion-content [class.with-footer]="footer.children.length !== 0"
    where does variable footer come from?
    ...leaving this comment for future reference if needed...
  -->
    <div class="right">
      <div class="right__close" (click)="dismiss($event)">&times;</div>
    </div>
    <div class="header">
      <fs-list-title [icon]="titleIcon">{{ title }}&nbsp;</fs-list-title>
    </div>
    <div class="description" *ngIf="description">{{ description }}</div>
    <div class="container">
      <div class="row" style="position: relative">
        <!-- slot for content -->
        <ng-content></ng-content>
      </div>
    </div>
  </ion-content>

  <ion-footer [class.light]="mode === 'light'" [class.dark]="mode === 'dark'">
    <ng-content select=".footer"></ng-content>
  </ion-footer>
</ng-container>
