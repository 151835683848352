/** @format */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Display Durations by hiding hours if none
 */
@Pipe({
  name: 'duration',
})
export class DurationRenderPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: string, ...args: unknown[]): string {
    const str = value && typeof value === 'string' ? value : '';
    const times = str ? str.split(':') : [];
    // console.log(`DurationRenderPipe times:`, times);
    // if there's only min:sec then it'll be 2, what we want to return
    if (times.length < 3) {
      // console.log(`DurationRenderPipe times < 3 -> return`, times.length);
      return str;
    }

    // for each time greater than sec & mins (greater than length minus 2)
    const len = times.length - 2;
    for (let i = 0; i < len; i++) {
      const time = isNaN(parseInt(times[i], 10)) ? 0 : parseInt(times[i], 10);
      if (time <= 0) {
        times.shift();
      }
    }

    const padZero = (d) => ('0' + d).slice(-2);

    return times.map((time) => padZero(time)).join(':');
  }
}
