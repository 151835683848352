<!-- @format -->

<ng-template #noClips>
  <div class="no-clips ion-padding skinny-limit-width">
    <h1 class="text-uppercase">
      <span *ngIf="isCollaborative">{{ 'COMMON.COLLABORATIVE' | translate }}</span>
      {{ 'COMMON.STACK_STORY' | translate }}
    </h1>
    <div *ngIf="isMine || (isCollaborative && (canCollaborate || openInvite) ); else noEdit">
      <h2>{{ 'EDITOR.NO_CLIPS' | translate }}</h2>
      <div class="ion-text-center">
        <fs-button buttonStyle="primary" size="large" (click)="addClips.emit()">
          {{ 'CLIP.ADD_CLIPS' | translate }}
          <ion-icon *ngIf="!isSaving" slot="end" name="add-outline"></ion-icon>
        </fs-button>
      </div>
    </div>
    <ng-template #noEdit>
      {{ 'EDITOR.NO_EDIT' | translate }}
    </ng-template>
  </div>
  <!-- <div class="no-clips-image skinny-limit-width">
    <img src="assets/images/stack-placeholder.png" draggable="false" />
  </div> -->
</ng-template>

<ion-reorder-group (ionItemReorder)="doReorder($event)" [disabled]="disableReorder || (!isMine && !canCollaborate)">
  <ion-item-sliding 
    *ngFor="let clip of items; let i = index; trackBy: trackByStackItem" 
    [disabled]="!isMine && userId !== clip?.userId"
    #slidingItem>
    <!-- 
      finally figured out what happened to the transcoding progress indicators...
      when we changed the code to create the item in DB before it was done transcoding,
      the clipAPI.createClip method deleted the  ___
      just figured out why the Editor transcoding progress hasn't been working... ugg. 
      i was triggering the capture item component based on a property that i was deleting when creating in DB... 
      and in Javascript that mutates the object, which was changing the source
    -->
    <app-my-stack-item
      *ngIf="clip?.hlsSrc || clip?.sources?.length > 0; else uploadingItem"
      [attr.data-id]="clip?.projectId + '/' + clip?.id"
      [attr.data-index]="i"
      [clip]="clip"
      [index]="i"
      [playlistLength]="clips.length"
      [currentIndex]="currentIndex"
      [isPreviewOpen]="isPreviewOpen"
      [isVideoPlaying]="isVideoPlaying"
      [slidingItem]="slidingItem"
      [isAdmin]="isAdmin"
      [isMyStack]="isMine"
      [isMyClip]="userId === clip?.userId"
      (playClipIndex)="onPlayClipIndex($event)"
      (pauseClipIndex)="onPauseClipIndex($event)"
      (deleteClipIndex)="deleteClipByIndex($event)"
      (moveTop)="onMoveTop($event)"
      (moveBottom)="onMoveBottom($event)"
      class="draggable-item"
    ></app-my-stack-item>

    <ng-template #uploadingItem>
      <app-my-stack-capture-item
        [clip]="clip"
        [index]="i"
        [attr.data-index]="i"
        [currentIndex]="currentIndex"
        [isPreviewOpen]="isPreviewOpen"
        [slidingItem]="slidingItem"
        (deleteClipIndex)="deleteClipByIndex($event)"
        class="draggable-item"
      ></app-my-stack-capture-item>
    </ng-template>
  </ion-item-sliding>
</ion-reorder-group>

<div *ngIf="clips?.length > 0; else noClips" class="editor-actions">
  <!-- show the last updated -->
  <div *ngIf="stack.dteSaved || stack.updatedBy" class="editor-status ion-text-center">
    <i>{{ 'COMMON.LAST_UPDATED' | translate }} </i> 
    <b *ngIf="stack.dteSaved">{{  stack.dteSaved | date:'medium' }} </b>
    <i *ngIf="stack.updatedBy">{{ 'COMMON.BY' | translate }} </i>
    <b *ngIf="stack.updatedBy">{{ stack.updatedBy }}</b>
  </div>
  <div class="editor-actions--buttons">  
    <!-- Sort by Date is not working - we are not capturing the file date correctly durion iOS Filestack upload
    <ion-button (click)="sortDate()">
      <ion-icon slot="start" name="funnel-outline"></ion-icon>
      {{ 'EDITOR.SORT_DATE' | translate }}
    </ion-button> -->

    <!-- todo: make this sort ONLY YOUR CLIPS if !isMine
    *ngIf="isMine || (isCollaborative && canCollaborate)"  -->
    <ion-button *ngIf="isMine" (click)="sortByTitle()" fill="clear">
      <ion-icon slot="start" name="funnel-outline"></ion-icon>
      {{ 'EDITOR.SORT_TITLE' | translate }}
    </ion-button>
    
    <ion-button *ngIf="isMine" (click)="clearStack()" color="secondary" fill="clear">
      <ion-icon slot="start" name="remove-circle-outline"></ion-icon>
      {{ 'EDITOR.REMOVE_ALL_CLIPS' | translate }}
    </ion-button>
  </div>
  <div class="editor-actions--buttons">
    <ion-button *ngIf="isMine || (!stack.dtePublished && isCollaborative && canCollaborate) else startNewStack" [disabled]="isSaving" (click)="addClips.emit()" color="primary" size="large" fill="outline">
      <!-- {{ 'EDITOR.SAVE_COLLABORATIVE_DRAFT' | translate }} -->
      {{ 'CLIP.ADD_CLIPS' | translate }}
      <!-- <ion-spinner *ngIf="isSaving" slot="end" name="crescent" style="margin-left:4px;"></ion-spinner> -->
      <ion-icon *ngIf="!isSaving" slot="end" name="add-outline"></ion-icon>
    </ion-button>
    <ng-template #startNewStack>
      <div *ngIf="stack.dtePublished" class="watch-stack ion-padding-bottom">
        <span class="bright">{{ 'STACK.STACK_PUBLISHED' | translate }}</span>
        <ion-button [routerLink]="[watchRoute, stack.projectId, stack.stackId]" color="primary" fill="clear">
          {{ 'PLAYER.WATCH_IT' | translate }}
          <ion-icon slot="start" name="play"></ion-icon>
        </ion-button>
          <!-- {{ stack?.shareUrl }} -->
      </div>
      <div *ngIf="openInvite" class="join-crew ion-padding">
        <ion-button (click)="acceptInvite.emit()" color="primary">
          {{ 'FILE_UPLOAD.JOIN_CREW_UPLOAD_CLIPS' | translate }}
          <ion-icon slot="end" name="add"></ion-icon>
        </ion-button>
      </div>
      <!-- MVP-1342 not needed here, it's in the FAB..
      <div *ngIf="userId" class="re-stack">
        <ion-button (click)="newStack.emit()" color="primary" fill="outline">
          {{ 'EDITOR.CREATE_NEW_STACK' | translate }}
        </ion-button>
      </div> -->
      <div class="re-stack">
        <p>{{ 'STACK.RESTACK_HINT' | translate }}</p>
        <ion-button (click)="reStack.emit()" color="primary" fill="outline">
          {{ 'STACK.ACTIONS.RESTACK_NEW_DRAFT' | translate }}
          <!-- <ion-icon *ngIf="!isSaving" slot="end" name="save-outline"></ion-icon> -->
        </ion-button>
      </div>
    </ng-template>
  </div>
</div>

 <!-- With or without clips, no matter -->
 <div *ngIf="isMine" class="margin-top ion-padding-top ion-text-center">
  <div *ngIf="isCollaborative else mineNotCollab" class="share">
    <h3>{{ 'STACK.PUBLIC_COLLAB_DRAFT' | translate }} &mdash; {{ 'STACK.COLLAB_INVITE_HEADING' | translate }}</h3>

    <!--       
    What should happen: I make the draft collaborative and then a modal pops up asking if I 
    “ready to add clips to this collaborative draft?” 
    Or 
    “I'll come back to this collaborative draft later”
    … this would help me avoid feeling that I should “publish” the draft, 
    which ends the collaborative aspect prematurely. -->

    <div *ngIf="collabShareUrl">
      <p class="no-top-margin">
        {{ 'STACK.COLLAB_INVITE_TEXT' | translate }}
      </p>
      
      <ion-button fill="outline" (click)="showingQr = !showingQr"
        [title]="('COMMON.TOGGLE' | translate) + ' ' + ('ONBOARDING.COMMON.QR_CODE' | translate)">
        <span *ngIf="!showingQr">{{ 'COMMON.SHOW' | translate }}</span>
        <span *ngIf="showingQr">{{ 'COMMON.HIDE' | translate }}</span>
        &nbsp;{{ 'ONBOARDING.COMMON.QR_CODE' | translate }}
        <ion-icon *ngIf="!showingQr" name="chevron-down-outline"></ion-icon>
        <ion-icon *ngIf="showingQr" name="chevron-up-outline"></ion-icon>
      </ion-button>
      <app-qr-code *ngIf="showingQr" [qrCode]="collabShareUrl"></app-qr-code>
      <!-- 
        todo: how to implement a named input and pass readonly attr to fs-input? (how to accomplish the below?)
        your crew now has access, here's a link to share via email
      -->
      <!-- <fs-input #inputShareUrl label="Share URL" [value]="[shareUrl]"></fs-input> -->
      <ion-item class="share--item" lines="none">
        <ion-input #inputShareUrl readonly selectAllOnClick [value]="collabShareUrl"
        labelPlacement="stacked" [label]="'EDITOR.SHARE_COLLABORATIVE_DRAFT' | translate"></ion-input>
        <ion-button slot="end" shape="round" (click)="copyLink()"
          [title]="'SOCIAL.COPY_TO_CLIPBOARD' | translate">
          <ion-icon name="link" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-item>

      <p>{{ 'EDITOR.COLLAB_INSTRUCT_REMOVE' | translate }}</p>
      <ion-button fill="clear" color="secondary" class="share--action-btn" (click)="removeCrew()">
        <ion-icon slot="start" name="person-remove-outline"></ion-icon>
        {{ 'STACK.ACTIONS.REMOVE_CREW' | translate }}
      </ion-button>
    </div>
  </div>
  <ng-template #mineNotCollab>
    <!-- not needed, handled in stack-edit  -->
    <div *ngIf="stack && stack.stackId && !stack.dtePublished" class="ion-padding-bottom">
      <h3>{{ 'STACK.DRAFT_INVITE' | translate }}</h3>
      <div class="ion-text-center">
        <fs-button buttonStyle="outline" (click)="inviteCrew()">
          <!-- {{ 'COMMON.LEARN_MORE' | translate }} -->
          {{ 'STACK.ACTIONS.INVITE_CREW' | translate }}
        </fs-button>
      </div>
    </div>
  </ng-template>

  <div class="editor-actions--buttons ion-padding-top ion-margin-top">
    <ion-button *ngIf="clips?.length > 0" (click)="continuePublish.emit(true)" color="primary" size="large">
      {{ 'COMMON.CONTINUE_PUBLISH' | translate }}
      <ion-icon slot="end" name="send"></ion-icon>
    </ion-button>
  </div>
</div>

<!-- <my-stack-list-item *ngFor="let clip of clips; index as i; trackBy: trackByStackItem" 
[attr.data-id]="clip?.projectId + '/' + clip?.id" 
[clip]="clip" [index]="i"
[currentIndex]="currentIndex"
[isPreviewOpen]="isPreviewOpen"
(playClipIndex)="onPlayClipIndex($event)"
(deleteClipIndex)="delete($event)"
class="draggable-item"> -->
