/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Injectable } from '@angular/core';
import { Analytics } from 'aws-amplify';
/** https://github.com/faisalman/ua-parser-js */
import { UAParser } from 'ua-parser-js';
import { ConfigService } from '@app/core/config';
import { Utils } from '@shared/utils';
import { environment } from 'src/environments/environment';
import { ProjectMember, PROJECT_MEMBER_ROLE } from '@members/shared/project-member.model';
import { Project } from '@projects/shared/project.model';
import _partition from 'lodash/partition';

const DEBUG_LOGS = !environment.production;

export interface PinpointEvent {
  [propName: string]: any; // any props
  name: string; // event KEY
}

export interface PinpointEndpointInput {
  /** The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number. */
  address?: string;
  channelType?: 'EMAIL' | 'APNS';
  /** Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment. */
  attributes?: {
    // hobbies: ['piano', 'hiking']
    [propName: string]: string[];
  };
  /** userId */
  userId?: string;
  /**
   * User attributes, example: { interests: ['football', 'basketball', 'AWS']}
   * It looks like the userAttributes values should be an array instead of a string.
   * Here's the Pinpoint API documentation for more info:
   * https://docs.aws.amazon.com/pinpoint/latest/apireference/apps-application-id-endpoints.html#apps-application-id-endpoints-prop-endpointuser-userattributes
   */
  userAttributes?: {
    // interests: ['football', 'basketball', 'AWS']
    [propName: string]: string[];
    location?: string[];
  };
  demographic?: {
    /** The version of the application associated with the endpoint. */
    appVersion?: string;
    /** The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value */
    locale?: string;
    /** The manufacturer of the endpoint device, such as Apple or Samsung. */
    make?: string;
    /** The model name or number of the endpoint device, such as iPhone. */
    model?: string;
    /** The model version of the endpoint device. */
    modelVersion?: string;
    /** The platform of the endpoint device, such as iOS or Android. */
    platform?: string;
    /** The platform version of the endpoint device. */
    platformVersion?: string;
    /** The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles. */
    timezone?: string;
  };
  location?: {
    /** The city where the endpoint is located. */
    city?: string;
    /** The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States. */
    country?: string;
    /** The latitude of the endpoint location, rounded to one decimal place. */
    latitude?: number;
    /** The longitude of the endpoint location, rounded to one decimal place. */
    longitude?: number;
    /** The postal code or zip code of the endpoint. */
    postalCode?: string;
    /** The region of the endpoint location. For example, in the United States, this corresponds to a state. */
    region?: string;
  };
  /**
   * Custom metrics that your app reports to Amazon Pinpoint.
   * ideas:
   * - num_stacks_published
   * - ...
   */
  metrics?: {
    [propName: string]: any;
  };
  /**
   * Indicates whether a user has opted out of receiving messages with one of the following values:
   * ALL - User has opted out of all messages.
   * NONE - Users has not opted out and receives all messages.
   *
   * @todo: implement OptOut
   */
  optOut?: 'ALL' | 'NONE';
}

/**
 * Docs for amplify Pinpoint Analytics class
 * https://aws-amplify.github.io/amplify-js/api/classes/analyticsclass.html
 */
@Injectable({
  providedIn: 'root',
})
export class PinpointService {
  // ready: boolean = false;

  constructor(private configService: ConfigService) {}

  /**
   * Recording an event
   * To record custom events call the record method
   * @param record string event-name or object ex:
   * {
   *   name: 'Album',
   *   attributes: { genre: 'Rock', year: '1989' }
   * }
   * record(event: string | object, provider?: any, metrics?: EventMetrics): Promise<unknown>
   * @returns Promise<unknown> A promise which resolves if buffer doesn't overflow
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async recordEvent(event: PinpointEvent) {
    try {
      // return await Analytics.record(event);
    } catch (error) {
      console.warn(error);
    }
  }

  /**
   * Update Endpoint
   * An endpoint represents a destination that you can send messages to,
   * such as a mobile device, email address, or phone number.
   *
   * You can use the Endpoint resource to create, retrieve information about, update, or delete
   * a specific endpoint from an application. This includes updating the settings and attributes of an endpoint.
   * To perform these tasks for multiple endpoints in a single operation, use the Endpoints resource.
   * https://docs.aws.amazon.com/pinpoint/latest/apireference/apps-application-id-endpoints.html
   *
   * There is a limit of 15 unique endpoints per user ID. See Pinpoint's documentation on endpoint quotas to learn more.
   * https://docs.aws.amazon.com/pinpoint/latest/developerguide/quotas.html#quotas-endpoint
   *
   * Learn more about Amazon Pinpoint and Endpoints: https://docs.aws.amazon.com/pinpoint/latest/developerguide/audience-define-user.html
   */
  async updateEndpoint(payload: PinpointEndpointInput) {
    try {
      const res = await Analytics.updateEndpoint(payload);
      DEBUG_LOGS && console.log(`[Pinpoint] updateEndpoint res:`, { res, payload });
    } catch (error) {
      console.warn(error);
      console.log('PinpointEndpointInput:', payload);
    }
  }

  sendInitEvent() {
    try {
      const hostname =
        window && window.location && window.location.hostname ? window.location.hostname : '!window.location.hostname';
      /**
       * Dev: capture widget session
       * @todo remove this once verified
       *
       * var url = (window.location != window.parent.location)
       *    ? document.referrer
       *    : document.location.href;
       *
       * Causes Error: SecurityError: Blocked a frame with origin "https://app.filmstacker.com" from accessing a cross-origin frame
       */
      let referrer = '';
      try {
        if (document.referrer && !document.referrer.startsWith('https://app.filmstacker.com')) {
          referrer = document.referrer;
        }
      } catch (error) {
        console.warn(`[App] document.referrer caught:`, error);
      }
      // this.configService.loadConfig().then(config => {
      //   if (config.isWidgetActive) {
      //     eventLabel = `Event: ${config.eventTitle} [${config.event}] [${config.appVersion}]`;
      //   } else {
      //     eventLabel = `${config.projectId} [${config.appVersion}]`;
      //   }
      // });

      this.recordEvent({
        name: 'app_init',
        version: Utils.getAppVersion(environment.version, environment.production),
        env: environment.name,
        hostname,
        ...(referrer ? { referrer } : {}), // add referrer if it exists
      });
    } catch (error) {
      console.warn(error);
    }
  }

  /**
   * Capturing the User Event
   * @param user
   */
  setUser(user: {
    id: string;
    userId: string;
    username: string;
    email?: string;
    location?: string;
    groups?: string[];
  }) {
    const {
      // id, // identityId
      userId,
      username,
      email,
      location: userLocation,
      groups = [],
    } = user;

    /**
     * let's see what data we can extract from our browser
     * @note are any of these automatically populated?
     */
    const metrics: any = {},
      attributes: any = {},
      demographic: any = {},
      location: any = {},
      userAttributes: any = {
        username: [username],
        userId: [userId],
        ...(userLocation ? { location: [userLocation] } : {}),
      };

    try {
      demographic.appVersion = environment.production ? environment.version : environment.version + '[dev]';

      if (window && window.navigator) {
        // locale = navigator.languages || navigator.language
        const updateLang = (lang) => {
          // "en-US"
          demographic.locale = lang.replace('-', '_');
          /** The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States. */
          location.country = lang.substring(lang.indexOf('-') + 1);
        };
        if (
          Array.isArray(window.navigator.languages) &&
          window.navigator.languages.length > 0 &&
          window.navigator.languages[0] &&
          typeof window.navigator.languages[0].replace === 'function'
        ) {
          //navigator.languages  //["en-US", "zh-CN", "ja-JP"]
          updateLang(window.navigator.languages[0]);
        } else if (window.navigator.language && typeof window.navigator.language.replace === 'function') {
          // navigator.language   //"en-US"
          updateLang(window.navigator.language);
        }

        if (window.navigator.userAgent) {
          // const isMobile = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
          // we never like inspecting UserAgent, but for now let's use UAParser...
          const parser = new UAParser();
          const {
            device,
            os,
            // browser: { name: browserName, version: browserVersion } = {},
          } = parser.getResult();

          if (device.vendor) {
            demographic.make = device.vendor;
          }
          if (device.model) {
            demographic.model = device.model;
          }
          if (os.name) {
            demographic.platform = os.name;
          }
          if (os.version) {
            demographic.platformVersion = os.version;
          }
          /**
           * this is not really the "ModelVersion": "8", let's see if this gets set automatically by Pinpoint
           * @todo where should deviceType get captured? does it already?
           */
          if (device.type) {
            // demographic.modelVersion = deviceType;
          }
        }
      } //end window.navigator

      /*
        Custom metrics that your app reports to Amazon Pinpoint. 
        metrics?: {
          [propName: string]: any;
        };
      */
    } catch (error) {
      console.log(`[Pinpoint] Caught Error extracting analytics data`, error);
    }
    /*
      we want attributes to use as selection criteria to create segments.
      possibilities:
        roles: [owner,producer,crew] - are you a project owner? crew, producer, on how many of each?
        groups: [Cognito UserGroups]
      example:
        interests: ['science', 'politics', 'travel']
     */
    try {
      if (Array.isArray(groups) && groups.length > 0) {
        attributes.groups = groups;
      }
    } catch (error) {
      console.log(`[Pinpoint] Caught Error extracting analytics attributes`, error);
    }

    try {
      // "Timezone": "America/Los_Angeles"
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (tz) {
        userAttributes.timeZone = tz;
      }
    } catch (error) {
      console.log(`[Pinpoint] Caught error during Intl.DateTimeFormat.timeZone`);
    }

    DEBUG_LOGS && console.log(`[Pinpoint] setUser`, { user, email, userId });

    if (email && userId) {
      // email is required for address...
      this.updateEndpoint({
        address: email, // the key to send info to
        channelType: 'EMAIL',
        userId,
        attributes,
        userAttributes: {
          // this doesn't make sense?
          /** @todo where do save the username? */
          username: [username],
          userId: [userId],
          ...(userLocation ? { location: [userLocation] } : {}),
        },
        demographic,
        location,
        /**
         * Indicates whether a user has opted out of receiving messages with one of the following values:
         * ALL - User has opted out of all messages.
         * NONE - Users has not opted out and receives all messages.
         *
         * @todo: implement OptOut
         */
        optOut: 'NONE',
      });
    }
  }

  /**
   * Foreach project the current user has, update the endpoint data
   * Be sure to add all at once, else append to existing since they will overwrite Pinpoint data
   *
   * @todo ERROR: updateEndpoint failed UnknownError: Too many attribute values for projects
   * appears limited to 50 max, so for now just take the first 50...
   */
  updateUserProjects({
    // identityId,
    userId,
    projectsOwned,
    projectRoles,
  }: {
    // identityId: string;
    userId: string;
    projectsOwned: Project[];
    projectRoles: ProjectMember[];
  }) {
    const attributes = {
      roles: [], // ['CREW', 'PRODUCER', 'OWNER'],
      projects: [],
      projects_producer: [],
      projects_crew: [],
    };
    if (Array.isArray(projectsOwned) && projectsOwned.length > 0) {
      const projectsOwnedIds = projectsOwned.filter((p) => p && p.id).map((p) => p.id);
      attributes.projects.push(...projectsOwnedIds);
      attributes.roles.push(PROJECT_MEMBER_ROLE.OWNER);
    }
    if (Array.isArray(projectRoles) && projectRoles.length > 0) {
      // partition projectMembers active and with a projectId
      const [crew, producers] = _partition(
        projectRoles.filter((p) => p && p.projectId && p.isActive),
        (o) => o.role === PROJECT_MEMBER_ROLE.CREW
      );
      if (producers.length > 0) {
        attributes.roles.push(PROJECT_MEMBER_ROLE.PRODUCER);
        attributes.projects_producer.push(...producers.map((p) => p.projectId));
        attributes.projects.push(...attributes.projects_producer);
      }
      if (crew.length > 0) {
        attributes.roles.push(PROJECT_MEMBER_ROLE.CREW);
        attributes.projects_crew.push(...crew.map((p) => p.projectId));
        attributes.projects.push(...attributes.projects_crew);
      }
    }

    // updateEndpoint Error: Too many attribute values for projects
    // appears limited to 50 max, so for now just take the first 50...
    attributes.projects = attributes.projects.slice(0, 50);
    attributes.projects_producer = attributes.projects_producer.slice(0, 50);
    attributes.projects_crew = attributes.projects_crew.slice(0, 50);

    this.updateEndpoint({
      // identityId,
      userId,
      attributes,
    });
  }

  /**
   * Custom metrics that your app reports to Amazon Pinpoint.
   * @todo ideas:
   * - num_stacks_published
   * - ...
   */
  updateUserMetrics(metricsInput) {
    const metrics: PinpointEndpointInput['metrics'] = {};
    for (const [key, value] of Object.entries(metricsInput)) {
      switch (key) {
        default:
          DEBUG_LOGS && console.log(`[Pinpoint] UNHANDLED updateUserMetrics prop: '${key}'`);
      }
    }
    if (Object.keys(metrics).length > 0) {
      this.updateEndpoint({ metrics });
    }
  }

  /**
   * @todo do this when it happens in Clip Settings! ...or,
   * @todo send from Profile, when it has these details... or,
   * @todo Ask User for GeoLocation and update - or
   * https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API/Using_the_Geolocation_API#examples
   */
  updateUserLocation(locationInput) {
    const location: PinpointEndpointInput['location'] = {};
    for (const [key, value] of Object.entries(locationInput)) {
      switch (key) {
        case 'latitude': // number
        case 'longitude': // number
          if (typeof value === 'number') {
            location[key] = value;
          }
          break;
        /** The region of the endpoint location. For example, in the United States, this corresponds to a state. */
        case 'region': // string
        case 'city': // string
        case 'postalCode': // string
          if (typeof value === 'string') {
            location[key] = value;
          }
          break;
      }
    }
    if (Object.keys(location).length > 0) {
      this.updateEndpoint({ location });
    }
  }

  /**
   * Handle the props to update our endpoint..
   */
  userUpdated({ property, value, userId }) {
    if (!property || !value) return;
    switch (property) {
      case 'userId':
        this.updateEndpoint({ userId: value });
        break;
      case 'location':
        this.updateEndpoint({ userAttributes: { location: value } });
        break;
      case 'bio':
        this.updateEndpoint({ userAttributes: { bio: value } });
        break;
      case 'country': // not currently used in the app, or is it?
        this.updateEndpoint({ location: { region: value } });
        break;
      case 'avatar':
        // ignoring
        break;
      default:
        DEBUG_LOGS && console.log(`[Pinpoint] UNHANDLED userUpdated prop: '${property}'`);
    }
  }
}

// examples() {

//   this.pinpoint.startTrackerWithId(GA_ID, 30)
//     .then(() => {
//       console.log('Google analytics is ready now');

//       this.pinpoint.trackView('Screen Title', 'my-scheme://content/1111?utm_source=google&utm_campaign=my-campaign')
//         .then(() => {
//           console.log("Success 1")
//         })
//         .catch((e) => {
//           console.log("Faild 1" + e)
//         })

//       this.pinpoint.trackEvent('Category', 'Action', 'Label', 30)
//         .then(() => {
//           console.log("Success Event");
//         })
//         .catch(() => {
//           console.log("Faild")
//         })

//       this.pinpoint.trackMetric(5)
//         .then(() => {
//           console.log("Key Matrics run successfully");
//         })
//         .catch((e) => {
//           console.log("Faild" + e)
//         })

//       this.pinpoint.trackTiming('Category', 2, 'Variable', 'Label')
//         .then(() => {
//           console.log("TrackTiming success")
//         })
//         .catch((e) => {
//           console.log("Faild" + e)
//         })
//       // Tracker is ready

//     })

// }
