/** @format */

import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

// Import ngrx Tools
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

/**
 * MVP-1128 upgraded from "@ionic/storage": "2.2.0" to "@ionic/storage-angular": "3.0.6"
 * @see Readme https://github.com/ionic-team/ionic-storage/blob/main/README.md
 * @see Changelog https://github.com/ionic-team/ionic-storage/blob/main/CHANGELOG.md
 *
 * 2022-02-09 moved from app.module to core/store/store.module.ts
 * @todo MVP-1245 2022-02-09 awaiting PR https://github.com/ionic-team/ionic-storage/pull/269
 * @see ionic-storage.ts implementation referenced in PR
 * @see ngrx-store-ionic-storage.ts detailed implementation of local storage
 */
import { IonicStorageModule, Drivers } from './ionic-storage';
// import { IonicStorageModule } from '@ionic/storage-angular';

// localstoragesync
import { StorageSyncEffects, storageSync } from './ngrx-store-ionic-storage';
// Import ngRx Store
import { reducers, logger, initialState } from './reducers';
//add the effects we are using
/** todo: MVP-1160 history of stacks + progress */
// import { HistoryEffects } from './effects/history.effects';
import { ProjectsEffects } from './effects/projects.effects';
import { StacksEffects } from './effects/stacks.effects';
import { MyStackEffects } from './effects/mystack.effects';
import { ClipEffects } from './effects/clips.effects';
import { UserEffects } from './effects/user.effects';
import { MembersEffects } from './effects/members.effects';
import { ListsEffects } from './effects/lists.effects';
import { ResetEffects } from './effects/reset.effects';

import { environment } from '../../../environments/environment';
import { AnalyticsEffects } from './effects/analytics.effects';

import { ignoreStorageSync as ignoreClipActions } from './actions/clips.actions';
import { ignoreStorageSync as ignoreMystackActions } from './actions/mystack.actions';
import { ignoreStorageSync as ignoreStackActions } from './actions/stacks.actions';
import { ignoreStorageSync as ignoreProjectActions } from './actions/projects.actions';
import { ignoreStorageSync as ignoreUserActions } from './actions/user.actions';

// Import Providers Service
// import { AlertService } from '../../providers/alert-service/alert-service'; // TODO

/**
 * store state features/keys to sync with localstorage
 */
export const LOCALSTORAGE_KEYS = [
  // 'history', // todo: MVP-1160 history of stacks + progress
  'viewstate',
  'environ',
  'mystack',
  'playlist',
  'watchLater',
  // ... (!environment.production) ? ['projects'] : [], // enable for dev - save on api calls during dev

  // 'clips', // disable for dev
  // 'stacks', // disable for dev

  // 'router', // test case: does it reload where you were? no, errors with "ngrx-store-ionic-storage onSyncErr: DOMException: Failed to execute 'put' on 'IDBObjectStore'"
  // not sync'd
  // 'search',
  // 'layout',
];

/**
 * callback from https://github.com/natural-apptitude/ngrx-store-ionic-storage
 * @param err
 */
function onSyncError(err) {
  console.warn('ngrx-store-ionic-storage onSyncErr:', err);
}

/**
 * Pass options into the storageSync function to create a meta-reducer, and compose it with your other reducers
 * https://github.com/natural-apptitude/ngrx-store-ionic-storage
 */
export const storageSyncReducer = storageSync({
  // Only sync these state features/keys
  keys: LOCALSTORAGE_KEYS,
  // keys: [],   // DEV: reset local each time
  ignoreActions: [
    // Don't sync when these actions occur (avoids write to localstorage - more efficient than just specifying keys)
    ...ignoreMystackActions,
    ...ignoreStackActions,
    ...ignoreClipActions,
    ...ignoreProjectActions,
    ...ignoreUserActions,
  ],
  hydratedStateKey: 'hydrated', // Add this key to the state
  // eslint-disable-next-line object-shorthand
  onSyncError: onSyncError, // If a sync fails
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const metaReducers: Array<MetaReducer<any, any>> = [logger, storageMetaReducer];

// Pretend this is dynamically injected at runtime
const dynamicInitialState = {
  ...initialState,
};

// Static state
// is this really how it needs done?
// https://github.com/ngrx/platform/blob/master/docs/store/api.md#initial-state-and-ahead-of-time-aot-compilation
const staticInitialState = {
  hydrated: false,
  clips: {
    ids: [],
    entities: {},
    loadingIds: [],
  },
  environ: {
    permissionLevel: '',
    activeProject: '',
    currentClip: '',
    currentStack: null,
    searchProjects: [],
    searchTemplates: [],
    searchTags: [],
    searchLocations: [],
    searchDatetime: [],
    searchMood: [],
    searchCollabs: [],
    searchLanguage: [],
  },
  // todo: MVP-1160 history of stacks + progress
  // history: {
  //   loaded: false,
  //   loading: false,
  //   ids: [],
  //   current: null,
  //   firstSeenCurrent: false,
  // },
  mystack: {
    loaded: false,
    loading: false,
    currentIndex: 0,
    clipIds: [],
    projectId: '',
    stackId: '',
    credits: '',
    userId: '',
    userIdentityId: '',
    avatar: '',
    eventId: '',
    title: '',
    poster: '',
    playlist: [],
    description: '',
    duration: '00:00',
    dteSaved: '',
    dtePublished: '',
    recommended: 0,
    featured: 0,
    suggested: 0,
    topics: [],
    emotions: [],
    tags: [],
    votes: 0,
    views: 0,
    shares: 0,
    restacks: 0,
    projectUrl: '',
    shareUrl: '',
  },
  playlist: {
    loaded: false,
    loading: false,
    ids: [],
    current: null,
    currentIndex: null,
  },
  projects: {
    loaded: [],
    loading: [],
    ids: [],
    entities: {},
    selectedId: null,
    mine: [],
    nextTokenFeatured: null,
    nextTokenMine: null,
    nextTokenOther: null,
  },
  search: {
    ids: [],
    loading: false,
    query: '',
  },
  stacks: {
    loaded: false,
    loading: false,
    ids: [],
    entities: {},
    currentId: null,
    selectedId: null,
  },
  watchLater: {
    loaded: false,
    loading: false,
    ids: [],
  },
  viewstate: {
    filters: {},
    tourSeen: {
      overview: false,
      capture: false,
    },
  },
  user: {
    loggedIn: false,
    userId: null,
    username: '',
    email: '',
    identityId: '',
    cognitoUser: null,
    avatar: '',
    location: '',
    bio: '',
    groups: [],
    projects: [],
    projectCrews: [],
    // bookmarks: [],
    numClipsWatched: 0,
    numClipsAddedToStack: 0,
    numStacksPublished: 0,
    numStacksWatched: 0,
    numVotes: 0,
    numShares: 0,
    votes: [],
    likes: [],
  },
  members: {
    selectedId: null,
    nextTokens: {},
    loadedProjects: [],
  },
};

// In this function dynamic state slices, if they exist, will overwrite static state at runtime.
export function getInitialState() {
  // @todo couldn't staticInitialState be taken from `{ initialState } from @store/reducers`?
  return { ...staticInitialState, ...dynamicInitialState };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const providers: Array<any> = [
  // AlertService,
  // AuthStoreService
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const effects: Array<any> = [
  StorageSyncEffects,
  /** todo: MVP-1160 history of stacks + progress */
  // HistoryEffects,
  ProjectsEffects,
  StacksEffects,
  MyStackEffects,
  ClipEffects,
  UserEffects,
  MembersEffects,
  ListsEffects,
  ResetEffects,
  AnalyticsEffects,
];

/*
  Bootstrap NgRxStoreModule
  with default root store state & reducer & effects
  => rest of store will be loaded with lazy loading
*/
@NgModule({
  imports: [
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: 'FilmstackerSync',
      // defaults to indexedDB -> webSQL -> localStorage
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      // dbKey:'', // this is only for SQLite cipher
    }),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      initialState: getInitialState, // for AOT
      runtimeChecks: {
        // TODO: debug when turned on ERROR TypeError: Cannot read property 'color' of undefined (@freeze)
        strictStateImmutability: false,
        strictActionImmutability: false,
        // router state fails the serializability checks, to fix: https://ngrx.io/guide/router-store/configuration
        // strictStateSerializability: true,
        // strictActionSerializability: true,
      },
    }),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last # states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true,
    }),
    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([...effects]),
    /**
     * `provideDB` sets up @ngrx/db with the provided schema and makes the Database
     * service available.
     */
    // DBModule.provideDB(schema),

    // Connects RouterModule with StoreModule
    // StoreRouterConnectingModule.forRoot(),
  ],
  providers: [...providers, ...effects],
})
export class NgRxStoreModule {
  // guarantee that only one instance of Services is added to the root module
  // see => https://angular-2-training-book.rangle.io/handout/modules/feature-modules.html
  static forRoot(): ModuleWithProviders<NgRxStoreModule> {
    return {
      ngModule: NgRxStoreModule,
      providers: [...providers, ...effects],
    };
  }
}
