<fs-base-modal mode="light">
  <div class="col">
    <app-add-member
      [isStudio]="isStudio"
      [project]="project"
      (dismiss)="dismiss()"
    ></app-add-member>

    <div class="col ion-text-center ion-padding ion-margin-top">
      <ion-button color="primary" fill="clear" size="medium" (click)="dismiss()">
        {{ 'COMMON.DONE' | translate }}
      </ion-button>
    </div>
  </div>

</fs-base-modal>
