/** @format */

import _memoize from 'lodash/memoize';
import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import {
  State,
  featureKey,
  getSelectedId,
  selectIds,
  selectEntities,
  selectTotal,
  selectAll,
  getQuery,
  getQueryResults,
} from '../reducers/members.reducers';
import { selectMyProjectsIds } from './projects.selectors';

/**
 * Members Selectors (ngrx 8 entity)
 */

export const selectMembersState = createFeatureSelector<State>(featureKey);

// select the array of user ids
export const selectMemberIds = createSelector(selectMembersState, selectIds);
// select the dictionary of user entities
export const selectMemberEntities = createSelector(selectMembersState, selectEntities);
// select the total user count
export const selectMemberTotal = createSelector(selectMembersState, selectTotal);
export const selectAllMembers = createSelector(selectMembersState, selectAll); // select the array of users

export const selectCurrentMemberId = createSelector(selectMembersState, getSelectedId);

export const selectCurrentMember = createSelector(
  selectMemberEntities,
  selectCurrentMemberId,
  (entities, id) => id && entities[id]
);

/** @deprecated unused, i think? */
export const selectMemberQuery = createSelector(selectMembersState, getQuery);
export const selectMemberQueryResults = createSelector(selectMembersState, getQueryResults);

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * MEMOIZATION
 * In contrast to a normal NgRx selector where the selector is shared across multiple components,
 * we now have a new instance of the selector every time the selector factory is invoked.
 * This has the effect that we lose the memoization benefits of the selector.
 *
 * For most cases this is fine and you won't notice the difference. But when the selector has to so expensive work,
 * you can add a memoization layer on top as a countermeasure.
 *
 * To accomplish this, we must bring our own memoization method to the table, for example lodash.memoize.
 * After this, we can simply wrap the selector inside of the memoize method.
 * Note that this is a simple example and that you probably don't need to memoize an entity lookup.
 *
 * By doing this, consuming the selector is no different than before.
 *
 * It's important to keep in mind that this builds up an in-memory cache,
 * so that's why it's better that the added entry is disposed of when it's possible.
 *
 * https://lodash.com/docs/4.17.15#memoize
 * Note: The cache is exposed as the cache property on the memoized function.
 * Its creation may be customized by replacing the _.memoize.Cache constructor
 * with one whose instances implement the Map
 * method interface of clear, delete, get, has, and set.
 *
 * @dev This will likely be the solution for Lists items
 *
 * Usage:
 * class MyComponent {
 *  list$ = this.store.select(lists.selectList('listId'));
 * }
 */
export const selectMember = _memoize((id: string) => createSelector(selectMemberEntities, (entities) => entities[id]));
// export const selectMembersByIds = (ids: string[]) =>
//   forkJoin(ids.map(id => selectMember(id)));

/** @deprecated backward compat */
export const getMember = selectMember;

/** _not_ memoized */
export const getMembersInProjectIds = (props: { ids: string[] }) =>
  // MVP-970 ngrx deprecated selectorWithProps
  createSelector(selectAllMembers, (entities) =>
    entities.filter(
      (e) =>
        Array.isArray(e.memberProjects) &&
        Array.isArray(props.ids) &&
        e.memberProjects.some((p) => p.projectId && props.ids.indexOf(p.projectId) > -1)
    )
  );

/**
 * Get Members for all MY Projects (Studio & Community Slider)
 * find all crew in all my projects, active and not me
 */
export const selectMembersOfMyProjects = createSelector(
  selectAllMembers,
  selectMyProjectsIds,
  selectCurrentMemberId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (entities, projectIds, currentUserId) =>
    entities.filter(
      (e) =>
        Array.isArray(e.memberProjects) &&
        Array.isArray(projectIds) &&
        e.memberProjects.some(
          (p) => p.isActive && p.projectId && projectIds.indexOf(p.projectId) > -1
          // we do want the selectedMember, this is not the current user auth'd
          // p.isActive && p.userId !== currentUserId && p.projectId && projectIds.indexOf(p.projectId) > -1
        )
    )
);

// from project-member.service:
// selectProjectMembersByProjectIds(ids: string[]): Observable<User[]> {
//   return this.store.select(getMembersInProjectIds({ ids }));
// }
