<fs-base-modal title="" mode="dark" #modal>

  <div class="ion-text-center skinny-limit-width capture-clip-modal">
    <h1>{{ 'CLIP.CAPTURE.TITLE' | translate }}</h1>
    
    <div *ngIf="isLoading" class="ion-padding ion-text-center">
      <ion-spinner name="crescent"></ion-spinner>
    </div>

    <ng-template #joinACrew>
      <div class="ion-padding ion-text-center skinny-limit-width">
        <h1 class="ion-padding-top ion-text-center">{{ 'CLIP.CAPTURE.JOIN_A_CREW' | translate }}</h1>
  
        <p class="ion-text-center">
          {{ 'CLIP.CAPTURE.JOIN_A_CREW_BODY_1' | translate }}
          <!-- {{ 'CLIP.CAPTURE.JOIN_A_CREW_BODY' | translate:{ onlineTypes: onlineTypesAsText } }} -->
          <!-- Once you're on a Project Crew or have your own Project, you can upload videos or capture from 
          {{ onlineTypesAsText }}. -->
        </p>
  
        <!-- <div class="ion-padding ion-text-center action-buttons">
          <ion-button size="large" fill="outline" shape="round" color="primary" (click)="openProjectsPage()">
            {{ 'COMMON.DISCOVER' | translate }}&nbsp;{{ 'COMMON.PROJECTS' | translate }}
          </ion-button>
        </div> -->
      </div>
    </ng-template>

    <ng-container *ngIf="(isCaptureAllowed$ | async) === true || isLoading; else joinACrew">
      <ng-container *ngIf="!isCaptureEnabled">
        <p class="capture-message">
          {{ 'CLIP.CAPTURE.DISABLED_MESSAGE' | translate }}
        </p>
      </ng-container>
      <p>
        {{ 'CLIP.CAPTURE.SUBTITLE' | translate }}
        <br />
        {{ 'CLIP.CAPTURE.LIMITS' | translate }}
      </p>

      <app-form-select-myprojects
        *ngIf="showProjectSelect"
        [isCapture]="true"
        [defaultProjectId]="projectId"
        (selected)="projectChanged($event)"
      >
      </app-form-select-myprojects>
  
      <div class="capture-helpful-tips ion-padding-vertical ion-margin-top">
        <h2>{{ 'CLIP.CAPTURE.TIPS.HEADING' | translate }}</h2>
        <ul>
          <li>
            {{ 'CLIP.CAPTURE.TIPS.TIP_1' | translate }}
          </li>
          <li>
            {{ 'CLIP.CAPTURE.TIPS.TIP_2' | translate }}
          </li>
          <li>
            {{ 'CLIP.CAPTURE.TIPS.TIP_3' | translate }}
          </li>
          <li>
            {{ 'CLIP.CAPTURE.TIPS.TIP_4' | translate }}
          </li>
        </ul>
      </div>
  
      <div class="ion-text-center ion-padding">
        <app-terms-policy-modals
          [agreeTo] = "'POLICIES.AGREE_TO_TERMS_PREFIX' | translate"
        ></app-terms-policy-modals>
      </div>
      <div class="btn-wrapper ion-padding-top">
        <ion-button fill="clear" color="light" (click)="modal.dismiss()">
          {{ 'COMMON.CANCEL' | translate }}
        </ion-button>
        
        <ion-button #uploadBtn clipUpload size="large" color="primary" 
          [title]="'FILE_UPLOAD.UPLOAD_CLIPS' | translate"
          [projectId]="selectedProjectId$ | async"
          [userId]="userId$ | async"
          (uploadDone)="onFileUploadDone($event)"
          (fileUploadFinished)="onFileUploadFinished($event)"
          (fileUploadFailed)="onFileUploadFailed($event)"
          [disabled]="isLoading || (selectedProjectId$ | async)?.length < 1 || !isCaptureEnabled"
        >
          {{ 'FILE_UPLOAD.UPLOAD_CLIPS' | translate }}
          <ion-icon slot="end" name="cloud-upload-outline"></ion-icon>
        </ion-button>
      </div>
    </ng-container>

    <div *ngIf="showBetaInvite" class="ion-padding ion-text-center">
      <div class="announcement ion-padding">
        <h1>
          {{ 'CLIP.CAPTURE.BETA_HEADING' | translate }}
          <!-- We're actively developing new features for Capture! -->
          <!-- Be part of our community -->
        </h1>
        <p class="ion-text-center">
          {{ 'CLIP.CAPTURE.BETA_SUBHEAD' | translate }}
          <!-- Join us as we build a collaborative storytelling vision. -->
          <!-- Interested in helping us test new features? -->
        </p>
        <p class="ion-text-center">
          <ion-button
            size="small"
            class="ion-text-uppercase btn-link-inline"
            fill="clear"
            color="primary"
            [routerLink]="['/contact']"
            [queryParams]="{ topic: 'CLIP.CAPTURE.BETA_CONTACT_TOPIC' | translate }"
            routerDirection="forward"
            (click)="modal.dismiss()"
            >{{ 'CLIP.CAPTURE.BETA_CONTACT_US_BUTTON' | translate }}</ion-button
          >
          {{ 'CLIP.CAPTURE.BETA_CONTACT_US_TEXT' | translate }}
        </p>
      </div>
    </div>
  </div>

</fs-base-modal>
