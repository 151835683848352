/** @format */

import { Component, Input } from '@angular/core';
import { List } from '@store/selectors/lists.selectors';

@Component({
  selector: 'fs-list-title',
  templateUrl: './list-title.component.html',
  styleUrls: ['./list-title.component.scss'],
})
export class ListTitleComponent {
  /** Icon (optional) */
  @Input() icon?: string;
  /** Small text next to main title (optional) */
  @Input() small?: string;
  /** List to inspect for items and loadMore (optional) */
  @Input() list?: List;
}
