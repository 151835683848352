/** @format */

// import _memoize from 'lodash/memoize';
import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import {
  State,
  featureKey,
  getLoaded,
  getLoading,
  getIds,
  getStackId,
  getCurrentIndex,
} from '../reducers/mystack.reducers';
import { selectClipEntities } from '@store/selectors/clips.selectors';
import { Stack } from '@shared/models/stack.model';

const DEBUG_LOGS = false;

/**
 * MyStack State
 */
export const selectMyStackState = createFeatureSelector<State>(featureKey);

export const selectMyStackLoaded = createSelector(selectMyStackState, getLoaded);
export const selectMyStackLoading = createSelector(selectMyStackState, getLoading);
export const selectMyStackClipIds = createSelector(selectMyStackState, getIds);
export const selectMyStackStackId = createSelector(selectMyStackState, getStackId);
// const selectMyStackProjectId = createSelector(selectMyStackState, getProjectId); // unused
//  export const selectMyStackProjectIdStackId = createSelector(
//   selectMyStackStackId,
//   selectMyStackProjectId,
//   (stackId, projectId) => `${projectId}/${stackId}`,
// );
export const selectMyStackCurrentIndex = createSelector(selectMyStackState, getCurrentIndex);
export const selectMyStackCurrentClip = createSelector(
  selectClipEntities,
  selectMyStackClipIds,
  selectMyStackCurrentIndex,
  (entities, stackClipIds, index) => {
    DEBUG_LOGS && console.log(`[Store] selectMyStackCurrentClip`, { entities, stackClipIds, index });
    if (entities && stackClipIds && typeof index === 'number') {
      return entities[stackClipIds[index]];
    }
    // return ids.map(id => entities[id]).filter(Boolean);
  }
);

export const selectMyStackClips = createSelector(selectClipEntities, selectMyStackClipIds, (entities, ids) => {
  // console.log(`selectMyStackClips`, { ids, entities });
  if (!Array.isArray(ids) || ids.length < 1) {
    return [];
  }
  return ids.map((id) => entities[id]).filter(Boolean);
});

export const selectMyStack = createSelector(
  selectMyStackState,
  selectMyStackClips,
  (mystack, clips) =>
    new Stack({
      ...mystack,
      clips,
    })
);

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * MEMOIZATION
 * In contrast to a normal NgRx selector where the selector is shared across multiple components,
 * we now have a new instance of the selector every time the selector factory is invoked.
 * This has the effect that we lose the memoization benefits of the selector.
 *
 * For most cases this is fine and you won't notice the difference. But when the selector has to so expensive work,
 * you can add a memoization layer on top as a countermeasure.
 *
 * To accomplish this, we must bring our own memoization method to the table, for example lodash.memoize.
 * After this, we can simply wrap the selector inside of the memoize method.
 * Note that this is a simple example and that you probably don't need to memoize an entity lookup.
 *
 * By doing this, consuming the selector is no different than before.
 *
 * It's important to keep in mind that this builds up an in-memory cache,
 * so that's why it's better that the added entry is disposed of when it's possible.
 *
 * https://lodash.com/docs/4.17.15#memoize
 * Note: The cache is exposed as the cache property on the memoized function.
 * Its creation may be customized by replacing the _.memoize.Cache constructor
 * with one whose instances implement the Map
 * method interface of clear, delete, get, has, and set.
 *
 * @dev This will likely be the solution for Lists items
 *
 * Usage:
 * class MyComponent {
 *  list$ = this.store.select(lists.selectList('listId'));
 * }
 */
// export const selectList = _memoize((id: string) =>
//   createSelector(selectStackEntities, (entities) => entities[id])
// );

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * The currently recommended approach to this is using a factory function
 * ... but this is _not_ memoized
 */

/**
 * usage:
 * this.store.select(entityById(someID))
 */
// export const entityById = (id: string) => createSelector(
//   selectEntities,
//   (entities) => entities[id]
// );

// example:
// export const hasRight = (rightCode: RightCode) => createSelector(selectUser, (user) => {
//   return user?.rights?.indexOf(rightCode) !== -1;
// });
// // you can consume it as
// const canEditClient$ = this.store.select(hasRight(RIGHT_EDIT_CLIENT));
// https://github.com/ngrx/platform/issues/2980#issuecomment-819551245

// good example: https://github.com/ngrx/platform/issues/2980#issuecomment-856617283
