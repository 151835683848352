<div *ngIf="!currentClip" class="ion-text-center">
  <ion-spinner></ion-spinner>
</div>

<div *ngIf="currentClip" class="clip-item-wrap">
  <div class="clip-item">
    <div class="poster-wrap">
      <div class="poster" [style.background-image]="'url('+ poster +')'"></div>
    </div>
    <div class="title-wrap">
        <h4 class="ion-no-margin">{{currentClip.title}}</h4>
        <p class="ion-no-margin" class="path-deets">Current Clip</p>
    </div>
  </div>
</div>