
<ion-label *ngIf="heading" class="tag-heading">{{heading}}</ion-label>
<!-- https://github.com/Gbuomprisco/ngx-chips -->
<!-- events:
  (onBlur)="onInputBlurred($event)"
  (onFocus)="onInputFocused($event)"
  (onTextChange)="onTagTextChange($event)"
  [editable]='true' (onTagEdited)="onTagEdited($event)"
  -->
<!-- <tag-input 
    [inputId]="id"
    [modelAsStrings]="true"
    [inputClass]="'tagger-input'"
    [(ngModel)]='items'
    (onSelect)="onTagSelected($event)"
    (onRemove)="onTagRemoved($event)"
    (onAdd)="onTagAdded($event)"
    [placeholder]="placeholder"
    [secondaryPlaceholder]="initialPlaceholder"
    theme='dark' 
    [addOnBlur]='true'
    [addOnPaste]='false'
    [separatorKeys]="[',',';']"
  > -->
  <!-- TODO custom theme: https://github.com/gbuomprisco/ngx-chips/blob/master/docs/custom-themes.md -->
  <!-- <tag-input-dropdown [autocompleteItems]="autocompleteItems" [appendToBody]="appendToBody">
  </tag-input-dropdown>
</tag-input> -->

