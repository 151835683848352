/** @format */
/* eslint-disable @typescript-eslint/member-ordering */

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, delay, withLatestFrom } from 'rxjs/operators';
import { TokensApiService } from './api/tokens-api.service';
import { ToasterService } from '@services/toaster.service';
import { UserService } from '@services/user.service';
import { PROJECT_MEMBER_ROLE } from '@members/shared/project-member.model';
import { ProjectMemberService } from '@members/shared/services/project-member.service';
import {
  Token,
  TokenPayload,
  TokenActions,
  EventType,
  getReturnUrl,
  isTokenExpired,
} from '@tokens/shared/tokens.model';
import { SentryService } from '@services/analytics/sentry.service';
import { TranslateService } from '@ngx-translate/core';

/*
EXAMPLE tokenIds for testing
9807d1c0-3b92-4fc4-96c9-ba438a18670d
{
  createdAt: "2021-10-11T21:21:52.120Z"
  email: "jd@filmstacker.com"
  id: "4c857e68-5c36-44d4-9856-e76847f4751e"
  projectId: "filmstacker-dev"
  projectRole: "CREW"
  userId: "jd"
}
f01babe5-0b95-4950-8a33-c3d384675e2b
40519f3f-3be2-411a-95e0-c3be60a554ec
*/
const DEBUG_LOGS = false; // !environment.production;

/**
 * Clean the Token Value
 * only allow lower, upper, numbers and dashes
 * Based on https://ricardometring.com/javascript-replace-special-characters
 * @todo add test specs
 */
const cleanToken = (str: string = '') => {
  try {
    return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z-])/g, '');
  } catch (error) {
    console.warn(`[TokenService] cleanToken caught:`, error);
    return str;
  }
};

@Injectable({
  providedIn: 'root',
})
export class TokensService {
  get tokenId$(): Observable<string> {
    return this._tokenId$.asObservable();
  }
  get token$(): Observable<Token> {
    return this._token$.asObservable();
  }
  get payload$(): Observable<TokenPayload> {
    return this._payload$.asObservable();
  }

  private _tokenId$: BehaviorSubject<string> = new BehaviorSubject('');
  private _token$: BehaviorSubject<Token | undefined> = new BehaviorSubject(undefined);
  private _payload$: BehaviorSubject<TokenPayload | undefined> = new BehaviorSubject(undefined);
  private _loadingToken = false;

  constructor(
    private tokensApi: TokensApiService,
    private userService: UserService,
    private projectMemberService: ProjectMemberService,
    private toaster: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private sentryService: SentryService
  ) {
    this.tokenId$
      .pipe(
        map((id) => cleanToken(id)),
        filter((id) => !!id),
        distinctUntilChanged()
      )
      .subscribe(async (id) => {
        DEBUG_LOGS && console.log(`[TokensService] tokenId updated: '${id}'`);
        const data = await this.tokensApi.getToken(id);
        DEBUG_LOGS && console.log(`[TokensService] get token response:`, data);
        if (data && data.id) {
          this._token$.next(data);
          if (data.payload) {
            this._payload$.next({ ...data.payload, tokenId: data.id });
          }
        }
      });
  }

  setTokenId(val) {
    DEBUG_LOGS && console.log(`[TokensService] setTokenId: '${val}'`);
    try {
      const cleaned = cleanToken(val);
      if (cleaned) {
        this._tokenId$.next(cleaned);
      }
    } catch (error) {
      console.warn(`setToken caught:`, error);
    }
  }

  private setPayload(val) {
    DEBUG_LOGS && console.log(`[TokensService] setPayload:`, val);
    this._payload$.next(val);
  }

  /**
   * MVP-1173 Create PersistentToken
   */
  createPersistentToken({
    userId,
    userEmail,
    projectId,
    eventType,
  }: {
    userId: string;
    userEmail: string;
    projectId: string;
    eventType?: string;
  }): Promise<{ id: string; message: string }> {
    const payload = {
      message: this.translate.instant('EMAILS.ONBOARDING.INVITE_MESSAGE'),
      projectRoles: [
        {
          projectId,
          role: PROJECT_MEMBER_ROLE.CREW,
        },
      ],
      returnUrl: getReturnUrl(projectId),
    };
    const token: Token = new Token({
      // email,
      createdBy: userId,
      sentToEmail: userEmail,
      fromEmail: userEmail, // user that sent the token
      projectId,
      projectRole: PROJECT_MEMBER_ROLE.CREW,
      action: TokenActions.PersistentInvite,
      payload,
      persistToken: true,
      eventType: eventType || EventType.Projects,
    });
    // MVP-1183 initially, let's NOT expire these, awaiting MVP-1229
    // if (eventType !== EventType.Weddings) {
    //   token.expires = getExpires();
    // }

    return this.createToken(token);
  }

  /**
   * Token, TokenPayload
   */
  async createToken(token: Token): Promise<{ id: string; message: string }> {
    /*
      id: string; // PK
      createdAt: string;
      createdBy: string; // INDEX: user that sent the token
      sentToEmail?: string; //  index on email
      sentToUserId?: string; // keep the sentToUserId if we know it, but no index
      projectId: string; // keep this for easy scans
      action: TokenActions; // action that token represents
      payload: TokenPayload = {}; // payload will include what do when accepted
      accepted?: boolean; // we will delete the entry when accepted
      error: TokenErrors;
    */

    if (!token || !token.sentToEmail) {
      throw new Error('Missing To Email?');
    }
    token.isActive = true;

    return new Promise((resolve) => {
      // const userId = await lastValueFrom(this.userService.userId$); //rxjs7
      try {
        this.userService.userId$.pipe(take(1)).subscribe(async (userId) => {
          token.createdBy = userId;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const res: any = await this.tokensApi.createToken(token);
          DEBUG_LOGS && console.log(`[Tokens] createToken response:`, res);
          if (res?.data?.id) {
            return resolve({
              ...res.data,
              message: this.translate.instant('TOKENS.CREATED_SUCCESSFULLY'),
            });
          } else {
            console.warn(`[Tokens] createToken response missing data.id?:`, res);
            throw new Error(this.translate.instant('TOKENS.ERROR_ON_CREATE'));
          }
        });
      } catch (error) {
        console.warn(`[Tokens] createToken CAUGHT:`, error);
        throw error;
      }
    });
  }

  /**
   * @todo ? change to project-member.service.applyTokenRoleForCurrentUser
   * filtering out if the token is expired or not active..
   * note: no usages expect a response
   */
  applyTokenToCurrentUser({ tokenId }) {
    DEBUG_LOGS && console.log(`ApplyToken`, tokenId);

    this.token$
      .pipe(
        // need to wait for the token to be received
        delay(1500),
        take(1),
        // filtering out if the token is expired or not active
        filter((t) => t && t.id && !isTokenExpired(t)),
        withLatestFrom(this.userService.userId$)
      )
      .subscribe(async ([token, userId]) => {
        DEBUG_LOGS && console.log(`applyToken payload:`, { token, userId });
        try {
          /** persistToken logic now handled in api */
          const { projectId, projectRole } = token;
          const result = await this.projectMemberService.applyTokenRoleForCurrentUser(projectId, projectRole);

          // if this user was already a member, do something different
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (result && (result as any).message) {
            setTimeout(() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              this.toaster.present((result as any).message);
            }, 900);
            this._tokenId$.next('');
            this._token$.next({ ...token, success: true, tokenApplied: tokenId, returnUrl: token.payload.returnUrl });
            return;
          }

          DEBUG_LOGS && console.log(`[TokensService] applyTokenToCurrentUser applyTokenRoleForCurrentUser:`, result);
          // Toast success
          setTimeout(() => {
            this.toaster.present(this.translate.instant('TOKENS.APPLIED_SUCCESS'));
          }, 900);

          // apply this token, deal with persistance and deletion in the api
          this.tokensApi.applyToken(token);

          this._tokenId$.next('');
          this._token$.next({ ...token, success: true, tokenApplied: tokenId, returnUrl: token.payload.returnUrl });
        } catch (error) {
          console.warn(`[TokensService] caught error:`, error);
          this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
        }
      });
  }

  /**
   * List Tokens for ProjectId or by userId
   */
  listTokens({ projectId, userId }: { projectId?: string; userId?: string }) {
    return this.tokensApi.listTokens({ projectId, userId });
  }

  /**
   * Enable PersistentToken by tokenId
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enableToken({ tokenId, expires }: { tokenId: string; expires?: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.userId$.pipe(take(1)).subscribe(async (userId) => {
        try {
          const res = await this.tokensApi.enableToken({ tokenId, expires, userId });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  /**
   * Disable PersistentToken by tokenId
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disableToken({ tokenId, expires }: { tokenId: string; expires?: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.userId$.pipe(take(1)).subscribe(async (userId) => {
        try {
          const res = await this.tokensApi.disableToken({ tokenId, expires, userId });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  /**
   * Delete Token by ID
   * MemberInviteModal.confirmDeleteToken
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteToken(tokenId: string): Promise<any> {
    return this.tokensApi.deleteToken(tokenId);
  }

  /**
   * Update Token
   * Token, TokenPayload
   */
  async updateToken(token: Partial<Token>): Promise<{ id: string; message: string }> {
    /*
      id: string; // PK
      createdAt: string;
      createdBy: string; // INDEX: user that sent the token
      sentToEmail?: string; //  index on email
      sentToUserId?: string; // keep the sentToUserId if we know it, but no index
      projectId: string; // keep this for easy scans
      action: TokenActions; // action that token represents
      payload: TokenPayload = {}; // payload will include what do when accepted
      accepted?: boolean; // we will delete the entry when accepted
      eventType?: EventTypes?;
      error: TokenErrors;
      isActive?: boolean;
    */

    if (!token || !token.id) {
      throw new Error('Missing Id?');
    }

    return new Promise((resolve, reject) => {
      this.userService.userId$.pipe(take(1)).subscribe(async (userId) => {
        token.updatedBy = userId;
        try {
          // updateToken
          DEBUG_LOGS && console.log(`UpdateToken`, token);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const res: any = await this.tokensApi.update(token);
          DEBUG_LOGS && console.log(`[Tokens] updateToken response:`, res);
          if (res && res.data && res.data.id) {
            return resolve({
              // ...res,
              id: res.data.id,
              message: `Token updated successfully.`,
            });
          } else {
            console.warn(`[Tokens] updateToken response missing data.id?:`, res);
            throw new Error('Token was not updated successfully, please try again.');
          }
        } catch (error) {
          console.warn(`[Tokens] updateToken CAUGHT:`, error);
          reject(error);
        }
      });
    });
  }

  // /**
  //  * create method in TokenService to generate payload for invites
  //  */
  // generatePayloadForCrewInvites(crewInvites: ProjectRolePayload[] = []) {
  //   const payload = {};
  //   crewInvites.forEach(({ email, projectId, role = PROJECT_MEMBER_ROLE.CREW  }) => {
  //     // payload
  //   })
  // }
}
