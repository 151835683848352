<!-- @format -->

<ng-container *ngIf="copyResult">
  <div class="success-icon ion-text-center ion-padding-horizontal">
    <ion-icon size="large" name="thumbs-up-sharp"></ion-icon>
  </div>
  <div class="success-message ion-text-center ion-padding-horizontal">
    {{ 'SOCIAL.COPY_TO_CLIPBOARD_SUCCEED' | translate }}
  </div>
  <div class="success-message success-url ion-text-center ion-padding-horizontal">
    {{ copyResult.shareUrl }}
  </div>
</ng-container>
