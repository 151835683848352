/**
 * Billing and Subscription Model
 * @format
 */

import {
  ChargebeePlanId,
  ChargebeeSubscription,
  ChargebeeSubscriptionItemPriceId,
  // ChargebeePlanType,
  PlanPrice,
  PlanPriceTier,
  Plan,
  // AddOn,
  // SubscriptionStatus,
  SubscriptionLevel,
  // BillingSubscription,
  // getProSubscriptions,
  // getEventSubscriptions,
  isSubscriptionActive,
} from './chargebee.model';

export {
  ChargebeePlanId,
  ChargebeeSubscriptionItemPriceId,
  ChargebeePlanType,
  ChargebeeCustomer,
  ChargebeeEndpointResult,
  ChargebeeSubscription,
  BillingSubscription,
  Plan,
  AddOn,
  PlanPrice,
  PlanPriceTier,
  SubscriptionStatus,
  SubscriptionLevel,
  getProSubscriptions,
  getEventSubscriptions,
  getEventSubscriptionDetail,
  isSubscriptionActive,
} from './chargebee.model';

/** tiers */
export const DEFAULT_PRICE_RANGE_TIER = 2;

/**
 * root labels for translations in subscriptionLabels component to display in UI
 * allow project type to determine wording
 */
export const SUB_LEVEL_TRANSLATE_KEYS = {
  [SubscriptionLevel.Loading]: {
    name: 'BILLING.LEVELS.LOADING.NAME',
    label: 'BILLING.LEVELS.LOADING.LABEL',
  },
  [SubscriptionLevel.Unknown]: {
    name: 'BILLING.LEVELS.UNKNOWN.NAME',
    label: 'BILLING.LEVELS.UNKNOWN.LABEL',
  },
  [SubscriptionLevel.Unsubscribed]: {
    name: 'BILLING.LEVELS.FREE.NAME',
    label: 'BILLING.LEVELS.FREE.LABEL',
  },
  [SubscriptionLevel.Trial]: {
    name: 'BILLING.LEVELS.TRIAL.NAME',
    label: 'BILLING.LEVELS.TRIAL.LABEL',
  },
  [SubscriptionLevel.Pro]: {
    name: 'BILLING.LEVELS.PRO.NAME',
    label: 'BILLING.LEVELS.PRO.LABEL',
  },

  [SubscriptionLevel.Event]: {
    name: 'BILLING.LEVELS.EVENT.NAME',
    label: 'BILLING.LEVELS.EVENT.LABEL',
  },
  [SubscriptionLevel.Wedding]: {
    name: 'BILLING.LEVELS.WEDDING.NAME',
    label: 'BILLING.LEVELS.WEDDING.LABEL',
  },
  /**
   * FREE PRO EVENT - do not show crew/guest in status well
   * originally showed "Crew" , but was confusing so we changed to FREE
   */
  [SubscriptionLevel.Crew]: {
    name: 'BILLING.LEVELS.FREE.NAME',
    // label: 'BILLING.LEVELS.FREE.LABEL'
    // name: 'BILLING.LEVELS.CREW.NAME',
    label: 'BILLING.LEVELS.CREW.LABEL',
  },
  /** @todo implement Pro+ */
  [SubscriptionLevel.Sponsored]: {
    name: 'BILLING.LEVELS.SPONSORED.NAME',
    label: 'BILLING.LEVELS.SPONSORED.LABEL',
  },
};

/**
 * @deprecated UNUSED...
 * An attempt at translation.. use SUB_LEVEL_TRANSLATE_KEYS instead
 */
export class SubscriptionLevelMgr {
  id: ChargebeePlanId;
  level: SubscriptionLevel = SubscriptionLevel.Unsubscribed;

  get label() {
    switch (this.level) {
      case SubscriptionLevel.Trial:
      case SubscriptionLevel.Pro:
      case SubscriptionLevel.Event:
      case SubscriptionLevel.Wedding:
      case SubscriptionLevel.Crew:
      case SubscriptionLevel.Sponsored:
      case SubscriptionLevel.Unsubscribed:
        return this._translated[this.level];

      default:
        console.warn('Unkown Level', this.level);
        return 'Unkown Level';
    }
  }

  private _translated = {
    [SubscriptionLevel.Unsubscribed]: {
      name: 'FREE',
      label: 'Your subscription is not active',
    },
    [SubscriptionLevel.Trial]: {
      name: 'TRIAL',
      label: 'Active Trial subscription',
    },
    [SubscriptionLevel.Pro]: {
      name: 'PRO',
      label: 'Active Pro subscription',
    },
    [SubscriptionLevel.Event]: {
      name: 'EVENT',
      label: 'Active Event subscription',
    },
    [SubscriptionLevel.Wedding]: {
      name: 'WEDDING',
      label: 'Active Wedding subscription',
    },
    // allow project type to determine wording, in essence we are all Crew if not the subscribe;
    [SubscriptionLevel.Crew]: {
      name: 'CREW',
      label: 'Crew on Project Event subscription',
    },
    /** @todo implement Pro+ */
    [SubscriptionLevel.Sponsored]: {
      name: 'SPONSORED',
      label: 'Sponsored Project subscription',
    },
  };

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
    // get our TRANSLATIONS...?
  }

  setTranslations(translations = {}) {
    for (const [key, val] of Object.entries(translations)) {
      this._translated[key] = val;
    }
  }
}

const getPrices = (plan: Plan): PlanPrice[] => plan?.prices ?? [];
export const getPricesWithTiers = (plan: Plan): PlanPrice[] =>
  getPrices(plan).filter((p) => p && p.tiers && p.tiers.length > 0);

/**
 * tiers = [
 *   {start: 1, end: 25, price: 20000}
 *   {start: 26, end: 50, price: 35000}
 *   {start: 51, end: 100, price: 50000}
 *   {start: 101, end: undefined, price: 200000}
 * ]
 */
export const getPriceFromTiers = (numMembers: number, tiers: PlanPriceTier[]): number => {
  const tier = tiers.find((t) => numMembers >= t.start && numMembers <= t.end);
  return tier && tier.price; //? tier.price / 100 : 0;
};

/**
 * this could use a refactor, but for now...
 *
 * @todo how to handle the crew & guests?
 */
export const getTopSubscriptionInfo = (
  subs: ChargebeeSubscription[]
): {
  level: SubscriptionLevel;
  proSubscriptionId: string;
  eventSubscriptionIds: string[];
} => {
  let topPlan = -1; // arbitrary weight, to get the top plan
  let level = SubscriptionLevel.Unsubscribed;
  let proSubscriptionId = '';
  const eventSubscriptionIds = [];
  subs.forEach((sub) => {
    if (!isSubscriptionActive(sub)) {
      return; // not active, ignore it
    }
    (sub?.subscription_items ?? []).forEach((item) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { item_price_id: priceId = '' } = item;
      const subId = sub?.id;

      // console.log('getTopSubscriptionInfo', { priceId, item, sub });

      switch (priceId) {
        case ChargebeeSubscriptionItemPriceId.ProMonthly:
        case ChargebeeSubscriptionItemPriceId.ProYearly: {
          if (topPlan < 3) {
            topPlan = 3;
            level = SubscriptionLevel.Pro;
          }
          proSubscriptionId = subId;
          break;
        }
        // case ChargebeeSubscriptionItemPriceId.Festivals: etc..
        case ChargebeeSubscriptionItemPriceId.Weddings: {
          if (topPlan < 2) {
            topPlan = 2;
            level = SubscriptionLevel.Wedding;
          }
          eventSubscriptionIds.push(subId);
          break;
        }
        case ChargebeeSubscriptionItemPriceId.TeamsMonthly:
        case ChargebeeSubscriptionItemPriceId.TeamsYearly: {
          if (topPlan < 1) {
            topPlan = 1;
            level = SubscriptionLevel.Event;
          }
          eventSubscriptionIds.push(subId);
          break;
        }
        default: {
          /**
           * @todo sentry.captureMessage()
           */
          console.warn(`[BillingStore] ChargebeePlanId Missing '${priceId}'`, subs);
        }
      }
    });
  });

  return {
    level,
    proSubscriptionId,
    eventSubscriptionIds,
  };
};

export const enum PORTAL_SECTIONS {
  ACCOUNT_DETAILS = 'ACCOUNT_DETAILS', // = 'account_details',
  ADDRESS = 'ADDRESS', // = 'portal_address',
  ADD_PAYMENT_SOURCE = 'ADD_PAYMENT_SOURCE', // = 'portal_add_payment_method',
  BILLING_HISTORY = 'BILLING_HISTORY', // = 'portal_billing_history',
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION = 'CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION', // = 'portal_choose_payment_method',
  EDIT_ACCOUNT_DETAILS = 'EDIT_ACCOUNT_DETAILS', // = 'portal_edit_account',
  EDIT_BILLING_ADDRESS = 'EDIT_BILLING_ADDRESS', // = 'portal_edit_billing_address',
  EDIT_PAYMENT_SOURCE = 'EDIT_PAYMENT_SOURCE', // = 'portal_edit_payment_method',
  EDIT_SHIPPING_ADDRESS = 'EDIT_SHIPPING_ADDRESS', // = 'portal_edit_shipping_address',
  EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION', // = 'edit_subscription',
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS = 'EDIT_SUBSCRIPTION_CUSTOM_FIELDS', // = 'portal_edit_subscription_cf',
  PAYMENT_SOURCES = 'PAYMENT_SOURCES', // = 'portal_payment_methods',
  SUBSCRIPTION_CANCELLATION = 'SUBSCRIPTION_CANCELLATION', // = 'sub_cancel',
  SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS', // = 'sub_details',
  VIEW_PAYMENT_SOURCE = 'VIEW_PAYMENT_SOURCE', // = 'portal_view_payment_method',
  VIEW_SCHEDULED_CHANGES = 'VIEW_SCHEDULED_CHANGES', // = 'scheduled_changes',
}

export interface ChargebeeCustomerApiInput {
  id: string;
  email: string;
  phone?: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  locale?: string;
  billing_address?: {
    first_name?: string;
    last_name?: string;
    line1?: string;
    city?: string;
    state?: string;
    state_code?: string;
    zip?: string;
    country?: string;
  };
}
