/** @format */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  /** manages which logo */
  @Input() theme: 'light' | 'dark' = 'dark';
  
  copyrightYear = new Date().getFullYear();
}
