/** @format */

import { Component, Input } from '@angular/core';
import { OnboardingTypes, getTranslatePath, TRANSLATE_ROOT } from '../../shared/onboarding.model';

@Component({
  selector: 'app-onboarding-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
})
export class OnboardingHowItWorksComponent {
  @Input()
  set routeId(value) {
    this._routeId = value || OnboardingTypes.None;
    this.routeIdUpdated();
  }
  get routeId(): OnboardingTypes {
    return this._routeId;
  }

  onboardingId = '';
  translatePath = TRANSLATE_ROOT;

  backgroundUrl = '/assets/images/events/crowd-filming@2.jpg';
  backgroundPosition = 'center';

  private _routeId = OnboardingTypes.None;

  routeIdUpdated() {
    this.translatePath = getTranslatePath(this.routeId);
    switch (this.routeId) {
      case OnboardingTypes.Weddings: {
        this.backgroundUrl = '/assets/images/events/weddings-bride@2.jpg';
        this.backgroundPosition = 'bottom right';
        break;
      }
      case OnboardingTypes.Projects:
      case OnboardingTypes.None:
      default: {
        this.backgroundUrl = '/assets/images/events/crowd-filming@2.jpg';
        this.backgroundPosition = 'center';
      }
    }
  }
}
