/** @format */

// TODO: fix: projects.forEach((proj, i, arr) => arr[i] = updateProjectMembers(proj));

import { Injectable } from '@angular/core';
import { Observable, throwError, from } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { PROJECT_MEMBER_ROLE, Project } from '@projects/shared/project.model';
import { UpdateParam } from './api-types';
import { ALL_PROJECT_FIELDS } from './api.models';
import { SentryService } from '../services/analytics/sentry.service';
import { FilterEntity } from '@store/selectors/viewstate.selectors';

const DEBUG_LOGS = false;
const PAGE = '[ProjectsApiService]';

/**
 * Update Project Members
 * remove the "items" from the api response, reduce to array
 */
export const updateProjectMembers = (project: Partial<Project>) => {
  // console.log(`updateProjectmembers`, project);
  if (!project) return project;
  project.members = project.members && Array.isArray(project.members['items']) ? project.members['items'] : [];
  if (project.owner) {
    project.members.unshift({
      userId: project.owner,
      username: project.owner,
      role: PROJECT_MEMBER_ROLE.OWNER,
      isActive: true,
    });
  }
  return project;
};

/**
 * filter out the API Responses that are undefined
 * only return when found (not undefined)
// @todo VERIFY: or return null when not in api
 * @todo why is this not working?
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const filterApiResponse = () => filter((value) => typeof value !== 'undefined');

/**
 * Project API interactions
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  /**
   * The AppSync types for the update command
   */
  types = {
    id: 'ID!',
    title: 'String',
    owner: 'String',
    mission: 'String',
    description: 'String',
    featured: 'Int',
    views: 'Int',
    votes: 'Int',
    likes: 'Int',
    topics: '[String!]',
    tags: '[String!]',
    emotions: '[String!]',
    template: 'String',
    streams: '[String!]',
    hero: 'String',
    logoUrl: 'String',
    created: 'AWSDateTime',
    configJsonUrl: 'AWSURL',
    channel: 'String',
    shareUrl: 'String',
    privacy: 'PROJECT_PRIVACY',
    // if Project.isModerated
    isModerated: 'Boolean',
    config: 'AWSJSON',
    allowPublicPublish: 'Boolean',
    updatedBy: 'String',
    eventType: 'String',
    eventDate: 'String',
    eventConfig: 'AWSJSON',
    eventIsActive: 'Boolean',
    eventUpdatedBy: 'String',
    subscriptionId: 'String',
    subscriptionBy: 'String',
    subscriptionLevel: 'String',
    subscriptionStatus: 'Int',
    subscriptionMinutes: 'Int',
    subscriptionExpires: 'String',
    subscriptionRenews: 'String',
    private: 'Boolean', // deprecated, to be removed from model
  };

  constructor(private sentryService: SentryService) {}

  /**
   * createFilterString for graphQL
   * @returns string
   * 
   * FilterEntity:
      type?: 'FEATURED' | 'RECENT';
      q?: string;
      tags?: string; // e.g. “wildlife,sea,land” concatenated by “,”
      users?: string; // e.g. “simwicki,jd” concatenated by “,”
   */
  createFilterString(filters: FilterEntity): string {
    /**
     * DynamoDB values are case-sensitive in DB.
     * The Backend is conactenating the desired fields into 'searchField',
     * so we can simply query CONTAINS in searchField for q in LOWERCASE
     */
    const searchField = 'searchField';
    let result = '';
    if (filters) {
      if (filters.q) {
        // only alphanumeric
        const q = filters.q.toLowerCase();
        result += `${searchField}:{ contains: "${q}" }`;
      }
      if (filters.tags) {
        console.warn(`TODO: createFilterString HANDLE tags`, filters);
      }
      if (filters.users) {
        console.warn(`TODO: createFilterString HANDLE users`, filters);
      }
    }
    DEBUG_LOGS && console.log(`${PAGE} createFilterString`, { result, filters });
    return result;
  }

  /**
   * get the summary view, to await getting the detail view with crew, etc
   * filter: FEATURED, NOT PRIVACY.PRIVATE
   *
   * @todo get summary view of projects, check in store if key field is missing to get the full entity
   */
  listProjectsPreview({
    nextToken,
    filters,
    limit = 10,
    filterFeatured = true,
  }: {
    limit?: number;
    nextToken?: string;
    filterFeatured?: boolean;
    filters?: FilterEntity;
  }): Observable<{ projects: Project[]; nextToken: string }> {
    const endpoint = 'listProjects';
    const featuredFilter = filterFeatured ? `featured:{ ge: 1 }` : '';
    const filterString = this.createFilterString(filters);
    nextToken = nextToken ? `nextToken: "${nextToken}"` : '';

    const query = `query listProjectsPreview {
      listProjects(
        filter: {
          privacy:{
            ne: "PRIVATE"
          }
          ${featuredFilter}
          ${filterString}
        },
        limit: ${limit}
        ${nextToken}
      ) {
        items {
          id
          title
          owner
          mission
          featured
          privacy
          isModerated
          allowPublicPublish
          config
          views
          votes
          topics
          hero
          logoUrl
          created
          shareUrl
          template
          eventIsActive
          subscriptionId
          subscriptionStatus
          subscriptionLevel
          members {
            items {
              userId
              username
              role
              isActive
            }
          }
        }
        nextToken
      }
    }`;

    return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        const data = res.data[endpoint];
        const projects: Project[] = data?.items ?? [];
        // for (let proj of projects) { // this does not update the items..
        //   proj = updateProjectMembers(proj);
        // }
        projects.forEach((proj, i, arr) => (arr[i] = updateProjectMembers(proj) as Project));
        DEBUG_LOGS && console.log(`${PAGE} listProjects:`, { projects });
        return {
          projects,
          nextToken: res.data[endpoint].nextToken || null,
        };
      })
    );
  }

  getAllProjects(limit: number = 10, nextToken: string): Observable<{ projects: Project[]; nextToken: string }> {
    nextToken = nextToken ? `nextToken: "${nextToken}"` : '';

    const query = `query allProjects {
      listProjects(limit: ${limit} ${nextToken}) {
        items {
          ${ALL_PROJECT_FIELDS}
        }
        nextToken
      }
    }`;

    // Simple query
    return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        const data = res.data['listProjects'];
        const projects: Project[] = data?.items ?? [];
        // for (let proj of projects) { // this does not update the items..
        //   proj = updateProjectMembers(proj);
        // }
        projects.forEach((proj, i, arr) => (arr[i] = updateProjectMembers(proj) as Project));
        DEBUG_LOGS && console.log(`${PAGE} listProjects:`, { projects });
        return {
          projects,
          nextToken: data.nextToken,
        };
      })
    );
  }

  getFeaturedProjects(
    limit: number = 20,
    nextToken: string,
    filters: FilterEntity
  ): Observable<{ projects: Project[]; nextToken: string }> {
    const endpoint = 'getFeaturedProjects';
    nextToken = nextToken ? `nextToken: "${nextToken}"` : '';
    let filterStr = this.createFilterString(filters);
    if (filterStr) {
      filterStr = `filter: { ${filterStr} }`;
    }

    const query = `query GetFeaturedProjects {
      ${endpoint}(
        limit: ${limit} 
        ${nextToken}
        ${filterStr}
      ) {
        items {
          ${ALL_PROJECT_FIELDS}
        }
        nextToken
      }
    }`;

    // Simple query
    return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        const data = res.data[endpoint];
        const projects: Project[] = data?.items ?? [];
        // for (let proj of projects) { // this does not update the items..
        //   proj = updateProjectMembers(proj);
        // }
        projects.forEach((proj, i, arr) => (arr[i] = updateProjectMembers(proj) as Project));
        DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, { projects });
        return {
          projects,
          nextToken: data.nextToken,
        };
      })
    );
  }

  getProject(id: string): Observable<Project> {
    if (!id) {
      return throwError(() => new Error('No ID Provided'));
    }
    const endpoint = 'getProject';

    const query = `query GetProject($id: ID!) {
      getProject(id: $id) {
        ${ALL_PROJECT_FIELDS}
      }
    }`;

    // Query using a parameter
    return from(API.graphql(graphqlOperation(query, { id })) as Promise<GraphQLResult>).pipe(
      map((res) => res.data[endpoint]),
      // filter((val) => val), // remove null not found - no, we want to know if it fails
      map((proj) => {
        if (!proj || !proj.id) {
          throw new Error('Project not found');
        }
        DEBUG_LOGS && console.log(`${PAGE} api.getProject (${id})`, proj);
        return updateProjectMembers(proj) as Project;
      })
    );
  }

  getProjectPreview(id: string): Observable<Project> {
    if (!id) {
      return throwError(() => new Error('No ID Provided'));
    }

    const query = `query GetProject($id: ID!) {
      getProject(id: $id) {
        id
        title
        owner
        mission
        privacy
        isModerated
        allowPublicPublish
        config
        views
        votes
        topics
        hero
        logoUrl
        created
        template
        members {
          items {
            userId
            username
            role
            isActive
          }
        }
      }
    }`;

    // Query using a parameter
    return from(API.graphql(graphqlOperation(query, { id })) as Promise<GraphQLResult>).pipe(
      map((res) => res.data['getProject'] as Project),
      // filter(val => val), // remove null not found... but then it does not call next()
      map((proj) => {
        DEBUG_LOGS && console.log(`${PAGE} getProject:`, { proj });
        return updateProjectMembers(proj) as Project;
      })
    );
  }

  /**
   * @todo verify that they can do this action (paywall)
   * Authenticated only
   * @param id
   */
  createProject(project: Project): Promise<Project> {
    DEBUG_LOGS && console.log(`${PAGE} createProject`, project);

    if (project.config && typeof project.config !== 'string') {
      project.config = JSON.stringify(project.config);
    }

    const params = project;

    const action = `mutation CreateProject(
      $id: ID!
      $allowPublicPublish: Boolean
      $channel: String
      $config: AWSJSON
      $configJsonUrl: AWSURL
      $created: AWSDateTime
      $description: String
      $emotions: [String]
      $featured: Int
      $hero: String
      $logoUrl: String
      $mission: String
      $owner: String
      $privacy: PROJECT_PRIVACY
      $isModerated: Boolean
      $shareUrl: String
      $streams: [String]
      $template: String
      $title: String
      $topics: [String]
      $views: Int
      $votes: Int
      $updatedBy: String
    ) {
      createProject(
        id: $id
        allowPublicPublish: $allowPublicPublish
        channel: $channel
        config: $config
        configJsonUrl: $configJsonUrl
        created: $created
        description: $description
        emotions: $emotions
        featured: $featured
        hero: $hero
        logoUrl: $logoUrl
        mission: $mission
        owner: $owner
        privacy: $privacy
        isModerated: $isModerated
        shareUrl: $shareUrl
        streams: $streams
        template: $template
        title: $title
        topics: $topics
        views: $views
        votes: $votes
        updatedBy: $updatedBy
      ) {
        ${ALL_PROJECT_FIELDS}
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} createProject res:`, res);
      return updateProjectMembers(res.data['createProject']) as Project;
    });
  }

  /**
   * Delete Project
   * @param id
   * @param owner
   */
  deleteProject(id: string, owner: string = ''): Promise<Partial<Project>> {
    DEBUG_LOGS && console.log(`${PAGE} deleteProject: ${id}`);
    if (!id) {
      return Promise.reject('Missing required projectId');
    }
    const endpoint = 'deleteProject';
    const params = {
      id,
      owner,
    };

    const action = `mutation deleteProject(
      $id: ID!
      $owner: String!
    ) {
      deleteProject(
        id: $id
        owner: $owner
      ) {
        id
        owner
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} deleteProject res:`, res);
      return res.data[endpoint];
    });
  }

  /**
   * Just Public Projects
   */
  queryPublicProjectsByOwner(
    userId: string,
    nextToken: string = null
  ): Observable<{ projects: Project[]; nextToken: string }> {
    if (!userId) {
      return throwError(() => new Error('No UserId'));
    }

    nextToken = nextToken ? `nextToken: "${nextToken}"` : '';

    const query = `query getPublicProjectsByOwner {
      queryProjectsByOwner(
        owner: "${userId}" 
        filter: {
          privacy:{
            eq: "PUBLIC"
          }
        }
        ${nextToken}
      ) {
        items {
          ${ALL_PROJECT_FIELDS}
        }
      }
    }`;

    return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        const data = res.data['queryProjectsByOwner'];
        const projects: Project[] = data?.items ?? [];
        // for (let proj of projects) { // this does not update the items..
        //   proj = updateProjectMembers(proj);
        // }
        projects.forEach((proj, i, arr) => (arr[i] = updateProjectMembers(proj) as Project));
        DEBUG_LOGS && console.log(`${PAGE} queryProjectsByOwner res:`, projects);
        return {
          projects,
          nextToken: data.nextToken,
        };
      })
    );
  }

  /**
   * Include Private and Unlisted
   */
  queryProjectsByOwner(
    userId: string,
    nextToken: string = null
  ): Observable<{ projects: Project[]; nextToken: string }> {
    if (!userId) {
      return throwError(() => new Error('No UserId'));
    }

    nextToken = nextToken ? `nextToken: "${nextToken}"` : '';

    const query = `query getProjectsByOwner {
      queryProjectsByOwner(owner: "${userId}" ${nextToken}) {
        items {
          ${ALL_PROJECT_FIELDS}
        }
      }
    }`;

    return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} queryProjectsByOwner res:`, res);
        const data = res.data['queryProjectsByOwner'];
        const projects: Project[] = data?.items ?? [];
        // for (let proj of projects) { // this does not update the items..
        //   proj = updateProjectMembers(proj);
        // }
        projects.forEach((proj, i, arr) => (arr[i] = updateProjectMembers(proj) as Project));

        return {
          projects,
          nextToken: data.nextToken,
        };
      })
    );
  }

  /**
   * Update Project Hero Image URL
   * @param project.id
   * @param hero string
   * @returns the changed props + id
   */
  updateProjectHero(id: string, hero: string, userId: string): Promise<Partial<Project>> {
    DEBUG_LOGS && console.log(`${PAGE} updateProjectHero`, { id, hero });
    if (!id || !hero) {
      return Promise.reject('Missing required args');
    }
    const params = {
      id,
      hero,
      updatedBy: userId,
    };

    const action = `mutation UpdateProjectHero(
      $id: ID!
      $hero: String
      $updatedBy: String
    ) {
      updateProject(
        id: $id
        hero: $hero
        updatedBy: $updatedBy
      ) {
        id
        hero
        updatedBy
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} UpdateProjectHero res:`, res);
      return res.data['updateProject'];
    });
  }

  /**
   * Update a Project
   * @param project
   * @param updates UpdateParam[]
   * @returns the changed props + id
   */
  updateProject(project: Project, updates: UpdateParam[]): Promise<Project> {
    DEBUG_LOGS && console.log(`${PAGE} updateProject`, { project, updates });

    if (!project || !project.id) {
      return Promise.reject('Missing required project.id');
    }

    const params = {
      id: project.id,
      // updatedAt: Utils.getDateTimeString(), // TODO: Update API for this
    };
    updates.forEach((update) => {
      // [ProjectStoreEffects] setSubscriptionEvent caught eventConfig invalid value
      if (update.prop === 'eventConfig') {
        try {
          if (update.value && typeof update.value === 'string') {
            params[update.prop] = update.value;
          } else if (update.value && Object.keys(update.value).length > 0) {
            params[update.prop] = JSON.stringify(update.value);
          }
        } catch (error) {
          console.warn(error);
        }
      } else if (update.value || typeof update.value === 'number' || typeof update.value === 'boolean') {
        // it could be number == zero
        // dynamically create the update params for string query
        params[update.prop] = update.value;
      }
    });

    if (params['owner'] && params['owner'] !== project.owner) {
      console.warn(`${PAGE} DEV: What happens if owner is modified, since it's an index?`);
    }

    let definition = '',
      mapping = '';
    Object.keys(params).forEach((key: string) => {
      if (this.types[key]) {
        definition += `$${key}: ${this.types[key]} `;
        mapping += `${key}: $${key} `;
      }
    });
    const action = `mutation UpdateProject(${definition}) {
      updateProject(${mapping}) {
        ${ALL_PROJECT_FIELDS}
      }
    }`;
    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} updateProject res:`, res);
      return updateProjectMembers(res.data['updateProject']) as Project;
    });
  }

  /**
   * Batch GetProjects
   *
   * REFACTORING NOTES:
   * from project-crew-api getCrewForUser(queryProjectCrewsByUserId)
   * - projects.effects
   *   - private getUserProjectsCrewAndOwner (needs refactored)
   *     - private loadUserProjects.map => projectApi.getProject
   *       - loadByUserId Projects.Effects
   *   - loadProjectById$
   *   - selectProjectById$:
   *
   * - project.service
   *   - getUserProjectCrews @deprecated
   *     - isUserOnProjectCrewOrOwner @deprecated
   *   - getUserProjects
   *     - loadMyProjects() dispatch loadMine ACTION @deprecated
   */
  batchGetProjects(ids: string[]): Observable<Project[]> {
    const DEBUG_QUERY = false;
    const endpoint = 'batchGetProjects';
    const params = {
      ids,
    };
    if (DEBUG_QUERY) {
      // 2 work, 3 null
      params.ids = ['filmstacker-0', 'filmstacker', 'filmstacker-1', 'filmstacker-dev', 'filmstacker-2'];
    }

    const query = `
      query batchGetProjects (
        $ids: [String]!
      ) {
        batchGetProjects(
          ids: $ids
        ) {
          items {
            ${ALL_PROJECT_FIELDS}
          }
          unprocessedKeys
        }
      }

    `;
    // console.log(`${PAGE} batchGetProjects params:`, params);

    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} batchGetProjects res:`, res);
        if (res.data[endpoint].errors && res.data[endpoint].errors.length > 0) {
          console.warn(`${PAGE} batchGetProjects error:`, res.data[endpoint].errors[0]);
          this.sentryService.captureError(res.data[endpoint].errors);
        }

        const data = res.data[endpoint];
        const projects: Project[] = data?.items ?? [];
        // for (let proj of projects) { // this does not update the items..
        //   proj = updateProjectMembers(proj);
        // }
        projects.forEach((proj, i, arr) => (arr[i] = updateProjectMembers(proj) as Project));
        return projects;
      })
    );
  }
}
