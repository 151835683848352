/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { ClipsCoreService } from '@app/core/services/clips.service';
import { Clip } from '@shared/models/clip.model';

@Component({
  selector: 'app-explore-current-clip',
  templateUrl: './explore-current-clip.component.html',
  styleUrls: ['./explore-current-clip.component.scss'],
})
export class ExploreCurrentClipComponent implements OnInit {
  @Input() currentClip: Clip;
  poster: string;
  // isCollapsedDetails: boolean = true;

  constructor(private clipService: ClipsCoreService) {}

  ngOnInit() {
    this.poster = this.clipService.getPoster(this.currentClip);
  }
}
