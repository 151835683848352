<fs-base-modal #modal mode="light">
  <div class="plans container" style="height: 100%;">
    <h1>{{ 'ONBOARDING.SIGNUP_SUBTITLE' | translate }}</h1>
    <p>{{ 'ONBOARDING.COMMON.PLANS_DESC'  | translate }}</p>

    <div class="plans__list ion-padding-top">
      <fs-plan-item *ngIf="proPlan" [planItem]="proPlan" [isActivePro]="isActivePro" (navSignup)="navSignup($event)" [class.is-pro]="isActivePro" class="plans__item plans__item__pro"></fs-plan-item>
      <fs-plan-item *ngIf="weddingsPlan" [planItem]="weddingsPlan" (navSignup)="navSignup($event)" class="plans__item featured"></fs-plan-item>
      <fs-plan-item *ngIf="teamsPlan" [planItem]="teamsPlan" (navSignup)="navSignup($event)" class="plans__item"></fs-plan-item>
    </div>
       
    <p class="ion-text-wrap ion-padding-bottom ion-margin-bottom" style="line-height: 3em;">
      <ion-button class="btn-link-inline with-icon ion-text-wrap" fill="clear" href="https://filmstacker.com/plans-pricing" target="_filmstacker">
        {{ 'AUTH.PLANS.LEARN_MORE' | translate }}
        <ion-icon slot="end" name="open-outline"></ion-icon>
      </ion-button>
      <span class="float-right">
        <fs-button buttonStyle="clear" (click)="modal.dismiss()">{{ 'COMMON.CANCEL' | translate }}</fs-button>
      </span>
    </p>  
  </div>
</fs-base-modal>
