<!-- <ion-content forceOverscroll="false"> -->
  <ion-menu-toggle [menu]="menuId">
    <div class="menu-wrap">
      <div class="top-image-bg d-flex ion-justify-content-center ion-align-items-center">
        <ion-button fill="clear" title="Close" class="close-btn">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
        <ion-item lines="none" *ngIf="(loggedIn$ | async) === true" button detail="false" (click)="navStudio()">
          <ion-avatar *ngIf="avatar$ | async as avatarUrl" slot="start" draggable="false">
            <img [src]="avatarUrl" draggable="false" />
          </ion-avatar>
          <ion-label>{{ username$ | async }}</ion-label>
          <fs-subscription-mini-status-edit 
            class="menu-wrap__status"
            [showPlan]="enableBilling"
            (click)="navEditProfile($event)"
          ></fs-subscription-mini-status-edit>
          <!-- <ion-button *ngIf="enableBilling" fill="clear" color="light" class="menu-wrap__status" (click)="navEditProfile($event)">
            <ion-note color="light" class="well">
              {{ subscriptionLevel$ | async }}
            </ion-note>
            <ion-icon slot="end" name="settings-outline"></ion-icon>
          </ion-button> -->
        </ion-item>
      </div>
      <h3 class="projects-heading">
        {{ 'COMMON.PROJECTS' | translate }}
        <span *ngIf="projectsSort === projectsSortType.ActiveEvent"> - {{ 'PROJECT.SORT.ACTIVE_EVENTS' | translate }}</span>
      </h3>
      <ion-toolbar *ngIf="showSortToolbar && (recentProjects$ | async)?.length > 0" color="light" class="projects-menu-toolbar">
        <ion-segment mode="ios" [value]="projectsSort" (ionChange)="sortSegmentChanged($event)" class="user-menu-projects">
          <ion-segment-button 
            [value]="projectsSortType.Recent" 
            [title]="'PROJECT.SORT.VIEW_RECENT' | translate"
            (click)="stopPropogation($event)">
              <ion-label>{{ 'COMMON.RECENT' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button 
            [value]="projectsSortType.Alpha" 
            [title]="'PROJECT.SORT.VIEW_ALL' | translate"
            (click)="stopPropogation($event)">
              <ion-label>{{ 'COMMON.ALL' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button *ngIf="(projectsWithEvents$ | async)?.length > 0" 
            [value]="projectsSortType.ActiveEvent" 
            [title]="'PROJECT.SORT.ACTIVE_EVENT' | translate"
            (click)="stopPropogation($event)">
              <ion-icon name="today-outline" class="no-user"></ion-icon>
              <!-- <ion-label>{{ 'PROJECT.SORT.ACTIVE_EVENT' | translate }}</ion-label> -->
          </ion-segment-button>
          <ion-segment-button *ngIf="enableArchivedProjects && (archivedProjects$ | async)?.length > 0" 
            [value]="projectsSortType.Archived" 
            [title]="'PROJECT.SORT.ARCHIVED' | translate"
            (click)="stopPropogation($event)">
              <ion-label>{{ 'PROJECT.SORT.ARCHIVED' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
        <!-- removed buttons 2023-08-08 MVP-1272 -->
        <!-- <ion-button slot="end" fill="clear" size="small" color="medium" (click)="toggleSort($event)">
          {{ projectsSortText }}
        </ion-button>
        <ion-button slot="end" fill="outline" size="small" color="medium" (click)="toggleSort($event)">
          {{ projectsSortLabel }}
          <ion-icon name="funnel-outline" slot="end"></ion-icon>
        </ion-button> -->
      </ion-toolbar>

      <!-- Project ng-template to share.. -->
      <ng-template #projectItemTpl let-project="project">
        <!-- can upload clips:
          <ion-icon name="cloud-upload-outline"></ion-icon> -->
        <ion-button *ngIf="project?.id else noId" [routerLink]="[routeProjectDetail, project.id]"
          routerLinkActive="active" class="ion-text-wrap ion-text-left">
          {{ project.title }}
        </ion-button>
        <ng-template #noId>
          {{ project.title || '?' }}
        </ng-template>
        <div *ngIf="project?.isEventActive" slot="end" class="active-event-icon" title="Active Event">
          <ion-icon name="today-outline" class="no-user"></ion-icon>
        </div>
        <ion-button *ngIf="project?.canEdit" slot="end" (click)="openProjectMenu($event, project)" fill="clear"
          color="medium" class="projects-action">
          <ion-icon name="ellipsis-vertical-outline" slot="icon-only"></ion-icon>
        </ion-button>
        <ng-content></ng-content>
      </ng-template> 

      <!-- Recent Projects -->
      <div *ngIf="recentProjects$ | async as recentProjects" class="list-wrap">
        <ion-list *ngIf="projectsSort === projectsSortType.Recent" lines="none" class="projects-list recent-projects">
          <ion-item *ngFor="let recentProject of recentProjects" class="my-projects-menu">
            <ng-container *ngTemplateOutlet="projectItemTpl; context: { project: recentProject }">
              <ion-button *ngIf="recentProject?.id" slot="end" 
                class="projects-action"
                color="medium"
                fill="clear"
                (click)="removeRecent($event, recentProject)">
                <ion-icon name="close" slot="icon-only"></ion-icon>
              </ion-button>
            </ng-container>
          </ion-item>
        </ion-list>
      </div>

      <!-- Projects with Active Events -->
      <div *ngIf="projectsWithEvents$ | async as eventProjects" class="list-wrap">
        <ion-list *ngIf="projectsSort === projectsSortType.ActiveEvent" lines="none" class="projects-list my-projects">
          <ion-item *ngFor="let project of eventProjects" class="my-projects-menu">
            <ng-container *ngTemplateOutlet="projectItemTpl; context: { project }"></ng-container>
          </ion-item>
        </ion-list>
      </div>
      <!-- ALL Projects -->
      <div *ngIf="projects$ | async as projects" class="list-wrap">
        <ng-container *ngIf="projects?.length > 0 else noProjects">
          <ion-list *ngIf="projectsSort === projectsSortType.Alpha" lines="none" class="projects-list my-projects">
            <ion-item *ngFor="let project of projects" class="my-projects-menu">
              <ng-container *ngTemplateOutlet="projectItemTpl; context: { project }"></ng-container>
            </ion-item>
          </ion-list>
        </ng-container>
      </div>
      <!-- Archived Projects -->
      <div *ngIf="enableArchivedProjects && archivedProjects$ | async as archivedProjects" class="list-wrap">
        <ion-list *ngIf="projectsSort === projectsSortType.Archived" lines="none" class="projects-list archived-projects">
          <ion-item *ngFor="let project of archivedProjects" class="my-projects-menu">
            <ng-container *ngTemplateOutlet="projectItemTpl; context: { project }"></ng-container>
          </ion-item>
        </ion-list>
      </div>

      <ng-template #noProjects>
        <div class="no-projects">
          <ion-button fill="clear" (click)="createProject()">
            {{ 'STUDIO.FAB.CREATE_PROJECT' | translate }}
          </ion-button>
        </div>
      </ng-template>

      <ion-list lines="none" class="menu-list">

        <ng-container *ngIf="(loggedIn$ | async) === true; else notLoggedIn">
          <ion-item>
            <ion-button [routerLink]="routeStudio" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              {{ 'COMMON.MY_STUDIO' | translate }}
            </ion-button>
            <ion-button slot="end" [routerLink]="routeStudioSettings" routerLinkActive="active">
              {{ 'COMMON.SETTINGS' | translate }}
              <ion-icon slot="end" name="settings-outline"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-item>
            <ion-button [routerLink]="routeCommunity" routerLinkActive="active">
              {{ 'COMMON.COMMUNITY' | translate }}
            </ion-button>
          </ion-item>
        </ng-container>

        <ng-template #notLoggedIn>
          <ion-item>
            <ion-button routerLink="/signup" routerLinkActive="active">
              {{ 'AUTH.SIGNUP.ACTION' | translate }}
            </ion-button>
          </ion-item>
          <ion-item>
            <ion-button routerLink="/login" routerLinkActive="active">
              <ion-icon slot="start" name="person-circle-outline"></ion-icon>
              {{ 'AUTH.LOGIN.ACTION' | translate }}
            </ion-button>
          </ion-item>
        </ng-template>

        <ion-item>
          <ion-button href="https://filmstacker.com/support" target="_filmstackerSupport">
            {{ 'COMMON.SUPPORT' | translate }}
            <ion-icon slot="end" name="open-outline"></ion-icon>
          </ion-button>
        </ion-item>
        <!-- 2023-08-15 removed home from menu to give projects more space..
        <ion-item>
          <ion-button [routerLink]="routeHome" routerLinkActive="active">
            {{ 'HOME.TITLE' | translate }}
          </ion-button>
        </ion-item> -->
        <ion-item *ngIf="(loggedIn$ | async) === true">
          <ion-button (click)="logout()">
            {{ 'AUTH.LOGOUT.ACTION' | translate }}
          </ion-button>
        </ion-item>
      </ion-list>

      
      <div class="menu-footer">
        <!-- Note that we removed this, so user-menu calls billingService.initUser() -->
        <!-- <div *ngIf="enableBilling" class="menu-subscription-wrap">
          <fs-subscription-status class="top-image-subscription-status"></fs-subscription-status>
        </div> -->
        <app-version-reload></app-version-reload>
      </div>
    </div>
  </ion-menu-toggle>
<!-- </ion-content> -->
