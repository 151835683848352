<fs-base-modal [title]="''" #modal>
  <div class="ion-padding skinny-limit-width">
    <h2>{{ 'STACK.DRAFT_INVITE' | translate }}</h2>
    <p class="dark-contrast ion-padding-bottom">{{ 'STACK.DRAFT_INVITE_BODY' | translate }}</p>
    <fs-form-textarea
      class="ion-padding-vertical"
      [autoGrow]="true"
      [rows]="3"
      [label]="'STACK.DRAFT_INVITE_MESSAGE_LABEL' | translate"
      [hint]="'STACK.DRAFT_INVITE_MESSAGE_HINT' | translate"
      [control]="descControl | formControlCast"
      [debounce]="600"
    ></fs-form-textarea>
    <div class="actions ion-text-center ion-padding">
      <fs-button size="large" (click)="invite($event)">
        {{ 'STACK.DRAFT_INVITE_ACTION' | translate }}
      </fs-button>
      <fs-button buttonStyle="clear" (click)="cancel($event)">
        {{ 'COMMON.CANCEL' | translate }}
      </fs-button>
    </div>
  </div>
</fs-base-modal>