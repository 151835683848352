/**
 * FSR Core API
 * Credits: Filmstacker LLC
 *
 * @author: jdstackr
 * @format
 */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Observable, throwError, from, merge, combineLatest } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { API, graphqlOperation } from 'aws-amplify';
import {
  ALL_CLIP_FIELDS,
  ALL_PROJECT_FIELDS,
  ALL_PROJECT_MEMBER_FIELDS,
  ALL_STACK_FIELDS,
  PUBLIC_USER_DATA_FIELDS,
  SUB_CLIP_FIELDS,
} from './api.models';
// import { Observable as ZenObservable } from './../../../../node_modules/zen-observable-ts';
import { gqlToRx } from './api-amplify-zen';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { subUpdate as clipSubUpdate } from '@store/actions/clips.actions';
import { deleteStack, subUpdate as stackSubUpdate } from '@store/actions/stacks.actions';
import { addMine, subUpdate as projectSubUpdate } from '@store/actions/projects.actions';
import { subUpdate as memberSubUpdate } from '@store/actions/members.actions';
import { subUpdate as userSubUpdate } from '@store/actions/user.actions';
import { SentryService } from '@services/analytics/sentry.service';
import { UserService } from '@services/user.service';
import { Stack as StackModel, STACK_PRIVACY } from '@shared/models/stack.model';
import { Utils } from '@shared/utils';
import { selectMyProjectsIds } from '@store/selectors/projects.selectors';
import { Project as ProjectModel } from '@projects/shared/project.model';
import { Clip as ClipModel } from '@shared/models/clip.model';
import { ProjectMember as ProjectMemberModel } from '@members/shared/project-member.model';
import { environment } from 'src/environments/environment';
import { User as UserModel } from '@shared/models/user.model';
import { updateProjectMembers } from './projects-api.service';
import { ApiErrorCodes, convertClipResponse } from './clips-api.service';
import { selectRecentProjectsIds } from '@store/selectors/environ.selectors';
import { GraphQLResult } from './api-types';

/**
 * handle an open browser that stays active on network overnight?
 * @todo watch the events and reopen on event if not open
 */
const MAX_NUM_RECONNECTIONS = environment.production ? 20 : 50;

const DEBUG_LOGS = !environment.production;
const DEBUG_LOGS_GQL = false;
const PAGE = '[CoreApi]';

enum SubType {
  Clip,
  Stack,
  Project,
  ProjectCrew,
  User,
}

const TIMEOUT_DISCONNECT_MESSAGE = 'Timeout disconnect';
const CONNECTION_CLOSED_MESSAGE = 'Connection closed';
const PERMISSION_DENIED_MESSAGE = 'Permission Denied';
const CONNECTION_AUTH_DENIED_MESSAGE = 'Connection failed: com.amazonaws.deepdish.graphql.auth#BadRequestException';

/**
 * Core API interactions
 */
@Injectable({
  providedIn: 'root',
})
export class CoreLogicApiService {
  /** toggle if subscriptions are open */
  isWatching = false;

  /**
   * Subscribe to Updates
   * https://docs.aws.amazon.com/appsync/latest/devguide/aws-appsync-real-time-data.html
   * 
   * @param filters if props set will filter results
   * @returns Observable 
   * 
   * @note All subscription parameters must exist as a field in the returning type. 
   * This means that the type of parameter must also match the type of the field in the returning object.
   * https://stackoverflow.com/questions/64308456/how-to-make-subscription-with-arguments-correctly-in-graphql
   * 
   * MVP-1040 RealTimeData
   * 	watchStackData(projectId: ID, stackId: String, userId: String): Stack
        @aws_subscribe(mutations: ["createStack", "updateStack", "incrementStackAdmin", "incrementStackPublic", "deleteStack"])
      watchClipData(projectId: ID, id: String, userId: String): Clip
        @aws_subscribe(mutations: ["createClip", "updateClip", "deleteClip", "incrementAdminClip", "incrementPublicClip"])
      watchProjectData(projectId: ID, userId: String): Project
        @aws_subscribe(mutations: ["createProject", "updateProject", "deleteProject"])
      watchCrewData(projectId: ID, userId: String): ProjectCrew
        @aws_subscribe(mutations: ["createProjectCrew", "updateProjectCrew", "deleteProjectCrew"])
      watchUserData(userId: String): User
        @aws_subscribe(mutations: ["createUser", "updateUser", "incrementPublicUser"])
      # ---------
   */
  watchAllStacksData$: Observable<StackModel> = this.subscribeDataUpdated({
    endpoint: 'watchStackData',
    subType: SubType.Stack,
  }) as Observable<StackModel>;

  watchAllData$: Observable<ClipModel | StackModel | ProjectModel | ProjectMemberModel | UserModel> = merge(
    this.subscribeDataUpdated({ endpoint: 'watchStackData', subType: SubType.Stack }),
    this.subscribeDataUpdated({ endpoint: 'watchClipData', subType: SubType.Clip }),
    this.subscribeDataUpdated({ endpoint: 'watchProjectData', subType: SubType.Project }),
    this.subscribeDataUpdated({ endpoint: 'watchCrewData', subType: SubType.ProjectCrew }),
    this.subscribeDataUpdated({ endpoint: 'watchUserData', subType: SubType.User })
  );

  private isLoadingWatch = false;
  private numReconnects = 0;

  constructor(private userService: UserService, private sentryService: SentryService, private store: Store<State>) {}

  /**
   * On Socket Closed
   * In onError resubscribe to the query. 
   * There's a potential concern that we may miss data that comes in while reconnecting...
   
   * https://github.com/aws-amplify/amplify-js/issues/1283
   * onError: (error)=>{
   *   if(error.errorMessage.includes('Socket')){
   *     setTimeout(()=>{this.milkUpdateSubscribeFunction(subscribeToMore, retrying * 2)}, retrying)
   *   }
   * }
   * 
   * Saw a note that:
   * "For users that are using the GraphQL API without DataStore, a disruption in the network connection will close the subscription"
   * ...seemes important
   * 
   * 
   * @todo idea: how to we track connection restored in the browser?
   * 
   * so what I did as a workaround was let the React Native component listen for network connectivity change. 
   * If the network changed from offline to wifi, I subscribe again. 
   * Previous subscription channels with disconnected socket under the hood were closed.
   * https://github.com/aws-amplify/amplify-js/issues/2610#issuecomment-471816736
   * 
   * Most of the services I've seen use the following practice: 
   * with an increasing to a certain value timeout, trying to contact the server. 
   * When the maximum timeout value is reached, an indicator with a manual recconect button appears 
   * which indicates in how many time the next attempt of reconnect will occur
   * https://stackoverflow.com/questions/44756154/progressive-web-app-how-to-detect-and-handle-when-connection-is-up-again
   * 
   * interesting UI idea: https://justmarkup.com/articles/2016-08-18-indicating-offline/
   */
  /* https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
   from : https://stackoverflow.com/questions/44756154/progressive-web-app-how-to-detect-and-handle-when-connection-is-up-again
      // Test this by running the code snippet below and then
      // use the "Offline" checkbox in DevTools Network panel

      window.addEventListener('online', handleConnection);
      window.addEventListener('offline', handleConnection);

      function handleConnection() {
        if (navigator.onLine) {
          isReachable(getServerUrl()).then(function(online) {
            if (online) {
              // handle online status
              console.log('online');
            } else {
              console.log('no connectivity');
            }
          });
        } else {
          // handle offline status
          console.log('offline');
        }
      }

      function isReachable(url) {
        /**
         * Note: fetch() still "succeeds" for 404s on subdirectories,
         * which is ok when only testing for domain reachability.
         *
         * Example:
         *   https://google.com/noexist does not throw
         *   https://noexist.com/noexist does throw
         *
         return fetch(url, { method: 'HEAD', mode: 'no-cors' })
        .then(function(resp) {
          return resp && (resp.ok || resp.type === 'opaque');
        })
        .catch(function(err) {
          console.warn('[conn test failure]:', err);
        });
      }

      function getServerUrl() {
      return document.getElementById('serverUrl').value || window.location.origin;
      }
  */

  /**
   * @v1 wip
   * MVP-1040 Real Time Data
   */
  watchAllData() {
    if (this.isLoadingWatch) {
      !environment.production && console.log(`[RTD] Already Loading GQL Subscriptions.`);
      return;
    }
    if (this.isWatching) {
      !environment.production && console.log(`[RTD] Already Watching GQL Subscriptions.`);
      return;
    }
    !environment.production && console.log(`[RTD] Opening GQL Subscriptions...`);
    this.isLoadingWatch = true;
    const sub$ = combineLatest([
      this.userService.userId$,
      this.store.select(selectMyProjectsIds),
      this.store.select(selectRecentProjectsIds),
      this.watchAllData$,
    ]).subscribe(
      ([userId, myProjectIds = [], recentProjectIds = [], res]) => {
        if (!res) {
          console.warn(`[RTD] no result?`, res);
          // sentry?
          return;
        }
        if (!userId) {
          !environment.production && console.log(`[RTD] No GQL Subscriptions for Unauth.`);
          // this.isWatching = false; // do we need to watch Auth to try again?
          return;
        }
        this.isWatching = true; // maintaing that it's still true, probably not necesary..

        // __typename exists
        const typename = res['__typename']; //(res.__typename || '').slice();// create a copy
        delete res['__typename'];
        // remove the null values (unchanged)
        for (const key in res) {
          if (res[key] === null) {
            delete res[key];
          }
        }
        // DEBUG_LOGS && console.log(`[RTD] watchAllData$ [${typename}] next:`, res);
        let item;
        const isProjectIncluded = (projectId) =>
          myProjectIds.includes(projectId) || recentProjectIds.includes(projectId);
        switch (typename) {
          case 'Stack':
            item = res as StackModel;
            if (Object.keys(item).length < 3) {
              DEBUG_LOGS &&
                console.log(`RTD[${typename}] Only 2 keys likely means deleted...`, {
                  projectIncluded: isProjectIncluded(item.projectId),
                  item,
                });
              if (item.stackId && item.projectId) {
                this.store.dispatch(deleteStack({ stack: item }));
              }
            } else {
              DEBUG_LOGS &&
                console.log(`RTD[${typename}] checking for subUpdate:`, {
                  itemUserId: item.userId,
                  itemPrivacy: item.privacy,
                  myProjectIds,
                  projectIncluded: isProjectIncluded(item.projectId),
                  item,
                });
              // filters - it's mine or a recent or member project
              // removed this as it always fails since we only get it when it changes: item.privacy === STACK_PRIVACY.PUBLIC
              // or PUBLIC for Discover
              if (
                item.userId === userId ||
                isProjectIncluded(item.projectId) ||
                item.privacy === STACK_PRIVACY.PUBLIC
              ) {
                this.store.dispatch(stackSubUpdate({ stack: item, userId }));
              }
            }
            break;
          case 'Clip':
            // handle the clip subscription status
            // ignoring clips we don't care about -> don't send action
            // on api.subscription, it will have
            item = convertClipResponse(res as ClipModel);
            if (item.error === ApiErrorCodes.ClipNoSources) {
              // // check if transcoding message
              // "{"finishTime":"2023-07-15T18:00:23.000Z","percentComplete":100,"status":"COMPLETE","updatedAt":"2023-07-15T18:00:25.003Z"}"
              // { percentComplete: num, status: 'TRANSCODING' | 'FINISHING, but not complete...' } = JSON.parse(clip.hlsMeta)
              // - type !== complete ?=> isHlsComplete({ ...item, hlsMeta })
              switch (item?.hlsMeta?.status) {
                case 'TRANSCODING':
                case 'Finishing':
                  DEBUG_LOGS &&
                    console.log(
                      `transcoding status: ${item?.hlsMeta?.status} %: ${item?.hlsMeta?.percentComplete} (${item?.userId} @ ${item?.projectId}/${item?.clipId})`,
                      item
                    );
                  break;
                default:
                  console.warn(item?.hlsMeta);
              }
              // break; // we do need it to propogate the action...
            } else {
              DEBUG_LOGS &&
                console.log(`RTD[${typename}] checking for subUpdate:`, {
                  itemUserId: item.userId,
                  itemPrivacy: item.privacy,
                  projectIncluded: isProjectIncluded(item.projectId),
                  item,
                });
            }

            if (item.userId === userId || isProjectIncluded(item.projectId) || item.privacy === STACK_PRIVACY.PUBLIC) {
              this.store.dispatch(clipSubUpdate({ clip: item, userId }));
            }
            break;
          case 'Project':
            item = updateProjectMembers(res as ProjectModel);
            DEBUG_LOGS &&
              console.log(`RTD[${typename}] checking for subUpdate:`, {
                item,
                myProjectIds,
                projectIncluded: isProjectIncluded(item.id),
              });
            if (isProjectIncluded(item.id)) {
              this.store.dispatch(
                projectSubUpdate({
                  project: {
                    ...item,
                    config: Utils.tryParseJSON(item.config),
                  },
                  userId,
                })
              );
            }
            break;
          // case 'ProjectMember': // the DB is named ProjectCrew
          case 'ProjectCrew':
            item = res as ProjectMemberModel;
            // here the object is a memberProjects update
            DEBUG_LOGS &&
              console.log(`RTD[${typename}] checking for subUpdate:`, {
                itemUserId: item.userId,
                projectIncluded: isProjectIncluded(item.projectId),
                item,
              });
            if (item.userId === userId || isProjectIncluded(item.projectId)) {
              this.store.dispatch(memberSubUpdate({ memberProject: item, userId }));
            }
            if (item.projectId && item.userId === userId) {
              // add this to mine
              this.store.dispatch(addMine({ ids: [item.projectId] }));
            }
            break;
          case 'User':
            item = res as UserModel;
            // if this person is a member, update public data, & update me
            // check if it's more than just userId
            if (Object.keys(item).length > 1) {
              if (Object.keys(item).length === 2 && Object.keys(item).find((prop) => prop === 'updatedAt')) {
                DEBUG_LOGS && console.log(`RTD[${typename}] '${item.userId}' heartbeat...`);
              } else {
                DEBUG_LOGS &&
                  console.log(`RTD[${typename}] subUpdate User if me, else members if exist in store`, {
                    itemUserId: item.userId,
                    item,
                  });
                this.store.dispatch(userSubUpdate({ user: item }));
              }
            }
            break;
          case undefined:
            /** @todo  this is getting called again after the update with undefined typename... why? */
            break;
          default:
            // this might happen on projectIds change and other observables in combineLatest..
            console.log(`[RTD] watchAllData$ UNHANDLED ? [${typename}] next:`, res);
            break;
        }
        this.isLoadingWatch = false;
      },
      (err) => {
        this.isWatching = false;
        this.isLoadingWatch = false;
        if (err === CONNECTION_CLOSED_MESSAGE) {
          try {
            // console.log({ err, numReconnects: this.numReconnects, MAX_NUM_RECONNECTIONS, test: this.numReconnects < MAX_NUM_RECONNECTIONS});

            // In this case 10^n was used as the timeout where nn is the retry count.
            // In other words the first 5 retries will have the following delays: [1ms,10ms,100ms,1s,10s].
            // const delay = (retryCount) => new Promise(resolve => setTimeout(resolve, 10 ** retryCount));
            // no, instead just wait that many seconds? 1000 * retryCount , or just double it?
            const delay = (retryCount) => new Promise((resolve) => setTimeout(resolve, 200 * retryCount));

            if (this.numReconnects < MAX_NUM_RECONNECTIONS) {
              console.log(`RTD[${new Date().toISOString()}][${this.numReconnects}] ${err} - reopening socket..`);
              sub$.unsubscribe();
              // Exponential Backoff
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              delay(this.numReconnects).then((_) => {
                this.numReconnects++;
                this.watchAllData();
              });
              return;
            } else {
              console.warn(
                `RTD[${new Date().toISOString()}] ${err}. Reconnect retries expired. Please reload the page to refresh.`
              );
            }
          } catch (e) {
            console.warn(`[RTD] watchAllData$ Reconnect retries expired, last caught:`, e);
          }
        } else if (err === PERMISSION_DENIED_MESSAGE) {
          console.log(`[RTD] watchAllData$ Auth Error:`, err);
        } else if (err === TIMEOUT_DISCONNECT_MESSAGE) {
          console.log(`[RTD] watchAllData$ Disconnect:`, err);
        } else {
          console.warn(`[RTD] watchAllData$ ERROR:`, err);
          this.sentryService.captureError(err);
        }

        if (err.errorMessage && err.errorMessage.includes('Socket')) {
          console.warn(`RTD RECONNECT?`);
          // setTimeout(()=>{this.milkUpdateSubscribeFunction(subscribeToMore, retrying * 2)}, retrying)
        }

        // this overloaded Sentry - needs to be reduced to only critical/unknown errors
        // this.sentryService.captureError(err);
      },
      () => {
        // DEBUG_LOGS &&
        console.log(`[RTD] watchAllData$ => Complete.`);
      }
    );
  }

  /**
   * V2 returns multiple pathways (Stack[]), V1 returned a Stack
   * @param action
   * @param params
   * @param envData
   */
  doExplore(action: string, params: object, envData: object): Observable<{ items: StackModel[]; nextToken: string }> {
    // console.log(`${PAGE} doExplore envData:`, envData);
    const gqlParams = {
      action,
      params: JSON.stringify(params),
      envData: JSON.stringify(envData),
    };
    const query = `query coreStacksAction($action: String!, $params: AWSJSON, $envData: AWSJSON) {
      coreStacksAction(
        action: $action
        params: $params
        envData: $envData
      ) {
        items {
	        ${ALL_STACK_FIELDS}
        }
        nextToken
      }
    }`;
    // what happens if you remove the items and fields?

    // DEBUG_LOGS && console.log(`${PAGE} doExplore`, { query, gqlParams });
    return from(API.graphql(graphqlOperation(query, gqlParams)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} doExplore res:`, res);
        return res.data.coreStacksAction;
      })
      // map((items) => ({
      //   ...(res as any).data.coreStacksAction,
      //   envData,
      //   params,
      // })),
    );
  }

  /*
   * Test Connection
   */
  testMessage() {
    console.log(`${PAGE} sending testMessage...`);
    return this.getDifference(70, 1); //What is the difference between 5 and 3?
  }
  getDifference(nMinuend: number, nSubtrahend: number): Observable<GraphQLResult> {
    const query = `query testCoreApi {
      testCoreApi(action:"getDifference",nMinuend:${nMinuend},nSubtrahend:${nSubtrahend}) 
    }`;

    return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        console.log(`${PAGE} getDifference res:`, res);
        return res.data.testCoreApi;
      }),
      catchError((err) => {
        console.log(`${PAGE} getDifference error:`, err);
        return throwError(() => new Error('getDifference not successful.'));
      })
    );
  }

  /**
   * @deprecated - needs refactored..
   */
  searchStart(envData: object): Observable<GraphQLResult> {
    // console.log(`${PAGE} searchStart envData:`, envData);
    const params = {
      envData: JSON.stringify(envData),
    };
    const query = `query coreSearchStart($envData: AWSJSON) {
      coreSearchStart(
        envData: $envData
      ) {
        items {
	        ${ALL_STACK_FIELDS}
        }
      }
    }`;

    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        console.log(`${PAGE} coreSearchStart res:`, res);
        return {
          ...res.data.coreSearchStart,
          envData,
        };
      })
    );
  }

  /**
   * @returns Observable<Clip | Stack | Project | ProjectCrew | User>
   */
  private subscribeDataUpdated({
    subType,
    endpoint,
    projectId,
    userId,
  }: {
    subType: SubType;
    endpoint: string;
    projectId?: string;
    userId?: string;
  }): Observable<ClipModel | StackModel | ProjectModel | ProjectMemberModel | UserModel> {
    // DEBUG_LOGS && console.log(`${PAGE} subscribeDataUpdated...`, { projectId, userId })
    const inputs: { projectId?: string; userId?: string } = {};
    let inputDef = '';
    let inputVals = '';
    if (projectId) {
      inputs.projectId = projectId;
      inputDef += '$projectId: ID ';
      inputVals += 'projectId: $projectId ';
    }
    if (userId) {
      inputs.userId = userId;
      inputDef += '$userId: String ';
      inputVals += 'userId: $userId ';
    }
    // remove parens
    inputDef = inputDef ? `(${inputDef})` : '';
    inputVals = inputVals ? `(${inputVals})` : '';

    const returnFields = (() => {
      switch (subType) {
        case SubType.Clip:
          return SUB_CLIP_FIELDS;
        case SubType.Stack:
          return ALL_STACK_FIELDS;
        case SubType.Project:
          return ALL_PROJECT_FIELDS;
        case SubType.ProjectCrew:
          return ALL_PROJECT_MEMBER_FIELDS;
        case SubType.User:
          return PUBLIC_USER_DATA_FIELDS;
        default:
          console.warn(`[ SubApi] We should NOT have hit this point..`);
          return 'projectId userId id stackId';
      }
    })();

    const query = `subscription ${endpoint} ${inputDef} {
      ${endpoint} ${inputVals} {
        __typename
        ${returnFields}
      }
    }`;

    /*
      Successful response :
      {
        "data": {
          "endpoint": {
            "__typename": "filmstacker-dev",...
          }
        }
      }
    */

    DEBUG_LOGS_GQL && console.log(`${PAGE} calling gql ${endpoint}`, { inputs, query });

    // need Observable from rxjs not zen-observable-ts
    const apiObs = gqlToRx(API.graphql(graphqlOperation(query, inputs)));
    return apiObs.pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(({ provider, value }) => {
        if (!value || !value.data[endpoint]) {
          console.warn(`${PAGE} ${endpoint} pipe missing value.data.${endpoint}? value:`, value);
          throw new Error(`API Response missing value.data.${endpoint}`);
        }
        this.isWatching = true;
        DEBUG_LOGS_GQL && console.log(`${PAGE} ${endpoint} pipe:`, value.data[endpoint]);
        return value.data[endpoint];
      }),
      catchError((e) => {
        const { error: { errors = [] } = {} } = e;
        this.isWatching = false;
        const msgs = errors.map((err) => (err || {}).message);
        if (msgs.length > 0) {
          let errorMsg = '';
          for (const msg of msgs) {
            if (msg === CONNECTION_CLOSED_MESSAGE) {
              DEBUG_LOGS && console.log(`[subData] ${msg} - re-open connection!`);
              errorMsg = msg;
            } else if (msg === CONNECTION_AUTH_DENIED_MESSAGE || msg.indexOf('UnauthorizedException') > -1) {
              // "Connection failed: {"errors":[{"errorType":"UnauthorizedException","message":"Permission denied"}]}"
              console.log(`[subData] Connection failed: Permission denied - Please login first to get real-time data.`);
              errorMsg = PERMISSION_DENIED_MESSAGE;
            } else {
              console.warn(`${PAGE} subscribeDataUpdated pipe caught MESSAGE`, { msg, e });
              errorMsg = msg;
            }
          }
          return throwError(errorMsg);
        } else {
          console.warn(`${PAGE} subscribeDataUpdated pipe caught NO MSGS`, e);
        }
        return throwError(e);
      })
    );

    // return the observable and allow the caller to handle subscription directly
    // .subscribe({
    //   next: (res) => {
    //     DEBUG_LOGS_GQL && console.log(`***${PAGE} ${endpoint} next:`, res);
    //   },
    //   error: (err) => {
    //     console.warn(`${PAGE} ${endpoint}: `, err);
    //   },
    //   complete: () => {
    //     DEBUG_LOGS_GQL && console.log(`${PAGE} ${endpoint} subClipUpdates => Complete`);
    //   }
    // });
  }

  /*
    UNUSED
  */

  /**
   * @deprecated UNUSED
   * dev - returned clips , but now Stacks from V1
   * @param action
   * @param params
   * @param envData
   */
  private coreClipsAction(action: string, params: object, envData: object): Observable<GraphQLResult> {
    // console.log(`${PAGE} searchStart envData:`, envData);
    const gqlParams = {
      action,
      params: JSON.stringify(params),
      envData: JSON.stringify(envData),
    };
    const query = `query coreClipsAction($action: String!, $params: AWSJSON, $envData: AWSJSON) {
      coreClipsAction(
        action: $action
        params: $params
        envData: $envData
      ) {
        clips {
	        ${ALL_CLIP_FIELDS}
        }
      }
    }`;
    // what happens if you remove the items and fields?

    return from(API.graphql(graphqlOperation(query, gqlParams)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        console.log(`${PAGE} coreClipsAction res:`, res);
        return {
          ...res.data.coreClipsAction,
          envData,
          params,
        };
      })
    );
  }
}
