<div
  class="carousel carousel--with-navigation"
  [class.carousel--has-vertical-text]="heading"
>
  <div class="navigation">
    <ion-icon
      class="navigation__item navigation__item--left"
      [class.navigation__item--inactive]="
        (scrolledLeft$ | async) || !items || items.length === 0
      "
      (click)="navigate('left')"
      name="chevron-back-outline"
    ></ion-icon>
    <ion-icon
      class="navigation__item navigation__item--right"
      [class.navigation__item--inactive]="
        (scrolledRight$ | async) || !items || items.length === 0
      "
      (click)="navigate('right')"
      name="chevron-forward-outline"
    ></ion-icon>
  </div>
  <div *ngIf="heading" class="carousel__vertical-text">
    {{ heading }}
  </div>
  <div
    #carousel
    class="carousel__container"
    [class.carousel__container--stacks]="itemType === itemTypes.Stacks"
    [class.carousel__container--projects]="itemType === itemTypes.Projects"
    [class.carousel__container--clips]="itemType === itemTypes.Clips"
    [class.active-event]="options.isEvent"
    [class.carousel__container--users]="itemType === itemTypes.Users"
    (scroll)="calculateScrolls()"
    (window:resize)="calculateScrolls()"
  >
    <div
      class="carousel__container__content"
      [class.carousel__container__content--has-more]="canLoadMore"
      [class.carousel__container__content--loading-more]="isLoadingMore"
    >

      <fs-add-new-item-button
        *ngIf="!options.hasNewItemButtonAtEnd && options.hasAddNewItemButton && items?.length < showAddNewItemButtonForItemAmount"
        [itemType]="itemType"
        [isEvent]="options.isEvent"
        (click)="addNewItemButtonClick()"
      ></fs-add-new-item-button>

      <fs-slide
        *ngFor="let item of items; let i = index; trackBy: trackByFn"
        [itemType]="itemType"
        [item]="item"
        [currentUsername]="currentUsername"
        [isModerated]="isModerated"
        [canModerate]="canModerate"
        [canShare]="canShare"
        [showAvatar]="options.showAvatar"
        [isProjectMember]="isProjectMember"
        [isEvent]="options.isEvent"
        (itemClick)="itemClick.emit($event)"
      ></fs-slide>

      <fs-add-new-item-button
        *ngIf="options.hasNewItemButtonAtEnd"
        [itemType]="itemType"
        [isEvent]="options.isEvent"
        (click)="addNewItemButtonClick()"
      ></fs-add-new-item-button>
      

      <!-- undefined: items are still being loaded -->
      <ng-container *ngIf="!items">
        <fs-slide
          *ngFor="let item of [null, null, null, null]; let i = index"
          [itemType]="itemType"
          [item]="item"
          [showAvatar]="false"
        ></fs-slide>
      </ng-container>

      <!-- loading more spinner -->
      <div class="carousel__container__content__spinner">
        <ion-spinner slot="start" name="crescent"></ion-spinner>
      </div>

      <!-- empty array: items are loaded but there are no actual items -->
      <ng-container *ngIf="!options.hasAddNewItemButton && items?.length === 0">
        <div class="carousel__container__empty">
          {{ 'LISTS.NO_ITEMS' | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
