/** @format */

import { Injectable } from '@angular/core';
import { API, graphqlOperation } from '@aws-amplify/api';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '@shared/utils';
import { GraphQLResult } from './api-types';

const DEBUG_LOGS = false;
const PAGE = '[MailerApiService]';

const DEFAULT_TO_EMAIL = 'info@filmstacker.com'; //info@filmstacker.com';

/**
 * Aligns with the Lambda Handler
 */
export enum MailTopic {
  Error = 'error',
  Feedback = 'feedback',
  Contact = 'contact',
  BetaApplication = 'betaApplication',
  Flag = 'flag',
  Other = 'other topic',
  NotSpecified = '',
  CreateProject = 'create a project',
  ApplyAsCrew = 'apply to be crew',
  InviteToCrew = 'invite to be crew',
  InviteToEvent = 'invite to team',
}

export interface SendMail {
  fromEmail: string;
  toEmail?: string;
  name?: string;
  topic: MailTopic;
  subject?: string;
  heading?: string;
  message?: string;
  messageText?: string;
  footer?: string;
  buttonLabel?: string;
  returnUrl?: string;
  tokenId?: string;
  tokenQueryParam?: string;
}

export interface ProjectListInput {
  projectId?: string;
  title?: string;
  img?: string;
  shareUrl?: string;
  mission?: string;
}

/**
 * SendMessage Mailer API interactions
 */
@Injectable({
  providedIn: 'root',
})
export class MailerApiService {
  /**
   * send an email
   */
  sendEmail({
    fromEmail = '',
    message = '',
    messageText = '',
    footer = '',
    topic = MailTopic.NotSpecified,
    toEmail = DEFAULT_TO_EMAIL,
    name = '',
    subject = '',
    heading = '',
    buttonLabel = '',
    returnUrl = '',
    tokenId = '',
    tokenQueryParam = '',
  }: SendMail) {
    // console.log(`${PAGE} sendEmail params:`, { email, message, name, topic, subject, toEmail} );
    // in GraphQL @:./ chars are special characters

    // if (!fromEmail) {
    //   return of('Email is Required. Mail not sent.');
    // }

    const stringifiedMessage = JSON.stringify(message); //.replace(/""/g,'\"');

    /**
     * call api
     */
    //${encodeURIComponent(email)}
    const query = `query sendMessage {
      sendMessage(
        topic: "${topic}"
        message: ${stringifiedMessage}
        ${messageText ? 'messageText: "' + messageText + '"' : ''}
        ${toEmail ? 'toEmail: "' + toEmail + '"' : ''}
        ${fromEmail ? 'fromEmail: "' + fromEmail + '"' : ''}
        ${footer ? 'footer: "' + footer + '"' : ''}
        ${name ? 'name: "' + Utils.escapeHtml(name) + '"' : ''}
        ${subject ? 'subject: "' + Utils.escapeHtml(subject) + '"' : ''}
        ${heading ? 'heading: "' + Utils.escapeHtml(heading) + '"' : ''}
        ${buttonLabel ? 'buttonLabel: "' + Utils.escapeHtml(buttonLabel) + '"' : ''}
        ${returnUrl ? 'returnUrl: "' + returnUrl + '"' : ''}
        ${tokenId ? 'tokenId: "' + Utils.escapeHtml(tokenId) + '"' : ''}
        ${tokenQueryParam ? 'tokenQueryParam: "' + Utils.escapeHtml(tokenQueryParam) + '"' : ''}
      ) 
    }`;

    DEBUG_LOGS &&
      console.log(`[SendMailApi]`, {
        fromEmail,
        stringifiedMessage,
        messageText,
        footer,
        topic,
        toEmail,
        name,
        subject,
        heading,
        buttonLabel,
        returnUrl,
        tokenId,
        tokenQueryParam,
        query,
      });

    return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>).pipe(
      map((res) => res.data['sendMessage'])
    );
  }

  /**
   * build a message string from object
   */
  buildMessage(obj: object | string): { messageHtml: string; messageText: string } {
    // console.log(`${PAGE} buildMsg:'`, obj);
    let messageHtml = '',
      messageText = '';
    if (typeof obj == 'string') {
      return { messageHtml: obj, messageText: obj };
    }

    for (const prop of Object.keys(obj)) {
      // using for..of insteaad of for..in
      // https://eslint.org/docs/rules/guard-for-in#require-guarding-for-in-guard-for-in
      // if (Object.prototype.hasOwnProperty.call(prop, obj)) {
      // console.log(`${PAGE} buildMsg:'`, { val: obj[prop], typeof: typeof obj[prop], prop, obj });
      if (!obj[prop]) {
        console.warn(`BuildMsg: no obj[prop] val`);
        continue;
      }
      try {
        if (typeof obj[prop] == 'string') {
          messageHtml += `${prop}: <b>${obj[prop]}</b><br>`;
          messageText += `${prop}: ${obj[prop]}<br>`;
        } else if (Array.isArray(obj[prop])) {
          obj[prop].forEach((element, index) => {
            if (typeof element == 'string') {
              messageHtml += `${prop}[${index}]: <b>${element}</b><br>`;
              messageText += `${prop}[${index}]: ${element}<br>`;
            } else if (typeof element.toString == 'function') {
              messageHtml += `${prop}[${index}]: <b>${element.toString()}</b><br>`;
              messageText += `${prop}[${index}]: ${element.toString()}<br>`;
            }
          });
        } else if (typeof obj[prop].toString == 'function') {
          messageHtml += `${prop}: <b>${obj[prop].toString()}</b><br>`;
          messageText += `${prop}: ${obj[prop].toString()}<br>`;
        }
      } catch (error) {
        console.warn(`${PAGE} caught error during buildMessage`, error);
      }
      // }
    }
    DEBUG_LOGS && console.log(`${PAGE} buildMsg ret:`, { messageHtml, messageText });
    return { messageHtml, messageText };
  }

  buildProjectsListMessage(projectsList: ProjectListInput[] = []): { projectsHtml: string; projectsText: string } {
    let projectsText = '';
    const lineBr = '<br>'; // this breaks the GQL API query: Utils.convertHtmlBrToText('<br>');
    const projectsHtmlRows = projectsList.reduce((prev, curr) => {
      const {
        title,
        shareUrl,
        // id,
        img,
        // mission,
      } = curr;
      // NOTE: tokenQuery does not work with shareUrl, will lose querystring on redirect
      let row = '';
      if (title) {
        if (img) {
          row += '<td style="padding-right:6px;"><img style="height:128px;" src="' + img + '" alt=""/></td>';
        }
        const colSpan = img ? 1 : 2; // img && shareUrl ? 1 : img || shareUrl ? 2 : 3;
        let titleText = title;
        if (shareUrl) {
          projectsText += `${title} - ${shareUrl}${lineBr}`;
          titleText += `${lineBr}<a href="${shareUrl}" title="Visit ${shareUrl}">View Project on Filmstacker</a></td>`;
        } else {
          projectsText += `${title}${lineBr}`;
        }
        row += `<td colspan="${colSpan}" style="padding:6px;">${titleText}</td>`;
        return prev + row ? `<tr>${row}</tr>` : '';
      }
      return prev;
    }, '');
    const projectsHtml = projectsHtmlRows
      ? `<table cellspacing="0" cellpadding="0" style="border: none;border-collapse: collapse;">${projectsHtmlRows}</table>`
      : '';

    DEBUG_LOGS && console.log(`buildProjectsListMessage:`, { projectsHtml, projectsText });
    return { projectsHtml, projectsText };
  }
}
