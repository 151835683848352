/**
 * @todo we need to handle the case where a user is not yet signin in
 * - it's a requirement that they sign up - otherwise there's no userId...
 *
 * @format
 */

import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IonFab, ModalController, AlertController, NavController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, interval, Subject } from 'rxjs';
import { map, take, takeUntil, filter, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as mystackActions from '@store/actions/mystack.actions';
import * as stackActions from '@store/actions/stacks.actions';
import { selectMyStack, selectMyStackCurrentClip, selectMyStackCurrentIndex } from '@store/selectors/mystack.selectors';
import { Utils } from '@shared/utils';
import { MAX_VIDEO_CAPTURE_LENGTH_SECONDS } from '@app/app.config';
import { Stack, STACK_PRIVACY } from '@shared/models/stack.model';
import { getDoneTranscoding } from '@shared/models/clip.model';
import { ProjectMember, checkIsAdmin, isEventActive } from '@projects/shared/project.model';
import { CaptureClipModalComponent } from '@clips/shared/components';
import { UserService } from '@services/user.service';
import { STUDIO_PAGE, DISCOVER_PAGE, WATCH_ROUTE, PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';
import { VideoPlayerService } from '@app/modules/video-player/shared/services/video-player.service';
import { ToasterService } from '@services/toaster.service';
import { ROOT_EDIT_URL } from '@app/app-routing.module';
import { environment } from 'src/environments/environment';
import { CollabModalComponent, PublishModalComponent, PublishSuccessModalComponent } from '@stacks/shared/components';
import { PublishStackService } from '@services/publish-stack.service';
import { StackService } from '@stacks/shared/services';
import { selectMyProjects } from '@store/selectors/projects.selectors';
import { PROJECT_MEMBER_ROLE } from '@members/shared/project-member.model';
import { addMember } from '@store/actions/projects.actions';
import { getId, selectIdEdit } from '@store/selectors/stacks.selectors';
import { MyStackService } from '@services/mystack.service';
import { ProjectService } from '@projects/shared/services';
import { PlansModalComponent } from '@billing/shared/components';

const DEBUG_LOGS = false;
/**
 * @note that this was not a desireable UX without further focus
 * if feature required, needs dev to resolve timing issues with the drawer OnInit
 */
const ENABLE_SHOW_DRAWER_ON_LOAD = false;
/**
 * Show the Capture from YoutTube FAB
 * @todo: handle as popover component from Capture.page
 */
const SHOW_YOUTUBE_FAB = false;
/** the purple one; else openFab */
const ENABLE_TOOLTIP = false;
export const STACK_EDIT_PLAYER_ID = 'stack-edit';

@Component({
  selector: 'app-stack-edit',
  templateUrl: './stack-edit.component.html',
  styleUrls: ['./stack-edit.component.scss'],
})
export class StackEditComponent implements OnInit, OnDestroy {
  @ViewChild('tabFab') tabFab: IonFab;
  @ViewChild('tabFab') tabFabEl: ElementRef;
  @ViewChild('tabFabDetails') tabFabDetails: ElementRef;
  // @ViewChild(StackFormDetails) stackForm: StackFormDetails;

  watchRoute = '/' + WATCH_ROUTE;
  /** Allowed to Edit */
  // isAllowed = false;
  isSaving = false;
  isPublishing = false;
  isPreviewOpen = false;
  isVideoPlaying = false;
  playerId = STACK_EDIT_PLAYER_ID;

  userId$ = this.userService.userId$;
  isLoggedIn$ = this.userService.isLoggedIn$;
  returnUrl = '';
  /** has clips */
  hasStackClips = false;
  /** has clips currently transcoding */
  hasStackClipsTranscoding = false;
  hasMyClips = false;
  /** unused in template */
  isStackLoading = false;
  hasStackClipTooLong = false;
  // setting these onInit so it can takeUntil onDestroy... necessary?
  // segment$: Observable<string>;// = this.route.params.pipe(map((p) => p.segment || 'details'));
  projectId: string; // = this.route.params.pipe(map((p) => p.projectId || ''));
  stackId: string; // = this.route.params.pipe(map((p) => p.projectId || ''));

  projectUrl = '';

  stack$ = this.store.select(selectMyStack).pipe(
    filter(Boolean)
    /**
     * @deprecated refactor in ngrx 7
     * share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false
        })
     */
    // publishReplay(1),
    // refCount()
  );

  currentPlaylistIndex$ = this.store.select(selectMyStackCurrentIndex);
  currentClip$ = this.store.select(selectMyStackCurrentClip);

  isTabFabShown = false;
  // isTooltipShown = false;
  // placements = [
  //   { // will be translated OnInit
  //     title: 'ADD CLIPS',
  //     content: 'Capture video from your device or online, from your studio, or in our community.',
  //     // add this OnInit too, to ensure tabfab exists
  //     // el: () => this.tabFab && (this.tabFab as any).el,
  //   },
  // ];

  /** note that this was not a desireable UX without further focus */
  drawerOpenOnLoad = false;

  topDrawerExpand$: BehaviorSubject<{ i: number; showCreateForm?: boolean }> = new BehaviorSubject({ i: 0 });
  topDrawerClose$: BehaviorSubject<number> = new BehaviorSubject(0);

  captureClipEvent: boolean;
  // couldn't get this to work with the async in template to cause event...?
  // private _captureClipSubject$: Subject<boolean> = new Subject();
  // get captureClipEvent$(): Observable<boolean> {
  //   return this._captureClipSubject$.asObservable();
  // }

  // get stackFormValid(): boolean {
  //   return this.stackForm && this.stackForm.stackForm && this.stackForm.stackForm.valid;
  // }

  showYoutubeFab = SHOW_YOUTUBE_FAB;

  isPublished = false;
  // collaboration
  isCollaborative = false;
  canCollaborate = false;
  openInvite = false;
  inviteProjectEventActive = false;
  isMine = false;
  isProjAdmin = false;
  myProjects$ = this.store.select(selectMyProjects);
  
  private _incExpandEvent = 0;
  private _incCloseEvent = 0;
  private triggerTimeout;
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private translate: TranslateService,
    private userService: UserService,
    private videoPlayerService: VideoPlayerService,
    private publishStackService: PublishStackService,
    private stackService: StackService,
    private mystackService: MyStackService,
    private projectService: ProjectService,
    private toaster: ToasterService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    // has to be in constructor as OnINit only runs once, we need on all loads - INSTEAD: watch it, filtered OnINit
    // const navigation = this.router.getCurrentNavigation();
    // if (navigation?.extras?.state?.autoOpenCapture === true) {
    //   // auto open capture
    //   setTimeout(() => {
    //     console.warn('autoOpenCapture');
    //     this.captureDevice();
    //   }, 600);
    // }
  }

  ngOnInit() {
    // :projectId/:stackId/:segment
    // const routeParams$ = this.route.params.pipe(takeUntil(this.onDestroy$)); // shareReplay(1) causes issues when no params are passed...
    // // if the route segment is included in the Segments enum, return that, else default Details
    // this.segment$ = routeParams$.pipe(map((p) => (Object as any).values(Segment).includes(p.segment) ? p.segment : Segment.Details));
    this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(({ stackId = '', projectId = '' }) => {
      this.stackId = stackId;
      this.projectId = projectId;
      if (projectId && stackId && projectId !== 'video') {
        // if first route, allow the stack to be set...
        this.isStackLoading = true;
        DEBUG_LOGS && console.log(`edit route selectId`, { projectId, stackId });
        this.store.dispatch(stackActions.selectIdEdit({ projectId, stackId }));
      }
    });
    this.route.queryParamMap
      .pipe(
        takeUntil(this.onDestroy$)
        // filter((queryParams) => queryParams.get('returnUrl')?.length > 0)
      )
      .subscribe((queryParams) => {
        this.returnUrl = queryParams.get('returnUrl');
      });

    let timeoutAutoCapture;
    const checkCurrentNavForAutoCapture = () => {
      if (this.router.getCurrentNavigation()?.extras?.state?.autoOpenCapture) {
        !environment.production && console.log('nav says to autoOpenCapture..');
        clearTimeout(timeoutAutoCapture);
        // auto open capture
        timeoutAutoCapture = setTimeout(() => {
          this.captureDevice();
        }, 600);
      }
    };

    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd && event?.url?.startsWith('/stack/edit')) {
        // DEBUG_LOGS &&
        console.log(`router.events`, {
          state: this.router.getCurrentNavigation()?.extras?.state,
          extras: this.router.getCurrentNavigation()?.extras,
          // event
        });
        checkCurrentNavForAutoCapture();
      }
    });
    // but also do it now, as that doesn't pipe before first load
    checkCurrentNavForAutoCapture();

    // this.returnUrl = this.router.url;
    // translate placements
    // this.translate.get(['EDITOR.TUTORIAL.TITLE', 'EDITOR.TUTORIAL.CONTENT']).pipe(take(1))
    //   .subscribe((res) => {
    //     this.placements[0].title = res['EDITOR.TUTORIAL.TITLE'];
    //     this.placements[0].content = res['EDITOR.TUTORIAL.CONTENT'];
    //   }
    // );

    /**
     * Watch the stack, if there's a stackId then it's editable
     * route to edit url if not already there
     */
    combineLatest([
      this.stack$.pipe(
        distinctUntilChanged(
          (a, b) => a && b && a.projectId === b.projectId && a.stackId === b.stackId && a.error === b.error
        )
      ),
      this.store.select(selectIdEdit),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([stack, idEdit]) => {
        !environment.production && console.log({ stack, idEdit });
        const { stackId = '', projectId = '', error = '' } = stack;
        // if this is not the one currently selected, we should be in a route -> load action
        if (this.isStackLoading && idEdit === getId(projectId, stackId)) {
          // this is our stack
          this.isStackLoading = false;
        } else if (this.isStackLoading) {
          console.log(`isStackLoading && idEdit != mystackId`, { stack, idEdit });
          // they are not equal - we do not need to update, there's a pending load
          return;
        }

        this.projectUrl = projectId ? `/${PROJECT_DETAIL_ROUTE}/${projectId}` : '';
        const isEditRoute = this.router.url.startsWith(ROOT_EDIT_URL);
        if (error) {
          // error: "Stack not found: filmstacker-dev/jd_duplicating
          console.log(`Warning: ${error}`);
          this.store.dispatch(mystackActions.reset());
          if (isEditRoute) {
            this.navigateNoAnimation(ROOT_EDIT_URL);
          }
          return;
        }
        const url = projectId && stackId ? `${ROOT_EDIT_URL}/${projectId}/${stackId}` : ROOT_EDIT_URL;
        DEBUG_LOGS &&
          console.log(`Edit Stack redirect if '${isEditRoute}' [${stack.stackId}]:`, {
            url,
            routerUrl: this.router.url,
            stack,
          });
        if (isEditRoute && projectId && stackId) {
          this.navigateNoAnimation(url); // doesn't work unless you're on the edit page
          // } else if (isEditRoute && (!projectId && this.projectId) || (!stackId && this.stackId)) {
          //   // let's not do this, seems buggy... let the error property resolve instead
          //   // this stack was not found, remove from route
          //   this.navigateNoAnimation(ROOT_EDIT_URL);
        }
      });
    // doing this in app.component instead
    // setTimeout(() => {
    //   // this is required due to hydration of store from localstorage, to ensure getMyStack set reliably
    //   this.store.dispatch(mystackActions.load());
    // }, 700);

    this.stack$
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(
          (a, b) =>
            a &&
            b &&
            a.projectId === b.projectId &&
            a.userId === b.userId &&
            a.dtePublished === b.dtePublished &&
            a.isCollaborative === b.isCollaborative &&
            a.clips?.length === b.clips?.length &&
            a.privacy === b.privacy
        )
      )
      .subscribe((stack) => {
        // watch the stack dtePublished to disable or alert if it's published
        this.isPublished = stack?.dtePublished?.length > 0;
        this.isCollaborative = stack?.isCollaborative > 0;
        /**
         * you can collaborate if there's a project.id and stack is not published
         * MVP-1307 you can collaborate if this isCollaborative && public
         * 2024-08-30 fix that this should happen more often than this, and is only dependent on stack
         */
        this.openInvite =
          stack && !stack.dtePublished && stack.isCollaborative && stack.privacy === STACK_PRIVACY.PUBLIC;

        // load the Project immediately so it's there when we click the accept button
        if (this.openInvite) {
          // load the project to check if there's an active event
          this.projectService.getProject(stack.projectId).pipe(take(1)).subscribe((project) => {
            this.inviteProjectEventActive = isEventActive(project)
          });
        } 
        
        if (Array.isArray(stack?.clips)) {
          // ref: mystack.component.html clip?.sources?.length > 0; else uploadingItem
          this.hasStackClips = stack.clips.length > 0;
          const clipDoneTranscoding = stack.clips.filter((clip) => getDoneTranscoding(clip));
          this.hasStackClipsTranscoding = clipDoneTranscoding.length !== stack.clips.length;
          // this.hasStackClips = !!stack.clips.length;
          this.hasStackClipTooLong = stack.clips.some(
            (clip) => Utils.convertDurationToSeconds(clip?.duration ?? '') > MAX_VIDEO_CAPTURE_LENGTH_SECONDS
          );
        }
      });

    combineLatest([
      this.stack$.pipe(
        distinctUntilChanged(
          (a, b) =>
            a &&
            b &&
            a.projectId === b.projectId &&
            a.userId === b.userId &&
            a.dtePublished === b.dtePublished &&
            a.privacy === b.privacy &&
            a.clips?.length === b.clips?.length
        )
      ),
      this.userId$.pipe(distinctUntilChanged()),
      this.myProjects$.pipe(distinctUntilChanged((a, b) => a && b && a.length === b.length)),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([stack, userId, myProjects]) => {
        this.isMine = userId && stack?.userId === userId;

        const proj = (myProjects || []).find((p) => stack.projectId && p?.id === stack.projectId);
        
        this.canCollaborate = stack && !stack.dtePublished && proj?.id?.length > 0;
        //checkIsMember(userId, proj) &&? no.
        this.isProjAdmin = proj?.id?.length > 0 && checkIsAdmin(userId, proj);
        this.hasMyClips = stack.clips.some((clip) => clip.userId === userId);
        DEBUG_LOGS &&
          console.log(`Checking Permissions`, {
            stack,
            userId,
            myProjects,
            openInvite: this.openInvite,
            isMine: this.isMine,
            canCollaborate: this.canCollaborate,
            isProjAdmin: this.isProjAdmin,
        });
      });
  }

  ionViewWillLeave() {
    this.isTabFabShown = false;
    this.tabFab?.close();
    this.videoPlayerService.pause(this.playerId);
    // we need to force rerender due to the *ngIf on isTabFabShown which wouldn't execute sometimes due to ionic caching pages
    this.changeDetectorRef.detectChanges();
  }

  ionViewWillEnter() {
    this.isTabFabShown = true;

    if (ENABLE_SHOW_DRAWER_ON_LOAD) {
      //  check if the navigationExtras included some state
      // if feature required, needs dev to resolve timing issues with the drawer OnInit
      this.drawerOpenOnLoad = !(history && history.state && history.state.hideDrawerOnLoad);
      console.log(`drawerOpenOnLoad`, { drawerOpenOnLoad: this.drawerOpenOnLoad, historyState: history.state });
    }

    // we give the user a 2 sec headstart until the tooltip is shown, if no clips
    combineLatest([this.stack$.pipe(take(1)), interval(2000).pipe(take(1))])
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map(([s, w]) => s), // to filter out the value emitted by wait$
        take(1) // just to limit to 5 the values emitted
      )
      .subscribe((res: Stack) => {
        // close the tabfab onload, it'll be re-opened if needed, below (MVP-1182)
        this.tabFab?.close();

        if (res && res.error) {
          // stack had an error, let's not do anything here..
          return;
        }

        if (ENABLE_TOOLTIP) {
          // ensure tabfab exists, definitely should after this delay (avoiding issues in rendering where it was showing in middle of page)
          // this calc is finding two, need to choose only one..
          // this.placements = this.placements.map(p => ({ ...p, el: () => this.tabFabEl && (this.tabFabEl as any).el}));
        }

        if (!res) {
          !environment.production && console.warn(`EditTutorial check: missing response? opening fab...`);
          this.opentabFab();
          return;
        }
        DEBUG_LOGS && console.log(`StackEdit stack:`, res);
        // show tooltip if the timer triggered (showTutorial) or if there are no clips or clipIds (clips might be loading still)
        if (!this.stackId) {
          //  && !res.stackId
          this.expandDraftDrawer(true);
        }
        const stackHasClipIds =
          (Array.isArray(res.clipIds) && res.clipIds.length > 0) || (Array.isArray(res.clips) && res.clips.length > 0);
        if (!stackHasClipIds) {
          // res.showTutorial ||
          this.opentabFab();
        }
        // we need to force rerender due to the *ngIf on isTabFabShown which wouldn't execute sometimes due to ionic caching pages
        this.changeDetectorRef.detectChanges();
      });

    // setTimeout(() => {
    //   this.isTooltipShown = true;
    // }, 1000);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async continuePublish(event = true) {
    // save our stack in it's current state, so that nothing erases progress..
    this.saveStack();
    const modal = await this.modalCtrl.create({
      component: PublishModalComponent,
      cssClass: 'modal-publish modal-wide fullheight-modal',
      componentProps: {
        // todo: MVP-1204 handle admins being able to edit the info on a stack
        isProjAdmin: this.isProjAdmin,
      },
    });
    await modal.present();

    const { data = {} } = await modal.onDidDismiss();
    this.tabFab?.close();

    DEBUG_LOGS && console.log(`[Publish] res:`, data);
    const { action, stack } = data;
    switch (action) {
      case 'save':
        break;
      case 'publish':
        this.navCtrl.navigateRoot([ROOT_EDIT_URL], { animated: false });
        this.openPublishSuccessModal(stack);
        break;
      case 'play':
        this.navCtrl.navigateForward([`/${WATCH_ROUTE}`, stack.projectId, stack.stackId]);
        break;
      case 'cancel':
      default:
      // data = PointerEvent, it was simply a dismiss/cancel
      // console.log(`Unhandled dismiss action?`, data)
    }
  }

  async openPublishSuccessModal(stack) {
    // mystack has already been reset...

    const modal = await this.modalCtrl.create({
      component: PublishSuccessModalComponent,
      componentProps: {
        stack,
        // how here do we watch the updates? we should send the store selector, no?
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data.action) {
      switch (data.action) {
        case 'clear':
          // reset mystack already happened above...
          // this.mystackService.resetMyStack();
          break;
        case 'play':
          this.navCtrl.navigateForward([`/${WATCH_ROUTE}`, stack.projectId, stack.stackId]);
          break;
        default:
          console.log(`Unknown action?`, data);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async inviteCrew(event) {
    this.stack$.pipe(take(1)).subscribe(async (stack) => {
      const modal = await this.modalCtrl.create({
        component: CollabModalComponent,
        componentProps: {
          stack,
        },
      });
      modal.present();
      const { data } = await modal.onWillDismiss();
      console.log(`Modal dismissed`, data);
      // if (data) // if true, it was done... do we care?
      this.tabFab?.close();
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeCrew(event) {
    this.stack$.pipe(take(1)).subscribe((stack) => {
      this.stackService.removeCollaborative(stack.projectId, stack.stackId);
    });
  }

  expandDraftDrawer(showCreateForm = false) {
    this.topDrawerExpand$.next({ i: ++this._incExpandEvent, showCreateForm });
  }
  closeDraftDrawer() {
    this.topDrawerClose$.next(++this._incCloseEvent);
  }

  /**
   * drawer opened/closed
   */
  draftDrawerExpanded(event) {
    if (!event) {
      // closed
      if (!this.stackId) {
        // re-open the drawer... no!
      }
    }
  }

  opentabFab() {
    if (this.tabFab) {
      this.tabFab.activated = true;
    }
  }
  // closeTabFab() {
  //   if (this.tabFab && typeof this.tabFab.close === 'function') {
  //     this.tabFab.close();
  //   }
  // }

  /**
   * Avoid page animations for segment changes,
   * you can use NavController of Ionic4 giving { animated: false } as NavigationOptions
   */
  navigateNoAnimation(path) {
    if (path) {
      this.navCtrl.navigateForward(path, { animated: false });
    }
  }

  onPlayClipIndex(index: number) {
    console.log(`onPlayClipIndex ${index}`);
    if (typeof index !== 'number') {
      console.warn(`[StackEdit] onPlayClipIndex payload index isNaN???`, index);
      return;
    }
    this.isPreviewOpen = true;
    this.videoPlayerService.play(this.playerId);

    this.currentPlaylistIndex$.pipe(take(1)).subscribe((currentPlaylistIndex) => {
      if (index !== currentPlaylistIndex) {
        this.store.dispatch(mystackActions.currentIndex({ index }));
      }
    });
  }

  onPauseClipIndex(index: number) {
    console.log(`onPauseClipIndex ${index}`);
    if (typeof index !== 'number') {
      console.warn(`[StackEdit] onPauseClipIndex payload index isNaN???`, index);
      return;
    }
    this.videoPlayerService.pause(this.playerId);
    this.isPreviewOpen = false;
  }

  closePreview() {
    this.videoPlayerService.pause(this.playerId);
    this.isPreviewOpen = false;
  }

  /**
   * Can only happen if
   * stackId && hasStackClips  && stackFormValid
   * hasStackClipsTranscoding disables publish, but allows save
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionClick(event) {
    // open publish modal
    this.continuePublish();

    // this.stack$.pipe(take(1)).subscribe((stack) => {
    //   if (stack.dtePublished) {
    //     // update published stack
    //     this.saveStack();
    //   } else if (!stack.poster || stack.poster === STACK_DEFAULT_POSTER) {
    //     // open publish modal
    //     this.continuePublish();
    //     // poster not set, prompt
    //     // this.stackForm.openPosterSelector(event, stack).then((poster) => {
    //     //   if (poster && this.stackFormValid) {
    //     //     // this.confirmPublishStack(stack);
    //     //   }
    //     // });
    //   } else if (stack.stackId)  {
    //     // open publish modal
    //     this.continuePublish();
    //     // publish
    //     // this.confirmPublishStack(stack);
    //   }
    // })
  }

  addAllClipsToStack({ restack = false }) {
    this.stack$.pipe(take(1)).subscribe((stack) => {
      if (stack && Array.isArray(stack.playlist) && stack.playlist.length > 0) {
        // this.isFabOpen = false;
        // this.expandDraftDrawer();// already happens when playlist exists
        if (restack) {
          // MVP-1342 add title and description if create new (Restack)
          this.store.dispatch(
            stackActions.addToStackDrawer({
              playlist: stack.playlist,
              isCreateNew: true,
              projectId: stack.projectId,
              title: stack.title,
              description: stack.description,
              poster: stack.poster,
            })
          );
        } else {
          this.store.dispatch(stackActions.addToStackDrawer({ playlist: stack.playlist }));
        }
        // console.log(`addAllClipsToStack`, { stack, event });
      } else {
        !environment.production && console.log(`No Playlist.`, stack);
      }
    });
  }

  /**
   * Consider: on mystackActions.reorderClipIds -> autosave
   */
  saveStack() {
    this.isSaving = true;
    try {
      combineLatest([this.stack$, this.userId$, this.userService.username$])
        .pipe(take(1))
        .subscribe(async ([stack, userId, username]) => {
          // MVP-1176 handle collaborative stack
          const isMine = stack.userId === userId;
          DEBUG_LOGS && console.log(`saveStack`, { isMine, stack, userId, username });
          this.publishStackService.saveStackUpdates({
            userId: isMine ? userId : stack.userId,
            credits: isMine ? username : stack.credits,
            projectId: stack.projectId,
            stackId: stack.stackId,
            clips: stack.clips,
            poster: stack.poster,
          });
          setTimeout(() => {
            // just making it show loading for a bit, since we only dispatched actions here
            this.isSaving = false;
            // if we are a projAdmin then we will be in PublishModal, so don't show this
            if (!this.isMine && !this.isProjAdmin && stack.isCollaborative) {
              this.toaster.present(this.translate.instant('EDITOR.SAVE_COLLAB_SUCCESS'));
            }
          }, 900);
        });
    } catch (error) {
      console.error(error);
      // this.errorMsg = this.translate.instant('ERRORS.GENERIC_OOPS');
      // this.isLoading = false;
      this.isSaving = false;
    }
  }

  /**
   * modal with capture-instructions
   * only used if there is not a projectId...
   */
  async presentCaptureModal() {
    const modal = await this.modalCtrl.create({
      component: CaptureClipModalComponent,
      componentProps: {
        projectId: this.projectId,
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    console.log(`CaptureClipModal dismiss:`, { data });
  }

  /**
   * clipUpload button success
   * @todo MVP-1321
   * sort uploaded clips by title before saving
   */
  captureSuccess(filesUploaded = []) {
    const clips = filesUploaded.map((file) => {
      const id = Utils.removeFileExt(file.filename);
      return {
        id,
        title: id,
        projectId: file.uploadTags?.projectId ?? '',
      };
    });
    DEBUG_LOGS && console.log(`captureSuccess sort uploaded clips by title before saving:`, { clips, filesUploaded });
    this.mystackService.sortUserUploadSuccessByTitle(clips);
  }

  /**
   * check if there's a projectId and it's one of mine, then do it directly, else modal..
   */
  captureDevice() {
    combineLatest([this.userService.isCaptureAllowed$, this.userService.userId$, this.userService.username$])
      .pipe(take(1))
      .subscribe(([{ isAllowed, projects }, userId, username]) => {
        if (!userId) {
          this.tabFab?.close();
          this.navCtrl.navigateForward('/login', { queryParams: { returnUrl: this.router.url } });
          return;
        }
        // there's a user,
        const exists = projects.find((p) => p?.id && p.id === this.projectId);
        DEBUG_LOGS && console.log('captureDevice check', { userId, isAllowed, projects, projectId: this.projectId, project: exists });

        if (isAllowed && exists?.id) {
          // captureDevice projectId exists, open Filestack directly
          this.triggerCapture();
          this.tabFab?.close();
        } else if (!isAllowed && exists?.id) {
          // we are crew but not subscribed
          DEBUG_LOGS && console.log(`we are crew but not subscribed (captureDevice not allowed), open plans modal`);
          this.tabFab?.close();
          this.openPlansModal();
        } else if (this.projectId && this.openInvite) {
          // assume we're not an active crew member
          const member: ProjectMember = {
            isActive: true,
            role: PROJECT_MEMBER_ROLE.CREW,
            projectId: this.projectId,
            userId,
            username,
          };
          // DEBUG_LOGS && console.log(`captureDevice openInvite`, { member });
          /**
           * here we know this will be in MINE Projects once done here
           * sent to Effect with action instead of direct call to API
           * // do we assume this was successful in API? (via Effect)
           * // what happens if failure? hmm..
           */
          this.store.dispatch(addMember({ member }));
          //  this.analyticsService.crewInviteAccepted(projectId);

          // need to verify that the project has active event, else go to plans..
          const handleActiveEvent = (project) => {
            if (isEventActive(project) || this.inviteProjectEventActive) {
              this.triggerCapture();
            } else {
              DEBUG_LOGS && console.log(`captureDevice no active event, open plans modal`);
              this.openPlansModal();
            }
          };
          // MVP-1410 we know this is an openInvite, so we need to check if there's an active event
          // even if exists, we need to load full project record 
          if (exists?.id) {
            handleActiveEvent(exists);
          } else {
            this.projectService
              .getProject(this.projectId)
              .pipe(
                filter((p) => p?.id === this.projectId),
                take(1)
              )
              .subscribe((project) => {
                handleActiveEvent(project);
              });
          }
        } else if (isAllowed) {
          // we are Pro, but no projectId found
          // modal with capture-instructions, select projectId
          this.presentCaptureModal();
        } else if (!this.projectId) {
          DEBUG_LOGS && console.log(`captureDevice no projectId, open plans modal`);
          this.openPlansModal();
        } else {
          console.warn('CaptureDevice UNHANDLED?');
          this.openPlansModal();
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  captureYoutube(e: Event) {
    console.log(`DEV: youtube capture disabled`);
    // this.router.navigate(['/stack/capture/youtube']);
  }

  newDraft() {
    this.store.dispatch(stackActions.addToStackDrawer({ playlist: [], isCreateNew: true }));
    this.expandDraftDrawer(true);
  }
  restack() {
    this.addAllClipsToStack({ restack: true });
  }

  routeStudio() {
    this.navCtrl.navigateForward(STUDIO_PAGE);
  }
  routeDiscover() {
    this.navCtrl.navigateForward(DISCOVER_PAGE, { queryParams: { returnUrl: this.router.url } });
  }
  routeProject() {
    this.navCtrl.navigateForward([`/${PROJECT_DETAIL_ROUTE}`, this.projectId]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPlayerEnded(event) {
    this.store.dispatch(mystackActions.nextClip());
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPlaylistDone(event) {
    // this.isHistoryOpen = true;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onPlayerHeight(event) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onOrientationChange = (orient) => {
    // this.isHistoryOpen = false;
  };

  /**
   * We need to reset the trigger event..
   */
  private triggerCapture() {
    this.captureClipEvent = true;
    clearTimeout(this.triggerTimeout);
    this.triggerTimeout = setTimeout(() => {
      this.captureClipEvent = false;
    }, 300);
  }

  private async openPlansModal() {
    const modal = await this.modalCtrl.create({
      component: PlansModalComponent,
      componentProps: {},
      cssClass: 'fullheight-iframe-modal',
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      console.log('PlansModal dismissed:', data);
    }
  }
}
