/** @format */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-consent-banner',
  imports: [RouterModule, IonicModule, TranslateModule],
  templateUrl: './consent-banner.component.html',
  styleUrls: ['./consent-banner.component.scss'],
})
export class ConsentBannerComponent implements OnInit {
  @Output() accept = new EventEmitter<string>();

  isShown = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.isShown = true;
    }, 1000);
  }
}
