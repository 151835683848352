/** @format */

import { Component, Input } from '@angular/core';
import { OnboardingTypes, getTranslatePath, TRANSLATE_ROOT } from '../../shared/onboarding.model';

@Component({
  selector: 'app-onboarding-section-get-started',
  templateUrl: './section-get-started.component.html',
  styleUrls: ['./section-get-started.component.scss'],
})
export class OnboardingSectionGetStartedComponent {
  // @Input() routeId: OnboardingTypes = OnboardingTypes.None;
  @Input()
  set routeId(value) {
    this._routeId = value || OnboardingTypes.None;
    this.translatePath = getTranslatePath(value);
  }
  get routeId(): OnboardingTypes {
    return this._routeId;
  }

  @Input() showTitle = false;
  @Input() showMessage = true;
  @Input() actionUrl = '/onboarding/signup';

  translatePath = TRANSLATE_ROOT;

  private _routeId = OnboardingTypes.None;
}
