/**
 * Collapse Component Directive
 * Creates a collapsible on an element
 *
 * the .collapse css is in app/app.scss
 *
 * // ex: https://embed.plnkr.co/oVoPIA0ZbMMauth812Ls/
 * @format
 */

import { Directive, Input, HostBinding, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCollapse]', // Attribute selector
})
export class CollapseDirective {
  private speed: number = 4; // ms speed of timeout

  // style
  @HostBinding('style.max-height')
  private height: string;
  // shown
  @HostBinding('class.in')
  @HostBinding('attr.aria-expanded')
  private isExpanded: boolean = true;
  // hidden
  @HostBinding('attr.aria-hidden')
  private isCollapsed: boolean = false;
  // stale state
  @HostBinding('class.collapse')
  private isCollapse: boolean = true; //add the css via class
  // animation state
  @HostBinding('class.collapsing')
  private isCollapsing: boolean = false;

  @Input()
  private set appCollapse(value: boolean) {
    this.isExpanded = value;
    this.measureHeight(); // get the initial height
    this.toggle();
    // if(value!==undefined){
    //   if(value){
    //     this.hide();
    //   }else {
    //     this.show();
    //   }
    // }
  }

  private get appCollapse(): boolean {
    return this.isExpanded;
  }

  private h: number = 50; // internal int height tracking, defaulted

  constructor(private el: ElementRef) {
    // console.log('Hello Collapse Directive');
    // this.measureHeight(); // elem has no height at this point
  }

  measureHeight() {
    const elem = this.el.nativeElement;
    //lets be sure the element has display:block style
    // elem.className = elem.className.replace('collapse', '');
    if (elem.scrollHeight > 0) {
      this.h = elem.scrollHeight;
    }
    // console.log("[collapse] elem.scrollHeight",elem.scrollHeight)//this is always zero?
  }
  toggle() {
    if (this.isExpanded) {
      this.hide();
    } else {
      this.show();
    }
  }
  hide() {
    // this.height = this.h +'px'
    // this.isCollapse = false;//do we need this?
    this.isCollapsing = true;
    this.isExpanded = false;
    this.isCollapsed = true; //aria-hidden
    setTimeout(() => {
      this.height = '0px';
      this.isCollapse = true;
      this.isCollapsing = false;
    }, this.speed);
  }
  show() {
    // this.height = '0px'
    // this.isCollapse = false;//do we need this?
    this.isCollapsing = true;
    this.isExpanded = true;
    this.isCollapsed = false; //aria-expanded
    setTimeout(() => {
      this.height = this.h + 'px';
      this.isCollapse = true;
      this.isCollapsing = false;
    }, this.speed);
  }
}
