/** @format */

import { createAction, union } from '@ngrx/store';

export const resetStore = createAction('[Reset] Reset Store');
export const resetStoreThenReboot = createAction('[Reset] Reset Store Then Reboot');
export const resetStoreOnLogout = createAction('[Reset] Reset Store On User Logout');

// do not export return value
const actions = union({
  resetStore,
  resetStoreThenReboot,
  resetStoreOnLogout,
});

// export only type
export type ActionsUnion = typeof actions;
