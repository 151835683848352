/**
 * @todo REFACTOR EXPLORE
 * @todo refactor to remove ignores of typescript-eslint/no-explicit-any
 * @format
 */

import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import * as stackActions from '../store/actions/stacks.actions';
import { selectExploreEnvironState } from '../store/selectors/search.selectors';

import { CoreLogicApiService } from '../api/core-logic-api.service';
import { DEFAULT_POSTER } from '../../shared/models/clip.model';
import { Stack } from '@shared/models/stack.model';

import { SentryService } from './analytics/sentry.service';
import { PUBLIC_CONTENT_URL } from '../api/clips-api.service';
import { PathList } from '@app/modules/explore/shared/explore.model';
import { ConfigService } from '@app/core/config';
import { lastValueFrom } from 'rxjs';
export { getId } from '../store/selectors/environ.selectors';

const DEBUG_LOGS = false;

const PAGE = '[ExploreService]'; // eslint-disable-line @typescript-eslint/no-unused-vars

export interface GetPathwaysInput {
  pathId: string;
  num: number;
  currentClip?: { id: string; projectId: string };
  currentPaths?: PathList[];
}

/**
 * @deprecated use pathway's'
 */
export interface GetPathwayInput {
  pathId: string;
  pathIndex: number;
  iter: number;

  currentClip?: { id: string; projectId: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentPlaylist?: any[];
  currentStack?: { projectId: string; stackId: string };
}

@Injectable({
  providedIn: 'root',
})
export class ExploreService {
  constructor(
    private coreApi: CoreLogicApiService,
    private store: Store<State>,
    private configService: ConfigService,
    private sentryService: SentryService
  ) {}

  /**
   * GetPathways from GetPathwaysInput
   * @returns Promise<{ paths: PathList[], pathId }>
   */
  getPathways({ pathId, num, currentPaths = [] }: GetPathwaysInput): Promise<{ paths: Stack[] }> {
    return new Promise((resolve, reject) => {
      this.store
        .select(selectExploreEnvironState)
        .pipe(take(1))
        .subscribe(
          (state) => {
            DEBUG_LOGS &&
              console.log(`${PAGE} getPathways -> pathId: ${pathId}`, {
                currentPaths,
                state,
                num,
              });

            // convert pathLists to stackIds
            const pathIds = currentPaths.map((path) => path.pathId);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const params: any = {
              pathId,
              currentPaths: pathIds,
              num,
            };

            // remove empty props from state
            Object.keys(state).forEach((k) => {
              if (
                (!state[k] && typeof state[k] !== 'boolean' && state[k] !== 0) ||
                (Array.isArray(state[k]) && state[k].length < 1)
              ) {
                // console.log(`delete ${k}`, state[k]);
                delete state[k];
              }
            });

            this.coreApi
              .doExplore('getExplorePathways', params, state)
              .pipe(take(1))
              .subscribe(
                ({ items, nextToken }) => {
                  DEBUG_LOGS && console.log(`${PAGE} getPathways res:`, items);

                  if (nextToken) {
                    console.warn(`${PAGE} doExplore nextToken EXISTS!`);
                  }

                  if (!Array.isArray(items) || items.length < 1) {
                    console.warn(`${PAGE} doExplore - No items!`);
                    reject('No Items!');
                  }

                  const paths = items.map((item) => {
                    // create the front-end version of the paths, if we received clips
                    if (item && Array.isArray(item.clips) && item.clips.length) {
                      item.clips = item.clips.map((clip) => {
                        if (clip.poster && !clip.poster.startsWith('http')) {
                          clip.poster = PUBLIC_CONTENT_URL + clip.poster;
                        }
                        if (!clip.poster && !clip.youtube_id) {
                          clip.poster = DEFAULT_POSTER;
                        }
                        clip.sources.map((source) => {
                          if (source.src) {
                            source.src = PUBLIC_CONTENT_URL + source.src;
                          }
                          return source;
                        });
                        return clip;
                      });
                    }
                    // else add clips to store on play...
                    return item;
                  });

                  resolve({ paths });
                },
                (err) => {
                  console.warn(`${PAGE} getPathways error:`, err);
                  reject(err);
                }
              );
          },
          (error) => {
            console.error(error);
            this.sentryService.captureError(error);
          }
        );
    });
  }

  /**
   * @deprecated UNUSED
   * Call CoreAPI SearchStart
   * @param envData
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private doSearchStart(envData?): Promise<any> {
    DEBUG_LOGS && console.log(`${PAGE} doSearchStart...`);
    /**
     * get latest environ state, history clips
     * then coreApi searchStart
     */
    return lastValueFrom(this.store.select(selectExploreEnvironState).pipe(take(1))).then((data) => {
      // console.log(`${PAGE} doSearchStart -> data:`, data);
      if (envData) {
        data = { ...data, ...envData };
        DEBUG_LOGS && console.log(`${PAGE} doSearchStart -> merged envdata:`, data);
      }
      return lastValueFrom(this.coreApi.searchStart(data).pipe(take(1))).then((res) => {
        DEBUG_LOGS && console.log('coreApi.searchStart res:', res);
        return this.onSearchStartLoaded(res);
      });
    });
  }

  /**
   * @deprecated - REFACTOR!
   * Handle the response from CoreAPI
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onSearchStartLoaded(res): Promise<any> {
    DEBUG_LOGS && console.log(`${PAGE} onSearchStartLoaded:`, res);
    return new Promise((resolve, reject) => {
      const {
        // envData = {},
        items = [],
        nextToken = null,
      } = res;

      if (nextToken) {
        console.log(`${PAGE} TODO: Handle nextToken!`);
      }

      if (!items || items.length < 1) {
        reject('no stack');
      }

      // let proj = (envData && envData.activeProject) ? envData.activeProject : DEFAULT_PROJECT;
      const stack = new Stack(items[0]);

      /**
       * @todo revist this logic when you do
       *
       * @see explore-ui.component.watchStack()
       */

      this.store.dispatch(stackActions.createStackPlaylist({ stack }));
    });
  }

  /**
   * @deprecated UNUSED
   * GetPathwayInput
   * { currentClip, currentPlaylist, currentStack, pathId = 0, iter = 0 }
   */
  private getPathway({
    currentClip,
    currentPlaylist,
    currentStack = null,
    pathId = '',
    iter = 0,
  }: GetPathwayInput): Promise<Stack> {
    return new Promise((resolve, reject) => {
      this.store
        .select(selectExploreEnvironState)
        .pipe(take(1))
        .subscribe((envData) => {
          // console.warn(`${PAGE} getPathway -> envData:`,JSON.stringify(envData));
          console.warn(`${PAGE} getPathway -> envData:`, envData);

          // pathId = pathId > 0 ? pathId : 0;
          DEBUG_LOGS &&
            console.log(`${PAGE} getPathway -> pathId: ${pathId}`, {
              currentStack,
              currentPlaylist,
            });

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const params: any = {
            pathId,
            iter,
            currentClip,
            currentStack,
          };

          if (!currentStack && currentPlaylist && currentPlaylist.length > 0) {
            params.currentPlaylist = currentPlaylist
              .filter((item) => item && item.projectId && item.id)
              .map((item) => ({
                projectId: item.projectId,
                id: item.id,
              }));
          }
          // console.log(`${PAGE} getPathway params.currentPlaylist:`, JSON.stringify(params.currentPlaylist));
          if (!currentStack && params.currentPlaylist.length < 1) {
            return reject('No Playlist or Stack to Explore');
          }

          this.coreApi
            .doExplore('getExplorePathway', params, envData)
            .pipe(take(1))
            .subscribe(
              (res) => {
                DEBUG_LOGS && console.log(`${PAGE} getExplorePathway res:`, res);

                if (res.nextToken) {
                  console.info(`${PAGE} doExplore TODO: nextToken EXISTS!`);
                }

                if (!res.items || !res.items.length) {
                  console.info(`${PAGE} doExplore TODO: handle no items`);
                  reject('No Items!');
                }

                const stack = res.items[0];

                if (stack && stack.clips && stack.clips.length) {
                  stack.clips = stack.clips.map((item) => {
                    if (item.poster && !item.poster.startsWith('http')) {
                      item.poster = PUBLIC_CONTENT_URL + item.poster;
                    }
                    if (!item.poster && !item.youtube_id) {
                      item.poster = DEFAULT_POSTER;
                    }
                    item.sources.map((source) => {
                      if (source.src) {
                        source.src = PUBLIC_CONTENT_URL + source.src;
                      }
                      return source;
                    });
                    return item;
                  });
                } else {
                  // console.log(`${PAGE} doExplore: add clips to store on play...`);
                }

                resolve(stack);
              },
              (err) => {
                console.warn(`${PAGE} getExplorePathway error:`, err);
                reject(err);
              }
            );
        });
    });
  }
}
