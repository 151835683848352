<ion-item [class.current]="index === currentIndex" lines="none" 
          [attr.text-wrap]="isCollapsedDetails ? null : true" 
          (click)="onClickItem($event)"
          class="mystack-item">

  
  <!-- the display:block style forces the thumbnail to appear even if drag is disabled -->
  <ion-reorder slot="start" style="display:block;">
    <ion-thumbnail class="poster">
      <div *ngIf="clip?.poster" class="handle clip-poster" [ngStyle]="{'background-image': 'url(' + clip.poster + ')'}"></div>

      <div *ngIf="clip?.duration" class="clip-length" [class.too-long]="tooLong">{{clip?.duration | duration}}</div> 
      
      <div *ngIf="bDisplayIndex" class="clip-index">{{index}}</div> 
    </ion-thumbnail>
  </ion-reorder>

  <div slot="start" class="poster-play-wrap">
    <ion-button *ngIf="!isVideoPlaying || index !== currentIndex" 
      fill="clear" size="large" color="light" 
      class="poster-play ion-no-margin" 
      (click)="onClickPlay($event)">
        <ion-icon slot="icon-only" name="play-sharp"></ion-icon>
    </ion-button>
    <ion-button *ngIf="isVideoPlaying && index === currentIndex" 
      fill="clear" size="large" color="light" 
      class="poster-play ion-no-margin" 
      (click)="onClickPause($event)">
        <ion-icon slot="icon-only" name="pause-sharp"></ion-icon>
    </ion-button>
  </div>
  
  <ion-label>
    <h2 class="clip-title keep-top ion-text-wrap">{{ clip?.title | sentenceCase }}</h2>
    <div *ngIf="!tooLong" class="date">
      {{ dateFilmed }}
      <ion-icon *ngIf="clip?.hlsSrc?.length > 0" name="aperture-sharp" style="height:10px;"></ion-icon>
    </div>
    <div *ngIf="tooLong" class="duration-warning">Trim Before Publish - Max Duration exceeded</div>
    <div class="clip-user">
      <ion-item class="ion-text-wrap ion-no-padding" lines="none">
        <ion-avatar slot="start">
          <img [src]="userAvatarUrl" [alt]="clipOwner$ | async" [fallback]="avatarFallback" draggable="false" />
        </ion-avatar>
        <ion-label *ngIf="clipOwner$ | async as name" class="">{{ name }}</ion-label>
      </ion-item>
    </div>
  </ion-label>
          
      
  <ion-button *ngIf="isCollapsedDetails" slot="end" fill="clear" color="light" title="See details..." (click)="toggleDetails($event)">
    <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
  </ion-button>
  <ion-button *ngIf="!isCollapsedDetails" slot="end" fill="clear" color="light" title="Close details" (click)="toggleDetails($event)">
    <ion-icon slot="icon-only" name="ellipsis-vertical-outline"></ion-icon>
  </ion-button>

  
  <ion-reorder slot="end">
    <ion-icon name="reorder-two-outline" size="large" class="handle" color="light"></ion-icon>
  </ion-reorder>

</ion-item>

<ion-item-options side="end" (click)="closeSlider()" (ionSwipe)="onSwipeDelete($event)">
  <ion-item-option expandable [disabled]="!isMyStack && !isMyClip" (click)="deleteClip()" color="secondary" class="expandable-option">
    <ion-icon slot="top" name="remove-circle-outline"></ion-icon>
    {{ 'EDITOR.REMOVE' | translate }}
  </ion-item-option>
</ion-item-options>

<div [appCollapse]="isCollapsedDetails" class="mystack-clip-details ion-text-wrap" [class.current]="index === currentIndex" (click)="toggleDetails($event)">
  <app-clip-detail *ngIf="clip"
    [clip]="clip" 
    [currentPage]="'editor'"
    [index]="index"
    [playlistLength]="playlistLength"
    [isAdmin]="isAdmin"
    [canEdit]="isMyClip"
    [canRemove]="isMyStack || isMyClip"
    (moveTop)="onMoveTop($event)"
    (moveBottom)="onMoveBottom($event)"
    (deleteClip)="onDeleteClip($event)"
    (trimClip)="onTrimClip($event)"
    (editClip)="onEditClip($event)"
  ></app-clip-detail>
</div>
