<ion-fab *ngIf="isPopover" vertical="top" horizontal="start" slot="fixed" class="close-fab">
  <ion-fab-button class="close-btn" size="small" (click)="close()">
    <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>
<app-clip-detail-actions
  *ngIf="clip"
  [clip]="clip"
  [canEdit]="canEdit"
  [canRemove]="canRemove"
  [adminHls]="isAdmin && !(clip?.hlsSrc?.length > 0)"
  [currentPage]="currentPage"
  [playlistLength]="playlistLength"
  [index]="index"
  (moveTop)="moveTop.emit($event)"
  (moveBottom)="moveBottom.emit($event)"
  (deleteClip)="deleteClip.emit($event)"
  (trimClip)="trimClip.emit($event)"
  (editClip)="editClip.emit($event)"
  (closed)="close()"
></app-clip-detail-actions>
<div *ngIf="showSegmentBar" class="segment-bar">
  <ion-segment [(ngModel)]="segment" color="dark" mode="md">
    <ion-segment-button value="details" (click)="onSegmentClick($event)">
      <ion-label> Details </ion-label>
    </ion-segment-button>
    <ion-segment-button value="transcript" (click)="onSegmentClick($event)">
      <ion-label> Transcript </ion-label>
    </ion-segment-button>
  </ion-segment>
</div>

<div [ngSwitch]="segment" *ngIf="clip; else noClip" class="segment-view" [ngClass]="{ popover: isPopover }">
  <div *ngSwitchCase="'details'" class="details">
    <div *ngIf="isPopover">
      <div class="embed-responsive embed-responsive-16by9">
        <div
          (click)="playClip()"
          class="poster embed-responsive-item"
          [ngStyle]="{ 'background-image': 'url(' + getPoster() + ')' }"
        ></div>
      </div>
      <h3 class="clip-title">{{ clip.title }}</h3>
      <!-- <p *ngIf="clip.description" class="clip-desc">
          {{clip.description}}
      </p> -->

      <!-- <clip-detail-actions [clip]="clip" class="player-toolbar"></clip-detail-actions> -->
    </div>
    <!--end isPopover-->

    <div *ngIf="clipDesc" class="clip-desc">
      {{ clipDesc }}
    </div>

    <ion-item class="ion-text-wrap ion-no-padding" lines="none">
      <ion-avatar slot="start">
        <img [src]="userAvatarUrl" [alt]="clipOwner$ | async" [fallback]="avatarFallback" />
      </ion-avatar>
      <ion-label>
        <h3 *ngIf="clipOwner$ | async as name">{{ name }}</h3>

        <div hidden class="stats">
          <!-- Hiding, pending favorite & restack functionality -->
          <div class="ion-float-left">
            <ion-icon name="play-sharp"></ion-icon>
            {{ clip.views }}
          </div>
          <div class="ion-float-left">
            <ion-icon name="heart"></ion-icon>
            {{ clip.votes }}
          </div>
          <div class="ion-float-left">
            <ion-icon name="layers-outline"></ion-icon>
            {{ clip.numStacks }}
          </div>
        </div>
      </ion-label>
    </ion-item>

    <div *ngIf="clip.projectId" class="project-details">
      {{ 'COMMON.PROJECT' | translate }}:
      <ion-button class="btn-link-inline" (click)="openProject($event, clip.projectId)">{{ projectTitle$ | async }}</ion-button>
    </div>
  </div>
  <!--/end details -->

  <div *ngSwitchCase="'transcript'" class="transcript">
    <ion-item class="ios-no-padding">
      <ion-label>Language</ion-label>
      <ion-select [(ngModel)]="language">
        <ion-select-option value="en">English</ion-select-option>
      </ion-select>
    </ion-item>
    <p class="clip-subtitle">{{ clip.transcript }}</p>
  </div>
  <!--/end transcript -->
</div>
<!--/end segments -->

<div class="additional" *ngIf="clip">
  <div class="tags">
    <ion-chip *ngFor="let tag of tags">
      <ion-label>{{ tag }}</ion-label>
    </ion-chip>
  </div>

  <!--   <clip-detail-actions
      [clip]="clip" 
      [currentPage]="currentPage"
      (deleteClip)="onDeleteClip($event)"
      (close)="close($event)"
  ></clip-detail-actions> -->
</div>

<ng-template #noClip>
  <p>{{ 'ERRORS.NO_CLIP_FOUND' | translate }}</p>
</ng-template>
