/** @format */

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';

import { CoreApiModule } from './api/core-api.module';

/*
  Core Services
*/
import { UpdatesService } from './services/updates.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { GoogleAnalyticsService } from './services/analytics/google-analytics.service';
import { PinpointService } from './services/analytics/pinpoint.service';
import { SentryService } from './services/analytics/sentry.service';
import { AlertService } from './services/alert.service';
import { ClipsCoreService } from './services/clips.service';
import { EnvironService } from './services/environ.service';
import { ExploreService } from './services/explore.service';
import { FlagService } from './services/flag.service';
import { MyStackService } from './services/mystack.service';
import { PublishStackService } from './services/publish-stack.service';
import { QuestionsService } from './services/questions.service';
import { StacksService } from './services/stacks.service';
import { ThemeService } from './services/theme.service';
import { ToasterService } from './services/toaster.service';
import { UserService } from './services/user.service';
// import { VideoService } from './services/video.service';
import { YoutubeService } from './services/youtube.service';
import { EventsService } from './services/events.service';
// import { ListsService } from './services/lists.service';
import { SocialSharingModule } from './social-sharing/social-sharing.module';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, IonicModule, CoreApiModule],
  declarations: [],
  providers: [
    AuthGuard,
    NoAuthGuard,
    UpdatesService,
    AnalyticsService,
    GoogleAnalyticsService,
    PinpointService,
    SentryService,
    AlertService,
    ClipsCoreService,
    EnvironService,
    ExploreService,
    FlagService,
    MyStackService,
    PublishStackService,
    QuestionsService,
    StacksService,
    ThemeService,
    ToasterService,
    UserService,
    // VideoService,
    YoutubeService,
    EventsService,
    // ListsService,
    SocialSharingModule,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
