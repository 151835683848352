<div class="content" [class]="{ 'content--shown': isShown }">
  <div class="container">
    <div class="text">
      {{ 'POLICIES.CONSENT_BANNER' | translate }}
      <a [routerLink]="['/privacy']">{{ 'COMMON.LEARN_MORE' | translate }}</a>
    </div>
    <div class="button">
      <ion-button fill="clear" (click)="accept.emit('accept')">{{ 'COMMON.ACCEPT' | translate }}</ion-button>
    </div>
  </div>
</div>
