<ion-button #uploadBtn clipUpload 
  class="upload-btn" 
  [class.hidden]="!isShowing"
  [title]="'FILE_UPLOAD.UPLOAD_CLIPS' | translate"
  [projectId]="projectId"
  [userId]="userId$ | async"
  (uploadDone)="onUploadDone($event)"
  (fileUploadFinished)="onFileUploadFinished($event)"
  (fileUploadFailed)="onFileUploadFailed($event)"
  [disabled]="true"
>
  {{ 'FILE_UPLOAD.UPLOAD_CLIPS' | translate }}
  <ion-icon slot="end" name="cloud-upload-outline"></ion-icon>
</ion-button>
