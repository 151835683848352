/**
 * This is the "Drop-in Script" option, which is only for apps that
 * - You do not have a user authentication system at your end and you rely on Chargebee for providing it.
 * https://www.chargebee.com/checkout-portal-docs/checkout.html#via-drop-in-script
 * @format
 */

import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { State } from '@billing/store/billing.reducer';
import { ChargebeeCustomer, ChargebeePlanId } from '@billing/shared/billing.model';
import { createCustomer } from '@billing/store/billing.actions';
import { selectCustomer } from '@billing/store/billing.selectors';
import { BillingService, OpenCheckoutParams } from '@billing/shared/services';
// import { OpenCheckoutParams } from '@billing/shared/services/chargebee.service';
import { CheckoutModalComponent } from '../checkout-modal/checkout-modal.component';
import { getSuccessPath } from '@onboarding/shared/onboarding.model';

const DEBUG_LOGS = false;

@Component({
  selector: 'fs-checkout-button',
  templateUrl: './checkout-button.component.html',
  styleUrls: ['./checkout-button.component.scss'],
})
export class CheckoutButtonComponent implements OnDestroy {
  @Input() planId: ChargebeePlanId = ChargebeePlanId.Free;
  @Input() priceId = '';
  @Input() referralCode = '';
  @Input() routeId = '';
  @Input() disabled = false;
  @Input() projectId = '';
  @Input() projectTitle = '';
  @Input() eventDate = '';

  @Output() completed = new EventEmitter<{ successPageId: string; customer: ChargebeeCustomer }>();
  @Output() errored = new EventEmitter<string>();

  processing = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private billingService: BillingService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Decide if we need to show the modal or allow default Chargebee interface which allows more interaction
   */
  modalRequired(): boolean {
    try {
      // we know the chargebee popup will not work in ios
      if (this.platform.is('ios')) {
        return true;
      }
      // todo: test if this works on Android
      // if (this.platform.is('android')) { return true?}

      /**
       * We can check if popups are blocked with teh toString method, which return [native code] as a body of that function.
       * We'll use use ''+ to convert function toString.
       * When you convert some native function toString, function content is presented as [native code].
       * https://stackoverflow.com/a/31299484
       */
      const popupsBlocked = ('' + window.open).indexOf('[native code]') === -1;
      if (popupsBlocked) {
        return true;
      }

      return false;
    } catch (error) {
      console.warn(error);
      return true;
    }
  }

  /**
   * Chargebee Hosted Pages Modal does not work on iOS - TBD if it does not work on Android
   * We can open in Modal, but...
   * @todo how to determine if popups are blocked in a non-iOS browser?
   * https://support.chargebee.com/support/solutions/articles/256122-hosted-checkout-not-loading-on-safari-mobile-browser
   */
  continue() {
    console.log('continue', {
      planId: this.planId,
      priceId: this.priceId,
      referralCode: this.referralCode,
      projectId: this.projectId,
      projectTitle: this.projectTitle,
      eventDate: this.eventDate,
    });
    this.processing = true;
    // allow to reset if after awhile
    setTimeout(() => {
      this.processing = false;
    }, 4000);

    try {
      const onCompleted$ = new Subject();
      const stopConditions$ = merge(this.onDestroy$, onCompleted$);
      // .pipe(map((con) => {console.warn('processing Stop Condition'); return con;}));
      this.store
        .select(selectCustomer)
        .pipe(takeUntil(stopConditions$))
        .subscribe((customer) => {
          if (!customer || !customer.id) {
            DEBUG_LOGS && console.log(`createCustomer...`);
            this.store.dispatch(createCustomer());
          } else {
            // this should happen on the response of the above createCustomer since we're in the selectCustomer pipe

            const cb = (msg: string, res: string) => {
              // res = hostedPageID
              console.log(msg, res);
              // we are coming from an event outside of the zone
              this.ngZone.run(() => {
                this.processing = false;
                this.cd.detectChanges();
                onCompleted$.next(true);
                onCompleted$.complete();
                if (msg === 'onSuccess') {
                  this.completed.emit({ successPageId: res, customer });
                } else if (msg === 'onError') {
                  console.log('Caught Error:', res);
                  this.errored.emit(res);
                }
              });
            };
            const payload: OpenCheckoutParams = {
              user_id: customer.id,
              plan_id: this.planId,
              price_id: this.priceId,
              referral_code: this.referralCode,
              ...(this.projectId ? { project_id: this.projectId } : {}),
              ...(this.projectTitle ? { project_name: this.projectTitle } : {}),
              ...(this.eventDate ? { event_date: this.eventDate } : {}),
              successRedirectPath: getSuccessPath(this.routeId),
              onCancel: (res: string) => cb('onCancel', res),
              onError: (res: string) => cb('onError', res),
              onSuccess: (res: string) => cb('onSuccess', res),
            };
            DEBUG_LOGS && console.log(`customer:`, { payload, customer });

            if (this.modalRequired()) {
              // this appears to need callbacks... and does work in mobile?!!
              // this.openCheckoutModal(payload);

              // does not work in mobile..
              this.openCheckout(payload);
            } else {
              // this has callbacks included
              this.openCheckout(payload);
            }
          }
        });
    } catch (error) {
      console.log(`openCheckout caught`, error);
    }
  }

  /** this is necessary for mobile iOS (Android TBD) */
  async openCheckoutModal(payload) {
    const {
      user_id: userId,
      plan_id: planId,
      price_id: priceId,
      referral_code: referralCode,
      project_id: projectId,
      project_name: projectTitle,
      event_date: eventDate,
      successRedirectPath,
    } = payload;
    const modal = await this.modalCtrl.create({
      component: CheckoutModalComponent,
      componentProps: {
        userId,
        planId,
        priceId,
        referralCode,
        projectId,
        projectTitle,
        eventDate,
        successRedirectPath,
      },
      cssClass: 'fullheight-iframe-modal',
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      console.log('CheckoutModal dismissed:', data);
      // here we need to check the hostedPage result and redirect? it doesn't seem so...
      // this.billingService.updateBillingInfoWithHostedPage(hostedPageId);
      // onSuccess(hostedPageId);
    }
  }

  openCheckout(payload: OpenCheckoutParams) {
    this.billingService.openCheckout(payload);
  }
}

/*
  // the Chargebee "Drop-in Script" solution does not allow us to use our own Auth
  // also, we need to allow User Click to initiate the portal in order for mobile to function...
  @Input() triggerClick: Subject<number>;
  @Input()
  set triggerClick(i) {
    this.clickLink();
  }
  @ViewChild('linkButton') linkButton: ElementRef;
  public get linkElement() {
    return this.linkButton && this.linkButton.nativeElement ? this.linkButton.nativeElement : {};
  }
  ngOnInit() {
    this.triggerClick.pipe(takeUntil(this.onDestroy$)).subscribe((i) => {
      DEBUG_LOGS && console.log(`checkout click triggered`, i);
      this.clickLink();
    });
  ngAfterViewInit() {
    this.billingService.rebindElements();
  clickLink()
    this.linkElement.click();
*/
