<div class="explore-ui-wrap">
  <div *ngIf="helpText" class="help-text">
    {{ helpText }}
  </div>

  <app-explore-current-clip *ngIf="showCurrentClip" [currentClip]="currentClip$ | async"></app-explore-current-clip>

  <app-explore-path-list
    [drawerId]="drawerId"
    [currentClip]="currentClip$ | async"
    (newPlaylist)="onNewPlaylist($event)"
  ></app-explore-path-list>
  <!-- if we want to show the currentStack within the panel:
    [scroller]="scroller" 
    [currentStack]="currentStack$ | async"
    [currentPlaylistIndex]="currentPlaylistIndex$ | async"
    [currentPlaylist]="currentPlaylist$ | async"
  -->
</div>
