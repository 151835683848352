/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { State } from '@store/reducers';
import * as stackActions from '@store/actions/stacks.actions';
import { List, selectList, selectStackListItems } from '@store/selectors/lists.selectors';
import { StackList } from '@shared/models/list.model';
import { FilterEntityTypes } from '@store/selectors/viewstate.selectors';
import { GUIDES_PROJECT } from '@app/app.config';
import { LAYOUT_TYPE } from '@shared/models/layout.model';
import { Store } from '@ngrx/store';

const CAROUSEL_GUIDES_LIST_ID = 'carousel_guides';
const CAROUSEL_GUIDES_HEADING = 'DISCOVER.GUIDES.HEADING';
const CAROUSEL_GUIDES_LABEL = 'DISCOVER.GUIDES.LABEL';
@Component({
  selector: 'fs-carousel-guides',
  templateUrl: './carousel-guides.component.html',
  styleUrls: ['./carousel-guides.component.scss'],
})
export class CarouselGuidesComponent implements OnInit {
  stackList: StackList;
  layoutType = LAYOUT_TYPE;

  @Input() heading: string;
  @Input() label: string;
  @Input() listId = CAROUSEL_GUIDES_LIST_ID;
  @Input() showListIfEmpty = true;

  constructor(private translate: TranslateService, private store: Store<State>) {}

  ngOnInit() {
    this.stackList = {
      id: this.listId,
      label: this.labelWithDefault,
      list$: this.store.select(selectList(this.listId)),
      items$: this.store.select(selectStackListItems(this.listId)).pipe(shareReplay(1)),
    };

    this.store.dispatch(
      stackActions.loadFilteredStacks({
        listId: this.listId,
        filters: {
          // : FilterEntity
          projectId: GUIDES_PROJECT,
          type: FilterEntityTypes.Featured,
          id: this.listId,
          q: null,
        },
      })
    );
  }

  get labelWithDefault() {
    return this.label ?? this.translate.instant(CAROUSEL_GUIDES_LABEL);
  }

  get headingWithDefault() {
    return this.heading ?? this.translate.instant(CAROUSEL_GUIDES_HEADING);
  }

  loadMoreStacks(list: List) {
    this.store.dispatch(
      stackActions.loadMoreFilteredStacks({
        listId: list.id,
        filters: list.filters,
        nextToken: list.nextToken,
      })
    );
  }
}
