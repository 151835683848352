import { Directive, HostListener, HostBinding } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[imageFadeIn]",
})
export class ImageFadeInDirective {
  @HostBinding("style.opacity") opacity = "0";
  @HostBinding("style.transition") transition = "opacity 0.4s ease-in-out";
  @HostListener("load") onLoaded() {
    this.opacity = "1";
  }
}
