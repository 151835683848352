<!-- <div *ngIf="loading" class="ion-text-center">
  <ion-spinner></ion-spinner>
  <p class="ion-text-center">
    <ion-text color="tertiary"> Loading... </ion-text>
  </p>
</div> -->

<div *ngIf="errorMessage" class="pathway-error ion-text-center">
  <p>
    {{ errorMessage }}
  </p>

  <ion-button fill="outline" (click)="newPath()" title="Get A New Storyline">
      <ion-icon slot="start" name="refresh"></ion-icon>
      New Storyline
  </ion-button>
</div>


<ion-card *ngIf="!errorMessage"
  mode="md" 
  color="dark" 
  class="path-item-wrap" 
  (click)="onPlayPath($event)">

  <div class="poster-overlay">
    <div *ngIf="duration" class="duration">
      {{ duration | duration }}
    </div>
    <div class="aspect-ratio wrap-flex">
      <ion-button fill="clear" size="large" expand="full" class="poster-play" title="Watch" (click)="onPlayPath($event)">
        <ion-icon slot="icon-only" name="play-sharp"></ion-icon>
      </ion-button>
      <div class="path-num">
        {{ pathNum }}
        <span class="circle"></span>
      </div>
    </div>
  </div>
  <img [src]="poster" />
  
  <ion-card-header *ngIf="showTitleDetails">
    <ion-card-subtitle class="truncate">{{ subTitle }}</ion-card-subtitle>
    <ion-card-title class="truncate">{{ title }}</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    {{ content }}

    <div class="chip-wrap">
      <ion-chip *ngFor="let chip of tags">
        <ion-label>{{ chip }}</ion-label>
      </ion-chip>
    </div>

  </ion-card-content>
</ion-card>
