/**
 * Model for Tokens
 *
 * @format
 * @note Re-send Invitation -> replace invites for that same user/feature combo (delete them)
 */

import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';
import { PROJECT_MEMBER_ROLE } from '@members/shared/project-member.model';
import { OnboardingTypes } from '@onboarding/shared/onboarding.model';

export const TOKEN_QUERY_PARAM = 'invite'; // 'token';
export const TOKEN_QUERY_PARAM_OLD = 'token'; // keeping until all invites prior to 06 Feb 2023 are used or cleared

export enum TokenErrors {
  Invalid = 'Invalid Token Id',
  Unknown = 'Unknown Error',
}

export enum TokenActions {
  ProjectInvite = 'Invite To Project',
  PersistentInvite = 'QR Invite',
  RequestToJoin = 'Request to Join',
}

export enum EventType {
  Events = 'EVENT',
  // Festivals = 'FESTIVAL',
  Projects = 'PROJECT',
  Weddings = 'WEDDING',
}

/**
 * Add days to today
 * @param days number to add (default 30)
 * @param from current date string (default now)
 * @returns ISO Date string
 */
export const getExpires = (days = 30, from = null): string => {
  const date = from ? new Date(from) : new Date(); // Now
  date.setDate(date.getDate() + days); // Set now + 30 days as the new date
  return date.toISOString();
};
/** Check if Token is past the expire date, or if not isActive  */
export const isTokenExpired = (token: Token): boolean => {
  if (!token || !token.isActive) {
    // if no token or not active -> expired
    return true;
  }
  if (token.isActive && !token.expires) {
    // if active and no expires date -> NOT expired
    return false;
  }
  const now = new Date().toISOString();
  // if now is past expires -> expired
  return now > token.expires;
};

export const getReturnUrl = (projectId) => `/${PROJECT_DETAIL_ROUTE}/${projectId}`;

export const getEventTypeFromOnboarding = (s: OnboardingTypes | string): EventType => {
  switch (s) {
    case OnboardingTypes.Weddings:
      return EventType.Weddings;
    case OnboardingTypes.Teams:
    case OnboardingTypes.Projects:
      return EventType.Projects;
    case OnboardingTypes.Pro:
    case OnboardingTypes.None:
    default:
      return EventType.Events;
  }
};

/** @deprecated use Token.projectRole */
export interface InviteProjectRole {
  role: PROJECT_MEMBER_ROLE;
  projectId: string;
}

export class TokenPayload {
  returnUrl?: string;
  acceptUrl?: string;
  message?: string;
  tokenId?: string; // to hold parent's info
  /** @deprecated use Token.projectRole */
  // projectRoles?: InviteProjectRole[];
  /** @deprecated implement EventType in parent Token */
  eventType?: EventType | string;
  /** the message to show on the accept page */

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

export class Token {
  id: string; // PK
  projectId?: string; // INDEX: by project
  projectRole?: PROJECT_MEMBER_ROLE;
  /** @todo createdBy - no userId in name */
  createdBy: string; // INDEX: user that sent the token

  isActive?: boolean;
  expires?: string; // ISO datetime
  // expiresOn?: string; // use expires
  eventType?: EventType | string; // might we query on this?

  fromEmail?: string; // user that sent the token
  fromName?: string; // name that the invitee might recognize

  sentToEmail?: string; //  index on email
  sentToUserId?: string; // keep the sentToUserId if we know it, but no index
  // email?: string; // possible index - use sentToEmail instead
  action: TokenActions; // action that token represents
  payload: TokenPayload = {}; // payload will include what do when accepted
  accepted?: boolean; // we will delete the entry when accepted
  persistToken?: boolean; // do not delete token when applied (allow for re-use)

  returnUrl?: string;
  success?: boolean;
  tokenApplied?: boolean;

  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;

  error?: TokenErrors;
  eventId?: string; // do we ever need to query by eventId ? ...i think not
  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}
