<ng-container *ngIf="item; then hasItem; else hasNoItem"> </ng-container>

<ng-template #hasItem>
  <div *ngIf="item.userId?.length > 0 else hasNoItem"
    class="user-slide community-user" 
    [class.user-slide__selected]="item.userId === (currentMemberId$ | async)"
    (click)="onClick($event, item)">
    <div class="user-slide__content">
      <ion-avatar class="user-slide__avatar" draggable="false">
        <img
          class="user-slide__avatar__image"
          [src]="item.userId | avatarUrl:'dark'"
          [fallback]="avatarFallback"
          imageFadeIn
          [alt]="item.username ?? item.userId"
          [title]="item.username ?? item.userId"
          draggable="false"
        />
      </ion-avatar>
      <div class="user-slide__name">
        {{ item.username || item.userId }}
      </div>
      <div *ngIf="showProjects && item.projects?.length > 0" class="user-slide__projects" title="{{ 'COMMON.PROJECTS' | translate }}">
        <ion-icon name="folder-outline" class="no-pointer-events"></ion-icon>
        <span class="project-count">{{ item.projects?.length ?? 0 }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #hasNoItem>
  <div class="user-slide">
    <ion-spinner class="loading-spinner"></ion-spinner>
  </div>
</ng-template>
