/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromBillingReducers from './billing.reducer';
import { BillingEffects } from './billing.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBillingReducers.featureKey, fromBillingReducers.reducer),
    EffectsModule.forFeature([BillingEffects]),
  ],
  declarations: [],
})
export class BillingStoreModule {}
