/** @format */

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { EventsService, EventActions } from '@services/events.service';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { getUserInteractedWithDom } from '@store/selectors/user.selectors';
import * as stackActions from '@store/actions/stacks.actions';
import { Clip } from '@shared/models/clip.model';
import { Stack } from '@shared/models/stack.model';
import { Project } from '@projects/shared/project.model';

const DEBUG_LOGS = false;

/**
 * Detect Autoplay
 * https://stackoverflow.com/questions/7120703/how-do-i-detect-if-the-html5-autoplay-attribute-is-supported
 *
 * this is rather old - always causes error in safari
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
const supports_video_autoplay = (callback) => {
  if (typeof callback !== 'function') return false;
  try {
    const v = document.createElement('video');
    if (v) {
      v.pause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const p: any =
        'play' in v &&
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        v.play().catch((e) => {
          DEBUG_LOGS && console.log('caught no autoplay');
        });
      return callback(!v.paused || p instanceof Promise);
    }
    return callback(false);
  } catch (e) {
    console.log(e);
    return callback(false);
  }
  // "AbortError" only for Safari
  // https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
  // let promise = document.querySelector('video').play();
  // if (promise !== undefined) {
  //     promise.catch(error => {
  //         // Auto-play was prevented
  //         // Show a UI element to let the user manually start playback
  //     }).then(() => {
  //         // Auto-play started
  //     });
  // }
};

const PAGE = '[VideoPlayer]';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() playerId: string = 'fsr_player';
  @Input() autoplay: boolean = true;
  @Input() handlePlaylistDone: boolean = true;
  @Input() showOverlayPlay: boolean = true;
  @Input() fullscreen: boolean = false;
  @Input() showControls: boolean = true;
  @Input() useTrimmerControls: boolean = false;
  @Input() showRestart: boolean = false;
  /** @todo for dev, to be removed */
  @Input() doHlsDestroy: boolean = true;

  @Input() project: Project;

  @Input('stack')
  get stack(): Stack {
    return this._stack;
  }
  set stack(input: Stack) {
    DEBUG_LOGS && console.log(`${PAGE} set stack`, input);
    // this.showOverlay = false;
    if (input && this._stack && this._stack.stackId !== input.stackId) {
      this._currentIndex = 0;
    }
    this._stack = input;
    // this.isHLS = (input && input.hlsSrc && input.hlsSrc.length > 0);
  }

  @Input('playlist')
  get playlist(): Clip[] {
    return this._playlist;
  }
  set playlist(clips: Clip[]) {
    this.showOverlay = false;
    this._playlist = Array.isArray(clips) ? clips : [];
    DEBUG_LOGS && console.log(`${PAGE} set playlist, length = ${this._playlist.length}`);
    if (typeof this._currentIndex === 'number' && this.playlist.length > this._currentIndex) {
      this.currentClip = this.playlist[this._currentIndex];
    }
  }

  @Input('currentIndex')
  get currentIndex(): number {
    return this._currentIndex;
  }
  set currentIndex(index: number) {
    DEBUG_LOGS && console.log(`${PAGE} set currentIndex`, index);
    this.showOverlay = false;
    this._currentIndex = typeof index === 'number' ? index : 0;
    if (!Array.isArray(this.playlist) || this.playlist.length < 1) return;
    if (this.playlist.length <= this._currentIndex) {
      console.warn(
        `${PAGE} playlist.length (${this.playlist.length}) <= currentIndex (${this.currentIndex})? - using last`
      );
      this.currentClip = this.playlist[this.playlist.length - 1];
    } else {
      this.currentClip = this.playlist[this._currentIndex];
    }
  }

  @Output() videoDone = new EventEmitter<boolean>();
  @Output() playlistDone = new EventEmitter<boolean>();
  @Output() videoPlaying = new EventEmitter<boolean>();
  @Output() loadedMetadata = new EventEmitter<boolean>();
  @Output() playerReady = new EventEmitter<boolean>();

  currentClip: Clip;
  /**
   * Check if user has interacted with the browser Document
   * Avoid NotAllowedError OnPlay
   */
  didInteract$ = this.store.select(getUserInteractedWithDom);

  /*
   JIRA MVP-156
    If orientation changes, check didInteract Observable<boolean>
    if did, go Fullscreen for Landscape
    if not, should we hide everything but video with css? (that's what currently is happening)

    + MVP-826 bug = tabs are showing on landscape 
  */

  isSafari: boolean = false;
  screenWidth: number;
  screenHeight: number;
  videoHeight: string = '200px';
  videoWidth: string = '100%';
  loading: boolean = true;
  loadingText = 'Loading...';
  showOverlay = false;

  // interacted with DOM
  // private _interactedWithDom = false;

  private _stack: Stack;
  private _playlist: Clip[] = [];
  private _currentIndex: number = 0;

  private inputAutoplay = true;
  private subscriptions: Subscription = new Subscription();
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private events: EventsService,
    // private toaster: ToasterService,
    // private mystackService: MyStackService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.inputAutoplay = this.autoplay;

    // // track interaction with DOM, to allow fullscreen toggle or autoplay...
    // this.store.pipe(
    //   select(getUserInteractedWithDom),
    //   takeUntil(this.onDestroy$),
    //   filter(Boolean),
    //   take(1),
    // ).subscribe((interacted) => {
    //   // console.log(`${PAGE} onPlayerReady autoplay: ${this.autoplay} but we need a DOM interaction first, please?`);
    //   if (interacted) {
    //     this._interactedWithDom = true;
    //   }
    // });

    const subResize = this.events.subscribe(EventActions.RESIZE, this.onResize);
    this.subscriptions.add(subResize);

    this.platform.ready().then(() => {
      this.screenHeight = this.platform.height();
      this.screenWidth = this.platform.width();
    });

    setTimeout(() => {
      // if (!this.currentClip || !this.currentClip.id) {
      this.loading = false;
      this.loadingText = 'Oops! there was a problem loading this Story.';
      // }
    }, 4000);
  }

  ngAfterViewInit() {
    this.checkSupportsAutoplay();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.subscriptions.unsubscribe();
  }

  onPlayerReady(event) {
    DEBUG_LOGS && console.log(`${PAGE} onPlayerReady -> updatePlayerDimensions`);
    this.updatePlayerDimensions();
    this.playerReady.emit(event);
  }

  onLoadedMetadata(event) {
    DEBUG_LOGS && console.log(`${PAGE} onLoadedMetadata...`);
    this.loadedMetadata.emit(event);
  }

  onVideoEnded(event: boolean) {
    DEBUG_LOGS && console.log(`${PAGE} onVideoEnded -> check playlist (ended=${event})`);
    if (this.handlePlaylistDone && Array.isArray(this.playlist) && this.currentIndex >= this.playlist.length - 1) {
      DEBUG_LOGS && console.log(`${PAGE} DEVPLAYER onVideoEnded playlist DONE - Handling`);
      // handle Playlist DONE - but note the inputs change quicker than the event,..
      this.showOverlay = true;
      this.videoDone.emit(true);
      this.playlistDone.emit(true);
    } else {
      this.videoDone.emit(true);
    }
  }

  updatePlayerDimensions() {
    DEBUG_LOGS && console.log(`${PAGE} updatePlayerDimensions...`);
    const screenHeight = this.screenHeight || this.platform.height();
    const screenWidth = this.screenWidth || this.platform.width();
    let minHeight = screenHeight;
    if (!this.fullscreen) {
      // Math.round((value/16)*9)
      const height16x9 = Math.round((screenWidth / 16) * 9),
        videoHeight = Math.round(screenHeight / 2);
      // console.log(`${PAGE} screenWidth: ${screenWidth} height16x9: ${height16x9}`);
      //TODO: note good prog test... which is smaller?
      minHeight = videoHeight < height16x9 ? videoHeight : height16x9; //height16x9 + "px";
      this.videoWidth = Math.round(minHeight * (16 / 9)) + 'px';
      DEBUG_LOGS &&
        console.log(`${PAGE}(${this.playerId}) updatePlayerDimensions`, {
          screenHeight,
          videoHeight,
          height16x9,
          minHeight,
        });
    } else {
      DEBUG_LOGS &&
        console.log(`${PAGE}(${this.playerId}) updatePlayerDimensions using full screenHeight: ${screenHeight}`);
    }

    this.videoHeight = minHeight + 'px'; //height16x9 + "px";
    // this.events.publish('player:video:height', { playerId: this.playerId, cssHeight: this.videoHeight });
  }

  onResize = ({ width, height }) => {
    DEBUG_LOGS && console.log(`${PAGE} onResize`, { width, height });
    this.screenHeight = height;
    this.screenWidth = width;
    this.updatePlayerDimensions();
  };

  checkSupportsAutoplay() {
    // isSafari
    // console.log(this.platform.platforms());// core for desktop web, but we need to know if safari..
    if (
      navigator &&
      navigator.userAgent &&
      typeof navigator.userAgent.search == 'function' &&
      navigator.userAgent.search('Safari') >= 0 &&
      navigator.userAgent.search('Chrome') < 0
    ) {
      this.isSafari = true; // passed to child for handling
    }
    if (this.inputAutoplay) {
      // parent wants to

      if (this.isSafari) {
        console.log(`${PAGE} onPlayerReady autoplay: ${this.autoplay} but safari says no`);
        // this.autoplay = false; // don't set this here, it messes up the logic in the actual player
      } else {
        DEBUG_LOGS && console.log(`${PAGE} checkSupportsAutoplay`);
        // chrome / firefox
        // handle errors NotAllowedError for Chrome And Firefox
        setTimeout(() => {
          // throw it off the main thread...
          supports_video_autoplay((supported) => {
            console.log(supported ? 'video autoplay supported!' : 'no video autoplay support :(');
            this.autoplay = supported;
            // this.readyToPlay = supported;
          });
        }, 300);
      }
    } else {
      // don't autoplay if parent says not to
      this.autoplay = false;
      DEBUG_LOGS && console.log(`${PAGE}  checkSupportsAutoplay: parent says not to`);
    }
  }

  onVideoPlaying(isPlaying: boolean) {
    this.videoPlaying.emit(isPlaying);
  }

  /**
   * This captures every click on the wrapper div for the overlay - it's not used right now, but could be... or removed
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onOverlayClick(event: MouseEvent) {
    // DEBUG_LOGS && console.log(`${PAGE} onOverlayClick... `, event);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWatchAgain(event: boolean) {
    this.store.dispatch(stackActions.playCurrentIndex({ index: 0, isPlayingAgain: 1 }));
  }
}
