/** @format */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HOMEPAGE } from '@app/app.config';

const DEBUG_LOGS = false;
const PAGE = '[CommunityRules]'; // eslint-disable-line @typescript-eslint/no-unused-vars

@Component({
  selector: 'app-community-rules',
  templateUrl: './community-rules.page.html',
  styleUrls: ['./community-rules.page.scss'],
})
export class CommunityRulesPage implements OnInit, OnDestroy {
  @Input() isModal: boolean = false;
  @Input() allowClickHome: boolean = true;

  returnUrl = '';
  title: string = 'Community Rules';

  private onDestroy$ = new Subject<void>();

  constructor(
    private navCtrl: NavController,
    private viewCtrl: ModalController,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      DEBUG_LOGS && console.log(`queryParams route:`, this.route);
      if (params?.returnUrl) {
        this.returnUrl = params.returnUrl;
        DEBUG_LOGS && console.log(`queryParams returnUrl:`, this.returnUrl);
      } else if (this.router.getCurrentNavigation()?.extras?.state) {
        this.returnUrl = this.router.getCurrentNavigation().extras.state.returnUrl || '';
        DEBUG_LOGS && console.log(`navState returnUrl:`, this.returnUrl);
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openHomePage() {
    this.dismiss();
    if (this.allowClickHome) {
      this.navCtrl.navigateForward(`/${HOMEPAGE}`);
    }
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
