/** @format */

import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '../base-form.component';

@Component({
  selector: 'fs-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormSelectComponent extends BaseFormComponent {
  @Input() items: { text: string; value: string | number | boolean }[];
  @Input() interface = 'popover';
  @Input() placeholder: string;
  @Input() hint: string = '';
  @Input() disabled: boolean = false;
  @Input() interfaceOptions = {
    side: 'top',
    cssClass: 'form-select',
  };

  constructor(translate: TranslateService) {
    super(translate);
  }
}
