/** @format */

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { SentryService } from '@services/analytics/sentry.service';
import { ToasterService } from '@services/toaster.service';
import { UserService } from '@services/user.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { EnvironService } from '@services/environ.service';
import { ClipsService } from '@clips/shared/services';
import { ProjectService } from '@projects/shared/services';
import { ConfigService } from '@app/core/config';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalComponent } from '@shared/components/ui/modal/base-modal.component';
import { PickerFileError, PickerFileMetadata, PickerUploadDone } from '@app/core/filestack/filestack.service';

const DEBUG_LOGS = false;

@Component({
  selector: 'app-capture-clip-modal',
  templateUrl: './capture-clip-modal.component.html',
  styleUrls: ['./capture-clip-modal.component.scss'],
})
export class CaptureClipModalComponent implements OnInit, OnDestroy {
  @Input() projectId: string = '';
  @Input() showProjectSelect = true;
  @Output() uploadSuccess = new EventEmitter<PickerFileMetadata[]>();

  @ViewChild('modal', { static: false, read: BaseModalComponent })
  modal: BaseModalComponent;
  // @ViewChild('uploadBtn', { static: false, read: IonButton }) uploadBtn: IonButton;
  @ViewChild('uploadBtn', { read: ElementRef }) uploadBtn: ElementRef;

  userId$ = this.userService.userId$;

  isCaptureEnabled = false;
  isCaptureAllowed$: Observable<boolean>;
  isLoading = true;
  showBetaInvite = false; // toggle the beta tester invitation panel
  // selectedProjectId: string = '';
  private _selectedProjectId$: BehaviorSubject<string> = new BehaviorSubject('');
  get selectedProjectId$(): Observable<string> {
    return this._selectedProjectId$.asObservable();
  }

  // private get captureToProjectId (): string {
  //   return this.projectSelected && this.projectSelected.id ? this.projectSelected.id : '';
  // }
  private onDestroy$ = new Subject<void>();

  constructor(
    private toaster: ToasterService,
    private alertCtrl: AlertController,
    private clipsService: ClipsService,
    private projectService: ProjectService,
    private userService: UserService,
    private environService: EnvironService,
    private configService: ConfigService,
    private sentryService: SentryService,
    // private cdref: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // take myProjects and the projectId passed as input
    // choose the projectId to select if projectId input || active || most recent
    // if no projectId, we need to show the project selector

    /**
     * @todo refactor this to billing status
     */
    this.userService.userHasUploadAccess$.pipe(takeUntil(this.onDestroy$)).subscribe((isAllowed) => {
      this.isCaptureEnabled = isAllowed; // !!isAdmin || !!isDeveloper || !!isKickstarter || !!isWeddingPilot || !!isBeta;
      if (!this.isCaptureEnabled) {
        this.showBetaInvite = true;
      }
    });

    /**
     *
     * isCaptureAllowed: appConfig.allowCapture || hasProjects
     * if isLoading then disable capture buttons
     * if !isCaptureAllowed then show tpl joinACrew
     */
    this.isCaptureAllowed$ = this.userService.isCaptureAllowed$.pipe(
      takeUntil(this.onDestroy$),
      map(({ isAllowed, projects }) => {
        // console.log(`isCaptureAllowed?`, { projectId: this.projectId, projects, appConfig, userId, activeProjectId })
        // note: this gets called ~3x times due to getMyProjects returning multiple times as it gets all your projects & memberProjects
        this.isLoading = false;
        if (!isAllowed) {
          this.showBetaInvite = true;
        } else if (this.projectId) {
          if (!this.showProjectSelect) {
            // console.log(`!showProjectSelect do we care?`);
            // if !showProjectSelect then the selector never loads therefore does not trigger change
          }
          // since hasProjects we could assume that's the selectedOne since the parent manages display
          const exists = projects.find((p) => p && p.id === this.projectId);
          if (exists) {
            try {
              //click the button here?
              // this.uploadBtn.nativeElement.click();

              // causing ExpressionChangedAfterItHasBeenCheckedError
              this.projectChanged({ id: this.projectId });
            } catch (error) {
              console.warn(`Caught Error:`, error);
            }
          }
        }
        return isAllowed;
      })
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Project was selected, update with this value
   */
  projectChanged(project) {
    if (project && project.id) {
      // && !this.isLoading
      this._selectedProjectId$.next(project.id);
      DEBUG_LOGS && console.log(`selectedProjectId:`, project.id);

      // set the activeProject in EnvironStore to save it for next Capture
      // this.environService.setActiveProjectId(event.target.value.id);
    }
  }

  async showProjectAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Filmstacker',
      subHeader: 'Please select a Project',
      message: `Clips must be uploaded to a Project, please select one and try again.`,
      buttons: ['Ok'],
    });
    return await alert.present();
  }

  /**
   * Filestack is done with all
   * {
   *    filesFailed: PickerFileMetadata[],
   *    filesUploaded: PickerFileMetadata[]}
   * }
   */
  onFileUploadDone(res: PickerUploadDone) {
    try {
      if (!res) {
        console.error(`onFileUploadDone no filestackResult?!?`);
        this.sentryService.captureMessage(`onFileUploadDone no filestackResult?`);
        throw new Error(this.translate.instant('ERRORS.CLIP_CREATE'));
      }

      const { filesFailed = [], filesUploaded = [] } = res;
      if (filesFailed.length > 0) {
        this.toaster.present(`There might have been an issue uploading ${filesFailed.length} of the clips...`);
      }

      // do we need a delay here to await the actions being done?
      interval(300)
        .pipe(take(1))
        .subscribe(() => {
          this.uploadSuccess.emit(filesUploaded);
          this.modal.dismiss({ role: 'success', data: filesUploaded });
        });
    } catch (error) {
      if (error && error.message) {
        this.toaster.present(error.message);
      }
    }
  }

  /**
   * Filestack is done with one or more
   * @see capture.page original implementation
   */
  onFileUploadFinished(res: PickerFileMetadata) {
    try {
      if (!res) {
        console.error(`onFileUploadFinished no filestackResult?!?`);
        this.sentryService.captureMessage(`onFileUploadFinished no filestackResult?`);
        throw new Error(this.translate.instant('ERRORS.CLIP_CREATE'));
      }

      this.selectedProjectId$.pipe(take(1)).subscribe((selectedProjectId) => {
        if (!selectedProjectId) {
          console.info(`[CaptureClip] NO captureToProject ('${selectedProjectId}'), use personal studio?`);
          this.showProjectAlert();
          throw new Error(''); // don't show toaster here, alert instead
        }
        this.userId$.pipe(take(1)).subscribe((userId) => {
          this.clipsService.createClipFromFilestack(res, selectedProjectId, userId);
        });
      });
    } catch (error) {
      if (error && error.message) {
        this.toaster.present(error.message);
      } else {
        this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
      }
    }
  }

  onFileUploadFailed(res: PickerFileError) {
    console.log('### fileUploadFailed', res);
    this.sentryService.captureError(`Filestack onFileUploadFailed`);
    if (res && res.error.message) {
      this.toaster.present(res.error.message);
    } else {
      this.toaster.present(this.translate.instant('ERRORS.GENERIC_OOPS'));
    }
  }
}
