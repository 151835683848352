/** @format */

import { Component, Input, OnChanges, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Platform, ModalController, InputCustomEvent, TextareaCustomEvent } from '@ionic/angular';
// import { Diagnostic } from '@awesome-cordova-plugins/diagnostic'; // TODO: native
// import { Geolocation } from '@awesome-cordova-plugins/geolocation'; // TODO: native
import { catchError, filter, take } from 'rxjs/operators';
import { UpdateParam } from '@app/core/api/api-types';
import { UserService } from '@services/user.service';
import { VideoService } from '@services/video.service';
import { AppConfig, ConfigService } from '@app/core/config';
import { ToasterService } from '@app/core/services/toaster.service';
import { ClipsCoreService } from '@app/core/services/clips.service';
import { Clip, MAX_DESC_LENGTH } from '@shared/models/clip.model';
import { Utils } from '@shared/utils';
// modals
import { PrivacyPage } from '@app/pages/privacy/privacy.page';
import { TermsPage } from '@app/pages/terms/terms.page';
import { Project } from '@projects/shared/project.model';
import { ProjectService } from '@projects/shared/services/index';

const DEBUG_LOGS = false;
const DEV_NO_SAVE = false; // stop the save process for dev'ing

const PAGE = '[ClipSettings]';

/**
 * Clip Title and Description should be html safe
 */
const VALIDATE_SAFE_PATTERN = /^[a-zA-Z0-9,._\-\?!@#$%^&*[\]|\(\)–+=’\"\'{}:;\s]*$/;
const VALIDATE_SAFE_PATTERN_MULTILINE = /^[a-zA-Z0-9,._\-\?!@#$%^&*[\]|\(\)–+=’\"\'{}:;\r\n\\\/\s]*$/;

declare let google; // geolocation

@Component({
  selector: 'app-clip-settings',
  templateUrl: './clip-settings.component.html',
  styleUrls: ['./clip-settings.component.scss'],
})
export class ClipSettingsComponent implements OnInit, OnChanges {
  /** @todo reenable tags with refactor */
  enableTags = false;

  @Input() currentUserId: string;
  @Input() clip: Clip;
  @Input() project: Project;

  @ViewChild('map') mapElement: ElementRef;

  public canEdit: boolean = false;

  playerId = 'edit-clip-settings';

  /** type: new google.maps.Map */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map: any;
  mapLoaded: boolean = false;
  mapLoadingCanceled: boolean = false;
  currentLat: string;
  currentLng: string;

  formGroup: UntypedFormGroup;
  submitAttempt: boolean = false;

  maxDescLen: number = MAX_DESC_LENGTH;
  curDescLen: number = 0;
  curTitleLen: number = 0;

  appConfig: Promise<AppConfig>;
  filmingDate;

  clipTitle: string;
  clipDesc: string;
  isPublic: boolean = false;
  clipLanguage: string = 'english';

  tagAutocompleteItems: string[] = [];

  canHlsTranscode = false;
  public isSaving: boolean = false;

  private isPwa = false;
  private isNative = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private platform: Platform,
    private configService: ConfigService,
    private toaster: ToasterService,
    private clipService: ClipsCoreService,
    private projectService: ProjectService,
    private userService: UserService,
    private videoService: VideoService
  ) {
    this.appConfig = this.configService.appConfig;
  }

  ngOnInit() {
    /**
     * Check currentUser Cognito Groups for Admin access to platform
     */
    this.userService.userIsGlobalAdmin$
      .pipe(
        filter((isAdmin) => typeof isAdmin === 'boolean'),
        take(1),
        filter((isAdmin) => isAdmin)
      )
      .subscribe((isAdmin) => {
        console.log(`sub userGroups isAdmin:`, { isAdmin, clip: this.clip });
        /**
         * DEV HLS TRANSCODE MVP-742
         */
        if (this.clip && !this.clip.hlsSrc) {
          this.canHlsTranscode = true;
        }
      });
    // only do this once onInit, not onChanges...
    this.determinePlatform().then(() => {
      DEBUG_LOGS && console.log(`${PAGE} determinePlatform -> setupMap`);
      this.setupMap();
    });

    // currently we are only handling this if project already exists,
    // so may not work if app loaded on this route (project was loaded async)
    // which aligns with TaggerInput.autocompleteItems which is only considered during OnInit
    if (this.project?.tags?.length > 0) {
      // get tags from project, to add to global ALL_TAGS (provided by tagger-input)
      this.tagAutocompleteItems = this.project.tags;
    }
  }

  ngOnChanges() {
    this.checkCanEdit();
    if (this.canEdit) {
      this.getClipDetails();
      this.createForm();
    }
  }

  checkCanEdit() {
    // if there's a clip and userId, check for canEdit permission
    if (this.currentUserId && this.clip && this.clip.userId && this.clip.userId === this.currentUserId) {
      this.canEdit = true;
    } else if (this.projectService.isProjectAdmin(this.project, this.currentUserId)) {
      this.canEdit = true;
    } else {
      this.canEdit = false;
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      titleInput: [
        this.clipTitle,
        Validators.compose([Validators.required, Validators.pattern(VALIDATE_SAFE_PATTERN), Validators.maxLength(140)]),
      ],
      descInput: [
        this.clipDesc,
        Validators.compose([
          Validators.maxLength(this.maxDescLen),
          Validators.pattern(VALIDATE_SAFE_PATTERN_MULTILINE),
        ]),
      ],
      publicViewing: [this.isPublic],
      language: [this.clipLanguage],
      filmingDate: [this.filmingDate],
    });
  }
  onChangeDate(event: Event) {
    const customEvent = event as TextareaCustomEvent;
    if (customEvent?.detail?.value) {
      // have to convert the ion-datetime result to our DB style
      const filmingDate = Utils.getDateTimeString(customEvent.detail.value);
      this.formGroup.patchValue({ filmingDate });
      DEBUG_LOGS && console.log('onChangeDate', { filmingDate, value: customEvent?.detail?.value });
    }
  }

  /**
   * Get Clip Data for Form
   * (why was this async?)
   */
  getClipDetails() {
    if (!this.clip) {
      console.warn(`${PAGE} getClipDetails NO CLIP?`, this.clip);
      return;
    }
    // this.projectId = this.clip.projectId;
    this.clipTitle = this.clip.title;
    this.curTitleLen = this.clipTitle.length;

    /**
     * @todo implement other settings for Clip Privacy
     *
     * isPublic = clip.private == null || undefined || typeof 'boolean' and !private
     */
    this.isPublic = !(typeof this.clip.private === 'boolean' && this.clip.private);

    // let zoneOffset = (new Date()).getTimezoneOffset() * 60000;
    if (this.clip.filmingDate) {
      this.filmingDate = Utils.getDateTimeStringWithOffset(this.clip.filmingDate);
    } else if (this.clip.source && this.clip.source.lastModifiedDate) {
      this.filmingDate = Utils.getDateTimeStringWithOffset(this.clip.source.lastModifiedDate);
      // DEBUG_LOGS && console.log(`${PAGE} filmingDate:`, this.filmingDate);
    } else {
      DEBUG_LOGS && console.log(`${PAGE} NO filmingDate?`, this.clip);
    }

    this.clipDesc = this.clipService.getDescription(this.clip);

    this.curDescLen = this.clipDesc.length;

    if (this.clip.language) {
      this.clipLanguage = this.clip.language;
    }

    this.currentLat = this.clip.geoLat || null;
    this.currentLng = this.clip.geoLng || null;
  }

  save() {
    this.isSaving = true;

    const { clip, isValid } = this.getFormValuesChanged();
    if (!isValid) {
      // if not valid - throw alert and stop save
      console.warn(`${PAGE} save formValues !isValid`, clip);
      this.toaster.present(`Clip Settings Not Valid - please correct the fields and try again.`);
      this.isSaving = false;
      return;
    }
    // @todo handle this.isPublic = !(typeof this.clip.private === 'boolean' && this.clip.private);
    const clipUpdates: UpdateParam[] = [
      ...Object.keys(clip).map((key) => ({
        prop: key,
        value: clip[key],
      })),
    ];

    if (DEV_NO_SAVE) {
      console.log(`${PAGE} save todo: `, { clipUpdates, clipChanges: clip, origClip: this.clip });

      setTimeout(() => {
        this.isSaving = false;
        this.toaster.present(`Updated Clip ${Object.keys(clip).join(', ')}.`);
      }, 1000);
      return;
    }

    DEBUG_LOGS && console.log(`${PAGE} saving: `, { clipUpdates, clipChanges: clip, origClip: this.clip });

    // save changes
    this.clipService
      .updateClip(this.clip, clipUpdates)
      .pipe(
        take(1),
        catchError((err) => {
          console.warn(`updateClip caught`, err);
          throw err;
        })
      )
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.toaster.present(`Save Success: Updated Clip ${Object.keys(clip).join(', ')}.`);
        },
        error: (err) => {
          console.warn(err);
          this.isSaving = false;
          this.toaster.present(`Oops, Clip not updated - please try again.`);
        },
      });
  }

  /**
   * Get the Changed form values
   */
  public getFormValuesChanged(): { clip: Partial<Clip>; isValid: boolean } {
    const controls = this.formGroup.controls;
    const clip: Partial<Clip> = {};
    let isValid = true;
    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        const control = controls[key];
        if (!control.pristine) {
          if (!control.valid) {
            isValid = false;
            DEBUG_LOGS && console.log(`${PAGE} invalid form control: '${key}' value:`, control.value);
          }
          switch (key) {
            case 'titleInput': {
              clip.title = control.value;
              break;
            }
            case 'descInput': {
              clip.description = control.value;
              break;
            }
            case 'publicViewing': {
              DEBUG_LOGS &&
                console.log(`${PAGE}'${key}' value:`, { value: control.value, clip_private: !control.value });
              clip.private = !control.value;
              break;
            }
            case 'language':
            case 'filmingDate': {
              clip[key] = control.value;
              break;
            }
          }
        }
      }
    }
    if (this.currentLat !== this.clip.geoLat) {
      clip.geoLat = this.currentLat;
    }
    if (this.currentLng !== this.clip.geoLng) {
      clip.geoLng = this.currentLng;
    }
    if (this.enableTags) {
      clip.tags = this.clip.tags;
    }

    return { clip, isValid };
  }

  determinePlatform() {
    return this.platform.ready().then((readySource) => {
      if (readySource === 'dom') {
        this.isPwa = true;
        this.isNative = false;
      } else if (readySource === 'cordova') {
        // For native app
        this.isPwa = false;
        this.isNative = true;
      } else {
        console.info(
          `${PAGE} determinePlatform readySource (${readySource}) NOT dom or cordova? platforms:`,
          this.platform.platforms()
        );
        this.isPwa = true;
        this.isNative = false;
      }
      return true;
    });
  }

  setupMap() {
    if (this.isPwa) {
      // For browser
      this.addGoogleScriptTagThenLoadMap();
    } else if (this.isNative) {
      // For mobile app
      this.getLocationAccess();
    } else {
      console.warn(`${PAGE} setupMap platform UNKNOWN`);
    }
  }

  async getLocationAccess() {
    // For mobile app
    if (this.isNative) {
      try {
        this.toaster.present(`Native Diagnotic in DEV...`);
        // // detect if device's location is on or off
        // const data = await this.diagnostic.isLocationEnabled();
        // // if location is turned on
        // if (data == true) {
        //   this.loadMap();
        // }
        // // if location is turned off
        // else if (data == false) {
        //   // prompt user to turned on location
        //   let alert = await this.alertCtrl.create({
        //     header: 'Filmstacker',
        //     message: "To continue, please allow location services for your device.",
        //     buttons: [
        //       {
        //         text: 'Cancel',
        //         role: 'cancel',
        //         handler: () => {
        //           console.log('Cancel clicked');
        //           this.mapLoadingCanceled = true;
        //         }
        //       },
        //       {
        //         text: 'Ok',
        //         handler: () => {
        //           this.retryLocationAccess();
        //         }
        //       }
        //     ]
        //   });
        //   await alert.present();
        // }
      } catch (error) {
        console.warn(error);
      }
    }
  }

  retryLocationAccess() {
    if (this.isNative) {
      this.toaster.present(`Native Diagnotic in DEV...`);
      //// redirect to setting for turned on location
      // this.diagnostic.switchToLocationSettings(); // todo: re-enable

      // // after coming back to app it will load map if location is turned on
      // this.platform.resume.subscribe((e) => {
      //   this.loadMap();
      // })
    } else {
      this.setupMap();
    }
  }

  /**
   * load the G Maps API code asynchronously
   * convert script tag in index.html to only load when needed
   * <script async defer src="https://maps.google.com/maps/api/js?key=AIzaSyBnMwLZWa8o2AmTWJbVfd_bp44IvvgdkA4&callback=initGMap"></script>
   */
  addGoogleScriptTagThenLoadMap(): void {
    // console.log(`${PAGE} addGoogleScriptTag google:`, typeof google);

    if (typeof google === 'undefined' || !google || !google.maps) {
      console.log(`${PAGE} gMaps Adding google.maps script...`);
      if (window) {
        window['fsrGmapsCallback'] = () => {
          console.log(`${PAGE} gMaps ready... mapLoaded?: ${this.mapLoaded}`);
          if (!this.mapLoaded) {
            this.loadMap();
          }
        };
      } else {
        console.warn(`${PAGE} WARN: no Window object...`);
        this.loadMap();
      }

      const tag = document.createElement('script');
      tag.src =
        'https://maps.google.com/maps/api/js?key=AIzaSyBnMwLZWa8o2AmTWJbVfd_bp44IvvgdkA4&callback=fsrGmapsCallback';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      console.log(`${PAGE} gMaps google.maps already exists -> loadMap`);
      this.loadMap();
    }
  }

  loadMap = () => {
    if (this.isNative) {
      console.log(`${PAGE} loadMap TODO: native implementation`);
      this.toaster.present(`Native Load Map implementation in dev...`);
      // TODO: Native
      // this.geolocation.getCurrentPosition().then((position) => {
      //   // console.log("longitude: " + position.coords.longitude);
      //   this.currentLat = position.coords.latitude;
      //   this.currentLng = position.coords.longitude;
      //   this.setMap();
      // }, (err) => {
      //   console.log(err);
      // });
    } else {
      if (this.currentLng && this.currentLat) {
        this.setMap();
      } else {
        this.getCurrentPosition();
      }
    }
  };

  getCurrentPosition() {
    if (navigator && navigator.geolocation) {
      /**
       * Create local functions due to callback having different 'this' context
       * fix: Cannot read property 'toaster' of null
       */
      const positionError = (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.warn('User denied the request for Geolocation.');
            this.mapLoadingCanceled = true;
            break;
          case error.POSITION_UNAVAILABLE:
            console.warn('Location information is unavailable.');
            this.toaster.present(`Location information is unavailable. Please reload the page and try again.`);
            break;
          case error.TIMEOUT:
            console.warn('The request to get user location timed out.');
            this.toaster.present(`The request to get user location timed out. Please reload the page and try again.`);
            break;
          case error.UNKNOWN_ERROR:
            console.warn('An unknown error occurred.', error);
            this.toaster.present(`An unknown location error occurred. Please reload the page and try again.`);
            break;
          default:
            console.warn('An uncaught error occurred.', error);
        }
      };

      const setCurrentPosition = (position) => {
        if (!position || !position.coords) {
          console.warn(`${PAGE} setCurrentPosition !position.coords?`, position);
          return;
        }
        DEBUG_LOGS &&
          console.log(`${PAGE} setCurrentPosition`, {
            accuracy: position.coords.accuracy,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            speed: position.coords.speed,
          });
        this.currentLat = position.coords.latitude;
        this.currentLng = position.coords.longitude;

        this.setMap();
      };

      navigator.geolocation.getCurrentPosition(setCurrentPosition, positionError, {
        enableHighAccuracy: false,
        timeout: 15000,
        maximumAge: 0,
      });
    } else {
      console.warn(`${PAGE} getCurrentPosition !navigator.geolocation`, navigator);
      this.toaster.present(`We were not able to access the geolocation api. Please try reloading the page...`);
    }
  }

  setMap = () => {
    if (typeof google != 'undefined' && google && google.maps) {
      this.mapLoaded = true;
      const latLng = new google.maps.LatLng(this.currentLat, this.currentLng);
      const mapOptions = {
        center: latLng,
        zoom: 6,
        mapTypeId: 'hybrid',
        enableHighAccuracy: true,
        timeout: Infinity,
        gestureHandling: 'cooperative', // Prevents map pan and zoom on page scroll (https://developers.google.com/maps/documentation/javascript/interaction)
      };

      /**
       * SENTRY-HS EXCEPTION:
       * TypeError: undefined is not an object (evaluating 'this.mapElement.nativeElement') at setMap
       */
      if (!this.mapElement || !this.mapElement.nativeElement) {
        console.warn(`WARN: setMap this.mapElement.nativeElement is UNDEFINED`);
        this.toaster.present(`Hmm. We were unable to load the Map. Please refresh to try again.`);
        return;
      }

      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
      this.addMarker();
    }
  };

  addMarker = () => {
    const marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: this.map.getCenter(),
      draggable: true,
    });

    new google.maps.event.addListener(marker, 'dragend', () => {
      this.currentLat = marker.getPosition().lat();
      this.currentLng = marker.getPosition().lng();
      DEBUG_LOGS &&
        console.log(`${PAGE} Marker dragend:`, { currentLat: this.currentLat, currentLng: this.currentLng });
    });
  };

  onTagSelected(item: string) {
    DEBUG_LOGS && console.log(`${PAGE} onTagSelected: %o`, item);
  }
  onTagRemoved(item: string) {
    this.clip.tags = Utils.removeFromArray(this.clip.tags, item);
    DEBUG_LOGS && console.log(`${PAGE} onTagRemoved: %o`, item, this.clip.tags);
  }
  onTagAdded(item: string) {
    Utils.addToArrayUnique(this.clip.tags, item.toLowerCase());
    DEBUG_LOGS && console.log(`${PAGE} onTagAdded: %o`, item, this.clip.tags);
  }

  onChangeTitle(event: Event) {
    const customEvent = event as InputCustomEvent;

    const value = customEvent.detail.value;
    this.curTitleLen = value ? value.length : 0;
  }
  onChangeDesc(event: Event) {
    const customEvent = event as TextareaCustomEvent;

    const value = customEvent.detail.value;
    this.curDescLen = value ? value.length : 0;
  }

  async openPrivacyPolicy() {
    const modal = await this.modalCtrl.create({
      component: PrivacyPage,
      componentProps: {
        isModal: true,
      },
    });
    return await modal.present();
  }

  async openTerms() {
    const modal = await this.modalCtrl.create({
      component: TermsPage,
      componentProps: {
        isModal: true,
      },
    });
    return await modal.present();
  }

  /**
   * DEV HLS TRANSCODE MVP-742
   */
  async confirmTranscode() {
    return await this.videoService.confirmSendClipForHLSTranscode(this.clip);
  }
}
