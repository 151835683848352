/**
 * This has to live in SharedModule since the topnav-drawer needs it 2022-10-10
 * @format
 */

import { Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { take, delay } from 'rxjs/operators';
import { ConfigService } from '@app/core/config';
import { EnvironService } from '@services/environ.service';
import { PublishStackService } from '@services/publish-stack.service';
import { UserService } from '@services/user.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IOrderedClip, StackDraftPublishInput } from '@shared/models/stack.model';
import { IAddToStackDrawerPayload } from '@store/actions/stacks.actions';

const DEBUG_LOGS = false;

@Component({
  selector: 'app-form-stack-create',
  templateUrl: './form-stack-create.component.html',
  styleUrls: ['./form-stack-create.component.scss'],
})
export class FormStackCreate implements OnDestroy {
  @Input() showHeading = true;
  @Input() showSubmit = true;
  @Input() showErrorTextOnError = true;
  // @Input() addToStackPlaylist: IOrderedClip[] = [];
  @Input()
  set addToStackPayload(value) {
    this._addToStackPayload = value;
    const {
      title = '',
      projectId = '',
      // isCreateNew = false,
      // description = '',
      // playlist = [],
      // poster = '',
    } = value;
    if (title) {
      // update the form values
      this.stackCreateForm.patchValue({
        title,
        ...(projectId ? { projectId } : {}),
      });
      this.emitValid();
    }
  }
  get addToStackPayload() {
    return this._addToStackPayload;
  }

  @Output() loading = new EventEmitter<boolean>();
  /** { projectId, stackId, title } */
  @Output() createSuccess = new EventEmitter<{
    projectId: string;
    stackId: string;
    title: string;
    playlist?: IOrderedClip[];
  }>();
  @Output() formValid = new EventEmitter<boolean>();

  stackCreateForm = this.formBuilder.group({
    title: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(64)])],
    projectId: ['', Validators.compose([Validators.required])],
  });

  userId$ = this.userService.userId$;
  isLoggedIn$ = this.userService.isLoggedIn$;

  errorMsg = '';
  isLoading = false;

  public get isFormValid(): boolean {
    return this.stackCreateForm && this.stackCreateForm.valid;
  }

  get addToStackPlaylist(): IOrderedClip[] {
    return this._addToStackPayload?.playlist ?? [];
  }
  get payloadProjectId() {
    return this._addToStackPayload?.projectId ?? '';
  }
  private _addToStackPayload: IAddToStackDrawerPayload = { playlist: [] };
  private onDestroy$ = new Subject<void>();

  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    private configService: ConfigService,
    private environService: EnvironService,
    private publishService: PublishStackService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService
  ) {}

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public checkForm() {
    this.stackCreateForm.markAllAsTouched();
    this.emitValid();
  }

  /**
   * debounced on each change
   */
  titleInput(event) {
    DEBUG_LOGS && console.log(`[StackCreate] titleInput`, { value: event, stackCreateForm: this.stackCreateForm });
    this.emitValid();
  }

  projectChanged(project) {
    DEBUG_LOGS && console.log(`[StackCreate] projectChanged`, { project, stackCreateForm: this.stackCreateForm });
    if (project && project.id) {
      this.stackCreateForm.patchValue({ projectId: project.id });
      this.environService.setActiveProjectId(project.id);
      this.emitValid();
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _loadingTimeout;
  showLoading(timeout = 900) {
    this.isLoading = true;
    this.loading.emit(this.isLoading);
    clearTimeout(this._loadingTimeout);
    this._loadingTimeout = setTimeout(() => {
      // just making it show loading for a bit, since we only dispatched actions here
      this.isLoading = false;
      this.loading.emit(this.isLoading);
    }, timeout);
  }

  /**
   * if there are already clips, save those too
   * if restack, copy our data
   */
  createStack() {
    if (this.stackCreateForm.valid) {
      this.errorMsg = '';
      this.isLoading = true;
      this.loading.emit(this.isLoading);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isCreateNew = false, description = '', poster = '' } = this._addToStackPayload;

      combineLatest([this.userId$, this.userService.username$])
        .pipe(take(1), delay(2000))
        .subscribe(async ([userId, username]) => {
          const input: StackDraftPublishInput = {
            userId,
            credits: username,
            title: this.stackCreateForm.get('title').value,
            projectId: this.stackCreateForm.get('projectId').value,
            // if we already have clips, include those
            playlist: this.addToStackPlaylist?.length > 0 ? this.addToStackPlaylist : [],
          };
          // carry the ReStack details if they exist
          if (description) {
            input.description = description;
          }
          if (poster) {
            input.poster = poster;
          }
          // create the Draft in DB, updating...
          const stack = await this.publishService.createStackDraft(input);

          this.isLoading = false;
          this.loading.emit(this.isLoading);
          /** MVP-1146 */
          this.createSuccess.emit(stack);
        });
    } else {
      this.checkForm(); // show errors, right?
    }
  }

  resetForm() {
    this.stackCreateForm.reset();
    this.emitValid();
  }

  private emitValid() {
    this.formValid.emit(this.stackCreateForm.valid);
  }
}
