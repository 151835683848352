/**
 * 2022-06-29 jd migrated functionality from stacks.reducers MVP-970
 * @format
 */
/* eslint-disable arrow-body-style */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { getCollabPrivacy, Stack } from '@shared/models/stack.model';
// import { State as AppState } from '@store/reducers';
import * as ResetActions from '@store/actions/reset.actions';
import * as StackActions from '@store/actions/stacks.actions';
import * as MystackActions from '@store/actions/mystack.actions';
import * as UserActions from '@store/actions/user.actions';
import { environment } from 'src/environments/environment';
import { Utils } from '@shared/utils';

const DEBUG_LOGS = false;

export const featureKey = 'stacks';

export enum StackGroup {
  Featured = 'FEATURED',
  Recent = 'RECENT',
  Trending = 'TRENDING',
  Mine = 'MINE',
  All = 'ALL',
  Other = 'OTHER',
  // Filters ID's here too ?
}

export const getId = (projectId: string, stackId: string): string => `${projectId}/${stackId}`;
export const splitId = (id: string): { projectId: string; stackId: string } => ({
  projectId: id ? id.split('/')[0] : '',
  stackId: id ? id.split('/')[1] : '',
});

/**
 * on ViewState.setFilter
 *
 * get Entity ID & q
 * search API and add results
 * add StackGroup Viewstate.id with nextToken for loadmore
 *
 */
export interface State extends EntityState<Stack> {
  // additional entity state properties
  loaded: string[];
  loading: string[];
  selectedIdPlay: string | null;
  selectedIdEdit: string | null;
  currentPlayIndex: number;
  nextTokens: { [group: string]: string | null };
  /** temp holding space to store the playlist for when we select the stack to apply it to */
  addToStackPlaylist: { projectId: string; id: string; order: number }[];
  addToStackPayload: {
    projectId?: string;
    title?: string;
    description?: string;
    poster?: string;
    isCreateNew?: boolean;
  };
}

export const adapter: EntityAdapter<Stack> = createEntityAdapter<Stack>({
  selectId: (stack) => getId(stack.projectId, stack.stackId),
  // consider for v2 api
  // selectId: (stack) => stack.stackId,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: [],
  loading: [],
  selectedIdPlay: null,
  selectedIdEdit: null,
  currentPlayIndex: 0,
  nextTokens: {},
  addToStackPlaylist: [],
  addToStackPayload: {},
});

export const getProjectStacksGroup = (projectId: string, group: StackGroup | string): string => {
  if (!projectId) {
    console.warn(`[StackStore] getProjectStacksGroup missing projectId?`, projectId);
    return `unknown__${group || 'no-group'}`;
  }
  return group ? `${projectId}__${group}` : projectId;
};

/**
 * Return new array with item removed
 */
const newFilteredArray = (arr, str: string) => arr.filter((item) => item !== str);

/**
 * Handle Load Actions for different groups
 */
const handleLoad = (state: State, group: StackGroup | string): State => {
  // NOTE: if it is NOT an Array, we have an old version of state loaded
  if (Array.isArray(state.loaded) && state.loaded.includes(group) && !state.nextTokens[group]) {
    DEBUG_LOGS && console.log(`[StackStore] already loaded ${group}.`);
    return state;
  }
  if (Array.isArray(state.loading) && state.loading.includes(group)) {
    DEBUG_LOGS && console.log(`[StackStore] currently loading ${group}`);
    return state;
  }
  const loading = Array.isArray(state.loading) ? state.loading : [];
  return Object.assign({}, state, {
    loading: [...loading, group],
  });
};

const stackReducer = createReducer(
  initialState,

  /** @todo addClipIds */

  on(UserActions.logout, (state) => ({ ...state, selectedIdEdit: initialState.selectedIdEdit })),

  on(StackActions.reset, ResetActions.resetStore, ResetActions.resetStoreThenReboot, () => ({ ...initialState })),

  /** @todo: instead use Effect to => LOAD_SUCCESS */
  on(StackActions.hydrated, (state) => {
    console.log(`${StackActions.hydrated.type} TODO: Effect verifying enitities exist in clips store`);
    return Object.assign({}, state, {
      loaded: Array.isArray(state.loaded) ? state.loaded : [],
      loading: [],
    });
  }),

  /*
   * Add Playlist to Stack Draft
   */
  on(StackActions.addToStackDrawer, (state, { playlist, ...args }) => ({
    ...state,
    addToStackPlaylist: playlist,
    addToStackPayload: {
      ...args,
    },
  })),
  on(StackActions.resetAddToStackPlaylist, (state) => ({
    ...state,
    addToStackPlaylist: [],
    addToStackPayload: {},
  })),
  on(StackActions.addToStackIdPlaylist, (state, { projectId, stackId }) => {
    // find the entity, update the playlist by appending
    const entity = Object.assign({}, state.entities[getId(projectId, stackId)]);
    const playlist = Array.isArray(entity.playlist) ? [...entity.playlist] : [];
    const len = playlist.length;
    // the new playlist should have .order incrementing from current length
    const newPlaylist = (state.addToStackPlaylist || [])
      .map((item, index) => ({
        projectId: item.projectId,
        id: item.id,
        order: index + len,
      }))
      .map((item) => {
        // append the newPlaylist to the current
        Utils.addObjectToArrayUnique(playlist, item, 'id');
        return item;
      });
    DEBUG_LOGS && console.log(`[Store] addToStackIdPlaylist:`, { playlist, newPlaylist });
    return adapter.upsertOne({ ...entity, playlist }, state);
  }),

  on(StackActions.loadProjectFeaturedStacks, (state, action) =>
    handleLoad(state, getProjectStacksGroup(action.projectId, StackGroup.Featured))
  ),
  on(StackActions.loadProjectRecentStacks, (state, action) =>
    handleLoad(state, getProjectStacksGroup(action.projectId, StackGroup.Recent))
  ),

  on(
    // on addDraft, add the stack
    StackActions.addDraft,
    (state, { stack }) => {
      // update selectedIdEdit here? no. wait for component to route here
      // return adapter.upsertOne(stack, { ...state, selectedIdEdit: stack.stackId });
      return adapter.upsertOne(stack, state);
    }
  ),

  on(StackActions.subUpdate, (state, { stack }) => {
    if (
      stack &&
      stack.stackId &&
      state.entities[getId(stack.projectId, stack.stackId)] &&
      state.entities[getId(stack.projectId, stack.stackId)].stackId
    ) {
      // this is an item we are tracking
      const entity = Object.assign({}, state.entities[getId(stack.projectId, stack.stackId)]);
      for (const prop in stack) {
        if (
          stack.hasOwnProperty(prop) &&
          (stack[prop] || typeof stack[prop] === 'boolean' || typeof stack[prop] === 'number')
        ) {
          const val = stack[prop];
          if (entity[prop] !== val) {
            entity[prop] = val;
            // console.log(`[subUpdate] changed [${prop}]`, val);
          }
        }
      }
      DEBUG_LOGS && console.log(`[StackStore] subUpdate:`, { entity });
      return adapter.upsertOne(entity, state);
    } else if (stack && stack.stackId) {
      // we already checked if (item.userId === userId || myProjectIds.includes(item.projectId))
      // so we do indeed want this, but it does not currently exist - allow the StackEffect to handle loading it
      // !environment.production && console.log(`[Store] subUpdate new:`, { stack });
      return state;
      // return adapter.addOne(stack, state);
    } else {
      // ignoring clips we don't care about.. - this should be handled before the action is sent, no?
      !environment.production && console.log(`subUpdate ignoring:`, stack);
      return state;
    }
  }),

  on(StackActions.add, StackActions.loadSuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { stacks = [], listId = '', nextToken } = action as any;
    DEBUG_LOGS && console.log(`[StackStore] stacks.loadSuccess`, { stacks });
    // migrated logic for our slice of state
    const group = listId || StackGroup.Other;
    const newNextToken = {};
    if (nextToken) {
      newNextToken[group] = nextToken;
    }
    const nextTokens = Object.assign({}, state.nextTokens, newNextToken);
    const loaded = [...newFilteredArray(state.loaded, group), group];
    const loading = newFilteredArray(state.loading, group);

    return adapter.upsertMany(stacks, { ...state, loaded, loading, nextTokens });
  }),

  on(StackActions.loadFail, (state, action) => {
    const { error } = action;
    console.warn(`[StackStore] load fail:`, action);
    // const id = getId(action.projectId, action.stackId);
    return adapter.upsertOne(
      new Stack({
        projectId: action.projectId,
        stackId: action.stackId,
        error,
      }),
      state
    );
  }),

  on(StackActions.update, (state, { stack, updates }) => {
    const id = getId(stack.projectId, stack.stackId);

    const updatedStack: Stack = state.entities[id] ? Object.assign({}, state.entities[id]) : new Stack(stack);
    // merge updates into existing
    updates.forEach((update) => {
      if (update.value || typeof update.value === 'number' || typeof update.value === 'boolean') {
        // it could be number == zero
        // dynamically create the update params for string query
        updatedStack[update.prop] = update.value;
      }
    });
    return adapter.upsertOne(updatedStack, state);
  }),

  on(StackActions.deleteStack, (state, { stack }) => {
    // note here we may have to remove a deleted stack from selectedIds
    const id = getId(stack.projectId, stack.stackId);
    return adapter.removeOne(id, {
      ...state,
      selectedIdPlay: state.selectedIdPlay === id ? '' : state.selectedIdPlay,
      selectedIdEdit: state.selectedIdEdit === id ? '' : state.selectedIdEdit,
    });
  }),

  on(StackActions.setPoster, (state, { projectId, stackId, poster }) => {
    const id = getId(projectId, stackId);
    const stack = state.entities[id];
    if (!stack || !stack.stackId) {
      console.warn(`Missing stack: '${id}'? No action taken`);
      return state;
    }
    return adapter.upsertOne({ ...stack, poster }, state);
  }),

  /*
    Playlists
  */
  on(StackActions.selectIdPlay, (state, action) =>
    Object.assign({}, state, {
      selectedIdPlay: getId(action.projectId, action.stackId),
    })
  ),
  on(StackActions.selectIdEdit, (state, action) =>
    Object.assign({}, state, {
      selectedIdEdit: getId(action.projectId, action.stackId),
    })
  ),

  /**
   * Mystack / Editor
   */
  on(MystackActions.loadSuccess, (state, { stack }) => {
    const { projectId, stackId } = stack;
    const selectedIdEdit = getId(projectId, stackId);

    return Object.assign({}, state, {
      selectedIdEdit,
    });
  }),
  on(StackActions.resetIdEdit, MystackActions.reset, (state) =>
    Object.assign({}, state, {
      selectedIdEdit: '',
    })
  ),

  on(MystackActions.updateTitle, (state, { title }) => {
    const selectedIdEdit = state.selectedIdEdit;
    if (selectedIdEdit && state.entities[selectedIdEdit]) {
      return adapter.updateOne({ id: selectedIdEdit, changes: { title } }, state);
    }
    return state;
  }),
  on(MystackActions.updatePoster, (state, { poster }) => {
    const selectedIdEdit = state.selectedIdEdit;
    if (selectedIdEdit && state.entities[selectedIdEdit]) {
      return adapter.updateOne({ id: selectedIdEdit, changes: { poster } }, state);
    }
    return state;
  }),
  on(MystackActions.updateMyStack, (state, { stack, updates }) => {
    DEBUG_LOGS && console.log(`[StackStore] updateMyStack`, { stack, updates });
    const selectedIdEdit = state.selectedIdEdit;
    if (selectedIdEdit && state.entities[selectedIdEdit]) {
      const changes = {};
      updates.forEach((update) => {
        if (update.value || typeof update.value === 'number' || typeof update.value === 'boolean') {
          // it could be number == zero
          // dynamically create the update params for string query
          changes[update.prop] = update.value;
        }
      });
      return adapter.updateOne({ id: selectedIdEdit, changes }, state);
    }

    return state;
  }),
  on(MystackActions.newMyStack, (state, { stack }) => {
    DEBUG_LOGS && console.log(`[StackStore] newMyStack`, { stack });
    if (!stack || !stack.stackId) {
      return state;
    }
    const id = getId(stack.projectId, stack.stackId);
    if (state.entities[id] && state.entities[id].stackId) {
      // console.log(`We already have that one..`);
      return state;
    }
    return adapter.upsertOne(stack as Stack, state);
  }),

  /**
   * is this where this player logic should be?
   */
  on(StackActions.playCurrentIndex, (state, action) => {
    const i = action.index;
    const currentStack = state.entities[state.selectedIdPlay];
    if (
      typeof i !== 'number' ||
      i < 0 ||
      !currentStack ||
      !Array.isArray(currentStack.playlist) ||
      i >= currentStack.playlist.length
    ) {
      console.info(`[StackStore] NewCurrentIndex NaN or > length`, { index: i, currentStack });
      return state;
    }
    return Object.assign({}, state, {
      currentPlayIndex: i,
    });
  }),

  on(StackActions.playNextClip, (state) => {
    const currentStack = state.entities[state.selectedIdPlay];

    if (
      !currentStack ||
      !Array.isArray(currentStack.playlist) ||
      state.currentPlayIndex >= currentStack.playlist.length - 1
    ) {
      // we're at the end
      return state;
    }
    return Object.assign({}, state, {
      currentPlayIndex: state.currentPlayIndex + 1,
    });
  }),

  on(StackActions.playPrevClip, (state) => {
    if (state.currentPlayIndex < 1) {
      // we're at the start
      return state;
    }
    // else there's a prev
    return Object.assign({}, state, {
      currentIndex: state.currentPlayIndex - 1,
    });
  }),

  /*
    Moderation and Project Management
  */

  on(StackActions.setApproved, (state, { projectId, stackId, isApproved }) => {
    const id = getId(projectId, stackId);
    const stack = state.entities[id];
    if (!stack || !stack.stackId) {
      console.warn(`Missing stack: '${id}'? No action taken`);
      return state;
    }
    return adapter.upsertOne({ ...stack, isApproved }, state);
  }),
  on(StackActions.setFeatured, (state, { projectId, stackId, featured, isApproved }) => {
    const id = getId(projectId, stackId);
    const stack = state.entities[id];
    if (!stack || !stack.stackId) {
      console.warn(`Missing stack: '${id}'? No action taken`);
      return state;
    }
    const approved = typeof isApproved === 'boolean' ? { isApproved } : {};
    return adapter.upsertOne({ ...stack, ...approved, featured }, state);
  }),
  on(StackActions.share, (state, { projectId, stackId }) => {
    const id = getId(projectId, stackId);
    const stack = state.entities[id];
    if (!stack || !stack.stackId) {
      console.warn(`Missing stack: '${id}'? No action taken`);
      return state;
    }
    return adapter.upsertOne({ ...stack, shares: stack.shares + 1 }, state);
  }),
  on(StackActions.setCollaborative, (state, { projectId, stackId, isCollaborative, description }) => {
    const id = getId(projectId, stackId);
    const stack = state.entities[id];
    if (!stack || !stack.stackId) {
      console.warn(`Missing stack: '${id}'? No action taken`);
      return state;
    }
    const updates: Partial<Stack> =
      typeof isCollaborative === 'number' && isCollaborative > -1
        ? {
            isCollaborative,
            privacy: getCollabPrivacy(isCollaborative),
          }
        : {};
    if (description) {
      updates['description'] = description;
    }
    return adapter.upsertOne({ ...stack, ...updates }, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return stackReducer(state, action);
}

export const {
  /** select the array of user ids */
  selectIds,
  /** select the dictionary of entities */
  selectEntities,
  // selectAll,
  // selectTotal,
} = adapter.getSelectors();

export const getCurrentPlayIndex = (state: State) => state.currentPlayIndex;
export const getSelectedIdPlay = (state: State) => state.selectedIdPlay;
export const getSelectedIdEdit = (state: State) => state.selectedIdEdit;
export const getLoaded = (state: State) => state.loaded;
export const getAddToStackPlaylist = (state: State) => state.addToStackPlaylist;
export const getAddToStackPayload = (state: State) => state.addToStackPayload;

// selectors - moved to stacks.selectors.ts

// export const selectStacksState = createFeatureSelector<State>(featureKey);
// export const selectStackEntities = createSelector(selectStacksState, selectEntities);
// export const selectStackSelectedIdPlay = createSelector(selectStacksState, (state: State) => state.selectedIdPlay);
// export const selectStackSelectedIdEdit = createSelector(selectStacksState, (state: State) => state.selectedIdEdit);

// // select the array of entites
// export const selectAllStacks = selectAll;
// // select the total user count
// export const selectStackTotal = selectTotal;
