import { Pipe, PipeTransform } from '@angular/core';
import { AVATAR_URL_PREFIX } from '@app/app.config';
import { DEFAULT_USER_AVATAR, DEFAULT_USER_AVATAR_DARK, DEFAULT_USER_AVATAR_TERTIARY } from '@shared/models/user.model';

@Pipe({
  name: 'avatarUrl',
})
export class AvatarUrlPipe implements PipeTransform {

  transform(userId: string, ...args: unknown[]): string {
    if (userId) {
      return `${AVATAR_URL_PREFIX}${userId}.jpg`;
    }
    const [ color ] = args;
    switch (color) {
      case 'dark':
        return DEFAULT_USER_AVATAR_DARK;
      case 'tertiary':
        return DEFAULT_USER_AVATAR_TERTIARY;
      default:
        return DEFAULT_USER_AVATAR;
    }
    // return userId ? `${AVATAR_URL_PREFIX}${userId}.jpg` : defaultAvatar;
  }

}
