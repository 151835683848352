/** @format */

import { NgModule } from '@angular/core';
import { ClipsApiService } from './clips-api.service';
import { CoreLogicApiService } from './core-logic-api.service';
import { MailerApiService } from './mailer.api.service';
import { ProjectCrewApiService } from './project-crew-api.service';
import { ProjectsApiService } from './projects-api.service';
import { StacksApiService } from './stacks-api.service';
import { UsersApiService } from './users-api.service';

@NgModule({
  imports: [],
  providers: [
    ClipsApiService,
    CoreLogicApiService,
    MailerApiService,
    ProjectCrewApiService,
    ProjectsApiService,
    StacksApiService,
    UsersApiService,
  ],
})
export class CoreApiModule {}
