/**
 * HLS Logic
 * Global APP Video Service
 *
 * @note search code for DEV HLS TRANSCODE MVP-742
 *
 * @format
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import { Stack } from '@shared/models/stack.model';
import { Clip, HLS_META_UPGRADE } from '@shared/models/clip.model';
import { UpdateParam } from '../api/api-types';
import { StacksService } from './stacks.service';
import { ClipsCoreService } from './clips.service';
/** @todo remove these following HLS TRanscode done */
import { AlertController } from '@ionic/angular';
import { ToasterService } from './toaster.service';
import { Utils } from '@shared/utils';
import { lastValueFrom } from 'rxjs';

const DEBUG_LOGS = false;
const PAGE = '[VideoService]'; // eslint-disable-line @typescript-eslint/no-unused-vars

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  /** only send sendStackForHLSTranscode once (hacky throttle) */
  private didSendUpgrade = false;

  constructor(
    private alertCtrl: AlertController,
    private toaster: ToasterService,
    private stacksService: StacksService,
    private clipsService: ClipsCoreService,
    private store: Store<State>
  ) {}

  /**
   * DEV HLS TRANSCODE MVP-742
   * Update the Stack props to queue for HLS Transcoding
   * 2022-10-24 fix: only send this once! this.didSendUpgrade
   * @param stack
   */
  async sendStackForHLSTranscode(stack: Stack): Promise<string | Stack> {
    if (!stack || !stack.projectId || !stack.stackId) {
      return Promise.reject('Missing required arguments: stack ids');
    }

    if (this.didSendUpgrade) {
      return Promise.resolve('didSendUpgrade');
    }

    try {
      // get the clips without a source & not youtube
      const clips = stack.clips.filter((c) => c && !c.hlsSrc && !c.providerId && !c.youtube_id);
      if (clips.length > 0) {
        // clips all need to be HLS first
        console.log(`sendStackForHLSTranscode first clips:`, clips);
        return await this.watchStackClipsForHLSTranscode(clips);
      }
      const hlsMeta = typeof stack.hlsMeta === 'string' ? Utils.tryParseJSON(stack.hlsMeta) : stack.hlsMeta || {};
      const updates: UpdateParam[] = [
        {
          prop: 'hlsMeta',
          value: {
            ...hlsMeta,
            ...HLS_META_UPGRADE,
          },
        },
      ];
      DEBUG_LOGS && console.log(`${PAGE} sendStackForHLSTranscode`, { stack, updates });
      this.didSendUpgrade = true;
      return this.stacksService.updateStack(stack, updates).then((updatedStack) => {
        DEBUG_LOGS && console.log(`${PAGE} sendStackForHLSTranscode updatedStack`, { updatedStack, updates });
        // this.toaster.present(`Submitted Stack for Transcoding...`);
        // watching the status updates here:
        this.stacksService.subStacksUpdated(stack.projectId, stack.stackId);
        setTimeout(() => {
          this.didSendUpgrade = false;
        }, 900);
        return updatedStack;
      });
    } catch (error) {
      console.error(error);
      this.toaster.present(`Error while sending Stack to Transcoding...`);
    }
  }

  /**
   * Stack Playlist HLS Upgrades MVP-983
   * Update the Clip props to queue for HLS Transcoding
   * + Subscribe to updates, awaiting all clips to be done
   * @param clip
   */
  async watchStackClipsForHLSTranscode(clips: Clip[]): Promise<string> {
    if (!Array.isArray(clips) || clips.length < 1) {
      return Promise.reject('Missing required arguments: clips');
    }
    try {
      for (const clip of clips) {
        // send and watch clip in HLS Transcode
        await this.sendClipForHLSTranscode(clip);

        // ref: await this.clipsService.createClipFromFilestack(res, this.captureToProjectId, this.userId)
      }
      return 'success';
    } catch (error) {
      console.error(error);
      this.toaster.present(`Error while sending Clip to Transcoding...`);
      return 'error';
    }
  }
  /**
   * DEV HLS TRANSCODE MVP-742
   * Update the Clip props to queue for HLS Transcoding
   * @param clip
   */
  sendClipForHLSTranscode(clip: Clip): Promise<Clip | string> {
    if (!clip || !clip.projectId || !clip.id) {
      return Promise.reject('Missing required arguments: clip ids');
    }
    const updates: UpdateParam[] = [
      {
        prop: 'hlsMeta',
        value: HLS_META_UPGRADE,
      },
    ];
    DEBUG_LOGS && console.log(`${PAGE} sendClipForHLSTranscode`, { clip, updates });
    return lastValueFrom(this.clipsService.updateClip(clip, updates)).then((updatedClip) => {
      DEBUG_LOGS && console.log(`${PAGE} sendClipForHLSTranscode updatedClip`, { updatedClip, updates });
      return updatedClip;
    });
  }
  /**
   * DEV HLS TRANSCODE MVP-742
   */
  async confirmSendClipForHLSTranscode(clip: Clip) {
    const alert = await this.alertCtrl.create({
      header: 'Add this Clip to the HLS Transcoding queue?',
      // subHeader: `We'll loop the Clips and create HLS version of them too, if not already done.`,
      // message: `Note that if this is a Youtube Clip, we're going to cancel this request...`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Confirm Canceled...');
          },
        },
        {
          text: 'Transcode',
          cssClass: 'ion-color-secondary',
          handler: () =>
            this.sendClipForHLSTranscode(clip)
              .then(() => {
                this.toaster.present(`Submitted for Transcoding...`);
              })
              .catch((error) => {
                console.warn(`[ClipPopover] confirmTranscode caught:`, error);
                this.toaster.present(`Oops! There was an error. Please refresh and try again.`);
              }),
        },
      ],
    });
    return await alert.present();
  }
}
