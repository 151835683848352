/** @format */

import { Component, Input } from '@angular/core';
import { Clip } from '@shared/models/clip.model';
import { ModalController, PopoverController } from '@ionic/angular';
import { ClipPopoverActionsComponent } from './clip-popover-actions/clip-popover-actions.component';
import { MyStackService } from '@services/mystack.service';
import { Utils } from '@shared/utils';

@Component({
  selector: 'app-clip-tile',
  templateUrl: './clip-tile.component.html',
  styleUrls: ['./clip-tile.component.scss'],
})
export class ClipTileComponent {
  @Input() item: Clip;
  @Input() isAddedToMyStack: boolean;
  @Input() canEdit: boolean = false;
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;
  @Input() showAvatar = false;
  @Input() currentUserId: string;

  get isMine(): boolean {
    return this.currentUserId && this.currentUserId === this.item.userId;
  }
  get canApprove(): boolean {
    return  this.isModerated && this.canModerate;
  }
  get canAddToStack(): boolean {
    return this.isMine || !this.isModerated || this.canModerate || (this.isModerated && this.item.isApproved);
  }

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private mystackService: MyStackService,
  ) {}

  async openActions(event: MouseEvent) {
    Utils.tryPreventDefault(event);
    Utils.tryStopPropagation(event);
    const popover = await this.popoverCtrl.create({
      component: ClipPopoverActionsComponent,
      componentProps: {
        clip: this.item,
        canEdit: this.canEdit,
        canAddToStack: this.canAddToStack,
        canApprove: this.canApprove,
      },
      event,
    });
    return await popover.present();
  }

  async toggleClipInMyStacks(item: Clip, event: MouseEvent) {
    Utils.tryPreventDefault(event); // why is the search form getting selected on ios?!
    Utils.tryStopPropagation(event);
    if (this.isAddedToMyStack) {
      /**
       * FIXED: Property 'removeClipFromMyStack' does not exist on type 'MyStackService'.
       */
      this.mystackService.removeClipFromMyStack(item);
    } else {
      this.mystackService.addClipsToMyStack([item]);
    }
  }

  async openModal(event: MouseEvent) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { ClipTileDetailModal } = await import(
      /* webpackPrefetch: true */
      '@clips/shared/components/clip-tile-detail/clip-tile-detail.modal'
    );
    Utils.tryPreventDefault(event);
    Utils.tryStopPropagation(event);
    if (!this.canAddToStack) {
      return console.log(`Clip awaiting moderation..`);
    }
    // console.log('item', this.item);
    const modal = await this.modalCtrl.create({
      component: ClipTileDetailModal,
      componentProps: {
        title: this.item.title,
        item: this.item,
        canEdit: this.canEdit,
      },
      cssClass: 'modal-lg',
    });
    return await modal.present();
  }
}
