<ion-header>
  <ion-toolbar color="dark">
    <ion-title>Select Stack Poster Image</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="6" *ngFor="let posterUrl of getPosters()">
        <div
          class="poster"
          [class.poster--selected]="posterUrl === currentPoster"
          (click)="selectPoster(posterUrl)"
        >
          <img [src]="posterUrl" />
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
