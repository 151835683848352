/**
 * Ionic [DEPRECATION][Events]: The Events provider is deprecated and it will be removed in the next major release.
 *   - Use "Observables" for a similar pub/sub architecture: https://angular.io/guide/observables
 *   - Use "Redux" for advanced state management: ngrx
 *
 * @format
 */

import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

// import { Store, select } from '@ngrx/store';
// import * as eventActions from '../store/actions/events.actions';

/*
  USAGE:

  private subManager = new Subscription();
  const subResize = this.events.subscribe("resize", this.handleResie);
  this.subManager.add(subResize);
  ngOnDestroy() this.subManager.unsubscribe();
 
  this.events.publish("resize", resizeObj);



  V3 Events:

  this.events.publish("resize", resizeObj);
  this.events.publish("orientationchange",orient);
  this.events.subscribe('auth:change', this.authChange);

  this.events.subscribe('flag', this.flag);

  // example:
  https://forum.ionicframework.com/t/ionic-4-tab-to-page-then-back-to-tab-did-not-trigger-ionviewwillenter-solved/167922/11

  and to read rxjs share:
  https://netbasal.com/understanding-rxjs-multicast-operators-77b3f60af0a2
*/

export enum Orientation {
  Portrait = 'PORTRAIT',
  Landscape = 'LANDSCAPE',
}

export interface ResizeObject {
  width: number;
  height: number;
}

export enum EventActions {
  RESIZE = 'app:resize',
  ORIENTATION_CHANGE = 'app:orientation:change',
  // AUTH_CHANGE = 'app:auth:change',
  FLAG = 'app:flag',
  SHARE = 'app:share',
  FLASH_MYSTACK = 'mystack:tab:flash',
  TABBAR_HIDE = 'tabbar:hide',
  TABBAR_SHOW = 'tabbar:show',
  TAB_CAPTURE_SHOW = 'tabbar:capture:show',
  TAB_CAPTURE_HIDE = 'tabbar:capture:hide',
  HISTORY_SCROLLEND = 'historyDrawer:scrollToBottom',
  EXPLORE_CLOSE = 'explore:close',
  AVATAR_CHANGED = 'user:avatar:changed',
}

const PAGE = '[EventsService]';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventHandler = (...args: any[]) => any;

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  // https://forum.ionicframework.com/t/ionic-4-tab-to-page-then-back-to-tab-did-not-trigger-ionviewwillenter-solved/167922/11
  // profile$ = new BehaviorSubject<Profile | null>(null);
  // watchProfile(): Observable<Profile | null> { return this.profile$; }
  // peekProfile(): Profile | null { return this.profile$.value; }
  // pokeProfile(profile: Profile): void { this.profile$.next(profile); }

  /**
   * based on https://github.com/ionic-team/ionic/commit/efe23edaab0d1981f322b86b30925b3780bf7afa
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private events = new Map<string, Subject<any>>();
  /**
   * based on https://github.com/ionic-team/ionic/commit/efe23edaab0d1981f322b86b30925b3780bf7afa
   */
  private c = new Map<string, EventHandler[]>();

  /**
   * Publish an event to the given topic.
   *
   * @param topic the topic to publish to
   * @param eventData the data to send as the event
   */
  publish(topic: string, eventData?): void {
    // console.log(`[Events] publish '${topic}'`, eventData);
    const topicSubject = this.events.get(topic);
    if (!topicSubject) {
      return null;
    }
    topicSubject.next(eventData);
  }

  /**
   * Subscribe to an event topic. Events that get posted to that topic will trigger the provided handler.
   *
   * @param topic the topic to subscribe to
   * @param handler the event handler
   */
  subscribe(topic: string, handler: EventHandler): Subscription {
    const topicSubject = this.events.get(topic);
    if (!topicSubject) {
      this.events.set(topic, new Subject());
    }
    return this.events.get(topic).subscribe(handler); // returns unsubscribe()
  }

  /** unsubscribe() is returned from the subscription, just use that..
   *
   *
   * Unsubscribe from the given topic. Your handler will no longer receive events published to this topic.
   *
   * @param topic the topic to unsubscribe from
   * @param handler the event handler
   *
   * @return true if a handler was removed
   */
  // unsubscribe(topic: string, handler?: EventHandler): boolean {
  //   if (!handler) {
  //     return this.c.delete(topic);
  //   }

  //   const topics = this.c.get(topic);
  //   if (!topics) {
  //     return false;
  //   }

  //   // We need to find and remove a specific handler
  //   const index = topics.indexOf(handler);

  //   if (index < 0) {
  //     // Wasn't found, wasn't removed
  //     return false;
  //   }
  //   topics.splice(index, 1);
  //   if (topics.length === 0) {
  //     this.c.delete(topic);
  //   }
  //   return true;
  // }

  // ngFromEventExample(target, eventName) {
  //   return new Observable((observer) => {
  //     const handler = (e) => observer.next(e);

  //     // Add the event handler to the target
  //     target.addEventListener(eventName, handler);

  //     return () => {
  //       // Detach the event handler from the target
  //       target.removeEventListener(eventName, handler);
  //     };
  //   });
  // }

  // notifyMulticast(topic: string, ...args: any[]): void {
  //   const topicSubject = this.events.get(topic);
  //   if (!topicSubject) {
  //     return null;
  //   }
  //   // Iterate through observers and notify all subscriptions
  //   topicSubject.observers.forEach(obs => obs.next(args));
  // }

  // completeMulticast(topic: string) {
  //   // Notify all complete callbacks
  //   const topicSubject = this.events.get(topic);
  //   if (!topicSubject) {
  //     return null;
  //   }
  //   // Iterate through observers and notify all subscriptions
  //   topicSubject.observers.slice(0).forEach(obs => obs.complete());
  // }

  // // https://angular.io/guide/observables#multicasting
  // createMulticastSubscriber() {
  //   // Keep track of each observer (one for every active subscription)
  //   const observers = [];
  //   // Return the subscriber function (runs when subscribe() function is invoked)
  //   return (observer) => {
  //     observers.push(observer);
  //     // if (observers.length === 1) this is the first subscription
  //     return {
  //       unsubscribe() {
  //         // Remove from the observers array so it's no longer notified
  //         observers.splice(observers.indexOf(observer), 1);
  //         // if (observers.length === 0) there's no more listeners, do cleanup
  //       }
  //     };
  //   };

  //   // // Create a new Observable that will deliver the above sequence
  //   // const multicastSequence = new Observable(multicastSequenceSubscriber());

  //   // // Subscribe starts the clock, and begins to emit after 1 second
  //   // multicastSequence.subscribe({
  //   //   next(num) { console.log('1st subscribe: ' + num); },
  //   //   complete() { console.log('1st sequence finished.'); }
  //   // });

  //   // // After 1 1/2 seconds, subscribe again (should "miss" the first value).
  //   // setTimeout(() => {
  //   //   multicastSequence.subscribe({
  //   //     next(num) { console.log('2nd subscribe: ' + num); },
  //   //     complete() { console.log('2nd sequence finished.'); }
  //   //   });
  //   // }, 1500);

  //   // // Logs:
  //   // // (at 1 second): 1st subscribe: 1
  //   // // (at 2 seconds): 1st subscribe: 2
  //   // // (at 2 seconds): 2nd subscribe: 2
  //   // // (at 3 seconds): 1st subscribe: 3
  //   // // (at 3 seconds): 1st sequence finished
  //   // // (at 3 seconds): 2nd subscribe: 3
  //   // // (at 3 seconds): 2nd sequence finished
  // }

  /*
    Try using ngrx - too much overhead for this need
  */

  publishStore(name, payload) {
    console.log(`${PAGE} Publish ${name} payload:`, payload);
    // switch (name) {
    //   case eventActions.RESIZE: {
    //     this.store.dispatch(eventActions.resizeAction(payload as eventActions.ResizeObject));
    //     break;
    //   }
    //   case eventActions.ORIENTATION_CHANGE: {
    //     this.store.dispatch(eventActions.orientationAction(payload as { orientation: eventActions.Orientation}));
    //     break;
    //   }
    //   default: {
    //     console.log(`${PAGE} Publish UNHANDLED name: ${name}`);
    //   }
    // }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  subscribeStore(name, handler) {
    console.log(`${PAGE} Subscribe ${name}`);
    // switch (name) {
    //   case eventActions.RESIZE: {
    //     // return this.store.pipe(select(fromStore.getEventResize));
    //   }
    //   case eventActions.ORIENTATION_CHANGE: {
    //     // return this.store.pipe(select(fromStore.getEventOrientationChange));
    //   }
    //   default: {
    //     console.log(`${PAGE} Publish UNHANDLED name: ${name}`);
    //   }
    // }
  }

  /**
   * Publish an event to the given topic.
   *
   * @param topic the topic to publish to
   * @param eventData the data to send as the event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ionicPublish(topic: string, ...args: any[]): any[] | null {
    const topics = this.c.get(topic);
    if (!topics) {
      return null;
    }
    return topics.map((handler) => {
      try {
        return handler(...args);
      } catch (e) {
        console.error(e);
        return null;
      }
    });
  }

  /**
   * Subscribe to an event topic. Events that get posted to that topic will trigger the provided handler.
   *
   * @param topic the topic to subscribe to
   * @param handler the event handler
   */
  ionicSubscribe(topic: string, ...handlers: EventHandler[]) {
    let topics = this.c.get(topic);
    if (!topics) {
      this.c.set(topic, (topics = []));
    }
    topics.push(...handlers);
  }

  /**
   * Unsubscribe from the given topic. Your handler will no longer receive events published to this topic.
   *
   * @param topic the topic to unsubscribe from
   * @param handler the event handler
   *
   * @return true if a handler was removed
   */
  ionicUnsubscribe(topic: string, handler?: EventHandler): boolean {
    if (!handler) {
      return this.c.delete(topic);
    }

    const topics = this.c.get(topic);
    if (!topics) {
      return false;
    }

    // We need to find and remove a specific handler
    const index = topics.indexOf(handler);

    if (index < 0) {
      // Wasn't found, wasn't removed
      return false;
    }
    topics.splice(index, 1);
    if (topics.length === 0) {
      this.c.delete(topic);
    }
    return true;
  }
}
