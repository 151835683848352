/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  OnboardingTypes,
  OnboardingMeta,
  getTranslatePath,
  ROUTE_DATA_PROP,
} from '../onboarding.model';
import { STACK_PRIVACY, Stack, StackDraftPublishInput } from '@shared/models/stack.model';
import { PublishStackService } from '@services/publish-stack.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  /**
   * use with takeUntil(this.onDestroy$),
   * @todo consider where this should live
   */
  routeOnboardingType$: Observable<OnboardingTypes> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map((_) => this.route),
    map((route) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
    mergeMap((route) => route.data),
    map((data) => data[ROUTE_DATA_PROP]),
    shareReplay(1)
  );

  /**
   * use with takeUntil(this.onDestroy$),
   */
  routeOnboardingMeta$: Observable<OnboardingMeta> = this.routeOnboardingType$.pipe(
    map((routeId: OnboardingTypes) => this.getMetaForRoute(routeId)),
    shareReplay(1)
  );

  constructor(
    private publishService: PublishStackService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  getMetaForRoute(routeId: string): OnboardingMeta {
    const translatePath = getTranslatePath(routeId);
    switch (routeId) {
      case OnboardingTypes.Weddings:
        return {
          routeId,
          translatePath,
          actionUrl: `/events/weddings/`,
          backgroundUrl: '/assets/images/events/weddings-bride@2.jpg',
          backgroundPosition: 'bottom right',
        };
      case OnboardingTypes.Teams:
        return {
          routeId,
          translatePath,
          actionUrl: `/events/`,
          backgroundUrl: '/assets/images/events/crowd-filming@2.jpg',
          backgroundPosition: 'center',
        };
      case OnboardingTypes.Pro:
      case OnboardingTypes.Projects:
      case OnboardingTypes.None:
      default:
        return {
          routeId: (routeId as OnboardingTypes) || OnboardingTypes.None,
          translatePath,
          actionUrl: `/pro/`,
          backgroundUrl: '/assets/images/events/crowd-filming@2.jpg',
          backgroundPosition: 'center',
          // this.sideBlocks = this.buildSideBlocks('');
        };
    }
  }

  /**
   * On successful signup, create 2 collab drafts for Guests [MVP-1367]
   * The owner should be the person who just signed up
   * 1: Reception
   *    poster: https://app.filmstacker.com/assets/images/events/weddings-jump@1.jpg
   * 2: Ceremony (created second so it shows up first?)
   *    poster: https://app.filmstacker.com/assets/images/events/weddings-hands@1.jpg
   */
  async createCollabStacksForWedding({ userId, username, projectId }): Promise<Array<Stack>> {
    console.warn('create the collab stacks!', { userId, username, projectId });
    try {
      const input: StackDraftPublishInput = {
        userId,
        credits: username,
        projectId,
        privacy: STACK_PRIVACY.PUBLIC,
        playlist: [],
        isCollaborative: 1,
        title: this.translate.instant('ONBOARDING.COMMON.STACK_TITLE_RECEPTION'),
        poster: 'https://app.filmstacker.com/assets/images/events/weddings-jump@1.jpg',
      };
      const receptionStack = await this.publishService.createStackDraft(input);
      // ok, now the Ceremony
      input.title = this.translate.instant('ONBOARDING.COMMON.STACK_TITLE_CEREMONY');
      input.poster = 'https://app.filmstacker.com/assets/images/events/weddings-hands@1.jpg';
      const ceremonyStack = await this.publishService.createStackDraft(input);
      return [receptionStack, ceremonyStack];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
