<ion-searchbar
  #searchbar
  mode="md"
	inputmode="search"
	enterkeyhint="search"
	[animated]="true"
	[debounce]="debounceTime"
	[value]="value"
	[placeholder]="placeholderText"
	[showCancelButton]="showCancelButton"
	[color]="color"
	[class.responsive-width]="responsiveWidth"
	(keyup.enter)="onSearch()"
	(ionChange)="onChange($event)"
	(ionClear)="onClear()"
	(ionBlur)="onBlur()"
	(ionCancel)="onCancel()"
></ion-searchbar>
