/** @format */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ENABLE_BILLING } from '@app/app.config';
import { ChargebeePlanId } from '@billing/shared/billing.model';
import {
  PlanItem,
  SIGNUP_RETURN_URL_EVENTS,
  SIGNUP_RETURN_URL_PRO,
  SIGNUP_RETURN_URL_WEDDINGS,
} from '@onboarding/shared/onboarding.model';

@Component({
  selector: 'fs-plan-item',
  templateUrl: './plan-item.component.html',
  styleUrls: ['./plan-item.component.scss'],
})
export class PlanItemComponent {
  @Input() planItem: PlanItem;
  @Input() isActivePro = false;

  @Output() navSignup = new EventEmitter<string>();

  enableBilling = ENABLE_BILLING;

  get isProPlan(): boolean {
    return this.planItem?.planId === ChargebeePlanId.Pro;
  }

  get getPriceMonthly(): string {
    return this.planItem?.plan?.prices?.find((p) => p?.periodUnit === 'month')?.price?.toString();
  }
  get getPriceYearly(): string {
    return this.planItem?.plan?.prices?.find((p) => p?.periodUnit === 'year')?.price?.toString();
  }

  get signupTranslatePath(): string {
    switch (this.planItem?.planId) {
      case ChargebeePlanId.Pro:
        return 'AUTH.PLANS.SIGN_UP_PRO';
      case ChargebeePlanId.Teams:
        return 'AUTH.PLANS.SIGN_UP_EVENT';
      case ChargebeePlanId.Weddings:
        return 'AUTH.PLANS.SIGN_UP_WEDDING';
    }
  }
  get signupPath(): string {
    switch (this.planItem?.planId) {
      case ChargebeePlanId.Pro:
        return SIGNUP_RETURN_URL_PRO;
      case ChargebeePlanId.Teams:
        return SIGNUP_RETURN_URL_EVENTS;
      case ChargebeePlanId.Weddings:
        return SIGNUP_RETURN_URL_WEDDINGS;
    }
  }
}
