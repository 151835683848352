<div class="uploader-stats__date">
  {{ date | date: 'mediumDate' }}
</div>
<div *ngIf="showStats" class="uploader-stats__content">
  <div *ngIf="views > -1" class="uploader-stats__content__stats" title="{{ viewsTitle }}">
    <ion-icon name="play-sharp"></ion-icon>&nbsp;{{ views || 0 }}<span *ngIf="moreViews">+</span>
  </div>
  <div *ngIf="shares > -1" class="uploader-stats__content__stats" [title]="'COMMON.SHARES' | translate">
    <ion-icon ios="share-outline" md="share-social-outline"></ion-icon>&nbsp;{{ shares || 0 }}
  </div>
  <div *ngIf="likes > -1" class="uploader-stats__content__stats" [title]="'COMMON.LIKES' | translate">
    <ion-icon name="heart-sharp"></ion-icon>&nbsp;{{ likes || 0 }}
  </div>
  <div class="uploader-stats__content__stats" title="{{ stacksTitle }}">
    <ion-icon name="film-sharp"></ion-icon>&nbsp;{{ stacks || 0 }}<span *ngIf="moreStacks">+</span>
  </div>
  <div *ngIf="members !== undefined" class="uploader-stats__content__stats" [title]="'COMMON.MEMBERS' | translate">
    <ion-icon name="person-sharp"></ion-icon>&nbsp;{{ members || 0 }}
  </div>
  <div *ngIf="indicateHls" class="uploader-stats__content__stats" [title]="'COMMON.PREMIERE_QUALITY' | translate">
    <ion-icon name="aperture-sharp"></ion-icon>
  </div>
  <!-- <div *ngIf="indicateApproved" class="uploader-stats__content__stats" [title]="'COMMON.MODERATED' | translate">
    <ion-icon name="thumbs-up-outline"></ion-icon>
  </div> -->
  <div
    *ngIf="indicateNeedsApproved"
    class="uploader-stats__content__stats"
    [title]="'STACK.ACTIONS.REQUIRES_MODERATION' | translate"
  >
    <ion-icon name="lock-closed"></ion-icon>
  </div>
</div>
<div *ngIf="showUploader" class="uploader-stats__profile">
  <ion-avatar *ngIf="showAvatar" class="uploader-stats__profile__avatar">
    <img class="uploader-stats__profile__avatar__image" *ngIf="avatarUrl" [src]="avatarUrl" [fallback] imageFadeIn />
  </ion-avatar>
  <div class="uploader-stats__profile__name">{{ username$ | async }}</div>
</div>
