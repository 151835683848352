<ion-header *ngIf="!isModal; else modalHeader;">
  <app-top-navbar [title]="title">
    <!-- SLOT: anything added here will go below the ion-navbar -->
  </app-top-navbar>
</ion-header>

<ng-template #modalHeader>
  <ion-header>
    <ion-toolbar color="dark">
      <ion-title>
        <ion-button fill="clear" class="toolbar-title__logo" (click)="openHomePage()">
          <img src="/assets/images/logos/filmstackerLogo.png" alt="Filmstacker" />
        </ion-button>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" color="primary" (click)="dismiss()">{{ 'COMMON.CLOSE' | translate }}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</ng-template>

<ion-content forceOverflow="false">

  <div class="iframe-container">
    <iframe id="FilmstackerTerms" height="100%" name="filmstacker-terms" style="width:100%;border:none;padding:0;"
      title="Filmstacker {{ 'POLICIES.TERMS_SERVICE' | translate }}" src="https://filmstacker.com/policies/terms.html">
      <p>{{ 'ERRORS.IFRAMES_NOT_SUPPORTED' | translate }}</p>
      <p>
        {{ 'COMMON.OPEN_NEW_PAGE' | translate }}: <a href="https://filmstacker.com/policies/terms.html"
          target="_blank">https://filmstacker.com/policies/terms.html</a>
      </p>
    </iframe>
  </div>

</ion-content>

<ion-footer *ngIf="isModal else pageFooter" class="ion-text-center ion-padding">
  <ion-button (click)="dismiss()">{{ 'COMMON.CLOSE' | translate }}</ion-button>
</ion-footer>

<ng-template #pageFooter>
  <ion-footer>
    <app-footer class="no-bottom-spacer"></app-footer>
  </ion-footer>
</ng-template>