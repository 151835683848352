import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialSharingPopoverComponent } from './social-sharing-popover/social-sharing-popover.component';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@shared/shared.module';
import { CopyToClipboardFeedbackPopoverComponent } from './copy-to-clipboard-feedback-popover/copy-to-clipboard-feedback-popover.component';
import { TokensModule } from '@tokens/tokens.module'

@NgModule({
  declarations: [SocialSharingPopoverComponent, CopyToClipboardFeedbackPopoverComponent],
  imports: [CommonModule, IonicModule, SharedModule, TokensModule],
})
export class SocialSharingModule {}
