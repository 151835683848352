/** @format */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CONFIRM_AUTH_PAGE, NAV_PAGE_ON_LOGIN } from '@app/app-routing.module';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { UserService } from '@services/user.service';
import { PasswordValidator } from '@shared/validators/password';
// import { UsernameValidator } from '@shared/validators/username';
import { TokensService } from '@tokens/shared/services/tokens.service';
import { TOKEN_QUERY_PARAM } from '@tokens/shared/tokens.model';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fs-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @Input() routeOnSuccess: string = `/${NAV_PAGE_ON_LOGIN}`;
  /** if there's a cancel route, we'll show the cancel button */
  @Input() cancelText: string;
  @Output() login = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();

  loginForm = this.formBuilder.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  newPasswordForm = this.formBuilder.group(
    {
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required, PasswordValidator.isValid]],
      confirmPassword: [''],
    },
    {
      validator: this.checkMatchingPasswords('newPassword', 'confirmPassword'),
    }
  );
  mode: 'login' | 'new-password' = 'login';
  error = '';
  loading = false;
  challengeAttributes: [Record<string, string>];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private userService: UserService,
    private tokensService: TokensService,
    private analyticsService: AnalyticsService,
    private loadingCtrl: LoadingController,
    private router: Router
  ) {}

  get isBusy() {
    return (
      this.loading ||
      (this.mode === 'login' && this.loginForm.invalid) ||
      (this.mode === 'new-password' && this.newPasswordForm.invalid)
    );
  }

  async loginUser() {
    this.loading = true;
    this.error = '';
    const loader = await this.loadingCtrl.create({
      message: this.translate.instant('AUTH.SIGNING_IN'),
    });
    await loader.present();
    const username = (this.loginForm.controls.username.value || '').toLowerCase();
    const password = this.loginForm.controls.password.value;
    try {
      const user = await this.userService.login(username, password);
      if (!user) {
        this.error = 'User not found. Please try again.';
        console.warn('login no user?');
        return;
      }
      if (user.cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.challengeAttributes =
          user['challengeParam'] && user['challengeParam'].requiredAttributes
            ? user['challengeParam'].requiredAttributes
            : [];
        throw new Error('NEW_PASSWORD_REQUIRED');
      }
      this.analyticsService.login();
      // apply the token if exists
      this.tokensService.tokenId$.pipe(take(1)).subscribe((tokenId) => {
        if (tokenId) {
          !environment.production && console.log(`[Login] token:`, tokenId);
          // apply the token & toast success
          this.tokensService.applyTokenToCurrentUser({ tokenId });
        }
      });
      this.router.navigate([this.routeOnSuccess], {
        queryParams: {
          [TOKEN_QUERY_PARAM]: null,
          returnUrl: null,
        },
        queryParamsHandling: 'merge',
      });
    } catch (err) {
      switch (err.message) {
        case 'User is not confirmed.':
          this.router.navigateByUrl(`/${CONFIRM_AUTH_PAGE}`, {
            state: { username },
          });
          break;
        case 'NEW_PASSWORD_REQUIRED':
          this.mode = 'new-password';
          console.log(`@todo ${err.message}`);
          break;
        default:
          console.log(`[LoginForm] caught (username: ${username})`, err.message);
          this.error = err.message;
      }
    } finally {
      this.loading = false;
      loader.dismiss();
    }
  }

  async submitNewPassword() {
    this.loading = true;
    this.error = '';
    const loader = await this.loadingCtrl.create({
      message: 'Changing password and signing in...',
    });
    loader.present();

    const { password, newPassword } = this.newPasswordForm.value;
    try {
      if (password === newPassword) {
        throw new Error('Please do not re-use the provided Password.');
      }
      await this.userService.completeNewPassword(newPassword, this.challengeAttributes || []);
      loader.dismiss();
      this.router.navigate([this.routeOnSuccess], {
        queryParams: {
          returnUrl: null,
        },
        queryParamsHandling: 'merge',
      });
    } catch (err) {
      this.error = err.message;
    }
  }

  private checkMatchingPasswords(passwordField: string, confirmPasswordField: string) {
    return (group: UntypedFormGroup) => {
      const passwordInput = group.controls[passwordField];
      const passwordConfirmationInput = group.controls[confirmPasswordField];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notMatching: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
}
