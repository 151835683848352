/** @format */

// import _memoize from 'lodash/memoize';
import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import { State, featureKey } from '../reducers/user.reducers';

export const selectUserState = createFeatureSelector<State>(featureKey);

export const getUserLoggedIn = createSelector(selectUserState, (state: State) => state.loggedIn);

export const getUserId = createSelector(selectUserState, (state: State) => state.userId);

export const getUsername = createSelector(
  selectUserState,
  (state: State) => state.username || state.userId // if username not set, return userId
);

export const getUserPublicName = createSelector(
  selectUserState,
  (state: State) => state.name || state.username || state.userId // if username not set, return userId
);

export const getUserAvatar = createSelector(selectUserState, (state: State) => state.avatar);

export const getUserEmail = createSelector(selectUserState, (state: State) => state.email);

export const getUserIdentityId = createSelector(selectUserState, (state: State) => state.identityId);

export const getUserGroups = createSelector(selectUserState, (state: State) => state.groups);

export const getUserBio = createSelector(selectUserState, (state: State) => state.bio);
export const getUserLocation = createSelector(selectUserState, (state: State) => state.location);
export const getUserCountry = createSelector(selectUserState, (state: State) => state.country);

export const getUserNumClipsWatched = createSelector(selectUserState, (state: State) => state.numClipsWatched);

export const getUserNumStacksWatched = createSelector(selectUserState, (state: State) => state.numStacksWatched);
export const getUserNumStacksPublished = createSelector(selectUserState, (state: State) => state.numStacksPublished);

export const getUserInteractedWithDom = createSelector(selectUserState, (state: State) => state.interactedWithDom);

export const getUserBillingInfo = createSelector(getUserId, getUserPublicName, getUserEmail, (userId, name, email) => ({
  userId,
  name,
  email,
}));

export const getUserSubscriptionState = createSelector(selectUserState, (state: State) => ({
  userId: state.userId,
  subscriptionId: state.subscriptionId,
  subscriptionLevel: state.subscriptionLevel,
  subscriptionStatus: state.subscriptionStatus,
  subscriptionMinutes: state.subscriptionMinutes,
}));

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * MEMOIZATION
 * In contrast to a normal NgRx selector where the selector is shared across multiple components,
 * we now have a new instance of the selector every time the selector factory is invoked.
 * This has the effect that we lose the memoization benefits of the selector.
 *
 * For most cases this is fine and you won't notice the difference. But when the selector has to so expensive work,
 * you can add a memoization layer on top as a countermeasure.
 *
 * To accomplish this, we must bring our own memoization method to the table, for example lodash.memoize.
 * After this, we can simply wrap the selector inside of the memoize method.
 * Note that this is a simple example and that you probably don't need to memoize an entity lookup.
 *
 * By doing this, consuming the selector is no different than before.
 *
 * It's important to keep in mind that this builds up an in-memory cache,
 * so that's why it's better that the added entry is disposed of when it's possible.
 *
 * https://lodash.com/docs/4.17.15#memoize
 * Note: The cache is exposed as the cache property on the memoized function.
 * Its creation may be customized by replacing the _.memoize.Cache constructor
 * with one whose instances implement the Map
 * method interface of clear, delete, get, has, and set.
 *
 * @dev This will likely be the solution for Lists items
 *
 * Usage:
 * class MyComponent {
 *  list$ = this.store.select(lists.selectList('listId'));
 * }
 */
// export const selectList = _memoize((id: string) =>
//   createSelector(selectListEntities, (entities) => entities[id])
// );
// export const selectUserState = createFeatureSelector<State, State>(fromUser.featureKey);

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * The currently recommended approach to this is using a factory function
 * ... but this is _not_ memoized
 */

/**
 * usage:
 * this.store.select(entityById(someID))
 */
// export const entityById = (id: string) => createSelector(
//   selectEntities,
//   (entities) => entities[id]
// );

/**
 * Usage:
 * this.store.select(entitiesByIDs(arrayOfIDs)
 */
// export const entitiesByIDs = (ids = []) => createSelector(
//   selectEntities,
//   (entities) => ids.map(id => entities[id])
// );

/**
 * However, a type safe alternative is demonstrated in RFC 2980. Applied to the answer from @jordan-gray:
 * Usage:
 * this.store.select(selectEntity({ id: myId }));
 */
// export const selectEntity = (props: { id: string }) =>
//   createSelector(selectEntities, (entities) => entities[props.id]);
