<!-- [@slideInOut] -->
<ion-toolbar class="drawer {{theme}}">
	<div class="container">
		<h3 class="drawer__heading clickable" (click)="closeDrawer()">
			<ng-container *ngIf="showLabel">
				<span *ngIf="(isLoggedIn$ | async) !== true else loggedInHeading">
					{{ 'AUTH.LOGIN_TO' | translate }}
					{{ 'EDITOR.START_STACK' | translate }}
				</span>
				<ng-template #loggedInHeading>
					<span *ngIf="showingCreateForm else notShowingCreate">
						{{ 'EDITOR.START_STACK' | translate }}
					</span>
					<ng-template #notShowingCreate>
						<span *ngIf="label else defaultLabel">{{ label }}</span>
						<ng-template #defaultLabel>
							<ng-container *ngIf="handleActions else selectDraftText">
								{{ 'STACK.STACK_STORY_DRAFTS' | translate }}
							</ng-container>
							<ng-template #selectDraftText>
								{{ 'STACK.ACTIONS.ADD_ALL_TO_STACK_DRAFTS' | translate }}
							</ng-template>
						</ng-template>
					</ng-template>
				</ng-template>
			</ng-container>

			<div class="float-right">
				<ion-icon name="close"></ion-icon>
			</div>
		</h3>

		<ng-template #loginForm>
			<div class="drawer__items drawer__items--login-form">
				<fs-login-form [routeOnSuccess]="returnUrl"></fs-login-form>
			</div>
		</ng-template>

		<div #drawerItems *ngIf="isLoggedIn$ | async else loginForm" 
			class="drawer__items"
			[class.drawer__items--showing-login-form]="(isLoggedIn$ | async) !== true"
		>
			<div class="drawer__items__item" 
				[class.drawer__items__item--expanded]="showingCreateForm"
				[class.drawer__items__item--selected]="(selectedStackDraft$ | async) === ''"
				[title]="'COMMON.ADD_DRAFT' | translate" 
				(click)="onNewClicked($event)"
			>
				<div *ngIf="selectedItemSuccess && (selectedStackDraft$ | async) === '' else defaultAddButton"
					class="success-check">
					<ion-icon name="checkmark-circle"></ion-icon>
				</div>
				<ng-template #defaultAddButton>
					<ion-fab-button *ngIf="!showingCreateForm">
						<ion-icon name="add-outline"></ion-icon>
					</ion-fab-button>
				</ng-template>
				<ng-container *ngIf="showingCreateForm">
					<div class="drawer__items__item__form-wrap">
						<app-form-stack-create 
							[showSubmit]="false" 
							[showHeading]="false"
							[showErrorTextOnError]="false" 
							[addToStackPayload]="addToStackPayload$ | async"
							(loading)="onCreateFormLoading($event)"
							(createSuccess)="onCreateFormSuccess($event)"
							(formValid)="onCreateFormValid($event)"
						></app-form-stack-create>

					</div>
					<ion-fab-button 
						[disabled]="!isCreateFormValid || isLoadingCreate" 
						(click)="continueNew($event)"
						class="drawer__items__item__continue-btn">
						<ion-spinner *ngIf="isLoadingCreate else defaultContinueIcon"></ion-spinner>
						<ng-template #defaultContinueIcon>
							<ion-icon name="arrow-forward-outline"></ion-icon>
						</ng-template>
					</ion-fab-button>
				</ng-container>
			</div>
			<!-- here item with current stack if it is not a draft -->
			<div *ngIf="publishedItem && publishedItem.stackId" 
				class="drawer__items__item"
				[class.drawer__items__item--selected]="(selectedStackDraft$ | async) === publishedItem.stackId"
				[style.background-image]="'url(' + publishedItem.poster || stackDefaultPoster + ')'" 
				(click)="selectItem($event, publishedItem)">
				<div *ngIf="selectedItemSuccess && (selectedStackDraft$ | async) === publishedItem.stackId"
					class="success-check">
					<ion-icon name="checkmark-circle"></ion-icon>
				</div>
				<div class="drawer__items__item__bottom">
					{{ publishedItem.title }}
					<div class="drawer__items__item__actions">
						<fs-ellipsis-button class="stack-slide__more"
							[class.stack-slide__more--active]="(popoverOpen$ | async) === true"
							(click)="openPopover($event, publishedItem)"></fs-ellipsis-button>
					</div>
				</div>
			</div>
			<!-- draft items... -->
			<div *ngFor="let item of items" class="drawer__items__item"
				[class.drawer__items__item--selected]="item?.stackId && (selectedStackDraft$ | async) === item.stackId"
				[style.background-image]="'url(' + item.poster || stackDefaultPoster + ')'" 
				(click)="selectItem($event, item)">
				<div *ngIf="selectedItemSuccess && item?.stackId && (selectedStackDraft$ | async) === item.stackId" class="success-check">
					<ion-icon name="checkmark-circle"></ion-icon>
				</div>
				<div class="drawer__items__item__bottom">

					<div *ngIf="item?.isCollaborative" class="drawer__items__item__indicator"
						[title]="'STACK.COLLABORATIVE_DRAFT' | translate">
						<ion-icon name="people-outline" class="no-pointer-events"></ion-icon>
					</div>

					{{ item?.title ?? ('STACK.DRAFT' | translate) }}
					<!-- TODO: <br><small>{{ item?.projectName ?? 'Get Project Title from ID' }}</small> -->
					<div class="drawer__items__item__actions">
						<fs-ellipsis-button class="stack-slide__more"
							[class.stack-slide__more--active]="(popoverOpen$ | async) === true"
							(click)="openPopover($event, item)"></fs-ellipsis-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ion-toolbar>