import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@shared/shared.module';
import { TaggerInputModule } from '../../modules/tagger-input/tagger-input.module';
import { VideoPlayerModule } from '../video-player/video-player.module';

import { SelectPosterComponent } from './select-poster/select-poster.component';
import { TrimmerComponent } from './trimmer/trimmer.component';
import { ClipSettingsComponent } from './clip-settings/clip-settings.component';
import { ClipperSegmentBarComponent } from './clipper-segment-bar/clipper-segment-bar.component';
import { ClipperComponent } from './clipper.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        SharedModule,
        TaggerInputModule,
        VideoPlayerModule,
    ],
    declarations: [
        SelectPosterComponent,
        TrimmerComponent,
        ClipSettingsComponent,
        ClipperComponent,
        ClipperSegmentBarComponent,
    ],
    exports: [
        SelectPosterComponent,
        TrimmerComponent,
        ClipSettingsComponent,
        ClipperComponent,
        ClipperSegmentBarComponent,
    ]
})
export class ClipperModule { }
