
<ion-content>
  <ng-container *ngIf="hasEditPermission; then editable else cannotEdit"></ng-container>
  
  <ng-template #cannotEdit>
    <div class="ion-padding ion-text-center ion-margin-top">
      <ion-text *ngIf="!clipTrimLocked; else locked" color="light">
        Sorry, you are not able to Trim this Clip.
        <br><br>
        Only the person that uploaded the Clip can Trim.
      </ion-text>
      <ng-template #locked>
        <ion-text color="secondary">
          Sorry, the owner of this Clip Locked the Trim, so we can't change it.
        </ion-text>
      </ng-template>
      
    </div>
  </ng-template>

  <ng-template #editable>

    <div class="player-wrap">
      <!--[class.video-fullscreen]="isLandscape"-->
      <app-video-player #trimmerPlayer
        [playerId]="playerId" 
        [playlist]="[clip]"
        [autoplay]="false"
        [handlePlaylistDone]="false"
        [useTrimmerControls]="true"
        (loadedMetadata)="onLoadedMetadata($event)"
      ></app-video-player>
    </div>
    <div class="trim-tools ion-padding-vertical">
     
      <!-- range selection -->
      <ion-list class="video-range" lines="none">
        <ion-item>
          <div class="range-labels">
            <ion-label class="label-range-left white">{{ minLabel }}</ion-label>
            <ion-label class="label-range-right white">{{ maxLabel }}</ion-label>
          </div>
          <ion-range #trimRange dualKnobs="true" debounce="50" [value]="rangeObject" [min]="rangeMin" [max]="rangeMax" step="1" (ionChange)="onRangeChange($event)">
            <ion-buttons slot="start" class="fine-trim-btns btns-range-left">
              <!-- todo during trimmer refactor: [disabled]="trimRange.value.lower <= 0" -->
              <ion-button slot="start" fill="clear" class="tiny-less" (click)="timeTinyScrub(trimRange, 'start', 'less')">
                <ion-icon slot="icon-only" name="play-back-circle"></ion-icon>
              </ion-button>
              <!-- todo during trimmer refactor: [disabled]="trimRange.value.lower >= trimRange.upper" -->
              <ion-button slot="start" fill="clear" class="tiny-more" (click)="timeTinyScrub(trimRange, 'start', 'more')">
                <ion-icon slot="icon-only" name="play-forward-circle"></ion-icon>
              </ion-button>

            </ion-buttons>

            <!-- <ion-label slot="start" class="label-range-left white" [style.left]="left+'%'" [ngClass]="{'align-left-label': left > 0}">{{minLabel}}</ion-label>
            <ion-label slot="end" class="label-range-right white" [style.right]="right+'%'" [ngClass]="{'align-right-label': right > 0}">{{maxLabel}}</ion-label> -->
            
            <ion-buttons slot="end" class="fine-trim-btns btns-range-right">
              <!-- todo during trimmer refactor: [disabled]="trimRange.value.upper <= trimRange.value.lower" -->
              <ion-button fill="clear" class="tiny-less" (click)="timeTinyScrub(trimRange, 'end', 'less')">
                <ion-icon slot="icon-only" name="play-back-circle"></ion-icon>
              </ion-button>
              <!-- todo during trimmer refactor: [disabled]="trimRange.value.upper >= rangeMax" -->
              <ion-button fill="clear" class="tiny-more" (click)="timeTinyScrub(trimRange, 'end', 'more')">
                <ion-icon slot="icon-only" name="play-forward-circle"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-range> 
        </ion-item>
      </ion-list>
    
      <div *ngIf="!usedInStacks || enableFeatureTrimUsed" class="instructions ion-text-center">
        Trim clip to less than {{maxVideoLengthText}}
        <br>
        <i>You might need to push play once to start trimming.</i>
      </div>

      <div *ngIf="usedInStacks && !enableFeatureTrimUsed" class="instructions ion-text-center ion-padding-top">
        <b>
          This Clip has already been used in a published Filmstack, so we'll need to create a new Clip.
        </b>
        <span *ngIf="!enableFeatureTrimUsed">
          (<i>Feature in Dev - unable to Save Trim</i>)
        </span>
      </div>

    
      <!-- trim and refresh button -->
      <div class="trim-buttons ion-text-center">
        <ion-button fill="outline" shape="round" color="light" (click)="resetDuration()">
          <ion-icon slot="icon-only" name="arrow-undo-outline"></ion-icon>
        </ion-button>
  
        <ion-button fill="outline" shape="round" color="primary" (click)="saveTrim()" [disabled]="isSaving || (usedInStacks && !enableFeatureTrimUsed)">
          <ion-spinner *ngIf="isSaving" slot="start" name="crescent"></ion-spinner>
          TRIM
        </ion-button>

      </div>
    
    </div>
  </ng-template>

  <div class="tab-spacer"></div>

</ion-content>