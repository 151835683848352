/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || typeof value.substring !== 'function') {
      return '';
    }
    const first = value.substring(0, 1).toUpperCase();
    return first + value.substring(1);
  }
}
