<!-- - FREE PRO EVENT
    do not show crew/guest in status well -->

<ion-button  fill="clear" [color]="color" [disabled]="isDisabled" (click)="onClick($event)" [title]="buttonTitleTranslateKey | translate">
  <ion-note *ngIf="showPlan" [color]="color" class="well ion-text-uppercase">
    <ng-container *ngIf="level else levelLabelTpl">{{ level }}</ng-container>
    <ng-template #levelLabelTpl>
      {{ subscriptionLevel$ | async | translate }}
    </ng-template>
    
  </ion-note>
  <ion-icon *ngIf="showEditButton" [slot]="showPlan? 'end' : 'icon-only'" name="settings-outline" class="no-pointer-events"></ion-icon>
</ion-button>

<!-- [id]="triggerId"
<ion-popover [trigger]="triggerId" 
  triggerAction="hover" 
  mode="ios"
  class="billing-status-options"
  [dismissOnSelect]="true" 
  [showBackdrop]="false"
  [translucent]="true"
>
  <ng-template>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item>Level: {{ subscriptionLevel$ | async }}</ion-item>
        <ion-item>EventDate</ion-item>
        <ion-item>Edit Subscription</ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover> -->
