/** @format */

import { Injectable } from '@angular/core';

@Injectable()
export class NoAuthGuard {
  /**
   * No Auth means if you are logged-in, redirect to the default personal page (studio)
   * This can have alight delay for the store to load
   * > Note: The guard can also tell the router to navigate elsewhere,
   * > effectively canceling the current navigation.
   * > When doing so inside a guard, the guard should return false;
   * https://angular.io/guide/router-tutorial-toh#milestone-5-route-guards
   */
  canActivate(): boolean {
    //Observable<boolean> {

    return true;

    // const isLoggedIn = this.userService.loggedIn;
    // console.log(`canActivate isLoggedIn='${isLoggedIn}'`)
    // if (isLoggedIn) {
    //   this.router.navigate([`/${NAV_PAGE_ON_LOGIN}`]);
    //   return of(false);
    // } else {
    //   return of(true);
    // }

    /*
      NOTE: could not solve the issue where once observable was called,
      ngrx updates do not change the outcome of additional navigations?
    */
    // return this.store.pipe(
    //   select(selectHydrated),
    //   // take(1),
    //   delay(1200), // need to wait for the auth token to be set
    //   withLatestFrom(this.userService.isLoggedIn$),
    //   switchMap(([isHydrated, isLoggedIn]) => {
    //     console.log(`canActivate isHydrated='${isHydrated}' isLoggedIn='${isLoggedIn}'`)
    //     if (isLoggedIn) {
    //       this.router.navigate([`/${NAV_PAGE_ON_LOGIN}`]);
    //       return of(false);
    //     } else {
    //       return of(true);
    //     }
    //   }),
    //   catchError(() => of(false))
    // );
    // v1 had the same issue, not able to update result on additional navs
    // return this.userService.isLoggedIn$.pipe(
    //   // take(1),
    //   switchMap((isLoggedIn) => {
    //     if (isLoggedIn) {
    //       this.router.navigate([`/${NAV_PAGE_ON_LOGIN}`]);
    //       return of(false);
    //     } else {
    //       return of(true);
    //     }
    //   }),
    //   catchError(() => of(false))
    // );
  }
}
