/** @format */

import { Component, Input, OnChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { ToasterService } from '@app/core/services/toaster.service';
import { ClipsCoreService } from '@app/core/services/clips.service';
import { ClipsService } from '@clips/shared/services/clips.service';
import { Clip, ClipVideoFile, convertVideoFileToClip } from '@shared/models/clip.model';
import { Utils } from '@shared/utils';
import { UpdateParam } from '@app/core/api/api-types';
import { Project } from '@projects/shared/project.model';
import { ProjectService } from '@projects/shared/services/';
import { VideoPlayerService } from '@app/modules/video-player/shared/services/video-player.service';

const DEBUG_LOGS = false;
const DEV_NO_SAVE = false; // stop the save process for dev'ing

const PAGE = '[SelectPoster]';

@Component({
  selector: 'app-select-poster',
  templateUrl: './select-poster.component.html',
  styleUrls: ['./select-poster.component.scss'],
})
export class SelectPosterComponent implements OnChanges {
  @Input() clip: Clip;
  @Input() video: ClipVideoFile;
  @Input() posterTime: number = 0;
  @Input() currentUserId: string;
  @Input() project: Project;

  isSaving: boolean = false;
  canEdit: boolean = false;
  isYoutube: boolean = false;

  sliderValue: number = 0;
  playerId = 'select-poster-player';

  minRangeValue: number = 0;
  maxRangeValue: number = 0;
  // rangeLabel: any = "";
  left: number = 0;

  private origPosterTime: number;

  constructor(
    private toaster: ToasterService,
    private clipCoreService: ClipsCoreService,
    private clipsService: ClipsService,
    private projectService: ProjectService,
    private videoPlayerService: VideoPlayerService
  ) {}

  ngOnChanges() {
    this.getClipDetails();
  }

  getClipDetails() {
    if (this.clip) {
      DEBUG_LOGS && console.log(`${PAGE} getClipDetails`, { clip: this.clip });
      if (this.clip.youtube_id || this.clip.youtube_id_nomusic) {
        this.isYoutube = true;
        this.canEdit = false;
        return; // can't change the poster of a youtube video
      }
      this.posterTime = this.clipsService.getPosterTimeFromPoster(this.clip);
      this.origPosterTime = this.posterTime;
    } else if (this.video) {
      // this is a video file, convert it to a clip so we can play it
      this.clip = convertVideoFileToClip(this.video);
    }
    DEBUG_LOGS && console.log(`${PAGE} getClipDetails`, { posterTime: this.posterTime, clip: this.clip });

    if (typeof this.posterTime !== 'number' || this.posterTime < 0) {
      this.posterTime = 0;
    }

    this.checkCanEdit();
  }

  checkCanEdit() {
    // if there's a clip and userId, check for canEdit permission
    if (this.currentUserId && this.clip && this.clip.userId && this.clip.userId === this.currentUserId) {
      this.canEdit = true;
    } else if (this.projectService.isProjectAdmin(this.project, this.currentUserId)) {
      this.canEdit = true;
    } else {
      this.canEdit = false;
    }
  }

  onPlayerReady(value: boolean) {
    DEBUG_LOGS && console.log(`${PAGE} onPlayerReady`, value);
    this.getClipDetails();
  }

  onLoadedMetadata = (event) => {
    DEBUG_LOGS && console.log(`${PAGE} onLoadedMetadata... ${event.playerId}`, event);
    if (event && event.playerId && event.playerId === this.playerId) {
      if (event.payload.duration) {
        if (!this.clip.source) {
          this.clip.source = {};
        }
        this.clip.source.durationInSeconds = event.payload.duration;
        this.clip.duration = Utils.convertSecondsToDuration(event.payload.duration);
        this.loadVideoData();
      }
    }
  };

  loadVideoData() {
    DEBUG_LOGS && console.log(`${PAGE} loadVideoData...`, this.clip);

    const startTime = this.clip.startTime || this.clip.source.startTime || 0;
    const endTime = this.clip.endTime || this.clip.source.endTime || this.clip.source.durationInSeconds;

    // restrict the range to be just the trim (required due to elastic transcoder job)
    this.minRangeValue = startTime;
    this.maxRangeValue = endTime;

    // set poster time
    const time = this.clipsService.getPosterTimeFromPoster(this.clip);
    const posterTimeInt = this.clip.source && this.clip.source.posterTimeInt; // been here before
    if (typeof posterTimeInt === 'number' && posterTimeInt >= startTime) {
      this.posterTime = posterTimeInt;
    } else if (time >= startTime) {
      this.posterTime = time || startTime || 0;
    } else {
      this.posterTime = startTime || 0;
    }
    DEBUG_LOGS &&
      console.log(`${PAGE} loadVideoData... posterTime:`, {
        posterTime: this.posterTime,
        posterTimeInt,
        time,
        startTime,
      });
    this.videoPlayerService.seekTo(this.playerId, this.posterTime);

    // set range's label
    // let rangeArray = [this.posterTime.toString()];
    // this.rangeLabel = Utils.getTotalDuration(rangeArray);

    this.left = (this.posterTime * 100) / this.clip.source.durationInSeconds;
    this.sliderValue = this.posterTime;
  }

  /**
   * ionRange ionChange event
   */
  onRangeChange(event) {
    if (!event || !event.detail || typeof event.detail.value !== 'number') {
      console.warn(`${PAGE} onRangeChange event.detail.value NaN!`, event);
      return;
    }
    DEBUG_LOGS && console.log(`${PAGE} onRangeChange ev:`, event.detail);
    this.videoPlayerService.pause(this.playerId);

    this.posterTime = event.detail.value;
    this.clip.source.posterTimeInt = this.posterTime;

    // set poster time
    this.videoPlayerService.seekTo(this.playerId, this.posterTime);

    // set range's label
    // let rangeArray = [this.posterTime.toString()];
    // this.rangeLabel = Utils.getTotalDuration(rangeArray);

    // change position of label
    this.left = (this.posterTime * 100) / this.clip.source.durationInSeconds;
  }

  /**
   * on Submit - select and save
   */
  selectImage() {
    if (typeof this.posterTime !== 'number' || this.posterTime < 0) {
      this.toaster.present(`Oops! Poster Time is not valid. Please reload and try again.`);
      return;
    }

    if (this.origPosterTime === this.posterTime) {
      this.toaster.present(`Poster time did not change, no save necessary.`);
      DEBUG_LOGS &&
        console.log(`${PAGE} savePosterTime No Change needed`, {
          origPosterTime: this.origPosterTime,
          posterTime: this.posterTime,
        });
      return;
    }

    this.savePosterTime();
  }

  /**
   * Do the save..
   */
  savePosterTime() {
    this.isSaving = true;

    const clipUpdates: UpdateParam[] = [];

    const newPoster = this.clipsService.updatePosterToPosterTime(this.clip, this.posterTime);

    if (!newPoster) {
      this.isSaving = false;
      this.toaster.present(`Hmm, Poster not updated - please try again.`);
      return;
    }

    clipUpdates.push({
      prop: 'poster',
      value: newPoster,
    });

    if (DEV_NO_SAVE) {
      DEBUG_LOGS && console.log(`${PAGE} savePosterTime:`, { clipUpdates });
      this.toaster.present(`Feature in DEV: posterTime NOT saved: '${this.posterTime}'`);
      setTimeout(() => {
        this.isSaving = false;
      }, 1000);
      return;
    }

    this.clipCoreService
      .updateClip(this.clip, clipUpdates)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.toaster.present(`Poster Updated.`);
        },
        error: (err) => {
          this.isSaving = false;
          console.warn(err);
          this.toaster.present(`Oops, Poster not updated - please try again.`);
        },
      });
  }
}
