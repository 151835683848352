/** @format */

/** to handle 'require', app needs 'resolveJsonModule: true' on tsconfig.app */
declare const require: NodeRequire;
const appVersion = require('../../package.json').version;

export const environment = {
  production: true,
  name: 'prod',
  version: appVersion,
  chargebee: {
    // endpoint: 'https://i6997h4qhh.execute-api.us-west-2.amazonaws.com/dev/chargebee',
    // site: 'filmstacker-test',
    // domain: 'https://filmstacker-test.chargebee.com', 
    /** @TODO: change to prod endpoint! */
    endpoint: 'https://nj5kbjx6sd.execute-api.us-west-2.amazonaws.com/one/chargebee',
    site: 'filmstacker',
    domain: 'https://filmstacker.chargebee.com', // optional parameter, needed if custom domain is enabled for your site
  },
  youtubeApiKey: 'AIzaSyBdDLczLbyEvaodP8yrCctLi_IPNr-nRMc',
  youtubeApiKeyIos: 'AIzaSyBaS5JaHw6UWlARLq2x8RVddWCp_0nOyv8',
  youtubeApiKeyAndroid: 'AIzaSyDMBHy0UVljDA1Z5ZCGmMEeGaLE7nrDHGY',
  tokensWidgetsApiUrl: 'https://7wmi6r8und.execute-api.us-west-2.amazonaws.com/one', // ApiId: 7wmi6r8und
  aws: {
    identityPoolId: 'us-west-2:68c48e33-2124-4277-9d7c-c72850a66d21',
    authRegion: 'us-west-2',
    userPoolId: 'us-west-2_UyteEhEtX',
    userPoolWebClientId: '23qdvd1u9irbmlhqheuj1nvujr',
    appSyncGraphQLEndpoint: 'https://jprfbopf45hddnoq4vfgmfqeiy.appsync-api.us-west-2.amazonaws.com/graphql',
    appSyncRegion: 'us-west-2',
    s3Bucket: 'filmstacker-amp-v1-userfiles-master',
    s3Region: 'us-west-2',
    pinpointAppId: 'a209811e40e042fba14fb85171d1ca5b',
  },
  GA_MEASUREMENT_ID: 'GTM-TMR9FKM',
  isAnalyticsEnabled: true
};

/**
 * Filestack Config
 * https://www.filestack.com/docs/uploads/pickers/web/
 * https://github.com/filestack/filestack-angular
 * 
 * https://filestack.github.io/filestack-js/interfaces/pickeroptions.html
 */
 export const filestackConfig = {
  apiKey: 'Ad1QJeB3ETxiEeMt5XRaZz',
  storeTo: {
    clip: {
      /**
       * Set container path. Indicate a folder by adding a trailing slash. Without a trailing slash all files will be stored to the same object.
       * https://s3.console.aws.amazon.com/s3/buckets/filmstacker-amp-v1-userfiles-master?region=us-west-2&prefix=public/uploads/filestack/videos/&showversions=false
       */
      path: 'public/uploads/filestack/videos/',
      /**
       * Specify storage container. - S3 name 
       *   content.filmstacker.com (us-west-2)
       *   filmstacker-amp-v1-userfiles-master (us-west-2)
       *   transcode-pipeline-source-m1mlrptkfy16 (us-east-1)
       */
      container: 'filmstacker-amp-v1-userfiles-master',
      /** S3 region */
      region: 'us-west-2',
      /** S3 container access. 'public' or 'private'. */
      // access: '',
    },
    avatar: {
      path: 'public/avatars/', // <user-id.jpg>
      container: 'content.filmstacker.com',
      region: 'us-west-2',
      /** cdn url for delivery */
      cdn: 'https://content.filmstacker.com/',
      /* This must align with the FsrApi-ImageManager env var */
      apiTriggerSuffix: '__orig',
    },
    poster: {
      path: 'public/posters/', // <project-id>
      container: 'content.filmstacker.com',
      region: 'us-west-2',
      /** cdn url for delivery */
      cdn: 'https://content.filmstacker.com/',
    }
  }
};
