/**
 * Youtube API Service
 * based on: https://piratesofjs.wordpress.com/2017/02/13/ionic-2-app-tutorial-a-simple-youtube-player/
 *
 * ref:
 * https://stackoverflow.com/questions/39022009/youtube-api-get-tags-for-all-videos-with-playlist-query
 *
 * @format
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAX_DESC_LENGTH } from '../../shared/models/clip.model';
import { Utils } from '../../shared/utils';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

const DEBUG_LOGS = false;

const PAGE = '[YoutubeService]';

@Injectable({
  providedIn: 'root',
})
export class YoutubeService {
  googleApiKey: string = '';
  maxResults = 5;
  queryUrl = 'https://www.googleapis.com/youtube/v3/search?part=id,snippet&q=';

  constructor(private http: HttpClient) {
    if (environment.youtubeApiKey) {
      this.googleApiKey = environment.youtubeApiKey;
      // console.log(`${PAGE} youtubeApiKey:`, this.googleApiKey);
    } else {
      console.warn(`${PAGE} env NO API KEY!!`, environment);
    }
  }

  /**
   * helper getters for videoMetadata
   */

  getMetadataTitle(data) {
    return data && data.snippet && data.snippet.title ? data.snippet.title : '';
  }
  getMetadataDescription(data) {
    const o = Utils.tryParseJSON(data);
    DEBUG_LOGS && console.log('get desc', o);
    return o && o.snippet && o.snippet.description ? o.snippet.description.substring(0, MAX_DESC_LENGTH) : '';
  }

  getMetadataPublishDate(data) {
    return data && data.snippet && data.snippet.publishedAt ? data.snippet.publishedAt : '';
  }

  /**
   * The length of the video.
   * https://developers.google.com/youtube/v3/docs/videos#properties
   * The property value is an ISO 8601 duration.
   * For example, for a video that is at least one minute long and less than one hour long,
   * the duration is in the format PT#M#S, in which the letters PT indicate that the value
   * specifies a period of time, and the letters M and S refer to length in minutes and seconds, respectively.
   * The # characters preceding the M and S letters are both integers that specify the
   * number of minutes (or seconds) of the video.
   * For example, a value of PT15M33S indicates that the video is 15 minutes and 33 seconds long.
   *
   * If the video is at least one hour long, the duration is in the format PT#H#M#S,
   * in which the # preceding the letter H specifies the length of the video in hours and
   * all of the other details are the same as described above.
   * If the video is at least one day long, the letters P and T are separated,
   * and the value's format is P#DT#H#M#S. Please refer to the ISO 8601 specification for complete details.
   */
  getMetadataDuration(data) {
    if (data && data.contentDetails && data.contentDetails.duration) {
      const dur = this.parseISO8601Duration(data.contentDetails.duration);
      const padStart = (val) => ('0' + val).slice(-2);
      const hours = dur.days * 24 + dur.hours;
      return `${padStart(hours)}:${padStart(dur.minutes)}:${padStart(dur.seconds)}`;
    }
    return '';
  }
  // example with all options: https://stackoverflow.com/questions/14934089/convert-iso-8601-duration-with-javascript
  // private iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private iso8601DurationRegex = /P(?:([.,\d]+)D)?(?:T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?)?/; // simpler
  parseISO8601Duration(iso8601Duration) {
    const matches = iso8601Duration.match(this.iso8601DurationRegex);
    return {
      days: matches[1] === undefined ? 0 : matches[1],
      hours: matches[2] === undefined ? 0 : matches[2],
      minutes: matches[3] === undefined ? 0 : matches[3],
      seconds: matches[4] === undefined ? 0 : matches[4],
    };
  }

  /**
   * get the video metadata for an id
   * @param id 
   * retrieves information about a group of videos. 
   * The id parameter value is a comma-separated list of YouTube video IDs. 
   * You might issue a request like this to retrieve additional information about the items in a playlist or the results of a search query.
   * https://developers.google.com/youtube/v3/docs/videos/list
   
   * 
   * https://www.googleapis.com/youtube/v3/videos?key={API-key}&fields=items(snippet(title,description,tags))&part=snippet&id={video_id}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getVideoMetadata(ids: string[]): Promise<any[]> {
    if (!ids || ids.length < 1) {
      return Promise.reject(`No Youtube ID provided.`);
    }
    if (!this.googleApiKey) {
      console.warn(`${PAGE} env NO API KEY!!`, environment);
      return Promise.reject(`Config error.`);
    }
    // comma-separated list of YouTube video IDs ex: Ks-_Mh1QhMc,c0KYU2j0TM4,eIho2S0ZahI
    const csvIds = ids.join(',');
    /* https://developers.google.com/youtube/v3/docs/videos
      Quota Cost
      2 - The snippet object contains basic details about the video, 
          snippet = {title, description, thumbnails, publishedAt, channelId, channelTitle, tags, category

      2 - The contentDetails object contains information about the video content, 
          contentDetails = { duration, definition (hd or sd),  caption (true/false), licensedContent, regionRestriction, contentRating

      2 - The status object contains information about the video's uploading, processing, and privacy statuses
          status = { embeddable

      2 - The statistics object contains statistics about the video
          statistics = { viewCount, likeCount, dislikeCount, favoriteCount, commentCount

      2 - The recordingDetails object encapsulates information about the location, date and address where the video was recorded
          recordingDetails = { location, recordingDate, 

        &fields=items(snippet(title,description,tags))
    */
    const endpoint =
      `https://www.googleapis.com/youtube/v3/videos` +
      `?key=${this.googleApiKey}` +
      // don't need the thumbnails from snippet, shrink response (removed: thumbnails,liveBroadcastContent,localized)
      //  + `&fields=items(id,snippet,contentDetails,status(embeddable),statistics,recordingDetails(location, recordingDate))`
      `&fields=items(id,snippet(publishedAt,channelId,title,description,channelTitle,categoryId,defaultAudioLanguage),contentDetails,status(embeddable),statistics,recordingDetails(location, recordingDate))` +
      `&part=id,snippet,contentDetails,status,statistics,recordingDetails` +
      `&id=${csvIds}`;
    // this.http.get(endpoint, { observe: 'response' }) // get full response (with headers)
    return lastValueFrom(this.http.get(endpoint))
      .then((data) => {
        // success path
        DEBUG_LOGS && console.log(`${PAGE} getVideoMetadata:`, data);
        // handle multiple & error checking...
        return data && data['items'] ? data['items'] : [];
        // return items.map(item => {
        //   return item.snippet || { error: "No Youtube snippet found" };
        // });
      })
      .catch((error) => {
        console.warn(`${PAGE} getVideoMetadata error:`, error);
        return [{ error: 'Caught Error' }];
      });
    // .subscribe(
    //   (data: any) => {  // success path
    //     console.log(`${PAGE} getVideoMetadata:`,data);
    //   },
    //   error => { // error path
    //     console.log(`${PAGE} getVideoMetadata error:`,error);
    //   }
    // );
  }

  /** THUMBNAILS
   * if need api: https://developers.google.com/youtube/v3/docs/thumbnails
   */

  /** 
   * getThumbnail via http url by videoId
   * @param videoId 
   * @param res 
   * 
      The default thumbnail for a video – or a resource that refers to a video, such as a playlist item or search result 
      – is 120px wide and 90px tall. The default thumbnail for a channel is 88px wide and 88px tall.
      https://img.youtube.com/vi/5NQUyrlHYB0/default.jpg
      A higher resolution version of the thumbnail image. For a video (or a resource that refers to a video), 
      this image is 320px wide and 180px tall. For a channel, this image is 240px wide and 240px tall.
      https://img.youtube.com/vi/5NQUyrlHYB0/mqdefault.jpg
      A high resolution version of the thumbnail image. For a video (or a resource that refers to a video), 
      this image is 480px wide and 360px tall. For a channel, this image is 800px wide and 800px tall.
      https://img.youtube.com/vi/5NQUyrlHYB0/hqdefault.jpg
      An even higher resolution version of the thumbnail image than the high resolution image. 
      This image is available for some videos and other resources that refer to videos, like playlist items or search results. 
      This image is 640px wide and 480px tall.
      https://img.youtube.com/vi/5NQUyrlHYB0/sddefault.jpg
      The highest resolution version of the thumbnail image. 
      This image size is available for some videos and other resources that refer to videos, like playlist items or search results. 
      This image is 1280px wide and 720px tall.
      https://img.youtube.com/vi/5NQUyrlHYB0/maxresdefault.jpg
   */
  getThumbnail(videoId: string, res: string = 'default'): { url: string; width: number; height: number } {
    const rootUrl = 'https://i.ytimg.com/vi/'; // saw this url in api video list response 26 oct 18
    switch (res) {
      case 'low': // 320px wide and 180px tall. For a channel, this image is 240px wide and 240px tall.
        return {
          width: 320,
          height: 180,
          url: `${rootUrl}${videoId}/mqdefault.jpg`,
        };
      case 'med': // 480px wide and 360px tall. For a channel, this image is 800px wide and 800px tall.
        return {
          width: 480,
          height: 360,
          url: `${rootUrl}${videoId}/hqdefault.jpg`,
        };
      case 'high': // 640px wide and 480px tall.
        return {
          width: 640,
          height: 480,
          url: `${rootUrl}${videoId}/sddefault.jpg`,
        };
      case 'maxres': // 1280px wide and 720px tall.
        return {
          width: 1280,
          height: 720,
          url: `${rootUrl}${videoId}/maxresdefault.jpg`,
        };
      case 'default': //120px wide and 90px tall
      default:
        return {
          width: 120,
          height: 90,
          url: `${rootUrl}${videoId}/default.jpg`,
        };
    }
  }

  // private YOUTUBE_REGEX = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/; // id=parsed[2]
  // including the case where it's just an id string
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
  private YOUTUBE_REGEX = /(^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*)|([^#\&\?]*)/; // id=parsed[3] || parsed[4]
  /**
   * get the YouTube ID from a url string
   * @param url
   * https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url/27728417#27728417
   * or, /(?:(?:\?|&)v=|embed\/|v\/|youtu\.be\/)((?!videoseries)[a-zA-Z0-9_]*)/g
   */
  getYoutubeVideoIdFromUrl(url: string) {
    const parsed = url.match(this.YOUTUBE_REGEX);
    if (parsed && (parsed[3] || parsed[4])) {
      // console.log(parsed[3] || parsed[4]);
      return parsed[3] || parsed[4];
    } else {
      console.log(`${PAGE} youtube id not found in url:`, url, parsed);
      return '';
    }
  }

  // private YOUTUBE_PLAYLIST_REGEX = /(?:youtube\.com.*(?:\?|&)(?:list)=)((?!videoseries)[a-zA-Z0-9_]*)/g;
  // https://stackoverflow.com/questions/49535029/regex-get-youtube-playlist-id
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
  private YOUTUBE_PLAYLIST_REGEX = /[&?]list=([^&]+)/i;
  /** (not tested yet)
   * get the YouTube Playlist ID from a url string
   * @param url
   * https://stackoverflow.com/questions/32295157/regex-to-extract-both-video-id-or-playlist-id-from-youtube-url
   * only grabs list:    /(?:(?:\?|&)list=)((?!videoseries)[a-zA-Z0-9_]*)/g
   * only youtube lists: /(?:youtube\.com.*(?:\?|&)(?:list)=)((?!videoseries)[a-zA-Z0-9_]*)/g
   */
  getYoutubePlaylistIdFromUrl(url: string) {
    const parsed = url.match(this.YOUTUBE_PLAYLIST_REGEX);
    if (parsed && parsed[1]) {
      // console.log(parsed);
      return parsed[1];
    } else {
      console.log(`${PAGE} youtube playlist id not found in url:`, url, parsed);
      return '';
    }
  }

  testGetYoutubeVideoIdFromUrl() {
    const testUrls = [
      'http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index',
      'http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o',
      'http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0',
      'http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s',
      'http://www.youtube.com/embed/0zM3nApSvMg?rel=0',
      'http://www.youtube.com/watch?v=0zM3nApSvMg',
      'http://youtu.be/0zM3nApSvMg',
      '//www.youtube-nocookie.com/embed/up_lNV-yoK4?rel=0',
      'http://www.youtube.com/user/Scobleizer#p/u/1/1p3vcRhsYGo',
      'http://www.youtube.com/watch?v=cKZDdG9FTKY&feature=channel',
      'http://www.youtube.com/watch?v=yZ-K7nCVnBI&playnext_from=TL&videos=osPknwzXEas&feature=sub',
      'http://www.youtube.com/ytscreeningroom?v=NRHVzbJVx8I',
      'http://www.youtube.com/user/SilkRoadTheatre#p/a/u/2/6dwqZw0j_jY',
      'http://youtu.be/6dwqZw0j_jY',
      'http://www.youtube.com/watch?v=6dwqZw0j_jY&feature=youtu.be',
      'http://youtu.be/afa-5HQHiAs',
      'http://www.youtube.com/user/Scobleizer#p/u/1/1p3vcRhsYGo?rel=0',
      'http://www.youtube.com/watch?v=cKZDdG9FTKY&feature=channel',
      'http://www.youtube.com/watch?v=yZ-K7nCVnBI&playnext_from=TL&videos=osPknwzXEas&feature=sub',
      'http://www.youtube.com/ytscreeningroom?v=NRHVzbJVx8I',
      'http://www.youtube.com/embed/nas1rJpm7wY?rel=0',
      'http://www.youtube.com/watch?v=peFZbP64dsU',
      'http://youtube.com/v/dQw4w9WgXcQ?feature=youtube_gdata_player',
      'http://youtube.com/vi/dQw4w9WgXcQ?feature=youtube_gdata_player',
      'http://youtube.com/?v=dQw4w9WgXcQ&feature=youtube_gdata_player',
      'http://www.youtube.com/watch?v=dQw4w9WgXcQ&feature=youtube_gdata_player',
      'http://youtube.com/?vi=dQw4w9WgXcQ&feature=youtube_gdata_player',
      'http://youtube.com/watch?v=dQw4w9WgXcQ&feature=youtube_gdata_player',
      'http://youtube.com/watch?vi=dQw4w9WgXcQ&feature=youtube_gdata_player',
      'http://youtu.be/dQw4w9WgXcQ?feature=youtube_gdata_player',
      'dQw4w9WgXcQ',
    ];

    let failures = 0;
    testUrls.forEach((url) => {
      const res = this.getYoutubeVideoIdFromUrl(url);
      if (!res) {
        failures++;
      }
    });
    if (failures) {
      console.error(`testGetYoutubeIdFromUrl result: ${failures} failures`);
    }
  }
}
