/**
 * @todo migrate from app/shared/models/clip.model.ts
 * @format
 */

import { CLIP_TITLE_MAX_LENGTH_TRANSCODING } from '@app/app.config';
import { Utils } from '@shared/utils';
import { Clip } from '@shared/models/clip.model';

/** MVP-1061 ensure unique clip ids */
let clipIndex = 1;

/**
 * take an uploaded Video filename and convert to app filename
 */
export const makeSafeClipFilename = (str): string => {
  const defaultName = 'filmclip';
  const filename = typeof str === 'string' && str.length > 0 ? str : defaultName;

  let safeFilename = Utils.urlSafeString(Utils.removeFileExt(filename)).trim().toLowerCase();
  /** MVP-1061 remove fullsizerender */
  safeFilename = safeFilename.replace(/fullsizerender/g, '');

  if (filename.length > CLIP_TITLE_MAX_LENGTH_TRANSCODING) {
    const split = CLIP_TITLE_MAX_LENGTH_TRANSCODING / 2;
    const front = str.substring(0, split - 1); // leave a char for the _
    const back = str.substring(str.length - split);
    return `${front}_${back}-${clipIndex++}`;
  }
  return `${safeFilename || defaultName}-${clipIndex++}`;
};

/**
 * create a filename with userId and add Date
 * @param userId userId
 * @param filename full filename.ext
 */
export const makeCaptureClipFilename = (userId = 'anon', filename = 'filmclip') => {
  const sep = '_-_';
  const safeName = Utils.urlSafeFileName(filename).trim().toLowerCase();
  const name = makeSafeClipFilename(Utils.removeFileExt(safeName));
  return (
    Utils.urlSafeString(userId + sep + name + '_' + Utils.urlDate(new Date())) +
    '.' +
    Utils.getFileExt(safeName)
  ).toLowerCase();
  // return Utils.urlSafeString(username) + sep + (addDate ? Utils.shortUrlDate(new Date()) + sep : '') + name;
};

export const sortByTitle = (clips: Partial<Clip>[]) => {
  try {
    const ordered = clips.map((clip) => {
      if (!clip) {
        return {
          ...clip,
          sort: '',
        };
      }
      if (clip.title) {
        return {
          ...clip,
          sort: clip.title.toLowerCase(),
        };
      }
      return {
        ...clip,
        sort: clip.id.toLowerCase(),
      };
    });
    return ordered.sort((a, b) => (a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0));
  } catch (error) {
    console.warn(error);
    throw new Error(`Uh oh! We ran into an issue - please try again.`);
  }
};
