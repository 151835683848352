<div class="qr-wrap">
  <qrcode 
    *ngIf="qrCode" 
    [qrdata]="qrCode" 
    [width]="256" 
    [colorDark]="colorDark"
    [colorLight]="colorLight"
    [errorCorrectionLevel]="errorCorrectionLevel"
    (qrCodeURL)="onChangeURL($event)"
  ></qrcode>
  
  <a *ngIf="showDownload" [href]="qrCodeDownloadLink" download="qrcode" title="Download">
    <ion-fab-button>
      <ion-icon name="download"></ion-icon>
    </ion-fab-button>
  </a>
</div>


