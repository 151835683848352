/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  OnboardingTypes,
  TRANSLATE_ROOT,
  DEFAULT_BACKGROUND_URL,
  DEFAULT_BACKGROUND_POSITION,
} from '../shared/onboarding.model';
import { OnboardingService } from '../shared/services/onboarding.service';

@Component({
  selector: 'app-onboarding-landing',
  templateUrl: './onboarding-landing.component.html',
  styleUrls: ['./onboarding-landing.component.scss'],
})
export class OnboardingLandingComponent implements OnInit, OnDestroy {
  translatePath = TRANSLATE_ROOT;
  actionUrl = '/signup';
  routeId: OnboardingTypes;
  backgroundUrl = DEFAULT_BACKGROUND_URL;
  backgroundPosition = DEFAULT_BACKGROUND_POSITION;

  private onDestroy$ = new Subject<void>();

  constructor(private onboardingService: OnboardingService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.onboardingService.routeOnboardingMeta$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ routeId, translatePath = '', actionUrl = '', backgroundUrl = '', backgroundPosition = '' }) => {
        this.routeId = routeId;
        this.translatePath = translatePath;
        this.actionUrl = actionUrl + 'signup';
        this.backgroundUrl = backgroundUrl;
        this.backgroundPosition = backgroundPosition;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
