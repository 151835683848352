/** @format */

import { createAction, props, union } from '@ngrx/store';

export const hydrated = createAction('[Environ] Hydrated');
export const reset = createAction('[Environ] Reset');
export const updateSearchStart = createAction(
  '[Environ] Update SearchStart',
  props<{ key: string; vals?: string[] }>()
);
export const resetSearchStart = createAction('[Environ] Reset SearchStart');
export const activeProject = createAction('[Environ] Set Active Project', props<{ projectId: string }>());
export const currentClip = createAction('[Environ] Set Current Clip', props<{ projectId: string; id: string }>());
/** @deprecated */
export const currentStack = createAction(
  '[Environ] Set Current Stack',
  props<{ projectId: string; stackId: string }>()
);

export const searchProjects = createAction('[Environ] Set SearchProjects', props<{ ids: string[] }>());

export const recentProjects = createAction('[Environ] Set RecentProjects', props<{ ids: string[] }>());

// do not export return value
const actions = union({
  reset,
  hydrated,
  updateSearchStart,
  resetSearchStart,
  activeProject,
  currentClip,
  currentStack,
  searchProjects,
  recentProjects,
});

// export only type
export type ActionsUnion = typeof actions;
