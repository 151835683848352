<fs-base-modal #modal mode="light">
  <div class="container" style="height: 100%;">
    <div *ngIf="!iframeSrc" class="ion-padding ion-text-center">
      <ion-spinner></ion-spinner>
    </div>
    <iframe *ngIf="iframeSrc" id="FilmstackerCbCheckout"
      style="width:100%;border:none;padding:0;"
      height="100%"
      title="Filmstacker Checkout"
      name="filmstacker-checkout"
      [src]="iframeSrc | safeUrl">
      <!-- iframeSrc | safeUrl -->
      <p>Your browser does not support iframes.</p>
      <p>
        Open in a new page: <a [href]="iframeSrc | safeUrl" target="_filmstacker-cb">{{ iframeSrc | safeUrl }}</a>
      </p>
    </iframe>
  </div>
</fs-base-modal>
