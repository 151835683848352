<ion-app>
  <ion-menu side="end" swipeGesture="false" menuId="user-menu" contentId="main-content" type="overlay">
    <ion-content forceOverscroll="false">
      <app-user-menu [menuId]="'user-menu'"></app-user-menu>
    </ion-content>
  </ion-menu>

  <ion-router-outlet
    id="main-content"
    (window:orientationchange)="onOrientationChange()"
    (window:resize)="onWindowResize()"
  ></ion-router-outlet>
  <!--
    Async pipes should not be negated. 
    Use (observable | async) === (false | null | undefined) to check its value instead  
    *ngIf="(gdprAccepted$ | async) === (false || null || undefined)"
    @angular-eslint/template/no-negated-async
  -->
  <app-consent-banner
    *ngIf="(gdprAccepted$ | async) !== true && (hydrating$ | async) !== true"
    (accept)="onGdprAccept()"
  ></app-consent-banner>
</ion-app>

