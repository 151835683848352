<div class="container">
  <h2 *ngIf="showTitle">
    {{ translatePath + 'CTA_MEMBERS_1' | translate }}
  </h2>
  <h1 *ngIf="showMessage">
    {{ translatePath + 'GET_STARTED_MESSAGE' | translate }} 
  </h1>
  <div *ngIf="actionUrl" class="content-actions">
    <ion-button color="light" size="large" [routerLink]="actionUrl">
      {{ translatePath + 'CTA_MEMBERS_1' | translate }}
    </ion-button>
  </div>
</div>
