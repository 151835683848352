/** @format */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Stack } from '@shared/models/stack.model';
import {
  ShareableItem,
  SHARING_METHOD,
  SocialSharingService,
  socialSharingServiceProvider,
} from '@app/core/social-sharing';
import { ModalController } from '@ionic/angular';
import { ITEM_TYPE } from '@shared/models/layout.model';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { getId as getStackId } from '@store/selectors/stacks.selectors';
import { selectStackEntities } from '@store/selectors/stacks.selectors';

const OPTIMIZING_TIMEOUT_BEFORE_WATCH = 9000;

@Component({
  selector: 'app-publish-success-modal',
  templateUrl: './publish-success-modal.component.html',
  styleUrls: ['./publish-success-modal.component.scss'],
  providers: [socialSharingServiceProvider],
})
export class PublishSuccessModalComponent implements OnInit, OnDestroy {
  @Input() stack: Stack;

  optimizingStack = true;
  shareItem: ShareableItem;
  public shareToOptions = SHARING_METHOD;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private socialSharingService: SocialSharingService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit(): void {
    if (!this.stack || !this.stack.stackId) {
      console.warn(`Missing stack info?`, this.stack);
      return;
    }
    // console.log(this.stack);
    this.shareItem = {
      id: this.stack.stackId,
      title: this.stack.title,
      description: this.stack.description,
      shareUrl: this.stack.shareUrl,
      // tags: ['CreateTogether'],
      type: ITEM_TYPE.Stacks,
    };

    /**
     * @todo refactor this to watchById..
     * watch for updates to hlsSrc
     */
    try {
      this.store
        .select(selectStackEntities)
        .pipe(
          takeUntil(this.onDestroy$),
          filter(
            (entities) =>
              entities &&
              entities[getStackId(this.stack.projectId, this.stack.stackId)] &&
              entities[getStackId(this.stack.projectId, this.stack.stackId)].stackId.length > 0
          ),
          map((entities) => entities[getStackId(this.stack.projectId, this.stack.stackId)])
        )
        .subscribe((entity) => {
          if (entity && entity.hlsSrc) {
            this.optimizingStack = false;
          }
        });
    } catch (error) {
      console.error(`Caught Error:`, error);
    }
    // allow to watch if that didn't work..
    setTimeout(() => {
      this.optimizingStack = false;
    }, OPTIMIZING_TIMEOUT_BEFORE_WATCH);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  share(shareTo: SHARING_METHOD, event?: MouseEvent) {
    console.log({ shareTo, shareItem: this.shareItem });
    this.socialSharingService
      .shareTo(this.shareItem, shareTo)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  watchStack() {
    this.dismiss({ action: 'play' });
  }

  newStack() {
    this.dismiss({ action: 'clear' });
  }

  dismiss(data = {}) {
    this.modalCtrl.dismiss(data);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
