/** @format */
import { extensionsMap } from './extensions';
// import moment from 'moment';

/**
 * Utils for the app
 * ref: https://stackoverflow.com/questions/32790311/how-to-structure-utility-class
 * 
 * USAGE:
  import { Utils } from './app/shared/utils'
 
  export class MyClass {
      constructor()
      {
          Utils.doSomething('test');
      }
  }
 
    // default export => named export
    //   https://blog.angularindepth.com/making-your-angular-2-library-statically-analyzable-for-aot-e1c6f3ebedd5
 */
export class Utils {
  // static doSomething(val: string) { return val; }
  // static doSomethingElse(val: string) { return val; }

  // const REGEX_EMAIL = /^\S+@\S+$/; // no period
  // const REGEX_EMAIL = /^\S+@\S+\.\S+$/; // with period
  static REGEX_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // with period, only one @ sign

  static getAppVersion(version: string, isProduction: boolean) {
    return isProduction ? 'v' + version : '[dev]';
  }

  /**
   * Tests navigator.userAgent for CriOS to exist as well as iphone|ipad|ipod
   * @returns :boolean true if is Chrome iOS
   */
  static isChromeIOS() {
    try {
      return /CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent);
    } catch (error) {
      console.warn('unable to access window.navigator', error);
      return false;
    }
  }

  /**
   * Tests for serviceWorker support, and verifies !isChrome iOS
   * based on https://developer.mozilla.org/en-US/docs/Web/API/Navigator/serviceWorker
   */
  static isServiceWorkersSupported() {
    return navigator && 'serviceWorker' in navigator && !this.isChromeIOS();
  }

  /**
   * event.stopPropagation if it exists
   * @param event
   */
  static tryStopPropagation(event) {
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }
  }
  static tryPreventDefault(event) {
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
  }
  /**
   * Try Parsing JSON, or return string
   */
  static tryParseJSON(string) {
    let res = string;
    try {
      res = JSON.parse(string);
    } catch (error) {
      return res;
    }
    return res;
  }

  static isEmptyObj(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

  static objToString(obj: object): string {
    let str = '';
    for (const [p, val] of Object.entries(obj)) {
      str += `${p}: ${val},`;
    }
    return str;
  }

  /**
   * File Upload to Blob
   * @param dataURI
   * @param dataType string image type, defaults to jpg
   */
  static dataURItoBlob(dataURI, dataType: string = 'image/jpeg') {
    // code adapted from: http://stackoverflow.com/questions/33486352/cant-upload-image-to-aws-s3-from-ionic-camera
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: dataType });
  }

  /**
   * Convert the file type to an extension
   * @param fileType string
   */
  static convertFileTypeToExt(fileType: string): string {
    switch (fileType) {
      case 'image/jpeg':
        return 'jpg';
      case 'image/png':
        return 'png';
      case 'image/gif':
        return 'gif';

      case 'video/quicktime':
      case 'video/mov':
        return 'mov';
      case 'video/mp4':
        return 'mp4';
      case 'video/webm':
        return 'webm';
      case 'video/ogg':
        return 'ogg';

      case 'audio/mpeg':
        return 'mp4';
      case 'audio/webm':
        return 'webm';
      case 'audio/ogg':
        return 'ogg';
      case 'audio/wav':
        return 'wav';
      default:
        return '';
    }
  }

  static convertBytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(2);
  }
  static convertMBToBytes(size: number) {
    return size * 1024 * 1024;
  }

  /**
   * makes a string url-safe
   * @param s
   */
  static urlSafeString(s: string): string {
    //REGEX Explanation:
    // first replace spaces with dashes, then
    // ^                 # the beginning of the string
    // [a-zA-Z0-9_-]     #  any character of: 'a' to 'z', 'A' to 'Z', '0' to '9', '_', '-' (0 or more times)
    // gi                # globally, ignore case
    // replace anything not in that with ''
    return Utils.replaceSpacesWith(s, '-').replace(/[^a-z0-9-_]/gi, '');
  }

  /**
   * makes a string url-safe
   * @param s
   */
  static urlSafeFileName(filename: string): string {
    const substrings = filename.split('.'); // split the string at '.'
    if (substrings.length === 1) {
      return Utils.urlSafeString(filename); // there was no file extension, file was something like 'myfile'
    } else {
      const ext = substrings.pop(); // remove the last element
      const name = Utils.urlSafeString(substrings.join('')); // rejoin the remaining elements without separator
      return [name, ext].join('.'); // readd the extension
    }
  }

  /**
   * removes file extension
   * @param s
   */
  static removeFileExt(filename: string): string {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }

  /**
   * get extension from filename
   * @param s
   */
  static getFileExt(filename: string): string {
    return filename.split('.').pop();
  }

  /**
   * replaces spaces with a string
   * @param s
   * @param replacer
   * modified 2023-04-13 to reduce " - " to single dash instead of ---
   */
  static replaceSpacesWith(s: string, replacer = '-'): string {
    return s.replace(/[\s-]+/g, replacer); //.replace(/[^a-z0-9]/gi, '');
  }

  static upperFirstChar(s: string): string {
    return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
  }

  static mimeToExtension(mime: string): string {
    return (extensionsMap[mime] && extensionsMap[mime][0]) || null;
  }

  static extensionToMime(ext: string): string {
    const mimesMap = this.invertBackBy(extensionsMap);
    return mimesMap[ext] || null;
  }

  /**
   * get an AWS DB friendly date time AWSDATETIME
   * http://momentjs.com/docs/#/displaying/as-iso-string/
   */
  static getDateTimeString(date = null): string {
    if (date) {
      return new Date(date).toISOString();
    }
    return new Date().toISOString();
    // return moment().toISOString(); // simply implements native .toISOString
  }
  /**
   * get a DB friendly date time
   * http://momentjs.com/docs/#/displaying/as-iso-string/
   */
  static getDateTimeStringWithOffset(date): string {
    try {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      const zoneOffset = new Date().getTimezoneOffset() * 60000;
      return new Date(date - zoneOffset).toISOString().slice(0, -1); // removing Z
    } catch (e) {
      console.warn('getDateTimeStringWithOffset error:', e, date);
      return date;
    }
  }
  /**
   * make an auto id for stack, project,...
   * @param title
   * @param userId
   */
  static autoId(title: string, userId: string) {
    const MAX_TITLE_LEN = 64;
    const now = new Date();
    const urlSafeTitle = Utils.urlSafeString(title.trim().toLowerCase()),
      urlSafeUser = userId ? Utils.urlSafeString(userId.toLowerCase()) + '_' : '',
      urlDate = Utils.shortUrlDate(now);
    return `${urlSafeUser}${urlSafeTitle.substring(0, MAX_TITLE_LEN)}_${urlDate}`;
  }

  static titleCase(s: string): string {
    return s
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  static dashedToTitleCase(s: string): string {
    const capitalize = (part) => part.charAt(0).toUpperCase() + part.slice(1);
    return s.split('-').map(capitalize).join(' ');
  }

  /**
   * escape html to avoid script injection from form inputs
   * @param s
   */
  static escapeHtml(text: string): string {
    /* eslint-disable @typescript-eslint/naming-convention */
    const mapping = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;', // eslint-disable-line  @typescript-eslint/quotes
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    return text.replace(/[&<>"']/g, (m) => mapping[m]);
  }

  /**
   * strip html from a string
   * @param s
   * based on : https://stackoverflow.com/a/1237620/2019544
   */
  static stripHtml(text: string, params: { leaveBrTags: boolean }): string {
    if (params && params.leaveBrTags) {
      text = text.replace(/<p.*>/gi, '');
    } else {
      text = text.replace(/<br>/gi, '\n');
      text = text.replace(/<p.*>/gi, '\n');
    }
    text = text.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, ' $2 (Link->$1) ');
    text = text.replace(/<(?:.|\s)*?>/g, '');
    return text;
  }

  /** change <br> to newline for text emails */
  static convertHtmlBrToText(s: string) {
    return s.replace(/<br\s*[\/]?>/gi, '\r\n');
  }
  /** change testarea newlines into <br> for emails */
  static convertNewlinesToBr(s: string) {
    return s.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  /**
   * removeFromArray([1, 2, 3, 4, 5, 5, 6, 5], 5) //return [1, 2, 3, 4, 6]
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static removeFromArray(arr: any[], x) {
    return arr.filter((n) => n !== x);
  }

  /**
   * Move items from/to, manipulates arr in-place
   */
  static arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  /**
   * add to array if not exists
   * @param arr returns array
   * @param val value to add to array
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static addToArrayUnique(arr: any[], val) {
    if (arr.indexOf(val) === -1) {
      arr.push(val);
    }
    return arr;
    // return arr.filter((e, i, a) => a.indexOf(e) === i);
  }

  /**
   * Sort an array alphabetically
   */
  static sortArrayAlpha(array: string[]) {
    return array.sort((a, b) => {
      const sA = typeof a == 'string' ? a.toLowerCase() : ''; // ignore case
      const sB = typeof b == 'string' ? b.toLowerCase() : ''; // ignore case
      if (sA < sB) {
        return -1;
      }
      if (sA > sB) {
        return 1;
      }
      // must be equal
      return 0;
    });
  }

  // https://stackoverflow.com/a/3579651/2019544
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static sortArrayByFrequency(array: any[]) {
    const frequency = {};
    array.forEach((value) => {
      frequency[value] = 0;
    });
    const uniques = array.filter((value) => ++frequency[value] == 1); // eslint-disable-line  eqeqeq
    return uniques.sort((a, b) => frequency[b] - frequency[a]);
  }

  static sortObjArrayAlphaBy(a: object, b: object, prop: string = 'title') {
    return a[prop] && b[prop] && a[prop].toLowerCase() > b[prop].toLowerCase() ? 1 : -1;
  }

  static filterByValue(array: object[], string: string) {
    return array.filter((o) => Object.keys(o).some((k) => o[k].toLowerCase().includes(string.toLowerCase())));
  }

  static filterPropsByValue(props: string[], array: object[], string: string): object[] {
    return array.filter((o) => {
      // get which props exist on Object
      const keysToCheck = Object.keys(o).filter((key) => props.indexOf(key) > -1);
      // console.log(`Utils.filterPropsByValue keysToCheck:`, keysToCheck);
      return keysToCheck.some((k) => o[k] && o[k].toLowerCase().includes(string.toLowerCase()));
    });
  }

  /**
   * add object to array if not exists
   * @param arr mutates array
   * @param obj value to add to array
   */
  static addObjectToArrayUnique(arr: object[], obj: object, propNameToCompare: string) {
    const found = arr.some((item) => item[propNameToCompare] === obj[propNameToCompare]);
    if (!found) {
      arr.push(obj);
    }
  }

  /**
   * add object to array if not exists
   * @param arr array
   * @param obj value to add to array
   * @param propNameToCompare property name to compare
   * @returns new array
   */
  static addOrReplaceObjectToNewArray(array: object[], obj: object, propNameToCompare: string): object[] {
    const arr = [...array];
    const index = arr.findIndex((item) => item[propNameToCompare] === obj[propNameToCompare]);
    if (index > -1) {
      arr[index] = obj;
    } else {
      arr.push(obj);
    }
    return arr;
  }

  /**
   * add object to array if not exists
   * @param arr array
   * @param obj value to add to array
   * @param propNameToCompare property name to compare
   * @returns new array
   */
  static addOrReplaceMultipleObjectsToNewArray(array: object[], objs: object[], propNameToCompare: string): object[] {
    const arr = [...array];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < objs.length; i++) {
      const element = objs[i];
      const index = arr.findIndex((item) => item[propNameToCompare] === element[propNameToCompare]);
      if (index > -1) {
        arr[index] = element;
      } else {
        arr.push(element);
      }
    }
    return arr;
  }

  static removeHostname(url) {
    return url.match(/\/\/[^\/]+\/([^\.]+)/)[1];
  }
  static extractHostname(url) {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf('://') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];
    return hostname;
  }

  /**
   * Get the Clip Duration for array of clip_length (duration) formatted as "00:00:00"
   * handles "HH:MM:SS" as well as "MM:SS" or "SS".
   * @param duration string
   */
  static convertDurationToSeconds(duration: string = ''): number {
    const p = duration.split(':');
    let s = 0,
      m = 1;
    while (p.length > 0) {
      s += m * parseInt(p.pop(), 10);
      m *= 60;
    }
    return s;
  }

  /**
   * Get the Total Duration for array of clip_length (duration) formatted as "00:00:00"
   * @param seconds
   */
  static convertSecondsToDuration(secs: number): string {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60) % 60;
    const seconds = secs % 60;
    return (
      [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        // .filter((v,i) => v !== "00" || i > 0) // keep the '00'
        .join(':')
    );
  }

  /**
   * Get the Total Duration for array of clip_length (duration) formatted as "00:00:00"
   * @param durations
   */
  static getTotalDuration(durations: Array<string> = []) {
    return durations.reduce((prev, curr) => Utils.addTimes(prev, curr), '00:00:00'); //give reduce a starting point as the second arg (error if durations.length=0)
  }

  static getTotalDurationNoHours(durations: Array<string> = []) {
    return durations.reduce((prev, curr) => Utils.addTimes(prev, curr, true), '00:00'); //give reduce a starting point as the second arg (error if durations.length=0)
  }

  /**
   * Sum two times values HH:mm:ss with javascript
   * Usage:
   *  > addTimes('04:20:10', '21:15:10');
   *  > "25:35:20"
   *
   * @param start
   * @param end
   * @returns
   * https://gist.github.com/joseluisq/dc205abcc9733630639eaf43e267d63f
   */
  static addTimes(start, end, hideHoursIfEmpty = false) {
    const times = [],
      times1 = start.split(':'),
      times2 = end.split(':');

    while (times1.length < 3) {
      times1.unshift(0);
    }
    while (times2.length < 3) {
      times2.unshift(0);
    }
    // console.log("addTimes",times1.join(":"), times2.join(":"));

    for (let i = 0; i < 3; i++) {
      times1[i] = isNaN(parseInt(times1[i], 10)) ? 0 : parseInt(times1[i], 10);
      times2[i] = isNaN(parseInt(times2[i], 10)) ? 0 : parseInt(times2[i], 10);
      times[i] = times1[i] + times2[i];
    }

    let seconds = times[2];
    let minutes = times[1];
    let hours = times[0];

    if (seconds >= 60) {
      const res = (seconds / 60) | 0;
      minutes += res;
      seconds = seconds - 60 * res;
    }

    if (minutes >= 60) {
      const res = (minutes / 60) | 0;
      hours += res;
      minutes = minutes - 60 * res;
    }
    const padZero = (d) => ('0' + d).slice(-2);

    // hide hours if there's none
    const hourRes = hideHoursIfEmpty && hours <= 0 ? '' : padZero(hours) + ':';
    return hourRes + padZero(minutes) + ':' + padZero(seconds);
  }

  static formatDate(date: Date) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // return day + ' ' + monthNames[monthIndex] + ' ' + year;
    return monthNames[monthIndex] + ' ' + day + ', ' + year;
  }

  static formatEuDate(date: Date, doShort = false) {
    let monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    if (doShort) {
      monthNames = monthNames.map((name) => name.substring(0, 3));
    }

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const padStart = (val) => ('0' + val).slice(-2);

    return padStart(day) + ' ' + monthNames[monthIndex] + ' ' + year;
    // return monthNames[monthIndex] + ' ' + day + ', ' + year;
  }

  static shortUrlDate(date: Date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    //.slice(-2) gives us the last two characters of the string.
    const padStart = (val) => ('0' + val).slice(-2);

    return year + padStart(monthIndex + 1) + padStart(day);
  }

  static urlDate(date: Date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    //.slice(-2) gives us the last two characters of the string.
    const padStart = (val) => ('0' + val).slice(-2);

    return year + padStart(monthIndex + 1) + padStart(day) + padStart(hour) + padStart(minutes);
  }

  static longUrlDate(date: Date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //.slice(-2) gives us the last two characters of the string.
    const padStart = (val) => ('0' + val).slice(-2);

    return year + padStart(monthIndex + 1) + padStart(day) + padStart(hour) + padStart(minutes) + padStart(seconds);
  }

  /**
   * calculate number of days ago
   * @param dateNow
   * @param datePast
   */
  static daysAgo(dateNow: Date, datePast: Date) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.round(Math.abs((dateNow.getTime() - datePast.getTime()) / oneDay)).toLocaleString();
  }

  /**
   * @todo did not work (usused)
   * Based on
   * https://towardsdev.com/debouncing-and-throttling-in-javascript-8862efe2b563
   */
  static throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(() => {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  }

  // ref: https://gist.github.com/andjosh/6764939
  // https://stackoverflow.com/questions/8917921/cross-browser-javascript-not-jquery-scroll-to-top-animation
  static smoothScrollTo(element, to, duration = 1000) {
    const start = element.scrollTop,
      change = to - start,
      increment = 20;
    let currentTime = 0;
    const animateScroll = () => {
      currentTime += increment;
      const val = Utils.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        /**
         * @todo should there be a clearTimeout? revisit...
         */
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  static easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  static makeCommaSeparatedString(arr = [], useOxfordComma = false) {
    const listStart = arr.slice(0, -1).join(', ');
    const listEnd = arr.slice(-1);
    const conjunction = arr.length <= 1 ? '' : useOxfordComma && arr.length > 2 ? ', and ' : ' and ';

    return [listStart, listEnd].join(conjunction);
  }

  /**
   * [Reverse function of _.invertBy](https://stackoverflow.com/a/70125437/4982169)
   * [replace _.identity by x => x](https://stackoverflow.com/a/67105853/4982169)
   */
  private static invertBackBy(object, iteratee = (x) => x) {
    const result = {};
    Object.keys(object).forEach((key) => {
      object[key].map(iteratee).forEach((value) => {
        result[value] = key;
      });
    });
    return result;
  }
}
