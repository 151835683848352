<div class="invite-form">
  
  <ng-container *ngIf="isLoggedIn$ | async as isLoggedIn; else notLoggedIn">
    <form
      [formGroup]="projectMemberInviteForm"
      (ngSubmit)="sendInvites()"
    >

      <fs-form-textarea
        [label]="'MEMBERS.INVITE.LABEL' | translate"
        [control]="projectMemberInviteForm.get('message') | formControlCast"
      ></fs-form-textarea>

      <fs-form-textarea
        [label]="'COMMON.EMAILS' | translate"
        [placeholder]="'ONBOARDING.COMMON.INVITE.MEMBERS_PLACEHOLDER' | translate"
        [control]="projectMemberInviteForm.get('emails') | formControlCast"
        [debounce]="1000"
        (onInput)="emailsChange($event)"
      ></fs-form-textarea>

      <!-- <ion-item lines="none">
        <ion-label position="floating" class="ion-text-uppercase">{{ 'MEMBERS.INVITE.LABEL' | translate }}</ion-label>
        <ion-textarea rows="5" auto-grow="true" [(ngModel)]="message"></ion-textarea>
      </ion-item>

      <ion-item lines="none">
        <ion-label position="stacked" class="ion-text-uppercase">{{ 'COMMON.EMAILS' | translate }}</ion-label>
        <ion-textarea debounce="500" rows="6"
          [placeholder]="'ONBOARDING.COMMON.INVITE.MEMBERS_PLACEHOLDER' | translate"
          (ionChange)="membersChange($event.detail.value)"
        ></ion-textarea>
      </ion-item> -->

      <fs-form-select [hidden]="hideProjectSelect"
        label="{{ 'COMMON.PROJECT' | translate }}"
        placeholder="{{ 'PROJECT.SELECT_PROJECT' | translate }}"
        [items]="projectOptions"
        [control]="projectMemberInviteForm.get('projectId') | formControlCast"
      ></fs-form-select>

      <div class="actions ion-text-center ion-padding-top ion-padding-bottom">
        <div *ngIf="message" class="message ion-padding-bottom ion-color-success">
          {{ message }}
        </div>
        <fs-button
          [disabled]="!projectMemberInviteForm.valid || emails.length < 1 || loadingAttempt"
          type="submit"
          size="large"
          [loading]="loadingAttempt"
          >{{ translatePath + 'INVITE_ACTION' | translate }}</fs-button
        >
      </div>
    </form>

    <div *ngIf="emails?.length > 0" class="results">
      <h3>{{ 'COMMON.EMAILS_FOUND' | translate }}:</h3>
      <span *ngFor="let email of emails" style="display:inline-block;margin-right: 10px;">{{ email }}</span>
    </div>

    <!-- <div *ngIf="projectId" class="ion-text-center">
      <ion-button [routerLink]="['/stack/discover/project/', projectId]" fill="outline">
        {{ 'PROJECT.RETURN_TO_PROJECT' | translate }}
      </ion-button>
    </div> -->
  </ng-container>

  <ng-template #notLoggedIn>
    <!-- LOGGED OUT -->
    <div class="container skinny-limit-width">
      <fs-login-form [routeOnSuccess]="actionUrl"></fs-login-form>
    </div>
  </ng-template>
</div>