/**
 * eslint-disable arrow-body-style
 *
 * @format
 */

import _memoize from 'lodash/memoize';
// import keyBy from 'lodash/keyBy';
import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import { State, featureKey, selectEntities, selectIds, getSelectedId, getMineIds } from '../reducers/projects.reducers';
import { selectUserState } from './user.selectors';
import { selectRecentProjectsIds } from './environ.selectors';
import {
  isEventActive,
  PROJECT_MEMBER_ROLE,
  sortProjectTitleAlpha,
  getEventConfig,
} from '@projects/shared/project.model';
import { isAllowedFor } from '@members/shared/project-member.model';

export { ProjectGroup } from '../reducers/projects.reducers';

export const selectProjectState = createFeatureSelector<State>(featureKey);

export const selectProjectEntities = createSelector(selectProjectState, selectEntities);
export const selectProjectIds = createSelector(selectProjectState, selectIds);

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * MEMOIZATION
 * In contrast to a normal NgRx selector where the selector is shared across multiple components,
 * we now have a new instance of the selector every time the selector factory is invoked.
 * This has the effect that we lose the memoization benefits of the selector.
 *
 * For most cases this is fine and you won't notice the difference. But when the selector has to so expensive work,
 * you can add a memoization layer on top as a countermeasure.
 *
 * To accomplish this, we must bring our own memoization method to the table, for example lodash.memoize.
 * After this, we can simply wrap the selector inside of the memoize method.
 * Note that this is a simple example and that you probably don't need to memoize an entity lookup.
 *
 * By doing this, consuming the selector is no different than before.
 *
 * It's important to keep in mind that this builds up an in-memory cache,
 * so that's why it's better that the added entry is disposed of when it's possible.
 *
 * https://lodash.com/docs/4.17.15#memoize
 * Note: The cache is exposed as the cache property on the memoized function.
 * Its creation may be customized by replacing the _.memoize.Cache constructor
 * with one whose instances implement the Map
 * method interface of clear, delete, get, has, and set.
 *
 * @dev This will likely be the solution...
 */
export const selectProject = _memoize((id: string) =>
  createSelector(selectProjectEntities, (entities) => entities[id])
);
export const selectProjectsByIds = (ids: string[]) =>
  createSelector(selectProjectEntities, (entities) => ids.map((id) => entities[id]));
/**
 * @todo why not working?
 */
// export const selectProjectsByIds = (ids: string[]) =>
//   forkJoin(ids.map(id => selectProject(id)))

/**
 * However, a type safe alternative is demonstrated in RFC 2980. Applied to the answer from @jordan-gray:
 * Usage:
 * this.store.select(selectEntity({ id: myId }));
 */
// export const selectEntity = (props: { id: string }) =>
//   createSelector(selectEntities, (entities) => entities[props.id]);
export const getProjects = createSelector(selectProjectEntities, selectProjectIds, (entities, ids) =>
  ids.map((id) => entities[id])
);
export const selectCurrentProjectId = createSelector(selectProjectState, getSelectedId);

export const selectCurrentProject = createSelector(
  selectProjectEntities,
  selectCurrentProjectId,
  (projectEntities, projectId) => projectEntities[projectId]
);

export const selectMyProjectsIds = createSelector(selectProjectState, getMineIds);

export const selectMyProjects = createSelector(selectProjectEntities, selectMyProjectsIds, (entities, ids) =>
  (ids || []).map((id) => entities[id]).sort(sortProjectTitleAlpha)
);

/** Get MyProjects where I am an Admin */
export const selectMyProjectsAdmin = createSelector(
  selectProjectEntities,
  selectMyProjectsIds,
  selectUserState,
  (entities, ids, userState) =>
    (ids || [])
      .map((id) => entities[id])
      .filter((proj) =>
        isAllowedFor(
          [PROJECT_MEMBER_ROLE.OWNER, PROJECT_MEMBER_ROLE.PRODUCER, PROJECT_MEMBER_ROLE.EXECUTIVE],
          proj,
          userState.userId
        )
      )
);

// select subscriptions for project

export const selectMyProjectsActiveEvent = createSelector(selectProjectEntities, selectMyProjectsIds, (entities, ids) =>
  (ids || [])
    .map((id) => entities[id])
    .filter((project) => isEventActive(project))
    .sort(sortProjectTitleAlpha)
    .map((item) => ({
      ...item,
      eventConfig: getEventConfig(item), // desired?
    }))
);

export const selectRecentProjects = createSelector(selectProjectEntities, selectRecentProjectsIds, (entities, ids) =>
  (ids || []).map((id) => entities[id])
);

export const getFeaturedProjects = createSelector(selectProjectEntities, selectProjectIds, (entities, ids) =>
  // console.log(`${PAGE} getFeaturedProjects entities:`, entities);
  ids.map((id) => entities[id]).filter((item) => item && item.featured && item.featured > 0)
);

export const getFeaturedIds = createSelector(selectProjectEntities, selectProjectIds, (entities, ids) =>
  ids
    .map((id) => entities[id])
    .filter((item) => item && item.featured && item.featured > 0)
    .map((item) => item.id)
);

export const selectCurrentProjectMemberByUser = createSelector(
  selectCurrentProject,
  selectUserState,
  (currentProject, userState) =>
    currentProject &&
    Array.isArray(currentProject.members) &&
    currentProject.members.find((member) => member.userId === userState.userId)
);

export const selectIsCurrentProjectAdminByUser = createSelector(
  selectCurrentProject,
  selectUserState,
  (currentProject, userState) =>
    isAllowedFor(
      [PROJECT_MEMBER_ROLE.OWNER, PROJECT_MEMBER_ROLE.PRODUCER, PROJECT_MEMBER_ROLE.EXECUTIVE],
      currentProject,
      userState.userId
    )
);

// for backward compat
export const getProject = selectProject;
// export const getProjectsByIds = selectProjectsByIds;

// not used yet..
// export const getProjectsByChannelUrl = (url: string) =>
//   createSelector(getProjectsIds, getProjectEntities, (ids, entities) => {
//     const items = ids.map((id) => entities[id]);
//     return items.filter((item) => item.channel === url);
//   });

// helpers

// // isAllowedFor -> moved to project-member.model
// function _isAllowedFor(allowedRoles: PROJECT_MEMBER_ROLE[], project: Project, userId: string): boolean {
//   let isAllowed = false;
//   if (userId && project && Array.isArray(project.members)) {
//     if (project.owner === userId) {
//       // allowed for all
//       console.log('isAllowedFor OWNER');
//       return true;
//     }
//     // if the user exists multiple times, we need to check for max permission if duplicates
//     // while also filtering for isActive (project.members.filter((member) => member.isActive))
//     const userRoles = project.members.reduce((prev, curr) => {
//       if (curr?.userId === userId && curr.isActive) {
//         const exists = prev.find((p) => p.userId === curr.userId);
//         if (exists?.userId && getTopPermission(curr, exists.role) === exists.role) {
//           // already exists with higher role => skip
//           return prev;
//         }
//         return [...prev, curr];
//       }
//       return prev;
//     }, []);

//     // // ...the below works, but since we're looking for a single userId, we can simplify since we're within selector
//     // const members = project.members.reduce((prev, curr) => {
//     //   if (!curr?.isActive) {
//     //     return prev;
//     //   }
//     //   const exists = prev.find((p) => p.userId === curr.userId);
//     //   if (exists?.userId && getTopPermission(curr, exists.role) === exists.role) {
//     //     // already exists with higher role => skip
//     //     return prev;
//     //   }
//     //   return [...prev, curr];
//     // }, []);

//     const projectMembersMap = keyBy(
//       userRoles,
//       'userId'
//     );
//     isAllowed = allowedRoles.some((r) => projectMembersMap[userId] && projectMembersMap[userId].role === r);
//     console.log('isAllowedFor', { projectId: project.id, projectMembersMap, isAllowed, userId, members: project.members });
//   }

//   return isAllowed;
// }

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * The currently recommended approach to this is using a factory function
 * ... but this is _not_ memoized
 */

// /**
//  * usage:
//  * this.store.select(entityById(someID))
//  */
// export const entityById = (id: string) => createSelector(
//   selectProjectEntities,
//   (entities) => entities[id]
// );

// /**
//  * Usage:
//  * this.store.select(entitiesByIDs(arrayOfIDs))
//  */
// export const entitiesByIDs = (ids = []) => createSelector(
//   selectProjectEntities,
//   (entities) => ids.map(id => entities[id])
// );
