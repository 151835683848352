import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@shared/shared.module';
import { BillingStoreModule } from './store';
import * as fromComponents from './shared/components';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    IonicModule,
    SharedModule,
    BillingStoreModule,
  ],
  declarations: [
    ...fromComponents.components
  ],
  exports: [
    ...fromComponents.components
  ]
})
export class BillingModule { }
