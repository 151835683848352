/**
 * Bind console.logs for dev
 *
 * @format
 */
/* eslint-disable brace-style */

const devReq = 'please';
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = function () {};
let windowConsole;
try {
  if (window) {
    windowConsole = {
      log: window.console.log.bind(window.console),
      info: window.console.info.bind(window.console),
      group: window.console.group.bind(window.console),
      groupEnd: window.console.groupEnd.bind(window.console),
      groupCollapsed: window.console.groupCollapsed.bind(window.console),
      time: window.console.time.bind(window.console),
      timeEnd: window.console.timeEnd.bind(window.console),
    };
  } else {
    windowConsole = {
      log: noop,
      info: noop,
      group: noop,
      groupEnd: noop,
      groupCollapsed: noop,
      time: noop,
      timeEnd: noop,
    };
  }
} catch (error) {
  console.warn(error);
  windowConsole = {
    log: noop,
    info: noop,
    group: noop,
    groupEnd: noop,
    groupCollapsed: noop,
    time: noop,
    timeEnd: noop,
  };
}

export function isLocalStorageSupported() {
  const testKey = 'localStorageEnabled';
  try {
    // Try and catch quota exceeded errors
    const storage = window.sessionStorage;
    if (storage.getItem(testKey) === '1') {
      // we've already been here, done that
      return true;
    } else {
      storage.setItem(testKey, '1');
      return true;
    }
  } catch (error) {
    let msg = '';
    if (error.code === DOMException.QUOTA_EXCEEDED_ERR) {
      msg =
        'Your HTML5 LocalStorage is disabled, and this web app requires storing a small amount of data on your device. \n\nIf you are viewing this site in a Private browsing window, please re-open this site in a browser window without Private browsing turned on, otherwise this site will not work.';
      console.warn('ERROR: ', msg);
    } else {
      msg =
        'HTML5 SessionStorage is not accessible, and this web app requires storing a small amount of data on your device. \n\nIf you are viewing this site in a Private browsing window, please re-open this site in a browser window without Private browsing turned on, otherwise this site will not work.';
      console.warn('ERROR: ', msg);
    }
    return false;
  }
}

function localStorageIfExists() {
  /*
   * ref: http://www.javascriptkit.com/javatutors/domstorage.shtml
   *
   * In FF, support for the localStorage object was only added starting in FF3.5.
   * In FF3.0 browsers, there exist the non standard globalStorage[location.hostname]
   * equivalent object you can use to maximize browser compatibility.
   */
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ecolocalstorage = window.localStorage || ((window as any).globalStorage ? (window as any).globalStorage[location.hostname] : null);

    if (!isLocalStorageSupported()) {
      console.warn(
        'Local/Session Storage is not available - for improved user experience please re-try in a browser window with Private browsing turned OFF.'
      );
      // alert('Sorry, for improved user experience we decided to save some data to your device (see the Help page for what we save). \n\nWould you please check if Private Browsing is turned ON, otherwise you may have to try in a different browser (maybe Google Chrome?).');// eslint-disable-line no-alert
    }

    return ecolocalstorage;
  } catch (error) {
    console.warn(
      'Local/Session Storage is not available - for the best experience, please re-try in a browser window with Private browsing turned OFF.'
    );
    console.warn(error);
    return null;
  }
}

function prepareJSONforStorage(s) {
  try {
    return JSON.stringify(s);
    //sRet = sRet.replace("jsonp(", "");
    /*if (sRet.indexOf('\\') > 0) {
        //todo: why is this crashing? sRet = removeJSONbackslashes(sRet);
    }*/
    //console.log(sRet);
  } catch (error) {
    console.log(`Caught in prepareJSONforStorage:`, error);
    return '';
  }
}

function getJSONfromStorage(s) {
  const sRet = JSON.parse(s);
  return sRet;
}

function saveToStorage(key, value, bIsObjOrArray = false, doLog = false) {
  if (bIsObjOrArray) {
    value = prepareJSONforStorage(value);
  }

  const html5localstorage = localStorageIfExists();
  if (html5localstorage) {
    if (html5localstorage[key]) {
      if (doLog) {
        console.log(
          '** saveToStorage -> localstorage[' + key + '] : Exists | current value = %o',
          bIsObjOrArray ? getJSONfromStorage(html5localstorage[key]) : html5localstorage[key]
        );
      }
      html5localstorage[key] = value;
      if (doLog) {
        console.log(
          '** saveToStorage -> localstorage[' + key + '] : Updated to value = %o',
          bIsObjOrArray ? getJSONfromStorage(html5localstorage[key]) : html5localstorage[key]
        );
      }
    } else {
      html5localstorage[key] = value;
      if (doLog) {
        console.log(
          '** saveToStorage -> localstorage[' + key + '] : Created with value = %o',
          bIsObjOrArray ? getJSONfromStorage(html5localstorage[key]) : html5localstorage[key]
        );
      }
    }
  } else if (window.sessionStorage) {
    if (window.sessionStorage.getItem(key)) {
      if (doLog) {
        console.log(
          '** saveToStorage -> sessionStorage[' + key + '] : Exists | current value = %o',
          bIsObjOrArray
            ? getJSONfromStorage(sessionStorage.getItem(key))
            : getJSONfromStorage(sessionStorage.getItem(key))
        );
      }
      window.sessionStorage.setItem(key, value);
      if (doLog) {
        console.log(
          '** saveToStorage -> sessionStorage[' + key + '] : Updated to value = %o',
          bIsObjOrArray
            ? getJSONfromStorage(sessionStorage.getItem(key))
            : getJSONfromStorage(sessionStorage.getItem(key))
        );
      }
    } else {
      window.sessionStorage.setItem(key, value);
      if (doLog) {
        console.log(
          '** saveToStorage -> sessionStorage[' + key + '] : Created with value = %o',
          bIsObjOrArray
            ? getJSONfromStorage(sessionStorage.getItem(key))
            : getJSONfromStorage(sessionStorage.getItem(key))
        );
      }
    }
  } else {
    // sessionStorage not available, use Win Vars
    const winvar = 'Session' + key;
    window[winvar] = value;
    if (doLog) {
      console.log(
        '** saveToStorage -> localStorage & sessionStorage not available - created window["' + winvar + '"] = %o',
        window[winvar]
      );
    }
  }
}

export const getFromStorage = (key, bIsObjOrArray = false, doLog = false) => {
  let sRet = '';

  const html5localstorage = localStorageIfExists();
  if (html5localstorage) {
    if (html5localstorage[key]) {
      if (doLog) {
        console.log(
          '** getFromStorage -> localstorage[' + key + '] : Exists | current value = %o',
          bIsObjOrArray ? getJSONfromStorage(html5localstorage[key]) : html5localstorage[key]
        );
      }
      sRet = html5localstorage[key];
    } else {
      if (doLog) {
        console.log('** getFromStorage -> localstorage[' + key + '] : Does Not Exist');
      }
    }
  } else if (window.sessionStorage) {
    if (window.sessionStorage.getItem(key)) {
      if (doLog) {
        console.log(
          '** getFromStorage -> sessionStorage[' + key + '] : Exists | current value = %o',
          bIsObjOrArray
            ? getJSONfromStorage(sessionStorage.getItem(key))
            : getJSONfromStorage(sessionStorage.getItem(key))
        );
      }
      sRet = window.sessionStorage.getItem(key);
    } else {
      if (doLog) {
        console.log('** getFromStorage -> sessionStorage[' + key + '] : Does Not Exist');
      }
    }
  } else {
    // sessionStorage not available
    const winvar = 'Session' + key;
    if (window[winvar]) {
      if (doLog) {
        console.log('** getFromStorage -> window["' + winvar + '"] : Exists | current value = ' + window[winvar]);
      }
      sRet = window[winvar];
    } else {
      if (doLog) {
        console.log('** getFromStorage -> window["' + winvar + '"] : Does Not Exist');
      }
    }
  }

  if (bIsObjOrArray && sRet.length > 0) {
    sRet = getJSONfromStorage(sRet) as string;
  }

  return sRet;
};

let windowAvailable = true;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function DevLogBind(cmd, opts) {
  if (!windowAvailable) return;

  if (!isLocalStorageSupported() && !localStorageIfExists()) {
    // avoid widget error in incognito mode
    // chrome incognito iframe Uncaught DOMException: Failed to read the 'localStorage' property from 'Window': Access is denied for this document
    windowAvailable = false;
    return;
  }

  let msg = 'hi dev.';

  if (window) {
    switch (cmd) {
      case 'logsEnabled': {
        return getFromStorage('consoul') === '1';
      }
      case 'disable': {
        window.console.log = noop;
        window.console.info = noop;
        window.console.group = noop;
        window.console.groupEnd = noop;
        window.console.groupCollapsed = noop;
        window.console.time = noop;
        window.console.timeEnd = noop;

        msg = 'dev console.logs disable.d';
        if (!opts || opts !== true) {
          saveToStorage('consoul', '0');
          msg += '... & saved.';
        }
        break;
      }
      case 'enable':
        if (opts === devReq) {
          window.console.log = windowConsole.log;
          window.console.info = windowConsole.info;
          window.console.group = windowConsole.group;
          window.console.groupEnd = windowConsole.groupEnd;
          window.console.groupCollapsed = windowConsole.groupCollapsed;
          window.console.time = windowConsole.time;
          window.console.timeEnd = windowConsole.timeEnd;
          // window.log = console.log.bind(console);

          saveToStorage('consoul', '1');
          msg = 'dev console.logs enable.d';
        } else {
          msg = 'sorry, not allowed.';
        }
        break;
    }
  } else {
    msg = 'no window';
  }
  return msg;
}

export const logLocalStorageSize = () => {
  const grp = 'logLocalStorageSize',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thisLocalstorage = window.localStorage || ((window as any).globalStorage ? (window as any).globalStorage[location.hostname] : []);
  let t = 0;
  const computeSize = function (thisKey) {
    const s = thisLocalstorage[thisKey].length * 2;
    t += s;
    console.log('localStorage: ' + thisKey + ' = ' + s / 1024 + ' KB');
  };
  console.group(grp);
  for (const thisKey in thisLocalstorage) {
    if (Object.prototype.hasOwnProperty.call(thisLocalstorage, thisKey)) {
      computeSize(thisKey);
    }
  }
  console.log('localStorage total: ' + t / 1024 + ' KB');
  console.groupEnd();
};

// removeJSONbackslashes : function (str) {
//     return String(str)
//             .replace(/\\n/g, '')
//             .replace(/\\/g, '');
// },

// function isJsonString(str) {
//   try {
//     JSON.parse(str);
//   } catch (e) {
//     return false;
//   }
//   return true;
// }
