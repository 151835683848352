/** @format */

import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  HostBinding,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectMember } from '@store/selectors/members.selectors';
import { ProjectMemberModalComponent } from '@members/project-member-modal/project-member-modal.component';
import { UserListPopoverComponent } from '@shared/components/user-list-popover/user-list-popover.component';
import { User } from '@shared/models/user.model';
import { Subject } from 'rxjs';

/** it is more interesting to show all users than just the hidden ones */
const SHOW_ALL_IN_POPUP = true;

@Component({
  selector: 'fs-avatar-stack',
  templateUrl: './avatar-stack.component.html',
  styleUrls: ['./avatar-stack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarStackComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  get users() {
    return this._users;
  }
  set users(value: Partial<User>[]) {
    this._users = value;
  }

  @Input()
  get threshold() {
    return this._threshold;
  }
  set threshold(value: number) {
    this._threshold = value;
  }

  @Output() modalOpened = new EventEmitter<string>();

  public avatarStack = {
    show: [],
    hide: [],
  };

  @ViewChild('avatarStackContainer', { static: false }) avatarStackEl: ElementRef;

  private _threshold: number;
  private avatarStackItemSize = 36;
  @HostBinding('style.--avatar-size')
  private avatarStackItemSizePx = `${this.avatarStackItemSize}px`;

  /** inner users for names */
  private _users: Partial<User>[] = [];

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private modalController: ModalController,
    private platform: Platform,
    private popoverCtrl: PopoverController
  ) {}

  ngOnInit(): void {
    this.platform.resize.subscribe(async () => {
      this.refreshAvatarStack();
    });

    (this.users || []).forEach((u, i) => {
      if (u?.userId) {
        this.store
          .select(selectMember(u.userId))
          .pipe(
            takeUntil(this.onDestroy$),
            filter((us) => us?.userId?.length > 0),
            take(1)
          )
          .subscribe((user) => {
            if (user?.userId) {
              this._users[i].name = user.name || user.username || user.userId;
            }
          });
      }
    });
  }

  ngAfterViewInit(): void {
    this.refreshAvatarStack();
    const REFRESH_EL_DELAY = 1000;
    setTimeout(() => {
      // First iteration could omit side elements, observed on stack-play where content-actions side-bar width is omitted by getDynamicThreshold.
      this.refreshAvatarStack();
    }, REFRESH_EL_DELAY);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  splitAvatarStackOnThreshold(threshold: number) {
    /**
     * Avoid showing only one more Elt badge
     */
    if (this.users?.slice(threshold).length === 1) {
      threshold = this.users?.length;
    }
    this.avatarStack = {
      show: this.users?.slice(0, threshold),
      hide: this.users?.slice(threshold),
    };
  }

  refreshAvatarStack() {
    const currentThreshold = this.threshold ?? this.getDynamicThreshold();

    this.splitAvatarStackOnThreshold(currentThreshold);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Min threshold is 1, to avoid showing only more Elt w/o any avatar
   */
  getDynamicThreshold(): number {
    const avatarStackWidth = this.avatarStackEl.nativeElement.clientWidth;
    const threshold = Math.floor(avatarStackWidth / this.avatarStackItemSize) - 2;

    return threshold > 0 ? threshold : 1;
  }

  trackByFn(index, user) {
    return user?.userId ?? index;
  }

  async viewUserProfile(id: string) {
    const modal = await this.modalController.create({
      component: ProjectMemberModalComponent,
      componentProps: {
        userId: id,
      },
      cssClass: 'modal-lg',
    });
    this.modalOpened.emit(id);
    return await modal.present();
  }

  async viewUserList(event: MouseEvent) {
    const users = SHOW_ALL_IN_POPUP ? this.users : this.avatarStack?.hide ?? [];
    const popover = await this.popoverCtrl.create({
      component: UserListPopoverComponent,
      componentProps: {
        users,
      },
      cssClass: 'user-list-popover',
      showBackdrop: false, // allow to see video when opened
      event,
    });
    await popover.present();
    const { data: { userId: selected = '' } = {} } = await popover.onDidDismiss();
    if (selected) {
      this.modalOpened.emit(selected);
    }
  }
}
