import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// import { TagInputModule } from '@vpetrusevici/ngx-chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TaggerInputComponent } from './tagger-input.component';


@NgModule({
  imports: [
    // TagInputModule, 
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
  ],
  declarations: [
    TaggerInputComponent,
  ],
  exports: [
    TaggerInputComponent,
  ]
})
export class TaggerInputModule { }
