/** @format */

import { Component, Input } from '@angular/core';
import { ProjectMemberModalComponent } from '@members/project-member-modal/project-member-modal.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { DEFAULT_USER_AVATAR_TERTIARY, User } from '@shared/models/user.model';

@Component({
  selector: 'app-user-list-popover',
  templateUrl: './user-list-popover.component.html',
  styleUrls: ['./user-list-popover.component.scss'],
})
export class UserListPopoverComponent {
  @Input() users: Partial<User>[] = [];

  avatarFallback = DEFAULT_USER_AVATAR_TERTIARY;

  constructor(private modalController: ModalController, private popoverCtrl: PopoverController) {}

  async viewUserProfile(userId: string) {
    const modal = await this.modalController.create({
      component: ProjectMemberModalComponent,
      componentProps: {
        userId,
      },
      cssClass: 'modal-lg',
    });
    this.popoverCtrl.dismiss({ userId });
    return await modal.present();
  }
}
