/** @format */

import { Injectable } from '@angular/core';
import { SocialSharingService } from './social-sharing.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ConfigService } from '@app/core/config';
import { ShareableItem, ShareResponse, SHARING_METHOD } from './social-sharing.model';
import { extractSharingParamsFromShareableItem } from './shared/utils';

const DEBUG_LOGS = false;
const PAGE = '[SocialSharingNative]';
@Injectable()
export class SocialSharingNativeService implements SocialSharingService {
  constructor(public socialSharing: SocialSharing, public configService: ConfigService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async share(item: ShareableItem, event?: MouseEvent): Promise<ShareResponse> {
    try {
      const appConfig = await this.configService.appConfig;
      const sharingParams = extractSharingParamsFromShareableItem(item, appConfig);

      return this.socialSharing
        .shareWithOptions(sharingParams)
        .then(() =>
          Promise.resolve({
            itemType: sharingParams.itemType,
            method: SHARING_METHOD.NATIVE,
            shareUrl: sharingParams.url,
          })
        )
        .catch((error) => {
          throw new Error(error);
        });
    } catch (error) {
      console.error(error);
      return Promise.reject({
        message: 'Sorry, we hit an error while trying to share. Please refresh and try again.',
      });
    }
  }

  async shareTo(item: ShareableItem, action: SHARING_METHOD): Promise<ShareResponse> {
    DEBUG_LOGS && console.log(`${PAGE} shareTo`, item);

    const functionName = 'shareTo' + action;

    try {
      const appConfig = await this.configService.appConfig;
      const sharingParams = extractSharingParamsFromShareableItem(item, appConfig);
      if (this[functionName]) {
        // method exists on the component
        return this[functionName](sharingParams); // call it, for ex. 'shareToFacebook'
      } else {
        return this.socialSharing
          .shareWithOptions(sharingParams)
          .then(() =>
            Promise.resolve({
              itemType: sharingParams.itemType,
              method: action,
              shareUrl: sharingParams.url,
            })
          )
          .catch((error) => {
            throw new Error(error);
          });
      }
    } catch (error) {
      console.error(error);
      return Promise.reject({
        message: 'Sorry, we hit an error while trying to share. Please refresh and try again.',
      });
    }
  }
}
