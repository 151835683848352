import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Router } from "@angular/router";
import keyBy from 'lodash/keyBy';
import { Project, PROJECT_MEMBER_ROLE } from "@projects/shared/project.model";
import { PROJECT_DETAIL_ROUTE } from "@app/app-routing.module";
import { SubscriptionStatus } from "@billing/shared/billing.model";
import { DiscoverCardClick, ITEM_TYPE } from "@shared/models/layout.model";

@Component({
  selector: "app-project-slide",
  templateUrl: "./project-slide.component.html",
  styleUrls: ["./project-slide.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSlideComponent {
  @Input() item: Project;
  @Input() currentUsername: string;
  @Output() itemClick = new EventEmitter<DiscoverCardClick>();
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;
  @Input() canShare = true;
  @Input() showAvatar = true;
  @Input() isProjectMember = false;
  @Input() isEvent = false;

  constructor(
    private router: Router
  ) {}

  onClicked(event, item: Project) {
    this.router.navigate([`/${PROJECT_DETAIL_ROUTE}`, item.id]);
    this.itemClick.emit({ event, item, itemType: ITEM_TYPE.Projects });
  }

  get role(): PROJECT_MEMBER_ROLE {
    if (!this.item || !Array.isArray(this.item.members)) {
      return null;
    }
    const projectMembersMap = keyBy(this.item.members.filter(member => member.isActive), 'username');

    return (projectMembersMap.hasOwnProperty(this.currentUsername) && projectMembersMap[this.currentUsername].role) ? projectMembersMap[this.currentUsername].role : null;
  }

  get level(): string {
    return this.item && this.item.subscriptionStatus === SubscriptionStatus.Active && this.item.subscriptionLevel ? this.item.subscriptionLevel : '';
  }
}
