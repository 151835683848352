<fs-base-modal
  [title]="'PROJECT.FORM.TITLE' | translate"
  [titleIcon]="'folder-outline'"
  [description]="'PROJECT.FORM.DESCRIPTION' | translate"
  >
  <form id="projectFormEl" #createProjectForm="ngForm" [formGroup]="projectForm" (ngSubmit)="save()" class="ion-margin-bottom ion-padding-bottom"> 
    <fs-form-input
      [label]="'PROJECT.FORM.PROPS.TITLE.LABEL' | translate"
      type="text"
      [control]="projectForm.get('title') | formControlCast"
      [disabled]="isLoading"
      [hint]="'PROJECT.FORM.PROPS.TITLE.HINT' | translate"
    ></fs-form-input>
    <fs-form-input
      [label]="'PROJECT.FORM.PROPS.MISSION.LABEL' | translate"
      type="text"
      [control]="projectForm.get('mission') | formControlCast"
      [disabled]="isLoading"
      [hint]="'PROJECT.FORM.PROPS.MISSION.HINT' | translate"
    ></fs-form-input>
    <fs-form-textarea
      [label]="'PROJECT.FORM.PROPS.DESCRIPTION.LABEL' | translate"
      type="text"
      [control]="projectForm.get('description') | formControlCast"
      [disabled]="isLoading"
      [rows]="4"
      [hint]="'PROJECT.FORM.PROPS.DESCRIPTION.HINT' | translate"
    ></fs-form-textarea>
    <fs-form-input
      [label]="'PROJECT.FORM.PROPS.OWNER.LABEL' | translate"
      type="text"
      [control]="projectForm.get('owner') | formControlCast"
      [disabled]="true"
      hidden
    ></fs-form-input>
    <ion-list *ngIf="true">
      <!--
            ngIf used to fix disapearing icon checks when reopening modal
            Open issue: https://github.com/ionic-team/ionic-framework/issues/26526
          -->
      <fs-form-radio class="form-radio"
        [header]="'PROJECT.SETTINGS.PRIVACY.LABEL' | translate"
        [values]="{
          'PUBLIC': {
            title: 'PROJECT.SETTINGS.PRIVACY.PUBLIC_LABEL' | translate,
            description: 'PROJECT.SETTINGS.PRIVACY.PUBLIC_DESC' | translate
          },
          'UNLISTED': {
            title: 'PROJECT.SETTINGS.PRIVACY.UNLISTED_LABEL' | translate,
            description: 'PROJECT.SETTINGS.PRIVACY.UNLISTED_DESC' | translate
          },
          'PRIVATE': {
            title: 'PROJECT.SETTINGS.PRIVACY.PRIVATE_LABEL' | translate,
            description: 'PROJECT.SETTINGS.PRIVACY.PRIVATE_DESC' | translate
          }
        }"
        [control]="projectForm.get('privacy') | formControlCast"
      ></fs-form-radio>
      <fs-form-radio
        [header]="'PROJECT.SETTINGS.IS_MODERATED.LABEL' | translate"
        [values]="{
          true: {
            title: 'PROJECT.SETTINGS.IS_MODERATED.TRUE_LABEL' | translate,
            description: 'PROJECT.SETTINGS.IS_MODERATED.TRUE_DESC' | translate
          },
          false: {
            title: 'PROJECT.SETTINGS.IS_MODERATED.FALSE_LABEL' | translate,
            description: 'PROJECT.SETTINGS.IS_MODERATED.TRUE_DESC' | translate
          }
        }"
        [control]="projectForm.get('isModerated') | formControlCast"
      >
      </fs-form-radio>
      <section formGroupName="config">
        <fs-form-radio
          [header]="'PROJECT.SETTINGS.SHOW_RECENT_STACKS.LABEL' | translate"
          [values]="{
            true: {
              title: 'PROJECT.SETTINGS.SHOW_RECENT_STACKS.TRUE_LABEL' | translate,
              description: 'PROJECT.SETTINGS.SHOW_RECENT_STACKS.TRUE_DESC' | translate
            },
            false: {
              title: 'PROJECT.SETTINGS.SHOW_RECENT_STACKS.FALSE_LABEL' | translate,
              description: 'PROJECT.SETTINGS.SHOW_RECENT_STACKS.FALSE_DESC' | translate
            }
          }"
          [control]="configGroup.get('showRecentStacks') | formControlCast"
        >
        </fs-form-radio>
        <fs-form-radio *ngIf="enableShowTrending"
          [header]="'PROJECT.SETTINGS.SHOW_TRENDING_STACKS.LABEL' | translate"
          [values]="{
            true: {
              title: 'PROJECT.SETTINGS.SHOW_TRENDING_STACKS.TRUE_LABEL' | translate,
              description: 'PROJECT.SETTINGS.SHOW_TRENDING_STACKS.TRUE_DESC' | translate
            },
            false: {
              title: 'PROJECT.SETTINGS.SHOW_TRENDING_STACKS.FALSE_LABEL' | translate,
              description: 'PROJECT.SETTINGS.SHOW_TRENDING_STACKS.FALSE_DESC' | translate
            }
          }"
          [control]="configGroup.get('showTrendingStacks') | formControlCast"
        >
        </fs-form-radio>
      </section>
      <fs-form-radio *ngIf="enableBilling"
        [header]="'PROJECT.SETTINGS.PROJECT_TYPE.LABEL' | translate"
        [values]="{
          'PROJECT': {
            title: 'PROJECT.SETTINGS.PROJECT_TYPE.DEFAULT_LABEL' | translate,
            description: 'PROJECT.SETTINGS.PROJECT_TYPE.DEFAULT_DESC' | translate
          },
          'EVENT': {
            title: 'PROJECT.SETTINGS.PROJECT_TYPE.EVENT_LABEL' | translate,
            description: 'PROJECT.SETTINGS.PROJECT_TYPE.EVENT_DESC' | translate
          },
          'WEDDING': {
            title: 'PROJECT.SETTINGS.PROJECT_TYPE.WEDDING_LABEL' | translate,
            description: 'PROJECT.SETTINGS.PROJECT_TYPE.WEDDING_DESC' | translate
          }
        }"
        [control]="projectForm.get('eventType') | formControlCast"
      >
      </fs-form-radio>
    </ion-list>
    <div *ngIf="error">
      <div class="project-form__error">
        <strong>{{ 'ERRORS.ERROR' | translate }}:</strong> {{ error }}
      </div>
    </div>
  </form>
  <div class="footer ion-text-center ion-margin">
    <!-- @note issue with submitting the form from button outside the form el.. 
       * resolved with #createProjectForm="ngForm"
       * https://forum.ionicframework.com/t/form-submit-in-footer/71732/10
       * https://github.com/ionic-team/ionic-framework/issues/21194 
       formerly: form="projectFormEl"
    -->
    <fs-button
      (click)="createProjectForm.ngSubmit.emit()"
      [disabled]="isLoading || projectForm.invalid"
      type="submit"
      size="large"
      >{{ 'PROJECT.FORM.SUBMIT' | translate }}</fs-button
    >
    <!-- [blocked]="true" -->
  </div>
</fs-base-modal>