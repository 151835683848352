/**
 * @license Private All code, logic and derivatives are property of Filmstacker Inc.
 * @author: jd gibbs <j2d2> jd@filmstacker.com
 * @since: 2019 July
 * @format
 */
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// eslint-disable-next-line max-len
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed for ngx-chips (https://github.com/Gbuomprisco/ngx-chips)
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
/**
 * MVP-1128 moved from app.module to src/app/core/store/store.module.ts
 */
// import { IonicStorageModule, Drivers } from './core/store/ionic-storage';

import { NgRxStoreModule } from './core/store/store.module';

import { CoreModule } from './core/core.module';
import { SentryErrorHandler } from './core/services/analytics/sentry.service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// feature modules
import { ExploreModule } from './modules/explore/explore.module';
/** @todo MVP-1170 remove this from root app.module to reduce initial bundle size, but note it's usage in user-menu */
import { BillingModule } from '@billing/billing.module';
import { OnboardingModule } from '@onboarding/onboarding.module';
import { ProModule } from '@pro/pro.module';
import { TeamsModule } from '@teams/teams.module';

// modals
import { TermsPageModule } from './pages/terms/terms.module';
import { PrivacyPageModule } from './pages/privacy/privacy.module';
import { CommunityRulesPageModule } from './pages/community-rules/community-rules.module';

// Directives
import { DirectivesModule } from './directives/directives.module';

// components
import { UserMenuComponent } from './components/user-menu/user-menu.component';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Utils } from '@shared/utils';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PortalModule } from '@angular/cdk/portal';
import { VersionReloadComponent } from '@shared/components/version-reload/version-reload.component';
import { ConsentBannerComponent } from '@shared/components/consent-banner/consent-banner.component';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

// examples
// import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';
// import { TabsLayoutComponent } from './layouts/tabs-layout/tabs-layout.component';
// import { AuthModule } from './modules/auth/auth.module';
// import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

@NgModule({
  declarations: [AppComponent, UserMenuComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // 3rd party
    HttpClientModule,
    PortalModule,
    NgRxStoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    // app
    IonicModule.forRoot({
      mode: 'ios',
      loadingSpinner: 'crescent',
      spinner: 'crescent',
      /**
       * Ionic7 now requires this flag to be set
       * to allow showUpdateAvailablePrompt AlertController.message to have <br>
       * https://github.com/ionic-team/ionic-framework/issues/27118#issuecomment-1497470528
       */
      innerHTMLTemplatesEnabled: true,
    }),
    // core & shared
    AppRoutingModule, // imported before features with child routing, to get precedence
    DirectivesModule,
    CoreModule,
    // features
    BillingModule,
    OnboardingModule,
    ProModule,
    TeamsModule,
    ExploreModule,
    //modals
    TermsPageModule,
    PrivacyPageModule,
    CommunityRulesPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production && Utils.isServiceWorkersSupported(),
    }),
    // Standalone Components
    ConsentBannerComponent,
    VersionReloadComponent,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    SocialSharing,
  ],
  bootstrap: [AppComponent],
  // added to support swiper.js per https://ionicframework.com/docs/angular/slides
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
