/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as stackActions from '@store/actions/stacks.actions';
import { getStackPlayWithClips } from '@store/selectors/stacks.selectors';
import { Stack } from '@shared/models/stack.model';

@Injectable({
  providedIn: 'root',
})
export class StackService {
  constructor(private store: Store<State>) {}

  /**
   * set the current Playing Stack, and return that Stack once loaded by Effect
   */
  selectAndGetPlay(projectId: string, stackId: string): Observable<Stack> {
    if (projectId && stackId) {
      this.store.dispatch(stackActions.selectIdPlay({ projectId, stackId }));
    }
    return this.store.select(getStackPlayWithClips);
  }

  /*
   * removed as it was causing confusing performance issues..
   * set the current Editing Stack, and return that Stack once loaded by Effect
   */
  // selectAndGetEdit(projectId: string, stackId: string): Observable<Stack> {
  //   if (projectId && stackId) {
  //     this.store.dispatch(stackActions.selectIdEdit({ projectId, stackId }));
  //   }
  //   return this.store.select(selectStackEdit);
  // }

  setStackIsApproved({
    projectId,
    stackId,
    isApproved = true,
  }: {
    projectId: string;
    stackId: string;
    isApproved: boolean;
  }) {
    this.store.dispatch(stackActions.setApproved({ projectId, stackId, isApproved }));
  }
  setStackFeatured({
    projectId,
    stackId,
    featured,
    isApproved,
    fromIdx,
    toIdx,
  }: {
    projectId: string;
    stackId: string;
    featured: number;
    isApproved?: boolean;
    fromIdx?: number;
    toIdx?: number;
  }) {
    this.store.dispatch(stackActions.setFeatured({ projectId, stackId, featured, isApproved, fromIdx, toIdx }));
  }

  // can subscribe to core stack service stacksUpdated$

  /**
   * Update the Stack to be collaborative MVP-1176
   */
  makeCollaborative(projectId, stackId, description = '') {
    this.store.dispatch(stackActions.setCollaborative({ projectId, stackId, isCollaborative: 1, description }));
  }
  removeCollaborative(projectId, stackId) {
    this.store.dispatch(stackActions.setCollaborative({ projectId, stackId, isCollaborative: 0 }));
  }
}
