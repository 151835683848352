/** @format */

// import _memoize from 'lodash/memoize';
import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import {
  State,
  featureKey,
  selectEntities,
  selectIds,
  getCurrentPlayIndex,
  getSelectedIdPlay,
  getSelectedIdEdit,
  getLoaded,
  getAddToStackPlaylist,
  getAddToStackPayload,
} from '../reducers/stacks.reducers';
import { selectClipEntities, getId as getClipId } from '@store/selectors/clips.selectors';
import { Stack } from '@shared/models/stack.model';

export { StackGroup, getProjectStacksGroup, getId, splitId } from '../reducers/stacks.reducers';

export const selectStacksState = createFeatureSelector<State>(featureKey);

export const selectStackEntities = createSelector(selectStacksState, selectEntities);
export const selectIdPlay = createSelector(selectStacksState, getSelectedIdPlay);
export const selectIdEdit = createSelector(selectStacksState, getSelectedIdEdit);
export const selectCurrentPlayIndex = createSelector(selectStacksState, getCurrentPlayIndex);

export const selectStackPlay = createSelector(
  selectStackEntities,
  selectIdPlay,
  (entities, selectedId) => entities[selectedId]
);
export const selectStackEdit = createSelector(
  selectStackEntities,
  selectIdEdit,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectAddToStackPlaylist = createSelector(selectStacksState, getAddToStackPlaylist);
export const selectAddToStackPayload = createSelector(selectStacksState, getAddToStackPayload);
export const selectAddToStackPayloadMeta = createSelector(
  selectAddToStackPlaylist,
  selectAddToStackPayload,
  (playlist, payload) => ({ playlist, ...payload })
);

/** @deprecated */
export const getStacksAll = createSelector(selectStackEntities, selectIds, (entities, ids) =>
  ((ids as string[]) || []).map((id) => entities[id])
);

export const getStacksGroupNextToken = (group: string) =>
  // eslint-disable-next-line arrow-body-style
  createSelector(selectStacksState, (state) => {
    // console.log(`store.getStacksGroupNextToken group:`, { group, state });
    return state && state.nextTokens && state.nextTokens[group] ? state.nextTokens[group] : '';
  });

export const getStackPlayPlaylist = createSelector(selectStackPlay, (stack) =>
  stack && Array.isArray(stack.playlist) ? stack.playlist : []
);
export const getStackPlayClips = createSelector(getStackPlayPlaylist, selectClipEntities, (playlist, entities) => {
  if (!Array.isArray(playlist) || playlist.length < 1) {
    return [];
  }
  // remove undefined
  return playlist.map((orderedClip) => entities[getClipId(orderedClip.projectId, orderedClip.id)]).filter(Boolean);
});

export const getStackPlayWithClips = createSelector(
  selectStackPlay,
  getStackPlayClips,
  (stack, clips) =>
    new Stack({
      ...stack,
      clips,
    })
);

export const getSelectedStackCurrentPlayClipId = createSelector(
  selectStackPlay,
  selectCurrentPlayIndex,
  (stack, index) => {
    if (!stack || !Array.isArray(stack.playlist) || stack.playlist.length < index + 1) {
      return {};
    }
    const { projectId, id } = stack.playlist[index];
    return { projectId, id };
  }
);

export const getSelectedStackCurrentPlayClip = createSelector(
  getSelectedStackCurrentPlayClipId,
  selectClipEntities,
  (clip, entities) => (clip && clip.projectId && clip.id ? entities[getClipId(clip.projectId, clip.id)] : null)
);

// // not used yet
// export const isSelectedClipInStacks = createSelector(
//   getStacksIds,
//   getSelectedClipId,
//   (ids, selected) => {
//     return ids.indexOf(selected) > -1;
//   }
// );

/**
 * @deprecated stacks.service.selectLoaded$
 */
export const getStacksLoaded = createSelector(selectStacksState, getLoaded);

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * MEMOIZATION
 * In contrast to a normal NgRx selector where the selector is shared across multiple components,
 * we now have a new instance of the selector every time the selector factory is invoked.
 * This has the effect that we lose the memoization benefits of the selector.
 *
 * For most cases this is fine and you won't notice the difference. But when the selector has to so expensive work,
 * you can add a memoization layer on top as a countermeasure.
 *
 * To accomplish this, we must bring our own memoization method to the table, for example lodash.memoize.
 * After this, we can simply wrap the selector inside of the memoize method.
 * Note that this is a simple example and that you probably don't need to memoize an entity lookup.
 *
 * By doing this, consuming the selector is no different than before.
 *
 * It's important to keep in mind that this builds up an in-memory cache,
 * so that's why it's better that the added entry is disposed of when it's possible.
 *
 * https://lodash.com/docs/4.17.15#memoize
 * Note: The cache is exposed as the cache property on the memoized function.
 * Its creation may be customized by replacing the _.memoize.Cache constructor
 * with one whose instances implement the Map
 * method interface of clear, delete, get, has, and set.
 *
 * @dev This will likely be the solution for Lists items
 *
 * Usage:
 * class MyComponent {
 *  list$ = this.store.select(lists.selectList('listId'));
 * }
 */
// export const selectList = _memoize((id: string) =>
//   createSelector(selectStackEntities, (entities) => entities[id])
// );

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * The currently recommended approach to this is using a factory function
 * ... but this is _not_ memoized
 */

/**
 * usage:
 * this.store.select(entityById(someID))
 */
export const entityById = (id: string) => createSelector(selectStackEntities, (entities) => entities[id]);

/**
 * Usage:
 * this.store.select(entitiesByIDs(arrayOfIDs))
 */
export const entitiesByIDs = (ids = []) =>
  createSelector(selectStackEntities, (entities) => ids.map((id) => entities[id]));

/**
 * However, a type safe alternative is demonstrated in RFC 2980. Applied to the answer from @jordan-gray:
 * Usage:
 * this.store.select(selectEntity({ id: myId }));
 */
export const selectEntity = (props: { id: string }) =>
  createSelector(selectStackEntities, (entities) => entities[props.id]);

// example:
// export const hasRight = (rightCode: RightCode) => createSelector(selectUser, (user) => {
//   return user?.rights?.indexOf(rightCode) !== -1;
// });
// // you can consume it as
// const canEditClient$ = this.store.select(hasRight(RIGHT_EDIT_CLIENT));
// https://github.com/ngrx/platform/issues/2980#issuecomment-819551245

// good example: https://github.com/ngrx/platform/issues/2980#issuecomment-856617283
