<ion-reorder-group
  [disabled]="!options.isDraggable"
  (ionItemReorder)="reorder($event)"
>
  <ng-container *ngIf="!items">
    <ion-item
      *ngFor="let item of [1, 2, 3, 4, 5, 6, 7]"
      lines="inset"
      class="list-stack-item ion-no-padding"
      style="--border-color: transparent;"
    >
      <div slot="start">
        <div class="list-stack-item__start">
          <div class="list-stack-item__start__reorder"></div>
          <ion-skeleton-text
            class="list-stack-item__start__poster d-none d-sm-block"
          ></ion-skeleton-text>
        </div>
      </div>
      <div class="list-stack-item__content">
        <ion-skeleton-text style="height: 20px;"></ion-skeleton-text>
      </div>
    </ion-item>
  </ng-container>

  <ng-container *ngIf="items">
    <ion-item
      *ngFor="let item of items; index as i; trackBy: trackByItem"
      lines="inset"
      class="list-stack-item ion-no-padding"
    >
      <div slot="start">
        <div class="list-stack-item__start">
          <ion-reorder>
            <div class="list-stack-item__start__reorder">
              <ion-icon
                *ngIf="options.isDraggable"
                name="reorder-two-outline"
                style="font-size: 24px;"
              ></ion-icon>
              <div *ngIf="options.isNumerable">
                {{ i + 1 | number: '2.0' }}
              </div>
            </div>
          </ion-reorder>
          <ion-reorder>
            <img
              class="list-stack-item__start__poster d-none d-sm-block"
              imageFadeIn
              loading="lazy"
              [src]="item.poster"
              [alt]="item.title"
            />
          </ion-reorder>
        </div>
      </div>
      <ion-buttons
        style="flex-direction: row-reverse;"
        slot="end"
      >
        <!-- width: 150px; -->
        <ion-button class="add-remove-text-btn ion-text-wrap" (click)="toggleFeatured(item)">
          <ng-container *ngIf="item.featured">
            <!-- <ion-icon slot="icon-only" name="star"></ion-icon> -->
            {{ 'STACK.ACTIONS.REMOVE_FROM_FEATURED' | translate }}
          </ng-container>
          <ng-container *ngIf="!item.featured">
            {{ 'STACK.ACTIONS.ADD_TO_FEATURED' | translate }}
          </ng-container>
        </ion-button>
        <div *ngIf="options.isDraggable" class="ion-text-center ion-color-medium">
          <small>
            {{ item.featured }}
          </small>
        </div>
      </ion-buttons>

      <div class="list-stack-item__content">
        <div
          class="list-stack-item__content__title"
          style="flex-grow: 2;"
          (click)="open(item)"
        >
          {{ item.title }}
        </div>

        <div class="list-stack-item__content__user d-none d-lg-block">
          <img
            class="list-stack-item__content__user__image"
            src="/assets/images/default-avatar.png"
          />
          {{ item.userId }}
        </div>

        <div class="d-none d-lg-block">
          {{ item.dtePublished | date: 'mediumDate' }}
        </div>

        <div class="d-none d-sm-block">
          {{ item.duration | duration }}
        </div>
      </div>
    </ion-item>
    <div class="list-stack-item__empty" *ngIf="items.length === 0">{{ 'LISTS.NO_ITEMS' | translate }}</div>
  </ng-container>
</ion-reorder-group>
