/** @format */

import { Component, HostListener } from '@angular/core';
import { ConfigService } from '@app/core/config';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-no-stack-clips-disclaimer',
  template: `
    <div class="ion-padding">
      <h2>{{ 'PLAYER.INTRO_HEADER' | translate }}</h2>
      <p [innerHTML]="'PLAYER.INTRO_TEXT' | translate"></p>
    </div>
  `,
  // styles: [':host >>> span { color: red; }'],
  styleUrls: ['./no-stack-clips-disclaimer.component.scss'],
})
export class NoStackClipsDisclaimerComponent {
  DISCOVER_LINK_ID = 'discover_link';

  constructor(private configService: ConfigService, private navCtrl: NavController) {}

  @HostListener('click', ['$event'])
  onDiscoverClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const elt = event.target as HTMLElement;
    const eltClass = elt.attributes.getNamedItem('class');
    if (eltClass && eltClass.value === this.DISCOVER_LINK_ID) {
      this.configService.getStackDiscoverPath().then((path) => {
        this.navCtrl.navigateForward(path);
      });
    }
  }
}
