<ion-fab-button [disabled]="disabled || processing" (click)="continue()">
  <ion-spinner *ngIf="processing else notProcessing"></ion-spinner>
  <ng-template #notProcessing>
    <ion-icon name="arrow-forward"></ion-icon>
  </ng-template>
</ion-fab-button>

<!-- 
  the "Drop-in Script" solution does not allow us to use our own Auth, so this is just a reference of how not to do it...
  [attr.data-cb-type]="'checkout'" does not work due to api config (using our auth)
  use portal instead
-->
<!-- <a #linkButton 
  href="javascript:void(0)" 
  [attr.data-cb-type]="'portal'" 
  [attr.data-cb-plan-id]="planId"
  style="display:none;"
>
  <ng-content></ng-content>
</a> -->

<!-- <a href = "javascript:void(0)"  data-cb-type = "checkout"  data-cb-plan-id = "wedding-500" > subscribe </a> -->
