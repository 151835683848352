/** @format */

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Project } from '@projects/shared/project.model';

@Component({
  selector: 'app-add-member-modal',
  templateUrl: './add-member-modal.component.html',
  styleUrls: ['./add-member-modal.component.scss'],
})
export class AddMemberModalComponent {
  @Input() isStudio: boolean = false;
  @Input() project: Project;

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
