/**
 * Question Service Provider
 *
 * @format
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventsService } from './events.service';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';

import { Question } from '../../shared/models/question.model';
import { ConfigService } from '@app/core/config';

/**
 * @todo: AOT error related to fromStore.getEnvironActiveProjectUrl
 */

const JSON_DATA = 'assets/data/questions.json';

interface IQuestionLoadParams {
  count?: number;
  startAt?: number;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  questions: Question[] = []; // the cache of available
  questionIndex: number = 0;
  currentQuestion: Question;

  environSub$: Subscription;
  activeProject: string;

  constructor(
    private store: Store<State>,
    private configService: ConfigService,
    private events: EventsService,
    private http: HttpClient
  ) {
    this.events.subscribe('questions:load', this.loadQuestions);

    /**
     * @todo: AOT error related to fromStore.getEnvironActiveProjectUrl
     */
    // this.environSub$ = this.store.select(fromStore.getEnvironActiveProjectUrl)
    //   .subscribe(activeProject => {
    //     this.activeProject = activeProject;
    //   });
  }

  loadQuestions = () => {
    const params: IQuestionLoadParams = {
      count: -1,
      startAt: 0,
    };
    if (this.questions.length > 0) {
      console.log('[QuestionService] questions exist already.');
      this.pubQuestionsChanged(this.questions);
    } else {
      this.httpGetQuestions(params).subscribe({
        next: (data: Question[]) => {
          // TODO: do we need to unsubscribe?
          console.log('[QuestionService] httpGetQuestions response:', data);
          this.questions = data; //TODO: Push if not exists
          this.pubQuestionsChanged(this.questions);
        },
        error: (err) => {
          console.warn('[QuestionService] http Error:', err);
        },
      });
    }
  };

  /*
  // ionic event publishers
  */

  pubQuestionChange(question: Question) {
    this.currentQuestion = question;
    this.events.publish('question:change:current', question);
  }

  pubQuestionsChanged(questions: Question[], index?: number) {
    this.questions = questions;
    this.questionIndex = index || 0;
    this.pubQuestionChange(this.questions[this.questionIndex]);
    this.events.publish('questions:change', { questions, questionIndex: this.questionIndex });
  }

  changeQuestionIndex(index: number) {
    this.questionIndex = index;
    this.pubQuestionChange(this.questions[this.questionIndex]);
    this.events.publish('questions:index:change', index);
  }

  /*
  GETTERS
  */

  getQuestion(params: IQuestionLoadParams) {
    console.log('[QuestionService] getQuestion()', params);

    const filtered = this.questions.filter((item) => {
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const field = item[key];
          if (typeof field == 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });

    return filtered && filtered.length > 0 ? filtered[0] : new Question({});
  }

  /*
    OBSERVABLES
  */

  private httpGetQuestions(params?: IQuestionLoadParams) {
    return this.http.get<Question[]>(JSON_DATA).pipe(
      map((json) => {
        if (params) {
          // console.info("[QuestionService] httpGetQuestions w/ params:",params, json);
          const begin = params.startAt || 0,
            end = (params.count && params.count) > 0 ? begin + params.count : undefined;
          if (end) {
            return json.slice(begin, end);
          }
          return json.slice(begin);
        }
        // console.log("[QuestionService] httpGetQuestions res:",json);
        return json;
      })
    );
  }

  // onDestroy is not called for service providers
  // ngOnDestroy() {
  //   this.events.unsubscribe('question:loadQuestions', this.loadQuestions);
  //   this.environSub$.unsubscribe();
  // }
}
