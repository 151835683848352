/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '../base-form.component';

@Component({
  selector: 'fs-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent extends BaseFormComponent implements OnInit {
  @Input() type?: string = 'text';
  @Input() placeholder?: string;
  @Input() hint: string = '';
  @Input() initValue?: string;
  @Input() showErrorTextOnError = true;

  constructor(translate: TranslateService) {
    super(translate);
  }

  ngOnInit() {
    if (this.initValue) {
      this.control.setValue(this.initValue);
    }
  }
}
