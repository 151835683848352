<fs-form-item [label]="label" [errorMessage]="errorMessage" class="fs-form-select">
  <ion-select
    mode="md"
    [interfaceOptions]="interfaceOptions"
    [placeholder]="placeholder"
    [label]="label"
    [helperText]="hint + ' ' + (errorMessage ? errorMessage : '')"
    [value]="control.value"
    [disabled]="disabled"
    [interface]="interface"
    (ionBlur)="isReadyForValidation = true"
    (ionChange)="isReadyForValidation = true; updateProp($any($event).detail.value)"
  >
    <!-- 
      weirdly this is not working. haven't investigated why:
      [style.border-color]="errorMessage ? 'var(--ion-color-danger)' : 'none'"
    -->
    <ion-select-option *ngFor="let item of items" [value]="item.value">{{
      item.text
    }}</ion-select-option>
  </ion-select>
  <!-- <span class="fs-form-select__hint" *ngIf="hint" [innerHTML]="hint"></span> -->
</fs-form-item>
