/** @format */

import { Injectable } from '@angular/core';
import { SocialSharingService } from './social-sharing.service';
import { SHARING_METHOD, ShareResponse, SharingParams } from './social-sharing.model';
import { ModalController, PopoverController } from '@ionic/angular';
import { ConfigService } from '@app/core/config';
import { SocialSharingPopoverComponent } from './social-sharing-popover/social-sharing-popover.component';
import { Platform } from '@ionic/angular';
import { ShareableItem } from './social-sharing.model';
import { CopyToClipboardFeedbackPopoverComponent } from './copy-to-clipboard-feedback-popover/copy-to-clipboard-feedback-popover.component';
import { extractSharingParamsFromShareableItem } from './shared/utils';
import { QrModalComponent } from '@tokens/shared/components/qr-modal/qr-modal.component';

const DEBUG_LOGS = false;
const PAGE = '[SocialSharingWeb]';

// declare let FB;

@Injectable()
export class SocialSharingWebService implements SocialSharingService {
  constructor(
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private configService: ConfigService,
    private platform: Platform
  ) {}

  async share(item: ShareableItem, event?: MouseEvent): Promise<ShareResponse> {
    DEBUG_LOGS && console.log('Shared item: ', { item });
    try {
      const appConfig = await this.configService.appConfig;
      DEBUG_LOGS && console.log('Share', { item, appConfig });

      if (!appConfig.allowSharing) {
        return Promise.reject({ message: 'Sorry, social sharing is disabled.', success: false, item: item.type });
      }
      if (!item || !item.shareUrl) {
        return Promise.reject({ message: 'Sorry, could not find that URL.', success: false, item: item.type });
      }

      if (this.platform.is('mobile') && navigator && navigator.share) {
        DEBUG_LOGS && console.log('The Web Share API IS supported...');
        const sharingParams = extractSharingParamsFromShareableItem(item, appConfig);

        return navigator
          .share({
            url: sharingParams.url,
            text: sharingParams.text,
            title: sharingParams.title,
          })
          .then(() => ({
            success: true,
            itemType: item.type,
            shareUrl: sharingParams.url,
            method: SHARING_METHOD.WEB_API,
          }));
      } else {
        DEBUG_LOGS && console.log('The Web Share API is not supported in your browser.');
        return this.openSocialSharingPopover(item, event);
      }
    } catch (error) {
      console.error(error);
      return Promise.reject({
        message: 'Sorry, we hit an error while trying to share. Please refresh and try again.',
      });
    }
  }

  async openSocialSharingPopover(item: ShareableItem, event?: MouseEvent): Promise<ShareResponse> {
    const popover = await this.popoverCtrl.create({
      component: SocialSharingPopoverComponent,
      componentProps: {
        item,
      },
      event,
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();
    if (data && data.action) {
      return await this.shareTo(item, data.action);
    } else {
      // this will happen when the background is clicked to dismiss the popover...
      // console.log(`${PAGE} no popover action to handle?`, data);
      return Promise.reject({
        message: 'no action to handle',
      });
    }
  }

  async shareTo(item: ShareableItem, action: SHARING_METHOD): Promise<ShareResponse> {
    DEBUG_LOGS && console.log(`${PAGE} shareTo`, item);

    const functionName = 'shareTo' + action;

    const appConfig = await this.configService.appConfig;
    const sharingParams = extractSharingParamsFromShareableItem(item, appConfig);
    if (this[functionName]) {
      // method exists on the component
      return this[functionName](sharingParams); // call it, for ex. 'shareToFacebook'
    } else {
      console.log(`${PAGE} no popover action to handle?`, action);
      return Promise.reject({
        message: 'no action to handle',
      });
    }
  }

  /**
   * Copy Text to Clipboard
   * https://www.30secondsofcode.org/blog/s/copy-text-to-clipboard-with-javascript
   */
  private async shareToClipboard(sharingParams: SharingParams): Promise<ShareResponse> {
    try {
      const str = sharingParams.url;
      /*
      DEBUG_LOGS && console.log('Copied on cipboard'); */
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(str);
      } else {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();

        document.execCommand('copy');
        document.body.removeChild(el);
      }
      await this.openCopyToClipboardFeedbackPopover({
        itemType: sharingParams.itemType,
        method: SHARING_METHOD.CLIPBOARD,
        shareUrl: str,
      });

      return Promise.resolve({
        itemType: sharingParams.itemType,
        method: SHARING_METHOD.CLIPBOARD,
        shareUrl: sharingParams.url,
      });
    } catch (error) {
      console.warn(error);
      return Promise.reject({
        message: 'Unable to copy to clipboard...',
      });
    }
  }

  private async openCopyToClipboardFeedbackPopover(copyResult: ShareResponse, event?: MouseEvent) {
    const popover = await this.popoverCtrl.create({
      component: CopyToClipboardFeedbackPopoverComponent,
      componentProps: {
        copyResult,
      },
      event,
    });
    await popover.present();
  }

  /**
   * Show QR Code Modal
   */
  private async shareToQR(sharingParams: SharingParams): Promise<ShareResponse> {
    try {
      const str = sharingParams.url;
      // we will show this in a modal from the popover component
      
      await this.openQRPopover({
        itemType: sharingParams.itemType,
        method: SHARING_METHOD.QR,
        shareUrl: str,
      });

      return Promise.resolve({
        itemType: sharingParams.itemType,
        method: SHARING_METHOD.QR,
        shareUrl: sharingParams.url,
      });
    } catch (error) {
      console.warn(error);
      return Promise.reject({
        message: 'Unable to copy to clipboard...',
      });
    }
  }

  private async openQRPopover(share: ShareResponse, event?: MouseEvent) {
    const modal = await this.modalCtrl.create({
      component: QrModalComponent,
      componentProps: {
        shareUrl: share.shareUrl,
      },
      cssClass: 'social-sharing-modal', //'modal-lg',
    });
    await modal.present();
  }

  private async shareToFacebook(sharingParams: SharingParams): Promise<ShareResponse> {
    const pageUrl = encodeURIComponent(sharingParams.url);
    const sharingLink = `https://www.facebook.com/sharer.php?u=${pageUrl}`;
    const win = this.openWindow(sharingLink, 'SocialSharingWindow');
    if (win && win.success) {
      return Promise.resolve({
        itemType: sharingParams.itemType,
        method: SHARING_METHOD.FACEBOOK,
        shareUrl: sharingParams.url,
      });
    } else {
      return Promise.reject({
        message: 'Unable to share to Facebook.',
      });
    }
  }

  /**
   * Share on LinkedIn
   * Most of the new endpoints require API and Auth
   * let's try a simple workaround, based on:
   * https://stackoverflow.com/questions/10713542/how-to-make-a-custom-linkedin-share-button
   *
   * https://www.linkedin.com/sharing/share-offsite/?url={url}
   */
  private shareToLinkedIn(sharingParams: SharingParams): Promise<ShareResponse> {
    const pageUrl = encodeURIComponent(sharingParams.url);
    const sharingLink = `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`;
    const win = this.openWindow(sharingLink, 'ShareOnLinkedIn');
    if (win && win.success) {
      return Promise.resolve({
        itemType: sharingParams.itemType,
        method: SHARING_METHOD.LINKEDIN,
        shareUrl: sharingParams.url,
      });
    } else {
      return Promise.reject({
        message: 'Unable to share to LinkedIn.',
      });
    }
  }

  private async shareToTwitter(sharingParams: SharingParams): Promise<ShareResponse> {
    const pageUrl = encodeURIComponent(sharingParams.url);
    const text = encodeURIComponent(sharingParams.title);
    let sharingQuery = `url=${pageUrl}&text=${text}`;

    if (sharingParams.hashtags) {
      sharingQuery += '&hashtags=' + sharingParams.hashtags.join(',');
    }
    const sharingLink = `https://twitter.com/intent/tweet?${sharingQuery}`;
    const win = this.openWindow(sharingLink, 'SocialSharingWindow');
    if (win && win.success) {
      return Promise.resolve({
        itemType: sharingParams.itemType,
        method: SHARING_METHOD.TWITTER,
        shareUrl: sharingParams.url,
      });
    } else {
      return Promise.reject({
        message: 'Unable to share to Twitter.',
      });
    }
  }

  private openWindow(url, windowName) {
    try {
      const newwindow = window.open(url, windowName, this.getWindowOptions());
      newwindow.opener = null;
      if (window.focus) {
        newwindow.focus();
      }
      return { success: true };
    } catch (error) {
      console.warn(error);
      return { success: false, error };
    }
  }

  private getWindowOptions() {
    if (!window || !window.innerWidth) {
      return 'resizable,scrollbars,status';
    }
    const width = window.innerWidth > 500 ? 500 : window.innerWidth - 20;
    const height = window.innerHeight > 600 ? 600 : window.innerHeight - 20;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    return ['resizable,scrollbars,status', 'height=' + height, 'width=' + width, 'left=' + left, 'top=' + top].join();
  }
}
