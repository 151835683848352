/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
// import { BillingModule } from '@billing/billing.module';
import { TeamsRoutingModule } from './teams-routing.module';
import { OnboardingModule } from '@onboarding/onboarding.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    TranslateModule,
    // BillingModule,
    OnboardingModule,
    TeamsRoutingModule,
  ],
  declarations: [],
})
export class TeamsModule {}
