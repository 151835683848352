/** @format */

import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { API, graphqlOperation } from '@aws-amplify/api';
import _get from 'lodash/get';
import { ProjectMember } from '@projects/shared/project.model';
import { ALL_PROJECT_MEMBER_FIELDS } from './api.models';
import { GraphQLResult } from './api-types';

const PAGE = '[ProjectCrewApiService]';

/**
 * QUERIES:
 *
  getProjectCrew(projectId: ID!,userId: String! ): ProjectMember
	listProjectCrews(filter: TableProjectCrewFilterInput,limit: Int,nextToken: String ): ProjectCrewConnection
	queryProjectCrewsByUserId(userId: String!,count: Int,nextToken: String ): ProjectCrewConnection
 */

/**
 * MUTATIONS:
 *
   createProjectCrew(
		projectId: ID!
		userId: String!
		username: String
		role: PROJECT_MEMBER_ROLE!
		isActive: Boolean
	): ProjectMember
	updateProjectCrew(
		projectId: ID!
		userId: String!
		username: String
		role: PROJECT_MEMBER_ROLE!
		isActive: Boolean
		createdAt: String
	): ProjectMember
	deleteProjectCrew(
		projectId: ID!
		userId: String!
	): ProjectMember
 */

@Injectable({
  providedIn: 'root',
})
export class ProjectCrewApiService {
  getCrewForProject(projectId: string): Observable<ProjectMember[]> {
    if (!projectId) {
      throwError(() => new Error('No ID Provided'));
    }

    const query = `query getCrewForProject {
      listProjectCrews(
        filter: {
          projectId:{
            eq: ${projectId}
          }
        }
      ) {
        items {
          ${ALL_PROJECT_MEMBER_FIELDS}
        }
        nextToken
      }
    }`;

    // Query using a parameter
    return from(API.graphql(graphqlOperation(query, { projectId })) as Promise<GraphQLResult>).pipe(
      map((res) => {
        const results = _get(res.data, 'listProjectCrews');
        const projectCrew: ProjectMember[] = _get(results, 'items', []);
        return projectCrew;
      })
    );
  }

  /**
   * Get All Projects that a User is a Crew on (isActive=true)
   * (note: does not include Projects that they are an Owner of)
   */
  getCrewForUser(userId: string): Observable<ProjectMember[]> {
    if (!userId) {
      throwError(() => new Error('No ID Provided'));
    }

    const params = {
      userId,
      count: 100,
      // todo: handle nextToken
    };

    const query = `query QueryProjectCrewsByUserId(
        $userId: String!
        $count: Int
        $nextToken: String
      ) {
        queryProjectCrewsByUserId(
          userId: $userId
          count: $count
          nextToken: $nextToken
        ) {
          items {
            ${ALL_PROJECT_MEMBER_FIELDS}
          }
          nextToken
        }
      }`;

    // Query using a parameter
    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        const results = _get(res.data, 'queryProjectCrewsByUserId');
        const projectCrew: ProjectMember[] = _get(results, 'items', []);
        return projectCrew;
      })
    );
  }

  /**
   * @param member ProjectMember
   */
  createProjectCrew(member: ProjectMember): Observable<ProjectMember> {
    console.log(`${PAGE} createProjectCrew`, member);

    // TODO...,
    // if (!this.userService.loggedIn) {
    //   return from("Not Authenticated");
    // }

    const params = member;

    const action = `mutation createProjectCrew(
      $projectId: ID!
      $userId: String!
      $username: String
      $role: PROJECT_MEMBER_ROLE!
      $isActive: Boolean
      $updatedBy: String
    ) {
      createProjectCrew(
        projectId: $projectId
        userId: $userId
        username: $username
        role: $role
        isActive: $isActive
        updatedBy: $updatedBy
      ) {
        ${ALL_PROJECT_MEMBER_FIELDS}
      }
    }`;

    return from(API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).pipe(
      map((res) => _get(res.data, 'updateProjectCrew') as ProjectMember)
    );
  }

  /**
   * @param member
   */
  updateProjectCrewMember(
    member:
      | ProjectMember
      | {
          projectId: string;
          userId: string;
          username?: string;
          role?: string;
          isActive: boolean;
          updatedBy?: string;
        }
  ): Observable<ProjectMember> {
    // if (!member.isActive) {
    //   console.warn(`${PAGE} Should we be deleting this record?`);
    // }
    const params = member;

    const action = `mutation UpdateProjectCrew(
      $projectId: ID!
      $userId: String!
      ${member.username ? '$username: String' : ''}
      ${member.role ? '$role: PROJECT_MEMBER_ROLE' : ''}
      ${member.updatedBy ? '$updatedBy: String' : ''}
      $isActive: Boolean
    ) {
      updateProjectCrew(
        projectId: $projectId
        userId: $userId
        ${member.username ? 'username: $username' : ''}
        ${member.role ? 'role: $role' : ''}
        ${member.updatedBy ? 'updatedBy: $updatedBy' : ''}
        isActive: $isActive
      ) {
        ${ALL_PROJECT_MEMBER_FIELDS}
      }
    }`;

    return from(API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).pipe(
      map((res) => _get(res.data, 'updateProjectCrew') as ProjectMember)
    );
  }

  /**
   * @param projectId
   * @param userId
   *  deleteProjectCrew(
   *     projectId: ID!
   *     userId: String!
   *  ): ProjectCrew
   */
  deleteProjectCrewMember(projectId: string, userId: string): Observable<ProjectMember> {
    const params = {
      projectId,
      userId,
    };

    const action = `mutation deleteProjectCrew(
      $projectId: ID!
      $userId: String!
    ) {
      deleteProjectCrew(
        projectId: $projectId
        userId: $userId
      ) {
        ${ALL_PROJECT_MEMBER_FIELDS}
      }
    }`;

    return from(API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).pipe(
      map((res) => _get(res.data, 'deleteProjectCrew') as ProjectMember)
    );
  }
}
