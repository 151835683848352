<div class="event-detail ion-text-capitalize">
  <div *ngIf="isPopover" class="popover-close">
    <ion-button size="small" fill="clear">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
  </div>
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size="10">
        <ion-grid>
          <ion-row>
            <ion-col size="12" [attr.size-md]="isPopover ? 12 : 4">
              <h3>
                {{ subscriptionLevel }}&nbsp;<span *ngIf="eventTitleTranslationPath">{{ eventTitleTranslationPath | translate }}</span>
                <!-- {{ 'AUTH.PLANS.SUBSCRIPTION_LEVEL' | translate }} -->
              </h3>
            </ion-col>
            <ion-col size="12" [attr.size-md]="isPopover ? 12 : 8">
              <dl>
                <dt *ngIf="subscriptionBy">{{ 'COMMON.OWNER' | translate }}:</dt>
                <dd *ngIf="subscriptionBy">{{ subscriptionBy }}</dd>
                <dt>{{ 'AUTH.STATUS' | translate }}:</dt>
                <dd>{{ (subscriptionisActive ? 'COMMON.ACTIVE' : 'COMMON.INACTIVE') | translate }}</dd>
                <!-- TODO:
                    COMMON.EXPIRES 
                    COMMON.RENEWS -->
                <!-- <div *ngIf="project?.expires">
                  {{ 'COMMON.EXPIRES_OR_RENEWS' | translate }}: 
                  {{ 'GET DATE' }}
                </div> -->
                <dt>{{ 'COMMON.EVENT_DATE' | translate }}:</dt>
                <dd>{{ eventDate }}</dd>
              </dl>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col *ngIf="!isPopover" size="2" class="col-right">       
        <ion-button 
          [disabled]="!subscriptionIsMine"
          fill="outline"
          (click)="editSubscription()">
          {{ 'COMMON.EDIT' | translate }}
          <ion-icon slot="end" name="create-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="isPopover" class="popover-actions">
      <ion-col>
        <ion-button 
          [disabled]="!subscriptionIsMine"
          fill="outline"
          (click)="editSubscription()">
          {{ 'COMMON.EDIT' | translate }}
          <ion-icon slot="end" name="create-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>