/** @format */

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonSearchbar, SearchbarCustomEvent } from '@ionic/angular';

const DEBUG_LOGS = false;

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit {
  // in case the <app-searchbar> is toggled by *ngIf, then this property will determine if it will be autofocussed right away
  @Input() autofocus = false;
  @Input() value = '';
  @Input() showCancelButton: 'always' | 'focus' | 'never' = 'never';
  @Input() placeholderText = 'Search';
  @Input() debounceTime = 1000;
  @Input() color = 'dark';
  /** Adds css class to enable media query for width to change at breakpoint */
  @Input() responsiveWidth = false;
  @Output() searchEnter = new EventEmitter<string>();
  @Output() searchChange = new EventEmitter<string>();
  @Output() searchCancel = new EventEmitter<string>();
  @Output() searchBlur = new EventEmitter<string>();
  @ViewChild('searchbar') searchbar: IonSearchbar;

  ngOnInit() {
    DEBUG_LOGS && console.log('search init', { value: this.value, autofocus: this.autofocus });
    if (this.autofocus) {
      setTimeout(() => {
        this.searchbar.setFocus();
      }, 50);
    }
  }

  onSearch() {
    DEBUG_LOGS &&
      console.log('onSearch', { value: this.value, searchBar: this.searchbar, searchbarValue: this.searchbar.value });
    this.searchEnter.emit(this.searchbar.value);
  }

  onChange(event: Event) {
    DEBUG_LOGS && console.log('onChange', { searchBar: this.searchbar, value: this.searchbar.value });
    const customEvent = event as SearchbarCustomEvent;
    this.searchChange.emit(customEvent.detail.value);
  }

  onClear() {
    DEBUG_LOGS && console.log('onClear', { searchBar: this.searchbar, value: this.searchbar.value });
    this.searchCancel.emit();
  }

  onCancel() {
    DEBUG_LOGS && console.log('onCancel', { searchBar: this.searchbar, value: this.searchbar.value });
    this.searchCancel.emit();
  }

  onBlur() {
    DEBUG_LOGS && console.log('onBlur', { searchBar: this.searchbar, value: this.searchbar.value });
    this.searchBlur.emit();
  }
}
