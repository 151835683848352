/** @format */

// import _memoize from 'lodash/memoize';
import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import {
  State,
  featureKey,
  getSearchProjects,
  getSearchTemplates,
  getSearchTags,
  getSearchLocations,
  getSearchDatetime,
  getSearchMood,
  getSearchCollabs,
  getSearchLanguage,
  lookupSearchProp,
  getRecentProjectIds,
} from '../reducers/environ.reducers';
export { getId } from '../reducers/environ.reducers';

/**
 * Environ Reducers
 * used by:
 * search-start
 */
export const selectEnvironState = createFeatureSelector<State>(featureKey);

export const getEnvironCore = createSelector(selectEnvironState, (state: State) => state);

/**
 * @todo if this is needed, (must refactor for AOT?)
 */
export const getEnvironActiveProjectId = createSelector(selectEnvironState, (state: State) => state.activeProjectId);

export const selectRecentProjectsIds = createSelector(selectEnvironState, getRecentProjectIds);

// export const getEnvironActiveProjectTitle = createSelector(
//   fromProjects.getEntities,
//   getEnvironActiveProjectUrl,
//   (projects, url) => {
//     if (projects && projects[url] && projects[url].id) {
//       return projects[url].id;
//     }
//     return "";
//   }
// );
// export const getEnvironActiveProject = createSelector(
//   fromProjects.getEntities,
//   getEnvironActiveProjectUrl,
//   (projects, url) => {
//     if (projects && projects[url]) {
//       return projects[url];
//     }
//     return {};
//   }
// );

// export const getEnvironCurrentClip = createSelector(selectEnvironState, fromEnviron.getCurrentClip);
// export const getEnvironCurrentStack = createSelector(selectEnvironState, fromEnviron.getCurrentStack);

export const getEnvironSearchProjects = createSelector(selectEnvironState, getSearchProjects);
export const getEnvironSearchTemplates = createSelector(selectEnvironState, getSearchTemplates);
export const getEnvironSearchTags = createSelector(selectEnvironState, getSearchTags);
export const getEnvironSearchLocations = createSelector(selectEnvironState, getSearchLocations);
export const getEnvironSearchDatetime = createSelector(selectEnvironState, getSearchDatetime);
export const getEnvironSearchMood = createSelector(selectEnvironState, getSearchMood);
export const getEnvironSearchCollabs = createSelector(selectEnvironState, getSearchCollabs);
export const getEnvironSearchLanguage = createSelector(selectEnvironState, getSearchLanguage);

export const lookupEnvironSearchProp = (key: string) => lookupSearchProp(key);

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * MEMOIZATION
 * In contrast to a normal NgRx selector where the selector is shared across multiple components,
 * we now have a new instance of the selector every time the selector factory is invoked.
 * This has the effect that we lose the memoization benefits of the selector.
 *
 * For most cases this is fine and you won't notice the difference. But when the selector has to so expensive work,
 * you can add a memoization layer on top as a countermeasure.
 *
 * To accomplish this, we must bring our own memoization method to the table, for example lodash.memoize.
 * After this, we can simply wrap the selector inside of the memoize method.
 * Note that this is a simple example and that you probably don't need to memoize an entity lookup.
 *
 * By doing this, consuming the selector is no different than before.
 *
 * It's important to keep in mind that this builds up an in-memory cache,
 * so that's why it's better that the added entry is disposed of when it's possible.
 *
 * https://lodash.com/docs/4.17.15#memoize
 * Note: The cache is exposed as the cache property on the memoized function.
 * Its creation may be customized by replacing the _.memoize.Cache constructor
 * with one whose instances implement the Map
 * method interface of clear, delete, get, has, and set.
 *
 * @dev This will likely be the solution for Lists items
 *
 * Usage:
 * class MyComponent {
 *  list$ = this.store.select(lists.selectList('listId'));
 * }
 */
// export const selectList = _memoize((id: string) =>
//   createSelector(selectClipEntities, (entities) => entities[id])
// );

/**
 * The currently recommended approach is using a factory function
 * @note this is _not_ memoized
 * MVP-970 ngrx deprecated selectorWithProps
 *
 * usage:
 * this.store.select(entityById(someID)
 */
//  export const entityById = (id: string) => createSelector(
//   selectEntities,
//   (entities) => entities[id]
// );
// export const entityByClip = (props: { id: string, projectId: string}) => createSelector(
//   selectEntities,
//   (entities) => entities[getId(props.projectId, props.id)]
// );

/**
 * However, a type safe alternative is demonstrated in RFC 2980. Applied to the answer from @jordan-gray:
 * Usage:
 * this.store.select(selectEntity({ id: myId }));
 */
// export const selectEntity = (props: { id: string }) =>
//   createSelector(selectEntities, (entities) => entities[props.id]);

// example:
// export const hasRight = (rightCode: RightCode) => createSelector(selectUser, (user) => {
//   return user?.rights?.indexOf(rightCode) !== -1;
// });
// // you can consume it as
// const canEditClient$ = this.store.select(hasRight(RIGHT_EDIT_CLIENT));
// https://github.com/ngrx/platform/issues/2980#issuecomment-819551245

// good example: https://github.com/ngrx/platform/issues/2980#issuecomment-856617283
