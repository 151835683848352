/** @format */

import { Component, Input } from '@angular/core';
import { SubscriptionLevel } from '@billing/shared';
import { BillingService } from '@billing/shared/services';

@Component({
  selector: 'fs-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent {
  @Input() currentUserId: string;
  @Input() subscriptionId;
  @Input() subscriptionLevel;
  @Input() subscriptionBy;
  @Input() subscriptionisActive = false;
  @Input() eventDate;
  @Input() canEdit = false;
  @Input() isPopover = false;

  get subscriptionIsMine() {
    return this.subscriptionBy && this.currentUserId === this.subscriptionBy;
  }

  get eventTitleTranslationPath() {
    return this.subscriptionLevel === SubscriptionLevel.Wedding ? 'PROJECT.EVENT' : '';
  }

  constructor(
    // private translate: TranslateService,
    // private store: Store<State>,
    private billingService: BillingService
  ) {}

  /**
   * Do the check and call service
   */
  editSubscription() {
    // this.isLoadingEvents = true;
    if (this.canEdit && this.subscriptionId) {
      this.billingService.openEditSubscription(this.subscriptionId);
    }
  }
}
