/** @format */

/**
 * UNUSED
 * Subscriptions: The fields shared and not duplicated
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SUB_ALL_FIELDS = `
  projectId
  stackId
  dteSaved
  userId
  title
  avatar
  eventId
  playlist {
    projectId
    id
    order
  }
  duration
  private
  privacy
  isLocked
  isApproved
  dtePublished
  description
  credits
  shareUrl
  shareDomain
  poster
  topics
  tags
  emotions
  recommended
  suggested
  featured
  shares
  restacks
  views
  votes
  likes
  hlsSrc
  hlsMeta
  contributors {
    userId
  }
  username
  name
  version
  email
  userIdType
  groups
  avatar
  location
  country
  bio
  numClipsWatched
  numClipsAddedToStack
  numStacksPublished
  numStacksWatched
  numShares
  numVotes
  createdAt
  updatedAt
  updatedBy
`;

export const ALL_PROJECT_FIELDS = `
  id
  title
  owner
  mission
  description
  featured
  views
  votes
  likes
  topics
  emotions
  template
  streams
  hero
  logoUrl
  created
  configJsonUrl
  channel
  shareUrl
  tags
  members {
    items {
      userId
      username
      role
      isActive
    }
  }
  privacy
  isModerated
  config
  allowPublicPublish
  eventType
  eventDate
  eventConfig
  eventIsActive
  eventUpdatedBy
  subscriptionId
  subscriptionBy
  subscriptionLevel
  subscriptionStatus
  subscriptionMinutes
  subscriptionExpires
  subscriptionRenews
  updatedBy
`;

export const ALL_PROJECT_MEMBER_FIELDS = `
  projectId
  userId
  username
  role
  isActive
  updatedBy
`;

/**
 * removed: userIdentityId
 */
export const ALL_STACK_FIELDS = `
  projectId
  stackId
  dteSaved
  userId
  title
  avatar
  eventId
  playlist {
    projectId
    id
    order
  }
  duration
  private
  privacy
  isLocked
  isCollaborative
  isApproved
  dtePublished
  description
  credits
  shareUrl
  shareDomain
  poster
  topics
  tags
  emotions
  recommended
  suggested
  featured
  shares
  restacks
  views
  votes
  likes
  hlsSrc
  hlsMeta
  contributors {
    userId
  }
  updatedBy
`;

/**
 * removed: userIdentityId
 */
export const ALL_CLIP_FIELDS = `
  projectId
  id
  userId
  created
  geoLat
  geoLng
  filmingDate
  language
  sources {
    src
    type
    hasMusic
    quality
    width
    height
    youtube_id
    durationInSeconds
  }
  tracks {
    kind
    label
    srclang
    src
    isDefault
  }
  source {
    hasMusic
    lastModifiedDate
    durationInSeconds
    youtube_id
    youtube_data
  }
  duration
  title
  description
  poster
  topics
  tags
  emotions
  youtube_id
  youtube_id_nomusic
  startTime
  endTime
  views
  votes
  featured
  suggested
  private
  privacy
  isLocked
  isApproved
  modified
  numStacks
  hlsSrc
  hlsMeta
  updatedBy
`;

/**
 * simply doesn't include created, as that errors our subscription since created is non-nullable
 *
 * @todo modify schema for Clip so created is not required..
 */
export const SUB_CLIP_FIELDS = `
 projectId
 id
 userId
 sources {
   src
   type
   hasMusic
   quality
   width
   height
   youtube_id
   durationInSeconds
 }
 tracks {
   kind
   label
   srclang
   src
   isDefault
 }
 duration
 poster
 topics
 tags
 emotions
 youtube_id
 startTime
 endTime
 private
 privacy
 isLocked
 isApproved
 modified
 views
 hlsSrc
 hlsMeta
 isLocked
 isApproved
 updatedBy
`;

/**
 * All UserData for current User
 * ignoring:
 *   identityId
 */
export const ALL_USER_DATA_FIELDS = `
	userId
	identityId
	username
	name
	version
	email
	userIdType
	groups
	avatar
	location
	country
	bio
	watchLater
  recentProjects
	numClipsWatched
	numClipsAddedToStack
	numStacksPublished
	numStacksWatched
	numShares
	numVotes
	votes
	likes
	historyClips {
    projectId
    id
    order
  }
  subscriptionId
  subscriptionLevel
  subscriptionStatus
  subscriptionMinutes
  subscriptionExpires
  subscriptionRenews
  environment
  createdAt
  updatedAt
`;
export const CURRENT_USER_DATA_FIELDS = `
  numClipsWatched
  numClipsAddedToStack
  numStacksWatched
  numStacksPublished
  votes
  likes
  watchLater
  recentProjects
  version
  historyClips {
    projectId
    id
    order
  }
  subscriptionId
  subscriptionLevel
  subscriptionStatus
  subscriptionMinutes
  subscriptionExpires
  subscriptionRenews
  environment
`;
export const PUBLIC_USER_DATA_FIELDS = `
  userId
  identityId
  username
  name
  avatar
  location
  country
  bio
  numStacksPublished
  numClipsWatched
  numStacksWatched
  numShares
  numVotes
  subscriptionLevel
  subscriptionStatus
  subscriptionExpires
  subscriptionRenews
  createdAt
  updatedAt
`;
