<div
  class="thumbnail-poster"
  [class.thumbnail-poster--needs-moderation]="isModerated && !item.isApproved"
  [class.thumbnail-poster--can-moderate]="canModerate"
  [class.thumbnail-poster--is-playing]="isPlaying"
  [class.thumbnail-poster--is-current]="isCurrent"
  [class.thumbnail-poster--is-mine]="isMine"
  [class.thumbnail-poster--is-collab-draft]="!isMine && canCollaborate"
  [class.thumbnail-poster--clip]="isClip(item)"
  [class.thumbnail-poster--stack]="isStack(item)"
>
  <!-- why is this not updating? <div class="thumbnail-poster__image" data-[style.background-image]="'url(' + item.poster + '), url(' + fallbackImg + ')'"></div> -->
  <div class="thumbnail-poster__image" [style.background-image]="posterBgImg"></div>
  <div *ngIf="showDuration" class="thumbnail-poster__duration">{{ item.duration | duration }}</div>
  <div *ngIf="showActionIcon" class="thumbnail-poster__play">
    <ion-icon *ngIf="(!isMine && isModerated && !item.isApproved && !canModerate && !canCollaborate) else canPlay" name="lock-closed" class="smaller-icon"></ion-icon>
    <ng-template #canPlay>
      <ng-container *ngIf="isStack(item) else isClipIcon">
        <ion-icon *ngIf="!isMine && canCollaborate; else mineStackIcon"
          name="add-circle-outline"
        ></ion-icon>
        <ng-template #mineStackIcon>
          <ion-icon
            [name]="item.dtePublished ? 'play-circle-outline' : 'create-outline'"
          ></ion-icon>
        </ng-template>
      </ng-container>
      
      <ng-template #isClipIcon>
        <ion-icon
          [name]="isPlaying ? 'pause-circle-outline' : 'play-circle-outline'"
        ></ion-icon>
      </ng-template>
    </ng-template>
  </div>
  <div *ngIf="showAvatar" class="thumbnail-poster__avatar">
    <!-- placeholder -->
    <div *ngIf="!avatarUrl" class="thumbnail-poster__avatar__image"></div>
    <!-- actual avatar -->
    <img
      class="thumbnail-poster__avatar__image"
      *ngIf="avatarUrl"
      [src]="avatarUrl"
      [fallback]="avatarFallback"
      imageFadeIn
    />
  </div>
  <div *ngIf="hasProvider" class="thumbnail-poster__provider">
    <ion-icon size="default" color="medium" name="logo-youtube"></ion-icon>
  </div>
  <div *ngIf="isModerated && !item.isApproved" class="thumbnail-poster__approved-indicator" [title]="'STACK.ACTIONS.REQUIRES_MODERATION' | translate">
    <ion-button size="small" fill="clear" color="primary" [disabled]="!canModerate">
      <ion-icon *ngIf="canModerate" name="lock-closed" slot="start"></ion-icon>
      {{ 'STACK.ACTIONS.' + (canModerate ? 'REVIEW_APPROVE' : 'NEEDS_REVIEW') | translate }}
    </ion-button>
  </div>
</div>
