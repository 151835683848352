<ng-container *ngIf="planItem?.plan?.id else loadingContent">
  <h2 class="ion-text-center">
    {{ (planItem?.meta?.translatePath ?? '') + 'SIGNUP_TITLE' | translate }}
  </h2>
  <ng-container *ngIf="enableBilling else billingDisabled">
    <!-- <h1>
      <ng-container *ngIf="planItem?.tiers?.length > 0 else defaultBillingTitle">
        {{ (planItem?.meta?.translatePath ?? '') + 'SIGNUP_SUBTITLE_TIERED' | translate }}
      </ng-container>
      <ng-template #defaultBillingTitle>
        {{ (planItem?.meta?.translatePath ?? '') + 'SIGNUP_SUBTITLE' | translate }}
      </ng-template>
    </h1> -->
    <div class="cost">
      <div *ngIf="getPriceMonthly" class="monthly">
        <sup>$</sup>{{getPriceMonthly}}<sup>/{{ 'COMMON.MONTH_ABBR' | translate }}</sup>
      </div>
      <div *ngIf="getPriceMonthly && getPriceYearly" class="or">{{ 'COMMON.OR' | translate }}</div>
      <div *ngIf="getPriceYearly" class="monthly">
        <sup>$</sup>{{getPriceYearly}}<sup>/{{ 'COMMON.YEAR_ABBR' | translate }}</sup>
      </div>
    </div>
    <p *ngIf="planItem?.plan?.description else defaultPlanDescription">
      {{ planItem?.plan.description }}
    </p>
    <ng-template #defaultPlanDescription>
      <p>
        {{ (planItem?.meta?.translatePath ?? '') + 'SIGNUP_BODY_1' | translate }}
      </p>
    </ng-template>

    <div class="actions ion-text-center">
      <!-- <ion-button *ngIf="(isPro$ | async) !== true" fill="outline" (click)="navSignup(proSignupRoute)"> -->
      <ion-button fill="outline" [disabled]="isProPlan && isActivePro" (click)="navSignup.emit(signupPath)">
        {{ signupTranslatePath | translate }}
      </ion-button>
      <div *ngIf="isProPlan && isActivePro" class="bold orange">
        {{ 'BILLING.ALREADY_PRO' | translate }}
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingContent>
  <div class="ion-text-center">
    <ion-spinner></ion-spinner>&nbsp;{{ 'COMMON.LOADING' | translate }}
  </div>
</ng-template>

<ng-template #billingDisabled>
  <div class="ion-text-center">
    Sorry, Billing is Disabled.
  </div>
</ng-template>
