import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
/** @note 158k pkg */
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from '@shared/shared.module';
import { DirectivesModule } from '@app/directives/directives.module';
import { MemberInviteListComponent } from './member-invite-list/member-invite-list.component';
import { MemberInviteModalComponent } from './member-invite-modal/member-invite-modal.component';
import { QrCodeComponent } from './shared/components/qr-code/qr-code.component';
import { QrManageComponent } from './shared/components/qr-manage/qr-manage.component';
import { QrModalComponent } from './shared/components/qr-modal/qr-modal.component';

/**
 * @note Routes cannot be specified here since this Module is imported by other Modules
 */
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    QRCodeModule,
    SharedModule,
    DirectivesModule
  ],
  declarations: [
    MemberInviteListComponent,
    MemberInviteModalComponent,
    QrCodeComponent,
    QrManageComponent,
    QrModalComponent
  ],
  exports: [
    MemberInviteListComponent,
    QrCodeComponent,
    QrManageComponent,
    QrModalComponent
  ]  
})
export class TokensModule { }
