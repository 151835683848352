<ion-item lines="none" class="fs-form-item" [class.fs-form-item--has-error]="!!errorMessage">
  <!-- ionic v7 forces labels on form inputs 
  <ion-label class="fs-form-item__label" position="stacked">
    <div>
      {{ label }}
    </div>
    <div class="fs-form-item__label--error" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
	</ion-label> -->
	<!-- slot for fs-form-input, fs-form-textarea -->
  <ng-content></ng-content>
</ion-item>
