/** @format */

import { AppConfig } from '@app/core/config';
import { ITEM_TYPE } from '@shared/models/layout.model';
import { ShareableItem, SharingParams } from '../social-sharing.model';

export const extractSharingParamsFromShareableItem = (
  item: ShareableItem,
  appConfig: Partial<AppConfig>
): SharingParams => {
  const sharingParams: SharingParams = {
    title: item.title,
    url: item.shareUrl,
    text: item.title,
    hashtags: appConfig.hashTags,
    itemType: item.type,
  };

  switch (item.type) {
    case ITEM_TYPE.Projects: {
      sharingParams.text = ['Check out this Filmstacker Project!', item.title].join(' ');
      if (appConfig.isWidgetActive && appConfig.shareUrl) {
        sharingParams.url = appConfig.shareUrl;
      }
      break;
    }
    case ITEM_TYPE.Stacks: {
      sharingParams.text = ['Check out this Filmstack!', item.title].join(' ');
      if (appConfig.isWidgetActive && appConfig.stackShareUrl) {
        if (appConfig.stackShareUrlIncludesProject) {
          sharingParams.url = [appConfig.stackShareUrl, appConfig.projectId, item.id].join('/');
        } else {
          sharingParams.url = [appConfig.stackShareUrl, item.id].join('');
        }
      }

      break;
    }
    case ITEM_TYPE.Clips: {
      break;
    }
  }

  return sharingParams;
};
