/** @format */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ClipsCoreService } from '@services/clips.service';
import { ProjectMemberService } from '@members/shared/services';
import { ProjectService } from '@projects/shared/services';
// import { YoutubeService } from '@services/youtube.service';
import { Clip, getClipTaxArray, getClipEmotionsString } from '@shared/models/clip.model';
import { Utils } from '@shared/utils';
import { DEFAULT_USER_AVATAR } from '@shared/models/user.model';
import { filter, map, take } from 'rxjs/operators';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';

const PAGE = '[ClipDetail]';

@Component({
  selector: 'app-clip-detail',
  templateUrl: './clip-detail.component.html',
  styleUrls: ['./clip-detail.component.scss'],
})
export class ClipDetailComponent implements OnInit {
  @Input() clip: Clip;
  @Input() currentPage: string;
  /** user is GLOBAL admin */
  @Input() isAdmin = false;
  @Input() canEdit = false;
  @Input() canRemove = false;
  @Input() playlistLength: number = 0; // to know when we're last
  @Input() index = 0;

  @Output() moveTop = new EventEmitter<MouseEvent>();
  @Output() moveBottom = new EventEmitter<MouseEvent>();
  @Output() deleteClip = new EventEmitter<MouseEvent>();
  @Output() trimClip = new EventEmitter<MouseEvent>();
  @Output() editClip = new EventEmitter<MouseEvent>();

  showSegmentBar = false;

  segment: string = 'details';
  language: string = 'en';
  // clip: Clip;
  tags: Array<string> = [];
  emotions: string = '';

  userAvatarUrl: string;
  clipOwner$: Observable<string>;
  projectTitle$: Observable<string>;
  clipDesc = '';

  isPopover: boolean = false;

  isCollapsedDetails: boolean = true;
  avatarFallback = DEFAULT_USER_AVATAR;

  constructor(
    private projectService: ProjectService,
    private memberService: ProjectMemberService,
    private clipService: ClipsCoreService,
    // private youtubeService: YoutubeService,
    private modalController: ModalController,
    private navCtrl: NavController
  ) {}

  ngOnInit() {
    if (this.clip) {
      this.emotions = this.getClipEmotions();
      this.tags = this.getClipTax();
      this.showSegmentBar = this.clip.transcript ? true : false;

      this.clipOwner$ = this.memberService.getPublicUsername(this.clip.userId);
      this.userAvatarUrl = this.memberService.getUserAvatar(this.clip.userId);

      this.clipDesc = this.clipService.getDescription(this.clip);

      this.projectTitle$ = this.projectService.getProjectPreview(this.clip.projectId).pipe(
        filter((p) => p && p.id && p.id.length > 0),
        take(1),
        map((proj) => (proj && proj.title ? proj.title : this.getProjectNameFromId(this.clip.projectId)))
      );
    } else {
      console.warn(`${PAGE} init !clip!`);
    }
  }

  onSegmentClick(event: MouseEvent) {
    Utils.tryStopPropagation(event);
  }

  close() {
    if (this.isPopover) {
      this.modalController.dismiss();
    } else {
      console.log(`${PAGE} Inline Close not handled`);
    }
  }

  playClip() {
    console.log(`${PAGE} PLAY clip:`, this.clip);
  }

  getPoster() {
    return this.clipService.getPoster(this.clip);
  }

  getProjectNameFromId(projId: string): string {
    return Utils.dashedToTitleCase(projId);
  }

  getClipEmotions() {
    // console.log(`${PAGE} getClipEmotions...`);
    return getClipEmotionsString(this.clip);
  }

  getClipTax() {
    // console.log(`${PAGE} getClipTax...`);
    return getClipTaxArray(this.clip);
  }

  openProject(event, projUrl: string) {
    event.stopPropagation(); // do not open the item collapse
    this.navCtrl.navigateForward(`/${PROJECT_DETAIL_ROUTE}/${projUrl}`);
  }
  openChannel(event, channelUrl: string) {
    event.stopPropagation(); // do not open the item collapse
    this.openPage('channel', { id: channelUrl });
  }

  openPage(name: string, params: { id?: string } = {}) {
    // push to the nav stack and get a back button
    this.navCtrl.navigateForward([`/${name}`, params]);
  }

  // getChannelFromProject(project: string) {
  //   // TODO: lookup project by projectId and get ChannelById...
  //   return 'Global';
  // }
}
