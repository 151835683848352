<fs-base-modal [title]="title" mode="light" #modal>
  <section>
    <div class="container heading ion-text-center">
      <h1 *ngIf="heading">{{ heading }}</h1>
      <div class="buttons">
        <ion-button (click)="openAddMemberDialog()">{{ btnText }}</ion-button>
        <ion-button fill="outline" (click)="navToProject()">{{ 'PROJECT.RETURN' | translate }}</ion-button>
      </div>
    </div>
  </section>

  <section *ngIf="project?.id">
    <div class="text-center">
      <h3>{{ 'PROJECT.SHARE_THIS' | translate }}</h3>
      <div class="share-buttons row ion-padding ion-justify-content-center">
        <ion-button fill="outline" color="dark" (click)="share(shareToOptions.FACEBOOK)" title="Share on Facebook">
          <ion-icon slot="icon-only" name="logo-facebook"></ion-icon>
        </ion-button>
        <ion-button fill="outline" color="dark" (click)="share(shareToOptions.TWITTER)" title="Share on Twitter">
          <ion-icon slot="icon-only" name="logo-twitter"></ion-icon>
        </ion-button>
        <ion-button fill="outline" color="dark" (click)="share(shareToOptions.LINKEDIN)" title="Share on LinkedIn">
          <ion-icon slot="icon-only" name="logo-linkedin"></ion-icon>
        </ion-button>
      </div>
      <div class="row">
        <ion-item class="share-item" lines="none">
          <ion-input #inputShareUrl readonly selectAllOnClick [value]="project?.shareUrl"></ion-input>
          <ion-button shape="round" (click)="share(shareToOptions.CLIPBOARD)" title="Copy to Clipboard">
            <ion-icon name="link" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-item>
      </div>
    </div>
  </section>
</fs-base-modal>
