import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExploreUiModule } from './explore-ui/explore-ui.module';
import { ExploreFabComponent } from './explore-fab/explore-fab.component';

@NgModule({
  imports: [
    CommonModule,
    ExploreUiModule,
  ],
  declarations: [
    ExploreFabComponent,
  ],
  exports: [
    ExploreFabComponent,
  ]
})
export class ExploreModule { }
