<div class="add-member">
  <h2>
    {{ translatePath + 'INVITE_TITLE' | translate }}
  </h2>
  <p>
    {{ translatePath + 'INVITE_BODY_1' | translate }}
  </p>
  <p *ngIf="showBillingText">
    {{ translatePath + 'INVITE_BODY_2' | translate }}
  </p>

  <p class="instructions">
    {{ 'ONBOARDING.COMMON.INVITE.INTRO_RULES' | translate }} 
    <a (click)="openCommunityRules()">{{ 'COMMON.COMMUNITY_RULES' | translate }}</a>. 
    {{ 'ONBOARDING.COMMON.INVITE.RULES' | translate }}
  </p>

  <div class="add-member__content-wrap">

    <section class="emails-wrap">
      <!-- <h1>
        {{ translatePath + 'INVITE_SUBTITLE' | translate }}
      </h1> -->
      <!-- <p class="no-top-margin">
        {{ 'ONBOARDING.COMMON.INTRO.EXPLAINED' | translate }}
      </p> -->
      <!-- [project]="project" -->
      <app-invite-form *ngIf="project else projectLoading"
        [projectId]="project?.id"
        [routeId]="routeId"
        [translatePath]="translatePath"
        (inviteSuccess)="openSuccessDialog()"
      ></app-invite-form>

      <ng-template #projectLoading>
        <p class="ion-text-center">
          {{ 'COMMON.LOADING_PROJECT' | translate }}
        </p>
      </ng-template>
    </section>

    <section class="existing-wrap">
      <h1>
        {{ 'ONBOARDING.COMMON.INVITE.EXISTING' | translate }}
      </h1>
      <div class="ion-padding-bottom">
        <!-- <p class="no-top-margin">
          {{ 'ONBOARDING.COMMON.INVITE.EXISTING_BODY' | translate }}
        </p> -->
        <!-- <h2 class="title">Invite Film Crew</h2> -->
        <section class="">
          <div *ngIf="projectsInactiveUsers$ | async as inactiveUsers">
            <div *ngIf="inactiveUsers?.length > 0" class="ion-text-center">
              <ion-button fill="clear" (click)="viewInactiveUsers(inactiveUsers)">
                <span *ngIf="!viewingInactiveUsers">{{ 'COMMON.SEE' | translate }}</span>
                <span *ngIf="viewingInactiveUsers">{{ 'COMMON.HIDE' | translate }}</span>
                &nbsp;{{ 'MEMBERS.INACTIVE_PROJECT_MEMBERS' | translate }} ({{ inactiveUsers.length }})
              </ion-button>
            </div>
          </div>
      
          <div class="search-users ">
            <app-searchbar
              color="light"
              [placeholderText]="'ONBOARDING.COMMON.INVITE.SEARCH_USERS' | translate"
              [debounceTime]="1000"
              (searchEnter)="searchChanged($event)"
              (searchChange)="searchChanged($event)"
            ></app-searchbar>
          </div>
      
          <div *ngIf="(searchResults$ | async) as searchResults">
            <div *ngIf="searchResults.length > 0" class="instructions next-step ion-text-center">
              {{ 'ONBOARDING.COMMON.INVITE.SELECT_USERS' | translate }}
            </div>
      
            <div class="user-search-results " [class.user-is-selected]="selectedUserIds?.length > 0">
              <div *ngFor="let user of searchResults" class="user-item clickable"
                [class.selected]="isUserSelected(user?.userId)"
                [title]="isUserSelected(user?.userId) ? 'Deselect User' : 'Select User'"
                [class.disabled]="user?.roleInCurrentProject" (click)="onClickUser(user)">
                <ion-avatar>
                  <img [src]="user?.avatar" [fallback]="avatarFallback" />
                </ion-avatar>
                <div class="name ion-text-center">
                  {{ user?.name || user?.userId }}
                </div>
                <div *ngIf="user?.roleInCurrentProject" class="current-role ion-text-center">
                  {{ user?.roleInCurrentProject }}
                </div>
      
                <div class="user-item__info-btn">
                  <ion-button color="medium" fill="clear" (click)="viewProjectMember($event, user)"
                    [title]="'USER.SEE_DETAILS' | translate">
                    <ion-icon slot="icon-only" name="information-circle-outline"></ion-icon>
                  </ion-button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="project-list ion-padding-horizontal ion-margin-top">
          <h3 class="ion-padding-top">
            <b>
              {{ 'ONBOARDING.COMMON.INVITE.CHOOSE_PROJECT_ROLE' | translate }}
            </b>
          </h3>
          <p>
            <b>{{ 'COMMON.CREW' | translate }}</b>:
            {{ 'MEMBERS.EXPLAIN_CREW' | translate }}
            <br>
            <b>{{ 'COMMON.PRODUCERS' | translate }}</b>:
            {{ 'MEMBERS.EXPLAIN_PRODUCERS' | translate }}
          </p>

          <!--CREW MEMBER PROJECTS-->
          <!-- [projects]="isStudio ? (myProjects$ | async) : [ project ]" -->
          <app-project-member-projects *ngIf="isStudio || project" 
              [projects]="projects$ | async"
              [addMember]="true"
              (closeModal)="dismiss.emit()"
              (roleChanged)="onProjectRoleChanged($event)"
            ></app-project-member-projects>
      
        </div>
      
        <section class="skinny-limit-width">
          <div class="ion-text-center submit-btn-wrap ion-padding-top">
      
            <div *ngIf="message" class="message ion-padding-bottom">
              {{ message }}
            </div>
      
            <ion-button color="primary" fill="solid" size="large"
              [disabled]="selectedUserIds?.length < 1 || !addProjectRole || loadingAddCrew" (click)="submitAddUser()">
              {{ 'ONBOARDING.COMMON.ADD_USER' | translate }}<span *ngIf="selectedUserIds?.length > 1">s&nbsp;</span> 
              {{ 'ONBOARDING.COMMON.TO_PROJECT' | translate }}
              <ion-spinner *ngIf="loadingAddCrew" name="crescent"></ion-spinner>
            </ion-button>
      
            <!-- <ion-button color="primary" fill="clear" size="medium" (click)="dismiss()">
              {{ 'COMMON.DONE' | translate }}
            </ion-button> -->
          </div>
        </section>
      </div>
    </section>

    <section *ngIf="!isStudio" class="qr-wrap ion-padding-vertical">

      <fs-qr-manage
        [project]="project"
        [translatePath]="translatePath"
        [isStudio]="isStudio"
      ></fs-qr-manage>

    </section>
  </div>
</div>
