<fs-form-item [label]="label" [errorMessage]="errorMessage">
  <ion-textarea
    [ngStyle]="{
      'border-color': errorMessage ? 'var(--ion-color-danger)' : 'inherit'
    }"
    [value]="control?.value ?? ''"
		[autoGrow]="autoGrow"
		[placeholder]="placeholder"
    labelPlacement="stacked"
    [label]="label"
    [helperText]="hint + (errorMessage ? ' ' + errorMessage : '')"
    [errorText]="errorMessage"
		[rows]="rows"
    [disabled]="disabled"
    [debounce]="debounce"
    (ionBlur)="isReadyForValidation = true; blur.emit(control?.value)"
    (ionChange)="isReadyForValidation = true; updateProp($any($event).detail.value)"
    (ionInput)="isReadyForValidation = true; inputEvent($any($event).detail.value)"
  ></ion-textarea>
  <!-- <span class="fs-form-textarea__hint" *ngIf="hint" [innerHTML]="hint"></span> -->
</fs-form-item>
