/** @format */

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Platform, NavController, LoadingController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { EventsService, EventActions, ResizeObject } from '@services/events.service';
import { ToasterService } from '@services/toaster.service';
import { StacksService } from '@services/stacks.service';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as stackActions from '@store/actions/stacks.actions';
import { selectMyStackCurrentClip } from '@store/selectors/mystack.selectors';
import { getSelectedStackCurrentPlayClip } from '@store/selectors/stacks.selectors';
import { Clip } from '@shared/models/clip.model';
import { Stack } from '@shared/models/stack.model';
// import { calcNumPaths } from '@app/modules/explore/shared/explore.model';

/**
 * @todo do we care about the PlayerIDs for this compoent?
 * if so, make them dynamic by importing, otherwise remove
 */
// import { STACK_PLAY_PLAYER_ID } from '@stacks/stack-play/stack-play.component';
// import { STACK_EDIT_PLAYER_ID } from '@stacks/stack-edit/stack-edit.component';
const STACK_EDIT_PLAYER_ID = 'stack-edit';
const STACK_PLAY_PLAYER_ID = 'stack-play';

const DEBUG_LOGS = false;
const PAGE = '[ExploreUI]';

export interface INewPlaylistEvent {
  pathStack?: Stack;
  pathPlaylist?: Clip[];
  pathId?: string;
  pathIndex?: number;
}

@Component({
  selector: 'app-explore-ui',
  templateUrl: './explore-ui.component.html',
  styleUrls: ['./explore-ui.component.scss'],
})
export class ExploreUiComponent implements OnInit, OnDestroy {
  @Input() drawerId: string;
  @Input() helpText: string;
  @Input() showCurrentClip = false;
  // @Input() respondToResizeNumPaths = true;
  // @Input() numPaths = 0;//3;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() scroller: any; // Scroll; // fix typing if used

  currentClip$: Observable<Clip>;
  // currentStack$: Observable<Stack>; // deprecated show currentStack in explore
  // currentPlaylistIndex$: Observable<number>;  // unused (needed if show currentStack in explore)
  // currentPlaylist$: Observable<Clip[]>; // unused (needed if show currentStack in explore)

  loading: HTMLIonLoadingElement; // Loading;

  screenWidth: number;

  private isCustomPlaylist = false; // if the explore pathay was a playlist instead of stack

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store: Store<State>,
    private toaster: ToasterService,
    private loadingCtrl: LoadingController,
    private events: EventsService,
    private stackService: StacksService,
    private navCtrl: NavController,
    private platform: Platform,
    private location: Location
  ) {}

  ngOnInit() {
    // console.log(`${PAGE} onInit`, this.drawerId);
    if (this.drawerId === STACK_EDIT_PLAYER_ID) {
      this.currentClip$ = this.store.select(selectMyStackCurrentClip);
    } else {
      this.currentClip$ = this.store.select(getSelectedStackCurrentPlayClip);
    }

    const subResize = this.events.subscribe(EventActions.RESIZE, this.onResize);
    const subScroll = this.events.subscribe('explore:scroll:top', this.scrollToTop);
    this.subscriptions.add(subResize);
    this.subscriptions.add(subScroll);

    this.platform.ready().then(() => {
      this.screenWidth = this.platform.width();
    });
  }

  scrollToTop = (drawerId) => {
    if (drawerId === this.drawerId) {
      if (this.scroller && this.scroller._scrollContent && this.scroller._scrollContent.nativeElement) {
        //credit to: https://stackoverflow.com/questions/44099796/how-can-i-use-content-scrollto-for-ion-scroll-in-ionic2
        const scroll = this.scroller._scrollContent.nativeElement;
        scroll.scrollTop = 0;
      } else {
        console.log(`${PAGE} unable to access scroller._scrollContent.nativeElement ?`);
      }
    }
  };

  onResize = (event: ResizeObject) => {
    console.log(`${PAGE} onResize:`, event);
    this.screenWidth = event.width;
  };

  onNewPlaylist(event: INewPlaylistEvent) {
    DEBUG_LOGS && console.warn(`${PAGE} onNewPlaylist:`, event);

    const { pathStack, pathPlaylist, pathId = 0 } = event;

    if (pathStack && pathStack.stackId && pathStack.projectId) {
      this.isCustomPlaylist = false;
      this.watchStack(pathStack);
    } else if (pathPlaylist && pathPlaylist.length > 0) {
      this.isCustomPlaylist = true;
      this.watchStack(
        new Stack({
          playlist: pathPlaylist,
          //clips: pathPlaylist, //TODO: are these full clips, or just IDs?
          title: pathStack && pathStack.title ? pathStack.title : `Storyline ${pathId}`,
          projectId: 'explore',
          stackId: `explore-storyline-${pathId}`,
        })
      );
    } else {
      this.toaster.present(`Uh oh! Path Playlist not formatted correctly...`);
    }
  }

  async presentLoading() {
    // https://ionicframework.com/docs/api/components/loading/LoadingController/
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles', //'dots',//'crescent',//https://ionicframework.com/docs/api/components/spinner/Spinner/
      message: 'Getting Filmstack Ready...',
      cssClass: 'loading-modal',
      backdropDismiss: false, // boolean Whether the loading indicator should be dismissed by tapping the backdrop. Default false.
    });

    return await this.loading.present();
  }

  hideLoading() {
    if (this.loading && typeof this.loading.dismiss === 'function') {
      this.loading.dismiss();
    }
  }

  watchStack(stack: Stack) {
    const doLoading = false;
    doLoading && this.presentLoading();
    if (this.isCustomPlaylist) {
      console.warn(`${PAGE} TODO: DEBUG watchStack customPlaylist -> createStackPlaylist...`);
      /**
       * TODO: verify this works when dynamically generating stacks
       * it probably won't because we need to select the stack to update the StackDetail on the Player page..
       */
      this.store.dispatch(stackActions.createStackPlaylist({ stack }));

      // lastValueFrom(this.stackService.createStackPlaylist(stack)).then(res => {
      //   console.log(`${PAGE} watchStack -> createStackPlaylist res:`,res);
      //   //TODO: this.stackService.selectStack(stack.projectId, stack.stackId);
      //   this.events.publish("explore:close", this.drawerId);
      //   this.events.publish('player:explore:close');
      //   this.openPlayer(res);
      //   doLoading && this.hideLoading();
      // }).catch(err => {
      //   console.log(`${PAGE} watchStack -> createStackPlaylist Error:`,err);
      //   doLoading && this.hideLoading();
      //   this.toaster.present("Oops! " + (err && typeof err === 'string' ? err : "There was an error, please try again."));
      // });
    } else {
      // watch stack
      // console.log(`${PAGE} watchStack::`, stack);
      if (!stack || !stack.projectId || !stack.stackId) {
        doLoading && this.hideLoading();
        this.toaster.present("Oops! We're missing some vital info to play this Stack, please try again.");
        return false;
      }
      // this.stackService.selectStack(stack.projectId, stack.stackId);
      this.store.dispatch(stackActions.selectIdPlay({ projectId: stack.projectId, stackId: stack.stackId }));
    }

    this.events.publish('explore:close', this.drawerId);
    this.events.publish('player:explore:close');
    this.openPlayer(stack);
    doLoading && this.hideLoading();
  }

  openPlayer(stack: Stack) {
    const url =
      stack && stack.projectId && stack.stackId ? `/stack/play/${stack.projectId}/${stack.stackId}` : `/stack/play`;

    if (this.drawerId === STACK_PLAY_PLAYER_ID) {
      // we're already on the player page
      this.location.replaceState(url);
    } else {
      this.navCtrl.navigateForward(url);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
