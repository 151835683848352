<ion-header>
  <app-top-navbar [title]="title">
    <!-- SLOT: anything added here will go below the ion-navbar -->
  </app-top-navbar>
</ion-header>

<ion-content class="light">
  <div class="container ion-padding ion-margin-top diet-limit-width">

    <ng-template #noProject>
      <div class="ion-text-center ion-padding-horizontal">
        <h2 class="ion-text-left ion-padding-bottom">
          {{ 'ONBOARDING.INVITE_TITLE' | translate }}
        </h2>
        <ion-spinner *ngIf="loading else doneLoading"></ion-spinner>
        <ng-template #doneLoading>
          <p>
            {{ 'PROJECT.NOT_FOUND' | translate }}
          </p>
           <ion-button [routerLink]="['/stack/studio']" fill="outline">
            {{ 'STUDIO.RETURN' | translate }}
          </ion-button>
        </ng-template>
      </div>
    </ng-template>

    <ng-container *ngIf="project$ | async as project; else noProject">
      <app-add-member *ngIf="project?.id else noProject" 
        [project]="project" (addSuccess)="onSuccess($event)"></app-add-member>
      
      <div class="ion-text-center ion-padding ion-margin">
        <ion-button [disabled]="!project?.id" [routerLink]="[routeProjectDetail, projectId]" fill="outline">
          {{ 'PROJECT.RETURN_TO_PROJECT' | translate }}
        </ion-button>
      </div>
    </ng-container>

  </div>

</ion-content>