/**
 * @license Private All code, logic and derivatives are property of Filmstacker Inc.
 * @author: jd gibbs <j2d2> jd@filmstacker.com
 * @format
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import DevLogBind, { isLocalStorageSupported } from './devlogs';
import { AppModule } from './app/app.module';

/**
 * @todo refactor to update timeout in s3 with amplify v3
 * https://github.com/aws-amplify/amplify-js/issues/7262
 * import Amplify, { AWS } from '@aws-amplify/core';
 * AWS.config.s3 = { httpOptions: { timeout: 1200000 } }; // set S3 upload config to 20 minutes from defualt 2 minutes
 *
 * 2023-06-27 Update to Amplify 5.x.x
 * https://github.com/aws-amplify/amplify-js#notice
 */
// import { Amplify } from 'aws-amplify'; // exists but unused
import { API, Auth, Storage, Analytics } from 'aws-amplify';

import { Utils } from '@shared/utils';

// use the js instead of renaming to ts everytime it's generated
// import awsconfig from './aws-exports';
declare const require: NodeRequire;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const awsconfig = require('./aws-exports').default;

/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Amplify version update & manual changes within AWS Console caused issues with amplify config
 * Need to manually config the Amplify Object, based on
 * https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
 * https://docs.amplify.aws/lib/storage/getting-started/q/platform/js/#manual-setup-import-storage-bucket
 */
// manually configure AppSync and other services, avoiding aws-exports...
const authConfig = {
  Auth: {
    identityPoolId: environment.aws.identityPoolId,
    region: environment.aws.authRegion,
    userPoolId: environment.aws.userPoolId,
    userPoolWebClientId: environment.aws.userPoolWebClientId,
  },
};
const apiConfig = {
  API: {
    aws_appsync_graphqlEndpoint: environment.aws.appSyncGraphQLEndpoint,
    aws_appsync_region: environment.aws.appSyncRegion,
    aws_appsync_authenticationType: 'AWS_IAM',
    // endpoints: [
    //   {
    //     name: 'MyFunction',
    //     endpoint: 'https://lambda.eu-west-1.amazonaws.com/2015-03-31/functions/MyFunction/invocations',
    //     service: 'lambda',
    //     region: 'eu-west-1',
    //   },
    // ],
  },
};
const storageConfig = {
  Storage: {
    AWSS3: {
      bucket: environment.aws.s3Bucket,
      region: environment.aws.s3Region,
    },
  },
};
const analyticsConfig = {
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: false,
    // OPTIONAL - Allow recording session events. Default is true.
    autoSessionRecord: true,

    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: environment.aws.pinpointAppId,
      // Amazon service region - has to match the Auth Region
      region: environment.aws.authRegion,
      mandatorySignIn: false,

      // Buffer settings used for reporting analytics events.
      // OPTIONAL - The buffer size for events in number of items.
      bufferSize: 1000,
      // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
      flushInterval: 5000, // 5s
      // OPTIONAL - The number of events to be deleted from the buffer when flushed.
      flushSize: 100,
      // OPTIONAL - The limit for failed recording retries.
      resendLimit: 5,
    },
  },
};

/* eslint-enable @typescript-eslint/naming-convention */
if (environment.production) {
  enableProdMode();
  // any changes to awsconfig for production?
}

/**
 * handle widget in iframe in incognito mode
 */
let okToLoad = true;
try {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const localStorageExists = window.localStorage || ((window as any).globalStorage ? (window as any).globalStorage[location.hostname] : null);
  const sessionStorageExists = isLocalStorageSupported();
  /* eslint-enable @typescript-eslint/no-unused-vars */
} catch (error) {
  // just kill the loading, we can't successfully load the app at this point..
  okToLoad = false;
}

if (window) {
  console.log(
    `%c Welcome to Filmstacker! %c(v${environment.version})`,
    'color:#f37941;font-size:120%;',
    'font-size:80%;'
  );
  console.log(`%c Contact us with any feedback, questions or comments: info@filmstacker.com`, 'color:#66ad9e;');
  console.log(
    `%c We respect your privacy! Read our privacy policy for details: https://filmstacker.com/privacy`,
    'color:#66ad9e;'
  );

  console.log(Utils.isServiceWorkersSupported() ? 'ServiceWorkers supported' : 'ServiceWorkers NOT supported');
}

if (okToLoad) {
  // // Amplify.configure(Object.assign(awsconfig, appsyncConfig));
  // Amplify.configure(amplifyConfig);
  Auth.configure(authConfig);
  API.configure(apiConfig);
  Storage.configure(storageConfig);

  // we need to configure Pinpoint for DEV, but could be moved into the below if
  Analytics.configure(analyticsConfig);
  if (environment.isAnalyticsEnabled) {
    enablePinpointAnalytics();
    enableGoogleAnalytics();
  }

  if (window && environment.production && !DevLogBind('logsEnabled', {})) {
    DevLogBind('disable', true); //this is on App Start, skip the localstorage step
  }

  // do not bootstrap if we are not ok to load
  // (avoid DOMException: Failed to read the 'localStorage' property from 'Window': Access is denied for this document)
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));

  if (window) {
    window['devlogs'] = DevLogBind;
  }
}

function enableGoogleAnalytics(): void {
  // add Google Analytics script to <head>
  const script = document.createElement('script');

  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.GA_MEASUREMENT_ID}');`;

  document.head.appendChild(script);
}

function enablePinpointAnalytics(): void {
  Analytics.autoTrack('session', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the attributes of the event, you can either pass an object or a function
    // which allows you to define dynamic attributes
    // attributes: {
    //   attr: "attr",
    // },
    // when using function
    attributes: () => {
      const url = new URL(window.location.href);
      return {
        origin: url.origin,
        host: url.host,
        pathname: url.pathname,
        referrer: document.referrer,
      };
    },
    // OPTIONAL, the service provider, by default is the Amazon Pinpoint
    provider: 'AWSPinpoint',
  });
  Analytics.autoTrack('pageView', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the event name, by default is 'pageView'
    eventName: 'pageView',
    // OPTIONAL, the attributes of the event, you can either pass an object or a function
    // which allows you to define dynamic attributes
    // attributes: {
    //   attr: "attr",
    // },
    // when using function
    attributes: () => {
      const url = new URL(window.location.href);
      return {
        origin: url.origin,
        host: url.host,
        pathname: url.pathname,
      };
    },
    // OPTIONAL, by default is 'multiPageApp'
    // you need to change it to 'SPA' if your app is a single-page app like React
    type: 'SPA',
    // OPTIONAL, the service provider, by default is the Amazon Pinpoint
    provider: 'AWSPinpoint',
    // OPTIONAL, to get the current page url
    // eslint-disable-next-line arrow-body-style
    getUrl: () => {
      return window.location.href;
    },
  });
}
