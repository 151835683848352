<fs-base-modal [title]="title" mode="light" #modal>
  <swiper-container #swiper [allowTouchMove]="false" [allowSlideNext]="allowSlideNext" [allowSlidePrev]="allowSlidePrev" [modules]="swiperModules" (init)="initSlider($event)">
    <swiper-slide>
      <div>
        <p class="ion-padding-horizontal">{{ 'REPORT.HEADING' | translate }}:</p>

        <!-- we need a separate list for the tooltips because doing so within ion-items will cut them off -->
        <div class="tooltip-list">
          <div
            class="tooltip-list__item"
            *ngFor="let category of categories; let i = index"
          >
            <div class="tooltip-list__item__icon">
              <ion-icon
                *ngIf="category.tooltip"
                [popperShowOnStart]="i === 1"
                [popper]="category.tooltip"
                [popperTrigger]="PopperTriggers.hover"
                [popperPlacement]="PopperPlacements.LEFT"
                [popperStyles]="{ width: '250px' }"
                class="small-icon"
                name="information-circle-outline"
              ></ion-icon>
            </div>
          </div>
        </div>

        <ion-list lines="none">
          <ion-radio-group (ionChange)="onSelectCategory($event)" class="spaced">
            <ion-item *ngFor="let category of categories; let i = index">
              <ion-radio
                mode="md"
                slot="start"
                labelPlacement="end"
                aria-label="{{ category.text }}"
                [value]="category.id"
              >{{ category.text }}</ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <div>
          <p>{{ 'REPORT.CONTENT_1' | translate }}</p>
          <p>{{ 'REPORT.CONTENT_2' | translate }}</p>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide *ngIf="(selectedCategory?.items || []).length > 0">
      <div>
        <p *ngIf="selectedCategory" class="ion-padding-horizontal">
          <strong>{{ 'REPORT.CATEGORY' | translate }}:</strong> {{ selectedCategory.text }}
        </p>
        <br />
        <p class="ion-padding-horizontal">{{ 'REPORT.REASONS' | translate }}:</p>
        <ion-list lines="none">
          <ion-radio-group (ionChange)="onSelectReason($event)">
            <ion-item *ngFor="let reason of selectedCategory?.items || []">
              <ion-radio mode="md" slot="start" labelPlacement="end" [value]="reason.id" aria-label="{{ reason.text }}">{{ 
                reason.text 
              }}</ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div>
        <p *ngIf="selectedCategory">
          <strong>{{ 'REPORT.CATEGORY' | translate }}:</strong> {{ selectedCategory.text }}
        </p>
        <p *ngIf="selectedReason">
          <strong>{{ 'REPORT.REASON' | translate }}:</strong> {{ selectedReason.text }}
        </p>
        <br />
        <p>{{ 'REPORT.ADDITIONAL' | translate }}:</p>
        <ion-textarea
          [(ngModel)]="additionalDetails"
          rows="4"
          [placeholder]="'REPORT.ADDITIONAL_PLACEHOLDER' | translate"
        ></ion-textarea>
      </div>
    </swiper-slide>
    <swiper-slide class="centered">
      <div>
        <ion-icon
          class="big-icon"
          color="primary"
          name="checkmark-circle-outline"
        ></ion-icon>
        <div>{{ 'REPORT.THANKS' | translate }}</div>
      </div>
    </swiper-slide>
  </swiper-container>

  <ion-toolbar class="footer ion-padding" *ngIf="currentStep.id !== 'sent'">
    <ion-buttons slot="end">
      <fs-button
        *ngIf="currentStep.id !== STEPS.selection.id"
        style="margin-right: 10px"
        buttonStyle="outline"
        (click)="back()"
        >{{ 'COMMON.PREVIOUS' | translate }}</fs-button
      >
      <fs-button
        *ngIf="currentStep.id === STEPS.selection.id"
        [disabled]="!selectedCategory"
        buttonStyle="primary"
        (click)="next()"
        >{{ 'COMMON.NEXT' | translate }}</fs-button
      >
      <fs-button
        *ngIf="currentStep.id === STEPS.subSelection.id"
        [disabled]="!selectedReason"
        buttonStyle="primary"
        (click)="next()"
        >{{ 'COMMON.NEXT' | translate }}</fs-button
      >
      <fs-button
        *ngIf="currentStep.id === STEPS.additionalText.id"
        buttonStyle="primary"
        (click)="sendReport()"
        >{{ 'REPORT.SEND' | translate }}</fs-button
      >
    </ion-buttons>
  </ion-toolbar>
</fs-base-modal>
