import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { PlyrModule } from '@app-plyr';
import { VideoPlayerComponent } from './video-player.component';
import { VideoPlayerPlyrComponent } from './video-player-plyr/video-player-plyr.component';
import { VideoPlayerRoutingModule } from './video-player-routing.module';
import { OverlayCompleteComponent } from './shared/components/overlay-complete/overlay-complete.component';

@NgModule({
  imports: [
    VideoPlayerRoutingModule,
    CommonModule,
    TranslateModule,
    IonicModule,
    DirectivesModule,
    PlyrModule,
  ],
  declarations: [
    VideoPlayerComponent,
    VideoPlayerPlyrComponent,
    OverlayCompleteComponent,
  ],
  exports: [
    VideoPlayerComponent,
  ]
})
export class VideoPlayerModule { }
