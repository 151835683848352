/** @format */

import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { SocialSharingNativeService } from './social-sharing-native.service';
import { SocialSharingWebService } from './social-sharing-web.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ConfigService } from '@app/core/config';
import { SHARING_METHOD, ShareResponse, ShareableItem } from './social-sharing.model';

@Injectable({
  providedIn: 'root',
})
export abstract class SocialSharingService {
  abstract share(item: ShareableItem, event?: MouseEvent): Promise<ShareResponse>;
  abstract shareTo?(item: ShareableItem, action: SHARING_METHOD, isReviewedItem?: boolean): Promise<ShareResponse>; // directly share to a service
}

const socialSharingFactory = (
  platform: Platform,
  socialSharing: SocialSharing,
  modalCtrl: ModalController,
  popoverCtrl: PopoverController,
  configService: ConfigService
) => {
  if (platform.is('cordova')) {
    return new SocialSharingNativeService(socialSharing, configService);
  } else {
    return new SocialSharingWebService(modalCtrl, popoverCtrl, configService, platform);
  }
};

export const socialSharingServiceProvider = {
  provide: SocialSharingService,
  useFactory: socialSharingFactory,
  deps: [Platform, SocialSharing, ModalController, PopoverController, ConfigService],
};
