/** @format */
import { Action, createReducer, on } from '@ngrx/store';
import * as Reset from '../actions/reset.actions';
import * as Mystack from '../actions/mystack.actions';
import * as ClipActions from '../actions/clips.actions';
import * as StackActions from '../actions/stacks.actions';
import {
  removeClipsFromPlaylist,
  Stack,
  STACK_DEFAULT_CREDITS,
  STACK_PRIVACY,
  getClipIdsFromPlaylist,
  addClipsToPlaylist,
  getCollabPrivacy,
} from '@app/shared/models/stack.model';
import { getId as getClipId, splitId as splitClipId } from './clips.reducers';
import { environment } from 'src/environments/environment';
import _isEqual from 'lodash/isEqual';

const DEBUG_LOGS = false;
const PAGE = '[MyStackStore]';

export const featureKey = 'mystack';

export interface State extends Stack {
  loaded: boolean;
  loading: boolean;
  clipIds: string[];
  currentIndex: number;
  selectedId: string;
  /** @deprecated not needed - using clip store entities */
  // entities: { [id: string]: Clip }; // the [ids] can sequence repeated, so only add to entities if not there already, or overwrite
}

export const initialState: State = {
  loaded: false,
  loading: false,
  currentIndex: 0,
  selectedId: '',
  clipIds: [],
  // entities: {},

  projectId: '',
  stackId: '',

  // public display name that published this
  credits: STACK_DEFAULT_CREDITS,
  // userId that published this - INDEX
  userId: '',
  userIdentityId: '', // for avatar
  avatar: '', // if just using url (better for social?)

  // if submitted to a project event
  eventId: '',
  title: '',
  poster: '',

  playlist: [],

  description: '',

  // length of all clips "00:00:00"
  duration: '00:00',

  private: false, //"public",

  dteSaved: '',
  dtePublished: '',

  recommended: 0,
  featured: 0,
  suggested: 0,

  topics: [],
  emotions: [],
  tags: [],

  votes: 0,
  views: 0,
  shares: 0,
  restacks: 0,
  likes: 0,

  projectUrl: '',
  shareUrl: '',

  // clips: [] // all selectors use clips store for the actual clips
};

const mystackReducer = createReducer(
  initialState,

  on(Reset.resetStoreThenReboot, Mystack.reset, () => ({
    ...initialState,
  })),
  on(Reset.resetStoreOnLogout, (state) =>
    Object.assign({}, state, {
      credits: STACK_DEFAULT_CREDITS,
      userId: '',
      userIdentityId: '',
      avatar: '',
    })
  ),
  // TODO: instead use Effect to => LOAD_SUCCESS
  on(Mystack.hydrated, (state, action) => {
    DEBUG_LOGS && console.log(`${action.type} TODO: Effect verifying enitities exist in clips store`);
    return Object.assign({}, state, {
      loaded: true,
      loading: false,
    });
  }),

  on(Mystack.load, (state) => {
    if (state.loaded) {
      DEBUG_LOGS && console.log(`${PAGE} already loaded - no change`);
      return state;
    }
    return Object.assign({}, state, {
      loading: true,
    });
  }),

  on(Mystack.newMyStack, (state, action) => {
    DEBUG_LOGS && console.log(`${PAGE} newMyStack`, action);
    const { stack } = action;
    // here we use initialState to reset
    // but keep the current clips if there was not previously a stackId set..
    // fixed Sentry-XT - TypeError: Cannot read properties of undefined (reading 'clipIds')
    if (!state.stackId && state?.clipIds?.length > 0) {
      DEBUG_LOGS && console.log(`Keep the current clips in playlist`, { stack, state });
      stack.clipIds = [...state.clipIds, ...(stack.clipIds || [])];
    }
    // fix: clips were not loading in the editor from collab drafts, this ensures they show up
    if (stack.playlist?.length > 0 && (!stack.clipIds || stack.clipIds.length < 1)) {
      stack.clipIds = getClipIdsFromPlaylist(stack.playlist);
    }
    return Object.assign({}, initialState, {
      ...stack,
      loaded: true,
      loading: false,
    });
  }),
  on(Mystack.updateMyStack, (state, action) => {
    DEBUG_LOGS && console.log(`${PAGE}`, action);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { stack, updates } = action;

    const updatedStack = Object.assign({}, state);
    // merge updates into existing
    updates.forEach((update) => {
      if (update.value || typeof update.value === 'number' || typeof update.value === 'boolean') {
        // it could be number == zero
        // dynamically create the update params for string query
        updatedStack[update.prop] = update.value;
      }
    });

    DEBUG_LOGS && console.log(`${PAGE} Mystack.UPDATE merge:`, { updates, current: state, updatedStack });

    return Object.assign({}, state, {
      ...updatedStack,
      loaded: true,
      loading: false,
    });
  }),
  on(Mystack.publishStack, Mystack.prePublishStack, Mystack.loadSuccess, (state, action) => {
    DEBUG_LOGS && console.log(`${PAGE}`, action);
    const { stack } = action;

    return Object.assign({}, state, {
      ...stack,
      loaded: true,
      loading: false,
    });
  }),
  on(Mystack.loadFail, (state, action) => {
    const { error } = action;
    console.warn(`${PAGE} load fail:`, error);
    return initialState;
  }),
  /**
   * Add Clips By IDs and let the effect manage the actual clip
   */
  on(Mystack.addClipIds, (state, action) => {
    const { ids } = action;
    if (!Array.isArray(ids)) {
      console.warn(`${Mystack.addClipIds.type} clips !array`);
      return state;
    }
    // append all ids, if they are new
    const newIds = ids
      .filter((clip) => clip && clip.projectId && clip.id)
      .map((clip) => getClipId(clip.projectId, clip.id))
      .filter((clipId) => state.clipIds.indexOf(clipId) < 0);

    let nextIndex = state.playlist.length;
    const newPlaylist = newIds.map((newId) => {
      const { projectId, id } = splitClipId(newId);
      return {
        projectId,
        id,
        order: nextIndex++,
      };
    });

    return Object.assign({}, state, {
      clipIds: [...state.clipIds, ...newIds],
      playlist: [...state.playlist, ...newPlaylist],
    });
  }),
  on(Mystack.addClip, (state, action) => {
    const { clip } = action;
    if (clip && clip.projectId && clip.id) {
      const id = getClipId(clip.projectId, clip.id);
      if (state.clipIds.indexOf(id) >= 0) {
        // already exists
        return state;
      }
      /** @todo update duration, noting that it might be 00:00 for a newly uploaded clip.. */
      // const durations = state.clips.filter((c) => c?.duration).map((c) => c.duration);
      // durations.push(clip.duration);

      return Object.assign({}, state, {
        clipIds: [...state.clipIds, id],
        playlist: [
          ...state.playlist,
          {
            projectId: clip.projectId,
            id: clip.id,
            order: state.playlist.length,
          },
        ],
        // duration: Utils.getTotalDuration(durations),

        // entities: Object.assign({}, state.entities, {
        //   [id]: clip,
        // }),
      });
    }
    console.warn(`${PAGE} ${action.type} no clip?`, clip);
    return state;
  }),
  on(Mystack.addClipSuccess, Mystack.removeClipFail, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((action as any).error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.warn('**** removeClipFail error:', (action as any).error);
    }
    const { clip } = action;
    if (clip && clip.projectId && clip.id) {
      const id = getClipId(clip.projectId, clip.id);

      return Object.assign({}, state, {
        clipIds: [...state.clipIds, id],
      });
    }
    console.warn(`${PAGE} ${action.type} no clip?`, clip);
    return state;
  }),
  on(ClipActions.deleteClip, Mystack.removeClipSuccess, Mystack.addClipFail, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((action as any).error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.warn('**** addClipFail error:', (action as any).error);
    }
    const { clip } = action;
    if (clip && clip.projectId && clip.id) {
      const id = getClipId(clip.projectId, clip.id);

      return Object.assign({}, state, {
        clipIds: state.clipIds.filter((clipId) => clipId !== id),
      });
    }
    console.warn(`${PAGE} ${action.type} no clip?`, clip);
    return state;
  }),
  on(Mystack.clearAllClips, (state) => {
    console.log('Clearing your stack - assuming you have confirmed this action...');
    return Object.assign({}, state, {
      clipIds: [],
      // entities: {},
      currentIndex: 0,
      selectedId: '',
    });
  }),
  on(Mystack.reorderClipIds, (state, action) => {
    DEBUG_LOGS && console.log(`${action.type} maintain order and currentIndex`, action); //string[]
    // keep the currentClip at the Index
    const currentClipId = state.clipIds[state.currentIndex];
    let { clipIds } = action;
    if (!Array.isArray(clipIds)) {
      clipIds = state.clipIds || [];
    }

    let newCurrentIndex = clipIds.indexOf(currentClipId);
    if (newCurrentIndex < 0) {
      newCurrentIndex = 0;
    }

    const playlist = clipIds.map((newId, i) => {
      const { projectId, id } = splitClipId(newId);
      return {
        projectId,
        id,
        order: i,
      };
    });

    return Object.assign({}, state, {
      clipIds,
      currentIndex: newCurrentIndex,
      playlist,
    });
  }),
  on(Mystack.removeClipByIndex, (state, action) => {
    const { index } = action;
    DEBUG_LOGS && console.log(`${action.type}`, index);

    if (typeof index !== 'number' || index < 0 || index >= state.clipIds.length) {
      console.warn(`${action.type} INVALID index`, index);
      return state;
    }
    // was it the current index?
    let newCurrentIndex = state.currentIndex;
    if (index === state.currentIndex) {
      DEBUG_LOGS && console.log(`${action.type} removing currentIndex`, index);
      newCurrentIndex = index > 0 ? index - 1 : 0;
    }

    // Use .slice(0) to clone an array.
    const tempIds = state.clipIds.slice(0);
    tempIds.splice(index, 1); //mutates tempIds, returns what was removed

    const playlist = tempIds.map((newId, i) => {
      const { projectId, id } = splitClipId(newId);
      return {
        projectId,
        id,
        order: i,
      };
    });

    return Object.assign({}, state, {
      clipIds: tempIds,
      currentIndex: newCurrentIndex,
      playlist,
    });
  }),
  on(Mystack.removeClip, (state, action) => {
    const { clip } = action;
    DEBUG_LOGS && console.log(`${action.type}`, clip);

    // remove all occurances of this item from state
    const clipIds = state.clipIds.filter((id) => id !== getClipId(clip.projectId, clip.id));

    // was it the current index?
    const currentIndex = state.currentIndex >= clipIds.length ? clipIds.length - 1 : state.currentIndex;

    const playlist = clipIds.map((newId, i) => {
      const { projectId, id } = splitClipId(newId);
      return {
        projectId,
        id,
        order: i,
      };
    });

    return Object.assign({}, state, {
      clipIds,
      currentIndex,
      playlist,
    });
  }),
  on(Mystack.currentIndex, (state, action) => {
    const currentIndex = typeof action.index === 'number' ? action.index : 0;
    DEBUG_LOGS && console.log(`${action.type}`, action);
    return Object.assign({}, state, {
      currentIndex,
    });
  }),
  on(Mystack.nextClip, (state, action) => {
    DEBUG_LOGS && console.log(`${action.type} nextClip`, action);
    return Object.assign({}, state, {
      currentIndex: state.currentIndex < state.clipIds.length - 1 ? state.currentIndex + 1 : state.clipIds.length - 1,
    });
  }),
  on(Mystack.prevClip, (state) =>
    Object.assign({}, state, {
      currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : 0,
    })
  ),

  // props

  on(Mystack.prevClip, (state) =>
    Object.assign({}, state, {
      currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : 0,
    })
  ),
  on(Mystack.updateProjectUrl, (state, action) =>
    Object.assign({}, state, {
      projectUrl: action.projectUrl,
    })
  ),
  on(Mystack.updatePrivacy, (state, action) =>
    Object.assign({}, state, {
      private: action.privacy === STACK_PRIVACY.PRIVATE,
      privacy: action.privacy,
    })
  ),
  on(Mystack.updateTitle, (state, action) =>
    Object.assign({}, state, {
      title: action.title,
    })
  ),
  on(Mystack.updatePoster, (state, action) =>
    Object.assign({}, state, {
      poster: action.poster,
    })
  ),
  on(Mystack.updateCredits, (state, action) =>
    Object.assign({}, state, {
      credits: action.credits,
    })
  ),
  on(Mystack.updateDescription, (state, action) =>
    Object.assign({}, state, {
      description: action.description,
    })
  ),
  on(Mystack.updateDuration, (state, action) =>
    Object.assign({}, state, {
      duration: action.duration,
    })
  ),
  on(Mystack.updateShareUrl, (state, action) =>
    Object.assign({}, state, {
      shareUrl: action.shareUrl,
    })
  ),
  on(Mystack.updatePublishedDate, (state, action) =>
    Object.assign({}, state, {
      dtePublished: action.dtePublished,
    })
  ),

  /**
   * when a stack is deleted, if it's my stack that was just deleted let's reset state (MVP-1064)
   */
  on(StackActions.deleteStack, (state, { stack }) => {
    if (
      stack &&
      stack.projectId &&
      stack.stackId &&
      stack.projectId === state.projectId &&
      stack.stackId === state.stackId
    ) {
      // we just deleted this draft or published stack
      return {
        ...initialState,
      };
    }
    return state;
  }),
  on(StackActions.loadFail, (state, { error, stackId, projectId }) => {
    if (projectId && stackId && projectId === state.projectId && stackId === state.stackId) {
      // we just had an error on this edit stack
      console.warn(`[MyStackStore] load fail:`, error);
      return Object.assign({}, state, {
        error,
      });
    }
    return state;
  }),

  on(StackActions.setCollaborative, (state, { projectId, stackId, isCollaborative, description }) => {
    if (projectId === state.projectId && stackId === state.stackId) {
      return Object.assign({}, state, {
        isCollaborative,
        privacy: getCollabPrivacy(isCollaborative),
        ...(description ? { description } : {}),
      });
    }
    return state;
  }),

  on(StackActions.subUpdate, (state, { stack }) => {
    if (stack && stack.stackId && stack.stackId === state.stackId && stack.projectId === state.projectId) {
      // this stack is the current mystack...
      const entity = {};
      for (const prop in stack) {
        if (
          stack.hasOwnProperty(prop) &&
          (stack[prop] || typeof stack[prop] === 'boolean' || typeof stack[prop] === 'number')
        ) {
          const val = stack[prop];
          if (!_isEqual(state[prop], val)) {
            if (prop === 'playlist') {
              // the playlist will be easier to determine if we compare the clipIds
              const clipIds = val.map((item) => getClipId(item.projectId, item.id));
              const stateIds = [...state['clipIds']];
              const isSame = _isEqual(clipIds, stateIds);

              if (clipIds.length > 0 && !isSame) {
                // not just isSame, but we need to keep any of our clips (don't allow others to remove ours)
                const myUniq = stateIds.filter((x) => !clipIds.includes(x));
                if (myUniq.length > 0) {
                  // append these to the playlist
                  const newIds = myUniq.map((item) => splitClipId(item));
                  entity[prop] = addClipsToPlaylist(val, newIds);
                  entity['clipIds'] = entity[prop].map((item) => getClipId(item.projectId, item.id));
                } else {
                  // clipIds is not blank & not the same, update the playlist and clipIds
                  entity[prop] = val;
                  entity['clipIds'] = clipIds;
                }
                !environment.production &&
                  console.log(`[subUpdate mystack] changed [clipIds & ${prop}]`, {
                    [prop]: entity[prop],
                    clipIds: entity['clipIds'],
                    val,
                    mystack: { clipIds: state['clipIds'], playlist: state['playlist'] },
                  });
              } else {
                !environment.production && console.log(`[subUpdate mystack] IGNORING [${prop}]`, val);
              }
            } else {
              entity[prop] = val;
              !environment.production && console.log(`[subUpdate mystack] changed [${prop}]`, val);
            }
            // ignore if (prop === 'duration' && val === '00:00')
          }
        }
      }
      // console.log(`[MystackStore] stack.subUpdate:`, { entity });
      return Object.assign({}, state, {
        ...entity,
      });
    } else {
      // ignoring stack not mystack
      return state;
    }
  }),

  on(StackActions.selectIdEdit, (state, action) => {
    if (!state.projectId && !state.stackId) {
      return Object.assign({}, state, {
        projectId: action.projectId,
        stackId: action.stackId,
      });
    }
    return state;
  }),

  /** handle the clip load fail */
  on(ClipActions.loadFail, (state, { error, id: clipId }) => {
    if (state.clipIds.indexOf(clipId) < 0) {
      // not a clipId in our stack
      return state;
    }
    console.log(`${PAGE} load clip fail: '${clipId}' - removing... Error:`, error);
    return Object.assign({}, state, {
      clipIds: state.clipIds.filter((d) => d !== clipId),
      playlist: [...removeClipsFromPlaylist(state.playlist, [splitClipId(clipId)])],
    });
  }),
  /** handle the clip load fail */
  on(ClipActions.loadFailBatch, (state, { error, ids }) => {
    const clipIds = state.clipIds.filter((d) => !ids.includes(d));
    if (state.clipIds.length === clipIds.length) {
      // not clipIds in our stack
      return state;
    }
    console.log(`${PAGE} load clips fail ids='${ids.join(', ')}' - removing... Error:`, error);
    return Object.assign({}, state, {
      clipIds: state.clipIds.filter((d) => !ids.includes(d)),
      playlist: [
        ...removeClipsFromPlaylist(
          state.playlist,
          clipIds.map((id) => splitClipId(id))
        ),
      ],
    });
  })

  /**
   * @deprecated - do we need to keep entities?
   * Clip was updated, update mystack version of the entity
   */
  // on(ClipActions.updateClip, (state, action) => {
  //   const { clip } = action;
  //   if (clip && clip.projectId && clip.id) {
  //     const id = getClipId(clip.projectId, clip.id);

  //     return Object.assign({}, state, {
  //       entities: Object.assign({}, state.entities, {
  //         [id]: clip,
  //       }),
  //     });
  //   }
  //   console.warn(`${PAGE} ${action.type} no clip?`, clip);
  //   return state;
  // }),
  /**
   * @deprecated - do we need to keep entities?
   * Clip was updated, update mystack version of the entity
   */
  // on(ClipActions.updateClipTranscoding, (state, action) => {
  //   const { id: actionId, projectId, updates = {} } = action;
  //   if (actionId && projectId) {
  //     const id = getClipId(projectId, actionId);
  //     const clip = Object.assign({}, state.entities[id]);
  //     for (const prop in updates) {
  //       if (updates.hasOwnProperty(prop) && (updates[prop] || typeof updates[prop] === 'boolean' || typeof updates[prop] === 'number')) {
  //         clip[prop] = updates[prop]
  //       }
  //     }
  //     return Object.assign({}, state, {
  //       entities: Object.assign({}, state.entities, {
  //         [id]: clip,
  //       }),
  //     });
  //   }
  //   console.warn(`${PAGE} ${action.type} no clip ids? '${projectId}/${actionId}'`);
  //   return state;
  // }),
  /**
   * @deprecated unused
   */
  // on(Mystack.loadClipsSuccess, (state, action) => {
  //   const { clips } = action;

  //   if (!Array.isArray(clips)) {
  //     console.warn(`${Mystack.loadClipsSuccess.type} clips !array`);
  //     return state;
  //   }

  //   // const newClipIds = clips.map(clip => getClipId(clip.projectId, clip.id));
  //   const newIds = [];
  //   const newEntities = {};
  //   clips.forEach((clip) => {
  //     if (clip && clip.projectId && clip.id) {
  //       const id = getClipId(clip.projectId, clip.id);
  //       // add id if not exists
  //       if (state.clipIds.indexOf(id) < 0) {
  //         newIds.push(id);
  //       }
  //       newEntities[id] = clip;
  //     } else {
  //       console.warn(`${PAGE} clip missing ${clip.projectId} or ${clip.id}`);
  //     }
  //   });
  //   console.log(`${PAGE} ${action} order?`, { newIds, newEntities });

  //   return Object.assign({}, state, {
  //     loaded: true,
  //     loading: false,
  //     clipIds: [...state.clipIds, ...newIds],
  //     entities: Object.assign({}, state.entities, newEntities),
  //   });
  // }),
  //end deprecated
);

export function reducer(state: State | undefined, action: Action) {
  return mystackReducer(state, action);
}

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getIds = (state: State) => state.clipIds;
export const getCurrentIndex = (state: State) => state.currentIndex;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getStackId = (state: State) => state.stackId;
export const getProjectId = (state: State) => state.projectId;

/** @deprecated using clip state entities */
// export const getEntities = (state: State) => state.entities;
// export const getCurrentClip = createSelector(
//   getEntities,
//   getCurrentIndex,
//   (entities, selectedId) => entities[selectedId],
// );
// export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map((id) => entities[id]));
// end deprecated
