/** @format */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OnboardingTypes, PlanItem, getPlanFromOnboardingType } from '@onboarding/shared/onboarding.model';
import { BaseModalComponent } from '@shared/components/ui/modal/base-modal.component';
import { Store } from '@ngrx/store';
import {
  selectMinutes,
  selectSubscriptionStatus,
  SubscriptionViewModel,
  selectIsPro,
  selectPlans,
} from '@billing/store';
import { Observable, Subject, combineLatest, takeUntil } from 'rxjs';
import { ENABLE_BILLING_MINUTES } from '@app/app.config';
import { Router } from '@angular/router';
import { getUserSubscriptionState } from '@store/selectors/user.selectors';
import { ChargebeePlanId, SubscriptionLevel, SubscriptionStatus } from '@billing/shared/billing.model';
import { OnboardingService } from '@onboarding/shared/services';

@Component({
  selector: 'fs-plans-modal',
  templateUrl: './plans-modal.component.html',
  styleUrls: ['./plans-modal.component.scss'],
})
export class PlansModalComponent implements OnInit, OnDestroy {
  /** @todo HostedPage - move to environment */
  // @Input() userId = '';
  // @Input() planId: ChargebeePlanId = ChargebeePlanId.Pro;
  // @Input() priceId = '';
  // @Input() referralCode = '';
  // @Input() projectId = '';
  // @Input() projectTitle = '';
  // @Input() eventDate = '';
  // @Input() successRedirectPath = getSuccessPath('' as any);

  subscriptionVm$: Observable<SubscriptionViewModel> = this.store.select(selectSubscriptionStatus);
  minutes$: Observable<number> = this.store.select(selectMinutes);
  isPro$: Observable<boolean> = this.store.select(selectIsPro);

  minutesEnabled = ENABLE_BILLING_MINUTES;
  // enableBilling = ENABLE_BILLING;

  @ViewChild('modal', { static: false, read: BaseModalComponent })
  modal: BaseModalComponent;

  isActivePro = false;

  get proPlan(): PlanItem | undefined {
    return this.vm[OnboardingTypes.Pro];
  }
  get teamsPlan(): PlanItem | undefined {
    return this.vm[OnboardingTypes.Teams];
  }
  get weddingsPlan(): PlanItem | undefined {
    return this.vm[OnboardingTypes.Weddings];
  }
  private vm: { [id: string]: PlanItem | undefined } = {};

  private onDestroy$ = new Subject<void>();

  constructor(
    // private chargebeeService: ChargebeeService,
    // private billingService: BillingService,
    private onboardingService: OnboardingService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    // billingService.loadPlans happens in app.component.initializeApp

    combineLatest([
      // this.onboardingService.routeOnboardingMeta$,
      this.store.select(selectPlans),
      this.store.select(getUserSubscriptionState),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ([
          // { routeId, translatePath = '', actionUrl = '', backgroundUrl = '', backgroundPosition = '' },
          /* eslint-disable @typescript-eslint/no-unused-vars */
          { plans, addons },
          { userId, subscriptionId, subscriptionLevel, subscriptionStatus, subscriptionMinutes },
          /* eslint-enable @typescript-eslint/no-unused-vars */
        ]) => {
          // if (!routeId) return; // not the current route or metadata not available
          // this.routeId = routeId;
          // this.translatePath = translatePath;
          // this.actionUrl = actionUrl + 'signup';// this component, used in login-form onsuccess
          // this.backgroundUrl = backgroundUrl;
          // this.backgroundPosition = backgroundPosition;

          try {
            // this.metaPlanOptions = [];
            if (subscriptionStatus === SubscriptionStatus.Active) {
              // we have an active sub - do we highlight what you are?
              this.isActivePro = subscriptionLevel === SubscriptionLevel.Pro;
            }
            // plans
            if (plans?.length > 0) {
              // const { planId, plan, isProPlan, tiers } = getPlanFromOnboardingType(routeId, plans);
              // this component with set order of Pro, Weddings, Events
              for (const plan of plans) {
                switch (plan?.id) {
                  case ChargebeePlanId.Pro:
                    this.vm[OnboardingTypes.Pro] = {
                      ...getPlanFromOnboardingType(OnboardingTypes.Pro, plans),
                      meta: this.onboardingService.getMetaForRoute(OnboardingTypes.Pro),
                    };
                    break;
                  case ChargebeePlanId.Teams:
                    this.vm[OnboardingTypes.Teams] = {
                      ...getPlanFromOnboardingType(OnboardingTypes.Teams, plans),
                      meta: this.onboardingService.getMetaForRoute(OnboardingTypes.Teams),
                    };
                    break;
                  case ChargebeePlanId.Weddings:
                    this.vm[OnboardingTypes.Weddings] = {
                      ...getPlanFromOnboardingType(OnboardingTypes.Weddings, plans),
                      meta: this.onboardingService.getMetaForRoute(OnboardingTypes.Weddings),
                    };
                    break;
                  default:
                    console.warn('unknown plan id?', plan);
                }
              }
              console.log(this.vm);
            } else {
              // no plans? reset, loading?
            }
          } catch (error) {
            console.warn(error);
          }
        }
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  navSignup(route) {
    this.router.navigateByUrl(route);
    this.modal.dismiss();
  }
}
