<ion-toolbar color="dark">
  <ion-buttons *ngIf="showBackButton" slot="start">
    <ion-button (click)="onDismiss.emit()">
      <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
    </ion-button>
  </ion-buttons>
  
  <ion-segment (ionChange)="segmentChanged($event)" [value]="selectedSegment" mode="md">
    <ion-segment-button [value]="segmentNames.Trim" *ngIf="enableTrimmer">
      <ion-label>{{ 'CLIP.SETTINGS.TRIM' | translate }}<span class="ion-hide-sm-down"> {{ 'COMMON.CLIP' | translate }}</span></ion-label>
    </ion-segment-button>
    <ion-segment-button *ngIf="enableSplitter" [value]="segmentNames.Split">
      <ion-label>{{ 'CLIP.SETTINGS.SPLIT' | translate }}<span class="ion-hide-sm-down"> {{ 'COMMON.CLIP' | translate }}</span></ion-label>
    </ion-segment-button>
    <ion-segment-button [value]="segmentNames.Poster">
      <ion-label><span class="ion-hide-sm-down">{{ 'COMMON.SELECT' | translate }} </span>{{ 'COMMON.POSTER' | translate }}</ion-label>
    </ion-segment-button>
    <ion-segment-button [value]="segmentNames.Settings">
      <ion-label><span class="ion-hide-sm-down">{{ 'COMMON.CLIP' | translate }} </span>{{ 'COMMON.SETTINGS' | translate }}</ion-label>
    </ion-segment-button>
  </ion-segment>
  
</ion-toolbar>