/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as stackActions from '@store/actions/stacks.actions';
import { List, selectList, selectStackListItems } from '@store/selectors/lists.selectors';
import { StackList } from '@shared/models/list.model';
import { FilterEntityTypes } from '@store/selectors/viewstate.selectors';
import { LAYOUT_TYPE } from '@shared/models/layout.model';
import { UserService } from '@services/user.service';

const DRAFT_STACKS_LIST_ID = 'my_stack_drafts';
const DRAFT_STACKS_HEADING = 'STUDIO.DRAFT_STACKS.HEADING';
const DRAFT_STACKS_LABEL = 'STUDIO.DRAFT_STACKS.LABEL';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fs-draft-stacks',
  templateUrl: './draft-stacks.component.html',
  styleUrls: ['./draft-stacks.component.scss'],
})
export class DraftStacksCarouselComponent implements OnInit {
  stackList: StackList;
  layoutType = LAYOUT_TYPE;

  @Input() canModerate = false;
  @Input() heading: string;
  @Input() isModerated = false;
  @Input() label: string;
  @Input() listId = DRAFT_STACKS_LIST_ID;
  @Input() projectId: string;
  @Input() showAvatar = true;
  @Input() showListIfEmpty = true;

  constructor(private translate: TranslateService, private store: Store<State>, private userService: UserService) {}

  ngOnInit() {
    this.stackList = {
      id: this.listId,
      label: this.labelWithDefault,
      list$: this.store.select(selectList(this.listId)),
      items$: this.store.select(selectStackListItems(this.listId)), //.pipe(shareReplay(1))
    };

    this.userService.userId$
      .pipe(
        filter((id) => id && id.length > 0),
        take(1)
      )
      .subscribe((userId) => {
        if (!userId) {
          return;
        }
        /**
         * when COLLABORATIVE DRAFTS ready, you can remove userId on this carousel
         */
        // console.log(`[draftStacks] userId: ${userId}`);
        this.store.dispatch(
          stackActions.loadFilteredStacks({
            listId: this.listId,
            filters: {
              // : FilterEntity
              type: FilterEntityTypes.StackDrafts,
              id: this.listId,
              q: null,
              isDraft: true,
              // userId,
              ...(this.projectId ? { projectId: this.projectId, userId } : { userId }),
            },
          })
        );
      });
  }

  get labelWithDefault(): string {
    return this.label ?? this.translate.instant(DRAFT_STACKS_LABEL);
  }

  get headingWithDefault(): string {
    return this.heading ?? this.translate.instant(DRAFT_STACKS_HEADING);
  }

  loadMoreStacks(list: List) {
    this.store.dispatch(
      stackActions.loadMoreFilteredStacks({
        listId: list.id,
        filters: list.filters,
        nextToken: list.nextToken,
      })
    );
  }
}
