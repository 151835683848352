<!-- @format -->

<section [ngSwitch]="layout.type">
  <ng-container *ngSwitchCase="layoutTypes.Carousel">
    <fs-carousel
      [items]="items"
      [canLoadMore]="canLoadMore"
      [isLoadingMore]="isLoading"
      [itemType]="itemTypes.Stacks"
      [heading]="heading"
      [options]="layout.options"
      [isModerated]="isModerated"
      [canModerate]="canModerate"
      [canShare]="canShare"
      [isProjectMember]="isProjectMember"
      (itemClick)="onClickItem($event)"
      (loadMore)="loadMore.emit($event)"
      (newItemClick)="addNewStackButtonClick()"
    ></fs-carousel>
  </ng-container>
  <ng-container *ngSwitchCase="layoutTypes.Grid">
    <fs-grid
      [items]="items"
      [itemType]="itemTypes.Stacks"
      [options]="layout.options"
      [isModerated]="isModerated"
      [canModerate]="canModerate"
    ></fs-grid>
  </ng-container>
  <ng-container *ngSwitchCase="layoutTypes.List">
    <fs-list
      [items]="items"
      [itemType]="itemTypes.Stacks"
      [options]="layout.options"
      [isModerated]="isModerated"
      [canModerate]="canModerate"
    ></fs-list>
  </ng-container>
  <ng-template *ngSwitchDefault> Unknown Layout... </ng-template>
</section>
