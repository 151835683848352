/** @format */

import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';

import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { 
  selectMyStack,
  selectMyStackClips,
  selectMyStackCurrentClip,
  selectMyStackCurrentIndex,
} from '@store/selectors/mystack.selectors';
import { selectClipErrorIds } from '@store/selectors/clips.selectors';
import * as mystackActions from '@store/actions/mystack.actions';
import { getId as getClipId, splitId as splitClipId } from '@store/selectors/clips.selectors';

import { ClipsCoreService } from '@services/clips.service';
import { STACK_PRIVACY, Stack } from '@shared/models/stack.model';
import { Clip } from '@shared/models/clip.model';
import { Utils } from '@shared/utils';
import { environment } from 'src/environments/environment';
import { sortByTitle as sortClipsByTitle } from '@clips/shared/clips.model';

const DEBUG_LOGS = false;
const PAGE = '[MyStackService]';

export interface ReorderStackClips {
  from: number;
  to: number;
  clips: Clip[];
}

/**
 * serves as an intermediary between the application store and the rest of the application
 * https://onehungrymind.com/handle-multiple-angular-2-models-ngrx-computed-observables/
 */
@Injectable({
  providedIn: 'root',
})
export class MyStackService {
  /*
   * ngrx Store connections
   */
  mystack$: Observable<Stack> = this.store.select(selectMyStack);
  currentClip$: Observable<Clip> = this.store.select(selectMyStackCurrentClip);
  currentIndex$: Observable<number> = this.store.select(selectMyStackCurrentIndex);
  stackClips$: Observable<Clip[]> = this.store.select(selectMyStackClips);

  constructor(
    private store: Store<State>,
    private clipsService: ClipsCoreService
  ) {}

  // selectMyStack$: Observable<Stack> = this.store.select(fromStore.getMyStack);
  // selectClips$: Observable<{[id:string]: Clip}> = this.store.select(fromStore.getClipEntities);
  // combinedStackClips$: Observable<[Stack[], {[id:string]: Clip}]> = Observable.combineLatest(this.selectStacks$, this.selectClips$);

  loadMyStack() {
    // doing this in app.component instead
    setTimeout(() => {
      this.store.dispatch(mystackActions.load());
    }, 700);

    // should this move to app.component too?
    this.store.select(selectClipErrorIds).subscribe((ids) => {
      try {
        if (Array.isArray(ids) && ids.length > 0) {
          console.log(`mystack selectClipErrorIds`, ids);
          ids.forEach((id) => {
            this.store.dispatch(mystackActions.removeClip({ clip: new Clip(splitClipId(id)) }));
          });
        }
      } catch (error) {
        console.warn(`mystack selectClipErrorIds caught`, error);
      }
    });
  }

  /** Actions */

  resetMyStack() {
    this.store.dispatch(mystackActions.reset());
  }

  /**
   * @deprecated - use addClipsToMyStack()
   */
  addClipToMyStack(clip: Clip) {
    this.store.dispatch(mystackActions.addClip({ clip }));
    // this.events.publish(EventActions.FLASH_MYSTACK); // now done in mystack.effects
  }

  /**
   * Add clips to mystack by ids, Effect to ensure loaded
   * FLASH_MYSTACK in mystack.effects
   */
  addClipIdsToMyStack(ids: { projectId: string; id: string }[]) {
    DEBUG_LOGS && console.log(`${PAGE} addClipIdsToMyStack:`, ids);
    this.store.dispatch(mystackActions.addClipIds({ ids }));
  }
  addClipsToMyStack(clips: Clip[]) {
    // DEBUG_LOGS && console.log(`${PAGE} addClipsToMyStack:`, clips);
    return this.addClipIdsToMyStack(clips.map((clip) => ({ projectId: clip.projectId, id: clip.id })));
  }

  addAllClipToMyStack(clips: Clip[]) {
    if (Array.isArray(clips) && clips.length > 0) {
      /**
       * @todo mystackActions.addMultipleClips
       */
      clips.forEach((clip) => {
        this.store.dispatch(mystackActions.addClip({ clip }));
      });
      // this.events.publish(EventActions.FLASH_MYSTACK); // now done in mystack.effects
    } else {
      console.log(`${PAGE} no clips`);
    }
  }

  updateTitle(title: string) {
    this.store.dispatch(mystackActions.updateTitle({ title }));
  }
  updateDescription(description: string) {
    this.store.dispatch(mystackActions.updateDescription({ description }));
  }
  updatePrivacy(privacy: STACK_PRIVACY) {
    if (Object.values(STACK_PRIVACY).includes(privacy)) {
      this.store.dispatch(mystackActions.updatePrivacy({ privacy }));
    }
  }
  updatePoster(posterUrl: string) {
    this.store.dispatch(mystackActions.updatePoster({ poster: posterUrl }));
  }
  prePublishStack(stack: Stack) {
    this.store.dispatch(mystackActions.prePublishStack({ stack }));
  }
  publishStack(stack: Stack) {
    this.store.dispatch(mystackActions.publishStack({ stack }));
  }

  /**
   * Handle a clip from Capture ONLINE
   */
  addCaptureOnlineClipToMyStack(clip: Clip) {
    DEBUG_LOGS && console.log(`${PAGE} addCaptureOnlineClipToMyStack:`, clip);

    try {
      if (clip && typeof clip.filmingDate !== 'string') {
        clip.filmingDate = Utils.getDateTimeString(clip.filmingDate);
      }
    } catch (error) {
      console.warn(error);
      clip.filmingDate = Utils.getDateTimeString(clip.filmingDate);
    }

    return this.clipsService.createClip(clip).then((result) => {
      console.log(`${PAGE} createClip res:`, result);
      this.store.dispatch(mystackActions.addClip({ clip: result }));
      // this.events.publish(EventActions.FLASH_MYSTACK); // now done in mystack.effects
    });
  }

  updateCurrentIndex(index: number) {
    this.store.dispatch(mystackActions.currentIndex({ index }));
  }

  prevClip() {
    this.store.dispatch(mystackActions.prevClip());
  }
  nextClip() {
    this.store.dispatch(mystackActions.nextClip());
  }
  restartCurrentIndex() {
    this.store.dispatch(mystackActions.currentIndex({ index: 0 }));
  }

  removeClipByIndex(index: number) {
    this.store.dispatch(mystackActions.removeClipByIndex({ index }));
  }

  removeClipFromMyStack(clip: Clip) {
    this.store.dispatch(mystackActions.removeClip({ clip }));
  }

  clearAllClips() {
    this.store.dispatch(mystackActions.clearAllClips());
  }

  //  * @deprecated we don't need from/to here...we were not using them anyways...
  // reorderClipsFromTo({ from, to, clips }: ReorderStackClips) {
  //   DEBUG_LOGS && console.log(`${PAGE} reorderClips`, { from, to, clips });
  //   if (!Array.isArray(clips)) {
  //     return console.warn(`${PAGE} reorderClips clip !array!`);
  //   }
  //   const clipIds = clips.map((clip) => getClipId(clip.projectId, clip.id));
  //   this.store.dispatch(mystackActions.reorderClipIds({ clipIds }));
  // }

  /**
   * Reorder from mystack 
   */
  reorderClips(clips: Partial<Clip>[]) {
    // DEBUG_LOGS && console.log(`${PAGE} reorderClips`, { clips });
    if (!Array.isArray(clips)) {
      return console.warn(`${PAGE} reorderClips !array!`);
    }
    const clipIds = clips.map((clip) => getClipId(clip.projectId, clip.id));
    this.reorderClipIds(clipIds);
  }
  reorderClipIds(clipIds: string[]) {
    DEBUG_LOGS && console.log(`${PAGE} reorderClipIds`, { clipIds });
    if (!Array.isArray(clipIds)) {
      return console.warn(`${PAGE} reorderClipIds !array!`);
    }
    this.store.dispatch(mystackActions.reorderClipIds({ clipIds }));
  }

  /**
   * @deprecated store is updated directly in clips.service
   * Handle a clip from Capture
   */
  addCaptureClipToMyStack(clip: Clip) {
    DEBUG_LOGS && console.log(`${PAGE} addCaptureClipToMyStack:`, clip);
    /**
     * @todo remove this once the mystack-capture-item has been refactored.
     */
    clip.source.uploading = true;

    this.store.dispatch(mystackActions.addClip({ clip }));
    // this.events.publish(EventActions.FLASH_MYSTACK); // now done in mystack.effects
  }

  sortByTitle(clips: Partial<Clip>[]) {
    try {
      const sorted = sortClipsByTitle(clips);
      this.reorderClips(sorted);
      return sorted;
    } catch (error) {
      console.warn(error);
      throw new Error(`Uh oh! We ran into an issue - please try again.`);
    }
  }

  /**
   * find filesUploaded at the end of mystack, 
   * sort those, leave the ones that have been dragged already
   */
  sortUserUploadSuccessByTitle(clips: Partial<Clip>[]) {
    this.store.select(selectMyStackClips).pipe(take(1)).subscribe((mystackClips) => {
      console.log('sortUserUploadSuccessByTitle', { mystackClips, clips });
      try {
        const sorted = sortClipsByTitle(clips);
        // filter out the clips we are adding, assuming they have not been moved we might replace by last index?
        const oldStackClips = mystackClips.filter((clip) => clip?.id && !sorted.some((newClip) => newClip.id === clip.id));
        const reorderedClips = [...oldStackClips, ...sorted];
        DEBUG_LOGS && console.log(`sortUserUploadSuccessByTitle...`, { reorderedClips, oldStackClips, sorted, clips });
        this.reorderClips(reorderedClips);
        return reorderedClips;
      } catch (error) {
        console.warn(error);
        throw new Error(`Uh oh! We ran into an issue - please try again.`);
      }
    });
  }

  sortDate(clips: Partial<Clip>[]) {
    try {
      const ordered = clips.map(clip => {
        if (!clip) {
          return {
            ...clip,
            sortDate: ''
          };
        }
        if (clip.source && clip.source.lastModifiedDate) {
          return {
            ...clip,
            sortDate: clip.source.lastModifiedDate
          };
        }
        return {
          ...clip,
          sortDate: clip.filmingDate || clip.modified || clip.created
        };
      });
      // '2007-01-17T08:00:00Z' < '2008-01-17T08:00:00Z' === true
      const sorted = ordered.sort((a, b) => (a.sortDate < b.sortDate) ? -1 : ((a.sortDate > b.sortDate) ? 1 : 0));
      !environment.production && console.log(`sortDate...`, { sorted, clips });
      this.reorderClips(sorted);
      return sorted;
    } catch (error) {
      console.warn(error);
      throw new Error(`Uh oh! We ran into an issue - please try again.`);
    }
  }
}
