/** @format */

import { Component, Input } from '@angular/core';
import { Stack } from '@shared/models/stack.model';
import { ItemReorderEventDetail } from '@ionic/core';
import { ITEM_TYPE, LayoutOptions } from '@shared/models/layout.model';

export class ListOptions extends LayoutOptions {
  isDraggable = false;
  isNumerable = false;

  constructor(opts: object) {
    super(opts);
    Object.entries(opts)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, value]) => Object.getOwnPropertyNames(this).includes(key))
      .forEach(([key, value]) => (this[key] = value));
  }
}

export interface DraggableList {
  reorder(event: CustomEvent<ItemReorderEventDetail>);
  toggleFeatured(item: Stack);
}

@Component({
  selector: 'fs-list',
  templateUrl: './fs-list.component.html',
  styleUrls: ['./fs-list.component.scss'],
})
export class FsListComponent {
  @Input() items: Stack[];
  @Input() itemType: ITEM_TYPE;
  /** Showing on a Project.isModerated page */
  @Input() isModerated = false;
  @Input() canModerate = false;

  /**
   * @Input Setter Getter
   * https://stackoverflow.com/a/40764091/4982169
   */
  @Input()
  set options(obj: object) {
    this._options = new ListOptions(obj);
  }
  get options(): ListOptions {
    return this._options;
  }

  private _options: ListOptions;
}
