/** @format */

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
// modals
import { PrivacyPage } from '@pages/privacy/privacy.page';
import { TermsPage } from '@pages/terms/terms.page';
import { CommunityRulesPage } from '@pages/community-rules/community-rules.page';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-policy-modals',
  templateUrl: './terms-policy-modals.component.html',
  styleUrls: ['./terms-policy-modals.component.scss'],
})
export class TermsPolicyModalsComponent {
  @Input() agreeTo = this.translate.instant('POLICIES.AGREE_TO_TERMS_PREFIX'); //'By capturing content';

  constructor(private modalCtrl: ModalController, private translate: TranslateService) {}

  async openPrivacyPolicy() {
    const modal = await this.modalCtrl.create({
      component: PrivacyPage,
      componentProps: {
        isModal: true,
      },
      cssClass: 'fullheight-modal policy-modal',
    });
    return await modal.present();
  }

  async openTerms() {
    const modal = await this.modalCtrl.create({
      component: TermsPage,
      componentProps: {
        isModal: true,
      },
      cssClass: 'fullheight-modal policy-modal',
    });
    return await modal.present();
  }

  async openCommunity() {
    const modal = await this.modalCtrl.create({
      component: CommunityRulesPage,
      componentProps: {
        isModal: true,
      },
      cssClass: 'fullheight-modal policy-modal',
    });
    return await modal.present();
  }
}
