/**
 * Migrating to @stacks/shared/services
 * @format
 */

import { Injectable } from '@angular/core';
import { Observable, combineLatest, lastValueFrom } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Storage } from 'aws-amplify';

import { CoreLogicApiService } from '../api/core-logic-api.service';
import { EventsService } from './events.service';
import { Utils } from '@shared/utils';

import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as stackActions from '@store/actions/stacks.actions';
import { splitId as splitClipId } from '@store/selectors/clips.selectors';
import {
  getStacksAll,
  getStacksLoaded,
  getStacksGroupNextToken,
  StackGroup,
  getProjectStacksGroup,
} from '@store/selectors/stacks.selectors';
// import { getClipEntities } from '@store/selectors/clips.selectors';
import { Stack, IOrderedClip } from '@shared/models/stack.model';
import { Clip } from '@shared/models/clip.model';
import { GraphQlParamsStacks, UpdateParam } from '../api/api-types';
import { StacksApiService } from '../api/stacks-api.service';
import { UpdateParamInt } from '../api/api-types';
import { UserService } from './user.service';
import { SentryService } from './analytics/sentry.service';

const DEBUG_LOGS = false;

const S3_STACK_POSTER_UPLOAD_PATH = 'uploads/images/';

export interface SaveStackPosterResponse {
  link?: string; // if it needs to have auth
  src: string; // what to save with the stack
}

const PAGE = '[StackService]';
/**
 * serves as an intermediary between the application store and the rest of the application
 * https://onehungrymind.com/handle-multiple-angular-2-models-ngrx-computed-observables/
 */
@Injectable({
  providedIn: 'root',
})
export class StacksService {
  /*
   * ngrx Store connections
   */
  selectAllStacks$: Observable<Stack[]> = this.store.select(getStacksAll);
  // selectStackPlay$: Observable<Stack> = this.store.select(fromStore.getSelectedStackPlay);
  // selectStackEdit$: Observable<Stack> = this.store.select(fromStore.getSelectedStackEdit);
  /** @deprecated selectLoaded$ required? */
  selectLoaded$: Observable<string[]> = this.store.select(getStacksLoaded);

  constructor(
    private store: Store<State>,
    private stacksApi: StacksApiService,
    private userService: UserService,
    private events: EventsService,
    private coreApi: CoreLogicApiService,
    private sentryService: SentryService
  ) {}

  /**
   * Create a Stack UID
   * @param projectId
   * @param stackId
   */
  makeId(projectId: string, stackId: string): string {
    return `${projectId}/${stackId}`;
  }

  getNextToken(group: string): Observable<string> {
    return this.store.select(getStacksGroupNextToken(group));
  }

  // /**
  //  * @deprecated - to be removed from project-detail once complete migration of project page to that page...
  //  * Get Project Stacks
  //  */
  // getProjectStacksRecent(projectId: string): Observable<Stack[]> {
  //   const recentStacks$ = this.selectAllStacks$.pipe(
  //     map((stacks: Stack[]) => stacks.filter((stack) => stack && stack.projectId && stack.projectId === projectId)),
  //     map((stacks: Stack[]) => stacks.sort(sortRecent)),
  //   );
  //   return this.getLoadedStacksOrUndefined(recentStacks$, projectId, StackGroup.Recent);
  // }
  // /**
  //  * @deprecated - to be removed from project-detail once complete migration of project page to that page...
  //  * Get Project Stacks
  //  */
  // getProjectStacksFeatured(projectId: string): Observable<Stack[]> {
  //   const featuredStacks$ = this.selectAllStacks$.pipe(
  //     map(
  //       (stacks: Stack[]) =>
  //         stacks.filter(
  //           (stack) =>
  //             stack &&
  //             stack.projectId &&
  //             stack.projectId === projectId &&
  //             typeof stack.featured === 'number' &&
  //             stack.featured > 0,
  //         )
  //     ),
  //     map((stacks: Stack[]) => stacks.sort(sortFeatured)),
  //   );
  //   return this.getLoadedStacksOrUndefined(featuredStacks$, projectId, StackGroup.Featured);
  // }

  // /**
  //  * @deprecated in favor of loadFilteredStacks
  //  * Load Recent Stacks
  //  */
  // loadRecent() {
  //   setTimeout(() => {
  //     this.store.dispatch(stackActions.loadRecent({}));
  //   }, REHYDRATE_DELAY);
  // }

  // /**
  //  * @deprecated in favor of loadFilteredStacks
  //  * Load Featured Stacks
  //  */
  // loadFeatured() {
  //   setTimeout(() => {
  //     this.store.dispatch(stackActions.loadFeatured({}));
  //   }, REHYDRATE_DELAY);
  // }

  // /**
  //  * @deprecated in favor of loadFilteredStacks
  //  * Load Trending Stacks
  //  */
  // loadTrending() {
  //   setTimeout(() => {
  //     this.store.dispatch(stackActions.loadTrending({}));
  //   }, REHYDRATE_DELAY);
  // }

  // /**
  //  * @deprecated in favor of loadFilteredStacks
  //  */
  // loadStacks() {
  //   // this.store.dispatch(stackActions.load()); // deprecated
  //   this.loadRecent();
  // }

  loadFeaturedProjectStacks(projectId: string) {
    this.store.dispatch(stackActions.loadProjectFeaturedStacks({ projectId }));
  }
  loadRecentProjectStacks(projectId: string) {
    this.store.dispatch(stackActions.loadProjectRecentStacks({ projectId }));
  }
  loadProjectStacks(projectId: string) {
    this.store.dispatch(stackActions.loadProjectRecentStacks({ projectId }));
  }

  // /**
  //  * @deprecated in favor of loadMoreFilteredStacks
  //  */
  // loadMoreFeatured(startIndex = 0, limit = 20) {
  //   console.log(`${PAGE} loadMoreFeatured...`);
  //   this.store.dispatch(stackActions.loadMoreFeatured({}));
  // }

  // loadMoreRecent(startIndex = 0, limit = 20) {
  //   console.log(`${PAGE} loadMoreRecent...`);
  //   this.store.dispatch(stackActions.loadMoreRecent({}));
  // }

  // loadMoreTrending() {
  //   console.log(`${PAGE} loadMoreTrending...`);
  //   this.store.dispatch(stackActions.loadMoreTrending({}));
  // }

  loadMoreProjectStacks(projectId: string, group: StackGroup) {
    console.log(`${PAGE} loadMoreProjectStacks...[${group}]`);

    switch (group) {
      case StackGroup.Featured:
        this.store.dispatch(stackActions.loadMoreProjectFeaturedStacks({ projectId }));
        break;
      case StackGroup.Recent:
        this.store.dispatch(stackActions.loadMoreProjectRecentStacks({ projectId }));
        break;
      default:
        console.log(`${PAGE} loadMoreProjectStacks UNHANDLED group: '${group}'`);
    }
  }

  /** used by publishService */
  addStack(stack: Stack): void {
    this.store.dispatch(stackActions.add({ stacks: [stack] }));
  }

  /**
   * Query the API for SearchText
   * NOTE: searchFields[] in stack.model.ts
   * @note unused
   */
  searchStacks(term: string, projectId: string = '') {
    console.log(`${PAGE} searchStacks - TODO!`, { term, projectId });
    // this.store.dispatch(stackActions.search()); // createEffect -> API
  }
  /** @note unused */
  addStackTags(projectId: string, stackId: string, tags: string[]) {
    console.log(`${PAGE} TODO: API: addTags: %o`, { tags, projectId, stackId });
  }
  /** @note unused */
  removeStackTags(projectId: string, stackId: string, tags: string[]) {
    console.log(`${PAGE} TODO: API: removeTags: %o`, { tags, projectId, stackId });
  }
  /** @note unused */
  addStacks(stacks: Stack[]): void {
    this.store.dispatch(stackActions.add({ stacks }));
  }

  /** @note unused */
  getAvatarUrl(stack: Stack) {
    return this.userService.getUserAvatarUrl(stack.userId);
  }

  /**
   * Save an image as the Stack Poster
   * @param userId
   * @param posterId
   * @param imageBlob
   * @param contentType
   */
  saveStackPoster(
    userId: string,
    posterId: string,
    imageBlob: Blob,
    contentType: string = 'image/jpeg'
  ): Promise<SaveStackPosterResponse> {
    console.log(`${PAGE} saveStackPoster: ${userId}_-_${posterId}`);

    const ext = Utils.mimeToExtension(contentType);
    const posterName = `${userId}_-_${posterId}.${ext}`;

    return Storage.put(S3_STACK_POSTER_UPLOAD_PATH + posterName, imageBlob, {
      level: 'public',
      contentType,
      // progressCallback(progress) {
      //   console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      // }
    }).then((res) => {
      console.log(`${PAGE} saveStackPoster res:`, res);
      if (res && res['key']) {
        const config = Storage.vault.configure();
        const bucket = config && config.AWSS3 && config.AWSS3.bucket ? config.AWSS3.bucket : '';
        const key: string = res['key'];
        const src = `${bucket}/public/${key}`;
        console.log(`${PAGE} saveStackPoster src:`, src);

        return Storage.get(key).then((link) => ({
          link: link as string,
          src, // TODO: send this with final resting place...
        }));
      }
      return {
        link: '',
        src: '',
      };
    });
  }

  /**
   * @v2
   * RealTimeData GraphQL Subscription MVP-1030
   * Just an observable, right?
   * moving to core-logic as root of http gql
   */
  subStacksUpdated$(projectId = '', stackId = '', userId = '') {
    console.log('TODO: update from v1 to v2 and shared CoreLib sub..', { projectId, stackId, userId });
    return this.coreApi.watchAllStacksData$;
  }

  /**
   * @v1
   * clipUpdate GraphQL Subscription
   */
  subStacksUpdated(projectId: string, stackId = '', userId = '') {
    if (userId) {
      console.warn('UserId was provided but we are not using it... fyi!');
    }
    const params: GraphQlParamsStacks = {
      projectId,
    };
    if (stackId) {
      params.stackId = stackId;
    }
    // not currently using userId on this GQL subscription in the API
    // if (userId) {  params.userId = userId; }

    // keeping the sub variable local so there can be multiple open subs
    const sub = this.stacksApi.subscribeStacksUpdated(params).subscribe({
      next: (res) => {
        // DEBUG_LOGS &&
        console.log(`${PAGE} stacksUpdatedSubscription next:`, res);
        if (!res || !res.stackId || !res.projectId) {
          throw new Error('Missing stack id in subscription?');
        }
        const updates: UpdateParam[] = [];
        let hlsMeta, hlsSrc;
        if (res.hlsMeta) {
          try {
            hlsMeta = typeof res.hlsMeta === 'string' ? JSON.parse(res.hlsMeta) : res.hlsMeta;
            updates.push({ prop: 'hlsMeta', value: hlsMeta });
          } catch (error) {
            console.log(`Caught error during JSON.parse(res.hlsMeta):`, {
              hlsMeta: res.hlsMeta,
              error: error.message || error,
            });
          }
        }
        if (res.hlsSrc) {
          hlsSrc = res.hlsSrc;
          updates.push({ prop: 'hlsSrc', value: hlsSrc });
        }
        if (res.hero) {
          updates.push({ prop: 'hero', value: res.hero });
        }
        if (res.duration) {
          updates.push({ prop: 'duration', value: res.duration });
        }

        // take the updates and flow to store...
        this.store.dispatch(
          stackActions.update({
            stack: res,
            updates,
          })
        );

        if (hlsSrc && hlsSrc.length > 0) {
          // when complete, this.unsubClipsUpdated()
          sub.unsubscribe();
        }
      },
      error: (err) => {
        console.warn(`${PAGE} stacksUpdatedSubscription ERROR:`, err);
        this.sentryService.captureError(err);

        if (projectId && stackId) {
          // this.store.dispatch(clipActions.updateClipTranscoding({
          //   projectId,
          //   id,
          //   updates: {
          //     hlsMeta: {
          //       errorMessage:  err.message || err.errorMessage || err
          //     },
          //   }
          // }));
        }
        sub.unsubscribe();
      },
      complete: () => {
        // DEBUG_LOGS &&
        console.log(`${PAGE} stacksUpdatedSubscription => Complete`);
      },
    });
  }
  //end GraphQL Subscription

  /**
   * take clipIds: string[] create IOrderedClip[] playlist
   * @returns IOrderedClip[]
   *
   * Currently UNUSED
   */
  createPlaylistFromClipIds(ids: string[]): IOrderedClip[] {
    if (!Array.isArray(ids)) {
      throw new Error('Missing ids?');
    }
    const orderedClips = [];

    ids.forEach((id, index) => {
      const clipId = splitClipId(id);
      // quick error checking
      if (!clipId || !clipId.id || !clipId.projectId) {
        console.warn(`${PAGE} createPlaylistFromClipIds Missing ClipId ! `, { id, index, ids });
        this.sentryService.captureMessage(`createPlaylistFromClipIds Missing ClipId: '${id}'`);
      } else {
        orderedClips.push({
          ...splitClipId(id),
          order: index,
        });
      }
    });
    return orderedClips;
  }

  /**
   * UNUSED
   */
  createPlaylistFromClips(clips: Clip[]) {
    if (clips.filter((item) => !item.projectId || !item.id)) {
      throw new Error('Missing a clip.projectId or clip.id !');
    }
    return clips.map((clip, index) => ({
      projectId: clip.projectId,
      id: clip.id,
      order: index,
    }));
  }

  /**
   * Update a Stack - supply the Stack and an array of UpdateParam{ prop: string, value: any }
   * @param stack
   * @param updates
   */
  async updateStack(stack: Stack, updates: UpdateParam[]): Promise<Stack> {
    if (!stack || !stack.projectId || !stack.stackId) {
      return Promise.reject('Missing required arguments: stack ids');
    }
    try {
      // https://stackoverflow.com/questions/34190375/how-can-i-await-on-an-rx-observable
      const userId = await lastValueFrom(this.userService.userId$.pipe(first()));
      updates.push({ prop: 'updatedBy', value: userId });

      const updatedStack = await this.stacksApi.updateStack(stack, updates);
      DEBUG_LOGS && console.log(`${PAGE} updatedStack`, { updatedStack, updates });

      this.store.dispatch(stackActions.update({ stack: updatedStack, updates }));
      return updatedStack;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * delete Stack and remove from store
   * @param stack
   */
  deleteStack(stack: Stack): Promise<string> {
    DEBUG_LOGS && console.log(`${PAGE} deleteStack`, stack);
    return this.stacksApi
      .deleteStack(stack.projectId, stack.stackId)
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} deleteStack res:`, res);
        this.store.dispatch(stackActions.deleteStack({ stack }));
        return 'Stack deleted';
      })
      .catch((err) => {
        console.error(`${PAGE} deleteStack err:`, err);
        throw err;
      });
  }

  /** appears unused... */
  private incrementStackAdmin(projectId: string, stackId: string, updates: UpdateParamInt[]): Promise<Partial<Stack>> {
    return this.stacksApi.incrementAdmin(projectId, stackId, updates).then((stack) => {
      this.store.dispatch(stackActions.update({ stack, updates }));
      return stack;
    });
  }
  /** appears unused... */
  private incrementStackPublic(projectId: string, stackId: string, updates: UpdateParamInt[]): Promise<Partial<Stack>> {
    return this.stacksApi.incrementPublic(projectId, stackId, updates).then((stack) => {
      this.store.dispatch(stackActions.update({ stack, updates }));
      return stack;
    });
  }

  /**
   * encapuslate stack observables with the information if they have been loaded or not.
   * if they're still being loaded, the observable will return `undefined`. otherwise `[…]`.
   * this helps to distinguish if there are no stacks to display or if there are no stacks yet, because they're still being requested.
   */
  private getLoadedStacksOrUndefined(
    stacks$: Observable<Stack[]>,
    projectId: string,
    group: StackGroup
  ): Observable<Stack[]> {
    return combineLatest([stacks$, this.selectLoaded$]).pipe(
      map(([stacks, loadedStacks]) =>
        Array.isArray(loadedStacks) && loadedStacks.indexOf(getProjectStacksGroup(projectId, group)) !== -1
          ? stacks
          : undefined
      )
    );
  }
}
