/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "filmstacker-amp-v1-hostingbucket-master",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d10r0lw7gl0njn.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:68c48e33-2124-4277-9d7c-c72850a66d21",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_UyteEhEtX",
    "aws_user_pools_web_client_id": "23qdvd1u9irbmlhqheuj1nvujr",
    "oauth": {},
    "aws_user_files_s3_bucket": "filmstacker-amp-v1-userfiles-master",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
