<ion-header>
  <app-top-navbar title="">
    <!-- SLOT: anything added here will go below the ion-navbar -->
  </app-top-navbar>
</ion-header>

<ion-content>
  <div class="content-background d-flex ion-padding"
      [style.background-image]="'url(' + backgroundUrl + ')'"
      [style.background-position]="backgroundPosition">
    <div class="container ion-padding">
      <div class="content-wrap ion-padding">
        <h1>
          {{ translatePath + 'NAME' | translate }}
        </h1>
        <h3>
          {{ translatePath + 'MEMBERS_INTRO' | translate }} 
          <div>
            {{ translatePath + 'MEMBERS_INTRO_2' | translate }} 
          </div>
        </h3>
        <div class="action-buttons ion-margin-top">
          <ion-button [routerLink]="[actionUrl]">
            {{ translatePath + 'CTA_MEMBERS_1' | translate }} 
          </ion-button>
          <!-- <ion-button [routerLink]="['/onboarding/signup', routeId]">
            {{ translatePath + 'CTA_MEMBERS_1' | translate }} 
          </ion-button> -->
          <!-- <ion-button *ngIf="!showingHowItWorks" fill="outline" color="light" (click)="showingHowItWorks = true">
            {{ translatePath + 'CTA_MEMBERS_2' | translate }} 
          </ion-button> -->
        </div>
      </div>
    </div>
  </div>
  <section class="how-it-works light">
    <app-onboarding-how-it-works [routeId]="routeId"></app-onboarding-how-it-works>
  </section>

  <app-onboarding-section-get-started [routeId]="routeId" [actionUrl]="actionUrl"></app-onboarding-section-get-started>

  <div class="ion-margin-top">
    <app-footer></app-footer>
  </div>
</ion-content>
