<fs-form-item [label]="label" [errorMessage]="errorMessage">
  <ion-input
    [ngStyle]="{
      'border-color': errorMessage ? 'var(--ion-color-danger)' : 'inherit'
    }"
    [value]="control.value"
    [type]="type"
    [placeholder]="placeholder"
    [labelPlacement]="labelPlacement"
    [label]="label"
    [helperText]="hint + (showErrorTextOnError && errorMessage ? ' ' + errorMessage : '')"
    [errorText]="showErrorTextOnError ? errorMessage : ''"
    [disabled]="disabled"
    [debounce]="debounce"
    (ionBlur)="isReadyForValidation = true; blur.emit(control.value)"
    (ionChange)="isReadyForValidation = true; updateProp($any($event).detail.value)"
    (ionInput)="isReadyForValidation = true; inputEvent($any($event).detail.value)"
  >
    <!-- <div *ngIf="label || errorMessage" slot="label">
      <div class="fs-form-item__label">
        {{ label }}
        <div class="fs-form-item__label--error" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </div>
    </div> -->
  </ion-input>
  <!-- <span class="fs-form-input__hint" *ngIf="hint" [innerHTML]="hint"></span> -->
</fs-form-item>
