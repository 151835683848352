import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@shared/shared.module';
import { DirectivesModule } from '../../directives/directives.module';
// import { ClipDetailModule } from '../clip-detail/clip-detail.module';
import { MyStackItemComponent } from './my-stack-item/my-stack-item.component';
import { MyStackCaptureItemComponent } from './my-stack-capture-item/my-stack-capture-item.component';
import { ClipperModule } from '../../modules/clipper/clipper.module';
import { MyStackComponent } from './my-stack.component';
import { TokensModule } from '@tokens/tokens.module'

@NgModule({
  imports: [
    IonicModule,
    DirectivesModule,
    SharedModule,
    TokensModule,
    // ClipDetailModule,
    ClipperModule
  ],
  declarations: [
    MyStackItemComponent,
    MyStackCaptureItemComponent,
    MyStackComponent
  ],
  exports: [
    MyStackComponent
  ]
})
export class MyStackModule { }
