<h1 *ngIf="loadingTokens && !hasPersistentToken else doneLoadingTokensHeading">
  {{ 'COMMON.LOADING' | translate }}
</h1>
<ng-template #doneLoadingTokensHeading>
  <h1 *ngIf="persistentTokenActive else notPublicHeading">
    {{ 'ONBOARDING.COMMON.INVITE.OPEN_PUBLIC' | translate }}
  </h1>
  <ng-template #notPublicHeading>
    <h1>
      {{ 'ONBOARDING.COMMON.INVITE.CLOSED_PUBLIC' | translate }}
    </h1>
  </ng-template>
</ng-template>


<div *ngIf="loadingTokens && !hasPersistentToken else doneLoadingTokens" class="ion-text-center">
  <p class="ion-text-center">
    {{ 'COMMON.LOADING' | translate }}
  </p>
</div>

<ng-template #doneLoadingTokens>

  <ng-container *ngIf="hasPersistentToken && (!persistentTokenExpired || persistentToken?.expires); else notPublic">
    <!-- 
      In development: 
      "Print this QR as a 6x9 graphic"  
      "Email this QR image to your email"
    -->
    <ng-container *ngIf="!persistentTokenExpired">
      <p class="no-top-margin">
        {{ translatePath + 'INVITE_QR_TEXT' | translate }}
      </p>

      <app-qr-code [qrCode]="qrCode"></app-qr-code>

      <ion-item class="share-item" lines="none">
        <ion-input #inputShareUrl readonly selectAllOnClick [value]="qrCode"></ion-input>
        <ion-button size="default" (click)="share(shareToOptions.CLIPBOARD, $event)" [title]="'SOCIAL.COPY_TO_CLIPBOARD' | translate">
          <ion-icon name="link" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-item>

      <!-- trying button instead of toggle, below...
      <ion-item *ngIf="persistentTokenId" lines="none" class="ion-padding-top">
        <ion-label class="ion-text-wrap">
          <ng-container *ngIf="persistentTokenActive else toggleNoPersistentText">
            {{ 'ONBOARDING.COMMON.INVITE.TOGGLE_CLOSE_PUBLIC' | translate }}
          </ng-container>
          <ng-template #toggleNoPersistentText>
            {{ 'ONBOARDING.COMMON.INVITE.TOGGLE_OPEN_PUBLIC' | translate }}
          </ng-template>
        </ion-label>
        <ion-toggle #togglePublicInvite slot="start" [checked]="true" [enableOnOffLabels]="true"
          (ionChange)="toggleDisabledChanged($event)"></ion-toggle>
      </ion-item> -->
    </ng-container>

    <ng-container *ngIf="persistentToken?.expires">
      <ng-container *ngIf="persistentTokenExpired else notExpired">
        <p class="no-top-margin">
          {{ translatePath + 'INVITE_NO_QR_TEXT' | translate }}
        </p>
        <p class="bold" translate [translateParams]="{ date: persistentToken.expires | date:'medium' }">
          {{ 'ONBOARDING.COMMON.INVITE.EXPIRED_ON' }}
        </p>
        <ion-button class="ion-padding-end" (click)="renewExpiration()">
          {{ 'ONBOARDING.COMMON.INVITE.EXPIRED_RENEW' | translate }}
        </ion-button>
        <ion-button fill="outline" color="secondary" (click)="confirmDeleteAndNew()">
          {{ 'ONBOARDING.COMMON.INVITE.DELETE_NEW' | translate }}
        </ion-button>
      </ng-container>

      <ng-template #notExpired>
        <ion-item lines="none">
          <ion-label translate [translateParams]="{ date: persistentToken.expires | date:'medium' }"
            class="ion-text-wrap ion-padding-vertical" style="text-transform: none;">
            {{ 'ONBOARDING.COMMON.INVITE.EXPIRES' }}
          </ion-label>
          <ion-button fill="outline" slot="end" (click)="addDays()">
            {{ 'ONBOARDING.COMMON.INVITE.ADD_DAYS' | translate }}
          </ion-button>
        </ion-item>
      </ng-template>

    </ng-container>

    <div *ngIf="persistentTokenId && !persistentTokenExpired" class="ion-text-center ion-padding-top">
      <ion-button fill="outline" color="secondary" (click)="disablePublicInvite()">
        {{ 'ONBOARDING.COMMON.INVITE.DISABLE_QR' | translate }}
      </ion-button>
    </div>

  </ng-container>

  <ng-template #notPublic>
    <p class="no-top-margin">
      {{ translatePath + 'INVITE_NO_QR_TEXT' | translate }}
    </p>
    <div *ngIf="!hasPersistentToken || persistentTokenExpired" class="ion-text-center">
      <ion-button [disabled]="!projectId || generatingQr" size="large" (click)="generateQr()">
        <ion-spinner *ngIf="generatingQr"></ion-spinner>
        {{ 'ONBOARDING.COMMON.INVITE.PUBLIC' | translate }}
      </ion-button>
    </div>
  </ng-template>

</ng-template>