/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, selectPlans } from '@billing/store';
import { CheckoutButtonComponent } from '@billing/shared/components';
import { ChargebeePlanId, Plan } from '@billing/shared/billing.model';
import { getPlanFromOnboardingType, TRANSLATE_ROOT } from '../shared/onboarding.model';
import { OnboardingService } from '../shared/services/onboarding.service';
import { Project } from '@projects/shared/project.model';
import { ProjectService } from '@projects/shared/services';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';

const DEBUG_LOGS = false;

@Component({
  selector: 'app-onboarding-invite',
  templateUrl: './onboarding-invite.component.html',
  styleUrls: ['./onboarding-invite.component.scss'],
})
export class OnboardingInviteComponent implements OnInit, OnDestroy {
  title = '';
  translatePath = TRANSLATE_ROOT;
  actionUrl = '/invite'; // change me
  routeId: string;
  backgroundUrl = '/assets/images/events/crowd-filming@2.jpg';
  backgroundPosition = 'center';

  isProPlan = false;
  planId: ChargebeePlanId = ChargebeePlanId.Free;
  plan: Plan;

  get projectId() {
    return this.route.snapshot.paramMap.get('projectId');
  }

  project$: Observable<Project>;
  loading = true;
  routeProjectDetail = `/${PROJECT_DETAIL_ROUTE}`;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private onboardingService: OnboardingService,
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // billingService.loadPlans happens in app.component.initializeApp

    setTimeout(() => {
      // wait for myProjects to load...
      this.loading = false;
      if (this.projectId) {
        this.project$ = this.projectService.getProjectPreview(this.projectId);
        this.project$.pipe(take(1)).subscribe((proj) => {
          this.title = proj?.title ?? '';
          // this.backgroundUrl = proj && proj.hero ? proj.hero : this.defaultBackgroundUrl;
        });
      }
    }, 3600);

    combineLatest([this.onboardingService.routeOnboardingMeta$, this.store.select(selectPlans)])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        async ([
          { routeId, translatePath = '', actionUrl = '', backgroundUrl = '', backgroundPosition = '' },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          { plans, addons },
        ]) => {
          if (!routeId) return; // not the current route or metadata not available
          this.routeId = routeId;
          this.translatePath = translatePath;
          this.actionUrl = actionUrl + 'invite'; // used for the login-form
          this.backgroundUrl = backgroundUrl;
          this.backgroundPosition = backgroundPosition;

          if (plans?.length > 0) {
            const { planId, plan, isProPlan } = getPlanFromOnboardingType(routeId, plans);
            this.planId = planId;
            this.plan = plan;

            DEBUG_LOGS && console.log(`[Invite]`, { routeId });
            this.isProPlan = isProPlan;
          }
        }
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSuccess(event) {
    console.log(`Invite Success. Route?`, event);
  }

  /**
   * ref: billing button click
   */
  checkoutButtonClick(onboarding, checkoutButton: CheckoutButtonComponent) {
    console.log(`signupContinue:`, { onboarding, checkoutButton, plan: this.plan });
    // if (!checkoutButton || !checkoutButton.linkElement || typeof checkoutButton.linkElement.click !== 'function') {
    //   throw new Error(`Not able to linkElement.click?`)
    //   // checkoutButton.linkElement.click();
    // }
    // if (!checkoutButton || typeof checkoutButton.clickLink !== 'function') {
    //   throw new Error(`Not able to linkElement.click?`);
    // }
    // checkoutButton.linkElement.click();
  }
}
