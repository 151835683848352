/*
  Docs: https://angular.io/guide/animations
  Original: https://github.com/angular-university/angular-advanced-course/blob/master/animations/src/app/animations.ts
*/
import { animation, style, animate, trigger, transition, useAnimation } from '@angular/animations';

export const fadeIn = animation([
    style({opacity:0}),
    animate("{{delay}}",style({opacity:1}))
],
    {params: {delay: '1000ms'}});

export const fadeOut = animation(
    animate("{{delay}}", style({opacity:0})),
    {params: {delay: '1000ms'}}
);

export const fadeInOut = trigger('fadeInOut', [
    transition('void => *', useAnimation(fadeIn, {params: {delay: '500ms'}}) ),
    transition('* => void', useAnimation(fadeOut, {params: {delay: '500ms'}}) )
]);

export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateY(-204px)', }),
    animate('450ms ease-in-out', style({ transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0)' }),
    animate('450ms ease-in-out', style({ transform: 'translateY(-204px)' })),
  ]),
]);
