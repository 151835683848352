/**
 * @format
 */

import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PlyrComponent, PlyrDriverUpdateSourceParams } from '@app-plyr';
import { Subject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as stackActions from '@store/actions/stacks.actions';
import { State } from '@store/reducers';
import { getUserInteractedWithDom } from '@store/selectors/user.selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { EventsService, EventActions, Orientation } from '@services/events.service';
import { Clip, DEFAULT_POSTER, HLS_META_PROP_SUBMITTED, HLS_META_UPGRADE_PROP } from '@shared/models/clip.model';
import { SentryService } from '@services/analytics/sentry.service';
import { IOrderedClip, Stack, StackHlsMeta } from '@shared/models/stack.model';
import { PlyrDriverDefault } from '../drivers/plyr-driver-default/plyr-driver-default';
import { PlyrDriverHlsjs } from '../drivers/plyr-driver-hlsjs/plyr-driver-hlsjs';
import { COMMAND, VideoPlayerService } from '@app/modules/video-player/shared/services/video-player.service';
import { Utils } from '@shared/utils';
import { environment } from 'src/environments/environment';
import { VideoService } from '@services/video.service';
import { MediaType } from 'plyr';
// import _throttle from 'lodash/throttle';

const DEBUG_LOGS = false;
/** trying to dig into the error prop location, but not available in current Plyr version... */
const PLYR_ERROR_DEBUG_LOGS = false;
const PAGE = '[Plyr]';

/**
 * Important: the order of the video files is vital; 
 *  @param value {Clip}
 
    Chrome currently has a bug in which it will not autoplay a .webm video 
    if it comes after anything else (put it before the mp4)
    per: http://thenewcode.com/777/Create-Fullscreen-HTML5-Page-Background-Video
 
    #t=0.1 will start the video from 0.1 sec during the load itself. Which appear like a thumbnail image for the video.
 
 
    notes:
 
    // https://forum.vuejs.org/t/safari-audio-play-notallowederror-dom-exception-35/25099/5
    he got it working because he actually toggles play/pause synchronously,
    He then saves the play/pause state in the store, and that happens asynchonously, but that doesn’t matter.
    That’s all fine until you want to change the play/pause state throguh the store, which again will be async.
    Probably one of those few situations where I would actually use an event bus to get the toggle signal to the player directly.
 *
 */
const reorderVideoSources = (value) => {
  if (value && value.sources && value.sources.length > 0) {
    for (let i = 0; i < value.sources.length; i++) {
      if (value.sources[i].type === 'video/webm') {
        const a = value.sources.splice(i, 1); // removes the item
        value.sources.unshift(a[0]); // adds it back to the beginning
      }
    }
  }
  return value;
};

enum Sources {
  Video,
  HLS,
  Youtube,
  Vimeo,
  Audio,
}

interface MetadataPayload {
  duration: number;
  currentTime: number;
  paused: boolean;
  ended: boolean;
  volume: number;
}

/**
 * HLS demo code: https://codepen.io/pen?template=oyLKQb
 *
 * https://github.com/sampotts/plyr#embeds
 * YouTube and Vimeo are currently supported and function much like a HTML5 video.
 * Similar events and API methods are available for all types. However if you wish to access the API's directly.
 * You can do so via the embed property of your player object - e.g. player.embed.
 * You can then use the relevant methods from the third party APIs.
 *
 * More info on the respective API's here:
 * YouTube iframe API Reference: https://developers.google.com/youtube/iframe_api_reference
 * Vimeo player.js Reference: https://github.com/vimeo/player.js
 *
 * Note: Not all API methods may work 100%. Your mileage may vary. It's better to use the Plyr API where possible.
 */
@Component({
  selector: 'app-video-player-plyr',
  templateUrl: './video-player-plyr.component.html',
  styleUrls: ['./video-player-plyr.component.scss'],
})
export class VideoPlayerPlyrComponent implements OnInit, OnDestroy {
  @Input() playerId = 'plyr';
  @Input() isSafari: boolean = false;
  @Input() showOverlayPlay: boolean = true;
  @Input() showControls: boolean = true;
  @Input() useTrimmerControls: boolean = false;
  @Input() showRestart: boolean = false;
  @Input() cssHeight: string = '100%';
  @Input() cssWidth: string = '100%';
  /** from parent, =didInteract$ */
  @Input() readyToPlay = false;
  /** @todo for dev, to be removed */
  @Input() doHlsDestroy: boolean = true;

  @Input('stack')
  get stack(): Stack {
    return this._stack;
  }
  set stack(input: Stack) {
    DEBUG_LOGS && console.log(`${PAGE} set stack`, input);
    this._stack = input;

    this.isHlsStack = input && input.hlsSrc && input.hlsSrc.length > 0;
    if (this.isHlsStack) {
      this.updateStack(input);
      this.plyrDriver = new PlyrDriverHlsjs(this.autoplay); // autoload is autoplay (no poster on autoload)
    } else {
      this.plyrDriver = new PlyrDriverDefault();
    }
  }

  /**
   * Question...
   * how can we refine the hlsMetadata tracks with more granularity than full seconds? (all that's offered by our transcoder ?!)
   * the clips themselves have detail?
   * playlist.map(clip => clip.durationInSeconds * 1000)
   *
   * ref: createHlsPlaylistTriggers
   */
  @Input('playlist')
  get playlist(): Clip[] {
    return this._playlist;
  }
  set playlist(clips: Clip[]) {
    this._playlist = Array.isArray(clips) ? clips : [];
    DEBUG_LOGS && console.log(`${PAGE} set playlist, length = ${this._playlist.length}`);
    // this.stackHlsMeta
  }

  @Input('currentIndex')
  get currentIndex(): number {
    return this._currentIndex;
  }
  set currentIndex(index: number) {
    if (typeof index === 'number' && index !== this._currentIndex) {
      this._currentIndex = index;
      if (this.isHlsStack && this.stackHlsMeta && Array.isArray(this.stackHlsMeta.clipMetadataList)) {
        const clipAtIndex = this.stackHlsMeta.clipMetadataList.find((item) => item && item.order === index + 1);
        if (clipAtIndex && typeof clipAtIndex.startTime === 'number' && this.player && this.player.currentTime) {
          // set time to seek to
          const startTime = clipAtIndex.startTime / 1000;
          DEBUG_LOGS &&
            console.log(
              `${PAGE} currentIndex updated for hlsStack (${index}), change currentTime to startTime:`,
              startTime
            );
          this.player.currentTime = startTime;
        }
      }
    }
  }

  @Input('currentClip')
  get currentClip(): Clip {
    return this._currentClip;
  }
  set currentClip(clip: Clip) {
    if (!clip || !clip.id) {
      DEBUG_LOGS && console.log(`${PAGE} DEVPLAYER skipping currentClip !clip.id`, clip);
      return;
    }
    if (this.isHlsStack) return; // handle the player based on HLS Stack
    /**
     * @todo note that the currentClip comes before the stack... so this *is* getting triggered on hlsStacls
     */

    clip = reorderVideoSources(clip);
    DEBUG_LOGS && console.log(`${PAGE} DEVPLAYER set currentClip clip:`, clip);
    if (clip.startTime !== null && typeof clip.startTime !== 'number') {
      clip.startTime = parseFloat(clip.startTime);
    }
    if (clip.endTime !== null && typeof clip.endTime !== 'number') {
      clip.endTime = parseFloat(clip.endTime);
    }
    this.startTime = typeof clip.startTime === 'number' ? clip.startTime : 0;
    this.endTime = typeof clip.endTime === 'number' ? clip.endTime : 0;
    this._currentClip = clip;

    // this.updateClip(clip); // do this in all cases except isHLS

    // check for an update
    const wasHls = !!this.isHlsClip;
    this.isHlsClip = clip && clip.hlsSrc && clip.hlsSrc.length > 0;
    if (wasHls && this.isHlsClip) {
      // we previously had an HLSClip, so the HLS Driver is already loaded
      // params for the driver update
      const driverParams: PlyrDriverUpdateSourceParams = {
        source: {
          sources: [
            {
              type: 'application/vnd.apple.mpegurl',
              src: clip.hlsSrc,
            },
          ],
          poster: clip.poster || DEFAULT_POSTER,
          type: 'video',
        },
        plyr: this.player,
        /**
         * player.media see: https://github.com/sampotts/plyr/issues/1918
         * I saved a link of video tag through useRef hook and used such code: hls.attachMedia(videoRef.current);
         * And when I was turning between different types I had a broken player,
         * because Plyr removes current video tag and creates a new one if its source field is changed.
         *
         * So videoRef.current link had an old tag which was already destroyed.
         * I tried the following code hls.attachMedia(player.media) and it worked.
         * player.media always has an actual link.
         */
        videoElement: this.player && this.player['media'] ? this.player['media'] : null,
      };

      this.plyrDriver.newSource(driverParams, this.doHlsDestroy);
      // this.plyrDriver = new PlyrDriverHlsjs(this.autoplay);
    } else if (!wasHls && this.isHlsClip) {
      // update Driver to HLS
      this.updateClip(clip);
      this.plyrDriver = new PlyrDriverHlsjs(this.autoplay);
    } else if (wasHls) {
      this.updateClip(clip);
      this.plyrDriver = new PlyrDriverDefault();
    } else {
      // this is a non-HLS, or no sources (Editor during Transcoding)
      this.updateClip(clip);
      this.plyrDriver = new PlyrDriverDefault();
    }
  }

  @Input()
  set autoplay(value: boolean) {
    this._autoplay = value;
    DEBUG_LOGS && console.log(`${PAGE} DEVPLAYER set autoplay='${value}'`);
  }
  get autoplay() {
    return this._autoplay;
  }

  @Output() playerReady = new EventEmitter<string>();
  @Output() loadedMetadata = new EventEmitter<{ playerId: string; payload: MetadataPayload }>();
  @Output() videoEnded = new EventEmitter<boolean>();
  @Output() videoPlaying = new EventEmitter<boolean>();

  // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;

  // or get it from plyrInit event
  player: Plyr;

  /**
   * https://github.com/smnbbrv/ngx-plyr#inputs
   */
  plyrType: MediaType = 'video'; // video or audio, see https://github.com/sampotts/plyr#the-source-setter
  /** the plyrTitle hovers the content strangely, recommend not using */
  plyrTitle = ''; //string, see https://github.com/sampotts/plyr#the-source-setter
  plyrPoster = DEFAULT_POSTER; //poster URL, see https://github.com/sampotts/plyr#the-source-setter
  // plyrSources: array of sources, see https://github.com/sampotts/plyr#the-source-setter
  plyrSources: Plyr.Source[] = [];
  plyrTracks: Plyr.Track[] = []; // array of tracks, see https://github.com/sampotts/plyr#the-source-setter
  // plyrPlaysInline: whether underlying element has playsinline attribute, boolean
  // plyrCrossOrigin: [whether underlying element has crossorigin attribute, boolean
  plyrDriver: PlyrDriverDefault | PlyrDriverHlsjs = new PlyrDriverDefault(); // see custom plyr driver https://github.com/smnbbrv/ngx-plyr#custom-plyr-driver

  /**
   * initial Plyr options https://github.com/sampotts/plyr#options
   */
  plyrOptions: Plyr.Options = {
    debug: false,
    autopause: false, // Only allow one player playing at once (needed for Audio player sync)
    clickToPlay: true,
    /**
     * controls: Array, Function or Element
     * ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
     * If a function is passed, it is assumed your method will return either an element or HTML string for the controls.
     * Three arguments will be passed to your function; id (the unique id for the player),
     * seektime (the seektime step in seconds),
     * and title (the media title).
     * See https://github.com/sampotts/plyr/blob/master/CONTROLS.md for more info on how the html needs to be structured.
     */
    controls: [
      'play-large', // The large play button in the center
      // 'restart', // Restart playback
      'rewind', // Rewind by the seek time (default 10 seconds)
      'play', // Play/pause playback
      'fast-forward', // Fast forward by the seek time (default 10 seconds)
      'progress', // The progress bar and scrubber for playback and buffering
      'current-time', // The current time of playback
      'duration', // The full duration of the media
      'mute', // Toggle mute
      'volume', // Volume control
      // 'captions', // Toggle captions - moved to the Settings menu
      'settings', // Settings menu
      // 'pip', // Picture-in-picture (currently Safari only, appears to work in Chrome on Mac too)
      'airplay', // Airplay (currently Safari only)
      // 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
      'fullscreen', // Toggle fullscreen
    ],
    /**
     * active: Toggles if captions should be active by default.
     * language: Sets the default language to load (if available). 'auto' uses the browser language.
     * update: Listen to changes to tracks and update menu.
     *         This is needed for some streaming libraries, but can result in non-selectable language options.
     */
    captions: { active: false, language: 'en', update: false },
    /** the Settings Menu items */
    settings: ['captions', 'quality', 'speed'],

    /**
     * If the default controls are used, you can specify which settings to show in the menu
     *
     * autoplay: Boolean false (turn on if allowed)
     * Autoplay the media on load. If the autoplay attribute is present on a <video> or <audio> element, this will be automatically set to true.
     * Autoplay is generally not recommended as it is seen as a negative user experience.
     * It is also disabled in many browsers. Before raising issues, do your homework. More info can be found here
     *
     * duration: Number null Specify a custom duration for media.
     *
     * vimeo: Object { byline: false, portrait: false, title: false, speed: true, transparent: false }
     * See Vimeo embed options.  https://github.com/vimeo/player.js/#embed-options
     * Some are set automatically based on other config options, namely: loop, autoplay, muted, gesture, playsinline
     *
     * youtube:	Object { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 }
     * See YouTube embed options: https://developers.google.com/youtube/player_parameters#Parameters
     * The only custom option is noCookie to use an alternative to YouTube that doesn't use cookies (useful for GDPR, etc).
     * Some are set automatically based on other config options, namely:
     * autoplay, hl, controls, disablekb, playsinline, cc_load_policy, cc_lang_pref, widget_referrer
     *
     *
     * previewThumbnails:	Object { enabled: false, src: '' }
     * enabled: Whether to enable the preview thumbnails (they must be generated by you).
     * src must be either a string or an array of strings representing URLs for the VTT files containing the image URL(s).
     * Learn more about preview thumbnails https://github.com/sampotts/plyr#preview-thumbnails.
     */
  };

  startTime: number = 0;
  endTime: number = 0;

  isTransitioning: boolean = false;

  isHlsStack = false;
  isHlsClip = false;

  stackHlsMeta: StackHlsMeta;

  // interacted with DOM
  private _interactedWithDom = false;

  private _stack: Stack;
  private _playlist: Clip[] = [];
  private _currentIndex: number;
  private _currentClip: Clip;
  private _autoplay: boolean = true;

  private interactedMedia: boolean = false;
  private isSeeking: boolean = false;
  private subscriptions: Subscription = new Subscription();
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private events: EventsService,
    private sentryService: SentryService,
    private videoService: VideoService,
    private videoPlayerService: VideoPlayerService
  ) {}

  ngOnInit(): void {
    if (!this.showControls) {
      this.plyrOptions.controls = [
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'captions', // Toggle captions
      ];
      if (this.showOverlayPlay) {
        this.plyrOptions.controls.push('play-large');
      }
    } else if (this.useTrimmerControls) {
      this.plyrOptions.hideControls = false;
      this.plyrOptions.controls = [
        'play-large', // The large play button in the center
        'play', // Play/pause playback
        'progress', // The progress bar and scrubber for playback and buffering
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'captions', // Toggle captions
      ];
    } else if (!this.showOverlayPlay) {
      this.plyrOptions.controls = [
        // 'play-large', // The large play button in the center
        'rewind', // Rewind by the seek time (default 10 seconds)
        'play', // Play/pause playback
        'fast-forward', // Fast forward by the seek time (default 10 seconds)
        'progress', // The progress bar and scrubber for playback and buffering
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'captions', // Toggle captions
        'settings', // Settings menu
        'airplay', // Airplay (currently Safari only)
        'fullscreen', // Toggle fullscreen
      ];
    }

    if (this.showRestart) {
      (this.plyrOptions.controls as string[]).unshift('restart');
    }

    const subCmd = this.videoPlayerService.command$.subscribe(({ cmd, playerId, payload }) => {
      if (playerId === this.playerId || playerId === '*') {
        switch (cmd) {
          case COMMAND.PLAY:
            this.player.play();
            break;
          case COMMAND.PAUSE:
            this.player.pause();
            break;
          case COMMAND.SEEKTO:
            const { startTime, endTime } = payload;
            this.onSeekTo(playerId, startTime, endTime);
            break;
          default:
            console.warn('Unknown command');
        }
      }
    });
    this.subscriptions.add(subCmd);

    const subSeek = this.events.subscribe('video:player:seekTo', this.onSeekTo);
    this.subscriptions.add(subSeek);

    const subOrient = this.events.subscribe(EventActions.ORIENTATION_CHANGE, this.onOrientationChange);
    this.subscriptions.add(subOrient);

    // track interaction with DOM, to allow fullscreen toggle
    this.store
      .pipe(select(getUserInteractedWithDom), takeUntil(this.onDestroy$), filter(Boolean), take(1))
      .subscribe((interacted) => {
        DEBUG_LOGS && console.log(`${PAGE} oninit getUserInteractedWithDom interacted: ${interacted}`);
        if (interacted) {
          this._interactedWithDom = true;
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onPlyrInit(event: Plyr) {
    DEBUG_LOGS && console.log(`${PAGE} onPlyrInit`, event);
    this.player = event;
  }

  updateStack(stack: Stack) {
    if (!stack) return;
    DEBUG_LOGS && console.log(`${PAGE} updateStack`, stack);
    // this.plyrTitle = stack.title || ''; // the plyrTitle hovers the content strangely
    this.plyrPoster = stack.poster || DEFAULT_POSTER;

    /**
     * @todo previewThumbnails
    // tracks?
     */
    if (stack.hlsMeta) {
      const hlsMeta = typeof stack.hlsMeta === 'string' ? Utils.tryParseJSON(stack.hlsMeta) : stack.hlsMeta;
      // this.stackHlsMeta = hlsMeta;
      this.createHlsPlaylistTriggers(hlsMeta, stack);
      if (stack.stackId === 'cody_sheehy_make-people-premeire_20220505') {
        this.setTracks({ metadataSrc: hlsMeta });
      }
    }

    if (stack.hlsSrc) {
      this.setSources({ source: Sources.HLS, src: stack.hlsSrc });
    }
  }

  updateClip(clip: Clip) {
    DEBUG_LOGS && console.log(`${PAGE} updateClip`, clip);
    // this.plyrTitle = clip.title || ''; // the plyrTitle hovers the content strangely

    this.plyrPoster = clip.poster || DEFAULT_POSTER;

    // previewThumbnails
    // tracks
    if (clip.hlsSrc) {
      this.setSources({ source: Sources.HLS, src: clip.hlsSrc });
      DEBUG_LOGS && console.log(`${PAGE} using HLS Clip hlsSrc: ${clip.hlsSrc}`);
    } else if (clip.youtube_id) {
      this.setSources({ source: Sources.Youtube, clip });
    } else if (clip.vimeo_id) {
      this.setSources({ source: Sources.Vimeo, clip });
    } else if (Array.isArray(clip.sources)) {
      this.setSources({ source: Sources.Video, clip });
    } else {
      console.warn(`${PAGE} updateClip no sources?`, clip);
    }
  }

  /**
   * https://github.com/sampotts/plyr#the-source-setter
   */
  setSources({ source, clip, src }: { source: Sources; clip?: Clip; src?: string }) {
    switch (source) {
      case Sources.Video: {
        this.plyrSources = this.currentClip.sources
          .filter((s) => s.src && s.type)
          .map((s) => ({
            src: s.src,
            type: s.type,
            size: 720, // || s.quality as number
          }));
        break;
      }
      case Sources.Youtube: {
        this.plyrSources = [
          {
            src: clip.youtube_id,
            provider: 'youtube',
          },
        ];
        break;
      }
      case Sources.Vimeo: {
        this.plyrSources = [
          {
            src: '143418951', // clip.vimeo_id
            provider: 'vimeo',
          },
        ];
        break;
      }
      case Sources.HLS: {
        this.plyrSources = [
          {
            type: 'application/vnd.apple.mpegurl',
            // type: 'video',
            src,
          },
        ];
        break;
      }
      case Sources.Audio: {
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        console.warn(`${PAGE} UNHANDLED source ${source}`, clip);
      }
    }
  }

  /**
   * take the metaData
   * 
   * Question... 
   * how can we refine the hlsMetadata tracks with more granularity than full seconds? (all that's offered by our transcoder ?!) 
   * the clips themselves have detail?
   * playlist.map(clip => clip.durationInSeconds * 1000)
   * @todo previewThumbnails
    // tracks?
   */
  createHlsPlaylistTriggers(hlsMeta, stack) {
    try {
      const playlist: IOrderedClip[] = stack && stack.playlist ? stack.playlist : [];
      if (hlsMeta && hlsMeta.stackId) {
        // !environment.production && console.log(`${PAGE} dev: hlsMeta createHlsPlaylistTriggers`, { hlsMeta, playlist });
        this.stackHlsMeta = hlsMeta;
      }
      // aligns with api dynamoStackStream handleUpdate (video-player-plyr.component.createHlsPlaylistTriggers)
      if (
        hlsMeta &&
        !hlsMeta[HLS_META_UPGRADE_PROP] &&
        !hlsMeta[HLS_META_PROP_SUBMITTED] &&
        Array.isArray(hlsMeta.clipMetadataList) &&
        playlist.length > 0
      ) {
        // each metaClip should exist in the same spot in the playlist
        let i = 0,
          didChange = false;
        // const clipMetadataList: StackHlsMetaClip[] = [];
        for (const { projectId: metaProjectId, clipId: metaId } of hlsMeta.clipMetadataList) {
          if (!playlist[i] || playlist[i].projectId !== metaProjectId || playlist[i].id !== metaId) {
            console.log(`[meta] playlist change [${i}]- project='${metaProjectId}' id='${metaId}'`);
            didChange = true;
            break;
          }
          i++;
        }
        !environment.production &&
          console.log(`${PAGE} dev: playlist createHlsPlaylistTriggers`, { didChange, hlsMeta, playlist });

        if (didChange) {
          return this.sendStackForHLSTranscode(stack);
        }
        // playlist.map(clip => clip.durationInSeconds * 1000)

        // StackHlsMetaClip
        // this.stackHlsMeta = ;
        return didChange;
      }
    } catch (error) {
      console.warn(error);
    }
  }

  /**
   * Stack Playlist HLS Upgrades MVP-983
   */
  async sendStackForHLSTranscode(stack) {
    try {
      await this.videoService.sendStackForHLSTranscode(stack);
    } catch (error) {
      console.warn(`[Plyr] sendStackForHLSTranscode caught:`, error);
    }
  }

  /**
   * @todo dev here.... but a good idea ;)
   * "cody_sheehy_make-people-premeire_20220505"
   * Set TRacks
   * https://github.com/sampotts/plyr#the-source-setter
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTracks({ metadataSrc }) {
    // /**
    //  * Convert the hlsMeta Transcoding Duration in ms to a TextTrack format of
    //  * minutes:seconds.milliseconds or hours:minutes:seconds.milliseconds
    //  */
    // const msToSecTrackTime = (milliseconds) => {
    //   //     00:92.000 --> 00:112.000
    //   const pad2 = (num) => num.toString().padStart(2, '0');
    //   let seconds = Math.floor(milliseconds / 1000);
    //   let minutes = Math.floor(seconds / 60);
    //   const hours = Math.floor(minutes / 60);

    //   seconds = seconds % 60;
    //   minutes = minutes % 60;

    //   // make it a 3 digit
    //   const ms = milliseconds.toFixed(0).padStart(3, '0').slice(3);

    //   return `${hours ? pad2(hours) + ':' : ''}${pad2(minutes)}:${pad2(seconds)}.${ms}`;
    // };

    // const tracksDev = metadataSrc.clipMetadataList.map((item, index) => {
    //   const trackTitle = msToSecTrackTime(item.duration);
    //   const trackStart = msToSecTrackTime(item.startTime);
    //   const trackEnd = msToSecTrackTime(item.endTime);

    //   console.log(`tracksDev.map ${item.order} ${trackStart} --> ${trackEnd}`, {
    //     trackTitle
    //   });

    //   return `${item.order} - ${trackTitle}
    //   ${trackStart} --> ${trackEnd}
    //   ${item.projectId}/${item.clipId}`;

    // })

    // console.log(`** setTracks`, {
    //   tracksDev
    // });

    // return;
    const tracks: Plyr.Track[] = [
      // so far, just the metadata kind
      {
        kind: 'metadata',
        label: 'meta',
        src: '/assets/data/cody_sheehy_make-people-premeire_20220505-chapters.vtt',
      },
      // but, the metadata is not triggering the oncuechanged event?
      {
        kind: 'chapters',
        label: 'story',
        src: '/assets/data/cody_sheehy_make-people-premeire_20220505-chapters.vtt',
        // default: true,
      },
      // try captions
      {
        kind: 'captions',
        label: 'English',
        srcLang: 'en',
        src: '/assets/data/cody_sheehy_make-people-premeire_20220505-_captions.vtt',
        default: true,
      },
    ];
    DEBUG_LOGS &&
      console.log(`${PAGE} TEXTTRACKS setTracks`, {
        tracks,
        playerSource: this.player && this.player.source,
        plyrTracks: this.player && this.player.source ? this.player.source.tracks : this.plyrTracks,
      });
    if (this.player && this.player.source) {
      this.player.source.tracks = tracks;
    }
    this.plyrTracks = tracks;
    /**
     * active: Toggles if captions should be active by default.
     * language: Sets the default language to load (if available). 'auto' uses the browser language.
     * update: Listen to changes to tracks and update menu.
     *         This is needed for some streaming libraries, but can result in non-selectable language options.
     */
    // this.plyrOptions.captions.active = true;
    // this.plyrOptions.captions.update = true;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private orientationTimeout;
  onOrientationChange = ({ orientation }) => {
    if (this._interactedWithDom) {
      const delay = 100;
      switch (orientation) {
        case Orientation.Portrait:
          clearTimeout(this.orientationTimeout);
          this.orientationTimeout = setTimeout(() => {
            this.exitFullscreen();
          }, delay);
          break;
        case Orientation.Landscape:
          DEBUG_LOGS && console.log(`${PAGE} onOrientationChange: ${orientation} -> player.fullscreen.enter...`);
          clearTimeout(this.orientationTimeout);
          this.orientationTimeout = setTimeout(() => {
            this.enterFullscreen();
          }, delay);
          break;
        default:
          console.log(`${PAGE} onOrientationChange: ${orientation} -> UNHANDLED!`);
      }
    } // else !interactedWithDOM
  };

  /*
    Methods

    airplay() - Trigger the airplay dialog on supported devices
    toggleControls(toggle) Boolean Toggle the controls (video only). Takes optional truthy value to force it on/off.
    on(event, function) String, Function Add an event listener for the specified event.
    once(event, function) String, Function Add an event listener for the specified event once.
    off(event, function) String, Function Remove an event listener for the specified event.
    supports(type) String Check support for a mime type.
    destroy() - Destroy the instance and garbage collect any elements.
  */

  /**
   * Start playback
   * For HTML5 players, play() will return a Promise for most browsers
   * e.g. Chrome, Firefox, Opera, Safari and Edge according to MDN at time of writing.
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play
   * @returns Promise
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play#return_value
   */
  async play(): Promise<void> {
    try {
      return await this.player.play(); // or this.plyr.player.play()
    } catch (error) {
      if (!error || !error.name) {
        console.warn(`${PAGE} play caught error:`, error);
        return Promise.reject(`Play caught ${error && error.message ? error.message : 'Unknown error'}`);
      }
      switch (error.name) {
        case 'NotSupportedError': {
          // The media source (which may be specified as a MediaStream, MediaSource, Blob, or File,
          // for example) doesn't represent a supported media format.
          console.warn(
            `${PAGE} play caught '${error.name}'': play() failed because the media source doesn't represent a supported media format.`
          );
          // return false; // what to return here? resolve?
          return Promise.reject(
            `Play caught '${error.name}': ${
              error.message || 'the media source does not represent a supported media format'
            }`
          );
        }
        case 'NotAllowedError': {
          // The user agent (browser) or operating system doesn't allow playback of media in the current context or situation.
          // This may happen, for example, if the browser requires the user to explicitly start media playback by clicking a "play" button.
          console.warn(
            `${PAGE} play caught '${error.name}': play() failed because the user didn't interact with the document first`
          );
          // return false; // what to return here? resolve?
          return Promise.reject(
            `Play caught '${error.name}': ${error.message || 'user did not interact with the document first'}`
          );
        }
        case 'DOMException': {
          console.warn(
            `${PAGE} play caught '${error.name}':' The play() request was interrupted by a new load request`
          );
          // return false; // what to return here? resolve?
          return Promise.reject(
            `Play caught '${error.name}': ${error.message || 'request was interrupted by a new load request'}`
          );
        }
        case 'AbortError': {
          // The play() request was interrupted by a new load request. https://goo.gl/LdLk22
          console.warn(
            `${PAGE} play caught '${error.name}':' The play() request was interrupted by a new load request https://goo.gl/LdLk22`
          );
          // return false; // what to return here? resolve?
          return Promise.reject(
            `Play caught '${error.name}': ${error.message || 'request was interrupted by a new load request...goo.gl'}`
          );
        }
        default: {
          console.warn(`${PAGE} play caught unhandled name='${error.name}'`, error);
          // NotSupportedError
          // The media source (which may be specified as a MediaStream, MediaSource, Blob, or File, for example) doesn't represent a supported media format.
          return Promise.reject(`Play caught ${error.message || error}`);
        }
      }
    }
  }
  /** Pause playback */
  pause(): void {
    this.player.pause();
  }
  /** Stop playback and reset to start */
  stop(): void {
    this.player.stop();
  }
  /** Restart playback */
  retart(): void {
    this.player.restart();
  }

  /**
   * Enter fullscreen.
   * If fullscreen is not supported, a fallback "full window/viewport" is used instead.
   */
  enterFullscreen(doPlay = true): void {
    try {
      // https://stackoverflow.com/questions/58817701/uncaught-in-promise-typeerror-document-not-active
      if (document.fullscreenEnabled && document.fullscreenElement === null) {
        this.player.fullscreen.enter(); // Enter fullscreen
        if (doPlay) {
          this.play(); // start playing
        }
      }
    } catch (error) {
      console.warn(`player.fullscreen.enter caught`, error);
    }
  }
  /** Exit fullscreen */
  exitFullscreen(): void {
    try {
      // https://stackoverflow.com/questions/58817701/uncaught-in-promise-typeerror-document-not-active
      if (this.player && this.player.fullscreen.active && document.fullscreenElement !== null) {
        this.player.fullscreen.exit(); // Exit fullscreen
      }
    } catch (error) {
      console.warn(`player.fullscreen.exit caught`, error);
    }
  }

  /*
    Getters and Setters
    ex: 
    player.volume = 0.5; // Sets volume at 50%
    player.currentTime = 10; // Seeks to 10 seconds

    prop  get set  Description
    isHTML5	✓	-	Returns a boolean indicating if the current player is HTML5.
    isEmbed	✓	- Returns a boolean indicating if the current player is an embedded player.
    playing	✓	-	Returns a boolean indicating if the current player is playing.
    paused	✓	-	Returns a boolean indicating if the current player is paused.
    stopped	✓	-	Returns a boolean indicating if the current player is stopped.
    ended	✓	-	Returns a boolean indicating if the current player has finished playback.
    buffered	✓	-	Returns a float between 0 and 1 indicating how much of the media is buffered

    currentTime	✓	✓	Gets or sets the currentTime for the player. The setter accepts a float in seconds.
    seeking	✓	-	Returns a boolean indicating if the current player is seeking.
    duration	✓	-	Returns the duration for the current media.
    volume	✓	✓	Gets or sets the volume for the player. The setter accepts a float between 0 and 1.
    muted	✓	✓	Gets or sets the muted state of the player. The setter accepts a boolean.
    hasAudio	✓	-	Returns a boolean indicating if the current media has an audio track.
    speed	✓	✓	Gets or sets the speed for the player. The setter accepts a value in the options specified in your config. Generally the minimum should be 0.5.
    quality¹	✓	✓	Gets or sets the quality for the player. The setter accepts a value from the options specified in your config.
    loop	✓	✓	Gets or sets the current loop state of the player. The setter accepts a boolean.
    source	✓	✓	Gets or sets the current source for the player. The setter accepts an object. See source setter below for examples.
    poster	✓	✓	Gets or sets the current poster image for the player. The setter accepts a string; the URL for the updated poster image.
    autoplay	✓	✓	Gets or sets the autoplay state of the player. The setter accepts a boolean.
    currentTrack	✓	✓	Gets or sets the caption track by index. -1 means the track is missing or captions is not active
    language	✓	✓	Gets or sets the preferred captions language for the player. The setter accepts an ISO two-letter language code. Support for the languages is dependent on the captions you include. If your captions don't have any language data, or if you have multiple tracks with the same language, you may want to use currentTrack instead.
    fullscreen.active	✓	-	Returns a boolean indicating if the current player is in fullscreen mode.
    fullscreen.enabled	✓	-	Returns a boolean indicating if the current player has fullscreen enabled.
    pip	✓	✓	Gets or sets the picture-in-picture state of the player. The setter accepts a boolean. This currently only supported on Safari 10+ (on MacOS Sierra+ and iOS 10+) and Chrome 70+. (html5 only)
    ratio	✓	✓	Gets or sets the video aspect ratio. The setter accepts a string in the same format as the ratio option.
    download	✓	✓	Gets or sets the URL for the download button. The setter accepts a string containing a valid absolute URL.

  */

  /**
   * @dev here to optimize
   * do an efficient watch of the stackMeta
   */
  onTimeUpdateCompareTriggers(currentTime) {
    if (!this.stackHlsMeta || !Array.isArray(this.stackHlsMeta.clipMetadataList)) {
      return;
    }

    try {
      // console.log("TEXTTRACK time " + currentTime)
      /**
       * @todo This is *not* efficient enough. need clever solution here
       * find the clip based on clip startTime / endTime, converting ms to seconds
       */

      /**
       * Here tried lodash.throttle, doesn't get called?
       * https://lodash.com/docs/4.17.15#throttle
       */
      // const doCheck = () => {
      //   const timeTest = Math.floor(currentTime * 1000);
      //   console.log("TEXTTRACK throttled time " + timeTest);
      //   return timeTest;
      // };
      // const did = _throttle(doCheck, 500);

      /**
       * Get the ms of currentTime, to quickly match agains
       * @todo optimization to monitor if it has changed?
       */
      const timeTest = Math.floor(currentTime * 1000); // get this, monitor if it has changed somehow?
      /**
       * @dev Wondering here if there's an interesting Observable solution to this?
       */
      const currentClip = this.stackHlsMeta.clipMetadataList.find(
        (item) => item && timeTest >= item.startTime && timeTest < item.endTime
      );
      if (currentClip && currentClip.order >= 0) {
        const currentClipOrder = currentClip.order - 1 > 0 ? currentClip.order - 1 : 0;
        if (this._currentIndex !== currentClipOrder) {
          // update this._currentIndex so it does not trigger a change from Input
          this._currentIndex = currentClipOrder;
          DEBUG_LOGS && console.log(`TEXTTRACK Update _currentIndex[${currentClipOrder}]`, timeTest);

          /** @todo does this need a throttle! */
          this.store.dispatch(stackActions.playCurrentIndex({ index: currentClipOrder }));

          // this is not being called...?
          // Utils.throttle(() => {
          //   console.warn("TEXTTRACK Update throttled index" + this._currentIndex)
          // }, 300);
        }
      }
    } catch (error) {
      // ignore
    }
  }

  /*
    Events
  */

  /**
   * * Sent when the media begins to play (either for the first time, after having been paused, or after ending and then restarting).
   */
  onPlaying(event: Plyr.PlyrEvent) {
    DEBUG_LOGS &&
      console.log(`${PAGE} onPlaying [${this.playerId}]`, { autoplay: this.autoplay, instance: event.detail.plyr });
    this.interactedMedia = true;
    this.videoPlaying.emit(true);
  }

  /**
   * Sent when playback is paused.
   */
  onPause(event: Plyr.PlyrEvent) {
    const instance = event.detail.plyr;
    DEBUG_LOGS && console.log(`${PAGE} onPause [${this.playerId}]`, { event, instance });
    this.videoPlaying.emit(false);
  }

  /**
   * The time indicated by the element's currentTime attribute has changed.
   */
  onTimeUpdate(event: Plyr.PlyrEvent) {
    try {
      if (this.isHlsStack) {
        // do an efficient watch of the stackMeta
        this.onTimeUpdateCompareTriggers(event.detail.plyr.currentTime);
      } else if (
        this.playerId === 'trimmer' ||
        this.playerId === 'trimmer-player' ||
        this.playerId === 'edit-clip-settings'
      ) {
        if (!this.isSeeking) {
          DEBUG_LOGS &&
            console.log(`${PAGE} DEVPLAYER onTimeUpdate TRIMMER ${this.startTime} (${typeof this.startTime})`);
          if (
            typeof this.startTime === 'number' &&
            this.startTime > 0 &&
            event.detail.plyr.currentTime < this.startTime
          ) {
            // Handle startTime for custom clips..
            this.player.currentTime = this.startTime;
          }
          if (typeof this.endTime === 'number' && this.endTime > 0) {
            if (event.detail.plyr.currentTime >= this.endTime) {
              DEBUG_LOGS && console.log(`${PAGE} DEVPLAYER onTimeUpdate TRIMMER`, 'currentTime >= this.endTime');
              this.player.currentTime = this.startTime;
            }
          }
          // this._autoplay = true;
        }
      } else {
        if (
          typeof this.startTime === 'number' &&
          this.startTime > 0 &&
          event.detail.plyr.currentTime < this.startTime
        ) {
          // Handle startTime for custom clips..
          this.player.currentTime = this.startTime;
        }
        if (typeof this.endTime === 'number' && this.endTime > 0 && event.detail.plyr.currentTime >= this.endTime) {
          // Handle EndTime for custom clips..
          this.pause();
          this.videoEnded.emit(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  toggleControlsForTransition() {
    this.isTransitioning = true;
    setTimeout(() => {
      this.isTransitioning = false;
    }, 2000);

    // .plyr.plyr--stopped .plyr__controls { display: none }
  }

  /**
   * Sent when playback completes.
   * 2022-12-22 Is this still true? it appears to work (Note: This does not fire if autoplay is true.)
   */
  onEnded(event: Plyr.PlyrEvent) {
    this.toggleControlsForTransition();
    // MVP-1099 exit fullscreen onEnded
    this.exitFullscreen();

    DEBUG_LOGS && console.log(`${PAGE} onEnded [${this.playerId}]`, { instance: event.detail.plyr });
    this.videoEnded.emit(true);
  }

  /**
   * Triggered when the instance is ready for API calls.
   */
  onReady(event: Plyr.PlyrEvent) {
    DEBUG_LOGS && console.log(`${PAGE} onReady [${this.playerId}]`, { instance: event.detail.plyr });
    this.playerReady.emit(this.playerId);
  }

  /**
   * The media's metadata has finished loading; all attributes now contain as much useful information as they're going to. (HTML5 only)
   */
  onLoadedMetadata(event: Plyr.PlyrEvent) {
    // const instance = event.detail.plyr;
    // fix: Sentry-XW reading plyr of undefined
    const { detail: { plyr: instance } = {} } = event;
    if (!instance) {
      console.warn(`${PAGE} DEVPLAYER NO INSTANCE? onLoadedMetadata (autoplay: ${this.autoplay})`);
      return;
    }
    DEBUG_LOGS && console.log(`${PAGE} DEVPLAYER onLoadedMetadata (autoplay: ${this.autoplay})`, { instance });
    // console.log(`${PAGE} document.video:`, document.getElementsByClassName('custom-sources')); // type='undefined'??
    const payload: MetadataPayload = {
      duration: instance.duration,
      currentTime: instance.currentTime,
      paused: instance.paused,
      ended: instance.ended,
      volume: instance.volume,
    };

    if (this.startTime > 0) {
      instance.currentTime = this.startTime;
    }
    this.loadedMetadata.emit({ playerId: this.playerId, payload });
  }
  /**
   * Sent when enough data is available that the media can be played, at least for a couple of frames. (HTML5 only)
   * This corresponds to the HAVE_ENOUGH_DATA readyState.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCanPlay(event?: Plyr.PlyrEvent) {
    // const instance = event.detail.plyr;
    if (this.autoplay) {
      if (this.isSafari && !this.interactedMedia && !this.readyToPlay) {
        console.log(`${PAGE} onCanPlay but Safari or not ready yet...`);
        return;
      }
      if (this.interactedMedia || this.readyToPlay) {
        DEBUG_LOGS && console.log(`${PAGE} onCanPlay -> play`);
        this.play().catch((e) => {
          console.log(e?.message ?? e);
        });
      }
    }
    // if ((!this.isSafari && this.autoplay) || (this.autoplay && this.interactedMedia)) {}
  }
  /**
   * Sent when the ready state changes to CAN_PLAY_THROUGH, indicating that the entire media can be played without interruption,
   * assuming the download rate remains at least at the current level. (HTML5 only)
   * Note: Manually setting the currentTime will eventually fire a canplaythrough event in firefox. Other browsers might not fire this event.
   */
  onCanPlayThrough(event: Plyr.PlyrEvent) {
    if (event && event.detail && event.detail.plyr) {
      DEBUG_LOGS &&
        console.log(`${PAGE} onCanPlayThrough, have instance... now what?`, {
          instance: event.detail.plyr,
        });
    } else {
      DEBUG_LOGS && console.log(`${PAGE} onCanPlayThrough... no instance yet`);
    }
  }

  /**
   * The state of the player has changed. The code can be accessed via event.detail.code. (YoutTube only)
   * Possible values are
   *    -1: Unstarted, 0: Ended, 1: Playing, 2: Paused, 3: Buffering, 5: Video cued.
   * See the YouTube Docs for more information: https://developers.google.com/youtube/iframe_api_reference#onStateChange
   */
  onStateChange(event: Plyr.PlyrEvent) {
    const instance = event.detail.plyr;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const code = (event.detail as any).code;
    DEBUG_LOGS && console.log(`${PAGE} onStateChange`, { code, event, instance });
  }

  /**
   * Sent when a TextTrack has changed the currently displaying cues. (HTML5 only)
   */
  onCueChange(event: Plyr.PlyrEvent) {
    try {
      const instance: Plyr = event.detail.plyr;
      const trackIdx = instance.currentTrack;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const captions = (instance as any).captions;
      const track = captions.currentTrackNode;
      const activeCues = track.activeCues;

      const ids = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(activeCues)) {
        // console.log(`${key}: ${value}`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (value && (value as any).text) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ids.push((value as any).text);
        }
      }

      console.warn(`TEXTTRACK Plyr onCueChange`, { ids, activeCues, track, trackIdx });
      // console.warn(`TEXTTRACK Plyr onCueChange`, { ids, activeCues, track, instance, trackIdx, captions });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Sent when the player enters fullscreen mode (either the proper fullscreen or full-window fallback for older browsers).
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEnterFullScreen(event: Plyr.PlyrEvent) {
    // const instance = event.detail.plyr;
    console.log(`${PAGE} onEnterFullScreen -> body.is-plyr-fullscreen`);
    document.body.classList.toggle('is-plyr-fullscreen', true);
  }
  /**
   * Sent when the player exits fullscreen mode.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onExitFullScreen(event: Plyr.PlyrEvent) {
    // const instance = event.detail.plyr;
    console.log(`${PAGE} onExitFullScreen -> !body.is-plyr-fullscreen`); //, { event, instance });
    document.body.classList.toggle('is-plyr-fullscreen', false);
  }

  /**
   * Sent when an error occurs. The element's error attribute contains more information. (HTML5 only)
   *
   * Per Plyr readme, [cryptically] "The element's error attribute contains more information,"
   * Unresolved Issues:
   *    Get error details on error event https://github.com/sampotts/plyr/issues/566
   *    Error event not firing https://github.com/sampotts/plyr/issues/1231
   *
   * Updated on April 27 2023
   * plyr.onError => event.detail.plyr.elements.container.getElementsByTagName('video')[0] : MediaError
   */
  onError(event) {
    // Sentry-FJ Error: undefined after this...
    console.warn(`${PAGE} onError`, event);
    if (!event) {
      return;
    }
    // this.sentryService.captureError(event);

    if (event.target && event.target.error) {
      console.warn(`${PAGE} onError target.error:`, event.target.error);
      this.sentryService.captureError(event.target.error);
    }
    // getting lots of undefined errors in Sentry, not providing value here unless this exists..
    if (event.detail && event.detail.plyr && event.detail.plyr.error) {
      console.warn(`${PAGE} onError detail.plyr.error:`, event.detail.plyr.error);
      this.sentryService.captureError(event.detail.plyr.error);
    }

    // updated 2023-07-23 based on https://github.com/sampotts/plyr/issues/566#issuecomment-1525816082
    if (event?.detail?.plyr?.elements?.container) {
      // original video is not documented in typescript
      // It's a reference to the <video> element which Plyr will override
      // A Plyr error won't happen in that element
      // const originalVideo = (plyr.elements as any).original;
      const container = event.detail.plyr.elements.container;
      const plyrVideoEl =
        container && container.getElementsByTagName('video') && container.getElementsByTagName('video')[0];
      console.log(`${PAGE} onError 'event.detail.plyr.elements.container' plyrVideoEl:`, plyrVideoEl);
      const plyrVideoError: MediaError | null | undefined = plyrVideoEl?.error;
      // handle your error
      if (plyrVideoError && plyrVideoError.message) {
        console.warn(`${PAGE} onError detail.plyr.error:`, plyrVideoError);
        this.sentryService.captureError(plyrVideoError.message);
      }
    }

    if (PLYR_ERROR_DEBUG_LOGS) {
      // trying to dig into the error prop location...
      const getPropNames = (o) => {
        try {
          return typeof o === 'object' ? Object.getOwnPropertyNames(o).join(', ') : 'not-an-object';
        } catch (error) {
          console.log(`${PAGE} getPropNames caught:`, error);
          return '(caught error in getPropNames)';
        }
      };

      try {
        // wrap our debug in try catch to fail softly
        // let's see what props exist on the event
        console.log(`${PAGE} onError 'event' propertyNames: ${getPropNames(event)}`);
        const eventPropsToCheck = ['currentTarget', 'target', 'detail', 'isTrusted'];
        eventPropsToCheck.forEach((p) => {
          if (event[p]) {
            console.log(`${PAGE} onError 'event.${p}' propertyNames: ${getPropNames(event[p])}`);
          }
        });

        if (event.detail && event.detail.plyr) {
          console.log(`${PAGE} onError 'detail.plyr' propertyNames: ${getPropNames(event.detail.plyr)}`);
          console.log(`${PAGE} onError detail.plyr instance:`);
          console.warn(event.detail.plyr);
          /**
           * Plyr Unresolved Issues - causes this to be 'undefined'
           *    Get error details on error event https://github.com/sampotts/plyr/issues/566
           *    Error event not firing https://github.com/sampotts/plyr/issues/1231
           */
          console.warn(event.detail.plyr.error); // undefined
          // getting lots of undefined errors in Sentry, not providing value here unless this exists..
          if (event.detail.plyr.error) {
            // this.sentryService.captureError(event.detail.plyr.error); // already done
          }
        } else {
          console.warn(`${PAGE} onError unknown:`, event);
          this.sentryService.captureError(event);
        }
      } catch (error) {
        console.warn(`${PAGE} onError caught:`);
        console.error(error);
        this.sentryService.captureError(error);
      }
    }
    // Sentry is choking on this as "unhandled" (Error: undefined)
    // - does returning here "solve" (no value in these error messages, since we don't get any interesting info)
    return;
  }

  /**
   * Sent when loading of the media begins. (HTML5 only)
   */
  // onLoadStart(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onLoadStart`, { event, instance });
  // }
  /**
   * The first frame of the media has finished loading. (HTML5 only)
   */
  // onLoadedData(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onLoadedData`, { event, instance });
  // }
  /**
   * Sent when playback of the media starts after having been paused; that is, when playback is resumed after a prior pause event.
   */
  // onPlay(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onPlay`, { event, instance });
  // }
  /**
   * Sent periodically to inform interested parties of progress downloading the media.
   * Information about the current amount of the media that has been downloaded is available in the media element's buffered attribute.
   */
  // onProgress(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onProgress`, { event, instance });
  // }
  /**
   * Sent when the audio volume changes (both when the volume is set and when the muted state is changed).
   */
  // onVolumeChange(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onVolumeChange`, { event, instance });
  // }
  /**
   * Sent when a seek operation begins.
   */
  // onSeeking(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onSeeking`, { event, instance });
  // }
  /**
   * Sent when a seek operation completes.
   */
  // onSeeked(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onSeeked`, { event, instance });
  // }
  /**
   * Sent when the playback speed changes.
   */
  // onRateChange(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onRateChange`, { event, instance });
  // }

  onCaptionsEnabled(event: Plyr.PlyrEvent) {
    const instance = event.detail.plyr;
    console.log(`${PAGE} onCaptionsEnabled`, { event, instance });
  }
  onCaptionsDisabled(event: Plyr.PlyrEvent) {
    const instance = event.detail.plyr;
    console.log(`${PAGE} onCaptionsDisabled`, { event, instance });
  }
  // onLanguageChange(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onLanguageChange`, { event, instance });
  // }
  // onControlsHidden(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onControlsHidden`, { event, instance });
  // }
  // onControlsShown(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onControlsShown`, { event, instance });
  // }

  /**
   * The quality of playback has changed. (HTML5 only)
   */
  // onQualityChange(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onQualityChange`, { event, instance });
  // }

  /**
   * Sent when the user agent is trying to fetch media data, but data is unexpectedly not forthcoming. (HTML5 only)
   */
  // onStalled(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onStalled`, { event, instance });
  // }
  /**
   * Sent when the requested operation (such as playback) is delayed pending the completion of another operation (such as a seek). (HTML5 only)
   */
  // onWaiting(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onWaiting`, { event, instance });
  // }
  /**
   * The media has become empty; for example, this event is sent if the media has already been loaded (or partially loaded), and the load() method is called to reload it. (HTML5 only)
   */
  // onEmptied(event: Plyr.PlyrEvent) {
  //   const instance = event.detail.plyr;
  //   console.log(`${PAGE} onEmptied`, { event, instance });
  // }

  /* eslint-disable @typescript-eslint/member-ordering */
  private seekDelay: number = 500;
  private seekingTimeout;
  /* eslint-enable @typescript-eslint/member-ordering */

  private onSeekTo(playerId, startTime, endTime) {
    if (playerId === this.playerId && this.player) {
      this.isSeeking = true;
      DEBUG_LOGS && console.log(`${PAGE}(${playerId}) onSeekTo startTime: ${startTime} endTime: ${endTime}`);

      if (typeof endTime === 'number') {
        // eslint-disable-next-line eqeqeq
        if (this.endTime != endTime) {
          this.endTime = endTime;
          this.player.currentTime = endTime;
        }
      }
      if (typeof startTime === 'number') {
        // eslint-disable-next-line eqeqeq
        if (this.startTime != startTime) {
          this.startTime = startTime;
          this.player.currentTime = startTime;
        }
      }
      clearTimeout(this.seekingTimeout);
      this.seekingTimeout = setTimeout(() => {
        this.isSeeking = false;
      }, this.seekDelay);
    }
  }
}
