/** @format */

import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '../base-form.component';

@Component({
  selector: 'fs-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
})
export class FormTextareaComponent extends BaseFormComponent {
  @Input() autoGrow = false;
  @Input() rows = 4;
  @Input() placeholder = '';
  @Input() hint: string = '';

  constructor(translate: TranslateService) {
    super(translate);
  }
}
