<ion-list class="action-popover" lines="none">
  <ion-item
    *ngFor="let action of actions"
    button
    small
    detail="false"
    (click)="onClick(action.type)"
    [disabled]="action.disabled"
    [class.add-to-stack]="action.type === 'add-to-stack'"
    [title]="action?.tooltip ?? action.text"
  >
    <ion-icon slot="start" [name]="action.icon" class="no-pointer-events"></ion-icon>
    <ion-text *ngIf="showText" style="padding-left: 0; white-space: nowrap" slot="start">{{
      action.text
    }}</ion-text>
  </ion-item>
</ion-list>
