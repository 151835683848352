<ion-toolbar *ngIf="clip" class="clip-actions ion-no-border-top ion-no-padding ">
    <!-- <div class="btns-wrap"> -->
        <div *ngIf="currentPage === 'info'" class="spacer" style="width:24px;"></div>

        <ion-button *ngIf="showFavorite" fill="clear" color="light" [title]="'COMMON.FAVORITE' | translate" (click)="onClickFavorite($event)">
            <ion-icon *ngIf="isFavorite" slot="icon-only" name="heart"></ion-icon>
            <ion-icon *ngIf="!isFavorite" slot="icon-only" name="heart-outline"></ion-icon>
        </ion-button>

        <ion-fab-button *ngIf="showAddToStack" center-abs color="primary" [title]="'STACK.ACTIONS.ADD_TO_STACK' | translate" (click)="onClickAddToStack($event)">
            <ion-icon name="add-circle-outline"></ion-icon>
        </ion-fab-button>

        <ion-button *ngIf="showWatchLater" fill="clear" color="light" [title]="'COMMON.WATCH_LATER' | translate" (click)="onClickWatchLater($event)">
            <ion-icon slot="icon-only" name="time-outline"></ion-icon>
        </ion-button>

        <ion-button *ngIf="showShare" fill="clear" color="light" [title]="'COMMON.SHARE' | translate" (click)="onClickShare($event)">
            <ion-icon slot="icon-only" name="share-alt"></ion-icon>
        </ion-button>

        <ion-button fill="clear" color="light" [title]="'COMMON.FLAG' | translate" (click)="onClickFlag($event)">
            <ion-icon *ngIf="isFlagged" slot="icon-only" name="flag"></ion-icon>
            <ion-icon *ngIf="!isFlagged" slot="icon-only" name="flag-outline" class="not-flagged"></ion-icon>
        </ion-button>
        
        <!-- Editor Page: if canEdit (mine) -->
        <ng-container *ngIf="currentPage === 'editor'">    
            <ion-button *ngIf="canEdit" fill="clear" color="light" [title]="'CLIP.EDIT' | translate" (click)="onClickEdit($event)">
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
            </ion-button>
            <ion-button *ngIf="showTrim && canEdit" fill="clear" color="light" [title]="'CLIP.SETTINGS.TRIM' | translate" (click)="onClickTrim($event)">
                <ion-icon slot="icon-only" name="cut"></ion-icon>
            </ion-button>
            <ion-button *ngIf="canRemove" fill="clear" color="light" [title]="'EDITOR.REMOVE' | translate" (click)="onClickDelete($event)">
                <ion-icon slot="icon-only" name="remove-circle-outline"></ion-icon>
            </ion-button>
            <!-- Editor Page: new nav actions -->
            <ion-button class="actions-btns-right" fill="clear" color="light" 
                [title]="'EDITOR.MOVE_TOP' | translate" 
                [disabled]="index === 0"
                (click)="onClickMoveTop($event)">
                    {{ 'EDITOR.TOP' | translate }}
                    <ion-icon name="arrow-up"></ion-icon>
                    <!-- <ion-icon slot="icon-only" name="arrow-up"></ion-icon> -->
            </ion-button>
            <ion-button class="actions-btns-right" fill="clear" color="light" 
                [title]="'EDITOR.MOVE_BOTTOM' | translate" 
                [disabled]="index === (playlistLength - 1)"
                (click)="onClickMoveBottom($event)">
                    {{ 'EDITOR.MOVE_BOTTOM' | translate }}
                    <ion-icon name="arrow-down"></ion-icon>
                    <!-- <ion-icon slot="icon-only" name="arrow-down"></ion-icon> -->
            </ion-button>
        </ng-container>
        

        <ion-button *ngIf="adminHls" fill="clear" color="light" title="Upgrade" (click)="onClickAdminHls($event)">
            <ion-icon slot="icon-only" name="aperture"></ion-icon>
        </ion-button>


        <ion-fab-button *ngIf="currentPage === 'info'" size="small" color="primary" [title]="'STACK.ACTIONS.ADD_TO_STACK' | translate" (click)="onClickAddToStack($event)">
            <ion-icon name="add-circle-outline"></ion-icon>
        </ion-fab-button>

    <!-- </div> -->

</ion-toolbar>
