/**
 * Chargebee Billing Model
 *
 * @format
 */

/*
Default-Pro-Plan
  - monthly
  - yearly
Default-Teams
  - monthly
  - yearly
Default-Wedding
  - 1 year
*/

/**
 * prefer to build dynamically
 * ...but we need a root lookup for the definitions to reference?
 */
export enum ChargebeePlanId {
  Free = 'none', // 'free'?
  // Basic = 'basic', // 'free'?
  Pro = 'Pro-Plus',
  Teams = 'Default-Teams',
  Weddings = 'Default-Wedding',
  // Annually = 'cbdemo_annually',
  // unused yet:
  // "addon-30-minutes-pro", "addon-30-minutes-teams"
}
/** What the User will actually be subscribed to */
export enum ChargebeeSubscriptionItemPriceId {
  Free = 'none', // 'free'?
  ProMonthly = 'Pro-Plus-USD-Monthly',
  ProYearly = 'Pro-Plus-USD-Yearly',
  TeamsMonthly = 'Default-Teams-USD-Monthly',
  TeamsYearly = 'Default-Teams-USD-Yearly',
  Weddings = 'Default-Wedding-USD-Yearly',
  // unused yet:
  // "addon-30-minutes-pro", "addon-30-minutes-teams"
}

export enum ChargebeePlanType {
  Plan = 'plan',
  AddOn = 'addon',
}

// in billing.model?
export enum SubscriptionStatus {
  InActive,
  Active,
}

/** translate in Billing.Model
 * @todo convert to index not string enum...
 */
export enum SubscriptionLevel {
  Unknown = 'UNKNOWN',
  Loading = 'LOADING',
  /** default */
  Unsubscribed = 'FREE',
  /** unused, but maybe an idea? */
  Trial = 'TRIAL',
  /** Pro Plan Active */
  Pro = 'PRO',
  /** Wedding or other Event Plan Active */
  Event = 'EVENT',
  Wedding = 'WEDDING',
  /** determine SubscriptionLevel for crew on projects with active events in Billing.Reducer */
  Crew = 'CREW', // allow project type to determine wording, in essence we are all Crew if not the subscriber
  // Guest = 'GUEST',
  /** @todo implement Pro+ */
  Sponsored = 'SPONSORED',
}

export interface PlanPrice {
  id: string;
  name?: string;
  currencyCode?: string;
  isTaxable?: string;
  period?: number;
  periodUnit?: string;
  price?: number;
  pricingModel?: string;
  tiers?: PlanPriceTier[];
}
export interface PlanPriceTier {
  end: number;
  price: number;
  start: number;
}

/**
 * Plan
 * These are what are available
 */
export class Plan {
  id: ChargebeePlanId;
  itemType?: ChargebeePlanType = ChargebeePlanType.Plan;
  title: string;
  description?: string;
  billingPeriod?: string; // 3 Months
  /** ref to chargebee plan.name */
  name?: string;
  /** @deprecated */
  cost?: number; // 500.00
  costUnit? = '$'; //USD
  pricingModel?: string; // Flat pricing
  isActive?: number; // 0 | 1 SubscriptionStatus
  /** @deprecated */
  inactive?: boolean; // ACTIVE

  prices?: PlanPrice[] = [];

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

export class AddOn extends Plan {
  // same as a plan, just different type
  itemType = ChargebeePlanType.AddOn;
  constructor(props: unknown) {
    super(props);
  }
}

/**
 * Subscription
 * These are what are available
 */
export class BillingSubscription {
  id: string;
  status: SubscriptionStatus = SubscriptionStatus.InActive;
  level: SubscriptionLevel = SubscriptionLevel.Unsubscribed;
  projectId?: string;
  eventDate?: string;

  renews?: string;
  expires?: string;
  // title: string;
  // name?: string;
  // description?: string;
  // billingPeriod?: string; // 3 Months
  // cost?: number; // 500.00
  // costUnit? = '$'; //USD
  // pricingModel?: string; // Flat pricing

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

export class Coupon {
  applied_count: number;
  coupon_id: string; // "PRODEV"
  object: string = 'coupon';

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

/** This is the item in user's Subscription.subscription_items -> the actual subscription! */
export class SubscriptionItem {
  amount: number; // pennies, ie. 499
  free_quantity: number = 0;
  item_price_id: ChargebeeSubscriptionItemPriceId | string; // ie."Pro-Plus-USD-Monthly"
  item_type: string; // "plan"
  object: string = 'subscription_item';
  quantity: number;
  unit_price: number; // pennies, ie. 499

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

export class BillingAddress {
  city: string;
  country: string;
  first_name: string;
  last_name: string;
  line1: string;
  object: string = 'billing_address';
  state: string;
  state_code: string;
  validation_status: string; // "not_validated"
  zip: string;

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

export class ChargebeeCustomer {
  id: string; // userId
  email?: string;
  allow_direct_debit: boolean;
  auto_collection: string; // "on"
  card_status: string; // "valid"
  created_at: number; //1517505759;
  created_from_ip?: number;
  deleted: boolean;
  excess_payments: number; // 0
  first_name: string;
  last_name: string;
  net_term_days: number;
  object: string = 'customer';
  pii_cleared: string; //"active";
  preferred_currency_code: string = 'USD';
  promotional_credits: number;
  refundable_credits: number;
  resource_version: number;
  taxability: string; //"taxable";
  unbilled_charges: number;
  updated_at: number; //151750575;

  billing_address?: BillingAddress;
  mrr?: number;

  // not interesting, but exist..
  auto_close_invoices?: boolean;
  channel?: string; //= "web"
  // payment_method: PaymentMethod;
  primary_payment_source_id?: string;

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

export class ChargebeeSubscription {
  cf_event_date?: string;
  cf_project_id?: string;
  cf_project_name?: string;

  activated_at: number;
  auto_close_invoices: boolean;
  base_currency_code: string;
  billing_period: number; // 1
  billing_period_unit: string; // "month"
  channel: string; // "web"
  coupon: string; // "PRODEV"
  coupons: Coupon[] = [];
  create_pending_invoices?: boolean;
  created_at: number;
  created_from_ip?: string;
  currency_code: string;
  current_term_end: number;
  current_term_start: number;
  customer_id: string;
  deleted: boolean;
  due_invoices_count?: number;
  exchange_rate: number;
  has_scheduled_advance_invoices?: boolean;
  has_scheduled_changes?: boolean;
  id: string;
  mrr: number;
  next_billing_at: number;
  object: string = 'subscription';
  plan_amount: number;
  plan_free_quantity: number;
  plan_id: string;
  plan_quantity: number;
  plan_unit_price: number;
  resource_version: number;
  started_at: number;
  status: string; //= 'active'
  updated_at: number;
  remaining_billing_cycles?: number;

  subscription_items: SubscriptionItem[] = [];

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

/** The Response will be an array of these */
export interface ChargebeeEndpointResult {
  customer?: ChargebeeCustomer;
  subscription?: ChargebeeSubscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  card?: any;
}

/*
 * moved to billing.selectors: canCapture, canCaptureToProject
 * moved to project.model: canCreateEvent
 *
 * MVP-1144
 * Has access to Capture for 60min /month
 * - Need to capture video length during upload (FileSelected event) and check minutes avail
 * - Present User with AddMinutes AddOn if they are out
 * - Need UI to display minutes used in current month
 *
 * Allow Pro User to create an Event for their Project
 * - Convert project to eventType & eventDate with Teams/Wedding Package
 */

const getPrices = (plan: Plan): PlanPrice[] => plan?.prices ?? [];
export const getPricesWithTiers = (plan: Plan): PlanPrice[] =>
  getPrices(plan).filter((p) => p && p.tiers && p.tiers.length > 0);

/**
 * tiers = [
 *   {start: 1, end: 25, price: 20000}
 *   {start: 26, end: 50, price: 35000}
 *   {start: 51, end: 100, price: 50000}
 *   {start: 101, end: undefined, price: 200000}
 * ]
 */
export const getPriceFromTiers = (numMembers: number, tiers: PlanPriceTier[]): number => {
  const tier = tiers.find((t) => numMembers >= t.start && numMembers <= t.end);
  return tier && tier.price; //? tier.price / 100 : 0;
};

/**
 * this could use a refactor, but for now...
 *
 * @todo how to handle the crew & guests?
 */
export const getTopSubscriptionInfo = (
  subs: ChargebeeSubscription[]
): {
  level: SubscriptionLevel;
  proSubscriptionId: string;
  eventSubscriptionIds: string[];
} => {
  let topPlan = -1; // arbitrary weight, to get the top plan
  let level = SubscriptionLevel.Unsubscribed;
  let proSubscriptionId = '';
  const eventSubscriptionIds = [];
  subs.forEach((sub) => {
    if (!sub?.status || sub.status !== 'active') {
      return; // not active, ignore it
    }
    (sub?.subscription_items ?? []).forEach((item) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { item_price_id: priceId = '' } = item;
      const subId = sub?.id;

      console.log('getTopSubscriptionInfo', { priceId, item, sub });

      switch (priceId) {
        case ChargebeeSubscriptionItemPriceId.ProMonthly:
        case ChargebeeSubscriptionItemPriceId.ProYearly: {
          if (topPlan < 3) {
            topPlan = 3;
            level = SubscriptionLevel.Pro;
          }
          proSubscriptionId = subId;
          break;
        }
        case ChargebeeSubscriptionItemPriceId.TeamsMonthly:
        case ChargebeeSubscriptionItemPriceId.TeamsYearly: {
          if (topPlan < 2) {
            topPlan = 2;
            level = SubscriptionLevel.Event;
          }
          eventSubscriptionIds.push(subId);
          break;
        }
        case ChargebeeSubscriptionItemPriceId.Weddings: {
          if (topPlan < 1) {
            topPlan = 1;
            level = SubscriptionLevel.Event;
          }
          eventSubscriptionIds.push(subId);
          break;
        }
        default: {
          /**
           * @todo sentry.captureMessage()
           */
          console.warn(`[BillingStore] ChargebeePlanId Missing '${priceId}'`, subs);
        }
      }
    });
  });

  return {
    level,
    proSubscriptionId,
    eventSubscriptionIds,
  };
};

/**
 * Subscription Status
 * Possible values are
 * future - The subscription is scheduled to start at a future date.
 * in_trial - The subscription is in trial.
 * active - The subscription is active and will be charged for automatically based on the items in it.
 * non_renewing - The subscription will be canceled at the end of the current term.
 * paused - The subscription is paused. The subscription will not renew while in this state.
 * cancelled - The subscription has been canceled and is no longer in service.
 */
export const isSubscriptionActive = ({ status, deleted = false }): boolean => {
  if (deleted) {
    return false;
  }
  switch (status) {
    case 'in_trial':
    case 'non_renewing':
    case 'active':
      return true;
    case 'future': // to confirm if this should be active?
    case 'paused':
    case 'cancelled':
    default:
      return false;
  }
};

/**
 * get Pro subscriptions
 */
export const getProSubscriptions = (subs: ChargebeeSubscription[]): BillingSubscription[] => {
  const subscriptions: BillingSubscription[] = [];

  subs.forEach((sub) => {
    if (!isSubscriptionActive(sub)) {
      return; // not active, ignore it
    }
    (sub?.subscription_items ?? []).forEach((item) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { item_price_id: priceId = '' } = item;
      const subId = sub?.id;
      switch (priceId) {
        case ChargebeeSubscriptionItemPriceId.ProMonthly:
        case ChargebeeSubscriptionItemPriceId.ProYearly: {
          subscriptions.push({
            id: subId,
            status: SubscriptionStatus.Active,
            level: SubscriptionLevel.Pro,
          });
          break;
        }
        case ChargebeeSubscriptionItemPriceId.TeamsMonthly:
        case ChargebeeSubscriptionItemPriceId.TeamsYearly:
        case ChargebeeSubscriptionItemPriceId.Weddings:
        default: {
          // not a Pro
          return;
        }
      }
    });
  });
  return subscriptions;
};


/**
 * get event subscription detail
 */
export const getEventSubscriptionDetail = (cbSub: ChargebeeSubscription): BillingSubscription => {
  const subscription: BillingSubscription = {
    id: cbSub?.id ?? '',
    status: SubscriptionStatus.InActive,
    level: SubscriptionLevel.Unknown,
    projectId: cbSub?.cf_project_id ?? '',
  };
  if (!isSubscriptionActive(cbSub)) {
    return subscription;
  }

  const devCheckProjectId = (sub) => {
    if (!(sub?.projectId?.length > 0)) {
      console.warn('Subscription missing projectId?', cbSub);
    }
  }
  
  (cbSub?.subscription_items ?? []).forEach((item) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { item_price_id: priceId = '' } = item;
    
    // console.log('SUBSv2 - getEventSubscriptions', { priceId, item, sub });
    switch (priceId) {
      case ChargebeeSubscriptionItemPriceId.TeamsMonthly:
      case ChargebeeSubscriptionItemPriceId.TeamsYearly:
        subscription.level = SubscriptionLevel.Event;
        subscription.eventDate = cbSub?.cf_event_date ?? '';
        devCheckProjectId(subscription);
        break;
      case ChargebeeSubscriptionItemPriceId.Weddings:
        subscription.level = SubscriptionLevel.Wedding;
        subscription.eventDate = cbSub?.cf_event_date ?? '';
        devCheckProjectId(subscription);
        break; 
      case ChargebeeSubscriptionItemPriceId.ProMonthly:
      case ChargebeeSubscriptionItemPriceId.ProYearly:
        subscription.level = SubscriptionLevel.Pro;
        break;
    }
  });

  // get our renew/expiration date
  if (cbSub.current_term_end > 0) {
    const endDate = new Date(cbSub.current_term_end * 1000).toISOString();
    if (cbSub.status === 'non_renewing' || cbSub.remaining_billing_cycles === 0) {
      subscription.expires = endDate;
      // current_term_end === cancelled_at
    } else {
      subscription.renews = endDate;
    }
  }
  
  return {
    ...subscription,
    status: SubscriptionStatus.Active,
  };
};

/**
 * get event subscriptions
 */
export const getEventSubscriptions = (subs: ChargebeeSubscription[]): BillingSubscription[] => {
  const subscriptions: BillingSubscription[] = [];
  subs.forEach((sub) => {
    if (!isSubscriptionActive(sub)) {
      return; // not active, ignore it
    }
    const subDetail = getEventSubscriptionDetail(sub);

    if (subDetail.level === SubscriptionLevel.Event || subDetail.level === SubscriptionLevel.Wedding) {
      // is an event
      subscriptions.push(subDetail);
    }
  });
  return subscriptions;
};


export const enum PORTAL_SECTIONS {
  ACCOUNT_DETAILS = 'ACCOUNT_DETAILS', // = 'account_details',
  ADDRESS = 'ADDRESS', // = 'portal_address',
  ADD_PAYMENT_SOURCE = 'ADD_PAYMENT_SOURCE', // = 'portal_add_payment_method',
  BILLING_HISTORY = 'BILLING_HISTORY', // = 'portal_billing_history',
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION = 'CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION', // = 'portal_choose_payment_method',
  EDIT_ACCOUNT_DETAILS = 'EDIT_ACCOUNT_DETAILS', // = 'portal_edit_account',
  EDIT_BILLING_ADDRESS = 'EDIT_BILLING_ADDRESS', // = 'portal_edit_billing_address',
  EDIT_PAYMENT_SOURCE = 'EDIT_PAYMENT_SOURCE', // = 'portal_edit_payment_method',
  EDIT_SHIPPING_ADDRESS = 'EDIT_SHIPPING_ADDRESS', // = 'portal_edit_shipping_address',
  EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION', // = 'edit_subscription',
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS = 'EDIT_SUBSCRIPTION_CUSTOM_FIELDS', // = 'portal_edit_subscription_cf',
  PAYMENT_SOURCES = 'PAYMENT_SOURCES', // = 'portal_payment_methods',
  SUBSCRIPTION_CANCELLATION = 'SUBSCRIPTION_CANCELLATION', // = 'sub_cancel',
  SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS', // = 'sub_details',
  VIEW_PAYMENT_SOURCE = 'VIEW_PAYMENT_SOURCE', // = 'portal_view_payment_method',
  VIEW_SCHEDULED_CHANGES = 'VIEW_SCHEDULED_CHANGES', // = 'scheduled_changes',
}

/*
//..... old .......
*/

export interface ChargebeeCustomerApiInput {
  id: string;
  email: string;
  phone?: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  locale?: string;
  billing_address?: {
    first_name?: string;
    last_name?: string;
    line1?: string;
    city?: string;
    state?: string;
    state_code?: string;
    zip?: string;
    country?: string;
  };
}
