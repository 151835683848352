<!-- 
2) Rewatch 
3) See more (this opens the stack’s parent project? 
4) Countdown “Watch next stack in 3-2-1" 
 -->
<div class="video-overlay">
  <div class="video-overlay__container">
    <div class="video-overlay__heading">{{ 'STACK.STACK_COMPLETE' | translate }}</div>
  </div>
  <!-- <div class="video-overlay__item">Select a new Storyline to Explore</div> -->
  <div class="video-overlay__container">
    <ion-button fill="outline" class="video-overlay__item" (click)="watchAgain.emit(true)">
      <ion-icon slot="start" name="refresh" class="ion-hide-sm-down"></ion-icon>
      {{ 'PLAYER.OVERLAY.REWATCH' | translate }}
    </ion-button>
    <ion-button class="video-overlay__item" (click)="addToMystack()">
      <ion-icon slot="start" name="add-circle-outline"></ion-icon>
      {{ 'PLAYER.OVERLAY.ADD_TO_EDITOR' | translate }}
    </ion-button>
    <ion-button fill="outline" class="video-overlay__item" (click)="discover()">
      {{ 'PLAYER.OVERLAY.DISCOVER' | translate }}
    </ion-button>
  </div>
  <div class="video-overlay__container">
    <!-- <ion-button fill="clear" size="small" class="video-overlay__item" *ngIf="project && project.title" (click)="navProject($event)">
      <span translate [translateParams]="{project: project.title}">PLAYER.OVERLAY.SEE_MORE</span>
    </ion-button> -->
    <ion-item *ngIf="project && project.title" 
      button 
      lines="none"
      class="video-overlay__item" 
      (click)="navProject()">
      <ion-label color="primary" style="text-transform: initial;">
        <span translate [translateParams]="{project: project.title}">PLAYER.OVERLAY.SEE_MORE</span>
      </ion-label>
    </ion-item>
  </div>
</div>