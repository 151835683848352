/** @format */

import Plyr from 'plyr';
import { PlyrDriver, PlyrDriverCreateParams, PlyrDriverUpdateSourceParams, PlyrDriverDestroyParams } from '@app-plyr';

export class PlyrDriverDefault implements PlyrDriver {
  create(params: PlyrDriverCreateParams) {
    return new Plyr(params.videoElement, params.options);
  }

  updateSource(params: PlyrDriverUpdateSourceParams) {
    params.plyr.source = params.source;
  }

  destroy(params: PlyrDriverDestroyParams) {
    params.plyr.destroy();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  newSource(params: PlyrDriverUpdateSourceParams, doDestroy = true) {
    params.plyr.source = params.source;
  }
}
