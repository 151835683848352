/** @format */

import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { COMMUNITY_ROUTE } from '@app/app-routing.module';
import { ModalController } from '@ionic/angular';
import { ProjectMemberModalComponent } from '@members/project-member-modal/project-member-modal.component';
import { DiscoverCardClick, ITEM_TYPE } from '@shared/models/layout.model';
import { List } from '@shared/models/list.model';
import { DEFAULT_USER_AVATAR_TERTIARY, User } from '@shared/models/user.model';

@Component({
  selector: 'fs-user-list-carousel',
  templateUrl: './user-list-carousel.component.html',
  styleUrls: ['./user-list-carousel.component.scss'],
})
export class UserListCarouselComponent {
  @Input() users: Partial<User>[] = [];
  @Input() projects = [];
  @Input() currentUserId = '';
  @Input() showTitle = true;
  @Input() titleTranslateKey = 'COMMON.FILMSTACKERS';

  public itemType = ITEM_TYPE.Users;
  avatarFallback = DEFAULT_USER_AVATAR_TERTIARY;

  get userList(): List {
    const itemIds = (this.users || []).map((u) => u.userId);
    return {
      id: 'user-title-list',
      itemIds,
      loading: itemIds.length < 1,
      nextToken: '',
    };
  }

  constructor(private modalController: ModalController, private route: ActivatedRoute, private router: Router) {}

  onClickItem(event: DiscoverCardClick) {
    // console.log(`onClickItem`, event);
    if ((event.item as User).userId) {
      this.router.navigate([`/${COMMUNITY_ROUTE}/${(event.item as User).userId}`]);
    } else {
      console.warn('No UserId in event?', event);
    }
  }

  /** UNUSED, for ref */
  async viewUserProfileModal(userId: string) {
    const modal = await this.modalController.create({
      component: ProjectMemberModalComponent,
      componentProps: {
        userId,
        viewOnly: false,
      },
      cssClass: 'modal-lg',
    });
    return await modal.present();
  }
}
