/** @format */

import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertCtrl: AlertController) {}

  /*
    Displaying Info alert with Ionic AlertController
  */
  async presentInfoAlert(payload: { message?: string; title?: string; subTitle?: string; btnText?: string }) {
    const alert = await this.alertCtrl.create({
      header: payload.title || 'Info',
      subHeader: payload.subTitle || 'Info...',
      message: payload.message || '',
      buttons: payload.btnText ? [payload.btnText] : ['Dismiss'],
    });

    return await alert.present();
  }

  /*
    Displaying Error State alert with Ionic AlertController
  */
  async presentErrorAlert(_payload: { subHeader?: string; message?: string }) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      subHeader: _payload.subHeader || '',
      message: _payload.message || 'Error...',
      buttons: ['Dismiss'],
    });

    return await alert.present();
  }
}
