/**
 * @format
 */

import { Injectable } from '@angular/core';
// import _merge from 'lodash/merge';
import { interval } from 'rxjs';
import { take, withLatestFrom } from 'rxjs/operators';
import { PORTAL_SECTIONS, ChargebeePlanId } from '../../shared/billing.model';
import { UserService } from '@services/user.service';
import { Store } from '@ngrx/store';
import { State } from '@billing/store';
import * as billingActions from '@billing/store/billing.actions';
import { ChargebeeService, OpenCheckoutParams } from '@billing/shared/services/chargebee.service';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  /*
     ngrx Store connections - use directly from store in components
   */
  // public subscriptionLevel$: Observable<string> = this.store.select(selectSubscriptionLevel);
  // public clipMinutes$: Observable<number> = this.store.select(selectMinutes);
  // public billingInfo$: Observable<State> = this.store.select(selectBilling);

  constructor(
    private store: Store<State>,
    private chargebeeService: ChargebeeService,
    private userService: UserService
  ) {}

  loadPlans(): void {
    this.store.dispatch(billingActions.loadPlans());
  }

  // v1:...

  /**
   * This function is used to rebind all the events attached.
   * Use this function if a new element inserted dynamically after page load
   */
  rebindElements() {
    return this.chargebeeService.rebindElements();
  }

  /**
   * Create a Chargebee User, if not already
   */
  async createCustomer() {
    this.store.dispatch(billingActions.createCustomer());
  }

  /**
   * Get BillingInfo User of current loggedIn User, if exists
   * 2024-01-15 now called by UserEffects.loginSuccess to catch the login flow even after component loaded
   * (was previously called by subscription-status.component and user-menu.component)
   */
  async initUser() {
    const didInit = await this.chargebeeService.init();

    if (didInit) {
      interval(1500)
        .pipe(take(1), withLatestFrom(this.userService.userId$))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .subscribe(([_, userId]) => {
          console.log(`initUser -> cb.init + 1.5 -> userId(${userId}) -> loadSubs...`);
          if (!userId) {
            return console.log(`Skipping throw initUser - no userId`);
          }
          this.store.dispatch(billingActions.loadCustomerSubscriptions({ userId }));
        });
    }
  }

  /*
    Currently used, may remove due to portal sessions not working on mobile
  */

  /**
   * USED
   */
  openCheckout({
    /* eslint-disable @typescript-eslint/naming-convention */
    user_id = '',
    plan_id = ChargebeePlanId.Pro,
    price_id = '',
    referral_code = '',
    project_id = '',
    project_name = '',
    event_date = '',
    /* eslint-enable @typescript-eslint/naming-convention */
    onCancel,
    onError,
    onSuccess,
  }: OpenCheckoutParams) {
    // need the username and email? no, the BillingEffects handle the createCustomer and set there
    if (!user_id) {
      this.userService.userId$.pipe(take(1)).subscribe((uid) => {
        this.chargebeeService.openCheckout({
          user_id: uid,
          plan_id,
          price_id,
          referral_code,
          project_id,
          project_name,
          event_date,
          onCancel,
          onError,
          onSuccess,
        });
      });
    } else {
      this.chargebeeService.openCheckout({
        user_id,
        plan_id,
        price_id,
        referral_code,
        project_id,
        project_name,
        event_date,
        onCancel,
        onError,
        onSuccess,
      });
    }
  }

  updateBillingInfoWithHostedPage(hostedPageId: string) {
    return this.chargebeeService.updateBillingInfoWithHostedPage(hostedPageId);
  }

  /**
   * Changed from PORTAL_SECTIONS.EDIT_SUBSCRIPTION to one-level higher
   * to show the Cancel Subscription action on initial view, allowing to nav to Edit from there
   */
  openEditSubscription(subscriptionId: string) {
    this.chargebeeService.openPortal({ section: PORTAL_SECTIONS.SUBSCRIPTION_DETAILS, subscriptionId });
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  getEditCheckoutHostedPageId({
    subscriptionId,
    user_id = '',
    plan_id = ChargebeePlanId.Pro,
    price_id = '',
    referral_code = '',
    project_id = '',
    project_name = '',
    event_date = '',
  }: OpenCheckoutParams) {
    return this.chargebeeService.getEditCheckoutHostedPageId({
      subscriptionId,
      user_id,
      plan_id,
      price_id,
      referral_code,
      project_id,
      project_name,
      event_date,
    });
  }
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * Open the Billing Info Hosted Page
   */
  openBillingInfo() {
    this.chargebeeService.openPortal({ section: PORTAL_SECTIONS.EDIT_BILLING_ADDRESS });
  }

  /**
   * Open the Payment Methods Hosted Page
   */
  openPaymentMethods() {
    this.chargebeeService.openPortal({ section: PORTAL_SECTIONS.PAYMENT_SOURCES });
  }

  /**
   * Open the Purchase history Hosted Page
   */
  openPurchaseHistory() {
    this.chargebeeService.openPortal({ section: PORTAL_SECTIONS.BILLING_HISTORY });
  }

  private reset() {
    this.store.dispatch(billingActions.reset());
  }

  /**
   * Set the Portal Session for current user
   */
  private async setPortalSession() {
    return this.chargebeeService.setPortalSession();
  }
}
