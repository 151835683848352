<plyr class="plyr-el"
  [style.height]="cssHeight"
  [style.width]="cssWidth"
  [class.transitioning]="isTransitioning"
  [plyrType]="plyrType"
  [plyrTitle]="plyrTitle" 
  [plyrPoster]="plyrPoster" 
  [plyrOptions]="plyrOptions"
  [plyrPlaysInline]="true" 
  [plyrCrossOrigin]="true"
  [plyrSources]="plyrSources" 
  [plyrTracks]="plyrTracks" 
  [plyrDriver]="plyrDriver" 

  (plyrPlaying)="onPlaying($event)"
  (plyrPause)="onPause($event)"
  (plyrTimeUpdate)="onTimeUpdate($event)"
  (plyrEnded)="onEnded($event)"
  (plyrEnterFullScreen)="onEnterFullScreen($event)"
  (plyrExitFullScreen)="onExitFullScreen($event)"

  (plyrLoadedMetadata)="onLoadedMetadata($event)"
  (plyrCanPlay)="onCanPlay($event)"
  (plyrCanPlayThrough)="onCanPlayThrough($event)"
  (plyrStateChange)="onStateChange($event)"
  (plyrReady)="onReady($event)"
  (plyrError)="onError($event)"
  (plyrCueChange)="onCueChange($event)"
  (plyrCaptionsEnabled)="onCaptionsEnabled($event)"
  (plyrCaptionsDisabled)="onCaptionsDisabled($event)"
  (plyrInit)="onPlyrInit($event)"></plyr>

<!-- note change from [plyrCrossOrigin]="'anonymous'" 2022-02-08 -->
<!--
https://github.com/smnbbrv/ngx-plyr#inputs

plyrType: video or audio, see source setters
plyrTitle: string, see source setters
plyrPoster: poster URL, see source setters
plyrSources: array of sources, see source setters
plyrTracks: array of tracks, see source setters
plyrOptions: initial Plyr options
plyrPlaysInline: whether underlying element has playsinline attribute, boolean
plyrCrossOrigin: [whether underlying element has crossorigin attribute, boolean
plyrDriver: see custom plyr driver

-->

<!-- Events

  (plyrLoadStart)="onLoadStart($event)"
  (plyrLoadedData)="onLoadedData($event)"
  (plyrPlay)="onPlay($event)"
  (plyrProgress)="onProgress($event)"
  (plyrVolumeChange)="onVolumeChange($event)"
  (plyrSeeking)="onSeeking($event)"
  (plyrSeeked)="onSeeked($event)"
  (plyrRateChange)="onRateChange($event)"
  (plyrEnterFullScreen)="onEnterFullScreen($event)"
  (plyrExitFullScreen)="onExitFullScreen($event)"
  (plyrCaptionsEnabled)="onCaptionsEnabled($event)"
  (plyrCaptionsDisabled)="onCaptionsDisabled($event)"
  (plyrLanguageChange)="onLanguageChange($event)"
  (plyrControlsHidden)="onControlsHidden($event)"
  (plyrControlsShown)="onControlsShown($event)"
  
  (plyrQualityChange)="onQualityChange($event)"
  
  (plyrStalled)="onStalled($event)"
  (plyrWaiting)="onWaiting($event)"
  (plyrEmptied)="onEmptied($event)"
  
-->
