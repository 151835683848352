/**
 * DON'T FORMAT THIS FILE (please no prettier)
 *
 * @format
 */

const DEBUG_LOGS = false;

import { ActionReducerMap, ActionReducer } from '@ngrx/store';

//TODO: v3
// import { /* StorageSyncEffects,*/ storageSync } from 'ngrx-store-ionic-storage';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
// import * as fromHydrated from './hydrate.reducers'; //not a feature, is root
import * as fromEnviron from './environ.reducers';
import * as fromSearch from './search.reducers';
import * as fromClips from './clips.reducers';
// todo: MVP-1160 history of stacks + progress
// import * as fromHistory from './history.reducers';
// import * as fromPlaylist from './playlist.reducers';
import * as fromMyStack from './mystack.reducers';
import * as fromWatchLater from './watch-later.reducers';
import * as fromStacks from './stacks.reducers';
import * as fromProjects from './projects.reducers';
import * as fromUser from './user.reducers';
import * as fromViewstate from './viewstate.reducers';
import * as fromMembers from './members.reducers';
import * as fromLists from './lists.reducers';

// import { LOCALSTORAGE_KEYS } from '../store.module';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
// export interface State {
//   hydrated?: boolean;

//   environ: fromEnviron.State;
//   // layout: fromLayout.State;
//   search: fromSearch.State;
//   clips: fromClips.State;
//   history: fromHistory.State;
//   playlist: fromPlaylist.State;
//   mystack: fromMyStack.State;
//   watchLater: fromWatchLater.State;
//   stacks: fromStacks.State;
//   projects: fromProjects.State;
//   user: fromUser.State;
//   viewstate: fromViewstate.State;
//   members: fromMembers.State;
//   // lists: fromLists.State;
//   // router: fromRouter.RouterReducerState<any>;
//   // [fromEvents.featureKey]: fromEvents.State;
// }

export const initialState = {
  // hydrated: false, // ngrx-store-ionic-storage
  environ: fromEnviron.initialState,
  // layout: fromLayout.initialState,
  search: fromSearch.initialState,
  clips: fromClips.initialState,
  // history: fromHistory.initialState, // todo: MVP-1160 history of stacks + progress
  // playlist: fromPlaylist.initialState,
  mystack: fromMyStack.initialState,
  watchLater: fromWatchLater.initialState,
  stacks: fromStacks.initialState,
  projects: fromProjects.initialState,
  user: fromUser.initialState,
  viewstate: fromViewstate.initialState,
  members: fromMembers.initialState,
  lists: fromLists.initialState,
};

export type State = typeof initialState;

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  // hydrated: fromHydrated.reducer,
  environ: fromEnviron.reducer,
  // layout: fromLayout.reducer,
  search: fromSearch.reducer,
  clips: fromClips.reducer,
  // history: fromHistory.reducer,  // todo: MVP-1160 history of stacks + progress
  // playlist: fromPlaylist.reducer,
  mystack: fromMyStack.reducer,
  watchLater: fromWatchLater.reducer,
  stacks: fromStacks.reducer,
  projects: fromProjects.reducer,
  user: fromUser.reducer,
  viewstate: fromViewstate.reducer,
  members: fromMembers.reducer,
  lists: fromLists.reducer,
  // router: fromRouter.routerReducer,
  // [fromEvents.featureKey]: fromEvents.reducer,
};

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
// console.log all actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (state: State, action: any): State {
    if (DEBUG_LOGS) {
      console.log('%c[store] action: %o state: %o', 'color:green', action, state);
      // } else {
      //   console.log(`[store] action: ${action && action.type ? action.type : ''}`);
    }

    return reducer(state, action);
  };
}

/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state. This selector simply selects the `clips` state.
 *
 * Selectors are used with the `select` operator.
 *
 * ```ts
 * class MyComponent {
 * 	constructor(state$: Observable<State>) {
 * 	  this.clipsState$ = state$.select(getClipsState);
 * 	}
 * }
 * ```
 */

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */

// selectors moved to ./selectors
