/**
 * eslint-disable @angular-eslint/directive-class-suffix
 *
 * @format
 */

import { Input, Directive, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PasswordValidator } from '@shared/validators/password';
import { UsernameValidator } from '@shared/validators/username';
import { take } from 'rxjs/operators';

/**
 * Translated in constructor
 * @see assets/i18n/en.json ERROR_MESSAGES.FORM
 */
const ERROR_MESSAGES = {
  required: 'This field is required',
  notMatching: 'This field does not match',
  minlength: 'This field is too short',
  maxlength: 'This field is too long',
  nospecialchars: 'Must contain special characters',
  invalidchars: 'Contains unsupported characters',
  email: 'Must contain a valid Email',
  lowercase: 'Must contain lowercase characters',
  uppercase: 'Must contain uppercase characters',
  number: 'Must contain a number',
  pattern: 'Does not match the pattern',
  usernameExists: 'Username already taken',
  // pattern: PasswordValidator.patternDescShort, // replaced with individual errors
};

@Directive()
export abstract class BaseFormComponent {
  @Input() label: string;
  @Input() labelPlacement: string = 'stacked';
  @Input() control: UntypedFormControl;
  // additional error messages to take into account
  @Input() errorMessages = {} as Record<string, string>;
  @Input() disabled = false;
  /** ms */
  @Input() debounce: number = 0;
  // eslint-disable-next-line @angular-eslint/no-output-native, @typescript-eslint/no-explicit-any
  @Output() change = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-native, @typescript-eslint/no-explicit-any
  @Output() blur = new EventEmitter<any>();
  /** Unlike the ionInput event, the ionChange event is fired when the element loses focus after its value has been modified */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
  @Output() onInput = new EventEmitter<any>();

  // to prevent all input fields to be displayed as invalid we wait it to be ready for validation.
  // this occurs either if the field has lost focus (blur) or there has been any input happening.
  isReadyForValidation = false;

  private static didTranslate = false;

  constructor(translate: TranslateService) {
    if (!BaseFormComponent.didTranslate) {
      BaseFormComponent.didTranslate = true;
      PasswordValidator.setTranslations(translate);
      UsernameValidator.setTranslations(translate);
      translate
        .get([
          'ERRORS.FORM.REQUIRED',
          'ERRORS.FORM.NOT_MATCHING',
          'ERRORS.FORM.MIN_LENGTH',
          'ERRORS.FORM.MAX_LENGTH',
          'ERRORS.FORM.SPECIAL_CHARS',
          'ERRORS.FORM.INVALID_CHARS',
          'ERRORS.FORM.EMAIL',
          'ERRORS.FORM.LOWERCASE',
          'ERRORS.FORM.UPPERCASE',
          'ERRORS.FORM.NUMBER',
          'ERRORS.FORM.PATTERN',
          'ERRORS.FORM.USERNAME_EXISTS',
        ])
        .pipe(take(1))
        .subscribe((res) => {
          ERROR_MESSAGES.required = res['ERRORS.FORM.REQUIRED'];
          ERROR_MESSAGES.notMatching = res['ERRORS.FORM.NOT_MATCHING'];
          ERROR_MESSAGES.minlength = res['ERRORS.FORM.MIN_LENGTH'];
          ERROR_MESSAGES.maxlength = res['ERRORS.FORM.MAX_LENGTH'];
          ERROR_MESSAGES.nospecialchars = res['ERRORS.FORM.SPECIAL_CHARS'];
          ERROR_MESSAGES.invalidchars = res['ERRORS.FORM.INVALID_CHARS'];
          ERROR_MESSAGES.email = res['ERRORS.FORM.EMAIL'];
          ERROR_MESSAGES.lowercase = res['ERRORS.FORM.LOWERCASE'];
          ERROR_MESSAGES.uppercase = res['ERRORS.FORM.UPPERCASE'];
          ERROR_MESSAGES.number = res['ERRORS.FORM.NUMBER'];
          ERROR_MESSAGES.pattern = res['ERRORS.FORM.PATTERN'];
          ERROR_MESSAGES.usernameExists = res['ERRORS.FORM.USERNAME_EXISTS'];
        });
    }
  }

  get errorMessage() {
    if (!this.isReadyForValidation) {
      return null;
    }
    const errors = this.control?.errors;
    return Object.keys(errors || {})
      .filter((key) => errors[key])
      .map((key) => {
        const errorMessages = { ...ERROR_MESSAGES, ...this.errorMessages };
        return errorMessages[key];
      })
      .find(Boolean);
  }

  updateProp(value: string | number | boolean) {
    this.control.patchValue(value);
    this.control.markAsDirty();
    this.change.emit(value);
  }

  /**
   * Assuming you've debounced this event..
   */
  inputEvent(value: string | number | boolean) {
    this.control.patchValue(value);
    this.control.markAsDirty();
    this.onInput.emit(value);
  }
}
