/** @format */

import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '../base-form.component';

@Component({
  selector: 'fs-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss'],
})
export class FormRadioComponent extends BaseFormComponent {
  @Input() values: Record<string, { title: string; description: string }>;
  @Input() header: string;

  constructor(translate: TranslateService) {
    super(translate);
  }

  /** keep original order, use keyvalue:onCompare */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public keepOriginalOrder = (a, b) => a.key;
}
