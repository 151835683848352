/** @format */
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store'; // eslint-disable-line  @typescript-eslint/no-unused-vars
import { createEffect, Actions, ofType } from '@ngrx/effects'; // eslint-disable-line  @typescript-eslint/no-unused-vars
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { State } from '../reducers'; // eslint-disable-line  @typescript-eslint/no-unused-vars
import * as resetActions from '../actions/reset.actions'; // eslint-disable-line  @typescript-eslint/no-unused-vars
import * as viewstateActions from '../actions/viewstate.actions';

@Injectable()
export class ResetEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  /**
   * RESET_STORE
   * Reload App!
   */

  handleResetRebootAction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(resetActions.resetStoreThenReboot),
      switchMap(() => {
        this.store$.dispatch(viewstateActions.resetAll());
        if (window && window.location && typeof window.location.reload === 'function') {
          console.log(`App Rebooting...`);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          console.log(`Reset Store but no window.location.reload so no Reboot...`);
        }
        return from([]);
      })
    )
  );

  constructor(
    private actions$: Actions<resetActions.ActionsUnion>, // eslint-disable-line  @typescript-eslint/no-unused-vars
    private store$: Store<State> // eslint-disable-line  @typescript-eslint/no-unused-vars
  ) {}
}
