/** @format */

import { Component, OnInit, Input, ViewChild, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Project } from '@projects/shared/project.model';
// import { ProjectMember } from '@members/shared/project-member.model';
import { ToasterService } from '@services/toaster.service';
import { UserService } from '@services/user.service';
import { ProjectMemberService } from '@members/shared/services/index';
import { ProjectMemberProjectsComponent } from '@members/project-member-projects/project-member-projects.component';
import { ENABLE_FEATURE_USER_MESSAGING, ENABLE_FEATURE_USER_FOLLOW } from '@app/app.config';
import { DEFAULT_USER_AVATAR_TERTIARY } from '@shared/models/user.model';
import { Utils } from '@shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { List, StackList } from '@shared/models/list.model';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { loadFilteredStacks, loadMoreFilteredStacks } from '@store/actions/stacks.actions';
import { selectList, selectStackListItems } from '@store/selectors/lists.selectors';
import { FilterEntityTypes } from '@store/selectors/viewstate.selectors';
import { LAYOUT_TYPE } from '@shared/models/layout.model';
import { Stack } from '@shared/models/stack.model';

const DEBUG_LOGS = false;
const PAGE = '[ProjectMember]';
/**
 * ProjectMemberDetail
 */
@Component({
  selector: 'fs-project-member',
  templateUrl: './project-member.component.html',
  styleUrls: ['./project-member.component.scss'],
})
export class ProjectMemberComponent implements OnInit, OnDestroy {
  @Input()
  set userId(value) {
    if (this._userId !== value && value?.length > 0) {
      // select this ID from the store -> kicks off effect to load UserData
      this.projectMemberService.selectUserId(value);
    }
    this._userId = value;
  }
  get userId(): string {
    return this._userId;
  }
  @Input() projects: Project[];
  @Input() viewOnly = true;
  @Input() isStudio = false;
  @Input() showRecentStacks = true;

  @Output() dismiss = new EventEmitter<void>();

  @ViewChild(ProjectMemberProjectsComponent) projectMemberProjectsComponent: ProjectMemberProjectsComponent;

  // @ViewChild('modal', { static: false, read: BaseModalComponent })
  // modal: BaseModalComponent;

  enableMessaging = ENABLE_FEATURE_USER_MESSAGING;
  enableFollow = ENABLE_FEATURE_USER_FOLLOW;

  selectedUser$ = this.projectMemberService.selected$;
  avatarUrl = DEFAULT_USER_AVATAR_TERTIARY;
  avatarFallback = DEFAULT_USER_AVATAR_TERTIARY;
  showingBio = false; // toggle the bio display

  joined: string;

  layoutType = LAYOUT_TYPE;
  recentStacks: StackList = {
    label: 'Recent', // will be translated onInit
    // list$: of(), // this.store.select(selectList(this.listIdProjectStacksRecent)),
    // items$: of(), //this.store.select(selectStackListItems(this.listIdProjectStacksRecent)),
  };

  private _userId = '';
  private get listIdMemberStacks() {
    return `stacks_recent_user_${this.userId || 'anon'}`;
  }
  private onDestroy$ = new Subject<void>();

  constructor(
    private modalController: ModalController,
    private toaster: ToasterService,
    private userService: UserService,
    private projectMemberService: ProjectMemberService,
    private store: Store<State>,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.selectedUser$
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(
          (prev, curr) => curr?.userId && curr.userId === prev?.userId && curr?.bio && curr.bio === prev?.bio
        )
      )
      .subscribe(async (user) => {
        DEBUG_LOGS && console.log(`${PAGE} currentUser$`, user);
        if (!user || !user.userId) return;

        const joined = user.createdAt || user.updatedAt;
        if (joined) {
          this.translate
            .get('COMMON.DAYS_AGO')
            .pipe(take(1))
            .subscribe((res) => {
              // todo: consider # COMMON.YEARS # days ago ?
              this.joined = `${Utils.daysAgo(new Date(), new Date(joined))} ${res}`;
            });
        }

        this.avatarUrl = this.userService.getUserAvatarUrl(user.userId);

        this.showingBio = user.bio?.length > 0;

        if (this.showRecentStacks) {
          this.translate
            .get(['COMMON.RECENT']) // 'COMMON.FEATURED'
            .pipe(take(1))
            .subscribe((res) => {
              this.recentStacks.label = res['COMMON.RECENT'];
              // this.featuredStacks.label = res['COMMON.FEATURED'];
            });

          this.recentStacks = {
            label: this.recentStacks.label,
            list$: this.store.select(selectList(this.listIdMemberStacks)),
            items$: this.store.select(selectStackListItems(this.listIdMemberStacks)) as Observable<Stack[]>,
          };
          // load RECENT STACKS by project id
          this.store.dispatch(
            loadFilteredStacks({
              listId: this.listIdMemberStacks,
              filters: {
                id: this.listIdMemberStacks,
                userId: this.userId,
                type: FilterEntityTypes.Recent,
              },
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  loadMoreStacks(list: List) {
    this.store.dispatch(
      loadMoreFilteredStacks({
        listId: list.id,
        filters: list.filters,
        nextToken: list.nextToken,
      })
    );
  }

  async followCrewMember() {
    console.log('follow btn clicked');
    await this.toaster.present(`Feature in DEV: Follow User`);
  }

  async messageCrewMember() {
    console.log('message btn clicked');
    await this.toaster.present(`Feature in DEV: Message User`);
  }

  saveProjectChanges() {
    console.log('TODO: saveProjectChanges here or in child component?');
    // this.projectMemberProjectsComponent.saveChanges();
  }
}
