/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnboardingTypes, ROUTE_DATA_PROP } from '@onboarding/shared/onboarding.model';
import { OnboardingLandingComponent } from '@onboarding/onboarding-landing/onboarding-landing.component';
// import { OnboardingSetupComponent } from '@onboarding/onboarding-setup/onboarding-setup.component';
import { OnboardingSignupComponent } from '@onboarding/onboarding-signup/onboarding-signup.component';
import { OnboardingSuccessComponent } from '@onboarding/onboarding-success/onboarding-success.component';
import { OnboardingInviteComponent } from '@onboarding/onboarding-invite/onboarding-invite.component';
// import { OnboardingTourComponent } from '@onboarding/onboarding-tour/onboarding-tour.component';
import { OnboardingAcceptComponent } from '@onboarding/onboarding-accept/onboarding-accept.component';

const routes: Routes = [
  {
    path: 'wedding',
    redirectTo: 'weddings',
    pathMatch: 'full',
  },
  {
    path: 'weddings',
    component: OnboardingLandingComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full',
  },
  {
    path: 'wedding/signup',
    redirectTo: 'weddings/signup',
    pathMatch: 'full',
  },
  {
    path: 'weddings/signup',
    component: OnboardingSignupComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'weddings/signup/:projectId',
    component: OnboardingSignupComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'wedding/success',
    redirectTo: 'weddings/success',
    pathMatch: 'full',
  },
  {
    path: 'weddings/success',
    component: OnboardingSuccessComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'wedding/invite',
    redirectTo: 'weddings/invite',
    pathMatch: 'prefix',
  },
  {
    path: 'weddings/invite',
    component: OnboardingInviteComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'weddings/invite/:projectId',
    component: OnboardingInviteComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'weddings/invite/:projectId/:inviteId',
    component: OnboardingInviteComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'wedding/join',
    redirectTo: 'weddings/accept',
    pathMatch: 'prefix',
  },
  {
    path: 'weddings/join',
    redirectTo: 'weddings/accept',
    pathMatch: 'prefix',
  },
  {
    path: 'wedding/accept',
    redirectTo: 'weddings/accept',
    pathMatch: 'prefix',
  },
  {
    path: 'weddings/accept',
    component: OnboardingAcceptComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'weddings/accept/:projectId',
    component: OnboardingAcceptComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'weddings/accept/:projectId/:inviteId',
    component: OnboardingAcceptComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Weddings },
    pathMatch: 'full'
  },
  {
    path: 'signup',
    component: OnboardingSignupComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
    pathMatch: 'full'
  },
  {
    path: 'signup/:projectId',
    component: OnboardingSignupComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
    pathMatch: 'full'
  },
  {
    path: 'success',
    component: OnboardingSuccessComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
  },
  {
    path: 'invite',
    component: OnboardingInviteComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
  },
  {
    path: 'invite/:projectId',
    component: OnboardingInviteComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
  },
  {
    path: 'invite/:projectId/:inviteId',
    component: OnboardingInviteComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
  },
  {
    path: 'join',
    redirectTo: 'accept',
    pathMatch: 'prefix',
  },
  {
    path: 'accept',
    component: OnboardingAcceptComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
    pathMatch: 'full'
  },
  {
    path: 'accept/:projectId',
    component: OnboardingAcceptComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
    pathMatch: 'full'
  },
  {
    path: 'accept/:projectId',
    component: OnboardingAcceptComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
    pathMatch: 'full'
  },
  {
    path: '',
    component: OnboardingLandingComponent,
    data: { [ROUTE_DATA_PROP]: OnboardingTypes.Teams },
  },
  // { path: 'signup', component: TeamsSignupComponent },
  // { path: 'success', component: TeamsSuccessComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamsRoutingModule {}
